import { InputHTMLAttributes, memo, useMemo } from 'react';

import { twMerge } from 'tailwind-merge';

import CloseIconTag from './CloseIcon';
import TagMentionIcon from './Tag.Mention';

export enum TagTheme {
  GRAY = 'gray',
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  TETRIARY = 'tetriary',
  SUCCESS = 'success',
  INFO = 'info',
  WARNING = 'warning',
  DANGER = 'danger',
  TAG = 'tag',
}

interface TagPropsInterface {
  onRemove?: () => void;
  theme?: TagTheme;
  haveRemove?: boolean; // Deprecated
  svgClassName?: string;
}

export type TagProps = TagPropsInterface & InputHTMLAttributes<HTMLDivElement>;

export function Tag(props: TagProps) {
  const classNameMerged = useMemo(() => {
    let theme;
    switch (props.theme) {
      case TagTheme.TAG:
      case TagTheme.WARNING:
        theme = 'bg-[#F5A623] text-[#F5A623]';
        break;
      case TagTheme.DANGER:
        theme = 'bg-v2red/15 text-v2red';
        break;
      case TagTheme.SECONDARY:
        theme = 'bg-v2blue-600/15 text-v2blue-600';
        break;
      case TagTheme.GRAY:
        theme = 'bg-[#666666] text-[#666666]';
        break;
      case TagTheme.TETRIARY:
        theme = 'bg-v2violet-650/[.16] text-v2violet-650';
        break;
      case TagTheme.SUCCESS:
      case TagTheme.PRIMARY: // By default primary
      default:
        theme = 'bg-primary text-primary';
    }
    return twMerge(
      `relative rounded-[59px] bg-opacity-15`,
      `inline-flex items-center`,
      `gap-x-[4px] select-none font-medium text-sm`,
      `h-[29px]`,
      'pl-[12px] pt-[5px] pb-[5px]',
      props.onRemove ? 'pr-[8px]' : 'pr-[12px]',
      theme,
      props.className,
    );
  }, [props.className, props.theme]);

  return (
    <div onClick={props.onClick} data-testid="outvioui--tag" className={twMerge(classNameMerged)}>
      {props.theme === TagTheme.TAG && <TagMentionIcon />}
      <span className="text-[14px] tracking-[-0.14px]">{props.children}</span>
      {props.onRemove && (
        <div
          data-testid="outvioui--tag__hoverable"
          onClick={props.onRemove}
          className="opacity-60 hover:opacity-100 hover:cursor-pointer"
        >
          <CloseIconTag className={twMerge('', props.svgClassName)} />
        </div>
      )}
    </div>
  );
}

export default memo<TagProps>(Tag);
