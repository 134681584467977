import { DisplayStatusCodes, OutvioStatusCodes } from './IPortalSettings';

export enum Emojis {
  SAD = '😞',
  MKAY = '😕',
  HAPPY = '🙂',
  BIG_SMILE = '😁',
  STARRY_EYES = '🤩',
}

export type StatusStepsDisplay = 'full-steps' | 'incidents' | 'categories';

export enum StepStatus {
  PENDING = 'pending',
  DONE = 'done',
  INCIDENT = 'incident',
  BACKTRACK_INCIDENT = 'backtrack_incident',
  BACKTRACK = 'backtrack',
  PAST_INCIDENT = 'past_incident',
}

export interface IOrderStatusInfoData {
  currentStep: DisplayStep;
  isIncident: boolean;
  incidentUrl?: string;
  steps: DisplayStep[];
}

export interface IDeliveryContact {
  fullName: string;
  email?: string;
  phone?: string;
}

export interface IDeliveryAddress {
  street: string;
  city: string;
  country: string;
  postcode: string;
}

export interface IDeliveryInfoData {
  contact: IDeliveryContact;
  address: IDeliveryAddress;
  comment?: string;
  courier: string;
  canUserEdit: boolean; // Is edit blocked because of time
}

export interface IOrderProduct {
  name: string;
  amount: number;
  price: string;
  imgSrc?: string;
  variant?: string;
  discountPrice?: string | null;
}

export interface IOrderInfoData {
  shippingPrice: string;
  vat: string;
  orderTotal: string;
  products: IOrderProduct[];
  otn: string;
  trackingNumber?: string;
  secondaryTrackingNumber?: string;
  originalId?: string;
  id?: string;
  canUserCancel: boolean;
  refund: string; // Value for refunding
  method?: string;
}

export interface ICoordinates {
  lng: number;
  lat: number;
}

export interface IMarker extends ICoordinates {
  isShipmentHere: boolean;
}

export interface IMap {
  markers: Promise<IMarker[]>;
}

export interface ITrackingData {
  orderStatusInfo: IOrderStatusInfoData;
  deliveryInfo: IDeliveryInfoData;
  orderInfo: IOrderInfoData;
  map: IMap;
  otn: string;
  trackingLink: Promise<string | null>;
  rating?: number;
  timeZone?: string;
  cmsInfo?: {
    // Deprecated
    zoneOffset?: string; // Format +00:00
  };
  shouldDisplayMap?: boolean;
  estimatedDeliverDate?: string;
  recommendedProducts?: {
    title: string;
    imageUrl: string;
    originalPrice: number;
    discountedPrice?: number;
    currency: string;
    link: string;
  }[];
}

export interface SourceSteps {
  steps: SourceStep[];
}

export interface SourceStep {
  date: string;
  location?: string | null;
  code: OutvioStatusCodes;
  description?: string;
  courier: string;
  courierDescription?: string | null;
  courierCode?: string;
  eventCode?: string | null;
  status?: string;
  substatus?: string;
  coordinates?: ICoordinates;
  serviceAreaCode?: unknown;
  pickupPoint?: string;
  packageId?: string;
}

export interface OrderStep {
  status: StepStatus;
  displayCode: DisplayStatusCodes;
  code: OutvioStatusCodes;
  description?: string;
  location?: string;
  dateTime: string;
  coordinates?: ICoordinates;
}

export interface CategorizedStep {
  displayCode: DisplayStatusCodes;
  steps: OrderStep[];
}

export interface DisplayStep extends OrderStep {
  isSubStep: boolean;
  isFirst: boolean;
  isLast: boolean;
  prevStatus?: StepStatus;
  nextStatus?: StepStatus;
  isLastDoneStep: boolean;
  parent?: {
    prevStatus?: StepStatus;
    nextStatus?: StepStatus;
    status: StepStatus;
  };
}

export enum Currency {
  EUR = 'EUR',
  PLN = 'PLN',
  GBP = 'GBP',
  USD = 'USD',
}

export const CURRENCY_SYMBOLS = {
  [Currency.EUR]: '€',
  [Currency.PLN]: 'zł.',
  [Currency.GBP]: '£',
  [Currency.USD]: '$',
};

/**
 * The OutvioStatusCodes that MUST belong as substeps of specific DisplayStatusCodes.
 * The order of the steps array matters.
 */
export const SPECIFIC_SUBSTEPS: { [key in OutvioStatusCodes]?: DisplayStatusCodes[] } = {
  [OutvioStatusCodes.BAD_ADDRESS]: [
    OutvioStatusCodes.IN_TRANSIT,
    OutvioStatusCodes.OUT_FOR_DELIVERY,
  ],
  [OutvioStatusCodes.DAMAGED]: [OutvioStatusCodes.IN_TRANSIT, OutvioStatusCodes.OUT_FOR_DELIVERY],
  [OutvioStatusCodes.DESTROYED]: [OutvioStatusCodes.IN_TRANSIT, OutvioStatusCodes.OUT_FOR_DELIVERY],
  [OutvioStatusCodes.DELAYED]: [OutvioStatusCodes.IN_TRANSIT],
  [OutvioStatusCodes.MISSING_DOCUMENTATION]: [
    OutvioStatusCodes.IN_TRANSIT,
    OutvioStatusCodes.OUT_FOR_DELIVERY,
  ],
  [OutvioStatusCodes.LOST]: [OutvioStatusCodes.IN_TRANSIT, OutvioStatusCodes.OUT_FOR_DELIVERY],
  [OutvioStatusCodes.NOT_AT_HOME]: [OutvioStatusCodes.OUT_FOR_DELIVERY],
  [OutvioStatusCodes.PARTIALLY_DELIVERED]: [OutvioStatusCodes.OUT_FOR_DELIVERY],
  [OutvioStatusCodes.REFUSED]: [OutvioStatusCodes.OUT_FOR_DELIVERY],
  [OutvioStatusCodes.RETURN_TO_ORIGIN]: [OutvioStatusCodes.OUT_FOR_DELIVERY],
  [OutvioStatusCodes.STOLEN]: [OutvioStatusCodes.IN_TRANSIT, OutvioStatusCodes.OUT_FOR_DELIVERY],
  [OutvioStatusCodes.SENT_TO_AGENCY]: [OutvioStatusCodes.IN_TRANSIT],
};

interface SourceOriginAddress {
  name?: string;
  postcode: string;
  city: string;
  address: string;
  country: string;
  countryCode: string;
  contactName: string;
  contactPhone: string;
  coordinates: ICoordinates;
}

export interface SourceDestinationAddress {
  comment?: string;
  address: string;
  city: string;
  state?: string;
  country: string;
  countryCode: string;
  postcode: string;
  phone?: string;
  email?: string;
  name: string;
  coordinates: ICoordinates;
}

interface Product {
  amount: number;
  discountPrice?: number | null;
  imgSrc: string;
  name: string;
  price: number;
  variant: string;
}

export interface SourceTrackingData {
  trackingNumber?: string;
  secondaryTrackingNumber?: string;
  otn: string;
  originAddress: SourceOriginAddress;
  origin: string;
  destination: string;
  processDate: string;
  pickingDate?: string;
  estimatedDeliverDate?: string;
  support?: string;
  destinationAddress: SourceDestinationAddress;
  courier: string;
  method?: string;
  shippingPrice: number;
  total: number;
  vat: number;
  currency: Currency;
  products: Product[];
  originalId?: string;
  id?: string;
  rating?: { rating: number } | null;
  canEditDeliveryAddress: boolean;
  dateTime?: string;
  createdAt: string;
  isSplitOrder?: boolean;
  orderOtn: string;
  isDeleted?: boolean;
  cancelingAllowed: boolean;
  cmsInfo?: {
    zoneOffset?: string; // Format +00:00
  };
  timeZone?: string;
  formattedTimes?: {
    processDate?: string;
    pickingDate?: string;
    createdAt?: string;
    dateTime?: string;
  };
  returnData?: {
    hasExchange: boolean;
    hasReturn: boolean;
    creditAmount: number;
    couponTrigger: any;
    clientPays: number;
    storeReturns: number;
    discountType: any;
    specialActionType: any;
    refundMethod: string;
  };
  shouldDisplayMap?: boolean;
  recommendedProducts?: {
    title: string;
    imageUrl: string;
    originalPrice: number;
    currency: string;
    link: string;
    discountedPrice?: number;
  }[];
}
