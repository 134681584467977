import React from 'react';
import { TextareaHTMLAttributes, useContext, useMemo } from 'react';

import { Theme, makeStyles } from '@material-ui/core';
import cn from 'classnames';

import opacityToSolidColor from '../utils/opacityToSolidColor';
import OVCStoreDataContext from './OVCStoreDataContext';

interface StyleProps {
  textColor: string;
  fadeText: string;
  errColor: string;
  errColorFaded: string;
}

const useStyles = makeStyles<Theme, StyleProps>({
  base: {
    width: '100%',
    minWidth: '100%',
    padding: '8px 10px',

    fontSize: '14px',
    outline: 'none',

    backgroundColor: 'transparent',
    borderRadius: '5px',
    border: (props) => `1px solid ${props.textColor}`,

    color: (props) => props.textColor,

    '&::placeholder': {
      color: (props: any) => props.fadeText,
    },
  },
  baseError: {
    border: (props) => `1px solid ${props.errColor}`,

    color: (props) => props.errColor,

    '&::placeholder': {
      color: (props: any) => props.errColorFaded,
    },
  },
});

interface IOVCTextarea extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  error?: boolean;
}

const OVCTextarea = ({ error = false, className, ...props }: IOVCTextarea) => {
  const { storeData } = useContext(OVCStoreDataContext);

  const fadeText = useMemo(
    () =>
      opacityToSolidColor(
        storeData.portalSettings.colors.primaryText,
        0.7,
        storeData.portalSettings.colors.primaryBg,
      ),
    [storeData],
  );
  const errColorFaded = useMemo(
    () =>
      opacityToSolidColor(
        storeData.portalSettings.colors.alertColor,
        0.7,
        storeData.portalSettings.colors.primaryBg,
      ),
    [storeData],
  );

  const classes = useStyles({
    textColor: storeData.portalSettings.colors.primaryText,
    fadeText,
    errColor: storeData.portalSettings.colors.alertColor,
    errColorFaded,
  });
  return (
    <textarea className={cn(classes.base, { [classes.baseError]: error }, className)} {...props} />
  );
};

export default OVCTextarea;
