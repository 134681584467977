import React, { FC, Fragment } from 'react';

import { Dialog, Transition } from '@headlessui/react';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { useGetPaletteColors } from 'return-portal-ui';

export const MODAL_Z_INDEX = 1000;

/* eslint-disable @typescript-eslint/no-explicit-any */
interface BaseModal {
  isVisible: boolean;
  title?: React.ReactNode | string;
  handleClose: any;
  isCloseButton?: boolean;
  withTitleBar?: boolean;
  children?: React.ReactNode;
  customStyle?: React.CSSProperties;
  LeftButton?: React.ReactNode;
  RightButton?: React.ReactNode;
  isOv?: boolean;
  iframeHeight?: string;
  titleAs?: 'div' | 'span' | 'h3';
}
/* eslint-enable @typescript-eslint/no-explicit-any */
const BaseModal: FC<BaseModal> = (props) => {
  const {
    isVisible,
    handleClose,
    title,
    isCloseButton = true,
    withTitleBar = true,
    children,
    customStyle,
    LeftButton,
    RightButton,
    isOv = false,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    iframeHeight,
    titleAs = `h3`,
  } = props;

  const { secondaryBg, primaryText } = useGetPaletteColors();

  return (
    <Transition as={Fragment} appear unmount show={isVisible}>
      <Dialog
        as="div"
        onClose={handleClose}
        open={true}
        className="fixed inset-0 overflow-y-auto"
        style={{ zIndex: MODAL_Z_INDEX }}
      >
        <div className="min-h-screen text-center">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-out duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-grayTP-700/50" />
          </Transition.Child>

          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="md:opacity-0 md:translate-y-0 md:scale-95 translate-y-full"
            enterTo="md:opacity-100 md:scale-100 translate-y-0"
            leave="ease-out duration-200"
            leaveFrom="md:opacity-100 md:scale-100 translate-y-0"
            leaveTo="md:opacity-0 md:translate-y-0 md:scale-95 translate-y-full"
          >
            <div
              style={{ ...customStyle, backgroundColor: isOv ? '#FFFFFF' : secondaryBg }}
              className={`max-h-[600px] flex-1 h-full min-w-full md:min-w-min bottom-0 left-0 py-8 px-10 mt-8 overflow-hidden text-left align-middle transition-all transform 
               shadow-lg rounded-xl md:rounded-2xl z-[100] md:max-h-[calc(100vh-72px)] inline-flex flex-col fixed md:static md:w-min`}
            >
              {withTitleBar && (
                <div className="flex justify-between items-center pb-8">
                  {LeftButton}
                  {title && (
                    <Dialog.Title
                      as={titleAs}
                      style={{ color: primaryText }}
                      className="text-xl font-semibold leading-6 flex-1"
                    >
                      {title}
                    </Dialog.Title>
                  )}
                  {RightButton}
                  {isCloseButton && (
                    <button type="button" onClick={handleClose} className="mr-6 !bg-transparent">
                      <CloseRoundedIcon
                        style={{ color: !isOv ? primaryText : '' }}
                        className={isOv ? 'text-slate-900' : 'opacity-30'}
                      />
                    </button>
                  )}
                </div>
              )}

              {children}
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
};

export default BaseModal;
