import { memo, useCallback, useState } from 'react';

import { StoreFeature } from 'app-types/core';
import { useRequestFeature } from 'data-layer';
import { DeskIcon } from 'icons';
import { FormattedMessage, useIntl } from 'localization';
import { ConfirmationWindow } from 'ui';
import { Copy, DialogContainer } from 'ui/atoms';

import RequestFeatureConfirmInfoModal from './RequestFeatureConfirmInfoModal';

type RequestDeskModalBaseProps = {
  show: boolean;
  onClose: () => void;
};

function RequestDeskModalBase({ show, onClose }: RequestDeskModalBaseProps) {
  const [showConfirm, setShowConfirm] = useState(false);

  const { formatMessage } = useIntl();

  const [mutate, { loading }] = useRequestFeature();

  const handleRequest = useCallback(async () => {
    await mutate({
      variables: {
        feature: StoreFeature.OutvioDesk,
      },
    });

    onClose();
    setShowConfirm(true);
  }, []);

  const closeConfirmWindow = useCallback(() => {
    setShowConfirm(false);
  }, []);

  return (
    <>
      <DialogContainer isOpen={show} handleClose={onClose}>
        <ConfirmationWindow
          title={formatMessage({ id: 'dashboard.support.requestTitle' })}
          cancelButtonTitle={formatMessage({ id: 'general.cancel' })}
          confirmButtonTitle={formatMessage({ id: 'settings.sendRequest' })}
          isLoading={loading}
          onCancel={onClose}
          onConfirm={handleRequest}
          headingIcon={<DeskIcon className="text-2xl" />}
          theme="dark"
          content={
            <Copy className="text-center">
              <FormattedMessage id="dashboard.support.lockDesk" />
            </Copy>
          }
        />
      </DialogContainer>

      <RequestFeatureConfirmInfoModal isOpen={showConfirm} onClose={closeConfirmWindow} />
    </>
  );
}

export const RequestDeskModal = memo(RequestDeskModalBase);
