export default {
  'custLanding.password.title': 'Passwort vergessen?',
  'custLanding.signup.btn': 'Konto erstellen',
  'custLanding.login.btn': 'Einloggen',
  'custLanding.password.btn': 'Passwort ändern',
  'custLanding.switch-to-login': 'Mit vorhandenem Konto einloggen',
  'custLanding.switch-to-signup': ' Neues Konto erstellen',
  'custLanding.auth.text.password':
    'Gib deine E-Mail-Adresse ein und wir schicken dir eine Anleitung zum Erstellen eines neuen Passworts.',
  'custLanding.form.password': 'Passwort',
  'custLanding.forgot-password': 'Passwort vergessen?',
  'custLanding.forgot-password.success': 'Deine Anleitung ist in wenigen Augenblicken da!',
  'custLanding.let-get-started': 'Herzlich willkommen!',
  'custLanding.already-have-an-account': 'Du hast bereits ein Konto?',
  'custLanding.just-remembered': 'Dir ist das Password eingefallen?',
  'custlanding.login.error.no-order': 'Keine Sendungen in Zusammenhang mit dieser E-Mail gefunden.',
};
