export default {
  'account.save': 'Išsaugoti',
  'account.address.name': 'Pavadinimas',
  'account.address.address': 'Adresas',
  'account.address.postcode': 'Pašto kodas',
  'account.address.city': 'Miestas',
  'account.address.country': 'Šalis',
  'account.address.province': 'Rajonas',
  'account.address.contactName': 'Kontaktinis asmuo',
  'account.address.contactPhone': 'Kontaktinis telefono numeris',
};
