export default {
  FALLBACK_ERROR_MESSAGE: "Une erreur s'est produite. Veuillez réessayer ultérieurement.",
  COURIER_ERROR_FALLBACK: 'Erreur de messagerie.',
  TOKEN_NOT_FOUND: "Jeton d'authentification introuvable.",
  IDENTITY_VERIFICATION_FAILED: "La vérification d'identité n'a pas pu être effectuée.",
  ACCOUNT_BLOCKED: 'Votre compte est bloqué. Veuillez consulter vos e-mails',
  WRONG_EMAIL_OR_PASS: "L'e-mail et/ou le mot de passe sont incorrects.",
  INVALID_CREDENTIALS: 'Identifiants non valides.',
  INVALID_TOKEN:
    "Session expirée, veuillez demander un nouveau lien de validation d'e-mail dans le portail de retour.",
  MALFORMED_TOKEN: "Jeton d'authentification erroné.",
  CUSTOMER_ALREADY_CREATED: 'Compte client déjà créé. Veuillez vous connecter.',
  SHIPPING_METHOD_NOT_SUPPORTED: "Méthode d'expédition non prise en charge.",
  SHIPMENT_ALREADY_PICKED_UP: 'Expédition déjà enlevée.',
  NO_RATE_FOR_DESTINATION: 'Aucun tarif disponible pour la destination.',
  BUILDING_LABEL_FAILED: "Échec de la création de l'étiquette.",
  COURIER_NOT_SUPPORTED: 'Transporteur non pris en charge.',
  PICKUP_REQUEST_FAILED: "Échec de la demande d'enlèvement.",
  ERROR_TRY_AGAIN_LATER: "Une erreur s'est produite. Veuillez réessayer ultérieurement.",
  EMAIL_ALREADY_REGISTERED: 'E-mail déjà enregistré.',
  INVALID_PASSWORD: 'Mot de passe non valide.',
  LABEL_ERROR: "Erreur d'étiquette.",
  UNDER_MAINTENANCE: 'Maintenance en cours. Outvio sera bientôt de retour en ligne.',
  MERGING_PDFS_FAILED: 'Échec de la fusion des fichiers PDF',
  EXPIRE_TOKEN: "Le jeton d'authentification a expiré",
  GENERIC_MISSING_PARAM: 'Paramètres obligatoires manquants.',
  ORDER_NOT_FOUND: 'Commande non trouvée.',
};
