export default {
  'account.save': 'Zapisz',
  'account.address.name': 'Pseudonim',
  'account.address.address': 'Adres',
  'account.address.postcode': 'Kod pocztowy',
  'account.address.city': 'Miasto',
  'account.address.country': 'Kraj',
  'account.address.province': 'Prowincja',
  'account.address.contactName': 'Osoba do kontaktu',
  'account.address.contactPhone': 'Numer do kontaktu',
};
