export default {
  'orders.package': 'Paka',
  'orders.returns.reasons.size': 'Neder izmērs',
  'orders.returns.reasons.wrong-product': 'Piegādāta nepareizā prece',
  'orders.returns.reasons.wrong-size': 'Piegādāts nepareizais izmērs',
  'orders.returns.reasons.wrong-colour': 'Nepareizā krāsa',
  'orders.returns.reasons.package-broken': 'Bojāts preces iepakojums',
  'orders.returns.reasons.product-broken': 'Prece bojāta/ar defektiem',
  'orders.returns.reasons.product-used': 'Prece lietota',
  'orders.returns.reasons.product-dirty': 'Prece netīra',
  'orders.returns.reasons.do-not-want': 'Es to vairs nevēlos',
  'orders.returns.reasons.exchange': 'Es vēlos apmainīt preci',
  'orders.returns.reasons.other': 'Cits',
  'orders.returns.pickup.form.comments': 'Komentāri',
  'orders.list.empty': 'Pašlaik jums nav neviena pasūtījuma',
  'orders.total': 'Kopā',
  'orders.vat': 'PVN',
  'or.confirm-address': 'APSTIPRINIET SAVU SAŅEMŠANAS ADRESI',
  'or.from': 'No',
  'or.to': 'Līdz',
  'or.return.list.empty': 'Pašlaik jums nav nevienas atgrieztas preces',
  'or.confirm-return': 'APSTIPRINIET SAVU PRECES ATGRIEŠANU',
};
