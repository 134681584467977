import { useContext, useEffect, useState } from 'react';

import { SubscriptionPlan } from 'app-types/core';
import { FormattedMessage } from 'localization';
import { match } from 'ts-pattern';

import currencyToSymbol from '../../../../utils/currencyToSymbol';
import { SubscriptionContext } from '../../PricingPage/hooks/useSubscriptionContext';
import { PricingItem } from '../PricingItem';
import { COUNQUER_PLAN_HASH } from '../constants/hashKeys';
import { useGetPlanFeatureOptions, useGetPlanPricingOptions } from '../hooks/useGetPlanOptions';
import ButtonPricing from '../parts/ButtonPricing';
import NextBilling from '../parts/NextBilling';

type ConquerBlockProps = {
  isActive: boolean;
  onSelectPlan: (plan: SubscriptionPlan) => void;
  onCancelPlanChanging: () => Promise<void>;
  inChangingPlanProcess: boolean;
  nextBillingDate: string | null | undefined;
  isCancelingSubscription: boolean;
  isTrial: boolean;
  shopifyBilling: boolean;
  paymentInPendingProcess: boolean;
};

const ConquerBlock = ({
  isActive,
  onSelectPlan,
  onCancelPlanChanging,
  inChangingPlanProcess,
  nextBillingDate,
  isCancelingSubscription,
  isTrial,
  shopifyBilling,
  paymentInPendingProcess,
}: ConquerBlockProps) => {
  const [isCancelling, setIsCancelling] = useState(false);
  const contextData = useContext(SubscriptionContext);

  const { conquerPlanPrice, currency, conquerPlanDiscountedPrice } = useGetPlanPricingOptions();
  const { conquerPlanFeatureOptions } = useGetPlanFeatureOptions();

  const onSelectHandler = async () => {
    if (
      (inChangingPlanProcess || (isActive && isCancelingSubscription && !isTrial)) &&
      !shopifyBilling
    ) {
      setIsCancelling(true);
      await onCancelPlanChanging();
      setIsCancelling(false);
    } else {
      onSelectPlan(SubscriptionPlan.Conquer);
    }
  };

  const conquerButtonTitle = match<boolean>(true)
    .with(
      isActive && isCancelingSubscription && !isTrial && !shopifyBilling,
      () => 'settings.subs.stay-in-current',
    )
    .with(inChangingPlanProcess && !shopifyBilling, () => 'settings.subs.stay-in-current')
    .otherwise(() => 'settings.subs.upgrade');

  const showButton = !isActive || inChangingPlanProcess || isCancelingSubscription || isTrial;
  const showNextBillingDate =
    isActive && !inChangingPlanProcess && !isCancelingSubscription && !isTrial;
  const actualPrice = isActive ? contextData?.subscription?.amount : conquerPlanPrice;
  const hasDiscountedPrice = conquerPlanDiscountedPrice !== conquerPlanPrice;

  const urlHash = window.location.hash;

  useEffect(() => {
    if (
      urlHash === COUNQUER_PLAN_HASH &&
      !inChangingPlanProcess &&
      !isActive &&
      !paymentInPendingProcess
    ) {
      onSelectPlan(SubscriptionPlan.Conquer);
    }
  }, []);

  return (
    <PricingItem
      isActive={isActive}
      title="Conquer"
      className="rounded-[12px] md:rounded-r-[12px] md:rounded-l-none"
      subtitle={
        <>
          <span className={hasDiscountedPrice ? 'line-through mr-1' : ''}>
            <FormattedMessage
              id="settings.subs.per-month"
              values={{ price: currencyToSymbol(currency, actualPrice || 0) }}
            />
          </span>
          {hasDiscountedPrice && (
            <FormattedMessage
              id="settings.subs.per-month"
              values={{
                price: currencyToSymbol(currency, conquerPlanDiscountedPrice || 0),
              }}
            />
          )}
        </>
      }
      featureList={conquerPlanFeatureOptions}
    >
      <div className="px-6 w-full">
        {showNextBillingDate && <NextBilling nextBillingDate={nextBillingDate} />}

        {showButton && (
          <ButtonPricing
            disabled={paymentInPendingProcess}
            loading={isCancelling}
            onClick={onSelectHandler}
          >
            <FormattedMessage id={conquerButtonTitle} />
          </ButtonPricing>
        )}
      </div>
    </PricingItem>
  );
};

export default ConquerBlock;
