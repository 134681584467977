import { useQuery } from '@apollo/client';
import { QueryHookOptions } from '@apollo/client/react/types/types';
import { StoreCompany } from 'app-types/core';

import { CompanyInfoQuery } from '../../../gql/core/queries';

export function useGetCompanyInfo(options?: QueryHookOptions) {
  const { data, loading, error } = useQuery<{ Store_current: { company: StoreCompany } }>(
    CompanyInfoQuery,
    {
      ...options,
      context: { ...options?.context, clientName: 'core' },
    },
  );

  return {
    data: data?.Store_current?.company,
    loading,
    error,
  };
}
