import React, {
  type Dispatch,
  type InputHTMLAttributes,
  type ReactNode,
  type SetStateAction,
} from 'react';

import { twMerge } from 'tailwind-merge';

export enum InputTextVariant {
  Primary = 'Primary',
  Info = 'Info',
}

export type RegularInputFragmentProps = InputHTMLAttributes<HTMLInputElement> & {
  isComplete?: boolean;
  isLocked?: boolean;
  name: string;
  onIconCtaClick?: (event: React.MouseEvent<HTMLElement>) => void;
  setIsFocused?: Dispatch<SetStateAction<boolean>>;
  isError: boolean;
  inputClassName?: string;
  icon?: ReactNode;
  iconPosition?: 'left' | 'right';
  variant?: InputTextVariant;
  register?: (name: string, mask?: string, options?: any) => any;
  hasIconDivider?: boolean;
  mask?: string;
  maskPlaceholder?: string;
};

export function RegularInputFragment({
  isComplete = false,
  isLocked = false,
  inputClassName,
  iconPosition = 'left',
  variant = InputTextVariant.Primary,
  icon,
  name,
  type = 'text',
  setIsFocused,
  onIconCtaClick,
  isError,
  register,
  disabled,
  onChange,
  mask,
  maskPlaceholder,
  hasIconDivider = false,
  ...otherProps
}: RegularInputFragmentProps) {
  let onBlurRHF: (event: React.FocusEvent<HTMLInputElement, Element>) => void;
  let onChangeRHF: (event: React.ChangeEvent<HTMLInputElement>) => void;
  let restRHF = {};
  if (register) {
    const { onBlur, onChange, ...rest } = register(
      name,
      mask,
      mask ? { placeholder: maskPlaceholder } : {},
    );
    onBlurRHF = onBlur;
    restRHF = rest;
    onChangeRHF = onChange;
  }

  return (
    <input
      type={type}
      data-testid="outvioui--input__input"
      className={twMerge(
        'placeholder:font-inter font-inter relative w-full px-4 z-0 rounded-md text-v2blueGray-800 h-9 text-sm bg-transparent outline-none focus:outline-none',
        inputClassName,
        icon &&
          (onIconCtaClick || hasIconDivider
            ? iconPosition === 'right'
              ? 'pr-[10px] mr-[42px]'
              : 'pl-[10px] ml-[42px]'
            : iconPosition === 'right'
            ? 'pr-[0px] mr-[40px]'
            : 'pl-[0px] ml-[40px]'),
        isLocked && 'placeholder-v2blueGray-400 text-v2blueGray-400',
        isComplete && 'text-primary',
        isError && 'text-v2red',
        variant === InputTextVariant.Info && 'text-v2blue',
        disabled && 'placeholder-v2blueGray-400 text-v2blueGray-400 cursor-not-allowed',
      )}
      {...otherProps}
      {...restRHF}
      onFocus={() => setIsFocused?.(true)}
      onBlur={(event) => {
        onBlurRHF && onBlurRHF(event);
        setIsFocused?.(false);
      }}
      onChange={(event) => {
        if (onChange) {
          onChange(event);
        } else {
          onChangeRHF && onChangeRHF(event);
        }
      }}
      disabled={disabled || isLocked}
    />
  );
}

export default RegularInputFragment;
