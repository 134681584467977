export function Emoji({ ...props }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      transform="rotate(180)"
      viewBox="0 0 24 24"
      {...props}
    >
      <g transform="translate(-24 -24)">
        <rect width="24" height="24" transform="translate(48 24) rotate(90)" fill="none" />
        <path
          d="M1356,1635a9,9,0,1,1-9-9A9,9,0,0,1,1356,1635Z"
          transform="translate(1383 1671) rotate(180)"
          fill="none"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.6"
        />
        <path
          d="M1343.4,1636.8a4.5,4.5,0,0,0,7.2,0m-.9-4.5h.009m-5.409,0h.009m5.841,0a.45.45,0,1,1-.45-.45A.446.446,0,0,1,1350.15,1632.3Zm-5.4,0a.45.45,0,1,1-.45-.45A.446.446,0,0,1,1344.75,1632.3Z"
          transform="translate(1383 1671) rotate(180)"
          fill="none"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.6"
        />
      </g>
    </svg>
  );
}
