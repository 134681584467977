export default {
  'ovc.general.powered-by': 'Zasilany przez',
  'ovc.auth.identity.email.placeholder': 'Email', // custLanding.form.email
  'ovc.auth.identity.cta': 'Dokonaj zwrotu', // landing.make-a-return
  'ovc.auth.order.orderNumber.placeholder':
    'Numer zamówienia lub numer śledzenia przesyłki kurierskiej', // custLanding.form.orderNumber
  'ovc.auth.order.clientInfo.placeholder': 'Kod pocztowy lub ostatnie 5 cyfr numeru telefonu', // custLanding.form.clientInfo
  'ovc.auth.switch-to-identity': 'Zamiast tego zaloguj się za pomocą adresu e-mail', // custLanding.switch-to-identity
  'ovc.auth.switch-to-order': 'Zamiast tego zaloguj się za pomocą informacji o zamówieniu', // custLanding.switch-to-order
  'ovc.auth.tracking.cta': 'Śledź moje zamówienie', // custLanding.tracking.btn
  'ovc.auth.tracking.orderIdentifier.placeholder':
    'Wstaw ID zamówienia, OTN lub numer śledzenia przesyłki', // custLanding.tracking.orderIdentifier
  'ovc.auth.tracking.postcode.placeholder': 'Wpisz kod pocztowy miejsca przeznaczenia', // custLanding.tracking.postcode
  'ovc.listing.page.all': 'Wszystkie zamówienia',
  'ovc.listing.page.in-transit': 'Zamówienia w tranzycie',
  'ovc.listing.page.returns': 'Zwroty',
  'ovc.listing.header.order': 'zamówienie',
  'ovc.listing.header.date': 'data',
  'ovc.listing.header.total': 'suma',
  'ovc.listing.header.choose-product': 'Wybierz przedmiot:',
  'ovc.listing.listing-track-cta': 'śledź',
  'ovc.listing.listing-return-cta': 'zwróć',
  'ovc.listing.listing-external-action-cta': 'zgłoś naprawę gwarancyjną',
  'ovc.listing.empty-list': 'Brak zamówień do wyświetlenia',
  'ovc.listing.return-status.store-non-returnable': 'Nie może być zwrócony',
  'ovc.listing.return-status.already-returned': 'Zwrócono w dniu {date}',
  'ovc.listing.return-status.no-longer-returnable': 'Nie podlega już zwrotowi',
  'ovc.listing.return-status.returnable': 'Zwróć przed {date}',
  'ovc.return-cart.refund-method-credit': `Wybierz tę opcję w przypadku, gdy chcesz wykorzystać zwrot pieniędzy na kolejne zakupy u nas!{lineBreak}
    {lineBreak}
    Wyślemy Ci kartę podarunkową z kwotą zwrotu.`,
  'ovc.return-cart.inst.outvio.heading.confirm-pickup': 'potwierdzenie adresu odbioru i zwrotu',
  'ovc.return-cart.inst.outvio.heading.confirm-return': 'potwierdź swój zwrot',
  'ovc.return-cart.address.heading': 'wprowadź nowy adres lub wybierz istniejący:',
  'ovc.return-cart.socket-error':
    'Błąd systemu kuriera:  {error}. Proszę spróbować ponownie później',
};
