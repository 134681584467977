export default {
  'custLanding.password.title': '¿Te has olvidado de la contraseña?',
  'custLanding.signup.btn': 'Regístrate',
  'custLanding.login.btn': 'Entra',
  'custLanding.password.btn': 'Reiniciar contraseña',
  'custLanding.switch-to-login': 'Entra con una cuenta existente',
  'custLanding.switch-to-signup': 'Registra una nueva cuenta',
  'custLanding.auth.text.password':
    'Introduce la dirección de email asociada a tu cuenta y te enviaremos instrucciones para obtener una nueva contraseña.',
  'custLanding.form.password': 'Contraseña',
  'custLanding.forgot-password': '¿Olvidaste tu contraseña?',
  'custLanding.forgot-password.success': '¡Tus instrucciones llegarán en breve!',
  'custLanding.let-get-started': '¡Vamos a empezar!',
  'custLanding.already-have-an-account': '¿Ya tienes una cuenta?',
  'custLanding.just-remembered': '¿Acabas de recordar tu contraseña?',
  'custlanding.login.error.no-order':
    'No se encuentran pedidos que puedan ser devueltos asociados a esta dirección de email.',
};
