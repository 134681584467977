import { useEffect, useState } from 'react';

import { useForm } from 'react-hook-form';

import { yupResolver } from '@hookform/resolvers/yup';
import { useQueryClient } from '@tanstack/react-query';
import { StoreCompany } from 'app-types/core';
import {
  GET_ESTIMATED_SUB_BILL_KEY,
  useGetCompanyInfo,
  useUpdateCompanyInfo,
  validateVat,
} from 'data-layer';
import { useIntl } from 'localization';

import companyInfoValidationSchema from '../validation/companyInfoValidationSchema';

const useSubmitVatFormHandler = (onClose: () => void) => {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const { data: company, loading: isLoading } = useGetCompanyInfo();
  const queryClient = useQueryClient();

  const { updateCompanyInfo } = useUpdateCompanyInfo();

  const intl = useIntl();

  const formMethods = useForm<StoreCompany>({
    resolver: yupResolver(companyInfoValidationSchema(intl) as any) as any,
  });
  const { handleSubmit: handleFormSubmit, reset, setError } = formMethods;

  useEffect(() => {
    reset({
      vat: company?.vat ?? '',
      registrationNumber: company?.registrationNumber ?? '',
      name: company?.name ?? '',
      address: company?.address ?? '',
      city: company?.city ?? '',
      postcode: company?.postcode ?? '',
      countryCode: company?.countryCode ?? '',
    });
  }, [isLoading]);

  const submitHandler = handleFormSubmit(async (updatedInfo) => {
    setIsSubmitting(true);

    const dataToSend = {
      ...updatedInfo,
      vat: company?.vat ?? '',
    };

    if (updatedInfo?.vat) {
      const vatValidation = await validateVat({ vat: updatedInfo.vat });

      if (vatValidation.result) {
        dataToSend.vat = updatedInfo.vat;

        await updateCompanyInfo(dataToSend);

        queryClient.removeQueries({
          predicate: (query) => {
            return query.queryKey[0] === GET_ESTIMATED_SUB_BILL_KEY;
          },
        });

        onClose();
      } else {
        setError('vat', { message: intl.formatMessage({ id: 'settings.company.vat.invalid' }) });
        setIsSubmitting(false);
      }
    } else {
      await updateCompanyInfo(dataToSend);

      onClose();
    }
  });

  return {
    submitHandler,
    isSubmitting,
    isLoading,
    formMethods,
  };
};

export { useSubmitVatFormHandler };
