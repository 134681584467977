export function InsertLinkShortcut({ ...props }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="1em" height="1em" {...props}>
      <g id="Group_10969" data-name="Group 10969" transform="translate(-2.339 -2.375)">
        <g
          id="Rectangle_8501"
          data-name="Rectangle 8501"
          transform="translate(2.339 2.375)"
          fill="#849fad"
          stroke="#707070"
          strokeWidth="1"
          opacity="0"
        >
          <rect width="24" height="24" stroke="none" />
          <rect x="0.5" y="0.5" width="23" height="23" fill="none" />
        </g>
        <g id="Group_10994" data-name="Group 10994" transform="translate(2.339 2.375)">
          <path
            id="Path_39097"
            data-name="Path 39097"
            d="M7.038,16.538a4.374,4.374,0,0,1-3.211-1.327A4.373,4.373,0,0,1,2.5,12,4.375,4.375,0,0,1,3.828,8.789,4.373,4.373,0,0,1,7.038,7.462h3.019a.75.75,0,1,1,0,1.5H7.037A3.037,3.037,0,0,0,4,12a3.037,3.037,0,0,0,3.037,3.038h3.02a.75.75,0,1,1,0,1.5ZM9,12.75a.75.75,0,1,1,0-1.5h6a.75.75,0,1,1,0,1.5Zm4.942,3.789a.75.75,0,1,1,0-1.5h3.02A3.037,3.037,0,0,0,20,12a3.037,3.037,0,0,0-3.037-3.038h-3.02a.75.75,0,1,1,0-1.5h3.019A4.532,4.532,0,0,1,21.5,12a4.533,4.533,0,0,1-4.538,4.539Z"
            fill="#849fad"
          />
        </g>
      </g>
    </svg>
  );
}
