import { useContext } from 'react';

import { useController } from 'react-hook-form';

import { SubscriptionPlan } from 'app-types/core';
import { FormattedMessage, useIntl } from 'localization';

import { Copy } from '../../../atoms';
import { ItemCard } from '../../../molecules';
import { useGetSpecificPlanPrice } from '../../Pricing/hooks/useGetPlanOptions';
import { SubscriptionContext } from '../../PricingPage/hooks/useSubscriptionContext';

type PlansProps = {
  initialSelectedPlan?: SubscriptionPlan;
  isShopifyBilling: boolean;
  deskOnly?: boolean;
  isSubmitting: boolean;
};

const Plans = ({ initialSelectedPlan, isShopifyBilling, deskOnly, isSubmitting }: PlansProps) => {
  const { getPricingData } = useGetSpecificPlanPrice();
  const { getPrice, getOrderProcessingFee, deskPrice } = getPricingData();
  const { formatMessage } = useIntl();

  const contextData = useContext(SubscriptionContext);

  const { field: planField } = useController({ name: 'plan' });
  const { field: deskField } = useController({ name: 'desk' });

  const isInitiallySelectedConquer = initialSelectedPlan === SubscriptionPlan.Conquer;
  const selectedPlanIsConquer = planField.value === SubscriptionPlan.Conquer;
  const selectedPlanIsGrow = planField.value === SubscriptionPlan.Grow;

  const conquerDescription = `${formatMessage(
    { id: 'settings.pricing.per-processed-order' },
    {
      value: `${getPrice(SubscriptionPlan.Conquer, 'monthly', true)} + ${getOrderProcessingFee()}`,
    },
  )}`;
  const growDescription = `${formatMessage(
    { id: 'settings.pricing.per-processed-order' },
    { value: `${getPrice(SubscriptionPlan.Grow, 'monthly', true)} + ${getOrderProcessingFee()}` },
  )}`;
  const deskDescription = `${formatMessage(
    { id: 'settings.pricing.per-processed-order' },
    {
      value: `${deskPrice} + ${getOrderProcessingFee('deskOnly')}`,
    },
  )}`;

  const plans: {
    plan: SubscriptionPlan;
    isSelected: boolean;
    description: string;
  }[] = [
    {
      plan: SubscriptionPlan.Conquer,
      isSelected: selectedPlanIsConquer,
      description: conquerDescription,
    },
  ];

  const grow = {
    plan: SubscriptionPlan.Grow,
    isSelected: selectedPlanIsGrow,
    description: growDescription,
  };

  if (!isInitiallySelectedConquer || (deskOnly && !isInitiallySelectedConquer)) {
    plans.push(grow);
  }

  const hasActivatedDeskWithoutCancelling =
    !!contextData?.subscription.isActive &&
    !!contextData?.subscription?.addons?.some((addon) => addon.type === 'desk' && !addon.expiredOn);

  return (
    <div className="mb-8">
      <Copy type="copy2" noColor className="ml-2 mb-4 text-v2blueGray-500">
        <FormattedMessage id="settings.payment.plan-selected" />
      </Copy>

      <div className="flex flex-col gap-2 mb-8">
        {plans.map(({ plan, isSelected, description }, index) => (
          <ItemCard
            disabled={isSubmitting}
            key={`upgrade-plan-${plan}-${index}`}
            isSelected={isSelected}
            theme="info"
            onSelect={() => planField.onChange(plan)}
            type="radio"
            title={<div className="first-letter:capitalize">{plan}</div>}
            description={description}
          />
        ))}
      </div>

      {!isShopifyBilling && (
        <>
          <Copy type="copy2" noColor className="ml-2 mb-4 text-v2blueGray-500">
            <FormattedMessage id="settings.payment.add-ons" />
          </Copy>
          <ItemCard
            disabled={hasActivatedDeskWithoutCancelling || deskOnly || isSubmitting}
            isSelected={deskField.value}
            theme="info"
            onSelect={() => deskField.onChange(!deskField.value)}
            type="checkbox"
            title="Outvio Desk"
            description={deskDescription}
          />
        </>
      )}
    </div>
  );
};

export default Plans;
