import { FullIUserRequest, IUser, UserPopulated } from 'app-types';
import { Languages } from 'app-types/desk';

import { AgentStatuses } from '../../../constants';
import request from '../../request';

export type SupportAgentsResponse = {
  success: boolean;
  data: Array<IUser>;
};

export const getUser = (): Promise<UserPopulated> => {
  return request(
    //@ts-ignore
    `${import.meta.env.VITE_OUTVIO_API_URL}/current-user`,
    {
      method: 'GET',
      secure: true,
    },
  ).then((res) => (res as any)?.user);
};

export type UserData = {
  email: string;
  firstname: string;
  lastname: string;
  newPasswordCheck: string;
  newPassword: string;
  oldPassword: string;
  position: string;
  signatures: {
    language: Languages;
    signature: string;
  }[];
  emailOnlySignature: boolean;
};

export const updateUser = (data: Partial<UserData>): Promise<any> =>
  request(
    //@ts-ignore
    `${import.meta.env.VITE_OUTVIO_API_URL}/user/agent/me`,
    {
      method: 'PUT',
      data,
      secure: true,
    },
  ).then((res) => res);

export type UserPasswordData = {
  newPassword: string;
  newPasswordCheck: string;
  oldPassword: string;
};

export const updateUserPassword = (data: UserPasswordData): Promise<any> =>
  request(
    //@ts-ignore
    `${import.meta.env.VITE_OUTVIO_API_URL}/user/change-password`,
    {
      method: 'POST',
      data,
      secure: true,
    },
  ).then((res) => (res as any)?.user);

export const getSupportAgents = (): Promise<Array<IUser> | undefined> => {
  return request<SupportAgentsResponse>(
    //@ts-ignore
    `${import.meta.env.VITE_OUTVIO_API_URL}/user/support-agents`,
    {
      method: 'GET',
      secure: true,
    },
  ).then((response: SupportAgentsResponse | undefined) => response?.data);
};

type Agent = {
  firstname: string;
  lastname: string;
  email: string;
  position: string;
  viewAllFolder: boolean;
  grantAdminAccess: boolean;
};

export const createSupportAgent = (data: Agent) =>
  request(
    //@ts-ignore
    `${import.meta.env.VITE_OUTVIO_API_URL}/user/agent`,
    {
      method: 'POST',
      data,
      secure: true,
    },
  );

export const updateSupportAgent = (
  id: string,
  data: Pick<Agent, 'position' | 'viewAllFolder' | 'grantAdminAccess'>,
) =>
  request(
    //@ts-ignore
    `${import.meta.env.VITE_OUTVIO_API_URL}/user/agent/${id}`,
    {
      method: 'PUT',
      data,
      secure: true,
    },
  );

export const EditAgent = (
  data: FullIUserRequest,
): Promise<
  | {
      success: boolean;
    }
  | undefined
> => {
  if (!data._id) {
    return new Promise((resolve, reject) => {
      reject('No ID provided');
    });
  }
  return request(
    //@ts-ignore
    `${import.meta.env.VITE_OUTVIO_API_URL}/user/agent/${data._id}`,
    {
      method: 'PUT',
      secure: true,
      data,
    },
  );
};

export const DeleteAgent = (
  id: string,
): Promise<
  | {
      success: boolean;
    }
  | undefined
> => {
  if (!id) {
    return new Promise((resolve, reject) => {
      reject('No ID provided');
    });
  }
  return request(
    //@ts-ignore
    `${import.meta.env.VITE_OUTVIO_API_URL}/user/agent/${id}`,
    {
      method: 'DELETE',
      secure: true,
    },
  );
};

export const CreateAgent = (
  agent: FullIUserRequest,
): Promise<
  | {
      success: boolean;
    }
  | undefined
> =>
  request(
    //@ts-ignore
    `${import.meta.env.VITE_OUTVIO_API_URL}/user/agent`,
    {
      method: 'POST',
      secure: true,
      data: agent,
    },
  );

export type AgentStatusData = { status: AgentStatuses };

export const updateAgentStatus = (data: AgentStatusData) =>
  request(
    //@ts-ignore
    `${import.meta.env.VITE_OUTVIO_API_URL}/user/agent/status`,
    {
      method: 'PUT',
      data,
      secure: true,
    },
  );
