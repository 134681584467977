export enum EDIT_MODE_TABS {
  PORTAL_DOMAINS,
  PAGE_TITLE_AND_FAVICON,
  LOGO,
  COLOR_PALETTE,
  LOGIN_PAGE,
  TRACKING,
  ORDER_STATUS,
  SUGGESTED_PRODUCTS,
  BANNER,
  DELIVERY_INFORMATION,
  ORDER_INFORMATION,
  QUESTIONS,
  RATING,
  CUSTOM_URL,
}

export const EDIT_MODE_TABS_INFO = [
  {
    index: 1,
    id: EDIT_MODE_TABS.PORTAL_DOMAINS,
    name: 'portalDomains',
  },
  {
    index: 2,
    id: EDIT_MODE_TABS.PAGE_TITLE_AND_FAVICON,
    name: 'pageTitleAndFavicon',
  },
  {
    index: 3,
    id: EDIT_MODE_TABS.LOGO,
    name: 'logo',
  },
  {
    index: 4,
    id: EDIT_MODE_TABS.COLOR_PALETTE,
    name: 'colorPalette',
  },
  {
    index: 5,
    id: EDIT_MODE_TABS.LOGIN_PAGE,
    name: 'loginPageInfo',
  },
  {
    index: 6,
    id: EDIT_MODE_TABS.TRACKING,
    name: 'tracking',
  },
  {
    index: 7,
    id: EDIT_MODE_TABS.ORDER_STATUS,
    name: 'orderStatus',
  },
  {
    index: 8,
    id: EDIT_MODE_TABS.SUGGESTED_PRODUCTS,
    name: 'suggestedProducts',
  },
  {
    index: 9,
    id: EDIT_MODE_TABS.BANNER,
    name: 'banner',
  },
  // {
  //   index: 7,
  //   id: EDIT_MODE_TABS.DELIVERY_INFORMATION,
  //   name: 'deliveryInformation',
  // },
  // {
  //   index: 8,
  //   id: EDIT_MODE_TABS.ORDER_INFORMATION,
  //   name: 'orderInformation',
  // },
  {
    index: 10,
    id: EDIT_MODE_TABS.QUESTIONS,
    name: 'questions',
  },
  {
    index: 11,
    id: EDIT_MODE_TABS.RATING,
    name: 'rating',
  },
  // {
  //   index: 11,
  //   id: EDIT_MODE_TABS.CUSTOM_URL,
  //   name: 'customUrl',
  // },
];

export interface IModuleTopValue {
  id: EDIT_MODE_TABS;
  top: number;
}

export const DEFAULT_MODULE_TOP_VALUES: IModuleTopValue[] = [
  {
    id: EDIT_MODE_TABS.PORTAL_DOMAINS,
    top: 0,
  },
  {
    id: EDIT_MODE_TABS.PAGE_TITLE_AND_FAVICON,
    top: 0,
  },
  {
    id: EDIT_MODE_TABS.LOGO,
    top: 0,
  },
  {
    id: EDIT_MODE_TABS.COLOR_PALETTE,
    top: 0,
  },
  {
    id: EDIT_MODE_TABS.LOGIN_PAGE,
    top: 0,
  },
  {
    id: EDIT_MODE_TABS.TRACKING,
    top: 0,
  },
  {
    id: EDIT_MODE_TABS.ORDER_STATUS,
    top: 0,
  },
  {
    id: EDIT_MODE_TABS.SUGGESTED_PRODUCTS,
    top: 0,
  },
  {
    id: EDIT_MODE_TABS.BANNER,
    top: 0,
  },
  // {
  //   id: EDIT_MODE_TABS.DELIVERY_INFORMATION,
  //   top: 0,
  // },
  // {
  //   id: EDIT_MODE_TABS.ORDER_INFORMATION,
  //   top: 0,
  // },
  {
    id: EDIT_MODE_TABS.QUESTIONS,
    top: 0,
  },
  {
    id: EDIT_MODE_TABS.RATING,
    top: 0,
  },
];
