export default {
  'orders.package': 'Pakend',
  'orders.returns.reasons.size': 'Suurus ei sobi',
  'orders.returns.reasons.wrong-product': 'Vale toode',
  'orders.returns.reasons.wrong-size': 'Vale suurus',
  'orders.returns.reasons.wrong-colour': 'Vale värv',
  'orders.returns.reasons.package-broken': 'Toote pakend purunenud ',
  'orders.returns.reasons.product-broken': 'Toode purunenud/defektiga',
  'orders.returns.reasons.product-used': 'Kasutatud toode',
  'orders.returns.reasons.product-dirty': 'Toode määrdunud',
  'orders.returns.reasons.do-not-want': 'Muutsin oma meelt',
  'orders.returns.reasons.exchange': 'Soovin toote vahetada',
  'orders.returns.reasons.other': 'Muu',
  'orders.returns.pickup.form.comments': 'Kommentaarid',
  'orders.list.empty': 'Sul ei ole hetkel ühtegi aktiivset tellimust',
  'orders.total': 'Kokku',
  'orders.vat': 'KM',
  'or.confirm-address': 'KINNITA KORJE AADRESS',
  'or.from': 'Alates',
  'or.to': 'Kuni',
  'or.return.list.empty': 'Hetkel tagastused puuduvad',
  'or.confirm-return': 'KINNITA TAGASTUS',
};
