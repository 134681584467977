import React from 'react';

export type ExclamationRoundedFilledProps = React.HTMLProps<SVGSVGElement>;

export function ExclamationRoundedFilled({ ...props }: ExclamationRoundedFilledProps) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 20 20" {...props}>
      <g transform="translate(-1112 -11372)">
        <g transform="translate(1110.864 11371.037)">
          <path
            d="M11.135,15.552a1.044,1.044,0,0,0,.739-1.774,1.053,1.053,0,0,0-1.474,0,1.011,1.011,0,0,0-.3.74,1.015,1.015,0,0,0,1.034,1.034Zm0-3.7a.992.992,0,0,0,1-1V7.445a1,1,0,1,0-2.007,0v3.407a.992.992,0,0,0,1,1Zm0,8.107a8.773,8.773,0,0,1-3.514-.708,8.979,8.979,0,0,1-4.778-4.778,8.773,8.773,0,0,1-.708-3.514,8.773,8.773,0,0,1,.708-3.514A8.98,8.98,0,0,1,7.622,2.671a8.773,8.773,0,0,1,3.514-.708,8.773,8.773,0,0,1,3.514.708,8.979,8.979,0,0,1,4.778,4.778,8.773,8.773,0,0,1,.708,3.514,8.773,8.773,0,0,1-.708,3.514,8.979,8.979,0,0,1-4.778,4.778A8.773,8.773,0,0,1,11.136,19.963Z"
            fill="currentColor"
          />
        </g>
      </g>
    </svg>
  );
}
