export enum AttachmentType {
  Csv = 'csv',
  Pdf = 'pdf',
  Image = 'image',
  Text = 'text',
  Json = 'json',
  Video = 'video',
  Audio = 'audio',
  Message = 'message',
  Sheet = 'sheet',
  Zip = 'zip',
}

export enum AttachmentError {
  FileTooLarge = 'fileTooLarge',
}

export const TAG_COLORS = [
  'gray',
  'cyan',
  'blue',
  'orange',
  'red',
  'green',
  'pink',
  'violet',
] as const;

export type TagColor = (typeof TAG_COLORS)[number] | 'random';

export enum ClientInfoDataTypes {
  Stats = 'stats',
  Contacts = 'contacts',
  Address = 'address',
  Tickets = 'tickets',
}

export enum ClientInfoStatTypes {
  Client = 'client',
  Courier = 'courier',
}

export enum ListItemType {
  Primary = 'primary',
  Secondary = 'seconday',
  Tertiary = 'tertiary',
}

export const ACCEPTED_IMAGE_FILE_EXTENSIONS = {
  'image/gif': ['.gif'],
  'image/avif': ['.avif'],
  'image/apng': ['.apng'],
  'image/webp': ['.webp'],
  'image/svg': ['.svg'],
  'image/pjp': ['.pjp'],
  'image/pjpeg': ['.pjpeg'],
  'image/jfif': ['.jfif'],
  'image/bmp': ['.bmp'],
  'image/png': ['.png'],
  'image/jpeg': ['.jpg', '.jpeg'],
};

export const ACCEPTED_FILE_EXTENSIONS = {
  ...ACCEPTED_IMAGE_FILE_EXTENSIONS,
  'text/csv': ['.csv'],
  'application/pdf': ['.pdf'],
  'text/plain': ['.txt'],
  'application/json': ['.json'],
  'video/mp4': ['.mp4'],
  'audio/mpeg': ['.mp3'],
  'message/rfc822': ['.eml'],
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
  'application/vnd.ms-excel.sheet.macroenabled.12': ['.xlsm'],
  'application/zip': ['.zip'],
  'text/xml': ['.xml'],
};
