import { ReactNode, memo, useMemo } from 'react';

import { Lock } from 'icons';
import { twMerge } from 'tailwind-merge';
import {
  Button,
  ButtonVariant,
  DashboardCard,
  DashboardCardHead,
  DashboardCardTheme,
} from 'ui/atoms';

import './DashboardLockedCard.css';

export enum DashboardLockedCardPosition {
  top = 'top',
  bottom = 'bottom',
}

export interface DashboardCardLock {
  title?: ReactNode;
  description?: ReactNode;
  theme?: DashboardCardTheme;
  children?: ReactNode;
  className?: string;
  lockPosition?: DashboardLockedCardPosition;
  onUnlock?: () => void;
  unlockText?: ReactNode;
  head?: ReactNode;
  isSubTheme?: boolean;
}

function DashboardLockedCardBase({
  title,
  description,
  children,
  theme,
  lockPosition,
  className,
  onUnlock,
  unlockText,
  head,
  isSubTheme,
}: DashboardCardLock) {
  const btnTheme = useMemo(() => {
    return (theme && ![DashboardCardTheme.black, DashboardCardTheme.gray].includes(theme)
      ? theme
      : undefined) as unknown as ButtonVariant;
  }, []);

  return (
    <DashboardCard
      theme={theme}
      className={className}
      headClass="flex-1"
      isSubTheme={isSubTheme}
      head={
        <div className="relative h-full">
          {head ? (
            head
          ) : (
            <>
              <DashboardCardHead title={title} description={description} />
              <Lock
                className={twMerge(
                  `db-lock-icon`,
                  lockPosition === DashboardLockedCardPosition.top ? 'top-px' : 'bottom-0',
                )}
              />
            </>
          )}
        </div>
      }
    >
      {onUnlock || children ? (
        <div className="flex justify-between">
          <div>{children}</div>
          {onUnlock && unlockText ? (
            <Button onClick={onUnlock} theme={btnTheme}>
              {unlockText}
            </Button>
          ) : null}
        </div>
      ) : null}
    </DashboardCard>
  );
}

export const DashboardLockedCard = memo(DashboardLockedCardBase);
