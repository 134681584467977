import { ReactNode, memo } from 'react';

import { OpenInNew } from 'icons';
import { twMerge } from 'tailwind-merge';

export interface IOrderHistoryItem {
  orderNumber?: string;
  orderStatus?: ReactNode;
  courierSrc?: string;
  color?: 'orange' | 'gray' | 'cyan';
  isFirst?: boolean;
  isLast?: boolean;
  link?: string;
}
const OrderHistoryItem = memo(
  ({
    isFirst = false,
    isLast = false,
    orderNumber,
    link,
    orderStatus,
    courierSrc,
    color = 'cyan',
  }: IOrderHistoryItem) => {
    return (
      <div className="gap-4 flex items-stretch justify-between font-inter">
        <div className="flex gap-4 truncate">
          <div className="flex flex-col items-center">
            <div
              className={twMerge(
                'bg-v2blueGray-200 h-full w-px -translate-x-1/2',
                isFirst && 'bg-transparent',
              )}
            />
            <div className="bg-v2blueGray-200 flex-shrink-0 rounded-full w-2 h-2" />
            <div
              className={twMerge(
                'bg-v2blueGray-200 h-full w-px -translate-x-1/2',
                isLast && 'bg-transparent',
              )}
            />
          </div>
          <div className="flex flex-col gap-px text-sm py-2.5 truncate">
            <div className="font-semibold truncate">{orderNumber}</div>
            <div
              className={twMerge(
                'font-medium truncate',
                color === 'cyan' && 'text-primary',
                color === 'gray' && 'text-v2blueGray-400',
                color === 'orange' && 'text-v2orange',
              )}
            >
              {orderStatus}
            </div>
          </div>
        </div>
        <div className="h-6 flex items-center justify-center self-center gap-1 flex-shrink-0">
          {courierSrc && <img src={courierSrc} loading="lazy" className="h-full" />}
          <a
            target="_blank"
            href={link}
            className="w-9 h-9 flex justify-center items-center rounded-10  hover:bg-v2blueGray-50 cursor-pointer"
          >
            <OpenInNew width="18" height="18" className="text-v2blueGray-550" />
          </a>
        </div>
      </div>
    );
  },
);

export { OrderHistoryItem };
