import { CurrencyValidText } from 'return-portal-ui/utils/convertTextCurrency';

import { CourierTypes } from '../../returns';
import { ReturnActions } from '../../returns';
import { ReturnMethods } from '../../returns';

export interface IPortalProductResponse {
  _id: string;
  createdAt: string;
  name: string;
  variant: string;
  variantId: string;
  sku: string;
  quantity: number;
  price: number;
  discountPrice?: number;
  discount?: number;
  pictureUrl: string;
  returnData: {
    isReturnable: boolean;
    quantity: number;
    returnedQuantity: number;
    availableQuantity: number;
    returnTillDate: string;
    inTransitQuantity: number;
  };
}
export interface IExchangeProduct {
  name?: string | null;
  variant?: string | null;
  price?: string | null;
  pictureUrl?: string | null;
  quantity?: number;
  originalId?: string | null;
  variantId?: string | null;
  currency?: string;
  description?: string;
  compareAtPrice?: string;
  maxQuantity?: string;
}

export interface IPortalProduct {
  _id: string;
  originalId?: string;
  name: string;
  variant: string;
  sku: string;
  quantity: number;
  price: number;
  discountPrice?: number;
  discount?: number;
  pictureUrl: string;
  orderId: string;
  reason: string;
  variantId?: string;
  action: ReturnActions;
  exchangeProducts?: IExchangeProduct[];
  description: string;
  attachments: { originalname: string; location: string }[];
  currency: CurrencyValidText;
  isShowOnAnotherProductPage?: boolean;
  searchWord?: string;
}

export interface IPortalReturnMethod {
  id: string;
  returnMethod: string;
  type: string;
}

export interface IReturnAddress {
  phone?: string;
  address: string;
  city: string;
  postcode: string;
  countryCode: string;
}

interface IInstructionSet {
  labels: {
    body: string;
    language: string;
  }[];
}
export interface IPortalReturnMethods {
  pickupInfo: IReturnAddress;
  couriers: {
    returnMethod?: ReturnMethods;
    type: CourierTypes | ReturnMethods;
    id: string;
    courierAlias?: string;
    name?: string;
    dropOffPointUrl?: string;
    totalPayment: number;
    instructionSet?: IInstructionSet;
  }[];
  returnValue: number;
  newItemsValue?: number;
  allowCustomerCourier: boolean;
  allowCustomerDeliverToStore: boolean;
  allowRefundAsCredit: boolean;
  allowRefundOriginalPayment: boolean;
  warehouseAddress: IReturnAddress;
  refundCreditOptions: {
    hasFreeShipping: boolean;
  };
  hasKeepItem?: boolean;
  deliverToStoreInstructionSet?: IInstructionSet;
  customerCourierInstructionSet?: IInstructionSet;
  deliverToStoreDropOffPointsUrl?: string;
  customCourierDropOffPointUrl?: string;
  refundValues: any;
  rawRefundValues: {
    bonusValue: number;
    originalPaymentMethod: number;
    storeCredits: number;
  };
}

export const enum PortalRoutes {
  login = 'login',
  orderList = 'my-orders',
  reasonSelection = 'new-return',
  exchangeForAnotherVariant = 'exchange-for-another-variant',
  exchangeForAnotherProduct = 'exchange-for-another-product',
  methodSelection = 'method-selection',
  summary = 'summary',
  confirmation = 'confirmation',
  preview = 'preview',
}
