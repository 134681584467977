import { CustomerSupportedLanguage2Letter, SupportedLanguage } from '../types/CustomerGeneral';
import { CustomerStoreData } from '../types/CustomerStoreData';

export const LANG_TO_LOCALE: Record<CustomerSupportedLanguage2Letter, SupportedLanguage> = {
  es: 'es-ES',
  en: 'en-EN',
  et: 'et-EE',
  fr: 'fr-FR',
  it: 'it-IT',
  de: 'de-DE',
  lt: 'lt-LT',
  lv: 'lv-LV',
  fi: 'fi-FI',
  pt: 'pt-PT',
  pl: 'pl-PL',
  ca: 'ca-ES',
  sv: 'sv-SE',
  da: 'da-DK',
  nl: 'nl-NL',
  bg: 'bg-BG',
  hr: 'hr-HR',
  cs: 'cs-CZ',
  el: 'el-GR',
  hu: 'hu-HU',
  ga: 'ga-IE',
  ro: 'ro-RO',
  sk: 'sk-SK',
  sl: 'sl-SI',
  no: 'no-NO',
};

// nl-NL, bg-BG, hr-HR, cs-CZ, el-GR, hu-HU, ga-IE, ro-RO, sk-SK, sl-SI, no-NO
export const LOCALE_TO_LANG: Record<SupportedLanguage, CustomerSupportedLanguage2Letter> = {
  'es-ES': 'es',
  'en-EN': 'en',
  'et-EE': 'et',
  'fr-FR': 'fr',
  'it-IT': 'it',
  'de-DE': 'de',
  'lt-LT': 'lt',
  'lv-LV': 'lv',
  'fi-FI': 'fi',
  'pt-PT': 'pt',
  'pl-PL': 'pl',
  'ca-ES': 'ca',
  'sv-SE': 'sv',
  'da-DK': 'da',
  'nl-NL': 'nl',
  'bg-BG': 'bg',
  'hr-HR': 'hr',
  'cs-CZ': 'cs',
  'el-GR': 'el',
  'hu-HU': 'hu',
  'ga-IE': 'ga',
  'ro-RO': 'ro',
  'sk-SK': 'sk',
  'sl-SI': 'sl',
  'no-NO': 'no',
};

export const DEFAULT_RETURN_REASONS = [
  'orders.returns.reasons.size',
  'orders.returns.reasons.wrong-product',
  'orders.returns.reasons.wrong-size',
  'orders.returns.reasons.wrong-colour',
  'orders.returns.reasons.package-broken',
  'orders.returns.reasons.product-broken',
  'orders.returns.reasons.product-used',
  'orders.returns.reasons.product-dirty',
  'orders.returns.reasons.do-not-want',
  'orders.returns.reasons.exchange',
  'orders.returns.reasons.other',
];

export const EMPTY_STORE_DATA: CustomerStoreData = {
  name: '',
  url: '',
  slug: '',
  printingReferenceType: 'ID' as const,
  customer: { identityVerification: true, orderVerification: false },
  feature: {
    customerPortal: {
      available: true,
      banner: true,
      customisable: false,
      languages: 1,
      watermark: true,
      customTexts: false,
    },
    customDomains: false,
  },
  languages: [],
  return: {
    customerAllowedMethods: [],
    customerCanDefineReturnPackaging: false,
    days: 3,
    toClientShopUrl: '',
    disableDefaultReasons: false,
    useCustomReasons: false,
    reimbursement: 'SAME_AS_PAYMENT' as const,
    customReasons: [],
    termsAndConditionsLink: '',
    hasButtonToManageProductExternally: false,
  },
  general: {
    groupProductsBy: 'sku',
  },
  portalSettings: {
    logo: null,
    desktopBg: '',
    mobileBg: '',
    trackingDesktopBg: null,
    trackingMobileBg: null,
    tracking: {
      right: [],
      left: [],
      title: {},
    },
    returns: {
      right: [],
      left: [],
      title: {},
    },
    colors: {
      header: '',
      primaryText: '',
      primaryBg: '',
      secondaryBg: '#e6e6e6', // needed for OVCLoader before data arrives
      btnText: '',
      btnBg: '#262626', // needed for OVCLoader before data arrives
      alertColor: '',
      mapBase: '',
      mapPath: '',
      mapText: '',
      mapPin: '',
    },
    customLocalizations: {},
    url: '',
    mobileImg: '',
    desktopImg: '',
    orientation: 'vertical' as const,
    useOutvioSeasonalMarkers: false,
    useCustomMapMarker: false,
    useCustomMapColors: false,
    customMapMarker: '',
    favicon: '',
    pageTitle: '',
    enableMapOnTrackingPage: false,
    mapFeatureExpireAtTheEndOfMonth: false,
  },
};
