export default {
  'account.save': 'Tallenna',
  'account.address.name': 'Alias',
  'account.address.address': 'Osoite',
  'account.address.postcode': 'Postinumero',
  'account.address.city': 'Kaupunki',
  'account.address.country': 'Maa',
  'account.address.province': 'Maakunta',
  'account.address.contactName': 'Yhteyshenkilö',
  'account.address.contactPhone': 'Yhteyshenkilön puhelinnumero',
};
