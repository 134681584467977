import React from 'react';

import ReactDOM from 'react-dom/client';

import * as Sentry from '@sentry/react';

import { Apps } from './Apps';
import './index.css';

Sentry.init({
  release: 'sentry@0.0.17',
  ignoreErrors: [
    /**
     * Thrown when firefox prevents an add-on from refrencing a DOM element that has been removed.
     * This can also be filtered by enabling the browser extension inbound filter
     */
    "TypeError: can't access dead object",
    /**
     * React internal error thrown when something outside react modifies the DOM
     * This is usually because of a browser extension or Chrome's built-in translate
     */
    // "NotFoundError: Failed to execute 'removeChild' on 'Node': The node to be removed is not a child of this node.",
    // "NotFoundError: Failed to execute 'insertBefore' on 'Node': The node before which the new node is to be inserted is not a child of this node.",
  ],
  dsn: 'https://c6347da6ca0ad1d2c02d91971e8fdd87@o4508652419612672.ingest.de.sentry.io/4508652432195664',
  integrations: [
    Sentry.breadcrumbsIntegration({
      console: true,
      dom: true,
      fetch: true,
      history: true,
      xhr: true,
    }),
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
    // Sentry.captureConsoleIntegration(),
    Sentry.httpClientIntegration(),
    Sentry.httpContextIntegration(),
  ],
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(<Apps />);
