import { TOption } from './Dropdown';
import { DropdownItem } from './DropdownItem';

const DefaultOption = ({
  option,
  selected,
  preselected,
}: {
  option?: TOption;
  selected?: boolean;
  preselected?: boolean;
}) => {
  return (
    <DropdownItem
      className={preselected && !selected ? 'bg-v2blueGray-200/40' : ''}
      icon={option?.icon}
      selected={selected}
      disabled={option?.disabled}
    >
      <span className="whitespace-nowrap overflow-hidden overflow-ellipsis">{option?.label}</span>
    </DropdownItem>
  );
};

export { DefaultOption };
