export function WaitingResponse({ ...props }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 16 16" {...props}>
      <g transform="translate(-1541 -8612)">
        <g transform="translate(1539.311 8609.012)">
          <path
            d="M3.966,9.487l2.958,3.02A.577.577,0,0,1,7.1,12.9a.564.564,0,0,1-.166.413.522.522,0,0,1-.39.172.55.55,0,0,1-.39-.182L2.887,9.969a.692.692,0,0,1-.15-.222.7.7,0,0,1,0-.513.778.778,0,0,1,.145-.226L6.153,5.67a.554.554,0,0,1,.393-.182.493.493,0,0,1,.386.182.533.533,0,0,1,.174.39.556.556,0,0,1-.174.4Zm4.2.557,2.412,2.463a.578.578,0,0,1,.174.394.564.564,0,0,1-.166.413.522.522,0,0,1-.39.172.55.55,0,0,1-.39-.182L6.546,9.969a.692.692,0,0,1-.15-.222.7.7,0,0,1,0-.513.778.778,0,0,1,.145-.226L9.812,5.67a.554.554,0,0,1,.393-.182.493.493,0,0,1,.386.182.532.532,0,0,1,.174.39.556.556,0,0,1-.174.4L8.171,8.93h4.7a3.644,3.644,0,0,1,2.7,1.142,3.8,3.8,0,0,1,1.118,2.759v2.1a.548.548,0,0,1-.155.4.516.516,0,0,1-.386.16.531.531,0,0,1-.39-.16.54.54,0,0,1-.16-.4v-2.1a2.713,2.713,0,0,0-.8-1.97,2.6,2.6,0,0,0-1.93-.816Z"
            fill="currentColor"
          />
        </g>
      </g>
    </svg>
  );
}
