export default {
  'cc.AF': 'Afghanistan',
  'cc.AX': 'Aland Islands',
  'cc.AL': 'Albania',
  'cc.DZ': 'Algeria',
  'cc.AS': 'American Samoa',
  'cc.AD': 'Andorra',
  'cc.AO': 'Angola',
  'cc.AI': 'Anguilla',
  'cc.AQ': 'Antarctica',
  'cc.AG': 'Antigua And Barbuda',
  'cc.AR': 'Argentina',
  'cc.AM': 'Armenia',
  'cc.AW': 'Aruba',
  'cc.AU': 'Australia',
  'cc.AT': 'Austria',
  'cc.AZ': 'Azerbaijan',
  'cc.BS': 'Bahamas',
  'cc.BH': 'Bahrain',
  'cc.BD': 'Bangladesh',
  'cc.BB': 'Barbados',
  'cc.BY': 'Belarus',
  'cc.BE': 'Belgium',
  'cc.BZ': 'Belize',
  'cc.BJ': 'Benin',
  'cc.BM': 'Bermuda',
  'cc.BT': 'Bhutan',
  'cc.BO': 'Bolivia',
  'cc.BA': 'Bosnia and Herzegovina',
  'cc.BW': 'Botswana',
  'cc.BV': 'Bouvet Island',
  'cc.BR': 'Brazil',
  'cc.VG': 'British Virgin Islands',
  'cc.IO': 'British Indian Ocean Territory',
  'cc.BN': 'Brunei Darussalam',
  'cc.BG': 'Bulgaria',
  'cc.BF': 'Burkina Faso',
  'cc.BI': 'Burundi',
  'cc.KH': 'Cambodia',
  'cc.CM': 'Cameroon',
  'cc.CA': 'Canada',
  'cc.CV': 'Cape Verde',
  'cc.KY': 'Cayman Islands',
  'cc.CF': 'Central African Republic',
  'cc.TD': 'Chad',
  'cc.CL': 'Chile',
  'cc.CN': 'China',
  'cc.HK': 'Hong Kong, SAR China',
  'cc.MO': 'Macao, SAR China',
  'cc.CX': 'Christmas Island',
  'cc.CC': 'Cocos (Keeling) Islands',
  'cc.CO': 'Colombia',
  'cc.KM': 'Comoros',
  'cc.CG': 'Congo',
  'cc.CD': 'Congo, Democratic Republic',
  'cc.CK': 'Cook Islands',
  'cc.CR': 'Costa Rica',
  'cc.CI': "Cote D'Ivoire",
  'cc.HR': 'Croatia',
  'cc.CU': 'Cuba',
  'cc.CY': 'Cyprus',
  'cc.CZ': 'Czech Republic',
  'cc.DK': 'Denmark',
  'cc.DJ': 'Djibouti',
  'cc.DM': 'Dominica',
  'cc.DO': 'Dominican Republic',
  'cc.EC': 'Ecuador',
  'cc.EG': 'Egypt',
  'cc.SV': 'El Salvador',
  'cc.GQ': 'Equatorial Guinea',
  'cc.ER': 'Eritrea',
  'cc.EE': 'Estonia',
  'cc.ET': 'Ethiopia',
  'cc.FK': 'Falkland Islands (Malvinas)',
  'cc.FO': 'Faroe Islands',
  'cc.FJ': 'Fiji',
  'cc.FI': 'Finland',
  'cc.FR': 'France',
  'cc.GF': 'French Guiana',
  'cc.PF': 'French Polynesia',
  'cc.TF': 'French Southern Territories',
  'cc.GA': 'Gabon',
  'cc.GM': 'Gambia',
  'cc.GE': 'Georgia',
  'cc.DE': 'Germany',
  'cc.GH': 'Ghana',
  'cc.GI': 'Gibraltar',
  'cc.GR': 'Greece',
  'cc.GL': 'Greenland',
  'cc.GD': 'Grenada',
  'cc.GP': 'Guadeloupe',
  'cc.GU': 'Guam',
  'cc.GT': 'Guatemala',
  'cc.GG': 'Guernsey',
  'cc.GN': 'Guinea',
  'cc.GW': 'Guinea-Bissau',
  'cc.GY': 'Guyana',
  'cc.HT': 'Haiti',
  'cc.HM': 'Heard Island & Mcdonald Islands',
  'cc.VA': 'Holy See (Vatican City State)',
  'cc.HN': 'Honduras',
  'cc.HU': 'Hungary',
  'cc.IS': 'Iceland',
  'cc.IN': 'India',
  'cc.ID': 'Indonesia',
  'cc.IR': 'Iran, Islamic Republic Of',
  'cc.IQ': 'Iraq',
  'cc.IE': 'Ireland',
  'cc.IM': 'Isle Of Man',
  'cc.IL': 'Israel',
  'cc.IT': 'Italy',
  'cc.JM': 'Jamaica',
  'cc.JP': 'Japan',
  'cc.JE': 'Jersey',
  'cc.JO': 'Jordan',
  'cc.KZ': 'Kazakhstan',
  'cc.KE': 'Kenya',
  'cc.KI': 'Kiribati',
  'cc.KP': 'North Korea',
  'cc.KR': 'South Korea',
  'cc.KW': 'Kuwait',
  'cc.KG': 'Kyrgyzstan',
  'cc.LA': "Lao People's Democratic Republic",
  'cc.LV': 'Latvia',
  'cc.LB': 'Lebanon',
  'cc.LS': 'Lesotho',
  'cc.LR': 'Liberia',
  'cc.LY': 'Libyan Arab Jamahiriya',
  'cc.LI': 'Liechtenstein',
  'cc.LT': 'Lithuania',
  'cc.LU': 'Luxembourg',
  'cc.MK': 'Macedonia',
  'cc.MG': 'Madagascar',
  'cc.MW': 'Malawi',
  'cc.MY': 'Malaysia',
  'cc.MV': 'Maldives',
  'cc.ML': 'Mali',
  'cc.MT': 'Malta',
  'cc.MH': 'Marshall Islands',
  'cc.MQ': 'Martinique',
  'cc.MR': 'Mauritania',
  'cc.MU': 'Mauritius',
  'cc.YT': 'Mayotte',
  'cc.MX': 'Mexico',
  'cc.FM': 'Micronesia, Federated States Of',
  'cc.MD': 'Moldova',
  'cc.MC': 'Monaco',
  'cc.MN': 'Mongolia',
  'cc.ME': 'Montenegro',
  'cc.MS': 'Montserrat',
  'cc.MA': 'Morocco',
  'cc.MZ': 'Mozambique',
  'cc.MM': 'Myanmar',
  'cc.NA': 'Namibia',
  'cc.NR': 'Nauru',
  'cc.NP': 'Nepal',
  'cc.NL': 'Netherlands',
  'cc.AN': 'Netherlands Antilles',
  'cc.NC': 'New Caledonia',
  'cc.NZ': 'New Zealand',
  'cc.NI': 'Nicaragua',
  'cc.NE': 'Niger',
  'cc.NG': 'Nigeria',
  'cc.NU': 'Niue',
  'cc.NF': 'Norfolk Island',
  'cc.MP': 'Northern Mariana Islands',
  'cc.NO': 'Norway',
  'cc.OM': 'Oman',
  'cc.PK': 'Pakistan',
  'cc.PW': 'Palau',
  'cc.PS': 'Palestinian Territory, Occupied',
  'cc.PA': 'Panama',
  'cc.PG': 'Papua New Guinea',
  'cc.PY': 'Paraguay',
  'cc.PE': 'Peru',
  'cc.PH': 'Philippines',
  'cc.PN': 'Pitcairn',
  'cc.PL': 'Poland',
  'cc.PT': 'Portugal',
  'cc.PR': 'Puerto Rico',
  'cc.QA': 'Qatar',
  'cc.RE': 'Reunion',
  'cc.RO': 'Romania',
  'cc.RU': 'Russian Federation',
  'cc.RW': 'Rwanda',
  'cc.BL': 'Saint Barthelemy',
  'cc.SH': 'Saint Helena',
  'cc.KN': 'Saint Kitts And Nevis',
  'cc.LC': 'Saint Lucia',
  'cc.MF': 'Saint Martin',
  'cc.PM': 'Saint Pierre And Miquelon',
  'cc.VC': 'Saint Vincent And Grenadines',
  'cc.WS': 'Samoa',
  'cc.SM': 'San Marino',
  'cc.ST': 'Sao Tome And Principe',
  'cc.SA': 'Saudi Arabia',
  'cc.SN': 'Senegal',
  'cc.RS': 'Serbia',
  'cc.SC': 'Seychelles',
  'cc.SL': 'Sierra Leone',
  'cc.SG': 'Singapore',
  'cc.SK': 'Slovakia',
  'cc.SI': 'Slovenia',
  'cc.SB': 'Solomon Islands',
  'cc.SO': 'Somalia',
  'cc.ZA': 'South Africa',
  'cc.GS': 'South Georgia And Sandwich Isl.',
  'cc.SS': 'South Sudan',
  'cc.ES': 'Spain',
  'cc.LK': 'Sri Lanka',
  'cc.SD': 'Sudan',
  'cc.SR': 'Suriname',
  'cc.SJ': 'Svalbard And Jan Mayen',
  'cc.SZ': 'Swaziland',
  'cc.SE': 'Sweden',
  'cc.CH': 'Switzerland',
  'cc.SY': 'Syrian Arab Republic',
  'cc.TW': 'Taiwan',
  'cc.TJ': 'Tajikistan',
  'cc.TZ': 'Tanzania',
  'cc.TH': 'Thailand',
  'cc.TL': 'Timor-Leste',
  'cc.TG': 'Togo',
  'cc.TK': 'Tokelau',
  'cc.TO': 'Tonga',
  'cc.TT': 'Trinidad And Tobago',
  'cc.TN': 'Tunisia',
  'cc.TR': 'Turkey',
  'cc.TM': 'Turkmenistan',
  'cc.TC': 'Turks And Caicos Islands',
  'cc.TV': 'Tuvalu',
  'cc.UG': 'Uganda',
  'cc.UA': 'Ukraine',
  'cc.AE': 'United Arab Emirates',
  'cc.GB': 'United Kingdom',
  'cc.US': 'United States',
  'cc.UM': 'United States Outlying Islands',
  'cc.UY': 'Uruguay',
  'cc.UZ': 'Uzbekistan',
  'cc.VU': 'Vanuatu',
  'cc.VE': 'Venezuela',
  'cc.VN': 'Viet Nam',
  'cc.VI': 'Virgin Islands, U.S.',
  'cc.WF': 'Wallis And Futuna',
  'cc.EH': 'Western Sahara',
  'cc.YE': 'Yemen',
  'cc.ZM': 'Zambia',
  'cc.ZW': 'Zimbabwe',
  'cc.XK': 'Kosovo',
};
