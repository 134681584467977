export default {
  'custLanding.password.title': 'Password dimenticata?',
  'custLanding.signup.btn': 'Registrati',
  'custLanding.login.btn': 'Accedi',
  'custLanding.password.btn': 'Reimposta password',
  'custLanding.switch-to-login': 'Accedi con l’account esistente',
  'custLanding.switch-to-signup': ' Registrati con un nuovo account',
  'custLanding.auth.text.password':
    'Inserisci l’indirizzo e-mail associato al tuo account e ti invieremo le istruzioni per ricevere una nuova password.',
  'custLanding.form.password': 'Password',
  'custLanding.forgot-password': 'Password dimenticata?',
  'custLanding.forgot-password.success': 'Le tue istruzioni arriveranno tra breve!',
  'custLanding.let-get-started': 'Iniziamo!',
  'custLanding.already-have-an-account': 'Hai già un account?',
  'custLanding.just-remembered': 'Ti sei ricordato la password?',
  'custlanding.login.error.no-order': 'Nessun ordine trovato per questo indirizzo e-mail',
};
