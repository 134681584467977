import { createContext } from 'react';

import { Subscription, SubscriptionPlanDescriptor } from 'app-types';

import { PlanProgressProps, ShopifyProgressProps } from '../SubscriptionFlow';

export const SubscriptionContext = createContext<
  | {
      subscription: Subscription;
      plans: SubscriptionPlanDescriptor[];
      planProgress?: PlanProgressProps;
      onProgressFinish?: () => void;
      shopifyProgress?: ShopifyProgressProps;
    }
  | undefined
>(undefined);
