import { memo, useCallback, useState } from 'react';

import noop from 'lodash-es/noop';

import { DialogContainer } from '../../atoms';
import PaymentDone from './PaymentDone';
import SubscriptionFlow, { SubscriptionFlowProps } from './SubscriptionFlow';
import { SubscriptionContext } from './hooks/useSubscriptionContext';

type PricingBlockerWindowProps = SubscriptionFlowProps & { isOpen: boolean };

function PricingBlockerWindowBase({
  plans,
  subscription,
  updateSubscriptionInfo,
  hideCancel,
  type,
  planProgress,
  onProgressFinish: handleFinshPayment,
  isOpen,
  shopifyProgress,
}: PricingBlockerWindowProps) {
  const [showConfirmationInfo, setShowConfirmationInfo] = useState(false);

  const onProgressFinish = useCallback(() => {
    setShowConfirmationInfo(true);

    if (handleFinshPayment) {
      handleFinshPayment();
    }
  }, []);

  return (
    <>
      {isOpen && (
        <SubscriptionContext.Provider
          value={{ subscription, plans, planProgress, onProgressFinish, shopifyProgress }}
        >
          <SubscriptionFlow
            onProgressFinish={handleFinshPayment}
            subscription={subscription}
            updateSubscriptionInfo={updateSubscriptionInfo}
            hideCancel={hideCancel}
            type={type}
          />
        </SubscriptionContext.Provider>
      )}

      <DialogContainer
        className="p-0 max-w-[796px] rounded-t-[22px] rounded-b-none md:rounded-[22px]"
        wrapperClassName="p-0 md:p-4 pt-10 md:py-10 items-end md:items-center"
        isOpen={showConfirmationInfo}
        handleClose={noop}
        zIndex={1001}
      >
        <PaymentDone onClose={() => setShowConfirmationInfo(false)} />
      </DialogContainer>
    </>
  );
}

export const PricingBlockerWindow = memo(PricingBlockerWindowBase);
