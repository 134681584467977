export default {
  'custLanding.password.title': 'Pamiršote slaptažodį?',
  'custLanding.signup.btn': 'Prisiregistruoti',
  'custLanding.login.btn': 'Prisijunkite',
  'custLanding.password.btn': 'Iš naujo nustatyti slaptažodį',
  'custLanding.switch-to-login': 'Prisijunkite naudodami egzistuojančią paskyrą',
  'custLanding.switch-to-signup': 'Prisiregistruokite naudodami naują paskyrą',
  'custLanding.auth.text.password':
    'Įveskite el. pašto adresą, susietą su jūsų paskyra, ir mes jums atsiųsime instrukcijas, kaip gauti naują slaptažodį.',
  'custLanding.form.password': 'Slaptažodis',
  'custLanding.forgot-password': 'Pamiršote slaptažodį?',
  'custLanding.forgot-password.success': 'Netrukus gausite instrukcijas!',
  'custLanding.let-get-started': 'Pradėkime',
  'custLanding.already-have-an-account': 'Jau turite paskyrą?',
  'custLanding.just-remembered': 'Prisiminėte savo slaptažodį?',
  'custlanding.login.error.no-order': 'Nerasta užsakymų pagal šį el. pašto adresą',
};
