import { IntlShape } from 'localization';
import * as yup from 'yup';

const companyInfoValidationSchema = (t: IntlShape) => {
  const requiredMessage = t.formatMessage({ id: '3PL.error.required-message' });

  return yup.object().shape({
    name: yup.string().required(requiredMessage),
    address: yup.string().required(requiredMessage),
    city: yup.string().required(requiredMessage),
    postcode: yup.string().required(requiredMessage),
    countryCode: yup.string().required(requiredMessage),
    vat: yup.string().notRequired(),
    registrationNumber: yup.string().required(requiredMessage),
  });
};

export default companyInfoValidationSchema;
