export default {
  FALLBACK_ERROR_MESSAGE: 'Kaut kas nogāja greizi. Lūdzu, mēģiniet vēlreiz vēlāk.',
  COURIER_ERROR_FALLBACK: 'Kurjera kļūda.',
  TOKEN_NOT_FOUND: 'Marķieris nav atrasts.',
  IDENTITY_VERIFICATION_FAILED: 'Nevarēja pabeigt identitātes pārbaudi.',
  ACCOUNT_BLOCKED: 'Jūsu konts ir bloķēts. Lūdzu, pārbaudiet savu e-pastu.',
  WRONG_EMAIL_OR_PASS: 'E-pasts un/vai parole nav pareizi.',
  INVALID_CREDENTIALS: 'Nederīgi akreditācijas dati.',
  INVALID_TOKEN:
    'Sesija ir beigusies. Lūdzu, pieprasiet jaunu e-pasta apstiprināšanas saiti atgriešanas portālā.',
  MALFORMED_TOKEN: 'Nepareizi izveidots marķieris.',
  CUSTOMER_ALREADY_CREATED: 'Klienta konts jau ir izveidots. Lūdzu, piesakieties tajā.',
  SHIPPING_METHOD_NOT_SUPPORTED: 'Piegādes metode netiek atbalstīta.',
  SHIPMENT_ALREADY_PICKED_UP: 'Sūtījums jau ir paņemts.',
  NO_RATE_FOR_DESTINATION: 'Šim galamērķim nav pieejams tarifs.',
  BUILDING_LABEL_FAILED: 'Neizdevās izveidot etiķeti.',
  COURIER_NOT_SUPPORTED: 'Kurjers netiek atbalstīts.',
  PICKUP_REQUEST_FAILED: 'Saņemšanas pieprasījums neizdevās.',
  ERROR_TRY_AGAIN_LATER: 'Kaut kas nogāja greizi. Lūdzu, mēģiniet vēlreiz vēlāk.',
  EMAIL_ALREADY_REGISTERED: 'Šis e-pasts jau ir reģistrēts.',
  INVALID_PASSWORD: 'Nederīga parole.',
  LABEL_ERROR: 'Etiķetes kļūda.',
  UNDER_MAINTENANCE: 'Mums pašlaik notiek apkopes darbi. Outvio drīz būs atpakaļ tiešsaistē.',
  MERGING_PDFS_FAILED: 'Neizdevās apvienot PDF failus',
  EXPIRE_TOKEN: 'Marķierim beidzies derīguma termiņš',
  GENERIC_MISSING_PARAM: 'Trūkst nepieciešamo parametru.',
  ORDER_NOT_FOUND: 'Pasūtījums nav atrasts.',
};
