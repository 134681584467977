import { ReactNode, memo, useEffect } from 'react';

import { useInView } from 'react-intersection-observer';

import { twMerge } from 'tailwind-merge';

const InfinityTableLoader = memo(
  ({
    children,
    onFetchNext,
    enable,
    className,
  }: {
    children: ReactNode;
    onFetchNext: () => void;
    enable: boolean;
    className?: string;
  }) => {
    const [ref, inView] = useInView();

    useEffect(() => {
      if (!enable) return;
      if (inView) onFetchNext();
    }, [enable, onFetchNext, inView]);

    return (
      <>
        {children}
        {enable ? <div ref={ref} className={twMerge('h-px', className)} /> : null}
      </>
    );
  },
);

export { InfinityTableLoader };
