import { IResponseCourierIcons } from 'app-types/src/order';

import request from '../../request';

export type UpdateCouriersSupportEmailsData = {
  data: Array<{
    email: string;
    courierId: string;
  }>;
};

type UpdateCouriersSupportEmailsResponse = {
  data: Array<{ courierId: string; email: string; isValidEmail: boolean }>;
};

export const updateCouriersSupportEmails = (data: UpdateCouriersSupportEmailsData) =>
  request<UpdateCouriersSupportEmailsResponse>(
    `${process.env.OUTVIO_API_URL}/courier/update-courier-support-mail`,
    {
      method: 'POST',
      data,
      secure: true,
    },
  ).then((response) => response?.data);

export const getCourierIcons = () =>
  request<IResponseCourierIcons>(
    //@ts-ignore
    `${import.meta.env.VITE_OUTVIO_API_URL}/courier/schema`,
    {
      method: 'GET',
      secure: true,
    },
  ).then((res) => res?.data);

export const manageWarehouse = (idCourier: string, warehouses: string[]) => {
  return request(`${process.env.OUTVIO_API_URL}/courier/manage-warehouses`, {
    method: 'POST',
    secure: true,
    data: {
      courierId: idCourier,
      warehouses,
    },
  });
};
