import { useCallback, useMemo, useState } from 'react';

import GetOffer from './DowngradeParts/GetOffer/GetOffer';
import HelpToImprove from './DowngradeParts/HelpToImprove/HelpToImprove';
import LostFeatures from './DowngradeParts/LostFeatures/LostFeatures';
import { PriceStep } from './hooks/pricingBlockerHooks';
import { PricingPlanProps } from './types/pricingTypes';

type DowngradePlanFlowProps = {
  onClose: () => void;
  from: PricingPlanProps;
  to: PricingPlanProps;
  onDowngradeDone: () => void;
  tookDowngradeOffer: boolean;
  onBookMeeting: (gotoStep: PriceStep) => void;
};

const DowngradePlanFlow = ({
  onClose,
  from,
  to,
  onDowngradeDone,
  onBookMeeting,
  tookDowngradeOffer,
}: DowngradePlanFlowProps) => {
  const [step, setStep] = useState<DowngradeStep>(DowngradeStep.FeaturesYouLose);

  const handleSetStep = useCallback((newStep: DowngradeStep) => setStep(newStep), []);

  const specificStepComponent: Record<DowngradeStep, JSX.Element | null> = useMemo(
    () => ({
      [DowngradeStep.FeaturesYouLose]: (
        <LostFeatures
          from={from}
          to={to}
          onClose={onClose}
          setStep={handleSetStep}
          tookDowngradeOffer={tookDowngradeOffer}
        />
      ),
      [DowngradeStep.GetOffer]: (
        <GetOffer from={from} to={to} setStep={handleSetStep} onGetOffer={onBookMeeting} />
      ),
      [DowngradeStep.HelpToImprove]: (
        <HelpToImprove from={from} to={to} onClose={onClose} onDowngradeDone={onDowngradeDone} />
      ),
    }),
    [from, to],
  );

  return specificStepComponent[step];
};

export enum DowngradeStep {
  FeaturesYouLose,
  GetOffer,
  HelpToImprove,
}

export default DowngradePlanFlow;
