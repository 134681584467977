import { useCallback, useMemo, useState } from 'react';

import { DowngradeStep } from 'ui/organisms';

import GetOffer from './DowngradeParts/GetOffer/GetOffer';
import HelpToImprove from './DowngradeParts/HelpToImprove/HelpToImprove';
import LostDeskFeatures from './DowngradeParts/LostDeskFeatures/LostDeskFeatures';
import { PriceStep } from './hooks/pricingBlockerHooks';

type DowngradePlanFlowProps = {
  onClose: () => void;
  onDowngradeDone: () => void;
  onBookMeeting: (gotoStep: PriceStep) => void;
};

const DowngradeDeskFlow = ({ onClose, onDowngradeDone, onBookMeeting }: DowngradePlanFlowProps) => {
  const [step, setStep] = useState<DowngradeStep>(DowngradeStep.FeaturesYouLose);

  const handleSetStep = useCallback((newStep: DowngradeStep) => setStep(newStep), []);

  const specificStepComponent: Record<DowngradeStep, JSX.Element | null> = useMemo(
    () => ({
      [DowngradeStep.FeaturesYouLose]: (
        <LostDeskFeatures onClose={onClose} setStep={handleSetStep} />
      ),
      [DowngradeStep.GetOffer]: (
        <GetOffer desk setStep={handleSetStep} onGetOffer={onBookMeeting} />
      ),
      [DowngradeStep.HelpToImprove]: (
        <HelpToImprove desk onClose={onClose} onDowngradeDone={onDowngradeDone} />
      ),
    }),
    [],
  );

  return specificStepComponent[step];
};

export default DowngradeDeskFlow;
