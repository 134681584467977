export function Delete({ ...props }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="1em" height="1em" {...props}>
      <g transform="translate(48 48) rotate(-180)">
        <rect width="24" height="24" transform="translate(48 24) rotate(90)" fill="none" />
        <path
          d="M888.333,1279h5.333M883,1281.667h16m-1.778,0-.623,9.351a6.527,6.527,0,0,1-.444,2.64,2.671,2.671,0,0,1-1.154,1.076,6.506,6.506,0,0,1-2.661.267h-2.683a6.506,6.506,0,0,1-2.661-.267,2.671,2.671,0,0,1-1.154-1.076,6.527,6.527,0,0,1-.444-2.64l-.623-9.351m4.444,4v4.444m3.556-4.444v4.444"
          transform="translate(927 1323) rotate(180)"
          fill="none"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.6"
        />
      </g>
    </svg>
  );
}
