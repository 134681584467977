const LocationOn = ({ ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="currentColor"
      {...props}
    >
      <path d="M12 11.75q.725 0 1.238-.512.512-.513.512-1.238t-.512-1.238Q12.725 8.25 12 8.25t-1.238.512q-.512.513-.512 1.238t.512 1.238q.513.512 1.238.512Zm0 9.7q-.175 0-.35-.05t-.325-.175Q7.775 18 6.013 15.25 4.25 12.5 4.25 10.2q0-3.625 2.338-5.788Q8.925 2.25 12 2.25q3.075 0 5.413 2.162Q19.75 6.575 19.75 10.2q0 2.3-1.762 5.05-1.763 2.75-5.313 5.975-.15.125-.325.175-.175.05-.35.05Z" />
    </svg>
  );
};

export { LocationOn };
