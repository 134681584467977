import { CustomerStoreData } from 'return-portal-ui/old-ui/types/CustomerStoreData';
import { TrackingStep } from 'return-portal-ui/old-ui/types/Tracking';
import { SourceDestinationAddress, SourceStep, SourceTrackingData } from 'tracking-fe/src';

import { Address } from '../../types/general';
import request from './request';

export interface ResponseOrderTrackingData {
  // if order is not yet shipped, these might be missing
  trackingNumber?: string;
  processDate?: string;
  returnProcessDate?: string;
  estimatedDeliverDate?: string;
  secondaryTrackingNumber?: string;
  secondaryCourier?: string;
  // always present
  otn: string;
  originAddress: Address;
  origin: string;
  destination: string;
  destinationAddress: Address;
  courier: string;
  method: string;
  steps: TrackingStep[];
}

export const requestTrackingData = ({
  otn,
  trackingLanguage,
}: {
  otn: string;
  trackingLanguage: string;
}): Promise<SourceTrackingData> =>
  request(`${import.meta.env.VITE_OUTVIO_API_URL}/customer/order/${otn}/general`, {
    method: 'GET',
    params: {
      trackingLanguage,
    },
  }).then((res) => ({ ...res.tracking, rating: res.rating }));

export const requestTrackingDataMultiOrder = ({
  otn,
  trackingLanguage,
}: {
  otn: string;
  trackingLanguage: string;
}): Promise<
  {
    otn: string;
    products: any;
    discountApplied?: number;
    currency: string;
    tax: number;
    total: number;
    shipping: { price: number };
  }[]
> =>
  request(`${import.meta.env.VITE_OUTVIO_API_URL}/customer/order/${otn}/split-orders`, {
    method: 'GET',
    params: {
      trackingLanguage,
    },
  }).then((res) => res.data);

export interface RequestTrackingInfo {
  tracking: { steps: SourceStep[]; packages?: string[] };
}

export const requestTrackingInfo = ({
  otn,
  trackingLanguage,
  cache = false,
}: {
  otn: string;
  trackingLanguage: string;
  cache?: boolean;
}): Promise<RequestTrackingInfo> =>
  request(`${import.meta.env.VITE_OUTVIO_API_URL}/customer/order/${otn}/track`, {
    method: 'GET',
    params: { trackingLanguage, cache },
  });

export const requestStoreInfo = (params: {
  otn?: string;
}): Promise<{ data: CustomerStoreData & { customDomains: any } }> =>
  request(`${import.meta.env.VITE_OUTVIO_API_URL}/customer/store`, { method: 'GET', params });

export const getOTNFromIdentifier = (params: {
  orderIdentifier: string;
  postcode: string;
}): Promise<{ otn: string }> =>
  request(`${import.meta.env.VITE_OUTVIO_API_URL}/customer/identify-otn`, {
    method: 'GET',
    params,
  });

export const requestSendRating = ({ token, data }: { token: string; data: { rating: number } }) =>
  request(`${import.meta.env.VITE_OUTVIO_API_URL}/customer/order/${token}/rating`, {
    method: 'POST',
    data,
  });

export const requestChangeDeliveryAddress = ({
  token,
  data,
}: {
  token: string;
  data: Partial<SourceDestinationAddress>;
}) =>
  request(`${import.meta.env.VITE_OUTVIO_API_URL}/customer/order/${token}/address`, {
    method: 'POST',
    data,
  });
