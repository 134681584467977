import { gql } from '@apollo/client';

import {
  ATTACHMENT_FRAGMENT,
  BRAND_SETTINGS_FRAGMENT,
  CHAT_LANGUAGES_FRAGMENT,
  CHAT_TRANSLATIONS_FRAGMENT,
  COMMENT_FRAGMENT,
  COURIER_CONTACT_FRAGMENT,
  EMAIL_CONFIGURATION_FRAGMENT,
  EMAIL_DOMAIN_AND_SENDER_FRAGMENT,
  FOLDER_FRAGMENT,
  MESSAGE_COMMENT_FRAGMENT,
  PERSONAL_FOLDER_FRAGMENT,
  ROUTING_RULE_FRAGMENT,
  STORE_ENTITY_FRAGMENT,
  TAG_FRAGMENT,
  TEAM_FRAGMENT,
  TEMPLATE_FRAGMENT,
  TICKET_FRAGMENT,
  TICKET_FRAGMENT_SHORT,
  TICKET_MESSAGE_FRAGMENT,
  TICKET_ORDER_FRAGMENT,
} from './fragments';

export const TEAM_CREATE_MUTATION = gql`
  mutation Team_CreateOne($input: TeamInput!) {
    Team_CreateOne(input: $input) {
      ...TeamFragment
    }
  }
  ${TEAM_FRAGMENT}
`;

export const TICKET_FORWARD_VOICE_CALL_MUTATION = gql`
  mutation Ticket_ForwardVoiceCall($agentId: String!, $callId: String!) {
    ticket: Ticket_ForwardVoiceCall(agentId: $agentId, callId: $callId) {
      id
    }
  }
`;

export const TEAM_UPDATE_MUTATION = gql`
  mutation Team_UpdateOne($teamUpdateOneId: String!, $input: TeamInput!) {
    Team_UpdateOne(id: $teamUpdateOneId, input: $input) {
      ...TeamFragment
    }
  }
  ${TEAM_FRAGMENT}
`;

export const TEAM_DELETE_MUTATION = gql`
  mutation Team_DeleteOne($teamDeleteOneId: String!) {
    Team_DeleteOne(id: $teamDeleteOneId)
  }
`;

export const ADD_TAG_MUTATION = gql`
  mutation Store_AddTag($tag: String!) {
    Store_AddTag(tag: $tag) {
      ...TagFragment
    }
  }
  ${TAG_FRAGMENT}
`;

export const REMOVE_TAG_MUTATION = gql`
  mutation Store_RemoveTag($tag: String!) {
    Store_RemoveTag(tag: $tag)
  }
`;

export const CREATE_COMPANY_FOLDER_MUTATION = gql`
  mutation Folder_CreateOneCompany($input: CompanyFolderInput!) {
    Folder_CreateOneCompany(input: $input) {
      ...FolderFragment
    }
  }
  ${FOLDER_FRAGMENT}
`;

export const CREATE_PERSONAL_FOLDER_MUTATION = gql`
  mutation Folder_CreateOnePersonal($input: PersonalFolderInput!) {
    Folder_CreateOnePersonal(input: $input) {
      ...PersonalFolderFragment
    }
  }
  ${PERSONAL_FOLDER_FRAGMENT}
`;

export const UPDATE_PERSONAL_FOLDER_MUTATION = gql`
  mutation Folder_UpdatePersonalFolder(
    $folderUpdateOneId: String!
    $input: UpdatePersonalFolderInput!
  ) {
    Folder_UpdatePersonalFolder(id: $folderUpdateOneId, input: $input) {
      ...FolderFragment
    }
  }
  ${FOLDER_FRAGMENT}
`;

export const UPDATE_COMPANY_FOLDER_MUTATION = gql`
  mutation Folder_UpdateCompanyFolder(
    $folderUpdateOneId: String!
    $input: UpdateCompanyFolderInput!
  ) {
    Folder_UpdateCompanyFolder(id: $folderUpdateOneId, input: $input) {
      ...FolderFragment
    }
  }
  ${FOLDER_FRAGMENT}
`;

export const DELETE_FOLDER_MUTATION = gql`
  mutation Folder_DeleteOne($folderDeleteOneId: String!) {
    Folder_DeleteOne(id: $folderDeleteOneId)
  }
`;

export const CREATE_TEMPLATE_MUTATION = gql`
  mutation Template_CreateOne($input: TemplateInput!) {
    Template_CreateOne(input: $input) {
      ...TemplateFragment
    }
  }
  ${TEMPLATE_FRAGMENT}
`;

export const UPDATE_TEMPLATE_MUTATION = gql`
  mutation Template_UpdateOne($templateUpdateOneId: String!, $input: TemplateInput!) {
    Template_UpdateOne(id: $templateUpdateOneId, input: $input) {
      ...TemplateFragment
    }
  }
  ${TEMPLATE_FRAGMENT}
`;

export const DELETE_TEMPLATE_MUTATION = gql`
  mutation Template_DeleteOne($templateDeleteOneId: String!) {
    Template_DeleteOne(id: $templateDeleteOneId)
  }
`;

export const UPDATE_FOLDERS_SORTING_INDEX_MUTATION = gql`
  mutation Mutation($ids: [String!]!) {
    Folder_SwapIndexes(ids: $ids)
  }
`;

export const UPDATE_TAGS_MUTATION = gql`
  mutation Store_UpdateTags($tags: [String]!) {
    Store_UpdateTags(tags: $tags)
  }
`;

export const CREATE_TICKET_MUTATION = gql`
  mutation Ticket_CreateByAgent($input: TicketCreateByAgentInput!) {
    Ticket_CreateByAgent(input: $input) {
      ...TicketFragment
    }
  }
  ${TICKET_FRAGMENT}
`;

export const CREATE_COURIER_CONTACT_MUTATION = gql`
  mutation CourierContact_Create($input: CourierContactInput!) {
    CourierContact_Create(input: $input) {
      ...CourierContactFragment
    }
  }
  ${COURIER_CONTACT_FRAGMENT}
`;

export const UPDATE_COURIER_CONTACT_MUTATION = gql`
  mutation CourierContact_Update($input: CourierContactInput!) {
    CourierContact_Update(input: $input) {
      ...CourierContactFragment
    }
  }
  ${COURIER_CONTACT_FRAGMENT}
`;

export const DELETE_COURIER_CONTACT_MUTATION = gql`
  mutation CourierContact_DeleteOne($courierContactDeleteOneId: String!) {
    CourierContact_DeleteOne(id: $courierContactDeleteOneId)
  }
`;

export const REPLY_TICKET_MUTATION = gql`
  mutation Message_ReplyByAgent($input: MessageReplyByAgentInput!) {
    Message_ReplyByAgent(input: $input) {
      ...TicketMessageFragment
    }
  }

  ${TICKET_MESSAGE_FRAGMENT}
`;

export const TICKET_SET_STATUS_MUTATION = gql`
  mutation Ticket_SetStatus($input: SetStatusInput!) {
    Ticket_SetStatus(input: $input)
  }
`;

export const BatchUpdateRatingPageMutation = gql`
  mutation RatingPage_batchUpdate(
    $brandId: String!
    $input: [RatingPageInput!]!
    $ruleInput: RatingRuleSettingsInput!
  ) {
    pages: RatingPage_batchUpsert(brandId: $brandId, input: $input) {
      buttonText
      description
      id
      language
      title
    }
    settings: RatingRule_update(brandId: $brandId, input: $ruleInput) {
      ratingEnabled
      timeUnit
      timeValue
      whenTime
      id
    }
  }
`;

export const BRAND_SETTINGS_MUTATION = gql`
  mutation BrandSettingsMutation($id: String!, $updateBrandInput: UpdateBrandInput!) {
    brand_update(id: $id, update: $updateBrandInput) {
      ...BrandSettings
    }
  }
  ${BRAND_SETTINGS_FRAGMENT}
`;

export const TICKET_BATCH_ADD_TAGS_MUTATION = gql`
  mutation Ticket_BatchAddTags($tags: [String!]!, $ticketIds: [String!]!) {
    Ticket_BatchAddTags(tags: $tags, ticketIds: $ticketIds)
  }
`;

export const TICKET_REMOVE_TAGS_MUTATION = gql`
  mutation Ticket_RemoveTags($tags: [String!]!, $ticketId: String!) {
    Ticket_RemoveTags(tags: $tags, ticketId: $ticketId) {
      tags
      id
    }
  }
`;

export const TICKET_SET_TAGS_MUTATION = gql`
  mutation Ticket_SetTags($tags: [String!]!, $ticketId: String!) {
    Ticket_SetTags(tags: $tags, ticketId: $ticketId) {
      tags
      id
    }
  }
`;

export const TICKET_SET_ASSIGNEE_MUTATION = gql`
  mutation Ticket_SetAssignee($ticketSetAssigneeId: String!, $assignee: String) {
    Ticket_SetAssignee(id: $ticketSetAssigneeId, assignee: $assignee) {
      id
      assignee
    }
  }
`;

export const TICKET_REMOVE_WATCHER_MUTATION = gql`
  mutation Ticket_RemoveWatcher($ticketRemoveWatcherId: String!) {
    Ticket_RemoveWatcher(id: $ticketRemoveWatcherId) {
      id
      watchers
    }
  }
`;

export const TICKET_ADD_WATCHER_MUTATION = gql`
  mutation Ticket_AddWatcher($ticketAddWatcherId: String!) {
    Ticket_AddWatcher(id: $ticketAddWatcherId) {
      id
      watchers
    }
  }
`;

export const TICKET_BATCH_FOLLOW_MUTATION = gql`
  mutation Ticket_BatchFollow($ticketIds: [String!]!) {
    Ticket_BatchFollow(ticketIds: $ticketIds)
  }
`;

export const TICKET_BATCH_SET_AGENT_MUTATION = gql`
  mutation Ticket_BatchAssignee($assigneeId: String!, $ticketIds: [String!]!) {
    Ticket_BatchAssignee(AssigneeId: $assigneeId, ticketIds: $ticketIds)
  }
`;

export const TICKET_HAS_BEEN_SEEN_MUTATION = gql`
  mutation Ticket_HasBeenSeen($ticketId: String!) {
    Ticket_HasBeenSeen(ticketId: $ticketId) {
      hasBeenSeenByAgent
    }
  }
`;

export const MESSAGE_COMMENT_CREATE_MUTATION = gql`
  mutation MessageComment_Create($input: MessageCommentInput!) {
    MessageComment_Create(input: $input) {
      ...MessageCommentFragment
    }
  }
  ${MESSAGE_COMMENT_FRAGMENT}
`;

export const CREATE_ROUTING_RULE_MUTATION = gql`
  mutation Rule_CreateOne($input: RuleInput!) {
    Rule_CreateOne(input: $input) {
      ...RoutingRuleFragment
    }
  }
  ${ROUTING_RULE_FRAGMENT}
`;

export const DELETE_ROUTING_RULE_MUTATION = gql`
  mutation Rule_DeleteOne($ruleDeleteOneId: String!) {
    Rule_DeleteOne(id: $ruleDeleteOneId)
  }
`;

export const DUPLICATE_ROUTING_RULE_MUTATION = gql`
  mutation Rule_Duplicate($ruleDuplicateId: String!) {
    Rule_Duplicate(id: $ruleDuplicateId) {
      ...RoutingRuleFragment
    }
  }
  ${ROUTING_RULE_FRAGMENT}
`;

export const UPDATE_ROUTING_RULE_MUTATION = gql`
  mutation Rule_UpdateOne($ruleUpdateOneId: String!, $input: RuleInput!) {
    Rule_UpdateOne(id: $ruleUpdateOneId, input: $input) {
      ...RoutingRuleFragment
    }
  }
  ${ROUTING_RULE_FRAGMENT}
`;

export const UPDATE_ROUTING_RULE_SORTING_INDEX_MUTATION = gql`
  mutation Rule_SwapIndexes($ids: [String!]!) {
    Rule_SwapIndexes(ids: $ids)
  }
`;

export const TICKET_SET_ORDER_MUTATION = gql`
  mutation Ticket_SetOrder($input: SetTicketOrderInput!) {
    Ticket_SetOrder(input: $input) {
      id
      order {
        ...TicketOrderFragment
      }
      ...TicketFragmentShort
    }
  }
  ${TICKET_ORDER_FRAGMENT}
  ${TICKET_FRAGMENT_SHORT}
`;

export const UPDATE_EMAIL_DOMAIN_AND_SENDER_MUTATION = gql`
  mutation Store_UpdateConfiguration($input: StoreInput!) {
    Store_UpdateConfiguration(input: $input) {
      ...EmailDomainAndSenderFragment
    }
  }
  ${EMAIL_DOMAIN_AND_SENDER_FRAGMENT}
`;

export const UPDATE_STORE_CONFIGURATION = gql`
  mutation Store_UpdateConfiguration($input: StoreInput!) {
    Store_UpdateConfiguration(input: $input) {
      ...StoreEntityFragment
    }
  }
  ${STORE_ENTITY_FRAGMENT}
`;

export const FINISH_ONBOARDING = gql`
  mutation Store_FinishOnboarding {
    Store_FinishOnboarding {
      ...StoreEntityFragment
    }
  }
  ${STORE_ENTITY_FRAGMENT}
`;

export const CREATE_EMAIL_CONFIGURATION_MUTATION = gql`
  mutation EmailConfiguration_CreateOne($input: EmailConfigurationInput!) {
    EmailConfiguration_CreateOne(input: $input) {
      ...EmailConfigurationFragment
    }
  }
  ${EMAIL_CONFIGURATION_FRAGMENT}
`;

export const EDIT_EMAIL_CONFIGURATION_MUTATION = gql`
  mutation EmailConfiguration_UpdateOne(
    $emailConfigurationUpdateOneId: String!
    $input: EmailConfigurationUpdateInput!
  ) {
    EmailConfiguration_UpdateOne(id: $emailConfigurationUpdateOneId, input: $input) {
      ...EmailConfigurationFragment
    }
  }
  ${EMAIL_CONFIGURATION_FRAGMENT}
`;

export const DELETE_EMAIL_CONFIGURATION_MUTATION = gql`
  mutation EmailConfiguration_DeleteOne($emailConfigurationDeleteOneId: String!) {
    EmailConfiguration_DeleteOne(id: $emailConfigurationDeleteOneId)
  }
`;

export const TICKET_SAVE_DRAFT_MUTATION = gql`
  mutation Ticket_SaveDraft($input: TicketDraftInput!) {
    Ticket_SaveDraft(input: $input)
  }
`;

export const UPDATE_CHAT_TRANSLATION_MUTATION = gql`
  mutation ChatTranslation_UpsertMany($brandId: ID!, $translations: [ChatTranslationInput!]!) {
    ChatTranslation_UpsertMany(brandId: $brandId, translations: $translations) {
      id
      language
      values {
        ...ChatTranslationsFragment
      }
    }
  }
  ${CHAT_TRANSLATIONS_FRAGMENT}
`;

export const UPDATE_CHAT_CONFIGURATION_MUTATION = gql`
  mutation ChatConfiguration_UpdateOne($brandId: ID!, $input: ChatConfigurationInput!) {
    ChatConfiguration_UpdateOne(brandId: $brandId, input: $input) {
      id
      chatToken
      companyName
      offlineEmailId
      logo {
        ...AttachmentFragment
      }
      primaryColor
      secondaryColor
      supportTeam
      languages {
        ...ChatLanguagesFragment
      }
      automation {
        findOrder
        returnExchange
      }
    }
  }
  ${ATTACHMENT_FRAGMENT}
  ${CHAT_LANGUAGES_FRAGMENT}
`;

export const UPDATE_CHAT_AVAILABILITY_MUTATION = gql`
  mutation ChatAvailability_Update($brandId: ID!, $input: ChatAvailabilityInput!) {
    ChatAvailability_Update(brandId: $brandId, input: $input) {
      brand
      days {
        day
        from
        isEnabled
        to
      }
      id
      timezone
    }
  }
`;

export const REPLY_COMMENT_MUTATION = gql`
  mutation Comment_ReplyByAgent($input: CommentReplyByAgentInput!) {
    Comment_ReplyByAgent(input: $input) {
      ...CommentFragment
    }
  }
  ${COMMENT_FRAGMENT}
`;

export const TICKET_COMMENT_CREATE_MUTATION = gql`
  mutation TicketComment_Create($input: TicketCommentInput!) {
    TicketComment_Create(input: $input) {
      ...MessageCommentFragment
    }
  }
  ${MESSAGE_COMMENT_FRAGMENT}
`;

export const TICKET_COMMENT_CREATE_BY_CALL_MUTATION = gql`
  mutation MessageComment_CreateByCallId($input: MessageCallCommentInput!) {
    message: MessageComment_CreateByCallId(input: $input) {
      ticket
    }
  }
`;
