import { CSSProperties, Ref, forwardRef, useCallback, useRef, useState } from 'react';

import { flip, offset, shift, useFloating } from '@floating-ui/react-dom';
import { InsertLinkShortcut } from 'icons';
import { useIntl } from 'localization';
import { Editor, Path, Transforms } from 'slate';
import { ReactEditor, useSlate } from 'slate-react';
import { twMerge } from 'tailwind-merge';
import { Tooltip } from 'ui';
import { Button, Copy, IconButton, TextInput } from 'ui/atoms';
import useClickAway from 'ui/src/hooks/useClickAway';

import { CustomElement } from '../types';
import { getSelectedLink, insertLink, isElementActive } from '../utils';

type InsertLinkButtonProps = {
  title: string;
  withTitle?: boolean;
  onClose?: () => void;
};

const InsertLinkButton = ({ title, withTitle, onClose }: InsertLinkButtonProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const { refs, floatingStyles } = useFloating({
    placement: 'top',
    middleware: [offset(4), flip(), shift()],
  });

  const editor = useSlate();

  const linkRef = useRef<HTMLDivElement | null>(null);

  useClickAway(linkRef, () => {
    if (isOpen) {
      setIsOpen(false);
    }
  });

  const toggleOpen = useCallback(() => {
    if (onClose && isOpen) {
      onClose();
    }

    ReactEditor.focus(editor);
    setIsOpen((prevState) => !prevState);
  }, [setIsOpen, onClose]);

  const linkData = useCallback(() => {
    const linkEntry = getSelectedLink(editor);

    const { selection } = editor;

    if (linkEntry) {
      const [linkNode] = linkEntry as [CustomElement, Path];
      return { url: linkNode.url, text: linkNode.children.map((x) => x.text).join('') };
    }
    return { url: '', text: selection ? Editor.string(editor, selection) : '' };
  }, [editor]);

  return (
    <div
      ref={(currentRef) => {
        linkRef.current = currentRef;
        refs.setReference(currentRef);
      }}
    >
      <Tooltip title={withTitle || isOpen ? '' : title} placement="top">
        <div className="relative">
          <IconButton
            open={isOpen || isElementActive(editor, 'link')}
            className={twMerge(
              'flex items-center h-9 w-full hover:bg-transparent active:bg-v2blueGray-100',
              !withTitle && 'justify-center w-9',
              withTitle &&
                'justify-start px-2 gap-2 active:bg-v2blueGray-100 hover:bg-v2blueGray-100',
              (isOpen || isElementActive(editor, 'link')) &&
                'bg-v2blueGray-100 hover:bg-v2blueGray-100',
            )}
            onClick={(e) => {
              e.preventDefault();
              toggleOpen();
            }}
          >
            <InsertLinkShortcut className="w-7 h-7" />
            {withTitle && <span className="capitalize">{title}</span>}
          </IconButton>
        </div>
      </Tooltip>
      {isOpen && (
        <InsertLinkForm
          className="static"
          onLinkClick={toggleOpen}
          linkData={linkData()}
          ref={refs.setFloating}
          style={{ ...floatingStyles, position: withTitle ? 'absolute' : 'fixed' }}
        />
      )}
    </div>
  );
};

const InsertLinkForm = forwardRef(
  (
    {
      onLinkClick,
      linkData,
      className,
      style,
    }: {
      onLinkClick: () => void;
      linkData: { url?: string; text?: string };
      className?: string;
      style?: CSSProperties;
    },
    ref?: Ref<HTMLDivElement>,
  ) => {
    const [linkText, setLinkText] = useState<string>(linkData?.text || '');
    const [linkUrl, setLinkUrl] = useState<string>(linkData?.url || '');

    const { formatMessage } = useIntl();

    const isEdit = !!linkData?.url;

    const editor = useSlate();

    const handleInsertLink = useCallback(() => {
      const linkEntry = getSelectedLink(editor);

      const validLink =
        linkUrl?.startsWith('http://') || linkUrl?.startsWith('https://')
          ? linkUrl
          : `https://${linkUrl}`;

      if (linkEntry) {
        const [, path] = linkEntry as [CustomElement, Path];

        Transforms.setNodes(editor, { url: validLink }, { at: path });
        Transforms.setNodes(editor, { children: [{ text: linkText }] }, { at: path });
      } else {
        insertLink(editor, validLink, linkText);
      }

      onLinkClick();
    }, [linkText, linkUrl, editor, onLinkClick]);

    const isValid = linkUrl && linkText;

    return (
      <div
        ref={ref}
        style={style}
        className={twMerge(
          'flex flex-col min-w-[332px] p-8 rounded-[22px] bg-white shadow-generic-popup overflow-hidden',
          className,
        )}
      >
        <div
          onKeyDown={(e) => {
            if (isValid && e.key === 'Enter') {
              handleInsertLink();
            }
          }}
        >
          <Copy className="ml-2 mb-4">
            {formatMessage({ id: 'desk-app.rich-editor.insert-link-modal.linkt-text.label' })}
          </Copy>
          <TextInput
            autoFocus={!isEdit && !linkData?.text}
            disabled={isEdit || !!linkData?.text}
            name="adsad"
            className="mb-6"
            placeholder={formatMessage({
              id: 'desk-app.rich-editor.insert-link-modal.linkt-text.placeholder',
            })}
            value={linkText}
            onChange={(e) => setLinkText(e.target.value)}
          />

          <Copy className="ml-2 mb-4">URL</Copy>
          <TextInput
            autoFocus={isEdit || !!linkData?.text}
            name="adsad"
            className="mb-10"
            placeholder="help.domain.com/article"
            value={linkUrl}
            onChange={(e) => setLinkUrl(e.target.value?.trim())}
          />

          <div className="flex justify-end">
            <Button disabled={!isValid} onClick={handleInsertLink}>
              {formatMessage({
                id: isEdit ? 'desk-app.rich-editor.link.edit' : 'desk-app.rich-editor.link',
              })}
            </Button>
          </div>
        </div>
      </div>
    );
  },
);

export { InsertLinkButton };
