export function History({ ...props }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="1em" height="1em" {...props}>
      <g transform="translate(-152 -691)">
        <rect
          id="Rectangle_6411"
          data-name="Rectangle 6411"
          width="24"
          height="24"
          transform="translate(152 691)"
          fill="none"
        />
        <g id="Group_10092" data-name="Group 10092" transform="translate(151.914 691)">
          <path
            id="Path_38811"
            data-name="Path 38811"
            d="M11.981,20.5a8.264,8.264,0,0,1-5.315-1.84,8.175,8.175,0,0,1-2.948-4.71.622.622,0,0,1,.1-.548.693.693,0,0,1,.5-.29.778.778,0,0,1,.55.121.844.844,0,0,1,.339.475A6.969,6.969,0,0,0,11.981,19a6.957,6.957,0,0,0,7-7,6.754,6.754,0,0,0-2.038-4.963A6.753,6.753,0,0,0,11.981,5a6.685,6.685,0,0,0-3.071.728,7.558,7.558,0,0,0-2.467,2H8.308a.75.75,0,1,1,0,1.5H4.885a.888.888,0,0,1-.9-.9V4.9a.756.756,0,0,1,1.285-.534.726.726,0,0,1,.215.534V6.523A8.488,8.488,0,0,1,8.4,4.293,8.368,8.368,0,0,1,11.981,3.5a8.254,8.254,0,0,1,3.316.669,8.532,8.532,0,0,1,4.515,4.515,8.549,8.549,0,0,1,0,6.631A8.531,8.531,0,0,1,15.3,19.831,8.254,8.254,0,0,1,11.981,20.5Zm.779-8.8,2.75,2.75a.724.724,0,0,1,.213.522.707.707,0,0,1-.213.532.747.747,0,0,1-1.054,0l-2.925-2.925a.9.9,0,0,1-.206-.306.923.923,0,0,1-.065-.342V7.75a.75.75,0,1,1,1.5,0Z"
            fill="currentColor"
          />
        </g>
      </g>
    </svg>
  );
}
