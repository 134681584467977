export default function IconDropdown({ ...props }: { className?: string }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" {...props}>
      <g transform="translate(0 2)">
        <rect width="24" height="24" transform="translate(0 -2)" fill="none" />
        <g transform="translate(0.004 -2.148)">
          <path
            d="M12,15.161a.973.973,0,0,1-.332-.058.817.817,0,0,1-.3-.2L6.858,10.4a.682.682,0,0,1-.21-.519.737.737,0,0,1,.225-.519.747.747,0,0,1,1.054,0L12,13.431l4.088-4.088a.682.682,0,0,1,.519-.21.737.737,0,0,1,.519.225.747.747,0,0,1,0,1.054l-4.494,4.494a.828.828,0,0,1-.3.2A.96.96,0,0,1,12,15.161Z"
            fill="currentColor"
          />
        </g>
      </g>
    </svg>
  );
}
