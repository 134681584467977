export default {
  'account.save': 'Saglabāt',
  'account.address.name': 'Segvārds',
  'account.address.address': 'Adrese',
  'account.address.postcode': 'Pasta indekss',
  'account.address.city': 'Pilsēta',
  'account.address.country': 'Valsts',
  'account.address.province': 'Province',
  'account.address.contactName': 'Kontaktpersona',
  'account.address.contactPhone': 'Kontakttālrunis',
};
