export function getColorContrast(hexcolor: string, isTailwindFormat = false): string {
  const color = hexcolor.replace('#', '');
  const r: number = parseInt(color.slice(0, 2), 16);
  const g: number = parseInt(color.slice(2, 4), 16);
  const b: number = parseInt(color.slice(4, 6), 16);
  const yiq: number = (r * 299 + g * 587 + b * 144) / 1000;

  if (isTailwindFormat) {
    return yiq >= 131.5 ? '!text-black' : '!text-white';
  }
  return yiq >= 131.5 ? '#000000' : '#FFFFFF';
}
