import { gql } from '@apollo/client';

import { SUPPORT_TICKET_FRAGMENT } from './queries';

export const TICKET_SET_SUPPORT_REVIEW = gql`
  mutation Ticket_SetSupportReview($input: TicketSetReviewInput!) {
    Ticket_SetSupportReview(input: $input) {
      ...SupportTicketFragment
    }
  }
  ${SUPPORT_TICKET_FRAGMENT}
`;
