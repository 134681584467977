import { createContext } from 'react';

import { CustomerStoreData } from '../types/CustomerStoreData';
import { EMPTY_STORE_DATA } from './constants';

interface IOVCStoreDataContext {
  storeData: CustomerStoreData;
}

export default createContext<IOVCStoreDataContext>({ storeData: EMPTY_STORE_DATA });
