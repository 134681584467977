import { ReactNode, memo } from 'react';

import { v2Path } from 'ui/utils';

import { useCompatibilityLink } from './link';

export const CompatibleLink = memo(
  ({ children, to, className }: { className?: string; children: ReactNode; to: string }) => {
    const Link = useCompatibilityLink();
    const pathname = window.location.pathname;
    const isV2 = !pathname.startsWith('/cms/');

    if (isV2 && to.startsWith(v2Path)) {
      return (
        <Link className={className} to={to.replace(v2Path, '')}>
          {children}
        </Link>
      );
    } else if (!isV2 && to.startsWith('/cms/')) {
      return (
        <Link className={className} to={to}>
          {children}
        </Link>
      );
    }

    return (
      <a className={className} href={to}>
        {children}
      </a>
    );
  },
);
