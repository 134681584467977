import { useCallback, useState } from 'react';

import { SubscriptionPlan } from 'app-types/core';
import { DowngradeFlowEmailType, triggerDowngradeSupportEmail, useUserProfile } from 'data-layer';
import { CalendarIcon, CallIcon } from 'icons';
import { FormattedMessage } from 'localization';
import { twMerge } from 'tailwind-merge';
import { match } from 'ts-pattern';
import { ItemCard } from 'ui';
import { Button, ButtonVariant, Copy, Heading } from 'ui/atoms';
import LayerWithSideBar from 'ui/src/molecules/LayerWithSidebar';

import { DowngradeStep } from '../../DowngradePlanFlow';
import { PriceStep } from '../../hooks/pricingBlockerHooks';
import { PricingPlanProps } from '../../types/pricingTypes';

type GetOfferProps = {
  setStep: (newStep: DowngradeStep) => void;
  from?: PricingPlanProps;
  to?: PricingPlanProps;
  onGetOffer: (gotoStep: PriceStep) => void;
  desk?: boolean;
};

const GetOffer = ({ setStep, from, to, onGetOffer, desk }: GetOfferProps) => {
  const { data: user } = useUserProfile();

  const [selectedMeetingOption, setSelectedMeetingOption] = useState<DowngradeFlowEmailType>(
    DowngradeFlowEmailType.RequestCall,
  );
  const [isSubmitting, setIsSubmitting] = useState(false);

  const onSelecteLoseOffer = useCallback(() => {
    setStep(DowngradeStep.HelpToImprove);
  }, []);

  const onSelectBookMeeting = useCallback(async () => {
    setIsSubmitting(true);

    try {
      await triggerDowngradeSupportEmail({
        type: selectedMeetingOption,
        currentPlan: from?.plan || desk ? 'Desk' : '',
        downPlan: to?.plan || 'CANCEL',
        email: user?.email || '',
        bookedTime: '',
      } as any);

      onGetOffer(
        selectedMeetingOption === DowngradeFlowEmailType.RequestCall
          ? PriceStep.RequestCall
          : PriceStep.RequestDemo,
      );
    } catch (error) {
      setIsSubmitting(false);
      console.error(error);
    }
  }, [from, onGetOffer, selectedMeetingOption, to, user, desk]);

  const isCancelSubscription = (to?.plan === SubscriptionPlan.Starter || !to?.plan) && !desk;

  const meetingOptions: {
    titleKey: string;
    icon: JSX.Element;
    value: DowngradeFlowEmailType;
  }[] = [
    {
      titleKey: 'settings.subs.downgrade.counter-offer.book-call',
      icon: <CallIcon />,
      value: DowngradeFlowEmailType.RequestCall,
    },
    {
      titleKey: 'settings.subs.downgrade.counter-offer.book-demo',
      icon: <CalendarIcon />,
      value: DowngradeFlowEmailType.RequestDemo,
    },
  ];

  const { titleKey } = match<boolean>(true)
    .with(isCancelSubscription, () => ({
      titleKey: 'settings.subs.downgrade.heading.cancel',
    }))
    .with(!!desk, () => ({
      titleKey: 'settings.subs.desk-cancel.title',
    }))
    .otherwise(() => ({
      titleKey: 'settings.subs.downgrade.heading.general',
    }));

  return (
    <LayerWithSideBar
      className="rounded-[22px]"
      sideBarContent={
        <>
          <div className="px-2 pt-2">
            <Heading className="mb-[29px] capitalize">
              <FormattedMessage id={titleKey} values={{ plan: to?.plan }} />
            </Heading>
            <Copy className="mb-10 whitespace-pre-line" type="copy7">
              <FormattedMessage
                id="settings.subs.cancel.book-meeting-description"
                values={{
                  boldText: (
                    <span className="font-medium">
                      <FormattedMessage id="settings.subs.cancel.personalised-offer-description" />
                    </span>
                  ),
                }}
              />
            </Copy>
          </div>
          <Button
            onClick={onSelecteLoseOffer}
            className="!bg-transparent hover:!bg-white/50"
            theme={ButtonVariant.Secondary}
          >
            <FormattedMessage id="settings.subs.cancel.lose-special-offer" />
          </Button>
        </>
      }
    >
      <>
        <Heading type="heading2" weight="regular" className="px-2 pt-2 mb-6">
          <FormattedMessage id="settings.subs.cancel.chose-phone-or-demo" />
        </Heading>

        <div className="flex flex-col gap-2">
          {meetingOptions.map(({ icon, titleKey, value }, index) => {
            const isSelected = selectedMeetingOption === value;

            const iconToUse = (
              <div
                className={twMerge(
                  'w-8 h-8 rounded-[10px] flex items-center justify-center text-[24px]',
                  !isSelected && 'bg-v2blueGray-100 text-v2blueGray-700 ',
                )}
              >
                {icon}
              </div>
            );

            return (
              <ItemCard
                title={
                  <div className="first-letter:capitalize">
                    <Copy as="span" noColor>
                      <FormattedMessage id={titleKey} />
                    </Copy>
                  </div>
                }
                key={`option-${titleKey}-${index}`}
                onSelect={() => setSelectedMeetingOption(value)}
                description={
                  <span>
                    + <FormattedMessage id="settings.subs.cancel.get-offer" />
                  </span>
                }
                leftIcon={iconToUse}
                isSelected={isSelected}
                type="radio"
              />
            );
          })}
        </div>

        <div className="flex-1 flex items-end">
          <Button loading={isSubmitting} onClick={onSelectBookMeeting} className="mt-4 w-full">
            <FormattedMessage id="settings.subs.cancel.get-offer" />
          </Button>
        </div>
      </>
    </LayerWithSideBar>
  );
};

export default GetOffer;
