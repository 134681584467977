import { useCallback, useContext, useEffect, useState } from 'react';

import { SubscriptionPlan } from 'app-types/core';
import { deskActivate, useGetDeskInfo } from 'data-layer';
import { FormattedMessage } from 'localization';

import { ToggleButton } from '../../../atoms';
import { SubscriptionContext } from '../../PricingPage/hooks/useSubscriptionContext';
import { PricingPlanProps } from '../../PricingPage/types/pricingTypes';
import { EPricingColors, PricingItem } from '../PricingItem';
import { DESK_PLAN_HASH } from '../constants/hashKeys';
import { useGetPlanFeatureOptions, useGetPlanPricingOptions } from '../hooks/useGetPlanOptions';

type DeskBlockProps = {
  onChangeDeskIncluded: (plan: PricingPlanProps) => void;
  paymentInPendingProcess: boolean;
  activePricingPlan: PricingPlanProps;
  isTrial: boolean;
};

const DeskBlock = ({
  onChangeDeskIncluded,
  paymentInPendingProcess,
  activePricingPlan,
  isTrial,
}: DeskBlockProps) => {
  const [deskChecked, setDeskChecked] = useState(activePricingPlan.isDeskIncluded);

  const contextData = useContext(SubscriptionContext);

  const { isCanceling } = useGetDeskInfo(contextData?.subscription);

  const { getOrderProcessingFee } = useGetPlanPricingOptions();
  const { deskPlanFeatureOptions } = useGetPlanFeatureOptions();

  const deskOrderProcessingFee = getOrderProcessingFee('deskOnly');
  const urlHash = window.location.hash;

  const handleActivateDeskOnly = useCallback(async (includedValue: boolean) => {
    await deskActivate(includedValue);

    setDeskChecked(includedValue);
  }, []);

  const handleSelectPlan = useCallback(
    (includedValue: boolean) => {
      if (isTrial) {
        handleActivateDeskOnly(includedValue);
      } else {
        const planData: PricingPlanProps = {
          plan: activePricingPlan.isActive ? activePricingPlan.plan : SubscriptionPlan.Grow,
          isDeskIncluded: includedValue,
          currency: activePricingPlan.currency,
        };

        onChangeDeskIncluded(planData);

        if (!activePricingPlan.isActive) {
          setDeskChecked(includedValue);
        }
      }
    },
    [activePricingPlan, onChangeDeskIncluded],
  );

  useEffect(() => {
    if (activePricingPlan.isActive) {
      setDeskChecked(activePricingPlan.isDeskIncluded);
    }
  }, [activePricingPlan.isDeskIncluded]);

  useEffect(() => {
    if (
      urlHash === DESK_PLAN_HASH &&
      !isCanceling &&
      !activePricingPlan.isDeskIncluded &&
      !paymentInPendingProcess
    ) {
      handleSelectPlan(true);
    }
  }, []);

  const isBlockedToChange = paymentInPendingProcess || (isCanceling && !isTrial);

  return (
    <PricingItem
      subtitle={
        <FormattedMessage
          id="settings.pricing.per-processed-order"
          values={{ value: `+ ${deskOrderProcessingFee}` }}
        />
      }
      className="rounded-[12px]"
      color={deskChecked ? EPricingColors.violet : EPricingColors.lightGray}
      title={
        <div className="flex items-center justify-between">
          <div>Desk</div>
          <ToggleButton
            disabled={isBlockedToChange}
            type="button"
            theme="violet"
            isChecked={deskChecked}
            onClick={(event) => {
              event.preventDefault();
              handleSelectPlan(!deskChecked);
            }}
          />
        </div>
      }
      featureList={deskPlanFeatureOptions}
    />
  );
};

export default DeskBlock;
