export function SocialMediaIcon({ ...props }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" width="1em" height="1em" {...props}>
      <g
        id="bar_chart_black_24dp_1_"
        data-name="bar_chart_black_24dp (1)"
        transform="translate(20) rotate(90)"
      >
        <path id="Path_16320" data-name="Path 16320" d="M0,0H20V20H0Z" fill="none" />
        <g id="Group_15032" data-name="Group 15032" transform="translate(3 2.75)">
          <path
            id="Path_40434"
            data-name="Path 40434"
            d="M0,3.9A6.042,6.042,0,0,1,.266,1.333,2.32,2.32,0,0,1,1.321.268,5.9,5.9,0,0,1,3.867,0h6.766a5.9,5.9,0,0,1,2.546.268,2.32,2.32,0,0,1,1.055,1.065A6.038,6.038,0,0,1,14.5,3.9V8.288a6.039,6.039,0,0,1-.266,2.568,2.321,2.321,0,0,1-1.055,1.065,5.9,5.9,0,0,1-2.546.268H8.6a5.158,5.158,0,0,0-.991.049A2.619,2.619,0,0,0,7,12.457a4.915,4.915,0,0,0-.806.585L4.269,14.594c-.339.268-.5.406-.645.406a.4.4,0,0,1-.314-.154c-.089-.114-.089-.325-.089-.756v-1.9a6.2,6.2,0,0,1-1.434-.081A2.446,2.446,0,0,1,.081,10.385,6.353,6.353,0,0,1,0,8.938Z"
            transform="translate(0 14.5) rotate(-90)"
            fill="none"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.64"
          />
          <path
            id="Path_40435"
            data-name="Path 40435"
            d="M3.627.773A1.98,1.98,0,0,0,.783.472,2.142,2.142,0,0,0,.461,3.454,25.464,25.464,0,0,0,2.789,5.762a1.79,1.79,0,0,0,.6.439.91.91,0,0,0,.467,0,1.705,1.705,0,0,0,.6-.439A24.473,24.473,0,0,0,6.784,3.454,2.127,2.127,0,0,0,6.462.472,1.977,1.977,0,0,0,3.627.773Z"
            transform="translate(3.046 10.877) rotate(-90)"
            fill="currentColor"
            fillRule="evenodd"
          />
        </g>
      </g>
    </svg>
  );
}
