export function AccountCircleLandscape({ ...props }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" {...props}>
      <g transform="translate(-150 -689)">
        <rect width="24" height="24" transform="translate(150 689)" fill="none" />
        <path
          d="M34.55,19.858A5.923,5.923,0,0,1,37.444,17,8.136,8.136,0,0,1,40.5,16.25a6.31,6.31,0,0,1,1.053.086,8.976,8.976,0,0,1,.993.226,8.591,8.591,0,0,0,1.041-2.074A7.439,7.439,0,0,0,44,12a7.721,7.721,0,0,0-2.325-5.675,8.088,8.088,0,0,0-11.35,0A7.721,7.721,0,0,0,28,12a7.754,7.754,0,0,0,.336,2.3,7.57,7.57,0,0,0,.949,2,8.871,8.871,0,0,1,2.014-.789,8.572,8.572,0,0,1,2.114-.261,7.862,7.862,0,0,1,1.379.118,10.606,10.606,0,0,1,1.217.285,6.225,6.225,0,0,0-.8.537,8.141,8.141,0,0,0-.717.638,3.473,3.473,0,0,0-.522-.064q-.28-.014-.553-.014a7.083,7.083,0,0,0-1.645.194,6.371,6.371,0,0,0-1.541.583A7.576,7.576,0,0,0,32.2,19.042,8,8,0,0,0,34.55,19.858ZM36,21.5a9.255,9.255,0,0,1-3.705-.748,9.486,9.486,0,0,1-5.048-5.046,9.547,9.547,0,0,1,0-7.409,9.486,9.486,0,0,1,5.046-5.048,9.546,9.546,0,0,1,7.409,0,9.485,9.485,0,0,1,5.048,5.046,9.546,9.546,0,0,1,0,7.409,9.485,9.485,0,0,1-5.046,5.048A9.245,9.245,0,0,1,36,21.5Zm-2.5-8.25A3.237,3.237,0,0,1,30.25,10a3.25,3.25,0,1,1,6.5,0,3.237,3.237,0,0,1-3.25,3.25Zm0-1.5a1.747,1.747,0,0,0,1.236-2.986,1.747,1.747,0,1,0-2.471,2.471A1.685,1.685,0,0,0,33.5,11.75Zm7,2.692A2.419,2.419,0,0,1,38.058,12a2.442,2.442,0,1,1,4.176,1.734A2.358,2.358,0,0,1,40.5,14.442Z"
          transform="translate(126 689)"
          fill="currentColor"
        />
      </g>
    </svg>
  );
}
