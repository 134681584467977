import { memo, useCallback, useEffect, useRef, useState } from 'react';

import { useClickAway } from 'react-use';

import { Transition } from '@headlessui/react';
import { twMerge } from 'tailwind-merge';
import { NavbarLogo } from 'ui/atoms';
import { useCompatibilityPathname } from 'ui/compatibility';

import { MobileMenu } from './MobileMenu';

export const MobileNavbar = memo(() => {
  const [show, toggleMenu] = useState(false);
  const pathname = useCompatibilityPathname();

  const toggle = useCallback(() => {
    toggleMenu((st) => !st);
  }, []);

  const ref = useRef(null);
  useClickAway(ref, () => {
    toggleMenu(false);
  });

  useEffect(() => {
    if (show) toggleMenu(false);
  }, [pathname]);

  return (
    <div ref={ref} className="z-20 relative w-[68px] h-[68px] flex-shrink-0">
      <div
        className={twMerge(`bg-v2blueGray-800 rounded-br-2xl z-2 relative`, show ? 'fixed' : '')}
        onClick={toggle}
      >
        <NavbarLogo />
      </div>

      <Transition
        className="bg-v2blueGray-800 rounded-r-2xl fixed h-full z-1 top-0 overflow-hidden"
        show={show}
        enter="transition-[width] duration-75"
        enterFrom="w-0"
        enterTo="w-[300px]"
        leave="transition-[width] duration-150"
        leaveFrom="w-[300px]"
        leaveTo="w-0"
      >
        <div className="px-3 pt-[68px] pb-3 h-full w-[300px]">
          <MobileMenu />
        </div>
      </Transition>
    </div>
  );
});
