import { ApolloClient, ApolloLink, HttpLink, InMemoryCache, Operation, from } from '@apollo/client';
import { defaultDataIdFromObject } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { PortalRoutes } from 'app-types';
import { getBrandHost } from 'utils/getBrandHost';
import { getAuthToken } from 'utils/ls/getAuthToken';

import {
  RETURN_PORTAL_METHODS_LS_KEY,
  RETURN_PORTAL_PRODUCTS_LS_KEY,
  RETURN_PORTAL_TOKEN_LS_KEY,
} from '../constants/localStorageKeys';

const defaultClient: keyof typeof clients = 'returns';

const clients = {
  returns: new HttpLink({
    uri:
      import.meta.env.DEV && import.meta.env.VITE_RETURNS_API
        ? import.meta.env.VITE_RETURNS_API
        : `${import.meta.env.VITE_OUTVIO_API_URL}/returns/graphql`,
  }),
  desk: new HttpLink({
    uri:
      import.meta.env.DEV && import.meta.env.VITE_DEV_DESK_API
        ? `${import.meta.env.VITE_DEV_DESK_API}/graphql`
        : `${import.meta.env.VITE_OUTVIO_API_URL}/desk/graphql`,
  }),
};

const isRequestedClient = (clientName: string) => (op: Operation) =>
  op.getContext().clientName === clientName;

const ClientResolverLink = Object.entries(clients)
  .map(([clientName, Link]) => [clientName, ApolloLink.from([Link])] as const)
  .reduce(
    ([, PreviousLink], [clientName, NextLink]) => {
      const ChainedLink = ApolloLink.split(isRequestedClient(clientName), NextLink, PreviousLink);

      return [clientName, ChainedLink];
    },
    ['_default', clients[defaultClient]],
  )[1];

const authLink = new ApolloLink((operation, forward) => {
  operation.setContext(({ headers = {} }) => {
    const token = getAuthToken();
    return {
      headers: {
        ...headers,
        ...(import.meta.env.DEV && !import.meta.env.VITE_OUTVIO_API_URL.includes('api.outvio.com')
          ? {
              'brand-host': getBrandHost(),
            }
          : {}),
        'x-access-token': token,
      },
    };
  });
  return forward(operation);
});

const errorLink = onError(({ graphQLErrors, operation }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(({ extensions }: { extensions: any }) => {
      if (extensions?.response?.statusCode === 401) {
        console.log('Token is expired');
        try {
          localStorage.removeItem(RETURN_PORTAL_TOKEN_LS_KEY);
          localStorage.removeItem(RETURN_PORTAL_METHODS_LS_KEY);
          localStorage.removeItem(RETURN_PORTAL_PRODUCTS_LS_KEY);
        } catch (err) {
          console.log(err);
        }

        if (
          window.location.pathname !== '/404' &&
          operation?.operationName === 'BrandSettingsFirstQuery'
        ) {
          return window.location.assign('/404');
        }

        if (window.location.pathname !== '/' && window.location.pathname !== '/404') {
          return window.location.assign('/');
        }
      }

      if (
        extensions?.response?.statusCode === 400 &&
        extensions?.response?.message === 'RETURNS_CACHE_NOT_FOUND'
      ) {
        console.log('Return cache id expired');
        try {
          localStorage.removeItem(RETURN_PORTAL_METHODS_LS_KEY);
          localStorage.removeItem(RETURN_PORTAL_PRODUCTS_LS_KEY);
        } catch (err) {
          console.log(err);
        }
        return window.location.assign(`/${PortalRoutes.orderList}`);
      }
    });
  }
});

const cache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        Orders_Find: {
          keyArgs: false,
          merge(existing, incoming) {
            return {
              ...incoming,
              orders: [...(existing?.orders || []), ...(incoming?.orders || [])],
            };
          },
        },
        Shopify_SearchExchangeProducts: {
          keyArgs: ['input', ['sku', 'returnRuleId', 'searchWord']], //without cursor
          merge(existing, incoming) {
            return {
              ...incoming,
              products: [...(existing?.products || []), ...(incoming?.products || [])],
            };
          },
        },
        Shopify_GetExchangeSuggestions: {
          keyArgs: ['input', ['sku', 'returnRuleId']], //without cursor
          merge(existing, incoming) {
            return {
              ...incoming,
              products: [...(existing?.products || []), ...(incoming?.products || [])],
            };
          },
        },
      },
    },
  },
  dataIdFromObject(responseObject) {
    switch (responseObject.__typename) {
      case 'OrderObject':
        return `OrderObject:${responseObject._id}.${responseObject.otn}`;
      default:
        return defaultDataIdFromObject(responseObject);
    }
  },
});

export const apolloClient = new ApolloClient({
  link: from([authLink, errorLink, ClientResolverLink]),
  cache,
  connectToDevTools: import.meta.env.DEV,
});
