import { useContext } from 'react';

import { useIntl } from 'localization';
import { IRegionalSettings } from 'tracking-fe/src';

import { OVCStoreDataContext } from '../CustomerArea';

const useGetRegionalTranslationsV2 = () => {
  const intl = useIntl();

  const language = intl.locale.split('-')[0];

  const { storeData } = useContext(OVCStoreDataContext);

  const regionalTranslations: IRegionalSettings = storeData?.portalV2?.regionalSettings?.find(
    (x: any) => x.language?.ISOCode === language || x.language?.ISOCode === intl.locale,
  );

  return regionalTranslations;
};

export default useGetRegionalTranslationsV2;
