export default function OutvioLogo({ ...props }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36" {...props}>
      <g transform="translate(-96 -18)">
        <path
          d="M17.864,19.8a2.5,2.5,0,0,0,2.926,2.925,2.4,2.4,0,0,0,1.939-1.938A2.5,2.5,0,0,0,19.8,17.866,2.4,2.4,0,0,0,17.864,19.8Z"
          transform="translate(93.72 15.719)"
          fill="#eef3f5"
        />
        <path
          d="M18.794,11.175a9.359,9.359,0,0,1,.987-.071,7.463,7.463,0,0,1,.882.071.59.59,0,0,0,.635-.349L25.387.956a.445.445,0,0,0-.236-.582.4.4,0,0,0-.045-.017A11.366,11.366,0,0,0,23.623.008a.436.436,0,0,0-.494.282l-3.314,7.9a.073.073,0,0,1-.141,0L16.29.36a.42.42,0,0,0-.528-.247C15.2.255,14.669.395,14.1.571a.445.445,0,0,0-.3.554c.005.016.01.031.017.045l4.3,9.729A.708.708,0,0,0,18.794,11.175Z"
          transform="translate(94.237 18)"
          fill="#eef3f5"
        />
        <path
          d="M28.461,18.875a9.356,9.356,0,0,1,.071.987,7.46,7.46,0,0,1-.071.882.591.591,0,0,0,.349.635l9.874,4.089a.444.444,0,0,0,.583-.236.283.283,0,0,0,.017-.045,11.221,11.221,0,0,0,.349-1.482.436.436,0,0,0-.282-.494l-7.9-3.314a.073.073,0,0,1-.051-.09.075.075,0,0,1,.051-.051l7.826-3.384a.419.419,0,0,0,.247-.528c-.141-.564-.282-1.093-.458-1.657a.443.443,0,0,0-.554-.3.4.4,0,0,0-.045.016l-9.73,4.3A.752.752,0,0,0,28.461,18.875Z"
          transform="translate(92.36 16.225)"
          fill="#eef3f5"
        />
        <path
          d="M20.7,28.46a9.325,9.325,0,0,1-.987.071,7.463,7.463,0,0,1-.882-.071.592.592,0,0,0-.635.349l-4.089,9.877a.444.444,0,0,0,.236.582.315.315,0,0,0,.045.017,11.226,11.226,0,0,0,1.483.349.436.436,0,0,0,.494-.282l3.314-7.9a.073.073,0,0,1,.09-.051.075.075,0,0,1,.051.051L23.2,39.282a.42.42,0,0,0,.528.247c.564-.141,1.093-.282,1.657-.458a.444.444,0,0,0,.3-.554.4.4,0,0,0-.017-.045l-4.3-9.726A.647.647,0,0,0,20.7,28.46Z"
          transform="translate(94.2 14.358)"
          fill="#eef3f5"
        />
        <path
          d="M25.356,12.749c.247.212.528.423.74.635a5.108,5.108,0,0,1,.564.67.6.6,0,0,0,.7.174l9.87-4.054a.436.436,0,0,0,.212-.635c-.247-.458-.494-.882-.775-1.308a.486.486,0,0,0-.564-.174L28.172,11.3a.077.077,0,0,1-.106-.03.078.078,0,0,1,0-.076l3.139-7.935a.5.5,0,0,0-.174-.564,15.847,15.847,0,0,0-1.483-.846.477.477,0,0,0-.631.24l0,.007-3.737,9.941A.577.577,0,0,0,25.356,12.749Z"
          transform="translate(92.787 17.769)"
          fill="#eef3f5"
        />
        <path
          d="M14.108,26.7c-.247-.212-.528-.423-.74-.635a5.177,5.177,0,0,1-.564-.67.6.6,0,0,0-.7-.174l-9.87,4.054a.436.436,0,0,0-.247.565.4.4,0,0,0,.035.07c.247.459.494.882.775,1.308a.486.486,0,0,0,.564.174l7.936-3.243a.078.078,0,0,1,.106.031.077.077,0,0,1,0,.075L8.258,36.192a.5.5,0,0,0,.174.564,15.654,15.654,0,0,0,1.483.846.478.478,0,0,0,.632-.24l0-.007,3.842-9.9A.887.887,0,0,0,14.108,26.7Z"
          transform="translate(95.75 14.777)"
          fill="#eef3f5"
        />
        <path
          d="M26.7,25.348c-.212.247-.423.528-.635.74a5.109,5.109,0,0,1-.67.564.6.6,0,0,0-.174.7l4.054,9.869a.436.436,0,0,0,.565.247.4.4,0,0,0,.07-.035c.458-.247.882-.494,1.308-.775a.486.486,0,0,0,.174-.564l-3.243-7.935a.078.078,0,0,1,.031-.106.077.077,0,0,1,.075,0L36.193,31.2a.5.5,0,0,0,.564-.174A15.652,15.652,0,0,0,37.6,29.54a.477.477,0,0,0-.24-.631l-.007,0-9.915-3.839A.891.891,0,0,0,26.7,25.348Z"
          transform="translate(92.78 14.792)"
          fill="#eef3f5"
        />
        <path
          d="M12.749,14.106c.212-.247.423-.528.635-.74a5.177,5.177,0,0,1,.67-.564.6.6,0,0,0,.174-.7L10.174,2.228a.436.436,0,0,0-.635-.212c-.458.247-.882.494-1.308.775a.486.486,0,0,0-.174.564L11.3,11.291a.077.077,0,0,1-.03.106.078.078,0,0,1-.076,0L3.258,8.257a.5.5,0,0,0-.564.174,16.041,16.041,0,0,0-.846,1.482.478.478,0,0,0,.24.632l.007,0L12,14.391A.8.8,0,0,0,12.749,14.106Z"
          transform="translate(95.769 17.75)"
          fill="#eef3f5"
        />
        <path
          d="M11.177,20.82a9.322,9.322,0,0,1-.071-.987,7.46,7.46,0,0,1,.071-.882.592.592,0,0,0-.349-.635L.956,14.228a.445.445,0,0,0-.583.236.474.474,0,0,0-.017.045,11.362,11.362,0,0,0-.349,1.482.435.435,0,0,0,.282.494l7.9,3.314a.073.073,0,0,1,0,.141L.36,23.324a.42.42,0,0,0-.247.528c.141.564.282,1.093.458,1.657a.445.445,0,0,0,.554.3.4.4,0,0,0,.045-.017l9.73-4.3A.79.79,0,0,0,11.177,20.82Z"
          transform="translate(96 16.183)"
          fill="#eef3f5"
        />
      </g>
    </svg>
  );
}
