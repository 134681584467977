import { useCallback, useState } from 'react';

import { SubscriptionPlan } from 'app-types/core';
import { subDeactivate } from 'data-layer';

import { PricingPlanProps } from '../../PricingPage/types/pricingTypes';
import { CancellationType } from '../../SubCancelReminder/CancellationConfirmWindow';

type SelectOrCancelPlanProps = {
  activePricingPlan: PricingPlanProps;
  onChangePlan: (selectedPlan: PricingPlanProps) => void;
  isCancelingSubscription: boolean;
  updateSubscriptionInfo: () => Promise<void>;
};

const useSelectOrCancelPlan = ({
  activePricingPlan,
  onChangePlan,
  isCancelingSubscription,
  updateSubscriptionInfo,
}: SelectOrCancelPlanProps) => {
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [cancellationType, setCancellationType] = useState<CancellationType>('downgrade-plan');

  const onChangeConfirmationWindowState = useCallback((value: boolean) => {
    setShowConfirmation(value);
  }, []);

  const handleSelectPlan = useCallback(
    (plan: SubscriptionPlan) => {
      const planData: PricingPlanProps = {
        plan,
        currency: activePricingPlan.currency,
      };

      onChangePlan(planData);
    },
    [activePricingPlan.isDeskIncluded, onChangePlan],
  );

  const onCancelPlanChanging = useCallback(async () => {
    try {
      if (isCancelingSubscription) {
        setCancellationType('cancel-subscription');
      }
      await subDeactivate(false);
      await updateSubscriptionInfo();
      onChangeConfirmationWindowState(true);
    } catch (error) {
      console.log(error);
    }
  }, [isCancelingSubscription]);

  return {
    showConfirmation,
    cancellationType,
    handleSelectPlan,
    onCancelPlanChanging,
    onChangeConfirmationWindowState,
  };
};

export { useSelectOrCancelPlan };
