import { CSSProperties, Ref, forwardRef, useCallback, useRef, useState } from 'react';

import { flip, offset, shift, useFloating } from '@floating-ui/react-dom';
import { Languages } from 'app-types/desk';
import { useUserProfile } from 'data-layer';
import { InsertSignatureShortcut } from 'icons';
import { FormattedMessage } from 'localization';
import { ReactEditor, useSlate } from 'slate-react';
import { twMerge } from 'tailwind-merge';
import { DropdownItem, Loader, Tooltip } from 'ui';
import { Copy, IconButton } from 'ui/atoms';
import useClickAway from 'ui/src/hooks/useClickAway';

import useGetSupportedLanguages from '../../../hooks/useGetSupportedLanguages';
import { insertTextAtCursor, isElementActive } from '../utils';

type InsertSignatureButtonProps = {
  title: string;
  withTitle?: boolean;
  disabled?: boolean;
  onClose?: () => void;
};

const InsertSignatureButton = ({
  title,
  withTitle,
  onClose,
  disabled,
}: InsertSignatureButtonProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const { refs, floatingStyles } = useFloating({
    placement: 'top-end',
    middleware: [offset(4), flip(), shift()],
  });

  const editor = useSlate();

  const linkRef = useRef<HTMLDivElement | null>(null);

  useClickAway(linkRef, () => {
    if (isOpen) {
      setIsOpen(false);
    }
  });

  const toggleOpen = useCallback(() => {
    if (onClose && isOpen) {
      onClose();
    }

    ReactEditor.focus(editor);
    setIsOpen((prevState) => !prevState);
  }, [setIsOpen, onClose]);

  return (
    <div
      ref={(currentRef) => {
        linkRef.current = currentRef;
        refs.setReference(currentRef);
      }}
    >
      <Tooltip title={withTitle || isOpen || disabled ? '' : title} placement="top">
        <div className="relative">
          <IconButton
            disabled={disabled}
            open={isOpen || isElementActive(editor, 'link')}
            className={twMerge(
              'flex items-center h-9 w-full hover:bg-transparent  text-v2blueGray-550',
              disabled && 'cursor-not-allowed text-v2blueGray-350',
              !disabled && 'active:bg-v2blueGray-100',
              !withTitle && 'justify-center w-9',
              withTitle &&
                'justify-start px-2 gap-2 active:bg-v2blueGray-100 hover:bg-v2blueGray-100',
              (isOpen || isElementActive(editor, 'link')) &&
                'bg-v2blueGray-100 hover:bg-v2blueGray-100',
            )}
            onClick={(e) => {
              e.preventDefault();
              toggleOpen();
            }}
          >
            <InsertSignatureShortcut className="w-7 h-7" />
            {withTitle && <span className="capitalize">{title}</span>}
          </IconButton>
        </div>
      </Tooltip>
      {isOpen && (
        <InsertSignatureDropdown
          className="static"
          onLinkClick={toggleOpen}
          ref={refs.setFloating}
          style={{ ...floatingStyles, position: withTitle ? 'absolute' : 'fixed' }}
        />
      )}
    </div>
  );
};

const InsertSignatureDropdown = forwardRef(
  (
    {
      onLinkClick,
      className,
      style,
    }: {
      onLinkClick: () => void;
      className?: string;
      style?: CSSProperties;
    },
    ref?: Ref<HTMLDivElement>,
  ) => {
    const languageOptions = useGetSupportedLanguages();

    const { data: userProfile, loading: isLoading } = useUserProfile();

    const filteredOptions = languageOptions?.filter((option) => {
      return userProfile?.signatures?.some((signature) => signature?.language === option.value);
    });

    const editor = useSlate();

    const onAddNewLanguage = useCallback(
      (language: Languages) => {
        const signatureToInsert = userProfile?.signatures?.find(
          (signature) => signature?.language === language,
        );

        insertTextAtCursor(editor, signatureToInsert?.signature || '');
        onLinkClick();
      },
      [editor, onLinkClick, userProfile],
    );

    if (isLoading) {
      return <Loader />;
    }

    return (
      <div
        ref={ref}
        style={style}
        className={twMerge(
          'flex flex-col min-w-[152px] rounded-[14px] bg-white shadow-generic-popup overflow-hidden',
          className,
        )}
      >
        <div className="h-full overflow-y-auto px-2 py-[10px] max-h-[300px]">
          {filteredOptions?.map((item, index) => (
            <DropdownItem onClick={() => onAddNewLanguage(item.value)} icon={item.icon} key={index}>
              {item.label}
            </DropdownItem>
          ))}

          {!filteredOptions?.length && (
            <Copy className="p-8">
              <FormattedMessage id="desk-app.no-signatures" />
            </Copy>
          )}
        </div>
      </div>
    );
  },
);

export { InsertSignatureButton };
