export default {
  FALLBACK_ERROR_MESSAGE: 'Kažkas ne taip. Pabandykite dar kartą vėliau.',
  COURIER_ERROR_FALLBACK: 'Siuntos sekimo klaida.',
  TOKEN_NOT_FOUND: 'Prieigos raktas nerastas.',
  IDENTITY_VERIFICATION_FAILED: 'Nepavyko užbaigti tapatybės patvirtinimo.',
  ACCOUNT_BLOCKED: 'Jūsų paskyra užblokuota. Prašome patikrinti savo el. paštą.',
  WRONG_EMAIL_OR_PASS: 'El. paštas ir (arba) slaptažodis yra neteisingi.',
  INVALID_CREDENTIALS: 'Neteisingi prisijungimo duomenys.',
  INVALID_TOKEN:
    'Sesijos laikas baigėsi, paprašykite naujos el. pašto patvirtinimo nuorodos prekių grąžinimo portale.',
  MALFORMED_TOKEN: 'Netinkamai suformuotas prieigos raktas.',
  CUSTOMER_ALREADY_CREATED: 'Kliento paskyra jau sukurta. Prašome pabandyti prisijungti.',
  SHIPPING_METHOD_NOT_SUPPORTED: 'Siuntimo būdas nepalaikomas.',
  SHIPMENT_ALREADY_PICKED_UP: 'Siunta jau paimta.',
  NO_RATE_FOR_DESTINATION: 'Paskirties vietai tarifas negalimas.',
  BUILDING_LABEL_FAILED: 'Nepavyko sukurti etiketės.',
  COURIER_NOT_SUPPORTED: 'Kurjeris nepalaikomas.',
  PICKUP_REQUEST_FAILED: 'Atsiėmimo užklausa nepavyko.',
  ERROR_TRY_AGAIN_LATER: 'Kažkas ne taip. Pabandykite dar kartą vėliau.',
  EMAIL_ALREADY_REGISTERED: 'El. paštas jau užregistruotas.',
  INVALID_PASSWORD: 'Neteisingas slaptažodis.',
  LABEL_ERROR: 'Etiketės klaida.',
  UNDER_MAINTENANCE: 'Vykdome priežiūros darbus. „Outvio“ netrukus vėl bus pasiekiamas.',
  MERGING_PDFS_FAILED: 'Nepavyko sujungti .pdf failų',
  EXPIRE_TOKEN: 'Prieigos rakto galiojimo laikas baigėsi',
  GENERIC_MISSING_PARAM: 'Trūksta būtinų parametrų.',
  ORDER_NOT_FOUND: 'Užsakymas nerastas.',
};
