export default {
  FALLBACK_ERROR_MESSAGE: 'Jokin meni pieleen. Yritä myöhemmin uudelleen.',
  COURIER_ERROR_FALLBACK: 'Kuriirivirhe.',
  TOKEN_NOT_FOUND: 'Tunnistetta ei löytynyt.',
  IDENTITY_VERIFICATION_FAILED: 'Henkilöllisyyden varmennusta ei kyetty suorittamaan.',
  ACCOUNT_BLOCKED: 'Tilisi on estetty. Tarkistathan sähköpostisi.',
  WRONG_EMAIL_OR_PASS: 'Sähköpostiosoite ja/tai salasana on väärin.',
  INVALID_CREDENTIALS: 'Virheelliset valtuutukset.',
  INVALID_TOKEN:
    'Istunto vanhentunut, ole hyvä ja pyydä uutta varmennussähköpostia palautusportaalista.',
  MALFORMED_TOKEN: 'Vioittunut tunniste.',
  CUSTOMER_ALREADY_CREATED: 'Asiakastili jo luotu. Kirjauduthan sisään.',
  SHIPPING_METHOD_NOT_SUPPORTED: 'Toimitustapa ei ole käytössä.',
  SHIPMENT_ALREADY_PICKED_UP: 'Toimitus on jo noudettu.',
  NO_RATE_FOR_DESTINATION: 'Hintaa ei ole saatavilla kyseiseen kohteeseen.',
  BUILDING_LABEL_FAILED: 'Lapun luonti epäonnistui.',
  COURIER_NOT_SUPPORTED: 'Kuriiri ei ole käytössä.',
  PICKUP_REQUEST_FAILED: 'Noutopyyntö epäonnistui.',
  ERROR_TRY_AGAIN_LATER: 'Jokin meni pieleen. Yritä myöhemmin uudelleen.',
  EMAIL_ALREADY_REGISTERED: 'Sähköpostiosoite on jo rekisteröity.',
  INVALID_PASSWORD: 'Virheellinen salasana.',
  LABEL_ERROR: 'Merkintävirhe.',
  UNDER_MAINTENANCE: 'Sivustoamme huolletaan. Outvio palaa pian.',
  MERGING_PDFS_FAILED: 'Pdf-tiedostojen yhdistäminen epäonnistui',
  EXPIRE_TOKEN: 'Tunniste vanhentunut',
  GENERIC_MISSING_PARAM: 'Vaaditut parametrit puuttuvat.',
  ORDER_NOT_FOUND: 'Tilausta ei löytynyt.',
};
