import React, { forwardRef } from 'react';

import Card from 'tracking-fe/src/Components/Common/Card';
import { Heading } from 'ui/atoms';

import { useGetPaletteColors, useGetRegionalTranslationsV2 } from '../hooks';

interface IOVCAuthBox {
  children: React.ReactNode;
  formWidth?: number;
}

const OVCAuthBox = forwardRef<HTMLDivElement, IOVCAuthBox>(({ children }, ref) => {
  const { primaryText } = useGetPaletteColors();
  const regionalTranslations = useGetRegionalTranslationsV2();

  return (
    <div ref={ref} className="md:w-[400px] w-full py-8">
      <Card hasBottomBorder={false} className="flex flex-col pt-8 pb-6 px-6">
        <>
          <Heading style={{ color: primaryText }} className="text-center" weight="semibold">
            {regionalTranslations?.loginPageInfo?.titleTrackingPortal || ''}
          </Heading>
          <div className="flex flex-col items-center justify-center gap-[5px]">{children}</div>
        </>
      </Card>
    </div>
  );
});

export default OVCAuthBox;
