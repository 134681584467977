import { useCallback, useContext } from 'react';

import { StoreCurrency, SubscriptionPlan } from 'app-types/core';
import { useIntl } from 'localization';
import { match } from 'ts-pattern';

import currencyToSymbol from '../../../../utils/currencyToSymbol';
import { SubscriptionContext } from '../../PricingPage/hooks/useSubscriptionContext';

const ORDER_PROCESSING_FEE = 0.1;
const DESK_ORDER_PROCESSING_FEE = 0.05;

type OrderProcessingFeeType = 'plan' | 'planWithDesk' | 'deskOnly';
type PeriodType = 'monthly' | 'yearly';

const orderProcessingFee = (
  type: OrderProcessingFeeType,
  currency: StoreCurrency,
  deskFee: number,
) => {
  const deskFeeToUse = deskFee || DESK_ORDER_PROCESSING_FEE;

  const result = match(type)
    .with('plan', () => ORDER_PROCESSING_FEE)
    .with('planWithDesk', () => deskFeeToUse + ORDER_PROCESSING_FEE)
    .with('deskOnly', () => deskFeeToUse)
    .exhaustive();

  return currencyToSymbol(currency, result.toFixed(2));
};

const useGetPlanFeatureOptions = () => {
  const { formatMessage } = useIntl();

  const growPlanFeatureOptions = new Array(6)
    .fill(undefined)
    .map((_, index) => formatMessage({ id: `settings.pricing.grow.${index + 1}` }));

  const conquerPlanFeatureOptions = new Array(8)
    .fill(undefined)
    .map((_, index) => formatMessage({ id: `settings.pricing.conquer.${index + 1}` }));

  const deskPlanFeatureOptions = new Array(8)
    .fill(undefined)
    .map((_, index) => formatMessage({ id: `settings.pricing.desk.${index + 1}` }));

  return { growPlanFeatureOptions, conquerPlanFeatureOptions, deskPlanFeatureOptions };
};

const useGetPlanPricingOptions = () => {
  const contextData = useContext(SubscriptionContext);

  const deskFee =
    contextData?.subscription?.addons?.find((addon) => addon.type === 'desk')?.options?.fee || 0;

  const growPlan = contextData?.plans?.find((plan) => plan.name === SubscriptionPlan.Grow);
  const conquerPlan = contextData?.plans?.find((plan) => plan.name === SubscriptionPlan.Conquer);

  const orderProcessingFeePrice = (type?: OrderProcessingFeeType) =>
    orderProcessingFee(
      type || 'plan',
      (growPlan?.currency as StoreCurrency) || StoreCurrency.Eur,
      deskFee,
    );

  return {
    growPlanPrice: growPlan?.price,
    growPlanDiscontedPrice: growPlan?.discountedPrice,
    conquerPlanPrice: conquerPlan?.price,
    conquerPlanDiscountedPrice: conquerPlan?.price,
    getOrderProcessingFee: orderProcessingFeePrice,
    currency: (contextData?.subscription?.currency as StoreCurrency) || StoreCurrency.Eur,
  };
};

const useGetSpecificPlanPrice = () => {
  const contextData = useContext(SubscriptionContext);

  const deskFee =
    contextData?.subscription?.addons?.find((addon) => addon.type === 'desk')?.options?.fee || 0;

  const { formatMessage } = useIntl();

  const getPricingData = useCallback(() => {
    const getPrice = (name: SubscriptionPlan, period: PeriodType, addPeriodSuffix?: boolean) => {
      const selectedPlan = contextData?.plans?.find((plan) => plan.name === name);

      const monthPrice = currencyToSymbol(
        (selectedPlan?.currency as StoreCurrency) || StoreCurrency.Eur,
        selectedPlan?.price || 0,
      );
      const yearlyPrice = currencyToSymbol(
        (selectedPlan?.currency as StoreCurrency) || StoreCurrency.Eur,
        selectedPlan?.yearlyPrice || 0,
      );

      const isYearlyPrice = period === 'yearly';

      const resultPrice = isYearlyPrice ? yearlyPrice : monthPrice;
      return addPeriodSuffix
        ? formatMessage(
            { id: isYearlyPrice ? 'settings.subs.per-year' : 'settings.subs.per-month' },
            { price: resultPrice },
          )
        : resultPrice;
    };

    // TODO: maybe need to fix "0 EUR" in the future if we will support "desk only"-plan
    const deskPriceValue = currencyToSymbol(
      (contextData?.plans[0]?.currency as StoreCurrency) || StoreCurrency.Eur,
      0,
    );
    const deskPrice = formatMessage({ id: 'settings.subs.per-month' }, { price: deskPriceValue });

    const orderProcessingFeePrice = (type?: OrderProcessingFeeType) =>
      orderProcessingFee(
        type || 'plan',
        (contextData?.plans[0]?.currency as StoreCurrency) || StoreCurrency.Eur,
        deskFee,
      );

    return {
      getPrice,
      getOrderProcessingFee: orderProcessingFeePrice,
      deskPrice,
      currency: (contextData?.plans[0]?.currency as StoreCurrency) || StoreCurrency.Eur,
    };
  }, []);

  return { getPricingData };
};

export { useGetPlanFeatureOptions, useGetPlanPricingOptions, useGetSpecificPlanPrice };
