import { Subscription } from 'app-types';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);

const useGetDeskInfo = (subscription?: Subscription) => {
  const hasActivatedDesk =
    !!subscription?.isActive &&
    !!subscription?.addons?.some(
      (addon) =>
        addon.type === 'desk' && (dayjs.utc(addon.expiredOn).isAfter() || !addon.expiredOn),
    );

  const isCanceling =
    !!subscription?.isActive &&
    !!subscription?.addons?.some((addon) => addon.type === 'desk' && !!addon.expiredOn);

  return { hasActivatedDesk, isCanceling };
};

export { useGetDeskInfo };
