export default {
  'account.save': 'Speichern',
  'account.address.name': 'Alias',
  'account.address.address': 'Adresse',
  'account.address.postcode': 'Postleitzahl',
  'account.address.city': 'Stadt',
  'account.address.country': 'Land',
  'account.address.province': 'Verwaltungseinheit',
  'account.address.contactName': 'Kontaktperson',
  'account.address.contactPhone': 'Kontakttelefon',
};
