export default {
  'account.save': 'Salva',
  'account.address.name': 'Pseudonimo',
  'account.address.address': 'Indirizzo',
  'account.address.postcode': 'Codice postale',
  'account.address.city': 'Città',
  'account.address.country': 'Paese',
  'account.address.province': 'Provincia',
  'account.address.contactName': 'Persona di contatto',
  'account.address.contactPhone': 'Telefono di contatto',
};
