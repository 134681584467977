export function LiveChatIcon({ ...props }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="1em" height="1em" {...props}>
      <g id="Group_18126" data-name="Group 18126" transform="translate(13089 3635)">
        <rect
          id="Rectangle_3785"
          data-name="Rectangle 3785"
          width="24"
          height="23.977"
          transform="translate(-13089 -3635)"
          fill="none"
        />
        <path
          id="Path_40425"
          data-name="Path 40425"
          d="M-13078.848-3621.707l1.162-1.075,7.253.187.789,1.146-.316,5.2-2.236.5v1.535l-2.193-1.72h-3.684l-1.09-1.247Z"
          transform="translate(1.143 -0.303)"
          fill="currentColor"
        />
        <path
          id="Path_40424"
          data-name="Path 40424"
          d="M2888.4,1196.4l-2.464,2.488c-.336.352-.512.528-.656.536a.4.4,0,0,1-.336-.136c-.1-.112-.1-.36-.1-.848v-1.248a.861.861,0,0,0-.792-.816h0a2.392,2.392,0,0,1-2.032-2.032,6.245,6.245,0,0,1-.024-.792v-3.712a5.89,5.89,0,0,1,.264-2.528,2.291,2.291,0,0,1,1.048-1.048,5.9,5.9,0,0,1,2.528-.264h5.92a5.9,5.9,0,0,1,2.528.264,2.291,2.291,0,0,1,1.048,1.048,5.89,5.89,0,0,1,.264,2.528v3.36m0,8.8-1.744-1.208a3.354,3.354,0,0,0-.5-.32,1.959,1.959,0,0,0-.368-.112,2.822,2.822,0,0,0-.592-.032h-1.44a4.128,4.128,0,0,1-1.688-.168,1.64,1.64,0,0,1-.7-.7,3.954,3.954,0,0,1-.176-1.688v-2.008a3.955,3.955,0,0,1,.176-1.688,1.623,1.623,0,0,1,.7-.7,3.956,3.956,0,0,1,1.688-.176h4.48a3.957,3.957,0,0,1,1.688.176,1.625,1.625,0,0,1,.7.7,3.96,3.96,0,0,1,.176,1.688v2.168a4.07,4.07,0,0,1-.12,1.416,1.6,1.6,0,0,1-.864.864,4.082,4.082,0,0,1-1.416.12Z"
          transform="translate(-15966.5 -4816.512)"
          fill="none"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.64"
        />
      </g>
    </svg>
  );
}
