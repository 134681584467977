import React, { ReactNode } from 'react';

import { IPortalSettings } from '../Interfaces';
import { EDIT_MODE_TABS, IModuleTopValue } from '../Interfaces/IEditModal';
import { Features } from '../Interfaces/IFeatures';
import { IUploadFileResponse } from '../Interfaces/IRequests';

export interface IEditModalContext {
  addModuleTopValue(module: IModuleTopValue): void;
  goToModule(id: EDIT_MODE_TABS): void;
  formData: IPortalSettings;
  /**
   * No workaround any here since it's a dependency's interface.
   */
  /* eslint-disable @typescript-eslint/no-explicit-any */
  setFieldValue(field: string, value: any, shouldValidate?: boolean | undefined): void;
  /* eslint-enable @typescript-eslint/no-explicit-any */
  uploadFileRequest(props: { file: File; maxResolution?: number }): Promise<IUploadFileResponse>;
  upgradeButton: ReactNode;
  features: Features;
  currency: string;
}

const EditModalContext = React.createContext({} as IEditModalContext);

export default EditModalContext;
