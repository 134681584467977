import { ReactNode, memo } from 'react';

import { useIntl } from 'localization';

const ordinalSuffixes = {
  en: {
    one: 'st',
    two: 'nd',
    few: 'rd',
    other: 'th',
  },
};

interface DateTimeProps {
  value: string;
  showTime?: boolean;
  showDate?: boolean;
  separator?: ReactNode;
  separatorDate?: ReactNode;
  reverse?: boolean;
  month?: 'numeric' | '2-digit' | 'narrow' | 'short' | 'long';
  showDay?: boolean;
  showYear?: boolean;
  showDayOrdinalSuffix?: boolean;
  as?: 'div' | 'span';
}

const DateTime = memo(
  ({
    value,
    showTime = true,
    reverse = false,
    showDate = true,
    showDay = true,
    separator = '\n',
    month = '2-digit',
    separatorDate = '.',
    showYear = true,
    showDayOrdinalSuffix = false,
    as: Tag = 'div',
  }: DateTimeProps) => {
    const { locale } = useIntl();

    //ADD useMemo when react versions will be equal
    const getTimeData = () => {
      try {
        if (!value) return [];
        const v = new Date(value);
        const y = new Intl.DateTimeFormat(locale, { year: 'numeric' }).format(v);
        const m = new Intl.DateTimeFormat(locale, { month: month }).format(v);
        let d = new Intl.DateTimeFormat(locale, { day: '2-digit' }).format(v);
        if (showDayOrdinalSuffix) {
          const langSubtag = locale.split('-')[0];
          const plural = new Intl.PluralRules(locale, { type: 'ordinal' }).select(+d);
          if (langSubtag in ordinalSuffixes) {
            d = `${d}${
              ordinalSuffixes[langSubtag as keyof typeof ordinalSuffixes][
                plural as keyof typeof ordinalSuffixes.en
              ]
            }`;
          }
        }
        const time = new Intl.DateTimeFormat(locale, {
          hour: 'numeric',
          minute: 'numeric',
          hour12: false,
        }).format(v);
        return [
          time,
          `${showDay ? d : ''}${separatorDate}${m}${separatorDate}${showYear ? y : ''}`,
        ];
      } catch (err) {
        return value;
      }
    };

    const [time, date] = getTimeData();

    if (!time) return <Tag>-</Tag>;

    return (
      <Tag className="whitespace-pre-line">
        {showTime ? (reverse ? date : time) : ''}
        {showTime && showDate ? separator : ''}
        {showDate ? (reverse ? time : date) : ''}
      </Tag>
    );
  },
);

export { DateTime };
