import { gql } from '@apollo/client';

export const MESSAGE_FRAGMENT = gql`
  fragment MessageFragment on MessageObject {
    attachments {
      key
      originalFilename
      size
      type
      url
    }
    body
    bodyJson
    id
    type
    createdBy
    createdAt
  }
`;

export const GET_SUPPORT_TICKET_MESSAGES_QUERY = gql`
  query Message_FindAllBySupportTicketId($ticketId: String!) {
    messages: Message_FindAllBySupportTicketId(ticketId: $ticketId) {
      ...MessageFragment
    }
  }
  ${MESSAGE_FRAGMENT}
`;

export const CREATE_SUPPORT_MESSAGE_BY_RECIPIENT = gql`
  mutation Message_ReplyBySupport($input: MessageReplyBySupportInput!) {
    message: Message_ReplyBySupport(input: $input) {
      ...MessageFragment
    }
  }
  ${MESSAGE_FRAGMENT}
`;
