import { gql } from '@apollo/client';
import { PORTAL_RETURN_FRAGMENT } from 'api/queries/fragments';

export const PORTAL_RETURN_QUERY = gql`
  query Returns_GetOneById_Query($input: IdInput!) {
    Returns_GetOneById(input: $input) {
      ...PortalReturn
    }
  }
  ${PORTAL_RETURN_FRAGMENT}
`;

export const ORDER_LIST_QUERY = gql`
  query OrdersFind($pagination: PaginationInput!) {
    Orders_Find(pagination: $pagination) {
      orders {
        _id
        displayedOrderId
        otn
        createdAt
        total
        otn
        currency
        products
        returnData {
          hasExchange
          isReturnable
          hasReturn
          lastReturnOtn
        }
      }
      count
    }
  }
`;

export const BRAND_FIND_BY_URI_QUERY = gql`
  query Brand_Find_By_URI {
    data: brand_findByUri {
      isTracking
    }
  }
`;

export const CALCULATE_CREDIT_QUERY = gql`
  query Calculate_Credit($input: ReturnsCalculateAvailableCreditInput!) {
    Returns_CalculateAvailableCredit(input: $input)
  }
`;
