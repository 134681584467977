import {
  CSSProperties,
  ReactElement,
  Ref,
  forwardRef,
  useCallback,
  useMemo,
  useRef,
  useState,
} from 'react';

import { twMerge } from 'tailwind-merge';

import { EmojiGroup, emojisData } from './EmojiPicker.data';

interface EmojiGroupTab {
  emojiGroup: EmojiGroup;
  onClick: (emoji: string) => void;
}

const EmojiGroupTab = ({ emojiGroup, onClick }: EmojiGroupTab): ReactElement => {
  const handleOnClick = useCallback(
    (emoji: string) => {
      onClick(emoji);
    },
    [emojiGroup, onClick],
  );

  return (
    <div
      className="grid grid-cols-auto-fill justify-between"
      style={{
        gridTemplateColumns: 'repeat(auto-fill, 40px)',
      }}
    >
      {emojisData[emojiGroup].map((emojiUnicode: string) => {
        const emojiChar = emojiUnicode
          .split('-')
          .map((hex: string) => String.fromCodePoint(parseInt(hex, 16)))
          .join('');
        return (
          <div
            key={emojiUnicode}
            className="font-sans inline-flex items-center justify-center w-[40px] h-[40px] text-[18px] hover:bg-v2blueGray-100 hover:cursor-pointer text-v2blueGray-500 rounded-lg"
            onClick={() => handleOnClick(emojiChar)}
          >
            {emojiChar}
          </div>
        );
      })}
    </div>
  );
};

interface EmojiTabButton {
  id?: string;
  emojiGroup: EmojiGroup;
  index: number;
  isSelected: boolean;
  onClick: (group: EmojiGroup) => void;
}

const EmojiTabButton = ({
  id,
  emojiGroup,
  index,
  isSelected,
  onClick,
}: EmojiTabButton): ReactElement => {
  const emojiChar = useMemo(() => {
    return emojisData[emojiGroup][index]
      .split('-')
      .map((hex: string) => String.fromCodePoint(parseInt(hex, 16)))
      .join('');
  }, [emojiGroup, index]);

  const handleOnClick = useCallback(() => {
    onClick(emojiGroup);
  }, [emojiGroup, onClick]);

  return (
    <div
      key={id || emojiChar}
      className={twMerge(
        'font-sans inline-flex items-center justify-center w-[12%] aspect-square hover:bg-v2blueGray-100 text-v2blueGray-500 rounded-lg hover:cursor-pointer',
        isSelected && 'bg-v2blueGray-100',
      )}
      onClick={handleOnClick}
    >
      {emojiChar}
    </div>
  );
};

type EmojiSelectorTab = {
  group: EmojiGroup;
  index: number;
};

export const emojiTabs: Array<EmojiSelectorTab> = [
  { group: EmojiGroup.SmileysPeople, index: 0 },
  { group: EmojiGroup.AnimalsNature, index: 54 },
  { group: EmojiGroup.FoodDrink, index: 48 },
  { group: EmojiGroup.TravelPlaces, index: 126 },
  { group: EmojiGroup.Activities, index: 27 },
  { group: EmojiGroup.Objects, index: 104 },
  { group: EmojiGroup.Symbols, index: 121 },
  { group: EmojiGroup.Flags, index: 0 },
];

interface Props {
  className?: string;
  onEmojiClick?: (emoji: string) => void;
  style?: CSSProperties;
}

export const EmojiPicker = forwardRef(
  ({ className, onEmojiClick, style }: Props, ref?: Ref<HTMLDivElement>) => {
    const [currentTab, setCurrentTab] = useState<EmojiGroup>(EmojiGroup.SmileysPeople);
    const scrollContainerRef = useRef<HTMLDivElement>(null);

    const emojiTabClick = useCallback(
      (group: EmojiGroup) => {
        setCurrentTab(group);

        if (scrollContainerRef.current) {
          scrollContainerRef.current.scrollTop = 0;
        }
      },
      [setCurrentTab],
    );

    const handleOnEmojiClick = useCallback(
      (emoji: string) => {
        onEmojiClick && onEmojiClick(emoji);
      },
      [setCurrentTab, onEmojiClick],
    );

    return (
      <div
        ref={ref}
        style={style}
        data-testid="outvioui--emojipicker"
        className={twMerge(
          'flex flex-col w-[280px] h-[400px] pl-2 pb-2 rounded-[14px] bg-white shadow-generic-popup overflow-hidden',
          className,
        )}
      >
        <div
          data-testid="outvioui--emojipicker-current-tab-wrapper"
          className="w-full h-full pt-2 pr-2  overflow-y-auto"
          ref={scrollContainerRef}
        >
          <EmojiGroupTab emojiGroup={currentTab} onClick={handleOnEmojiClick} />
        </div>
        <div
          data-testid="outvioui--emojipicker-current-nav-wrapper"
          className="flex justify-between pr-2 pt-2"
        >
          {emojiTabs.map(({ group, index }) => (
            <EmojiTabButton
              key={group}
              emojiGroup={group}
              index={index}
              isSelected={currentTab === group}
              onClick={emojiTabClick}
            />
          ))}
        </div>
      </div>
    );
  },
);
