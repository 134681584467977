import { memo } from 'react';

import noop from 'lodash-es/noop';

import { CheckSquare } from 'icons';
import { FormattedMessage } from 'localization';
import { match } from 'ts-pattern';

import { Copy, DialogContainer } from '../../atoms';
import { ConfirmationWindow } from '../../molecules';

export type CancellationType = 'cancel-subscription' | 'downgrade-plan' | 'cancel-desk';

type CancellationConfirmWindowProps = {
  isOpen: boolean;
  onClose: () => void;
  cancellationType: CancellationType;
};

const CancellationConfirmWindow = memo(
  ({ isOpen, onClose, cancellationType }: CancellationConfirmWindowProps) => {
    const { titleKey, descriptionKey } = match(cancellationType)
      .with('cancel-subscription', () => ({
        titleKey: 'settings.subs.cancel.termination.title',
        descriptionKey: 'settings.subs.cancel.termination.description',
      }))
      .with('downgrade-plan', () => ({
        titleKey: 'settings.subs.downgrade.termination.title',
        descriptionKey: 'settings.subs.cancel.termination.description',
      }))
      .with('cancel-desk', () => ({
        titleKey: 'settings.subs.desk-cancel.termination.title',
        descriptionKey: 'settings.subs.desk-cancel.termination.description',
      }))
      .exhaustive();

    return (
      <DialogContainer
        className="max-w-[448px] rounded-[22px]"
        wrapperClassName="p-0 md:p-4 py-10"
        isOpen={isOpen}
        handleClose={noop}
        zIndex={1001}
      >
        <ConfirmationWindow
          headingIcon={<CheckSquare className="text-2xl" />}
          theme="green"
          title={<FormattedMessage id={titleKey} />}
          content={
            <Copy>
              <FormattedMessage id={descriptionKey} />
            </Copy>
          }
          cancelButtonTitle="close"
          onCancel={onClose}
        />
      </DialogContainer>
    );
  },
);

export { CancellationConfirmWindow };
