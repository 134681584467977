import { type HTMLProps } from 'react';

import { twMerge } from 'tailwind-merge';
import { match } from 'ts-pattern';

export interface _ToggleButtonProps {
  className?: string;
  isChecked?: boolean;
  type?: 'button' | 'submit' | 'reset';
  theme?: 'primary' | 'violet';
}

export type ToggleButtonProps = HTMLProps<HTMLButtonElement> & _ToggleButtonProps;

export function ToggleButton({
  className,
  isChecked = false,
  theme = 'primary',
  ...otherProps
}: ToggleButtonProps) {
  const themeStyling = match(theme)
    .with(
      'primary',
      () =>
        `bg-[#DBF0F0] [&>span]:bg-primary [&>span]:disabled:bg-primary/30 
        [&>span]:hover:translate-x-[15px] [&>span]:disabled:hover:translate-x-[100%]`,
    )
    .with(
      'violet',
      () => `bg-v2violet-650/20 [&>span]:bg-v2violet-650 [&>span]:disabled:bg-v2violet-650/30`,
    )
    .exhaustive();

  return (
    <button
      data-testid="outvioui--togglebutton"
      className={twMerge(
        'relative w-9 h-[18px] bg-[#C5D4DB] disabled:bg-[#C5D4DB]/20 rounded-[10px] transition-colors ease-in-out duration-200 [&:disabled]:cursor-not-allowed',
        !isChecked &&
          '[&>span]:bg-white [&>span]:hover:translate-x-[3px] [&>span]:disabled:hover:translate-x-[0%]',
        isChecked && themeStyling,
        className,
      )}
      {...otherProps}
    >
      <span
        className={twMerge(
          'absolute top-0 left-0 block w-[18px] h-[18px] rounded-[9px] pointer-events-none transform ease-in-out duration-200',
          isChecked
            ? 'translate-x-[100%] filter drop-shadow-[0_4px_8px_rgba(75,180,179,0.3)]'
            : 'translate-x-[0%] filter drop-shadow-[0_4px_8px_rgba(31,60,78,0.2)]',
        )}
      />
    </button>
  );
}

export default ToggleButton;
