import { SubscriptionPlan } from 'app-types/core';
import { request } from 'ui';

export const getSubscription = () =>
  request(`${process.env.OUTVIO_API_URL}/subscription`, {
    method: 'GET',
    secure: true,
  });

let tid: NodeJS.Timeout | undefined = undefined;

export const changePlanTo = ({
  targetPlan,
  period,
  deskIncluded,
}: {
  targetPlan: SubscriptionPlan;
  period: 'monthly' | 'yearly';
  deskIncluded?: boolean;
}): Promise<{ isFirstSubscription: boolean; success: boolean }> => {
  const data =
    deskIncluded !== undefined
      ? { plan: targetPlan, period, deskIncluded }
      : { plan: targetPlan, period };

  // prevent from submit twice
  // temporarily fix!
  // bug to fix: when we add new payment method from modal, by some reason we display second modal
  // which is triggering to submit payment twice
  // src/modules/CmsModule/components/Settings/SubscriptionSettingsComponents/SubscriptionTab.tsx
  return new Promise((res, rej) => {
    if (tid) return res({ success: true, isFirstSubscription: false });
    tid = setTimeout(() => {
      request(`${process.env.OUTVIO_API_URL}/subscription`, {
        method: 'PUT',
        data,
        secure: true,
      })
        .then((response) => {
          tid = undefined;
          res(response);
        })
        .catch((error) => {
          tid = undefined;
          rej(error);
        });
    }, 1000);
  });
};

export enum SubscriptionGAEvent {
  trial = 'trial',
  firstPurchasePedding = 'firstPurchasePending',
  firstPurchaseSuccess = 'firstPurchaseSuccess',
  firstPurchaseSent = 'firstPurchaseSent',
}

export const emitFirstPurchaseSent = async () => {
  return request(`${process.env.OUTVIO_API_URL}/subscription/ga-event`, {
    method: 'PUT',
    data: { event: SubscriptionGAEvent.firstPurchaseSent },
    secure: true,
  });
};

export const getSubscriptionsPlans = () =>
  request(`${process.env.OUTVIO_API_URL}/subscription/plans`, {
    method: 'GET',
    secure: true,
  });

export const getDowngradePriceIncreaseEstimate = (clientCode: string) =>
  request(
    `${process.env.OUTVIO_API_URL}/subscription/downgrade-flow/approx-price-change/${clientCode}`,
    {
      method: 'GET',
      secure: true,
    },
  );

export enum DowngradeFlowEmailType {
  RequestCall = 'request-call',
  RequestDemo = 'request-demo',
  RequestDowngradeOffer = 'request-downgrade-offer',
  Feedback = 'feedback',
  UpgradeEnterprise = 'request-enterprise',
  RequestYearOffer = 'request-year-offer',
}

export type DowngradePlan = SubscriptionPlan | 'CANCEL' | 'ENTERPRISE';

interface DowngradeEmailRequestBase {
  currentPlan: SubscriptionPlan;
  downPlan: DowngradePlan;
}

interface DowngradeEmailRequestCall extends DowngradeEmailRequestBase {
  type: DowngradeFlowEmailType.RequestCall;
  phoneNumber: string;
  email: string;
  bookedTime: string;
}

interface DowngradeEmailRequestDemo extends DowngradeEmailRequestBase {
  type: DowngradeFlowEmailType.RequestDemo;
  email: string;
  bookedTime: string;
}

interface DowngradeEmailRequestOffer extends DowngradeEmailRequestBase {
  type: DowngradeFlowEmailType.RequestDowngradeOffer;
  email: string;
  bookedTime: string;
}

interface DowngradeEmailRequestFeedback extends DowngradeEmailRequestBase {
  type: DowngradeFlowEmailType.Feedback;
  feedback: string;
}

interface UpgradeRequestEnterpriseInfo extends DowngradeEmailRequestBase {
  type: DowngradeFlowEmailType.UpgradeEnterprise;
  email: string;
  phoneNumber: string;
  name: string;
}

export const triggerDowngradeSupportEmail = (
  data:
    | DowngradeEmailRequestCall
    | DowngradeEmailRequestDemo
    | DowngradeEmailRequestOffer
    | DowngradeEmailRequestFeedback
    | UpgradeRequestEnterpriseInfo,
) =>
  request(`${process.env.OUTVIO_API_URL}/subscription/downgrade-flow/triggers`, {
    method: 'POST',
    data,
    secure: true,
  });

export const triggerSupportEmail = (data: {
  email: string;
  type: string;
  currentPlan: SubscriptionPlan;
  feedback: string;
}) =>
  request(`${process.env.OUTVIO_API_URL}/subscription/downgrade-flow/triggers`, {
    method: 'POST',
    data,
    secure: true,
  });

// amount is vat+price.
export const getEstimatedSubBill = (
  targetPlan: string | undefined,
  period: string,
): Promise<{ rebillDate: string; amount: number; vat: number; price: number }> =>
  request(`${process.env.OUTVIO_API_URL}/subscription/upgrade-flow/estimateUpgrade`, {
    method: 'POST',
    data: { plan: targetPlan, period },
    secure: true,
  });

// cancelSubscription sets this field of subscription directly
// use this to abort a sub cancellation while it is in progress
export const subDeactivate = (cancelSubscription: boolean) =>
  request(`${process.env.OUTVIO_API_URL}/subscription/deactivate`, {
    method: 'POST',
    data: { cancelSubscription },
    secure: true,
  });

export const deskActivate = (active: boolean) =>
  request(`${process.env.OUTVIO_API_URL}/store-settings/outvio-desk/activate`, {
    method: 'POST',
    data: { active },
    secure: true,
  });

export const deskActivateAdmin = (fee: number, clientStoreId: string) =>
  request(`${process.env.OUTVIO_API_URL}/admin/store-settings/addon/desk-fee/${clientStoreId}`, {
    method: 'POST',
    secure: true,
    data: { fee },
  });
