import { RefObject, useEffect } from 'react';

const transitionTimePerPixel = 0.01;

export const useScrollText = (
  textBlockRef: RefObject<HTMLDivElement>,
  triggerBlockRef?: RefObject<HTMLDivElement>,
) => {
  useEffect(() => {
    const textBlock = textBlockRef.current;
    const triggerBlock = triggerBlockRef ? triggerBlockRef.current : textBlockRef.current;

    const handleMouseEnter = () => {
      if (textBlock && textBlock.lastChild instanceof HTMLElement) {
        const textWidth = textBlock.lastChild.clientWidth;
        const boxWidth = parseFloat(getComputedStyle(textBlock).width);
        const translateVal = Math.min(boxWidth - textWidth, 0);
        const translateTime = -transitionTimePerPixel * translateVal + 's';
        textBlock.lastChild.style.transitionDuration = translateTime;

        textBlock.lastChild.style.transitionProperty = `transform`;
        textBlock.lastChild.style.transform = `translateX(${translateVal}px)`;
        textBlock.lastChild.style.transitionTimingFunction = `linear`;
        textBlock.lastChild.style.whiteSpace = `nowrap`;
      }
    };

    const handleMouseLeave = () => {
      if (textBlock && textBlock.lastChild instanceof HTMLElement) {
        (textBlock.lastChild as HTMLElement).style.transform = 'translateX(0)';
        textBlock.lastChild.style.transitionDuration = '0s';
      }
    };

    if (triggerBlock) {
      triggerBlock.addEventListener('mouseenter', handleMouseEnter);
      triggerBlock.addEventListener('mouseleave', handleMouseLeave);
    }

    return () => {
      if (triggerBlock) {
        triggerBlock.removeEventListener('mouseenter', handleMouseEnter);
        triggerBlock.removeEventListener('mouseleave', handleMouseLeave);
      }
    };
  }, []);
};
