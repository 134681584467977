import React, { useEffect, useMemo, useState } from 'react';

import noop from 'lodash-es/noop';

import { makeStyles } from '@material-ui/core';
import ImageIcon from '@material-ui/icons/Image';
import cn from 'classnames';

import useIsMounted from '../hooks/useIsMounted';
import BrokenImgIcon from './BrokenImgIcon';

const useStyles = makeStyles({
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    overflow: 'hidden',

    fontSize: '70px',

    objectFit: 'contain',

    '& > img': {
      maxWidth: '100%',
      maxHeight: '100%',
      zIndex: 1,
    },
    '& > svg': {
      zIndex: 1,
    },

    backgroundPositionX: 'center',
    backgroundPositionY: 'center',
    backgroundSize: 'cover',
    backgroundRepeatX: 'no-repeat',
    backgroundRepeatY: 'no-repeat',
    backgroundAttachment: 'local',
  },
  containerBlur: {
    '&::after': {
      WebkitBackdropFilter: 'blur(500px)',
      backdropFilter: 'blur(500px)',

      content: "''",
      display: 'block',
      position: 'absolute',
      top: 0,
      bottom: 0,
      right: 0,
      left: 0,
    },
  },
});

const loadImage = (src: string) =>
  new Promise<string>((resolve, reject) => {
    const img = new Image();
    img.src = src;
    img.decoding = 'async';

    if (typeof img.decode !== 'undefined') {
      img
        .decode()
        .then(() => resolve(img.src))
        .catch(() => reject(new Error('Error decoding image.')));
    } else {
      img.onload = () => {
        resolve(img.src);
      };
    }

    img.onerror = () => {
      reject(new Error('Error downloading image.'));
    };
  });

interface IImage2 extends React.HTMLAttributes<HTMLDivElement> {
  src: unknown;
  width?: number | string;
  height?: number | string;
  style?: React.CSSProperties;
  imgStyle?: React.CSSProperties;
  useWhitePlaceholder?: boolean;
  onLoaded?(): void;
}

const OVCImage = ({
  src,
  width = '100%',
  height = '100%',
  style = {},
  imgStyle = {},
  onLoaded = noop,
  ...restProps
}: IImage2) => {
  const isMounted = useIsMounted();
  const classes = useStyles();

  const [isLoading, setLoading] = useState<boolean>(false);
  const [hadError, setError] = useState<boolean>(false);
  const [displayFallback, setDisplayFallback] = useState<boolean>(false);
  const [displaySrc, setDisplaySrc] = useState<string>('');

  useEffect(() => {
    setLoading(true);
    if (typeof src === 'string' && src !== '') {
      loadImage(src)
        .then((resolvedSrc: string) => {
          if (isMounted.current) {
            setDisplaySrc(resolvedSrc);
            setLoading(false);
            onLoaded();
          }
        })
        .catch(() => {
          if (isMounted.current) {
            setError(true);
            setLoading(false);
          }
        });
    } else if (typeof src === 'undefined' || (typeof src === 'string' && src === '')) {
      // image not provided, fallback to placeholder
      setDisplayFallback(true);
      setLoading(false);
    } else {
      // incorrect src format, default to error
      setError(true);
      setLoading(false);
    }
  }, []);

  const localStyle = useMemo(
    () => ({
      width: typeof width === 'string' ? width : `${width}px`,
      height: typeof height === 'string' ? height : `${height}px`,
      ...(displaySrc !== ''
        ? {
            backgroundImage: `url(${displaySrc})`,
          }
        : {}),
      ...style,
    }),
    [width, height, style, displaySrc],
  );

  return (
    <div
      className={cn(classes.container, {
        [classes.containerBlur]: !isLoading && !displayFallback && !hadError,
      })}
      style={localStyle}
      {...restProps}
    >
      {isLoading ||
        (!isLoading && displayFallback && <ImageIcon color="inherit" fontSize="inherit" />)}
      {!isLoading && !displayFallback && hadError && <BrokenImgIcon size={70} />}
      {!isLoading && !displayFallback && !hadError && (
        <img src={displaySrc} alt="" style={imgStyle} />
      )}
    </div>
  );
};

export default OVCImage;
