import { ReactNode, memo } from 'react';

import { CSSProperties } from '@material-ui/core/styles/withStyles';
import { twMerge } from 'tailwind-merge';

const Price = memo(
  ({ currency, value, discountPrice, classNameDiscount, styleDiscount }: PriceProps) => {
    const withDiscount =
      discountPrice !== undefined && typeof discountPrice === 'number' && discountPrice > 0;
    const isValueNumber = !Array.isArray(value) && typeof value === 'number';
    const formattedValue = isValueNumber ? value?.toFixed(2) : value;

    let symbol: ReactNode = '';
    switch (currency) {
      case 'GBP':
      case '£':
        symbol = '£';
        return (
          <span>
            {addDiscountPrice(symbol, discountPrice)}
            {addNormalPrice({
              symbol,
              price: formattedValue,
              withDiscount,
              classNameDiscount,
              styleDiscount,
            })}
          </span>
        );
      case 'USD':
      case '$':
        symbol = '$';
        return (
          <span>
            {addDiscountPrice(symbol, discountPrice)}
            {addNormalPrice({
              symbol,
              price: formattedValue,
              withDiscount,
              classNameDiscount,
              styleDiscount,
            })}
          </span>
        );
      case 'PLN':
      case 'zł':
        symbol = <span className="lowercase">zł</span>;
        return (
          <span>
            {addDiscountPrice(symbol, discountPrice)}
            {addNormalPrice({
              symbol,
              price: formattedValue,
              withDiscount,
              reverse: true,
              classNameDiscount,
              styleDiscount,
            })}
          </span>
        );
      case 'EUR':
      case '€':
      case undefined:
        symbol = `\u20ac`;
        return (
          <span>
            {addDiscountPrice(symbol, discountPrice)}
            {addNormalPrice({
              symbol,
              price: formattedValue,
              withDiscount,
              classNameDiscount,
              styleDiscount,
            })}
          </span>
        );
      default:
        symbol = `${currency} `;
        return (
          <span>
            {addDiscountPrice(symbol, discountPrice)}
            {addNormalPrice({
              symbol,
              price: formattedValue,
              withDiscount,
              classNameDiscount,
              styleDiscount,
            })}
          </span>
        );
    }
  },
);

export { Price };

interface PriceProps {
  currency?: string;
  value: ReactNode;
  discountPrice?: number | null;
  classNameDiscount?: string;
  styleDiscount?: CSSProperties | undefined;
}

const addDiscountPrice = (symbol: ReactNode, discountPrice?: number | null, reverse = false) => {
  if (discountPrice !== undefined && typeof discountPrice === 'number' && discountPrice > 0) {
    return (
      <>
        {!reverse && symbol}
        {discountPrice?.toFixed(2)}
        {reverse && symbol}
      </>
    );
  }
  return '';
};

const addNormalPrice = ({
  symbol,
  price,
  withDiscount = false,
  reverse = false,
  classNameDiscount,
  styleDiscount,
}: {
  symbol: ReactNode;
  price?: any;
  withDiscount: boolean;
  reverse?: boolean;
  classNameDiscount?: string;
  styleDiscount?: CSSProperties | undefined;
}) => {
  if (price) {
    return (
      <>
        <span
          style={styleDiscount}
          className={withDiscount ? twMerge('line-through pl-2', classNameDiscount) : ''}
        >
          {!reverse && symbol}
          {price}
          {reverse && symbol}
        </span>
        {withDiscount && <span>&nbsp;</span>}
      </>
    );
  }
  return '';
};
