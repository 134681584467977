import { gql } from '@apollo/client';

export const SUPPORT_TICKET_FRAGMENT = gql`
  fragment SupportTicketFragment on TicketObject {
    id
    ticketId
    status
    supportStatus
    subject
    supportSubject
    updatedAt
    createdAt
    updatedAt
    waitingForRecipientResponse
    review
    reviewComment
  }
`;

export const CREATE_SUPPORT_TICKET_MUTATION = gql`
  mutation Ticket_CreateSupport($input: TicketCreateBySupportInput!) {
    ticket: Ticket_CreateSupport(input: $input) {
      ...SupportTicketFragment
    }
  }
  ${SUPPORT_TICKET_FRAGMENT}
`;

export const GET_SUPPORT_TICKET_QUERY = gql`
  query Ticket_GetOneSupport($id: String) {
    ticket: Ticket_GetOneSupport(id: $id) {
      ...SupportTicketFragment
    }
  }
  ${SUPPORT_TICKET_FRAGMENT}
`;

export const GET_SUPPORT_TICKETS_LIST_QUERY = gql`
  query Ticket_ListSupport($limit: Int, $after: Int, $status: TicketSupportStatuses) {
    tickets: Ticket_ListSupport(limit: $limit, after: $after, status: $status) {
      ...SupportTicketFragment
    }
  }
  ${SUPPORT_TICKET_FRAGMENT}
`;
