export default {
  'ovc.general.powered-by': 'Powered by',
  'ovc.auth.identity.email.placeholder': 'Email',
  'ovc.auth.identity.cta': 'Comença una devolució!',
  'ovc.auth.order.orderNumber.placeholder': 'Número de comanda o seguiment del transportista',
  'ovc.auth.order.clientInfo.placeholder': 'Codi postal o 5 últims dígits del telèfon',
  'ovc.auth.switch-to-identity': 'Accedir mitjançant email',
  'ovc.auth.switch-to-order': 'Accedir amb informació de la comanda',
  'ovc.auth.tracking.cta': 'LOCALITZA LA MEVA COMANDA',
  'ovc.auth.tracking.orderIdentifier.placeholder': 'ID de comanda, OTN o número de seguiment',
  'ovc.auth.tracking.postcode.placeholder': 'Codi postal de destí',
  'ovc.listing.page.all': 'Totes les comandes',
  'ovc.listing.page.in-transit': 'Comandes en trànsit',
  'ovc.listing.page.returns': 'Devolucions',
  'ovc.listing.header.order': 'comanda',
  'ovc.listing.header.date': 'data',
  'ovc.listing.header.total': 'total',
  'ovc.listing.header.choose-product': 'Escolleix producte:',
  'ovc.listing.listing-track-cta': 'localitza',
  'ovc.listing.listing-return-cta': 'retornar',
  'ovc.listing.listing-external-action-cta': 'demana reparació en garantia',
  'ovc.listing.empty-list': 'No hi ha comandes per mostrar',
  'ovc.listing.return-status.store-non-returnable': 'No es pot retornar',
  'ovc.listing.return-status.already-returned': 'Retornat {date}',
  'ovc.listing.return-status.no-longer-returnable': 'No es pot retornar',
  'ovc.listing.return-status.returnable': 'Retorna fins el {date}',
  'ovc.return-cart.refund-method-credit': `Escull apuesta opció si vols usar el valor d'aquesta devolució per a futures compres {lineBreak}
  {lineBreak}
  T'enviarem un codi de descompte o tarjeta regal pel valor corresponent.`,
  'ovc.return-cart.inst.outvio.heading.confirm-pickup': "confirma l'adreça de recollida i retorna",
  'ovc.return-cart.inst.outvio.heading.confirm-return': 'confirma la teva devolució',
  'ovc.return-cart.address.heading': 'introdueix una nova adreça o selecciona una ja existent:',
  'ovc.return-cart.socket-error':
    'Error del sistema del transportista: {error}. Si us plau, intenta-ho de nou més tard',
};
