export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = {
  [_ in K]?: never;
};
export type Incremental<T> =
  | T
  | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
};

export interface AvailableIntegration {
  __typename?: 'AvailableIntegration';
  docs: Maybe<IntegrationDocs>;
  featured: Maybe<Scalars['Boolean']['output']>;
  integration: IntegrationType;
  isMock: Maybe<Scalars['Boolean']['output']>;
  isThirdParty: Maybe<Scalars['Boolean']['output']>;
  logo: Maybe<Scalars['String']['output']>;
  name: Maybe<Scalars['String']['output']>;
  plugin: Maybe<Scalars['String']['output']>;
  url: Maybe<Scalars['String']['output']>;
}

export interface Brand {
  __typename?: 'Brand';
  brandUrl: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  logo: Maybe<Scalars['String']['output']>;
  slug: Maybe<Scalars['String']['output']>;
  trackingSlug: Maybe<Scalars['String']['output']>;
  type: BrandType;
}

export enum BrandType {
  Custom = 'custom',
  Default = 'default',
}

export interface CourierType {
  __typename?: 'CourierType';
  alias: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  integration: Maybe<Scalars['String']['output']>;
  logo: Maybe<Scalars['String']['output']>;
  name: Maybe<Scalars['String']['output']>;
  smallLogo: Maybe<Scalars['String']['output']>;
}

export interface CreateCustomBrandInput {
  slug: InputMaybe<Scalars['String']['input']>;
}

export interface CustomerMailNotificationsKpi {
  __typename?: 'CustomerMailNotificationsKpi';
  clickRate: Maybe<Scalars['Int']['output']>;
  clicked: Maybe<Scalars['Int']['output']>;
  delivered: Maybe<Scalars['Int']['output']>;
  failed: Maybe<Scalars['Int']['output']>;
  failedRate: Maybe<Scalars['Int']['output']>;
  openRate: Maybe<Scalars['Int']['output']>;
  opened: Maybe<Scalars['Int']['output']>;
  sent: Maybe<Scalars['Int']['output']>;
  total: Maybe<Scalars['Int']['output']>;
  totalSent: Maybe<Scalars['Int']['output']>;
}

export interface CustomerMailNotificationsKpiInput {
  cache: InputMaybe<Scalars['Boolean']['input']>;
  fromDate: InputMaybe<Scalars['String']['input']>;
  toDate: InputMaybe<Scalars['String']['input']>;
}

export interface Features {
  __typename?: 'Features';
  canSeePickingList: Scalars['Boolean']['output'];
  skipAnalytics: Scalars['Boolean']['output'];
}

export interface FulfillmentStatistics {
  __typename?: 'FulfillmentStatistics';
  averageCartValue: Scalars['Float']['output'];
  exchangeOrders: Scalars['Int']['output'];
  processedOrders: Scalars['Int']['output'];
  receivedOrders: Scalars['Int']['output'];
  returnOrders: Scalars['Int']['output'];
  returnRate: Scalars['Float']['output'];
  totalSales: Scalars['Float']['output'];
}

export interface IntegrationDocs {
  __typename?: 'IntegrationDocs';
  de: Maybe<Scalars['String']['output']>;
  en: Maybe<Scalars['String']['output']>;
  es: Maybe<Scalars['String']['output']>;
  pl: Maybe<Scalars['String']['output']>;
  pt: Maybe<Scalars['String']['output']>;
}

export enum IntegrationType {
  LogiCommerce = 'LogiCommerce',
  Manomano = 'Manomano',
  Vivino = 'Vivino',
  Voog = 'Voog',
  Wix = 'Wix',
  AliExpress = 'aliExpress',
  Allegro = 'allegro',
  Amazon = 'amazon',
  AmazonApp = 'amazonApp',
  Api = 'api',
  BigCommerce = 'bigCommerce',
  Ebay = 'ebay',
  Ecwid = 'ecwid',
  Epages = 'epages',
  Etsy = 'etsy',
  Katana = 'katana',
  Magento = 'magento',
  Magento2 = 'magento2',
  Odoo = 'odoo',
  OpenCart2 = 'openCart2',
  OpenCart3 = 'openCart3',
  Prestashop = 'prestashop',
  Pulpo = 'pulpo',
  Salesforce = 'salesforce',
  Shopify = 'shopify',
  ShopifyApp = 'shopifyApp',
  Shopware5 = 'shopware5',
  Shopware6 = 'shopware6',
  Squarespace = 'squarespace',
  Vtex = 'vtex',
  Wordpress = 'wordpress',
}

export interface Mutation {
  __typename?: 'Mutation';
  Brand_createCustom: Brand;
  Brand_updateDefault: Brand;
  Brand_updateSlug: Brand;
  SetupIntegration_Allegro: Scalars['Boolean']['output'];
  SetupIntegration_Bigcommerce: Scalars['Boolean']['output'];
  SetupIntegration_Etsy: Scalars['Boolean']['output'];
  SetupIntegration_Katana: Scalars['Boolean']['output'];
  SetupIntegration_Magento_1_0: Scalars['Boolean']['output'];
  SetupIntegration_Magento_2_0: Scalars['Boolean']['output'];
  SetupIntegration_Manomano: Scalars['Boolean']['output'];
  SetupIntegration_Odoo: Scalars['Boolean']['output'];
  SetupIntegration_Opencart_3_0: Scalars['Boolean']['output'];
  SetupIntegration_Prestashop: Scalars['Boolean']['output'];
  SetupIntegration_Pulpo: Scalars['Boolean']['output'];
  SetupIntegration_Salesforce: Scalars['Boolean']['output'];
  SetupIntegration_Shopify: Scalars['Boolean']['output'];
  SetupIntegration_Shopware_5_0: Scalars['Boolean']['output'];
  SetupIntegration_Shopware_6_0: Scalars['Boolean']['output'];
  SetupIntegration_Squarespace: Scalars['Boolean']['output'];
  SetupIntegration_Vivino: Scalars['Boolean']['output'];
  SetupIntegration_Voog: Scalars['Boolean']['output'];
  SetupIntegration_Vtex: Scalars['Boolean']['output'];
  SetupIntegration_Wix: Scalars['Boolean']['output'];
  SetupIntegration_Woocommerce: Scalars['Boolean']['output'];
  Store_featureRequest: Scalars['Boolean']['output'];
  Store_onboarding: Store;
  Store_skipOnboardingStep: Store;
  Store_update: Store;
  User_updateProfile: User;
}

export type MutationBrand_CreateCustomArgs = {
  input: CreateCustomBrandInput;
};

export type MutationBrand_UpdateDefaultArgs = {
  input: UpdateBrandInput;
};

export type MutationBrand_UpdateSlugArgs = {
  input: UpdateSlugInput;
};

export type MutationSetupIntegration_AllegroArgs = {
  alias: Scalars['String']['input'];
};

export type MutationSetupIntegration_BigcommerceArgs = {
  shopEmail: Scalars['String']['input'];
};

export type MutationSetupIntegration_EtsyArgs = {
  apiKey: Scalars['String']['input'];
  apiSecret: Scalars['String']['input'];
};

export type MutationSetupIntegration_KatanaArgs = {
  alias: Scalars['String']['input'];
};

export type MutationSetupIntegration_Magento_1_0Args = {
  endpoint: Scalars['String']['input'];
};

export type MutationSetupIntegration_Magento_2_0Args = {
  endpoint: Scalars['String']['input'];
};

export type MutationSetupIntegration_ManomanoArgs = {
  login: Scalars['String']['input'];
  password: Scalars['String']['input'];
};

export type MutationSetupIntegration_OdooArgs = {
  endpoint: Scalars['String']['input'];
};

export type MutationSetupIntegration_PrestashopArgs = {
  endpoint: Scalars['String']['input'];
};

export type MutationSetupIntegration_PulpoArgs = {
  password: Scalars['String']['input'];
  storeUrl: Scalars['String']['input'];
  userName: Scalars['String']['input'];
};

export type MutationSetupIntegration_SalesforceArgs = {
  password: Scalars['String']['input'];
  secretToken: Scalars['String']['input'];
  userName: Scalars['String']['input'];
};

export type MutationSetupIntegration_ShopifyArgs = {
  endpoint: Scalars['String']['input'];
};

export type MutationSetupIntegration_Shopware_5_0Args = {
  apiToken: Scalars['String']['input'];
  endpoint: Scalars['String']['input'];
  userName: Scalars['String']['input'];
};

export type MutationSetupIntegration_Shopware_6_0Args = {
  endpoint: Scalars['String']['input'];
};

export type MutationSetupIntegration_SquarespaceArgs = {
  accessToken: Scalars['String']['input'];
};

export type MutationSetupIntegration_VivinoArgs = {
  clientId: Scalars['String']['input'];
  clientSecret: Scalars['String']['input'];
  merchantId: Scalars['String']['input'];
  password: Scalars['String']['input'];
  userName: Scalars['String']['input'];
};

export type MutationSetupIntegration_VoogArgs = {
  apiToken: Scalars['String']['input'];
  storeUrl: Scalars['String']['input'];
};

export type MutationSetupIntegration_VtexArgs = {
  accountName: Scalars['String']['input'];
  appKey: Scalars['String']['input'];
  appToken: Scalars['String']['input'];
  environment: Scalars['String']['input'];
};

export type MutationSetupIntegration_WixArgs = {
  code: Scalars['String']['input'];
  instanceId: Scalars['String']['input'];
};

export type MutationSetupIntegration_WoocommerceArgs = {
  endpoint: Scalars['String']['input'];
};

export type MutationStore_FeatureRequestArgs = {
  feature: StoreFeature;
};

export type MutationStore_OnboardingArgs = {
  input: StoreOnboardingInput;
};

export type MutationStore_SkipOnboardingStepArgs = {
  step: OnboardingSteps;
};

export type MutationStore_UpdateArgs = {
  input: StoreUpdateInput;
};

export type MutationUser_UpdateProfileArgs = {
  input: UpdateUserProfileInput;
};

export enum OnboardingSteps {
  BrandAssets = 'brandAssets',
  CmsIntegration = 'cmsIntegration',
  CourierIntegration = 'courierIntegration',
  DeskAgent = 'deskAgent',
  EmailSender = 'emailSender',
  EmailTemplate = 'emailTemplate',
  Fulfillment = 'fulfillment',
  ReturnRules = 'returnRules',
  ShippingRules = 'shippingRules',
}

export interface OrdersInTransitAndIncidents {
  __typename?: 'OrdersInTransitAndIncidents';
  incidents: Maybe<Scalars['Int']['output']>;
  ordersInTransit: Maybe<Scalars['Int']['output']>;
}

export interface Pickup {
  __typename?: 'Pickup';
  code: Maybe<Scalars['String']['output']>;
  courier: Scalars['String']['output'];
  courier_id: Scalars['String']['output'];
  createdAt: Maybe<Scalars['String']['output']>;
  failed: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['ID']['output'];
  isReturn: Scalars['Boolean']['output'];
  rejectDate: Maybe<Scalars['String']['output']>;
  requestedDate: Maybe<Scalars['String']['output']>;
  returnInfo: Maybe<ReturnInfo>;
  shipmentReference: Maybe<Scalars['String']['output']>;
  status: Scalars['String']['output'];
  updatedAt: Maybe<Scalars['String']['output']>;
  warehouse: Maybe<Scalars['String']['output']>;
}

export interface ProcessedOrdersPerCourier {
  __typename?: 'ProcessedOrdersPerCourier';
  courier: Scalars['String']['output'];
  percentage: Scalars['Int']['output'];
  processedOrders: Scalars['Int']['output'];
}

export interface Query {
  __typename?: 'Query';
  Brand_getById: Brand;
  Brand_getDefault: Brand;
  CourierType_getAll: Array<CourierType>;
  Integration_availableIntegrationsList: Maybe<Array<Maybe<AvailableIntegration>>>;
  Pickup_info: Maybe<Pickup>;
  Statistics_countCustomerMailNotifications: CustomerMailNotificationsKpi;
  Statistics_countOrdersInTransitAndIncidents: OrdersInTransitAndIncidents;
  Statistics_countProcessedOrdersPerCourier: Array<Maybe<ProcessedOrdersPerCourier>>;
  Statistics_fulfillment: FulfillmentStatistics;
  Store_current: Maybe<Store>;
  Store_findOneById: Maybe<Store>;
  Subscription_current: Maybe<Subscription>;
  User_getProfile: User;
}

export type QueryBrand_GetByIdArgs = {
  brandId: Scalars['String']['input'];
};

export type QueryPickup_InfoArgs = {
  otn: InputMaybe<Scalars['String']['input']>;
};

export type QueryStatistics_CountCustomerMailNotificationsArgs = {
  input: InputMaybe<CustomerMailNotificationsKpiInput>;
};

export type QueryStatistics_CountOrdersInTransitAndIncidentsArgs = {
  cache: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryStatistics_CountProcessedOrdersPerCourierArgs = {
  cache: InputMaybe<Scalars['Boolean']['input']>;
  limit: InputMaybe<Scalars['Int']['input']>;
};

export type QueryStatistics_FulfillmentArgs = {
  cache: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryStore_FindOneByIdArgs = {
  id: Scalars['String']['input'];
};

export interface ReturnInfo {
  __typename?: 'ReturnInfo';
  address: Maybe<Scalars['String']['output']>;
  city: Maybe<Scalars['String']['output']>;
  contactName: Maybe<Scalars['String']['output']>;
  contactPhone: Maybe<Scalars['String']['output']>;
  country: Maybe<Scalars['String']['output']>;
  countryCode: Maybe<Scalars['String']['output']>;
  name: Maybe<Scalars['String']['output']>;
  postcode: Maybe<Scalars['String']['output']>;
  state: Maybe<Scalars['String']['output']>;
}

export interface Store {
  __typename?: 'Store';
  apiKey: Scalars['String']['output'];
  approved: Scalars['Boolean']['output'];
  clientCode: Scalars['String']['output'];
  company: Maybe<StoreCompany>;
  currency: StoreCurrency;
  deskEnabled: Scalars['Boolean']['output'];
  hasPendingOnboarding: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['ID']['output'];
  invoiceCheckingEnabled: Scalars['Boolean']['output'];
  is3pl: Scalars['Boolean']['output'];
  isActive: Scalars['Boolean']['output'];
  onboardingSteps: Array<Maybe<OnboardingStep>>;
  promoCode: Maybe<Scalars['String']['output']>;
  statistics: Maybe<StoreStatistics>;
}

export interface StoreCompany {
  __typename?: 'StoreCompany';
  address: Maybe<Scalars['String']['output']>;
  city: Maybe<Scalars['String']['output']>;
  contactEmail: Maybe<Scalars['String']['output']>;
  contactPhone: Maybe<Scalars['String']['output']>;
  country: Maybe<Scalars['String']['output']>;
  countryCode: Maybe<Scalars['String']['output']>;
  name: Maybe<Scalars['String']['output']>;
  postcode: Maybe<Scalars['String']['output']>;
  registrationNumber: Maybe<Scalars['String']['output']>;
  supportEmail: Maybe<Scalars['String']['output']>;
  url: Maybe<Scalars['String']['output']>;
  vat: Maybe<Scalars['String']['output']>;
}

export interface StoreCompanyUpdateInput {
  address: InputMaybe<Scalars['String']['input']>;
  city: InputMaybe<Scalars['String']['input']>;
  contactEmail: InputMaybe<Scalars['String']['input']>;
  contactPhone: InputMaybe<Scalars['String']['input']>;
  country: InputMaybe<Scalars['String']['input']>;
  countryCode: InputMaybe<Scalars['String']['input']>;
  name: InputMaybe<Scalars['String']['input']>;
  postcode: InputMaybe<Scalars['String']['input']>;
  registrationNumber: InputMaybe<Scalars['String']['input']>;
  supportEmail: InputMaybe<Scalars['String']['input']>;
  url: InputMaybe<Scalars['String']['input']>;
  vat: InputMaybe<Scalars['String']['input']>;
}

export enum StoreCurrency {
  Eur = 'EUR',
  Gbp = 'GBP',
  Usd = 'USD',
}

export enum StoreFeature {
  OutvioDesk = 'OutvioDesk',
  VoiceCall = 'VoiceCall',
}

export interface StoreOnboardingInput {
  address: InputMaybe<Scalars['String']['input']>;
  city: InputMaybe<Scalars['String']['input']>;
  contactEmail: InputMaybe<Scalars['String']['input']>;
  contactPhone: InputMaybe<Scalars['String']['input']>;
  countryCode: InputMaybe<Scalars['String']['input']>;
  name: InputMaybe<Scalars['String']['input']>;
  postcode: InputMaybe<Scalars['String']['input']>;
  promoCode: InputMaybe<Scalars['String']['input']>;
}

export interface StoreStatistics {
  __typename?: 'StoreStatistics';
  clientSource: Maybe<Scalars['String']['output']>;
  partnerName: Maybe<Scalars['String']['output']>;
  shipmentsPerMonth: Maybe<Scalars['String']['output']>;
  whatCanWeDo: Maybe<Array<Maybe<Scalars['String']['output']>>>;
}

export interface StoreStatisticsInput {
  clientSource: InputMaybe<Scalars['String']['input']>;
  partnerName: InputMaybe<Scalars['String']['input']>;
  shipmentsPerMonth: InputMaybe<Scalars['String']['input']>;
  whatCanWeDo: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
}

export interface StoreUpdateInput {
  company: InputMaybe<StoreCompanyUpdateInput>;
  enableTrackingMode: InputMaybe<Scalars['Boolean']['input']>;
  statistics: InputMaybe<StoreStatisticsInput>;
}

export interface Subscription {
  __typename?: 'Subscription';
  features: Maybe<Features>;
  id: Scalars['ID']['output'];
  isActive: Scalars['Boolean']['output'];
  isConquer: Scalars['Boolean']['output'];
  isTrial: Scalars['Boolean']['output'];
  plan: Maybe<SubscriptionPlan>;
}

export enum SubscriptionPlan {
  Connect3pl = 'connect3pl',
  Conquer = 'conquer',
  Grow = 'grow',
  Launch = 'launch',
  Partner = 'partner',
  Starter = 'starter',
}

export interface UpdateBrandInput {
  brandUrl: InputMaybe<Scalars['String']['input']>;
}

export interface UpdateSlugInput {
  id: Scalars['ID']['input'];
  slug: InputMaybe<Scalars['String']['input']>;
}

export interface UpdateUserProfileInput {
  firstname: InputMaybe<Scalars['String']['input']>;
  lastname: InputMaybe<Scalars['String']['input']>;
}

export interface User {
  __typename?: 'User';
  email: Scalars['String']['output'];
  emailOnlySignature: Maybe<Scalars['Boolean']['output']>;
  firstname: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  language: Maybe<Scalars['String']['output']>;
  lastname: Maybe<Scalars['String']['output']>;
  position: Maybe<Scalars['String']['output']>;
  roles: Array<UserRole>;
  signature: Maybe<Scalars['String']['output']>;
  signatures: Maybe<Array<Maybe<UserSignature>>>;
  status: Maybe<UserStatus>;
  timeZone: Maybe<Scalars['String']['output']>;
  viewAllDeskFolder: Maybe<Scalars['Boolean']['output']>;
}

export enum UserRole {
  Admin = 'admin',
  Administrator = 'administrator',
  Customer = 'customer',
  CustomerSupport = 'customer_support',
  Manager = 'manager',
  Owner = 'owner',
  User = 'user',
  Warehouse = 'warehouse',
}

export interface UserSignature {
  __typename?: 'UserSignature';
  language: Maybe<Scalars['String']['output']>;
  signature: Maybe<Scalars['String']['output']>;
}

export enum UserStatus {
  Away = 'away',
  Offline = 'offline',
  Online = 'online',
  Reassign = 'reassign',
}

export enum WhatCanWeDo {
  Fulfillment = 'fulfillment',
  Notifications = 'notifications',
  OutvioDesk = 'outvioDesk',
  ReturnsAndExchanges = 'returnsAndExchanges',
  Shipping = 'shipping',
  Tracking = 'tracking',
}

export interface OnboardingStep {
  __typename?: 'onboardingStep';
  completed: Scalars['Boolean']['output'];
  step: OnboardingSteps;
}
