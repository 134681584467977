export function InstagramColored({ ...props }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 32 32" {...props}>
      <defs>
        <radialGradient
          id="radial-gradient-i"
          cx="0.065"
          cy="0.978"
          r="1.27"
          gradientTransform="translate(0)"
          gradientUnits="objectBoundingBox"
        >
          <stop offset="0.09" stopColor="#fa8f21" />
          <stop offset="0.78" stopColor="#d82d7e" />
        </radialGradient>
      </defs>
      <g transform="translate(-614 -984)">
        <rect
          width="32"
          height="32"
          rx="10"
          transform="translate(614 1016) rotate(-90)"
          fill="url(#radial-gradient-i)"
        />
        <g transform="translate(-345 869)">
          <rect width="24" height="24" transform="translate(963 119)" fill="none" />
          <path
            d="M8.5,0C6.191,0,5.9.011,5,.051a6.268,6.268,0,0,0-2.063.4,4.162,4.162,0,0,0-1.506.98,4.147,4.147,0,0,0-.98,1.506A6.249,6.249,0,0,0,.051,5C.008,5.9,0,6.191,0,8.5S.011,11.1.051,12a6.272,6.272,0,0,0,.4,2.063,4.168,4.168,0,0,0,.98,1.506,4.157,4.157,0,0,0,1.506.98,6.276,6.276,0,0,0,2.063.4C5.9,16.991,6.191,17,8.5,17s2.6-.011,3.5-.051a6.29,6.29,0,0,0,2.063-.4,4.344,4.344,0,0,0,2.486-2.486,6.272,6.272,0,0,0,.4-2.063c.043-.907.051-1.195.051-3.5s-.011-2.6-.051-3.5a6.286,6.286,0,0,0-.4-2.063,4.172,4.172,0,0,0-.98-1.506,4.141,4.141,0,0,0-1.506-.98A6.253,6.253,0,0,0,12,.051C11.1.008,10.809,0,8.5,0Zm0,1.53c2.269,0,2.539.011,3.435.05a4.683,4.683,0,0,1,1.577.294,2.8,2.8,0,0,1,1.614,1.613,4.693,4.693,0,0,1,.293,1.577c.04.9.05,1.166.05,3.435s-.011,2.539-.052,3.435a4.784,4.784,0,0,1-.3,1.577,2.7,2.7,0,0,1-.637.979,2.652,2.652,0,0,1-.977.635,4.728,4.728,0,0,1-1.583.293c-.9.04-1.168.05-3.442.05s-2.54-.011-3.442-.052a4.82,4.82,0,0,1-1.584-.3,2.632,2.632,0,0,1-.977-.637,2.581,2.581,0,0,1-.638-.977,4.824,4.824,0,0,1-.3-1.583c-.032-.893-.043-1.168-.043-3.431s.011-2.54.043-3.443a4.818,4.818,0,0,1,.3-1.582,2.52,2.52,0,0,1,.638-.978,2.514,2.514,0,0,1,.977-.636,4.7,4.7,0,0,1,1.573-.3c.9-.032,1.169-.043,3.442-.043L8.5,1.53Zm0,2.605A4.365,4.365,0,1,0,12.865,8.5,4.364,4.364,0,0,0,8.5,4.135Zm0,7.2A2.833,2.833,0,1,1,11.333,8.5,2.833,2.833,0,0,1,8.5,11.333Zm5.558-7.37a1.02,1.02,0,1,1-1.02-1.019A1.021,1.021,0,0,1,14.058,3.963Z"
            transform="translate(966.5 122.5)"
            fill="#fff"
            stroke="#fff"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="0.2"
          />
        </g>
      </g>
    </svg>
  );
}
