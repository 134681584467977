import { request } from 'ui';

export const requestCreditCard = (data?: any) =>
  request(`${process.env.OUTVIO_API_URL}/credit-card`, { method: 'GET', data, secure: true });
export const requestDirectDebit = (data?: any) =>
  request(`${process.env.OUTVIO_API_URL}/direct-debit`, { method: 'GET', data, secure: true });
export const insertCreditCard = (data: any) =>
  request(`${process.env.OUTVIO_API_URL}/credit-card`, { method: 'POST', data, secure: true });
export const insertDirectDebit = (data: any) =>
  request(`${process.env.OUTVIO_API_URL}/direct-debit`, { method: 'POST', data, secure: true });
export const requestActivatePayment = (data: any) =>
  request(`${process.env.OUTVIO_API_URL}/payment/activate`, {
    method: 'PUT',
    data,
    secure: true,
  });
export const deletePayment = (data: any) =>
  request(`${process.env.OUTVIO_API_URL}/payment`, { method: 'DELETE', data, secure: true });
export const requestInvoices = (params: any) =>
  request(`${process.env.OUTVIO_API_URL}/invoice`, { method: 'GET', params, secure: true });
export const requestInvoiceDetail = (invoiceId: any, pageParams: any, maxPerPage: any) =>
  request(
    `${process.env.OUTVIO_API_URL}/invoice/${invoiceId}/report?skip=${
      pageParams * maxPerPage
    }&limit=${maxPerPage}`,
    {
      method: 'GET',
      secure: true,
    },
  );
export const insertPayCod = (data: any) =>
  request(`${process.env.OUTVIO_API_URL}/direct-debit/cod`, {
    method: 'PUT',
    data,
    secure: true,
  });
export const requestPayCod = () =>
  request(`${process.env.OUTVIO_API_URL}/direct-debit/cod`, { method: 'GET', secure: true });
export const requestInvoiceDetailCSV = (invoiceId: any) =>
  request(
    `${process.env.OUTVIO_API_URL}/invoice/${invoiceId}/csv`,
    { method: 'GET', secure: true },
    { skipApiCheck: true, skipErrorRedirect: false, skipJsonConvert: true },
  );
export const getCredit = () =>
  request(`${process.env.OUTVIO_API_URL}/payment/credit`, { method: 'GET', secure: true });

/** Get Whatsapp / SMS Credit details
 *
 * @param date In format `2022-10` for example
 * @returns {Promise<any>}
 */
export const getWhatsappSMSCreditDetail = ({ date, channel = 'sms' }: any) =>
  request(`${process.env.OUTVIO_API_URL}/payment/messaging/credit/statistics/${channel}/${date}`, {
    method: 'GET',
    secure: true,
  });

/**
 * Get SMS Whatsapp Monthly statistics
 *
 * @param pagination
 * @param channel
 *
 * @returns {Promise<any>}
 */
export const getWhatsappSMSStatistics = (channel = 'sms', pagination: any) =>
  request(
    `${process.env.OUTVIO_API_URL}/payment/messaging/credit/statistics/${channel}?skip=${pagination.skip}&limit=${pagination.limit}`,
    {
      method: 'GET',
      secure: true,
    },
  );

/**
 * Add More Credit API SMS Whatsapp, user API
 *
 * @param channel
 * @param data
 *
 * @returns {Promise<any>}
 */
export const addMoreWhatsappSMSCredit = (channel: any, data: any) =>
  request(`${process.env.OUTVIO_API_URL}/payment/messaging/credit`, {
    method: 'POST',
    secure: true,
    data: {
      ...data,
      channel,
    },
  });

/**
 * Add More Credit API SMS, admin API
 *
 * @param storeId
 * @param data
 * @returns {Promise<any>}
 */
export const addMoreSMSCreditAsAdmin = (storeId: any, data: any) => {
  return request(`${process.env.OUTVIO_API_URL}/admin/credit/sms/${storeId}`, {
    method: 'POST',
    secure: true,
    data,
  });
};

/**
 * Add More Credit API Whatsapp, admin API
 *
 * @param storeId
 * @param data
 * @returns {Promise<any>}
 */
export const addMoreWhatsappCreditAsAdmin = (storeId: any, data: any) => {
  return request(`${process.env.OUTVIO_API_URL}/admin/credit/whatsapp/${storeId}`, {
    method: 'POST',
    secure: true,
    data,
  });
};

/**
 * Add More Credit API Whatsapp, admin API
 *
 * @param storeId
 * @param data
 * @returns {Promise<any>}
 */
export const addMoreWhatsappWhatsappCreditAsAdmin = (storeId: any, data: any) => {
  return request(`${process.env.OUTVIO_API_URL}/admin/credit/whatsapp/${storeId}`, {
    method: 'POST',
    secure: true,
    data,
  });
};

/**
 * Ask for the Whatsapp Credit Rates
 *
 * @returns {Promise<any>}
 */
export const askForWhatsappCreditRates = ({ channel = 'sms' }) => {
  return request(`${process.env.OUTVIO_API_URL}/payment/messaging/rates?channel=${channel}`, {
    method: 'GET',
    secure: true,
  });
};

export const validateVat = (data: {
  vat: string;
}): Promise<{ success: boolean; result: boolean }> =>
  request(`${process.env.OUTVIO_API_URL}/store-settings/validate-vat`, {
    method: 'POST',
    data,
    secure: true,
  });
