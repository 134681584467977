export default {
  'custLanding.password.title': 'Forgot your password?',
  'custLanding.signup.btn': 'Sign up',
  'custLanding.login.btn': 'Log in',
  'custLanding.password.btn': 'Reset password',
  'custLanding.switch-to-login': 'Log in with existing account',
  'custLanding.switch-to-signup': ' Sign up with new account',
  'custLanding.auth.text.password':
    'Enter the email address associated with your account and we will send you instructions to get a new password.',
  'custLanding.form.password': 'Password',
  'custLanding.forgot-password': 'Forgot your password?',
  'custLanding.forgot-password.success': 'Your instructions will arrive shortly!',
  'custLanding.let-get-started': 'Let’s get started!',
  'custLanding.already-have-an-account': 'Already have an account?',
  'custLanding.just-remembered': 'Just remembered your password?',
  'custlanding.login.error.no-order': 'No orders found for this email address',
};
