import { type CSSProperties, memo } from 'react';

import { AgentStatuses } from 'data-layer';
import { twMerge } from 'tailwind-merge';

import './AgentStatus.css';

interface IAgentPanelLogo {
  status?: AgentStatuses;
  className?: string;
  size?: 'small' | 'medium' | 'large' | 'medium-sm';
  withShadow?: boolean;
  withBorder?: boolean;
  style?: CSSProperties;
}

const AgentStatus = memo(
  ({ status, className, size = 'medium', withShadow, withBorder, style }: IAgentPanelLogo) => {
    const isOnline = status === AgentStatuses.Online;
    const isOffline = status === AgentStatuses.Offline;
    const isAway = status === AgentStatuses.Away;
    const isReassign = status === AgentStatuses.Reassign;

    const isSmall = size === 'small';
    const isMedium = size === 'medium' || size === 'medium-sm';

    return (
      <div
        className={twMerge(
          'rounded-full border-white agent-status',
          isMedium && 'w-3 h-3 -top-0.5 -right-0.5',
          isSmall && 'w-2 h-2 -top-px -right-px',
          withBorder && isMedium && 'border-2',
          withBorder && isSmall && 'border',
          isOnline && 'bg-v2cyan-500',
          isOffline && 'bg-v2red',
          isAway && 'bg-v2orange',
          isReassign && 'bg-v2blueGray-550',
          withShadow && isOnline && 'shadow-[0_2px_4px_#4BB4B44D]',
          withShadow && isOffline && 'shadow-[0_2px_4px_#EC66664D]',
          withShadow && isAway && 'shadow-[0_2px_4px_#FF9F314D]',
          withShadow && isReassign && 'shadow-[0_2px_4px_#849FAD4D]',
          className,
        )}
        style={style}
      />
    );
  },
);

export { AgentStatus };
