import moment from 'moment/moment';

import { CURRENCY_SYMBOLS, Currency } from '../Interfaces';

export const numberWithCurrency = ({ n, currency }: { n: number; currency: Currency }): string =>
  `${CURRENCY_SYMBOLS[currency] ? CURRENCY_SYMBOLS[currency] : `${currency || ''} `}${n.toFixed(
    2,
  )}`;

const getUtcOffset = (timeZone: string) => {
  if (!Intl || !timeZone) return 0;
  const timeZoneName = Intl?.DateTimeFormat?.('ia', {
    timeZoneName: 'short',
    timeZone,
  })
    ?.formatToParts()
    ?.find((i) => i.type === 'timeZoneName')?.value;
  if (!timeZoneName) return 0;
  const offset = timeZoneName?.slice(3);
  if (!offset) return 0;

  const matchData = offset.match(/([+-])(\d+)(?::(\d+))?/);
  if (!matchData) return 0;

  const [, sign, hour, minute] = matchData;
  let result = parseInt(hour, 10) * 60;
  if (sign === '-') result *= -1;
  if (minute) {
    result += parseInt(minute, 10);
  }

  return result;
};

export const toDisplayDate = (src: string, isIso = false, timeOffset = ''): string => {
  const date = moment(src, isIso ? undefined : 'DD/MM/YYYY HH:mm');
  if (!date.isValid()) return '';
  if (timeOffset && timeOffset !== '' && isIso) {
    const utcOffset = timeOffset.includes('/') ? getUtcOffset(timeOffset) : timeOffset;
    return date.utcOffset(utcOffset === '00:00' ? '+00:00' : utcOffset).format('DD/MM HH:mm');
  }
  return date.format('DD/MM HH:mm');
};
