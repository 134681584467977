export function AnalyticsIcon({ ...props }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" width="1em" height="1em" {...props}>
      <path id="Path_16320" data-name="Path 16320" d="M0,0H20V20H0Z" fill="none" />
      <path
        id="Path_40420"
        data-name="Path 40420"
        d="M2887,254h7a6.2,6.2,0,0,0,2.633-.272,2.447,2.447,0,0,0,1.092-1.092A6.156,6.156,0,0,0,2898,250v-7a6.156,6.156,0,0,0-.275-2.635,2.447,2.447,0,0,0-1.092-1.092A6.2,6.2,0,0,0,2894,239h-7a6.2,6.2,0,0,0-2.634.273,2.447,2.447,0,0,0-1.092,1.092A6.161,6.161,0,0,0,2883,243v7a6.161,6.161,0,0,0,.275,2.635,2.447,2.447,0,0,0,1.092,1.092A6.2,6.2,0,0,0,2887,254Z"
        transform="translate(-2880.5 -236.5)"
        fill="currentColor"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.64"
      />
      <path
        id="Path_40421"
        data-name="Path 40421"
        d="M2888,248v3.333m6.667-5v5M2891.333,243v8.333"
        transform="translate(-2881.333 -237.167)"
        fill="none"
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.64"
      />
    </svg>
  );
}
