export function MetaMessenger({ ...props }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 32 32" {...props}>
      <defs>
        <linearGradient
          id="linear-gradient-mm"
          x1="0.954"
          y1="0.075"
          x2="0.162"
          y2="1.039"
          gradientUnits="objectBoundingBox"
        >
          <stop offset="0" stopColor="#fd5977" />
          <stop offset="0.49" stopColor="#9738fe" />
          <stop offset="1" stopColor="#0d90fe" />
        </linearGradient>
      </defs>
      <g transform="translate(-614 -984)">
        <rect
          width="32"
          height="32"
          rx="10"
          transform="translate(614 1016) rotate(-90)"
          fill="url(#linear-gradient-mm)"
        />
        <g transform="translate(-342 832)">
          <rect width="24" height="24" transform="translate(960 156)" fill="none" />
          <path
            d="M0,10.176A10.162,10.162,0,0,1,10.069,0h.42A10.168,10.168,0,0,1,20.977,9.756v.42A10.162,10.162,0,0,1,10.908,20.351h-.42a10.138,10.138,0,0,1-3.042-.42H6.922l-2.1.944a.882.882,0,0,1-1.154-.42c0-.1-.1-.21-.1-.315l-.1-1.888a.907.907,0,0,0-.315-.629A10.3,10.3,0,0,1,0,10.176Z"
            transform="translate(961.698 157.699)"
            fill="none"
          />
          <path
            d="M3.42,17.933a.763.763,0,0,1-.361-.632L2.97,15.679a.782.782,0,0,0-.27-.541A8.854,8.854,0,0,1,0,8.74,8.693,8.693,0,0,1,9,0a8.693,8.693,0,0,1,9,8.74,8.7,8.7,0,0,1-9,8.742,8.31,8.31,0,0,1-2.61-.359H5.941l-1.8.811A1.13,1.13,0,0,1,3.78,18,1.128,1.128,0,0,1,3.42,17.933ZM3.51,3.424A7.491,7.491,0,0,0,1.35,8.74,7.164,7.164,0,0,0,3.69,14.148a2.218,2.218,0,0,1,.72,1.441v.722l.9-.452a2.662,2.662,0,0,1,.81-.18,1.133,1.133,0,0,1,.54.091,9.076,9.076,0,0,0,2.249.27A8,8,0,0,0,14.4,13.968a7.136,7.136,0,0,0,2.16-5.317A7.5,7.5,0,0,0,14.4,3.334,7.958,7.958,0,0,0,9,1.35,7.994,7.994,0,0,0,3.51,3.424ZM3.7,11.858c-.181-.091-.181-.361-.09-.541l2.61-4.236.09-.089a1.312,1.312,0,0,1,1.89-.27l2.07,1.621a.431.431,0,0,0,.628,0l2.882-2.161a.4.4,0,0,1,.449,0c.18.089.18.359.089.541l-2.61,4.234a.088.088,0,0,0-.088.091,1.315,1.315,0,0,1-1.891.27L7.657,9.694a.436.436,0,0,0-.63,0L4.147,11.858a.266.266,0,0,1-.192.068A.582.582,0,0,1,3.7,11.858Z"
            transform="translate(963 159)"
            fill="#fff"
            stroke="#fff"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="0.2"
          />
        </g>
      </g>
    </svg>
  );
}
