export default {
  FALLBACK_ERROR_MESSAGE: 'Ocorreu um erro. Por favor, tente novamente mais tarde.',
  TOKEN_NOT_FOUND: 'Token não encontrado.',
  IDENTITY_VERIFICATION_FAILED: 'Não foi possível concluir a verificação da sua identidade.',
  ACCOUNT_BLOCKED: 'Sua conta está bloqueada. Por favor verifique o seu email.',
  WRONG_EMAIL_OR_PASS: 'E-mail e/ou senha incorretos.',
  INVALID_CREDENTIALS: 'Credenciais inválidas.',
  MALFORMED_TOKEN: 'Token malformado.',
  CUSTOMER_ALREADY_CREATED: 'Conta de cliente já criada. Em vez disso, faça login.',
  SHIPPING_METHOD_NOT_SUPPORTED: 'Método de envio não suportado.',
  SHIPMENT_ALREADY_PICKED_UP: 'Remessa já retirada.',
  NO_RATE_FOR_DESTINATION: 'Nenhuma taxa disponível para o destino.',
  BUILDING_LABEL_FAILED: 'Falha ao construir etiqueta.',
  COURIER_NOT_SUPPORTED: 'Courier não suportado.',
  PICKUP_REQUEST_FAILED: 'Solicitação de retirada falhou.',
  ERROR_TRY_AGAIN_LATER: 'Ocorreu um erro. Por favor, tente novamente mais tarde.',
  EMAIL_ALREADY_REGISTERED: 'Email já registrado.',
  INVALID_PASSWORD: 'Senha inválida.',
  LABEL_ERROR: 'Erro de etiqueta.',
  UNDER_MAINTENANCE: 'Estamos em manutenção. Outvio estará online em breve.',
  MERGING_PDFS_FAILED: 'Falha ao unir PDFs',
  EXPIRE_TOKEN: 'Token expirado',
  GENERIC_MISSING_PARAM: 'Parâmetros obrigatórios ausentes.',
  ORDER_NOT_FOUND: 'Pedido não encontrado.',
};
