import { type HTMLProps, type ReactNode } from 'react';

import { Tooltip } from '@material-ui/core';
import HelpOutlineRoundedIcon from '@mui/icons-material/HelpOutlineRounded';
import { twMerge } from 'tailwind-merge';
import { ErrorMessage } from 'ui/atoms';

import { InputTextVariant } from './RegularInputFragment';

export type InputBackgroundFragmentProps = HTMLProps<HTMLDivElement> & {
  isLocked?: boolean;
  isComplete?: boolean;
  className?: string;
  backgroundClassName?: string;
  icon?: ReactNode;
  IconTag?: 'button' | 'div';
  iconPosition?: 'left' | 'right';
  onIconCtaClick?: (event: React.MouseEvent<HTMLElement>) => void;
  isFocused: boolean;
  isError: boolean;
  errorMessage?: ReactNode;
  hasIconDivider?: boolean;
  variant?: InputTextVariant;
  helpTooltip?: string;
  backgroundStyle?: React.CSSProperties;
};

export function InputBackgroundFragment({
  isLocked,
  isComplete,
  className,
  backgroundClassName,
  backgroundStyle,
  icon,
  IconTag = 'div',
  iconPosition = 'left',
  onIconCtaClick,
  isFocused,
  isError,
  errorMessage,
  disabled,
  children,
  hasIconDivider = false,
  variant,
  helpTooltip,
  ...otherProps
}: InputBackgroundFragmentProps) {
  return (
    <div className={twMerge('flex flex-col', className)} {...otherProps}>
      <div
        style={backgroundStyle}
        data-testid="outvioui--input"
        className={twMerge(
          `relative flex rounded-lg text-sm text-v2blueGray-800 h-9 bg-v2blueGray-0 hover:bg-v2blueGray-100 border-px border-solid transition-all duration-100 ease-in-out border border-v2blueGray-0 items-center`,
          isFocused && `!border-v2blueGray-200 outline-v2blueGray-200`,
          isComplete && 'border-primary outline-primary text-primary',
          isLocked && 'hover:!bg-v2blueGray-0',
          isError && 'border-v2red outline-v2red text-v2red',
          variant === InputTextVariant.Info && 'border-v2blue text-v2blue',
          backgroundClassName,
          disabled &&
            'text-v2blueGray-400 bg-[transparent] hover:!bg-[transparent] border-v2blueGray-200',
        )}
      >
        {icon && (
          <>
            <IconTag
              data-testid="outvioui--input__icon"
              className={twMerge(
                'absolute z-1 top-[6px] left-[10px] flex items-center justify-center h-6 w-6 text-[16px] text-v2blueGray-500',
                iconPosition === 'right' && 'right-[10px] left-auto',
                onIconCtaClick ? 'hover:cursor-pointer' : 'pointer-events-none',
                variant === InputTextVariant.Info && 'text-v2blue',
              )}
              onClick={onIconCtaClick}
            >
              {icon}
            </IconTag>
            {(onIconCtaClick || hasIconDivider) && (
              <div
                className={twMerge(
                  'w-px bg-v2blueGray-200 h-5 absolute top-1/2',
                  'left-[42px] transform -translate-y-1/2',
                  iconPosition === 'right' && 'right-[42px] left-auto',
                )}
              />
            )}
          </>
        )}

        {helpTooltip && (
          <Tooltip
            title={helpTooltip}
            placement="bottom-end"
            className="absolute right-[10px] left-auto z-1 top-[6px] flex items-center justify-center h-6 w-6 text-[16px] text-v2blueGray-500"
          >
            <HelpOutlineRoundedIcon color="inherit" className="text-xl" />
          </Tooltip>
        )}
        <label className="grow flex items-center">{children}</label>
      </div>
      {isError && !disabled && errorMessage && (
        <ErrorMessage>
          <div className="first-letter:capitalize">{errorMessage}</div>
        </ErrorMessage>
      )}
    </div>
  );
}

export default InputBackgroundFragment;
