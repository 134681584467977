export default {
  'custLanding.password.title': 'Unohtuiko salasana?',
  'custLanding.signup.btn': 'Rekisteröidy',
  'custLanding.login.btn': 'Kirjaudu sisään',
  'custLanding.password.btn': 'Vaihda salasana',
  'custLanding.switch-to-login': 'Kirjaudu sisään tilillesi',
  'custLanding.switch-to-signup': ' Rekisteröi uusi tili',
  'custLanding.auth.text.password':
    'Syötä tiliisi liitetty sähköpostiosoite, niin lähetämme sinulle ohjeet uuden salasanan luomiseen.',
  'custLanding.form.password': 'Salasana',
  'custLanding.forgot-password': 'Unohtuiko salasana?',
  'custLanding.forgot-password.success': 'Ohjeesi saapuvat pian!',
  'custLanding.let-get-started': 'Aloitetaan!',
  'custLanding.already-have-an-account': 'Onko sinulla jo tili?',
  'custLanding.just-remembered': 'Muistitko juuri salasanasi?',
  'custlanding.login.error.no-order': 'Tilauksia ei löytynyt tälle sähköpostiosoitteelle',
};
