export function Bell({ ...props }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="currentColor"
      {...props}
    >
      <g transform="translate(-152 -691)">
        <g transform="translate(7994.74 19876)">
          <path
            d="M1624.751,2259.4a5.327,5.327,0,0,0-1.611-3.816,5.546,5.546,0,0,0-7.762,0,5.327,5.327,0,0,0-1.611,3.816,10.917,10.917,0,0,1-1.51,5.949,8.466,8.466,0,0,0-1,1.737.368.368,0,0,0,.183.324,6.224,6.224,0,0,0,1.767.09h12.1a6.224,6.224,0,0,0,1.767-.09.369.369,0,0,0,.183-.324,8.454,8.454,0,0,0-1-1.737A10.915,10.915,0,0,1,1624.751,2259.4Z"
            transform="translate(-9450 -21436)"
            fill="currentColor"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.6"
          />
          <path
            d="M1617.35,2273a3.719,3.719,0,0,0,4.851,0"
            transform="translate(-9450.516 -21437.9)"
            fill="none"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.6"
          />
        </g>
      </g>
    </svg>
  );
}
