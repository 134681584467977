import { ReactNode } from 'react';

export interface IAgentTitleLogo {
  logo: ReactNode;
  title: ReactNode;
  subtitle: ReactNode;
  reversed?: boolean;
}

const titleClasses = 'text-v2blueGray-800 text-sm font-semibold truncate';
const subTitleClasses = 'text-v2blueGray-500 text-xs truncate';
export const AgentTitleLogo = ({ logo, title, subtitle, reversed }: IAgentTitleLogo) => {
  return (
    <div className="flex gap-3 items-center font-inter truncate">
      {logo}
      <div className="min-w-[0px] text-left tracking-[-.324px]">
        <div className={reversed ? titleClasses : subTitleClasses}>
          {reversed ? title : subtitle}
        </div>
        <div className={reversed ? subTitleClasses : titleClasses}>
          {reversed ? subtitle : title}
        </div>
      </div>
    </div>
  );
};
