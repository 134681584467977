import { twMerge } from 'tailwind-merge';

const Star = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 16 16">
    <g transform="translate(2350 -10745)">
      <path
        d="M1887.655,2963.108c.135-.269.2-.409.292-.45a.284.284,0,0,1,.257,0c.087.041.157.181.292.45l1.272,2.587a.569.569,0,0,0,.093.152.255.255,0,0,0,.087.07.626.626,0,0,0,.175.035l2.854.421c.3.041.449.064.519.134a.3.3,0,0,1,.076.24c-.012.1-.123.21-.339.42l-2.066,2.015a.663.663,0,0,0-.117.128.376.376,0,0,0-.035.105.76.76,0,0,0,.017.175l.49,2.844c.047.3.076.449.029.537a.3.3,0,0,1-.2.152c-.1.017-.233-.053-.5-.193l-2.556-1.343a.592.592,0,0,0-.157-.076.407.407,0,0,0-.117,0,.591.591,0,0,0-.157.076l-2.556,1.343c-.269.14-.4.21-.5.193a.3.3,0,0,1-.2-.152c-.047-.088-.018-.234.029-.537l.49-2.844a.76.76,0,0,0,.017-.175.376.376,0,0,0-.035-.105.67.67,0,0,0-.117-.128l-2.066-2.015c-.216-.21-.327-.315-.339-.42a.3.3,0,0,1,.076-.24c.07-.07.222-.093.519-.134l2.854-.421a.626.626,0,0,0,.175-.035.256.256,0,0,0,.087-.07.579.579,0,0,0,.094-.152Z"
        transform="translate(-4230.076 7784.623)"
        fill="currentColor"
      />
    </g>
  </svg>
);

export function Rating({ value }: { value?: number }) {
  if (!value) return null;

  return (
    <div
      className={twMerge(
        'rounded-xl bg-opacity-20 py-0.5 pl-1 pr-2 text-xs font-semibold flex items-center',
        value < 3 && 'bg-v2red text-v2red',
        value >= 3 && value < 5 && 'bg-v2orange text-v2orange',
        value === 5 && 'bg-v2green text-v2green',
      )}
    >
      <div className="text-base mr-px">
        <Star />
      </div>
      {value}
    </div>
  );
}
