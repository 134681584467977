export function CalendarIcon({ ...props }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24" {...props}>
      <g id="Group_16447" data-name="Group 16447" transform="translate(-481 -281)">
        <rect
          id="Rectangle_11590"
          data-name="Rectangle 11590"
          width="24"
          height="24"
          transform="translate(481 281)"
          fill="none"
        />
        <g id="Group_17910" data-name="Group 17910" transform="translate(481.5 281.115)">
          <path
            id="Path_41659"
            data-name="Path 41659"
            d="M8.676,15.26a2.178,2.178,0,0,1-1.539-3.713,2.188,2.188,0,0,1,3.079,0A2.173,2.173,0,0,1,8.676,15.26ZM5.2,20.385a1.688,1.688,0,0,1-1.7-1.7V6.079a1.688,1.688,0,0,1,1.7-1.7H6.5V3.109a.724.724,0,1,1,1.448,0V4.377h7.131V3.091a.706.706,0,1,1,1.412,0V4.377h1.3a1.688,1.688,0,0,1,1.7,1.7v12.6a1.688,1.688,0,0,1-1.7,1.7Zm0-1.412H17.8a.312.312,0,0,0,.29-.29V9.846H4.912v8.837a.312.312,0,0,0,.29.29Z"
            transform="translate(0 0)"
            fill="currentColor"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="0.4"
          />
        </g>
      </g>
    </svg>
  );
}
