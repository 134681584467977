import { memo, useMemo } from 'react';

import { Star } from 'icons';
import { twMerge } from 'tailwind-merge';

export enum RatingValue {
  one = 1,
  two = 2,
  three = 3,
  four = 4,
  five = 5,
}

function RatingBase({ value }: { value?: RatingValue }) {
  const color = useMemo(() => {
    switch (value) {
      case RatingValue.one:
      case RatingValue.two:
        return 'text-v2red bg-v2red';
      case RatingValue.three:
        return 'text-v2orange bg-v2orange';
      case RatingValue.four:
      case RatingValue.five:
        return 'text-v2green bg-v2green';
      default:
        return 'text-v2blueGray-400 bg-v2blueGray-400';
    }
  }, [value]);

  return (
    <div
      className={twMerge(`flex self-center py-[5px] pl-2 pr-3 rounded-full bg-opacity-15`, color)}
    >
      <div className="text-lg mr-1">
        <Star />
      </div>
      <div className="font-semibold leading-[17px] text-sm self-end">{value || `N/A`}</div>
    </div>
  );
}

export const Rating = memo(RatingBase);
