export default {
  'ovc.general.powered-by': 'Généré par',
  'ovc.auth.identity.email.placeholder': 'E-Mail',
  'ovc.auth.identity.cta': 'Effectuer un retour',
  'ovc.auth.order.orderNumber.placeholder': 'Numéro de commande ou de suivi du courrier',
  'ovc.auth.order.clientInfo.placeholder': 'Code postal ou 5 derniers chiffres du téléphone',
  'ovc.auth.switch-to-identity': 'Connectez-vous par e-mail à la place',
  'ovc.auth.switch-to-order': 'Connectez-vous avec les informations de commande à la place',
  'ovc.auth.tracking.cta': 'Suivre ma commande',
  'ovc.auth.tracking.orderIdentifier.placeholder': 'Insérer le numéro de commande ou de suivi',
  'ovc.auth.tracking.postcode.placeholder': 'Insérer le code postal de destination',
  'ovc.listing.page.all': 'Toutes les commandes',
  'ovc.listing.page.in-transit': 'Commandes en transit',
  'ovc.listing.page.returns': 'Retours',
  'ovc.listing.header.order': 'commande',
  'ovc.listing.header.date': 'date',
  'ovc.listing.header.total': 'total',
  'ovc.listing.header.choose-product': 'Choisissez un produit :',
  'ovc.listing.listing-track-cta': 'suivi',
  'ovc.listing.listing-return-cta': 'retour',
  'ovc.listing.listing-external-action-cta': 'demande de réparation sous garantie',
  'ovc.listing.empty-list': 'Aucune commande disponible à afficher',
  'ovc.listing.return-status.store-non-returnable': 'Ne peut pas être retourné',
  'ovc.listing.return-status.already-returned': 'Retourné le {date}',
  'ovc.listing.return-status.no-longer-returnable': `N'est plus retournable`,
  'ovc.listing.return-status.returnable': `Retourner jusqu'au {date}`,
  'ovc.return-cart.refund-method-credit': `Choisissez cette option si vous souhaitez utiliser votre remboursement pour votre prochain achat chez nous ! {lineBreak}
  {lineBreak}
Nous vous enverrons une carte-cadeau avec le montant du remboursement.`,
  'ovc.return-cart.inst.outvio.heading.confirm-pickup': `confirmez votre adresse d'enlèvement et de retour`,
  'ovc.return-cart.inst.outvio.heading.confirm-return': 'confirmez votre retour',
  'ovc.return-cart.address.heading':
    'saisissez une nouvelle adresse ou sélectionnez-en une existante :',
};
