import { gql } from '@apollo/client';

export const RETURNS_APPROVE_PRODUCT = gql`
  mutation Mutation($productId: String!, $returnId: ID!) {
    Returns_ApproveProduct(productId: $productId, returnId: $returnId)
  }
`;

export const RETURNS_REJECT_PRODUCT = gql`
  mutation Returns_RejectProduct($productId: String!, $returnId: ID!) {
    Returns_RejectProduct(productId: $productId, returnId: $returnId)
  }
`;

export const RETURNS_RESET_PRODUCT = gql`
  mutation Returns_ResetProduct($productId: String!, $returnId: ID!) {
    Returns_ResetProduct(productId: $productId, returnId: $returnId)
  }
`;

export const RETURNS_PROCESSING = gql`
  mutation Returns_ProcessManualApprove($returnId: ID!) {
    Returns_ProcessManualApprove(returnId: $returnId)
  }
`;
