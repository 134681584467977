import { useEffect } from 'react';

import { useController, useFormContext } from 'react-hook-form';

import { useGetPaymentMethods } from 'data-layer';
import { Add, CvcBackCardIcon, MastercardIcon } from 'icons';
import { FormattedMessage } from 'localization';
import { twMerge } from 'tailwind-merge';
import { match } from 'ts-pattern';

import VisaIcon from '../../../../../../apps/frontend/src/assets/images/visa.svg';
import { Loader } from '../../../../components';
import { Copy, SelectRhf, TextInputRhf } from '../../../atoms';
import { TOption } from '../../../molecules/Dropdown/Dropdown';
import { PaymentMethodProps } from '../types/PaymentTypes';

type PaymentMethodFormProps = {
  isSubmitting: boolean;
  disableAddNewPaymentMethod?: boolean;
  onlyDefaultPaymentMethod?: boolean;
};

const PaymentMethodForm = ({
  isSubmitting,
  disableAddNewPaymentMethod,
  onlyDefaultPaymentMethod,
}: PaymentMethodFormProps) => {
  const { field } = useController({ name: 'paymentMethod' });

  const { setValue, resetField } = useFormContext<PaymentMethodProps>();

  const { data, isLoading, isRefetching } = useGetPaymentMethods();

  const paymentOptions: TOption[] =
    data?.list
      .filter((card) => !card.expired)
      .map((card) => {
        const BankCardIcon = match(card.network)
          .with('visa', () => <img className="h-3" src={VisaIcon} alt="Visa" />)
          .with('mastercard', () => <MastercardIcon />)
          .otherwise(() => <></>);

        return {
          label: (
            <span className="text-[14px]">
              <span className="capitalize">{card.network}</span>
              <span className="leading-[22px] tracking-[0.1em]"> •••• •••• •••• </span>
              <span>{card.data.number}</span>
            </span>
          ),
          value: card._id,
          icon: BankCardIcon,
          default: card.isActive,
        };
      }) || [];

  if (!disableAddNewPaymentMethod) {
    paymentOptions.unshift(addNewPaymentOption);
  }

  const isSelectedFromOptions = !!field.value && field.value !== 'new';
  const isNew = !!field.value && field.value === 'new';
  const selectedCard = data?.list.find((card) => card._id === field.value)?.data;

  useEffect(() => {
    if (!isLoading && !isRefetching) {
      match<boolean>(true)
        .with(isSelectedFromOptions, () => {
          setValue('holderName', selectedCard?.holderName || '');
          setValue('number', selectedCard?.number || '');
          setValue(
            'expiry',
            `${selectedCard?.expiryMonth.padStart(2, '0')}/${selectedCard?.expiryYear.slice(-2)}`,
          );
          setValue('cvc', '000');
          resetField('acceptedTerms');
        })
        .with(isNew, () => {
          resetField('holderName');
          resetField('number');
          resetField('expiry');
          resetField('cvc');
          resetField('acceptedTerms');
        })
        .otherwise(() => {
          const defaultPaymentOption =
            paymentOptions.find((x) => x.default)?.value || paymentOptions[0].value;
          setValue('paymentMethod', defaultPaymentOption);
        });
    }
  }, [field.value, data, isLoading, isRefetching]);

  if (isLoading || isRefetching) {
    return (
      <div
        className={twMerge(
          !onlyDefaultPaymentMethod && 'min-h-[364px]',
          onlyDefaultPaymentMethod && 'min-h-[176px]',
        )}
      >
        <Loader />
      </div>
    );
  }

  return (
    <div className="mt-3">
      <div className="flex flex-col gap-6">
        {!onlyDefaultPaymentMethod && (
          <div>
            <Copy type="copy2" noColor className="ml-2 mb-4 text-v2blueGray-600">
              <FormattedMessage id="settings.payment.method" />
            </Copy>

            <SelectRhf<PaymentMethodProps>
              wrapperClassName="w-full align-text-bottom"
              className="w-full align-text-bottom"
              name="paymentMethod"
              placeholder={<FormattedMessage id="settings.payment.select-payment-method" />}
              options={paymentOptions}
              disabled={isSubmitting}
            />
          </div>
        )}
        <div>
          <Copy type="copy2" noColor className="ml-2 mb-4 text-v2blueGray-600">
            <FormattedMessage id="settings.payment.name-on-card" />
          </Copy>
          <TextInputRhf<PaymentMethodProps>
            disabled={isSelectedFromOptions || isSubmitting}
            name="holderName"
            placeholder="John Doe"
            type="text"
          />
        </div>
        <div>
          <Copy type="copy2" noColor className="ml-2 mb-4 text-v2blueGray-600">
            <FormattedMessage id="settings.payment.card-number" />
          </Copy>

          {(isNew || !field.value) && (
            <TextInputRhf<PaymentMethodProps>
              disabled={isSelectedFromOptions || isSubmitting}
              name="number"
              placeholder="1234 1234 1234 1234"
              mask="9999 9999 9999 9999"
              maskPlaceholder=" "
              type="text"
            />
          )}

          {isSelectedFromOptions && (
            <div className="border border-v2blueGray-200 h-9 w-full flex items-center rounded-lg px-4 cursor-not-allowed text-v2blueGray-400 gap-1">
              <span className="text-[14px] leading-[22px] tracking-[0.2em]"> •••• •••• •••• </span>
              <span className="truncate font-inter text-[14px] leading-[22px]">
                {selectedCard?.number}
              </span>
            </div>
          )}
        </div>

        {!onlyDefaultPaymentMethod && (
          <div className="grid grid-cols-2 gap-4">
            <div>
              <Copy type="copy2" noColor className="ml-2 mb-4 text-v2blueGray-600">
                <FormattedMessage id="settings.payment.card-expiry-date" />
              </Copy>
              <TextInputRhf<PaymentMethodProps>
                disabled={isSelectedFromOptions || isSubmitting}
                name="expiry"
                mask="99/99"
                maskPlaceholder="MM/YY"
                placeholder="MM/YY"
                type="text"
              />
            </div>
            <div>
              <Copy type="copy2" noColor className="ml-2 mb-4 text-v2blueGray-600">
                CVC
              </Copy>
              <TextInputRhf<PaymentMethodProps>
                disabled={isSelectedFromOptions || isSubmitting}
                iconPosition="right"
                icon={<CvcBackCardIcon className="text-[24px]" />}
                name="cvc"
                title="CVC"
                mask="999"
                maskPlaceholder=""
                placeholder="123"
                type="password"
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

const addNewPaymentOption: TOption = {
  label: <FormattedMessage id="settings.payment.add-payment-method" />,
  value: 'new',
  icon: <Add />,
};

export default PaymentMethodForm;
