import { useQuery } from '@apollo/client';
import { QueryHookOptions } from '@apollo/client/react/types/types';
import { User } from 'app-types/core';

import { CurrentUserProfileQuery } from '../../../gql/core/queries';

export function useUserProfile(options?: QueryHookOptions) {
  const data = useQuery<{ user: User }>(CurrentUserProfileQuery, {
    ...options,
    context: { ...options?.context, clientName: 'core' },
  });

  return { ...data, data: data.data?.user };
}
