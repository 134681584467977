import { ReactNode, memo } from 'react';

import { OpenInNew } from 'icons';
import { twMerge } from 'tailwind-merge';

export interface IOrderInteractionItem {
  date?: ReactNode;
  interaction?: ReactNode;
  iconSrc?: string;
  isFirst?: boolean;
  isLast?: boolean;
  link?: string;
  onClick?: () => void;
}
const OrderInteractionItem = memo(
  ({ date, isFirst, isLast, interaction, link, iconSrc, onClick }: IOrderInteractionItem) => {
    return (
      <div className="gap-4 flex items-stretch font-inter">
        <div className="flex flex-col items-center">
          <div
            className={twMerge(
              'bg-v2blueGray-200 h-full w-px -translate-x-1/2',
              isFirst && 'bg-transparent',
            )}
          />
          <div className="w-5 h-5 flex-shrink-0 my-0.5 ">
            <img src={iconSrc} loading="lazy" className="h-full" />
          </div>
          <div
            className={twMerge(
              'bg-v2blueGray-200 h-full w-px -translate-x-1/2',
              isLast && 'bg-transparent',
            )}
          />
        </div>
        <div className="flex flex-col gap-px text-sm py-2.5 flex-1">
          <div className="font-semibold ">{interaction}</div>
          <div className="text-v2blueGray-500">{date}</div>
        </div>
        <a
          onClick={onClick}
          href={link}
          className="w-9 h-9 flex justify-center items-center rounded-10  hover:bg-v2blueGray-50 cursor-pointer self-center"
        >
          <OpenInNew width="18" height="18" className="text-v2blueGray-550" />
        </a>
      </div>
    );
  },
);

export { OrderInteractionItem };
