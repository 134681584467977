import { PaymentPlanProps } from '../hooks/usePaymentFormHooks';

export const PLAN_INFO_KEY = 'plan-info';

const saveToLocalStoragePlanInfo = (info: PaymentPlanProps) => {
  localStorage.setItem(PLAN_INFO_KEY, JSON.stringify(info));
};

const getFromLocalStoragePlanInfo = () => {
  const result = localStorage.getItem(PLAN_INFO_KEY);

  return result ? (JSON.parse(result) as PaymentPlanProps) : undefined;
};

const cleanUpLocalStoragePlanInfo = () => {
  localStorage.removeItem(PLAN_INFO_KEY);
};

export { saveToLocalStoragePlanInfo, getFromLocalStoragePlanInfo, cleanUpLocalStoragePlanInfo };
