export function FulfillmentOnboardingIcon({ ...props }) {
  return (
    <svg
      width="1em"
      height="1em"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      fill="currentColor"
      {...props}
    >
      <g id="Group_16441" data-name="Group 16441" transform="translate(-481 -281)">
        <rect
          id="Rectangle_11582"
          data-name="Rectangle 11582"
          width="24"
          height="24"
          transform="translate(481 281)"
          fill="none"
        />
        <g id="Group_17379" data-name="Group 17379" transform="translate(480.893 280.025)">
          <path
            id="Path_41545"
            data-name="Path 41545"
            d="M12.858,20.544l6.1-3.533a.332.332,0,0,0,.115-.115.287.287,0,0,0,.038-.144V9.786l-6.25,3.619ZM11.2,22.18,4.512,18.329a1.775,1.775,0,0,1-.9-1.562V9.182a1.775,1.775,0,0,1,.9-1.562L11.2,3.769a1.783,1.783,0,0,1,1.808,0L19.7,7.621a1.775,1.775,0,0,1,.9,1.562v7.585a1.775,1.775,0,0,1-.9,1.562L13.012,22.18a1.782,1.782,0,0,1-1.808,0Zm4.76-12.306,2.319-1.331L12.262,5.061a.27.27,0,0,0-.308,0L9.783,6.311Zm-3.856,2.238,2.325-1.346L8.258,7.2,5.933,8.544Z"
            fill="currentColor"
          />
        </g>
      </g>
    </svg>
  );
}
