import { memo, useCallback } from 'react';

import { useFormContext } from 'react-hook-form';

import { TOption } from '../../../molecules/Dropdown/Dropdown';
import Tag, { type TagProps } from '../../Tag';

export interface _TagWithIdProps {
  id: string;
  name: string;
}

export type TagWithIdProps = TagProps & _TagWithIdProps;

export function TagWithId({ id, name, children, ...otherProps }: TagWithIdProps) {
  const { getValues, setValue } = useFormContext();

  const handleOnRemove = useCallback(() => {
    const currentSelectedTags = getValues(name) as Array<TOption>;
    const newSelectedTags = currentSelectedTags.filter(({ value }) => value !== id);
    setValue(name, newSelectedTags);
  }, [id, name, getValues, setValue]);

  return (
    <div data-testid="outvioui--tagwithId">
      <Tag {...otherProps} onRemove={handleOnRemove}>
        {children}
      </Tag>
    </div>
  );
}

export default memo<TagWithIdProps>(TagWithId);
