import { memo } from 'react';

import { FormattedMessage } from 'localization';
import { CompatibleLink } from 'ui/compatibility';
import { NavbarMenuItemData } from 'ui/utils';

export const MenuItem = memo(({ langKey, icon, to }: NavbarMenuItemData) => {
  return (
    <CompatibleLink to={to} className="flex">
      <div className="h-9 text-white flex items-center">
        <div className="text-2xl px-2">{icon}</div>
        <div className="text-sm">
          <FormattedMessage id={langKey} />
        </div>
      </div>
    </CompatibleLink>
  );
});
