import { FormattedMessage } from 'localization';

type NextBillingProps = { nextBillingDate: string | undefined | null };

const NextBilling = ({ nextBillingDate }: NextBillingProps) => {
  return nextBillingDate ? (
    <div className="text-xs px-3 text-v2blueGray-500">
      <FormattedMessage
        id="settings.subs.next-billing"
        values={{
          lineBreak: <br />,
          date: (
            <span className="text-v2blueGray-700">
              {new Intl.DateTimeFormat('en-GB', {
                day: '2-digit',
                month: '2-digit',
                year: 'numeric',
              }).format(new Date(nextBillingDate))}
            </span>
          ),
        }}
      />
    </div>
  ) : (
    <></>
  );
};

export default NextBilling;
