import { useMutation } from '@apollo/client';
import { MutationHookOptions } from '@apollo/client/react/types/types';
import { StoreCompany } from 'app-types/core';

import { CompanyInfoUpdateMutation } from '../../../gql/core/mutations';

export function useUpdateCompanyInfo(options?: MutationHookOptions) {
  const [mutate, { loading, error }] = useMutation<StoreCompany>(CompanyInfoUpdateMutation, {
    ...options,
    context: { clientName: 'core' },
  });

  return {
    updateCompanyInfo: async (updatedCompanyInfo: Partial<StoreCompany>) => {
      const { data } = await mutate({
        variables: { input: { company: updatedCompanyInfo } },
      });
      return data;
    },
    loading,
    error,
  };
}
