import Flag from 'react-world-flags';

import { Languages } from 'app-types/desk';
import { Catalan } from 'icons/icons/flags';
import { useIntl } from 'localization';

const useGetSupportedLanguages = () => {
  const { formatMessage } = useIntl();

  const result = Object.values(Languages)
    .map((language) => ({
      label: formatMessage({ id: `desk-app.${language}` }),
      value: language,
      icon: specialCoutryFlags[language] || (
        <Flag className="rounded-sm w-[18px] h-[14px] object-cover" code={countryCodes[language]} />
      ),
    }))
    .sort((a, b) => a?.label?.localeCompare(b?.label));

  return result;
};

export default useGetSupportedLanguages;

const specialCoutryFlags: Partial<Record<Languages, JSX.Element>> = {
  [Languages.Ca]: <Catalan className="rounded-sm w-[18px] h-[14px] object-cover" />,
};

export const countryCodes: Record<Languages, string> = {
  [Languages.Bg]: 'BG',
  [Languages.Ca]: 'ES-CA',
  [Languages.Cs]: 'CZ',
  [Languages.Da]: 'DK',
  [Languages.De]: 'DE',
  [Languages.El]: 'GR',
  [Languages.En]: 'GB',
  [Languages.Es]: 'ES',
  [Languages.Et]: 'EE',
  [Languages.Fi]: 'FI',
  [Languages.Fr]: 'FR',
  [Languages.Ga]: 'IE',
  [Languages.Hr]: 'HR',
  [Languages.Hu]: 'HU',
  [Languages.It]: 'IT',
  [Languages.Lt]: 'LT',
  [Languages.Lv]: 'LV',
  [Languages.Nl]: 'NL',
  [Languages.No]: 'NO',
  [Languages.Pl]: 'PL',
  [Languages.Pt]: 'PT',
  [Languages.Ro]: 'RO',
  [Languages.Sk]: 'SK',
  [Languages.Sl]: 'SI',
  [Languages.Sv]: 'SE',
};
