export default {
  'orders.package': 'Przesyłka',
  'orders.returns.reasons.size': 'Rozmiar nie pasuje',
  'orders.returns.reasons.wrong-product': 'Dostarczono zły przedmiot',
  'orders.returns.reasons.wrong-size': 'Dostarczono zły rozmiar',
  'orders.returns.reasons.wrong-colour': 'Zły kolor',
  'orders.returns.reasons.package-broken': 'Zniszczona przesyłka',
  'orders.returns.reasons.product-broken': 'Przedmiot zniszczony/nie działa',
  'orders.returns.reasons.product-used': 'Przedmiot był użyty',
  'orders.returns.reasons.product-dirty': 'Brudny przedmiot',
  'orders.returns.reasons.do-not-want': 'Już go nie chcę',
  'orders.returns.reasons.exchange': 'Chciałbym wymienić przedmiot',
  'orders.returns.reasons.other': 'Inne',
  'orders.returns.pickup.form.comments': 'Komentarz',
  'orders.list.empty': 'Obecnie nie masz żadnych zamówień',
  'orders.total': 'Suma',
  'orders.vat': 'VAT',
  'or.confirm-address': 'POTWIERDZ ADRES ODBIORU',
  'or.from': 'Od',
  'or.to': 'Do',
  'or.return.list.empty': 'Obecnie nie masz żadnych zwrotów',
  'or.confirm-return': 'POTWIERDŹ ZWROT',
};
