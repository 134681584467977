export function Lock({ ...props }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="currentColor"
      {...props}
    >
      <g transform="translate(24.109 -0.001) rotate(90)">
        <path
          d="M2897,3650v-2a5,5,0,0,0-10,0v2m5,4.5v2m-3.2,4.5h6.4a7.375,7.375,0,0,0,3.16-.33,2.868,2.868,0,0,0,1.31-1.31,7.375,7.375,0,0,0,.33-3.16v-1.4a7.375,7.375,0,0,0-.33-3.16,2.868,2.868,0,0,0-1.31-1.31,7.375,7.375,0,0,0-3.16-.33h-6.4a7.375,7.375,0,0,0-3.16.33,2.868,2.868,0,0,0-1.31,1.31,7.375,7.375,0,0,0-.33,3.16v1.4a7.375,7.375,0,0,0,.33,3.16,2.868,2.868,0,0,0,1.31,1.31A7.375,7.375,0,0,0,2888.8,3661Z"
          transform="translate(-3640 2904.108) rotate(-90)"
          fill="none"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
      </g>
    </svg>
  );
}
