import { useState } from 'react';

import { CalendarIcon, CallIcon } from 'icons';
import { FormattedMessage } from 'localization';
import { match } from 'ts-pattern';
import { ConfirmationWindow } from 'ui';
import { Copy } from 'ui/atoms';

type MeetingConfirmationProps = {
  onClose: () => void;
  meetingType?: 'RequestCall' | 'RequestDemo';
};

const MeetingConfirmation = ({ onClose, meetingType }: MeetingConfirmationProps) => {
  const [type] = useState(meetingType);

  if (!meetingType) {
    return <></>;
  }

  const { headingIcon, titleKey, descriptionKey } = match(type)
    .with('RequestCall', () => ({
      titleKey: 'settings.subs.cancel.book-meeting-confirmation.title',
      descriptionKey: 'settings.subs.cancel.book-call-confirmation.description',
      headingIcon: <CallIcon className="text-2xl " />,
    }))
    .otherwise(() => ({
      titleKey: 'settings.subs.cancel.book-meeting-confirmation.title',
      descriptionKey: 'settings.subs.cancel.book-demo-confirmation.description',
      headingIcon: <CalendarIcon className="text-2xl " />,
    }));

  return (
    <ConfirmationWindow
      headingIcon={headingIcon}
      theme="info"
      title={<FormattedMessage id={titleKey} />}
      content={
        <Copy>
          <FormattedMessage id={descriptionKey} />
        </Copy>
      }
      cancelButtonTitle="close"
      onCancel={onClose}
    />
  );
};

export default MeetingConfirmation;
