import { ReactNode, memo } from 'react';

import { twMerge } from 'tailwind-merge';

import './DashboardKpiList.css';

export interface DashboardKpiListProps {
  children?: ReactNode;
  className?: string;
}

function DashboardKpiListBase({ children, className }: DashboardKpiListProps) {
  return <div className={twMerge('dashboard-kpi-list', className)}>{children}</div>;
}

export const DashboardKpiList = memo(DashboardKpiListBase);
