export default {
  'ovc.general.powered-by': 'Powered by',
  'ovc.auth.identity.email.placeholder': 'Email', // custLanding.form.email
  'ovc.auth.identity.cta': 'Fazer uma devolução', // landing.make-a-return
  'ovc.auth.order.orderNumber.placeholder': 'N.º da encomenda ou n.º de tracking', // custLanding.form.orderNumber
  'ovc.auth.order.clientInfo.placeholder':
    'Código Postal ou últimos 5 digitos do contacto telefónico', // custLanding.form.clientInfo
  'ovc.auth.switch-to-identity': 'Entra com o teu email', // custLanding.switch-to-identity
  'ovc.auth.switch-to-order': 'Entra com os dados da encomenda', // custLanding.switch-to-order
  'ovc.auth.tracking.cta': 'Segue a tua encomenda', // custLanding.tracking.btn
  'ovc.auth.tracking.orderIdentifier.placeholder': 'ID da encomenda ou n.º de tracking', // custLanding.tracking.orderIdentifier
  'ovc.auth.tracking.postcode.placeholder': 'insere o Código Postal do destino', // custLanding.tracking.postcode
  'ovc.listing.page.all': 'Todas as encomendas',
  'ovc.listing.page.in-transit': 'Encomendas em trânsito',
  'ovc.listing.page.returns': 'Devoluções',
  'ovc.listing.header.order': 'encomenda',
  'ovc.listing.header.date': 'data',
  'ovc.listing.header.total': 'total',
  'ovc.listing.header.choose-product': 'Escolhe o produto:',
  'ovc.listing.listing-track-cta': 'seguir',
  'ovc.listing.listing-return-cta': 'devolver',
  'ovc.listing.listing-external-action-cta': 'solicitar reparação dentro da garantia',
  'ovc.listing.empty-list': 'Sem encomendas para mostrar',
  'ovc.listing.return-status.store-non-returnable': 'Não pode ser devolvido',
  'ovc.listing.return-status.already-returned': 'Devolvido no dia {date}',
  'ovc.listing.return-status.no-longer-returnable': 'Já não pode ser devolvido',
  'ovc.listing.return-status.returnable': 'Devolver até ao dia {date}',
  'ovc.return-cart.refund-method-credit': `Seleciona esta opção caso pretendas utilizar o valor do reembolso em compras futuras na nossa loja!{lineBreak}
    {lineBreak}
    Vamos enviar-te um vale de compra no valor do teu reembolso.`,
  'ovc.return-cart.inst.outvio.heading.confirm-pickup':
    'confirma o endereço de recolha e de devolução',
  'ovc.return-cart.inst.outvio.heading.confirm-return': 'confirma a tua devolução',
  'ovc.return-cart.address.heading':
    'adiciona um novo endereço ou seleciona um endereço existente:',
};
