export default function ConnectCMSCompleteIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 19 19">
      <g transform="translate(-2.5 -2.5)">
        <path
          d="M10.581,14.146,8.258,11.823a.724.724,0,0,0-.522-.212.707.707,0,0,0-.532.212.747.747,0,0,0,0,1.054l2.744,2.744a.874.874,0,0,0,1.265,0l5.563-5.563a.725.725,0,0,0,.213-.522A.707.707,0,0,0,16.777,9a.747.747,0,0,0-1.054,0ZM12,21.5A9.255,9.255,0,0,1,8.3,20.752a9.486,9.486,0,0,1-5.048-5.046,9.547,9.547,0,0,1,0-7.409A9.485,9.485,0,0,1,8.294,3.248a9.546,9.546,0,0,1,7.409,0,9.486,9.486,0,0,1,5.048,5.046,9.547,9.547,0,0,1,0,7.409,9.485,9.485,0,0,1-5.046,5.048A9.245,9.245,0,0,1,12,21.5Z"
          fill="#4bb4b3"
        />
      </g>
    </svg>
  );
}
