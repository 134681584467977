import { ReactNode, memo } from 'react';

import { twMerge } from 'tailwind-merge';

import './DashboardKpiGrid.css';

export interface DashboardKpiGridBaseProps {
  children?: ReactNode;
  className?: string;
}

function DashboardKpiGridBase({ children, className }: DashboardKpiGridBaseProps) {
  return <div className={twMerge('dashboard-kpi-grid grid-cols-2', className)}>{children}</div>;
}

export const DashboardKpiGrid = memo(DashboardKpiGridBase);
