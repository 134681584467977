import {
  AiRecognitionIcon,
  AllInOneInbox,
  AnalyticsIcon,
  AutomationsIcon,
  LiveChatIcon,
  SocialMediaIcon,
  TemplatesIcon,
} from 'icons';
import { FormattedMessage } from 'localization';
import { Button, ButtonVariant, Copy, Heading } from 'ui/atoms';
import LayerWithSideBar from 'ui/src/molecules/LayerWithSidebar';

import { DowngradeStep } from '../../DowngradePlanFlow';

type LostDeskFeaturesProps = {
  onClose: () => void;
  setStep: (newStep: DowngradeStep) => void;
};

const LostDeskFeatures = ({ onClose, setStep }: LostDeskFeaturesProps) => {
  const handleNextStep = () => {
    setStep(DowngradeStep.GetOffer);
  };

  return (
    <LayerWithSideBar
      className="rounded-[22px]"
      sideBarContent={
        <>
          <div className="px-2 pt-2">
            <Heading className="mb-[29px] capitalize">
              <FormattedMessage id="settings.subs.desk-cancel.title" />
            </Heading>
            <Copy className="mb-10 whitespace-pre-line" type="copy7">
              <FormattedMessage id="settings.subs.desk-cancel.lost-features.description" />
            </Copy>
          </div>
          <Button onClick={onClose}>
            <FormattedMessage id="settings.subs.cancel.keep-my-advantages" />
          </Button>
        </>
      }
    >
      <>
        <Heading type="heading2" weight="regular" className="px-2 pt-2 mb-6">
          <FormattedMessage id="settings.subs.cancel.features-you-will-lose" />
        </Heading>
        <div className="md:h-[355px] overflow-y-hidden md:overflow-y-auto">
          <div className="flex flex-col gap-2">
            {featureOptions.map(({ feature, icon, subFeatures }) => {
              return (
                <div
                  className="border border-v2blueGray-200 rounded-[14px] px-6 pt-6 pb-8 flex gap-4"
                  key={feature}
                >
                  <div
                    className="w-8 h-8 rounded-[10px] bg-v2blueGray-100 
                              flex items-center justify-center text-v2blueGray-700 text-[24px]"
                  >
                    {icon}
                  </div>
                  <div className="flex-1">
                    <Heading type="heading4" weight="medium" className="mb-4">
                      <FormattedMessage id={`settings.desk.feature.${feature}`} />
                    </Heading>

                    <div className="flex flex-col gap-[7px] text-v2blueGray-550">
                      {subFeatures.map((subFeature) => (
                        <Copy as="span" noColor className="flex gap-2 items-start" key={subFeature}>
                          <div className="font-bold text-xs mt-[3px]">·</div>
                          <FormattedMessage
                            id={`settings.desk.sub-feature.${feature}.${subFeature}`}
                          />
                        </Copy>
                      ))}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>

          <Button onClick={handleNextStep} className="mt-4 w-full" theme={ButtonVariant.Secondary}>
            <FormattedMessage id="settings.subs.cancel.lose-all-advantages" />
          </Button>
        </div>
      </>
    </LayerWithSideBar>
  );
};

const featureOptions = [
  {
    feature: 'all-in-one-inbox',
    subFeatures: ['centralized-communication'],
    icon: <AllInOneInbox />,
  },
  {
    feature: 'live-chat',
    subFeatures: ['real-time-communication', 'personalized', 'multi-lang', '24/7-customer-support'],
    icon: <LiveChatIcon />,
  },
  {
    feature: 'ai',
    subFeatures: ['order-matching', 'clients-insights'],
    icon: <AiRecognitionIcon />,
  },
  {
    feature: 'automations',
    subFeatures: ['unlimited', 'assignment-rules', 'workflow-creation', 'auto-reply-to-messages'],
    icon: <AutomationsIcon />,
  },
  {
    feature: 'templates',
    subFeatures: ['unlimited', 'multi-lang', 'meta-tags', 'instant-replies'],
    icon: <TemplatesIcon />,
  },
  {
    feature: 'social-media',
    subFeatures: [
      'reply-to-fb-comments',
      'integration-with-fb-messenger',
      'reply-to-insta-comments',
      'integration-insta-direct-messages',
      'integration-whatsapp',
    ],
    icon: <SocialMediaIcon />,
  },
  {
    feature: 'analytics',
    subFeatures: ['per-channel', 'monitor-agent-performance', 'gather-agents-reviews'],
    icon: <AnalyticsIcon />,
  },
];

export default LostDeskFeatures;
