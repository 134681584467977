import React, { FC, useContext } from 'react';

import EditRoundedIcon from '@mui/icons-material/EditRounded';

import TrackingPageContext from '../../Context/TrackingPageContext';
import { EDIT_MODE_TABS } from '../../Interfaces/IEditModal';

interface EditButton {
  icon?: React.ReactElement;
  position?: string;
  tab: EDIT_MODE_TABS;
}

const EditButton: FC<EditButton> = (props) => {
  const { icon, position = 'top-2 right-2', tab } = props;
  const { handleEditButton } = useContext(TrackingPageContext);
  return (
    <button
      type="button"
      className={`absolute bg-violet-700 text-white rounded flex p-0.5 hover:bg-fuchsia-600 transition-colors duration-100 ${position}`}
      onClick={() => handleEditButton && handleEditButton(tab)}
    >
      {icon || <EditRoundedIcon fontSize="small" />}
    </button>
  );
};

export default EditButton;
