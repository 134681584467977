import { gql } from '@apollo/client';

export const DAILY_AGENTS_AGENTS_STATISTICS = gql`
  query DailyAgents_agentsStatistics(
    $from: DateTime!
    $to: DateTime!
    $limit: Float
    $sortKey: DailyAgentsSortKey
    $sortOrder: SortOrder
  ) {
    DailyAgents_agentsStatistics(
      from: $from
      to: $to
      limit: $limit
      sortKey: $sortKey
      sortOrder: $sortOrder
    ) {
      agentId
      assigned
      avgFirstResponseTime
      avgResolutionTime
      closed
      rating
    }
  }
`;

export const DAILY_TICKETS_COUNT_TICKETS = gql`
  query DailyTickets_countTickets(
    $from: DateTime!
    $to: DateTime!
    $channels: [TicketChannel!]
    $tags: [String!]
  ) {
    DailyTickets_countTickets(from: $from, to: $to, channels: $channels, tags: $tags) {
      closedTickets
      newTickets
    }
  }
`;

export const DAILY_TICKETS_COUNT_TICKETS_PER_CHANNELS = gql`
  query DailyTickets_countTicketsPerChannels($from: DateTime!, $to: DateTime!) {
    DailyTickets_countTicketsPerChannels(from: $from, to: $to) {
      chat
      date
      email
      facebook
      instagram
      whatsapp
      facebookComment
      instagramComment
    }
  }
`;

export const DAILY_TICKETS_COUNT_TICKETS_PER_DAY = gql`
  query DailyTickets_countTicketsPerDay(
    $from: DateTime!
    $to: DateTime!
    $channels: [TicketChannel!]
    $tags: [String!]
  ) {
    DailyTickets_countTicketsPerDay(from: $from, to: $to, channels: $channels, tags: $tags) {
      closedTickets
      date
      newTickets
    }
  }
`;
