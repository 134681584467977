export function CallIcon({ ...props }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24" {...props}>
      <g id="Group_9666" transform="translate(-960 -156)">
        <rect
          id="Rectangle_4521"
          width="24"
          height="24"
          transform="translate(960 156)"
          fill="none"
        />
        <path
          id="Path_30513"
          data-name="Path 30513"
          d="M0,9.969A9.956,9.956,0,0,1,9.864,0h.411A9.962,9.962,0,0,1,20.551,9.558v.411a9.956,9.956,0,0,1-9.864,9.969h-.411a9.932,9.932,0,0,1-2.98-.411H6.782l-2.055.925A.864.864,0,0,1,3.6,20.04c0-.1-.1-.206-.1-.308l-.1-1.85a.888.888,0,0,0-.308-.617A10.094,10.094,0,0,1,0,9.969Z"
          transform="translate(961.664 157.664)"
          fill="none"
        />
        <path
          id="call_FILL1_wght300_GRAD0_opsz24"
          d="M154.066-805a11.894,11.894,0,0,1-5.012-1.16,16.007,16.007,0,0,1-4.63-3.272,16.1,16.1,0,0,1-3.264-4.626,11.867,11.867,0,0,1-1.16-5.007.912.912,0,0,1,.265-.666.89.89,0,0,1,.662-.269h2.878a.881.881,0,0,1,.59.218.959.959,0,0,1,.325.539l.506,2.6a1.484,1.484,0,0,1-.022.621.923.923,0,0,1-.268.436l-2.038,1.984a12.236,12.236,0,0,0,1.124,1.7,16.819,16.819,0,0,0,1.368,1.535,15.229,15.229,0,0,0,1.544,1.349,15.51,15.51,0,0,0,1.766,1.159l1.98-2a1.121,1.121,0,0,1,.5-.3,1.441,1.441,0,0,1,.612-.042l2.45.5a1.037,1.037,0,0,1,.546.341.87.87,0,0,1,.211.574v2.861a.891.891,0,0,1-.269.662A.912.912,0,0,1,154.066-805Z"
          transform="translate(824.499 980.499)"
          fill="currentColor"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.6"
        />
      </g>
    </svg>
  );
}
