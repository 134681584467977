import { memo, useMemo } from 'react';

import { useUserProfile } from 'data-layer';
import { NavbarMenuGroup, getNavbarDataByUser } from 'ui/utils';

import { Delimiter } from './Delimiter';
import { MenuGroup } from './MenuGroup';
import { MenuItem } from './MenuItem';

export const MobileMenu = memo(() => {
  const { data } = useUserProfile();
  const roles = data?.roles;

  const { shipping, postShipping, secondaryMenu } = useMemo(() => {
    const { getByGroup } = getNavbarDataByUser({
      roles,
    });
    return {
      shipping: getByGroup([NavbarMenuGroup.shipping]),
      postShipping: getByGroup([NavbarMenuGroup.postShipping]),
      secondaryMenu: getByGroup([NavbarMenuGroup.secondaryMenu, NavbarMenuGroup.mobile]),
    };
  }, [roles]);

  return (
    <>
      <Delimiter />
      <div className="overflow-y-auto h-full">
        <MenuGroup>
          {shipping.map((item) => (
            <MenuItem key={item.id} {...item} />
          ))}
        </MenuGroup>
        {postShipping.length ? (
          <>
            <Delimiter />
            <MenuGroup>
              {postShipping.map((item) => (
                <MenuItem key={item.id} {...item} />
              ))}
            </MenuGroup>
          </>
        ) : null}
        {secondaryMenu.length ? (
          <>
            <Delimiter />
            <MenuGroup>
              {secondaryMenu.map((item) => (
                <MenuItem key={item.id} {...item} />
              ))}
            </MenuGroup>
          </>
        ) : null}
      </div>
    </>
  );
});
