export function InsertImageShortcut({ ...props }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="1em" height="1em" {...props}>
      <g id="Group_10969" data-name="Group 10969" transform="translate(-2.339 -2.375)">
        <g
          id="Rectangle_8501"
          data-name="Rectangle 8501"
          transform="translate(2.339 2.375)"
          fill="#849fad"
          stroke="#707070"
          strokeWidth="1"
          opacity="0"
        >
          <rect width="24" height="24" stroke="none" />
          <rect x="0.5" y="0.5" width="23" height="23" fill="none" />
        </g>
        <g id="Group_10990" data-name="Group 10990" transform="translate(2.299 1.901)">
          <path
            id="Path_39095"
            data-name="Path 39095"
            d="M5.347,20.974A1.793,1.793,0,0,1,3.54,19.166V5.782A1.793,1.793,0,0,1,5.347,3.974H18.732A1.793,1.793,0,0,1,20.54,5.782V19.166a1.793,1.793,0,0,1-1.808,1.808Zm2.346-3.75h8.769a.428.428,0,0,0,.4-.246.417.417,0,0,0-.04-.477L14.44,13.307a.452.452,0,0,0-.723,0l-2.446,3.187-1.638-2.1a.442.442,0,0,0-.357-.171.433.433,0,0,0-.357.181L7.342,16.5a.408.408,0,0,0-.05.477A.428.428,0,0,0,7.694,17.224Z"
            fill="#849fad"
          />
        </g>
      </g>
    </svg>
  );
}
