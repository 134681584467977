import { Fragment, MouseEvent, ReactNode, memo, useCallback, useMemo } from 'react';

import { flip, offset, shift, useFloating } from '@floating-ui/react-dom';
import { Menu } from '@headlessui/react';
import { AgentStatuses } from 'data-layer';
import { FormattedMessage } from 'localization';
import { twMerge } from 'tailwind-merge';

import { DropdownDivider } from '../Dropdown/DropdownDivider';
import { DropdownItem } from '../Dropdown/DropdownItem';
import { DropdownPopup } from '../Dropdown/DropdownPopup';
import { AgentPanelButton } from './AgentPanelButton';
import { AgentPanelLogo, UserLogoSize } from './AgentPanelLogo';
import './AssignAgentPanel.css';

interface IAgent {
  _id: string;
  firstname: string;
  lastname: string;
  status: AgentStatuses;
}
export interface IAssignAgentPanel {
  value?: string;
  options?: Array<IAgent>;
  onChange?: (value?: string) => void;
  disabled?: boolean;
  className?: string;
}

export function AssignAgentPanel({
  value,
  onChange,
  options,
  disabled,
  className,
}: IAssignAgentPanel) {
  const valueObj = useMemo(() => {
    const option = options?.find((option) => option._id === value);
    return option || { firstname: '', lastname: '', status: undefined };
  }, [value, options]);

  const handleChange = useCallback(
    (value: any) => {
      onChange && onChange(value);
    },
    [onChange],
  );

  const { online, offline } = useMemo(() => {
    const online = options?.filter(
      ({ status }) => status === AgentStatuses.Online || status === AgentStatuses.Away,
    );
    const offline = options?.filter(
      ({ status }) => status === AgentStatuses.Offline || status === AgentStatuses.Reassign,
    );
    return { online, offline };
  }, [options]);

  const abbr = `${valueObj?.firstname?.charAt(0)}${valueObj?.lastname?.charAt(0)}`;

  const { refs, floatingStyles } = useFloating({
    placement: 'bottom-start',
    middleware: [offset(4), flip(), shift()],
  });

  return (
    <Menu
      ref={refs.setReference}
      as="div"
      className={twMerge('relative z-1 max-w-[188px]', className)}
    >
      {({ open }) => (
        <>
          <Menu.Button disabled={disabled} as={Fragment}>
            <div className={'relative'}>
              <AgentPanelButton
                logo={
                  <AgentPanelLogo
                    name={`${valueObj?.firstname} ${valueObj?.lastname}`}
                    color={valueObj?.status ? 'random' : undefined}
                    status={valueObj?.status}
                  >
                    {!!abbr && abbr}
                  </AgentPanelLogo>
                }
                open={open}
                title={
                  value ? (
                    <div className="flex whitespace-nowrap">
                      <span className="overflow-hidden overflow-ellipsis">{`${valueObj?.firstname}`}</span>
                      <span>&nbsp;</span>
                      <span>{`${valueObj?.lastname?.charAt(0)}.`}</span>
                    </div>
                  ) : (
                    <FormattedMessage id="desk-app.store-unassigned" />
                  )
                }
                subtitle={<FormattedMessage id="desk-app.assigned-agent" />}
              />
            </div>
          </Menu.Button>
          <DropdownPopup
            ref={refs.setFloating}
            style={floatingStyles}
            className="static m-0"
            classNameWrapper="w-full"
            position="bottom-start"
            open={open}
          >
            <AssignAgentPanelSection
              title={<FormattedMessage id="desk-app.online" />}
              options={online}
              value={value}
              handleChange={handleChange}
            />
            <DropdownDivider className="mt-4" />
            <AssignAgentPanelSection
              title={<FormattedMessage id="desk-app.offline" />}
              options={offline}
              value={value}
              handleChange={handleChange}
            />
          </DropdownPopup>
        </>
      )}
    </Menu>
  );
}

export default memo<IAssignAgentPanel>(AssignAgentPanel);

export const AssignAgentPanelSection = memo(
  ({
    title,
    options,
    value,
    handleChange,
  }: {
    value?: string;
    title: ReactNode;
    options?: Array<IAgent>;
    handleChange?: (value: string, event: MouseEvent<HTMLDivElement>) => void;
  }) => {
    return (
      <>
        <div className="px-4 pt-4 pb-3 text-xs text-v2blueGray-500 agent-section-title">
          {title}
        </div>
        <div className="flex flex-col w-full gap-[5px]">
          {options?.map(({ _id, lastname, firstname, status }, index) => {
            const abbr = `${firstname?.charAt(0)}${lastname?.charAt(0)}`;
            const isOffline = status === AgentStatuses.Offline || status === AgentStatuses.Reassign;
            return (
              <DropdownItem
                key={`${_id}${index}`}
                onClick={(e) => handleChange && handleChange(_id, e)}
                className="px-3"
                selected={_id === value}
              >
                <div className={`flex gap-2 items-center w-full${isOffline ? ' offline' : ''}`}>
                  <AgentPanelLogo
                    name={`${firstname} ${lastname}`}
                    size={UserLogoSize.small}
                    color={isOffline ? undefined : 'random'}
                    status={status === AgentStatuses.Offline ? AgentStatuses.Reassign : status}
                    className={isOffline ? 'bg-v2blueGray-200' : undefined}
                  >
                    {!!abbr && abbr}
                  </AgentPanelLogo>
                  <div className="text-sm overflow-hidden overflow-ellipsis agent-section-name">
                    {firstname} {lastname}
                  </div>
                </div>
              </DropdownItem>
            );
          })}
        </div>
      </>
    );
  },
);
