export default {
  'cc.AF': 'Afganistán',
  'cc.AX': 'Islas Aland',
  'cc.AL': 'Albania',
  'cc.DZ': 'Algeria',
  'cc.AS': 'Samoa Americana',
  'cc.AD': 'Andorra',
  'cc.AO': 'Angola',
  'cc.AI': 'Anguila',
  'cc.AQ': 'Antártida',
  'cc.AG': 'Antigua y Barbuda',
  'cc.AR': 'Argentina',
  'cc.AM': 'Armenia',
  'cc.AW': 'Aruba',
  'cc.AU': 'Australia',
  'cc.AT': 'Austria',
  'cc.AZ': 'Azerbayán',
  'cc.BS': 'Bahamas',
  'cc.BH': 'Bahrein',
  'cc.BD': 'Bangladesh',
  'cc.BB': 'Barbados',
  'cc.BY': 'Bielorrusia',
  'cc.BE': 'Bélgica',
  'cc.BZ': 'Belice',
  'cc.BJ': 'Benín',
  'cc.BM': 'Islas Bermudas',
  'cc.BT': 'Bhután',
  'cc.BO': 'Bolivia',
  'cc.BA': 'Bosnia y Herzegovina',
  'cc.BW': 'Botsuana',
  'cc.BV': 'Isla Bouvet',
  'cc.BR': 'Brasil',
  'cc.VG': 'Islas Vírgenes Británicas',
  'cc.IO': 'Territorios Brtiánicos del Océano Índico',
  'cc.BN': 'Brunei Darussalam',
  'cc.BG': 'Bulgaria',
  'cc.BF': 'Burkina Faso',
  'cc.BI': 'Burundi',
  'cc.KH': 'Camboya',
  'cc.CM': 'Camerún',
  'cc.CA': 'Canada',
  'cc.CV': 'Cabo Verde',
  'cc.KY': 'Islas Caymán',
  'cc.CF': 'República Centroafricana',
  'cc.TD': 'Chad',
  'cc.CL': 'Chile',
  'cc.CN': 'China',
  'cc.HK': 'Hong Kong',
  'cc.MO': 'Macao',
  'cc.CX': 'Isla de Navidad',
  'cc.CC': 'Islas Cocos',
  'cc.CO': 'Colombia',
  'cc.KM': 'Comoras',
  'cc.CG': 'Congo (Brazzaville)',
  'cc.CD': 'Congo (Kinshasa)',
  'cc.CK': 'Islas Cook',
  'cc.CR': 'Costa Rica',
  'cc.CI': 'Costa de Marfil',
  'cc.HR': 'Croacia',
  'cc.CU': 'Cuba',
  'cc.CY': 'Chipre',
  'cc.CZ': 'República Checa',
  'cc.DK': 'Dinamarca',
  'cc.DJ': 'Yibuti',
  'cc.DM': 'Dominica',
  'cc.DO': 'República Dominicana',
  'cc.EC': 'Ecuador',
  'cc.EG': 'Egipto',
  'cc.SV': 'El Salvador',
  'cc.GQ': 'Guinea Ecuatorial',
  'cc.ER': 'Eritrea',
  'cc.EE': 'Estonia',
  'cc.ET': 'Etiopía',
  'cc.FK': 'Islas Fakland',
  'cc.FO': 'Islas Faroe',
  'cc.FJ': 'Fiyi',
  'cc.FI': 'Finlandia',
  'cc.FR': 'Francia',
  'cc.GF': 'Guayana Francesa',
  'cc.PF': 'Polinesia Francesa',
  'cc.TF': 'Territorios franceses del sur',
  'cc.GA': 'Gabón',
  'cc.GM': 'Gambia',
  'cc.GE': 'Georgia',
  'cc.DE': 'Alemania',
  'cc.GH': 'Gana',
  'cc.GI': 'Gibraltar',
  'cc.GR': 'Grecia',
  'cc.GL': 'Groenlandia',
  'cc.GD': 'Granada',
  'cc.GP': 'Guadalupe',
  'cc.GU': 'Guam',
  'cc.GT': 'Guatemala',
  'cc.GG': 'Guernsey',
  'cc.GN': 'Guinea',
  'cc.GW': 'Guinea-Bisáu',
  'cc.GY': 'Guayana',
  'cc.HT': 'Haití',
  'cc.HM': 'Isla de Heard e Islas McDonalds',
  'cc.VA': 'Vaticano',
  'cc.HN': 'Honduras',
  'cc.HU': 'Hungría',
  'cc.IS': 'Islandia',
  'cc.IN': 'India',
  'cc.ID': 'Indonesia',
  'cc.IR': 'Irán',
  'cc.IQ': 'Irak',
  'cc.IE': 'Irlanda',
  'cc.IM': 'Isla de Man',
  'cc.IL': 'Israel',
  'cc.IT': 'Italia',
  'cc.JM': 'Jamaica',
  'cc.JP': 'Japón',
  'cc.JE': 'Jersey',
  'cc.JO': 'Jordania',
  'cc.KZ': 'Kazakhstan',
  'cc.KE': 'Kenia',
  'cc.KI': 'Kiribati',
  'cc.KP': 'Corea del Norte',
  'cc.KR': 'Corea del Sur',
  'cc.KW': 'Kuwait',
  'cc.KG': 'Kirguistán',
  'cc.LA': 'Laos',
  'cc.LV': 'Letonia',
  'cc.LB': 'Líbano',
  'cc.LS': 'Lesoto',
  'cc.LR': 'Liberia',
  'cc.LY': 'Libia',
  'cc.LI': 'Liechtenstein',
  'cc.LT': 'Lituania',
  'cc.LU': 'Luxemburgo',
  'cc.MK': 'Republica de Macedonia',
  'cc.MG': 'Madagascar',
  'cc.MW': 'Malawi',
  'cc.MY': 'Malasia',
  'cc.MV': 'Maldivas',
  'cc.ML': 'Mali',
  'cc.MT': 'Malta',
  'cc.MH': 'Islas Marshall',
  'cc.MQ': 'Martinica',
  'cc.MR': 'Mauritania',
  'cc.MU': 'Mauricio',
  'cc.YT': 'Mayotte',
  'cc.MX': 'México',
  'cc.FM': 'Micronesia',
  'cc.MD': 'Moldavia',
  'cc.MC': 'Mónaco',
  'cc.MN': 'Mongolia',
  'cc.ME': 'Montenegro',
  'cc.MS': 'Montserrat',
  'cc.MA': 'Marruecos',
  'cc.MZ': 'Mozambique',
  'cc.MM': 'Myanmar',
  'cc.NA': 'Namibia',
  'cc.NR': 'Nauru',
  'cc.NP': 'Nepal',
  'cc.NL': 'Países Bajos',
  'cc.AN': 'Antillas Holandesas',
  'cc.NC': 'Nueva Caledonia',
  'cc.NZ': 'Nueva Zelanda',
  'cc.NI': 'Nicaragua',
  'cc.NE': 'Níger',
  'cc.NG': 'Nigeria',
  'cc.NU': 'Niue',
  'cc.NF': 'Isla de Norfolk',
  'cc.MP': 'Islas Marianas del Norte',
  'cc.NO': 'Noruega',
  'cc.OM': 'Omán',
  'cc.PK': 'Pakistán',
  'cc.PW': 'Palau',
  'cc.PS': 'Territorios Ocupados de Palestina',
  'cc.PA': 'Panamá',
  'cc.PG': 'Papúa Nueva Guinea',
  'cc.PY': 'Paraguay',
  'cc.PE': 'Perú',
  'cc.PH': 'Filipinas',
  'cc.PN': 'Pitcairn',
  'cc.PL': 'Polonia',
  'cc.PT': 'Portugal',
  'cc.PR': 'Puerto Rico',
  'cc.QA': 'Catar',
  'cc.RE': 'Reunión',
  'cc.RO': 'Rumanía',
  'cc.RU': 'Rusia',
  'cc.RW': 'Ruanda',
  'cc.BL': 'San Bartolomé',
  'cc.SH': 'Santa Helena',
  'cc.KN': 'San Cristóbal y Nieves',
  'cc.LC': 'Santa Lucía',
  'cc.MF': 'San Martín',
  'cc.PM': 'Saint Pierre y Miquelon',
  'cc.VC': 'San Vicente y las Granadinas',
  'cc.WS': 'Samoa',
  'cc.SM': 'San Marino',
  'cc.ST': 'Santo Tomé y Príncipe',
  'cc.SA': 'Arabia Saudí',
  'cc.SN': 'Senegal',
  'cc.RS': 'Serbia',
  'cc.SC': 'Seychelles',
  'cc.SL': 'Sierra Leona',
  'cc.SG': 'Singapur',
  'cc.SK': 'Eslovaquia',
  'cc.SI': 'Eslovenia',
  'cc.SB': 'Islas Salomón',
  'cc.SO': 'Somalia',
  'cc.ZA': 'Sudáfrica',
  'cc.GS': 'Georgia del Sur e Sila Sandwich',
  'cc.SS': 'Sudán del Sur',
  'cc.ES': 'España',
  'cc.LK': 'Sri Lanka',
  'cc.SD': 'Sudán',
  'cc.SR': 'Surinam',
  'cc.SJ': 'Svalbard y Jan Mayen',
  'cc.SZ': 'Suazilandia',
  'cc.SE': 'Suecia',
  'cc.CH': 'Suiza',
  'cc.SY': 'Siria',
  'cc.TW': 'Taiwan',
  'cc.TJ': 'Tayikistán',
  'cc.TZ': 'Tanzania',
  'cc.TH': 'Tailandia',
  'cc.TL': 'Timor Oriental',
  'cc.TG': 'Togo',
  'cc.TK': 'Tokelau',
  'cc.TO': 'Tonga',
  'cc.TT': 'Trinidad y Tobago',
  'cc.TN': 'Túnez',
  'cc.TR': 'Turquía',
  'cc.TM': 'Turkmenistán',
  'cc.TC': 'Islas Turcas y Caicos',
  'cc.TV': 'Tuvalu',
  'cc.UG': 'Uganda',
  'cc.UA': 'Ucrania',
  'cc.AE': 'Emiratos Árabes Unidos',
  'cc.GB': 'Reino Unido',
  'cc.US': 'Estados Unidos de América',
  'cc.UM': 'Islas Periféricas menores de los EEUU',
  'cc.UY': 'Uruguay',
  'cc.UZ': 'Uzbekistan',
  'cc.VU': 'Vanuatu',
  'cc.VE': 'Venezuela',
  'cc.VN': 'Vietnam',
  'cc.VI': 'Islas Vírgenes de EE.UU',
  'cc.WF': 'Wallis y Futuna',
  'cc.EH': 'Sáhara Occidental',
  'cc.YE': 'Yemen',
  'cc.ZM': 'Zambia',
  'cc.ZW': 'Zimbabue',
  'cc.XK': 'Kosovo',
};
