import { useQuery } from '@tanstack/react-query';

import { hourCache } from '../../constants';
import { requestCreditCard } from '../../queries/apiPayments';

export type CreditCard = {
  data: {
    expiryMonth: string;
    expiryYear: string;
    holderName: string;
    number: string;
  };
  isActive: boolean;
  expired: boolean;
  fakeCard: boolean;
  paymentService: string;
  _id: string;
  hash: string;
  store: string;
  shopperCode: string;
  network: string;
  createdAt: string;
  updatedAt: string;
  __v: number;
  referenceToken: string;
  lastFailDate: string;
};

export type PaymentMethodsQueryResult =
  | {
      list: CreditCard[];
    }
  | undefined;

export const GET_ALL_PAYMENT_METHODS_KEY = 'getAllPaymentMethods';

export function useGetPaymentMethods<T = PaymentMethodsQueryResult>() {
  return useQuery<T>([GET_ALL_PAYMENT_METHODS_KEY], () => requestCreditCard() as T, {
    staleTime: hourCache,
    cacheTime: hourCache,
  });
}
