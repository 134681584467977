import { CheckSquare } from 'icons';
import { FormattedMessage, useIntl } from 'localization';
import { ConfirmationWindow, DialogContainer } from 'ui';
import { Copy } from 'ui/atoms';

type RequestFeatureConfirmInfoModalProps = {
  isOpen: boolean;
  onClose: () => void;
  afterLeave?: () => void;
};

const RequestFeatureConfirmInfoModal = ({
  isOpen,
  onClose,
  afterLeave,
}: RequestFeatureConfirmInfoModalProps) => {
  const { formatMessage } = useIntl();

  return (
    <DialogContainer isOpen={isOpen} handleClose={onClose} afterLeave={afterLeave}>
      <ConfirmationWindow
        title={formatMessage({ id: 'dashboard.thank-you.title' })}
        cancelButtonTitle={formatMessage({ id: 'general.close' })}
        theme="green"
        onCancel={onClose}
        headingIcon={<CheckSquare className="text-2xl" />}
        content={
          <Copy className="text-center">
            <FormattedMessage id="dashboard.thank-you.description" />
          </Copy>
        }
      />
    </DialogContainer>
  );
};

export default RequestFeatureConfirmInfoModal;
