export function BoldTextShortcut({ ...props }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="1em" height="1em" {...props}>
      <g id="Group_10943" data-name="Group 10943" transform="translate(-2.339 -2.375)">
        <g
          id="Rectangle_8501"
          data-name="Rectangle 8501"
          transform="translate(2.339 2.375)"
          fill="#849ead"
          stroke="#707070"
          strokeWidth="1"
          opacity="0"
        >
          <rect width="24" height="24" stroke="none" />
          <rect x="0.5" y="0.5" width="23" height="23" fill="none" />
        </g>
        <path
          id="Path_39080"
          data-name="Path 39080"
          d="M8.858,18.625a1.513,1.513,0,0,1-1.519-1.519V6.894A1.513,1.513,0,0,1,8.858,5.375H12.2a4.418,4.418,0,0,1,2.764.952A3.068,3.068,0,0,1,16.2,8.89a2.923,2.923,0,0,1-.541,1.828,3.516,3.516,0,0,1-1.166,1.026,3.569,3.569,0,0,1,1.469,1.049,3.051,3.051,0,0,1,.7,2.111,3.1,3.1,0,0,1-1.413,2.819,5.251,5.251,0,0,1-2.848.9Zm.631-1.992h2.831a1.949,1.949,0,0,0,1.669-.656,2.129,2.129,0,0,0,.489-1.258,2.129,2.129,0,0,0-.489-1.258,2.008,2.008,0,0,0-1.715-.656H9.488Zm0-5.758h2.594a1.98,1.98,0,0,0,1.445-.521,1.683,1.683,0,0,0,.534-1.248A1.6,1.6,0,0,0,13.5,7.842a2.036,2.036,0,0,0-1.384-.49H9.488Z"
          transform="translate(2.339 2.375)"
          fill="#849ead"
        />
      </g>
    </svg>
  );
}
