import React, { ButtonHTMLAttributes } from 'react';

import { twMerge } from 'tailwind-merge';
import { match } from 'ts-pattern';
import { getColorContrast } from 'ui';
import { Button } from 'ui/atoms';

import { useGetPaletteColors } from '../hooks';
import { getCalculatedBrightnessFromHex } from '../utils';

export interface IOVCButton extends ButtonHTMLAttributes<HTMLButtonElement> {
  className?: string;
  showLoader?: boolean;
  icon?: React.ReactNode;
  theme?: 'primary' | 'secondary' | 'alert';
  disabled?: boolean;
  onClick?: () => void;
  style?: React.CSSProperties;
}

const OVCButton = ({
  theme = 'primary',
  children,
  className,
  disabled,
  showLoader = false,
  icon = null,
  onClick,
  style,
  ...rest
}: IOVCButton) => {
  const { btnBg, secondaryBg, alertColor } = useGetPaletteColors();

  const textColor = getColorContrast(btnBg, false);
  const borderColor = getCalculatedBrightnessFromHex(secondaryBg, 2);

  const styling = match(theme)
    .with('primary', () => ({
      backgroundColor: btnBg,
      color: textColor,
    }))
    .with('secondary', () => ({
      backgroundColor: secondaryBg,
      color: btnBg,
      borderWidth: '1px',
      borderColor: borderColor,
    }))
    .with('alert', () => ({
      backgroundColor: alertColor,
      color: '#FFFFFF',
      borderWidth: '0px',
    }))
    .exhaustive();

  const padding = match<boolean>(true)
    .with(!!children && !icon && !showLoader, () => '6px 20px 6px 20px')
    .with((!!children && !!icon) || showLoader, () => '6px 20px 6px 14px')
    .otherwise(() => '6px 2px 6px 6px');

  return (
    <Button
      disabled={disabled}
      onClick={onClick}
      onClickIcon={onClick}
      icon={
        icon && (
          <div className="flex items-center justify-center w-full" style={{ color: styling.color }}>
            {icon}
          </div>
        )
      }
      loading={showLoader}
      style={{
        ...styling,
        boxShadow: '0 0 #0000',
        padding,
        ...style,
      }}
      className={twMerge('shadow-none', className)}
      {...rest}
    >
      {children}
    </Button>
  );
};

export default OVCButton;
