export function WhatsAppColored({ ...props }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 32 32" {...props}>
      <defs>
        <linearGradient
          id="linear-gradient-w"
          x1="1"
          x2="0"
          y2="1"
          gradientUnits="objectBoundingBox"
        >
          <stop offset="0" stopColor="#59d365" />
          <stop offset="1" stopColor="#26b43d" />
        </linearGradient>
      </defs>
      <g transform="translate(-614 -984)">
        <rect
          width="32"
          height="32"
          rx="10"
          transform="translate(614 1016) rotate(-90)"
          fill="url(#linear-gradient-w)"
        />
        <g transform="translate(-345 869)">
          <rect width="24" height="24" transform="translate(963 119)" fill="none" />
          <path
            d="M13.123,10.786c-.226-.112-1.325-.65-1.53-.724s-.355-.113-.5.112-.578.723-.708.871-.262.158-.484.056a6.087,6.087,0,0,1-1.8-1.114A6.8,6.8,0,0,1,6.849,8.436c-.13-.225-.014-.349.1-.461s.226-.259.338-.392a1.9,1.9,0,0,0,.223-.372.412.412,0,0,0-.019-.393C7.432,6.7,6.985,5.6,6.8,5.163s-.365-.383-.5-.383-.278-.011-.428-.011a.845.845,0,0,0-.6.269,2.5,2.5,0,0,0-.784,1.856A4.388,4.388,0,0,0,5.4,9.2a10,10,0,0,0,3.825,3.364c.535.225.953.36,1.278.472a3.1,3.1,0,0,0,1.41.091,2.308,2.308,0,0,0,1.512-1.07,1.856,1.856,0,0,0,.135-1.069c-.055-.1-.2-.157-.427-.259M9.046,16.313H9.034a7.5,7.5,0,0,1-3.791-1.035l-.27-.161-2.812.731.754-2.734-.179-.281A7.439,7.439,0,0,1,14.32,3.657,7.341,7.341,0,0,1,16.5,8.9,7.448,7.448,0,0,1,9.05,16.313M15.39,2.587A9.106,9.106,0,0,0,9.034,0,8.917,8.917,0,0,0,1.272,13.378L0,18l4.751-1.239a9.047,9.047,0,0,0,4.282,1.085h0A8.955,8.955,0,0,0,18,8.924a8.846,8.846,0,0,0-2.621-6.308"
            transform="translate(966 122)"
            fill="#fff"
            stroke="#fff"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="0.2"
          />
        </g>
      </g>
    </svg>
  );
}
