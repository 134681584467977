export default {
  'general.lang.ca': 'Català',
  'general.lang.de': 'Alemany',
  'general.lang.ee': 'Estoni',
  'general.lang.en': 'Anglès',
  'general.lang.es': 'Espanyol',
  'general.lang.et': 'Estoni',
  'general.lang.fi': 'Finés',
  'general.lang.fr': 'Francès',
  'general.lang.it': 'Italià',
  'general.lang.lt': 'Lituà',
  'general.lang.lv': 'Letó',
  'general.lang.pl': 'Polac',
  'general.lang.pt': 'Portuguès',
  'general.back': 'TORNAR',
  'general.required': 'Requerit',
  'general.PRODUCT_PACKAGE_BROKEN': 'Caixa/Embalatge del producte trencat',
  'general.PRODUCT_BROKEN': 'Producte trencat',
  'general.PRODUCT_USED': 'Producte usat',
  'general.PRODUCT_DIRTY': 'Producte brut',
  'general.MISSING_PRODUCT_LABEL': "No està l'etiqueta del producte",
  'general.PRODUCT_MISSING': 'Producte no es troba dins de la devolució',
  'general.OTHER': 'Altre',
  'general.credit-card': 'Tarjeta de Crèdito',
  'general.bank-transfer': 'Transferència bancaria',
  'general.cash-on-delivery': 'Pagament contra reemborsament',
  'general.continue': 'Continuar',
  'general.CLOSED': 'TANCAT',
  'general.PARTIALLY_RECEIVED': 'PARCIALMENT REBUT',
  'general.RECEIVED': 'REBUT',
  'general.IN_TRANSIT': 'EN TRÀNSIT',
  'general.comments': 'Comentaris',
  'general.return': 'Retornar',
  'general.user-account': "Comtpe d'Usuari",
  'general.sign-out': 'Desconectar-se',
  'general.accept': 'Acceptar',
  'general.was-returned-on': 'Va ser retreat el',
  'general.product-cannot-returned': 'Aquest producte no pot ser retornat',
  'general.product-non-returnable':
    "Aquest producte no pot ser retornat'. Contacta amb atenció al client si vols retornar-lo.",
  'general.can-return-it': 'Puedes retornarlo hasta el',
  'general.created': 'CREAT',
  'general.shipped': 'ENVIAT',
  'general.in-transit': 'EN TRÀNSIT',
  'general.out-of-delivery': 'EN REPART',
  'general.delivered': 'ENTREGAT',
  'general.shipping-charges': "DESPESES D'ENVIAMENT",
  'general.order-details': 'Detall de la comanda',
  'general.return-details': 'Detalls de devolució',
  'general.no-orders-in-transit': 'No tens comandes en trànsit actualment',
  'general.products-returned': 'Productes a retornar',
  'general.select-motive': 'Selecciona un motiu',
  'general.why-want-return': 'Per quà vols retornar aquest producte?',
  'general.do-have-comments': 'Vols afegir un comentari?',
  'general.enter-comment': 'Introdueix el teu comentari',
  'general.do-want-attach-image': 'Vols adjuntar alguna imatge del producte?',
  'general.pickup-address': 'Adreça de recollida',
  'general.date-time-pickup': 'Data I hora de recollida',
  'general.contact-phone-number': 'Telàfon de contacte',
  'general.comments-courier': 'Comentaris pel repartidor',
  'general.confirm-address': "Confirmo l'adreça de recollida:",
  'general.quantity': 'Quantitat',
  'general.length': 'Longitud',
  'general.width': 'Ample',
  'general.height': 'Alçada',
  'general.identifier': 'Identificador',
  'general.tracking-number': 'Número de seguiment',
  'general.date-purchase': 'Data de compra',
  'general.date-time': 'Data I hora',
  'general.status': 'Estat',
  'general.shipping-cost': "Despeses d'enviament per a aquesta devolució",
  'general.free': 'Gratis',
  'general.done': 'Fet',
  'general.spanish': 'Espanyol',
  'general.english': 'Anglès',
  'returns.step.products.title': 'SELECCIÓ DE PRODUCTE',
  'returns.step.products.text': 'Selecciona els productes a retornar',
  'returns.step.method.title': 'MÉTODE DE DEVOLUCIÓ',
  'returns.step.method.text': 'Escull el teu mètode de devolució',
  'returns.step.instructions.title': 'INSTRUCCIONS',
  'returns.step.instructions.text': 'Prepara la teva devolució',
  'returns.step.confirm.title': 'CONFIRMACIÓ',
  'returns.step.confirm.text': 'Devolució completada',
  'general.img-upload': 'Arrossega aquí o fes clic per afegir imatge',
  'retcart.packages.text': `Completa els paquets per fer la devolució
    {lineBreak}{lineBreak}ELS NOSTRES CONSELLS:
    {lineBreak}- Intenta reusar el mateix embalatge on has rebut la comanda. És bo pel medioambient i ja el tens amb tu!
    {lineBreak}- Si retornes més d'un article, intenta usar el menor nombre de paquets.
    {lineBreak}- Recorda, quan fas enviaments, l'aire dins del paquet també s'envia! Si és possible, intenta usar una caixa que minimitzi la quantidad d'espai buit. Això minimitza les despeses de la teva devolució.`,
  'retcart.step2.heading':
    "CONFIRMA EL TAMANY DEL TEU PAQUET (FES CLIC EN ‘AFEGIR PAQUET’ SI NECESSITES MÉS D'UN)",
  'retcart.pack.default-title.display': 'USAR EL PAQUET ENTREGAT:',
  'retcart.pack.custom-title.display': 'USAR PAQUET A MIDA',
  'retcart.pack.custom-title.edit': 'USAR PAQUET A MIDA (en cm)',
  'retcart.pack.change-size': '(Canviar tamany)',
  'retcart.pack.remove': '(Treure)',
  'retcart.pack.add': 'AFEGIR PAQUET',
  'retcart.addrmodal.heading1': 'SELECCIONA UNA DE LES TEVES ADRECES GUARDADES',
  'retcart.addrmodal.heading2': 'O INTRODUEIX UNA NOVA ADREÇA',
  'retcart.print.loading.heading': 'Estem processant la teva devolució...',
  'retcart.print.loading.note':
    'No tanquis aquesta finestra fins que hagi acabat el procés de devolució!',
  'landing.email-sent':
    "Email enviat amb èxit, si us plau ves a la teva safata d'entrada i verifica el teu email en els pròxims 15 minuts.",
  'general.status.IN_TRANSIT': 'EN TRÀNSIT',
  'general.status.DELIVERED': 'ENTREGAT',
  'general.status.DELIVERED_POST_OFFICE': 'ENTREGAT',
  'general.status.DELIVERED_TO_PICKUP_POINT': "ENTREGAT EN{lineBreak}PUNT D'ENTREGA",
  'general.status.DELIVERED_TO_SHOP': 'ENTREGADO EN{lineBreak}TIENDA',
  'general.status.PARTIALLY_DELIVERED': 'PARCIALMENT ENTREGAT',
  'general.status.PARTIALLY_DELIVERED.short': 'ENTREGAT PARC.',
  'general.status.DELAYED': 'ENDARRERIT',
  'general.status.RETURNED': 'RETORNAT',
  'general.status.DELETED': 'ESBORRAT',
  'general.status.RECEIVED': 'REBUT',
  'general.status.PARTIALLY_RECEIVED': 'PARCIALMENT REBUT',
  'general.status.PARTIALLY_RECEIVED.short': 'REBUT PARC.',
  'general.status.CLOSED': 'TANCAT',
  'general.status.CREATED': 'CREAT',
  'general.status.PICKING_QUEUE': 'CUA DE PICKING',
  'general.status.SHIPPING_QUEUE': "CUA D'ENVIAMENTS",
  'general.status.REFUNDED': 'REEMBORSAT',
  'general.status.SHIPMENT_PICKUP': 'PEDIDO ENVIADO',
  'general.status.SHIPPED': 'ENVIAT',
  'general.status.PICKUP': 'ENVIAT',
  'general.status.IN_TRANSIT_DELAYED': 'EN TRÀNSIT ENDARRERIT',
  'general.status.EXCEPTION': 'EXCEPCIÓN',
  'general.status.OUT_FOR_DELIVERY': 'EN REPART',
  'general.status.RETURNED_TO_ORIGIN': 'RETORNAT A ORIGEN',
  'general.status.CANCELLED': 'CANCELAT',
  'sidebar.orders-in-transit': 'COMANDES EN TRÀNSIT',
  'sidebar.my-orders': 'LES MEVES COMANDES',
  'sidebar.returns': 'DEVOLUCIONS',
  'general.courier.gls_ireland': 'GLS Ireland',
  'general.courier.tnt_italy': 'TNT Italy',
  'general.courier.brt': 'BRT',
  'general.courier.bring': 'Bring',
  'general.courier.budbee': 'Budbee',
  'general.courier.cbl': 'CBL',
  'general.courier.correos': 'Correos Express',
  'general.courier.ctt': 'CTT',
  'general.courier.ctt_portugal': 'CTT Portugal',
  'general.courier.chornopost': 'Chronopost',
  'general.courier.deprecated': 'DEPRECATED',
  'general.courier.deutschepost': 'Deutsche Post',
  'general.courier.deutschepost_germany': 'Deutsche Post',
  'general.courier.dhl': 'DHL',
  'general.courier.dhl_sweden': 'DHL',
  'general.courier.dhlparcel': 'DHL Parcel',
  'general.courier.dhlparcel_portugal': 'DHL Parcel',
  'general.courier.dhlparcel_spain': 'DHL Parcel',
  'general.courier.dhlparcel_poland': 'DHL Parcel',
  'general.courier.dhlparcel_unitedkingdom': 'DHL Parcel',
  'general.courier.dhl_paket_germany': 'DHL Paket',
  'general.courier.directa': 'Directa',
  'general.courier.dpd': 'DPD',
  'general.courier.gordon': 'Gordon',
  'general.courier.boostparcel': 'Boost Parcel',
  'general.courier.ronin': 'Ronin',
  'general.courier.trablisa': 'Trablisa Express',
  'general.courier.dpd_france': 'DPD',
  'general.courier.dpd_germany': 'DPD',
  'general.courier.dpd_portugal': 'DPD',
  'general.courier.dpd_portugal_new': 'DPD',
  'general.courier.dpd_romania': 'DPD',
  'general.courier.dpd_poland': 'DPD',
  'general.courier.envialia': 'Envialia',
  'general.courier.external': 'External Courier',
  'general.courier.external-EXCHANGE': 'Exchange',
  'general.courier.fedex': 'FedEx',
  'general.courier.glovo': 'Glovo',
  'general.courier.gls': 'GLS OLD',
  'general.courier.glsnew': 'GLS',
  'general.courier.gls_italy': 'GLS',
  'general.courier.glsshipit': 'GLS ShipIt',
  'general.courier.hermes': 'Hermes',
  'general.courier.inpost': 'InPost',
  'general.courier.inpost_spain': 'InPost',
  'general.courier.internal': 'Internal',
  'general.courier.matkahuolto': 'Matkahuolto',
  'general.courier.mondialrelay': 'Mondial Relay',
  'general.courier.colissimo': 'Colissimo',
  'general.courier.mrw': 'MRW',
  'general.courier.nacex': 'Nacex',
  'general.courier.omniva': 'Omniva',
  'general.courier.outvio': 'Outvio',
  'general.courier.paack': 'Paack',
  'general.courier.parcelforce': 'Parcelforce',
  'general.courier.posti': 'Posti',
  'general.courier.publiccorreos': 'Correos',
  'general.courier.postnord': 'PostNord',
  'general.courier.postnl': 'PostNL',
  'general.courier.relaiscolis': 'Relais Colis',
  'general.courier.royalmail': 'Royal Mail',
  'general.courier.schenker': 'DB Schenker',
  'general.courier.sending': 'SENDING',
  'general.courier.seur': 'SEUR',
  'general.courier.seur_atlas': 'SEUR Atlas',
  'general.courier.shop': 'To Shop',
  'general.courier.smartpost': 'Smartpost',
  'general.courier.spring': 'Spring GDS',
  'general.courier.tipsa': 'TIPSA',
  'general.courier.tnt': 'TNT',
  'general.courier.transaher': 'Transaher',
  'general.courier.ups': 'UPS',
  'general.courier.venipak': 'Venipak',
  'general.courier.zeleris': 'Zeleris',
  'general.courier.zeleriscustom': 'Zeleris',
  'general.courier.zitycity': 'Zitycity',
  'general.trackingbar.datetime': 'Data i Hora',
  'general.trackingbar.location': 'Localització',
  'general.trackingbar.status': 'Estat',
  'general.trackingbar.detail': 'Detall',
  'general.trackingbar.no-info':
    'La informació de seguiment per a aquesta comanda no està encara disponible. Normalment, triga unes hores en actualizar-se. Si us plau, torna més tard.',
  'general.return-error.all-returned': "S'han retirnat tots els productes.",
  'retcart.heading.methods': 'COM VOLS RETORNAR ELS PRODUCTES?',
  'retcart.method.toClientShop': 'PORTA LA DEVOLUCIÓ A UNA DE LES NOSTRES BOTIGUES',
  'retcart.method.outvio': 'USA EL NOSTRE TRANSPORTISTA PER FER LA DEVOLUCIÓ',
  'retcart.method.ownCourier':
    'FES LA DEVOLUCIÓ PEL TEU COMPTE AMB EL TRANSPORTISTA I MÈTODE DE LA TEVA ELECCIÓ',
  'retcart.method.exchange': 'CANVIA O REEMPLAÇA EL(S) PRODUCTE(S) EN COMPTES DE RETORNAR-LOS',
  'retcart.inst.toClientShop.heading':
    'CONFIRMA LA DEVOLUCIÓ I PORTA ELS PRODUCTES A UNA DE LES NOSTRES BOTIGUES',
  'retcart.inst.toClientShop.text1':
    'PER FINALITZAR LA TEVA DEVOLUCIÓ fes Clic al botóDevolució CONFIRMAR:',
  'retcart.inst.toClientShop.text2':
    'Crearem un Full de Devolució amb el que podràs retornar els productes a una de les nostras botigues abans del {dueDate}.',
  'retcart.inst.toClientShop.text3': 'Pots trobar un llistat de les nostres botigues aquí: {link}.',
  'retcart.inst.toClientShop.text3.link': 'llistat de botigues',
  'general.confirm': 'confirmar',
  'retcart.inst.ownCourier.heading':
    'CONFIRMA LA TEVA DEVOLUCIÓ I ENVIA-LA AMB EL TRANSPORTISTA DE LA TEVA ELECCIÓ',
  'retcart.inst.ownCourier.text1': 'PER FINALITZAR LA TEVA DEVOLUCIÓ fes clic al botó CONFIRMAR:',
  'retcart.inst.ownCourier.text2':
    "Crearem un Full de Devolució que hauràs d'incluir dins el paquet de devolució i enviar-nos els productes abans del {dueDate}.",
  'retcart.inst.ownCourier.text3': 'Envia la devolució a:',
  'retcart.inst.ownCourier.text4':
    "PD: Pots usar el transportista que vulguis, i seràs responsable de pagar les despeses d'enviament. Recomanem usar un transportista de confiança amb seguiment d'enviament per a assegurar-te de què l'enviament ens arribi sense problemes.",
  'retcart.inst.exchange.heading': "DIGUE'NS ELS DETALLS DEL CANVI I CONFIRMA",
  'retcart.inst.exchange.text1':
    'Per quin producte, talla/tamany i quantitats vols canviar aquest producte?',
  'retcart.inst.exchange.text2': 'Inclou tots els detalls necessaris al missatge.',
  'retcart.inst.exchange.text.placeholder': 'Escriu els detalls del canvi aquí.',
  'retcart.inst.exchange.text3': `Per a finalitzar el canvi, fes clic en CONFIRMAR.

Hem enviat un email amb la teva petició de canvi al nostro departament d'atenció al cliente i ens posarem en contacte amb tu per a confirmar els detalls del canvi. S'ha enviat també una copia al teu email.`,
  'retcart.confirm-return-terms': 'He revisat la meva devolució i acceptat els {terms}',
  'retcart.confirm-return-terms.terms': 'Termes i Condicions',
  'retcart.confirm.heading': 'DEVOLUCIÓ PROCESSADA',
  'retcart.confirm.text3': 'PER A ASSEGURARTE DE QUÈ TOT VA PERFECTE AMB LA TEVA DEVOLUCIÓ:',
  'retcart.confirm.toClientShop.text4':
    '1. Imprimeix el Full de Devolució i/o guarda la còpia electrònica que enviem per email.',
  'retcart.confirm.toClientShop.text5':
    '2. Porta els productes a una de les nostres botigues abans del {dueDate}.',
  'retcart.confirm.toClientShop.text6': '3. Porta el Full de Devolució a la botiga.',
  'retcart.download-return-sheet': 'DESCARREGAR FULL DE DEVOLUCIÓ',
  'retcart.download-label': "DESCARREGAR FULL DE DEVOLUCIÓ I ETIQUETA D'ENVIAMENT",
  'retcart.confirm.ownCourier.text1':
    "1. Imprimeix el Full de Devolució i fica'l dins del paquet amb als articles de la devolució.",
  'retcart.confirm.ownCourier.text2':
    "2. Envia la devolució amb un transportista de la teva confiança amb servei de seguiment d'enviament, a:",
  'retcart.confirm.ownCourier.text3': "3. Assegura't d'enviar la devolució abans del {dueDate}.",
  'retcart.confirm.outvio.text1':
    "LA TEVA DEVOLUCIÓ S'HA PROCESSAT AMB ÈXIT, DESCARREGA L'ETIQUETA DE DEVOLUCIÓ FENT CLIC AL BOTÓ D'ABAIX",
  'retcart.confirm.outvio.text2': "(l'etiqueta s'ha enviat també per email):",
  'retcart.confirm.outvio.text3':
    'IMPORTANT! PER A QUÈ TOT VAGI BÉ, ABANS DE QUÈ PASSI A RECOLLIR EL TRANSPORTISTA, SI US PLAU:',
  'retcart.confirm.outvio.text4':
    "1. Imprimeix el Full de Devolució i retalla l'etiqueta d'enviament.",
  'retcart.confirm.outvio.text5':
    "2. Introdueix el Full de Devolució dins el paquet, tanca'l i cola l'etiqueta d'enviament al paquet.",
  'retcart.confirm.outvio.text6':
    "3. Ja ho tenim!, relaxa't i espera a què el transportista passi a recollir la devolució dins dels pròxims 2 dies laborables.",
  'retcart.inst.headline.important': 'IMPORTANT:',
  'retcart.inst.hasPickup.noLabel': `1. Revisa la teva adreça i productes a retornar.{lineBreak}
      2. Tras fer clic al botó "CONFIRMAR", es generarà un Full de Devolució.{lineBreak}
      3. Posa els articles a retornar al embalatge i tanca'l. {fillerOptionally} pots afegir dins l'embalatge el Full de Devolucions.{lineBreak}
      4. Un cop facis clic a Confirmar, notificarem al transportista i es posarà en contacte amb tu per acordar la recollida o passarà directament a recollir dins els 2 pròxims dies laborables. IMPORTANT! No has d'imprimir etiqueta d'enviament. El transportista portarà una quan passi a recollir la devolució.`,
  'retcart.inst.hasPickup.noLabel.fillerOptionally': 'Opcionalment',
  'retcart.inst.hasPickup.byEmail': `1. Revisa la teva adreça i productes a retornar.{lineBreak}
      2. Tras fer clic al botó “CONFIRMAR”, es generarà un Full de Devolució i notificarem al transportista per a què passi a recollir. {fillerNotice}.{lineBreak}
      3. Un cop rebis l'etiqueta d'enviament per email, posa els articles a retornar dins la caixa, adjunta el Full de Devolució, tanca la caixa i cola l'etiqueta d'enviament a la caixa.{lineBreak}
      4. Un transportista vindrà a recollir la devolució a l'adreça que has indicat dins els pròxims 2 diss laborables.`,
  'retcart.inst.hasPickup.byEmail.fillerNotice':
    "El transportista t'enviarà l'etiqueta per email a ({email}) dins els pròxims 2 dies laborables.",
  'retcart.inst.hasPickup.provided': `1. Revisa la teva adreça i productes a retornar.{lineBreak}
      2. Tras fer clic al botó “CONFIRMAR”, l'etiqueta de l'enviament s'imprimirà, junt al Full de Devolució, per a cada paquet.{lineBreak}
      3. Posa els articles a retornar dins la caixa/bossa, adjunta el Full de Devolució, tanca la caixa i cola la'etiqueta d'enviament corresponent a la caixa.{lineBreak}
      4. El transportista vindrà a recollir la Devolució a l'adreça que has indicat dins els pròxims 2 dies laborables.`,
  'retcart.inst.noPickup.byEmail': `1. Revisa els productes de la teva devolució{lineBreak}
      2. Tras fer clic al botó “CONFIRMAR”, es generarà un Full de Devolució. {fillerNotice}{lineBreak}
      3. Quan rebis l'etiqueta d'enviament per email, posa els articles a retornar dins la caixa, adjunta el Full de Devolució, tanca la caixa i cola l'etiqueta d'enviament corresponent a la caixa.{lineBreak}
      4. Has de portar la devolució a un punt de conveniència o oficina del transportista. Per trobar el més proper, visita la web del transportista: {fillerCourierHomepage}.`,
  'retcart.inst.noPickup.byEmail.fillerNotice':
    " Rebràs l'etiqueta d'enviament a l'email ({email}) dins els pròxims 2 dies.",
  'retcart.inst.noPickup.byEmail.fillerCourierHomepage': 'aquí',
  'retcart.inst.noPickup.provided': `1. Revisa els productes de la teva devolució{lineBreak}
      2. Tras fer clic al botó “CONFIRMAR”, l'etiqueta de l'enviament es generarè, junt al Full de Devolució per a cada paquet.{lineBreak}
      3. Posa els articles a retornar dins la caixa, recorta l'etiqueta d'enviament, introdueix el Full de Devolució a la caixa, tanca-la i cola l'etiqueta d'enviament corresponent a la caixa.{lineBreak}
      4. Has de portar la Devolució a un punt de conveniència o oficina del transportista. Per trobar el més proper, visita la web del transportista: {fillerCourierHomepage}.`,
  'retcart.inst.noPickup.provided.fillerCourierHomepage': 'aquí',
  'retcart.confirm.outvio.head-sheet':
    "La teva devolució S'HA PROCESSAT AMB ÈXIT, SI US PLAU DESCARREGA EL FULL DE DEVOLUCIÓ FENT CLIC AL BOTÓ D'ABAIX.",
  'retcart.confirm.outvio.head-sheet.note':
    "Si no pots ara, no el preocupis, també te l'hem enviat al teu correu electrònic, per a què les puguis imprimir en qualsevol moment:",
  'retcart.confirm.outvio.head-label':
    "La teva devolució S'HA PROCESSAT AMB ÈXIT, SI US PLAU DESCARREGA EL FULL DE DEVOLUCIÓ (conté l'etiqueta d'enviament) FENT CLIC AL BOTÓ D'ABAIX.",
  'retcart.confirm.outvio.head-label.note':
    "(el Full de Devolució amb l'etiqueta d'enviament s'ha enviat també per email):",
  'retcart.confirm.outvio.head-label-by-email': "LA TEVA DEVOLUCIÓ S'HA PROCESSAT AMB ÈXIT",
  'retcart.confirm.outvio.hasPickup.noLabel.head':
    'IMPORTANT! PER A QUÈ TOT VAGI BÉ, ABANS DE QUÈ PASSI A RECOLLIR EL TRANSPORTISTA, SI US PLAU:',
  'retcart.confirm.outvio.hasPickup.noLabel': `1. Imprimeix el Full de Devolució (opcional).{lineBreak}
      2. Introdueix el Full de Devolució (si has decidit imprimir-ho) dins la caixa i tanca-la. NO HI HA ETIQUETA D'ENVIAMENT!.{lineBreak}
      3. Ja ho tenim. El transportista passarà a recollir el paquet dins els pròxims 2 dies laborables i portarà l'etiqueta d'enviament.`,
  'retcart.confirm.outvio.hasPickup.byEmail.head':
    "PD: REBRÀS L'ETIQUETA D'ENVIAMENT PER EMAIL DINS ELS PRÒXIMS 2 DIES LABORABLES.",
  'retcart.confirm.outvio.hasPickup.byEmail': `1. Un cop rebis l'etiqueta d'enviament per email, imprimeix-la i retalla-la del Full de Devolució.{lineBreak}
      2. Introdueix el Full de Devolució al paquet junt amb els articles a retornar i cola l'etiqueta d'enviament al paquet, previament tancat.{lineBreak}
      3. Ja ho tenim! El transportista passarà a recollir dins els pròxims 2 dies laborables.`,
  'retcart.confirm.outvio.hasPickup.provided': `1. Imprimeix el Full de Devolució i retalla l'etiqueta d'enviament.{lineBreak}
      2. Introdueix el Full de Devolució al paquet, tanca'l i cola l'etiqueta d'enviament al paquet amb els productes a retornar.{lineBreak}
      3. Ja ho tenim! El transportista passarà a recollir dins els pròxims 2 dies laborables.`,
  'retcart.confirm.outvio.noPickup.byEmail': `1. Quan rebis el Full de Devolució per email, imprimeix-ho i retalla l'etiqueta d'enviament.{lineBreak}
      2. Introdueix el Full de Devolució al paquet junt amb els articles a retornar i cola l'etiqueta d'enviament al paquet, previament tancat.{lineBreak}
      3. Porta la Devolució a un punt de conveniència o oficina del transportista. Per trobar el més proper, visita la web del transportista: {filler}.`,
  'retcart.confirm.outvio.noPickup.byEmail.filler': 'aquí',
  'retcart.confirm.outvio.noPickup.provided': `1. Imprimeix el Full de Devolució i retalla l'etiqueta d'enviament.{lineBreak}
      2. Introdueix el Full de Devolució al paquet junt amb els articles a retornar i cola l'etiqueta d'enviament al paquet, previament tancat.{lineBreak}
      3. Porta la Devolució a un punt de conveniència o oficina del transportista. Per trobar el més proper, visita la web del transportista: {filler}.`,
  'retcart.confirm.outvio.noPickup.provided.filler': 'aquí',
  'general.retcart.restart.btn': 'Començar de nou el procés de devolució',
  'general.heading.refund.methods': 'COM VOLS REBRE EL REEMBORSAMENT?',
  'retcart.method.SAME_AS_PAYMENT': 'REEMBORSAR AL MÈTODE DE PAGAMENT ORIGINAL',
  'retcart.method.CREDIT_IN_STORE': 'CRÈDIT DE LA BOTIGA PER A FUTURES COMPRES',
  'retcart.confirm.exchange.heading': 'PETICIÓ DE CANVI COMPLETADA',
  'retcart.confirm.exchange.text1': "LA TEVA PETICIÓ DE CANVI S'HA REBUT CORRECTAMENT.",
  'retcart.confirm.exchange.text2':
    '(Hem enviat una còpia al teu email amb els detalls del canvi).',
  'retcart.confirm.exchange.text3':
    'Ens posarem en contacte amb tu en breu per confirmar els detalls del canvi.',
  'retcart.confirm.exchange.text4': 'GRÀCIES!',
  'general.cancel': 'Cancel·lar',
  'general.retcart.init-restart': 'Si el problema persisteix, comença de nou fent clic AQUÍ',
  'retcart.return-method.toClientShop': 'EN BOTIGA',
  'retcart.return-method.outvio': 'EL NOSTRE TRANSPORTISTA',
  'retcart.return-method.ownCourier': 'TRANSPORTISTA PROPI',
  'retcart.return-method.exchange': 'CANVI / SUBSTITUCIÓ',
  'retcart.return-method.SAME_AS_PAYMENT': 'REEMBORSAMENT',
  'retcart.return-method.CREDIT_IN_STORE': 'CRÈDIT BOTIGA',
  'retcart.return-method.pickup': 'RECOLLIDA',
  'retcart.return-method.dropoff': 'PORTAR A PUNT',
  'general.heading.pickup.methods':
    'VOLS PORTAR EL PAQUET A UN PUNT DE CONVENIÈNCIA O DEMANAR UNA RECOLLIDA?',
  'retcart.method.pickup': 'VULL QUE EL TRANSPORTISTA PASSI A RECOLLIR LA DEVOLUCIÓ',
  'retcart.method.dropoff':
    'PORTARÉ LA DEVOLUCIÓ A UN PUNT DE CONVENIÈNCIA O OFICINA DEL TRANSPORTISTA',
  'retcart.img-attach-desc': "Puja fotos de l'embalatge, producte i etiqueta d'enviament",
  'retcart.confirm.outvio.noPickup.noLabel.head':
    "IMPORTANT! PER A QUÈ TOT VAGI BÉ, ABANS DE PORTAR EL PAQUET AL PUNT D'ENTREGA, SI US PLAU:",
  'retcart.title.pickup': 'amb recollida',
  'retcart.title.dropoff': 'portar a punt',
  'general.tracking.manage-exception': 'Gestionar incidència a la web del transportista',
  'general.error.id-ver-token-missing':
    "Es necessita el Token per autentificació. Comproba l'email d'autentificació enviat.",
  'general.error.id-ver.token-missing':
    "Error a la inicializació. Assegúra't de què el teu navegador està actualizat.",
  'general.error.fileupload.file-too-large': 'Tamany de fitxer màxim {size} MB',
  'general.error.fileupload.too-many-files': 'Número màxim de fitxers {number}',
  'general.error.fileupload.file-invalid-type': 'Tipus de fitxer invàlid',
  'general.error.fileupload.incorrect.size.max':
    'La resolució de la imatge ha de ser com a màxim {width} per {height}',
};
