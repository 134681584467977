export function ExchangeV2({ ...props }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="1em" height="1em" {...props}>
      <g transform="translate(-152 -691)">
        <rect
          id="Rectangle_6411"
          data-name="Rectangle 6411"
          width="24"
          height="24"
          transform="translate(152 691)"
          fill="none"
        />
        <path
          id="Path_38810"
          d="M168.5,707.4a4.351,4.351,0,0,0,.6-.7,5.583,5.583,0,0,0,.5-.8,2.181,2.181,0,0,1,.4-.5,1.422,1.422,0,0,1,.6-.2.764.764,0,0,1,.5.2.44.44,0,0,1,.1.5,5.387,5.387,0,0,1-.7,1.3l-.9,1.2a7.726,7.726,0,0,1-11,0l-.6-.6v1.9a.684.684,0,0,1-.7.7.684.684,0,0,1-.7-.7v-3.5a.9.9,0,0,1,.9-.9H161a.684.684,0,0,1,.7.7.684.684,0,0,1-.7.7h-2l.6.6a5.813,5.813,0,0,0,4.4,1.8A5.468,5.468,0,0,0,168.5,707.4Zm-9-8.8a4.351,4.351,0,0,0-.6.7,5.583,5.583,0,0,0-.5.8,2.181,2.181,0,0,1-.4.5,1.422,1.422,0,0,1-.6.2.764.764,0,0,1-.5-.2.44.44,0,0,1-.1-.5,5.387,5.387,0,0,1,.7-1.3l.9-1.2a7.726,7.726,0,0,1,11,0l.6.6v-1.9a.684.684,0,0,1,.7-.7.684.684,0,0,1,.7.7v3.5a.9.9,0,0,1-.9.9H167a.684.684,0,0,1-.7-.7.684.684,0,0,1,.7-.7h1.9l-.6-.6a5.813,5.813,0,0,0-4.4-1.8A5.385,5.385,0,0,0,159.5,698.6Z"
          transform="translate(-0.023)"
          fill="currentColor"
        />
      </g>
    </svg>
  );
}
