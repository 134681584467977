import React, { FC } from 'react';

import HelpIcon from '@material-ui/icons/Help';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';

interface CustomTooltip {
  text: string;
}

const NoMaxWidthTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 'none',
    backgroundColor: '#314955',
    color: 'white',
    padding: '16px 24px',
    borderRadius: '8px',
    fontSize: '12px',
  },
});

const CustomTooltip: FC<CustomTooltip> = ({ text }) => {
  return (
    <NoMaxWidthTooltip title={text || ''} placement="right">
      <div className="flex min-w-[40px] ml-[10px] mr-[10px] text-xl opacity-20 text-black cursor-pointer">
        <HelpIcon color="inherit" fontSize="inherit" />
      </div>
    </NoMaxWidthTooltip>
  );
};

export default CustomTooltip;
