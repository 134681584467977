import { FC, FunctionComponent, ReactElement, ReactNode, memo } from 'react';

import { FormattedMessage } from 'localization';

import {
  NavbarItem as DefaultNavItem,
  NavbarLogo as DefaultNavbarLogo,
  NavbarDelimiter,
} from '../../atoms/Navbar';
import { CustomTooltipProps } from '../../molecules/Tooltip/Tooltip';
import { NavbarMenuItemData } from '../../utils';

interface NavbarProps {
  menu: NavbarMenuItemData[];
  secondaryMenu: NavbarMenuItemData[];
  logo?: ReactNode;
  NavItem?: FunctionComponent<NavbarMenuItemData>;
  Tooltip?: FunctionComponent<CustomTooltipProps>;
}

const ConditionalWrapper: FC<{
  condition: boolean;
  wrapper(wrapperChildren: ReactElement): ReactElement;
  children: ReactElement;
}> = ({ condition, wrapper, children }) => {
  if (condition) {
    return wrapper(children);
  }
  return children;
};

function NavbarBase({ menu, secondaryMenu, logo, NavItem = DefaultNavItem, Tooltip }: NavbarProps) {
  return (
    <div className="flex flex-col w-17 bg-v2blueGray-800">
      {logo ? logo : <DefaultNavbarLogo />}
      <NavbarDelimiter />
      <nav>
        <div className="flex flex-col justify-center gap-4 py-3">
          {menu.map((item) => (
            <ConditionalWrapper
              key={item.id}
              condition={!!Tooltip}
              wrapper={(children) => (
                // @ts-ignore
                <Tooltip title={<FormattedMessage id={item.langKey} />} placement="right">
                  {children}
                </Tooltip>
              )}
            >
              <NavItem {...item} />
            </ConditionalWrapper>
          ))}
        </div>
        {secondaryMenu?.length > 0 ? (
          <>
            <NavbarDelimiter />
            <div className="flex flex-col justify-center gap-4 py-3">
              {secondaryMenu.map((item) => (
                <ConditionalWrapper
                  key={item.id}
                  condition={!!Tooltip}
                  wrapper={(children) => (
                    // @ts-ignore
                    <Tooltip title={<FormattedMessage id={item.langKey} />} placement="right">
                      {children}
                    </Tooltip>
                  )}
                >
                  <NavItem {...item} />
                </ConditionalWrapper>
              ))}
            </div>
          </>
        ) : null}
      </nav>
    </div>
  );
}

export const Navbar = memo(NavbarBase);
