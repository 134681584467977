import { type HTMLProps } from 'react';

import { ExclamationRounded } from 'icons';
import { twMerge } from 'tailwind-merge';
import { Copy } from 'ui/atoms';

export interface _ErrorMessageProps {
  className?: string;
}

export type ErrorMessageProps = HTMLProps<HTMLDivElement> & _ErrorMessageProps;

export function ErrorMessage({ className, children }: ErrorMessageProps) {
  return (
    <div
      data-testid="outvioui--errormessage"
      className={twMerge('flex items-center ml-[6px] mt-1 text-v2red', className)}
    >
      <ExclamationRounded width="16" height="16" className="self-start mt-[0.05em]" />
      <Copy as="span" className="ml-1 text-v2red" type="copy2">
        {children}
      </Copy>
    </div>
  );
}

export default ErrorMessage;
