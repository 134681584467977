export default {
  FALLBACK_ERROR_MESSAGE: 'Algo ha fallado. Por favor prueba de nuevo pasados unos minutos.',
  COURIER_ERROR_FALLBACK: 'Error del transportista',
  TOKEN_NOT_FOUND: 'Token no encontrado.',
  IDENTITY_VERIFICATION_FAILED: 'No se ha podido completar la verificación de identidad.',
  ACCOUNT_BLOCKED: 'Tu cuenta está bloqueada. Por favor comprueba tu email.',
  WRONG_EMAIL_OR_PASS: 'El email y/o la contraseña son incorrectos.',
  INVALID_CREDENTIALS: 'Credenciales inválidas.',
  INVALID_TOKEN:
    'Sesión expirada, por favor pide un nuevo link de validación en el portal de devoluciones',
  MALFORMED_TOKEN: 'Token malformado.',
  CUSTOMER_ALREADY_CREATED:
    'La cuenta de cliente ya existe. Por favor, accede a la misma mediante login',
  SHIPPING_METHOD_NOT_SUPPORTED: 'Método de envío no soportado.',
  SHIPMENT_ALREADY_PICKED_UP: 'Pedido ya recogido.',
  NO_RATE_FOR_DESTINATION: 'No hay tarifa disponible para este destino.',
  BUILDING_LABEL_FAILED: 'La creación de la etiqueta ha fallado.',
  COURIER_NOT_SUPPORTED: 'Transportista no soportado.',
  PICKUP_REQUEST_FAILED: 'La llamada de recogida ha fallado.',
  ERROR_TRY_AGAIN_LATER: 'Algo ha fallado. Prueba de nuevo en unos instantes.',
  EMAIL_ALREADY_REGISTERED: 'Email ya registrado.',
  INVALID_PASSWORD: 'Contraseña inválida.',
  LABEL_ERROR: 'Error de etiqueta error.',
  UNDER_MAINTENANCE: 'Estamos en mantenimiento. Outvio volverá a estar disponible en unos minutos.',
  MERGING_PDFS_FAILED: 'La creación del PDF ha fallado.',
  EXPIRE_TOKEN: 'Token ha expirado',
  GENERIC_MISSING_PARAM: 'Faltan parámetros requeridos.',
  ORDER_NOT_FOUND: 'Pedido no encontrado.',
};
