export function Return({ ...props }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="currentColor"
      {...props}
    >
      <g transform="translate(-865 -172)">
        <path
          d="M2173,646a46.415,46.415,0,0,0-3.63-4.362,9,9,0,1,0,2.28,8.862M2173,646v-6m0,6h-6"
          transform="translate(-1286 -464)"
          fill="none"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
      </g>
    </svg>
  );
}
