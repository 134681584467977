import { gql } from '@apollo/client';

export const RETURNS_GET_ONE_BY_ID_PRODUCTS = gql`
  query Returns_GetOneById_Products($input: IdInput!) {
    Returns_GetOne(input: $input) {
      courier {
        integration
        type
      }
      createdAt
      products {
        action
        sku
        exchangeData
        attachments
        currency
        totalValue
        reason
        pictureUrl
        name
        variant
        details
        quantity
      }
      refundMethod
      returnMethod
      paymentDetails {
        shippingValue
        returnValue
        toBePaid
        toBeReturned
      }
      id
      status
    }
  }
`;

export const RETURNS_GET_MANUAL_APPROVE_BY_ID = gql`
  query Returns_GetManualApprove($returnId: ID!) {
    Returns_GetManualApprove(returnId: $returnId) {
      courier {
        integration
        type
      }
      paymentDetails {
        toBeReturned
        returnValue
        shippingValue
        toBePaid
      }
      createdAt
      products {
        manualApproveStatus
        _id
        currency
        details
        name
        pictureUrl
        price
        reason
        variant
        orderIdentifier
      }
      refundMethod
      returnMethod
      manualApproveStatus
    }
  }
`;
export const RETURNS_CALCULATE_REVENUE_ANALYTICS = gql`
  query Returns_CalculateRevenueAnalytics($from: String!, $to: String!) {
    Returns_CalculateRevenueAnalytics(from: $from, to: $to) {
      retainedRevenue
      additionalRevenue
      totalRevenue
      storeCredits
    }
  }
`;
