export default {
  'ovc.general.powered-by': 'Powered by',
  'ovc.auth.identity.email.placeholder': 'Email',
  'ovc.auth.identity.cta': '¡Empieza una devolución!',
  'ovc.auth.order.orderNumber.placeholder': 'Número de pedido o seguimiento del transportista',
  'ovc.auth.order.clientInfo.placeholder': 'Código postal o 5 últimos dígitos del teléfono',
  'ovc.auth.switch-to-identity': 'Acceder mediante email',
  'ovc.auth.switch-to-order': 'Acceder con información del pedido',
  'ovc.auth.tracking.cta': 'LOCALIZA MI PEDIDO',
  'ovc.auth.tracking.orderIdentifier.placeholder': 'ID de pedido, OTN o número de seguimiento',
  'ovc.auth.tracking.postcode.placeholder': 'Código postal de destino',
  'ovc.listing.page.all': 'Todos los pedidos',
  'ovc.listing.page.in-transit': 'Pedidos en tránsito',
  'ovc.listing.page.returns': 'Devoluciones',
  'ovc.listing.header.order': 'pedido',
  'ovc.listing.header.date': 'fecha',
  'ovc.listing.header.total': 'total',
  'ovc.listing.header.choose-product': 'Elige producto:',
  'ovc.listing.listing-track-cta': 'localiza',
  'ovc.listing.listing-return-cta': 'devolver',
  'ovc.listing.listing-external-action-cta': 'pide reparación en garantía',
  'ovc.listing.empty-list': 'No hay pedidos que mostrar',
  'ovc.listing.return-status.store-non-returnable': 'No se puede devolver',
  'ovc.listing.return-status.already-returned': 'Devuelto {date}',
  'ovc.listing.return-status.no-longer-returnable': 'No se puede devolver',
  'ovc.listing.return-status.returnable': 'Devuelve hasta el {date}',
  'ovc.return-cart.refund-method-credit': `Elige esta opción si quieres usar el valor de esta devolución para futuras compras {lineBreak}
  {lineBreak}
  Te enviaremos un código de descuento o tarjeta regalo por el valor correspondiente.`,
  'ovc.return-cart.inst.outvio.heading.confirm-pickup':
    'confirma la dirección de recogida y devuelve',
  'ovc.return-cart.inst.outvio.heading.confirm-return': 'confirma tu devolución',
  'ovc.return-cart.address.heading': 'introduce una nueva dirección o selecciona una existente:',
  'ovc.return-cart.socket-error':
    'Error del sistema del transportista: {error}. Por favor, inténtalo de nuevo más tarde',
};
