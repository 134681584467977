import {
  DisplayStep,
  ICoordinates,
  IDeliveryInfoData,
  IMarker,
  IOrderProduct,
  ITrackingData,
  OutvioStatusCodes,
  SourceStep,
  SourceSteps,
  SourceTrackingData,
  StepStatus,
  incidentStatusSteps,
} from '../Interfaces';
import { numberWithCurrency } from './generalTracking';
import getDisplaySteps from './getDisplaySteps';

const testMarkers = [
  {
    lat: 59.41371289999999,
    lng: 24.725664,
    isShipmentHere: false,
  },
  {
    lat: 52.2296756,
    lng: 21.0122287,
    isShipmentHere: false,
  },
  {
    lat: 41.9027835,
    lng: 12.4963655,
    isShipmentHere: true,
  },
  {
    lat: 38.7222524,
    lng: -9.1393366,
    isShipmentHere: false,
  },
];

const getCanUserCancelOrder = (steps: SourceStep[]): boolean => {
  return steps.findIndex(({ code }) => code === OutvioStatusCodes.IN_TRANSIT) === -1;
};

const getDeliveryInfo = (data: SourceTrackingData): IDeliveryInfoData => {
  const {
    courier,
    destinationAddress: {
      address: street,
      city,
      country,
      comment,
      postcode,
      name: fullName,
      phone,
      email,
    },
  } = data;
  return {
    contact: {
      fullName,
      email,
      phone,
    },
    address: {
      city,
      country,
      street,
      postcode,
    },
    courier,
    canUserEdit: data.canEditDeliveryAddress,
    comment,
  };
};

const getMapMarkers = async (steps: DisplayStep[]): Promise<IMarker[]> => {
  const locations: { coordinates?: ICoordinates }[] = [];
  let lastPosition: ICoordinates | null = null;
  [...steps].map((step) => {
    // console.log(`step #${index}`, step);
    if (
      !step.coordinates ||
      (!step.isSubStep && step.displayCode !== OutvioStatusCodes.DELIVERED)
    ) {
      return null;
    }
    if (step.status === StepStatus.DONE) {
      lastPosition = step.coordinates;
    }
    locations.push({ coordinates: step.coordinates });
    return null;
  });

  const sortedLoc = locations.filter((loc, i) => {
    return (
      loc.coordinates?.lat !== locations[i + 1]?.coordinates?.lat &&
      loc.coordinates?.lng !== locations[i + 1]?.coordinates?.lng
    );
  });

  if (steps.some((step) => step.displayCode === OutvioStatusCodes.DELIVERED_TO_SHOP)) {
    const delshopStep = steps.find(
      (step) => step.displayCode === OutvioStatusCodes.DELIVERED_TO_SHOP,
    );
    return [
      {
        lat: delshopStep?.coordinates?.lat || 0,
        lng: delshopStep?.coordinates?.lng || 0,
        isShipmentHere: false,
      },
    ];
  }

  return sortedLoc.map((item) => ({
    lat: item?.coordinates?.lat || 0,
    lng: item?.coordinates?.lng || 0,
    isShipmentHere: !!(
      lastPosition && JSON.stringify(item?.coordinates) === JSON.stringify(lastPosition)
    ),
  }));
};

const getCurrentStep = (steps: DisplayStep[]): DisplayStep =>
  steps.find((step) => step.isLastDoneStep) || steps[2];

const getProducts = (data: SourceTrackingData): IOrderProduct[] => {
  const products: IOrderProduct[] = [];
  data.products.map(({ amount, price, name, imgSrc, variant, discountPrice }) => {
    const foundProductIndex = products.findIndex(
      (prod) => prod.name === name && prod.variant === variant,
    );
    const newProduct: IOrderProduct = {
      amount,
      imgSrc,
      variant,
      name,
      price: numberWithCurrency({ n: price, currency: data.currency }),
      discountPrice:
        discountPrice !== null && discountPrice !== undefined
          ? numberWithCurrency({ n: discountPrice, currency: data.currency })
          : null,
    };
    if (foundProductIndex === -1) {
      products.push(newProduct);
    } else {
      products[foundProductIndex].amount += amount;
    }
    return null;
  });
  return products;
};

const getIsIncident = (steps: SourceStep[]): boolean =>
  steps.length ? incidentStatusSteps.includes(steps[steps.length - 1].code) : false;

const cacheTrackingLink = new Map<string, string | null>();
const getTrackingLink = (otn: string) => {
  if (cacheTrackingLink.has(otn)) return Promise.resolve(cacheTrackingLink.get(otn) || null);

  return fetch(`${import.meta.env.VITE_OUTVIO_API_URL}/courier/public-tracking-link/${otn}`)
    .then((res) => {
      if (res.ok) return res.json();
      return null;
    })
    .then((res: { success: true; data?: string } | null) => {
      cacheTrackingLink.set(otn, res?.data || null);
      return res?.data || null;
    })
    .catch(() => null);
};

export const trackingToV2 = (
  data: SourceTrackingData,
  sourceSteps: SourceSteps,
  isTestData = false,
): ITrackingData | undefined => {
  const { steps = [] } = sourceSteps;
  try {
    let displaySteps = getDisplaySteps(sourceSteps, data);
    if (displaySteps.some((step) => step.displayCode === OutvioStatusCodes.DELIVERED_TO_SHOP)) {
      displaySteps = displaySteps
        .filter((step) =>
          [
            OutvioStatusCodes.RECEIVED,
            OutvioStatusCodes.PROCESSING,
            OutvioStatusCodes.DELIVERED_TO_SHOP,
            OutvioStatusCodes.DELIVERED,
          ].includes(step.code),
        )
        ?.map?.((_, index) => {
          if (index === 0) return { ..._, isFirst: true } as DisplayStep;
          return _;
        });
    }
    return {
      deliveryInfo: getDeliveryInfo(data),
      orderStatusInfo: {
        currentStep: getCurrentStep(displaySteps),
        steps: displaySteps.map((step) => {
          if (step.code === OutvioStatusCodes.CREATED) {
            return {
              ...step,
              dateTime: data.formattedTimes?.processDate || step.dateTime,
            } as DisplayStep;
          }
          return step;
        }),
        isIncident: getIsIncident(steps),
        incidentUrl: undefined,
      },
      map: {
        markers: isTestData ? Promise.resolve(testMarkers) : getMapMarkers(displaySteps),
      },
      orderInfo: {
        vat: numberWithCurrency({ n: data.vat, currency: data.currency }),
        otn: data.otn,
        orderTotal: numberWithCurrency({ n: data.total, currency: data.currency }),
        canUserCancel: getCanUserCancelOrder(steps),
        products: getProducts(data),
        refund: '', // TODO: V2
        trackingNumber: data.trackingNumber || '',
        secondaryTrackingNumber: data?.secondaryTrackingNumber || '',
        shippingPrice: numberWithCurrency({ n: data.shippingPrice, currency: data.currency }),
        originalId: data.originalId,
        id: data.id,
        method: data.method,
      },
      cmsInfo: data.cmsInfo,
      timeZone: data.timeZone,
      rating: data.rating?.rating,
      otn: data.otn,
      trackingLink: isTestData ? Promise.resolve(null) : getTrackingLink(data.otn),
      shouldDisplayMap: data.shouldDisplayMap,
      estimatedDeliverDate: data.estimatedDeliverDate,
      recommendedProducts: data.recommendedProducts,
    };
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e);
    return undefined;
  }
};
