export function CheckSquare({ ...props }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="currentColor"
      {...props}
    >
      <g
        id="bar_chart_black_24dp_1_"
        data-name="bar_chart_black_24dp (1)"
        transform="translate(-0.672)"
      >
        <path
          id="Path_16320"
          data-name="Path 16320"
          d="M0,0H24V24H0Z"
          transform="translate(0.672)"
          fill="none"
        />
        <path
          id="Path_41691"
          data-name="Path 41691"
          d="M73,1247l3,3,10-10m-6-1H71.8a7.39,7.39,0,0,0-3.162.33,2.931,2.931,0,0,0-1.311,1.31A7.438,7.438,0,0,0,67,1243.8v8.4a7.438,7.438,0,0,0,.327,3.16,2.931,2.931,0,0,0,1.311,1.31,7.39,7.39,0,0,0,3.162.33h8.4a7.39,7.39,0,0,0,3.162-.33,2.931,2.931,0,0,0,1.311-1.31A7.438,7.438,0,0,0,85,1252.2V1248"
          transform="translate(-63.328 -1236)"
          fill="none"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
      </g>
    </svg>
  );
}
