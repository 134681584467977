// @ts-nocheck
import { LocalePage, SupportedLanguage } from '../types/CustomerGeneral';
import account from './account';
import apiErrors from './apiErrors';
import countries from './countries';
import general from './general';
import customerLanding from './landing';
import customerOrder from './order';
import ovc from './ovc';

const messages: Record<LocalePage, Record<SupportedLanguage, Record<string, string>>> = {
  customerOrder,
  customerLanding,
  account,
  general,
  countries,
  apiErrors,
  ovc,
};

export default messages;
