import moment from 'moment/moment';

import {
  CategorizedStep,
  DisplayStatusCodes,
  DisplayStep,
  OrderStep,
  OutvioStatusCodes,
  SPECIFIC_SUBSTEPS,
  SourceDestinationAddress,
  SourceStep,
  SourceSteps,
  SourceTrackingData,
  StepStatus,
  displayStatus,
  incidentStatusSteps,
} from '../Interfaces';
import { toDisplayDate } from './generalTracking';

const sortStepsByDate = (steps: SourceStep[]): SourceStep[] => {
  if (steps.findIndex(({ date }) => !moment(date, 'DD/MM/YYYY hh:mm').isValid()) !== -1) {
    return steps;
  }
  return steps.sort(
    (a, b) => moment(a.date, 'DD/MM/YYYY hh:mm').unix() - moment(b.date, 'DD/MM/YYYY hh:mm').unix(),
  );
};

const getInitialCategorizedSteps = (trackingData: SourceTrackingData): CategorizedStep[] => {
  const isInProcessing = !!trackingData?.formattedTimes?.pickingDate;
  return [
    {
      displayCode: OutvioStatusCodes.RECEIVED,
      steps: [
        {
          displayCode: OutvioStatusCodes.RECEIVED,
          status: StepStatus.DONE,
          code: OutvioStatusCodes.RECEIVED,
          dateTime:
            trackingData?.formattedTimes?.dateTime ||
            toDisplayDate(
              trackingData.dateTime || trackingData.createdAt, // Backup in case formatted dateTime is not present
              true,
              trackingData?.cmsInfo?.zoneOffset && trackingData?.cmsInfo?.zoneOffset !== ''
                ? trackingData?.cmsInfo?.zoneOffset
                : trackingData?.timeZone,
            ),
          coordinates: trackingData.originAddress.coordinates,
        },
      ],
    },
    {
      displayCode: OutvioStatusCodes.PROCESSING,
      steps: [
        {
          displayCode: OutvioStatusCodes.PROCESSING,
          status: isInProcessing ? StepStatus.DONE : StepStatus.PENDING,
          code: OutvioStatusCodes.PROCESSING,
          dateTime:
            trackingData?.formattedTimes?.pickingDate ||
            toDisplayDate(
              trackingData?.pickingDate || trackingData.processDate, // Backup in case formatted dateTime is not present
              true,
              trackingData?.cmsInfo?.zoneOffset && trackingData?.cmsInfo?.zoneOffset !== ''
                ? trackingData?.cmsInfo?.zoneOffset
                : trackingData?.timeZone,
            ),
        },
      ],
    },
  ];
};

const getCodeBelongsToDisplayStatusCodes = (code: OutvioStatusCodes): DisplayStatusCodes[] => {
  const foundCode = displayStatus.find((c) => (code as DisplayStatusCodes) === c);
  if (foundCode) return [foundCode];
  const specificSubSteps = SPECIFIC_SUBSTEPS[code];
  if (specificSubSteps) return specificSubSteps;
  return displayStatus;
};

const getStatusFromCode = (code: OutvioStatusCodes): StepStatus => {
  if (incidentStatusSteps.includes(code)) return StepStatus.INCIDENT;
  return StepStatus.DONE;
};

// Salvatore DS-5605 Commented, not used
// const populateDisplayDeliveryToShop = (
//   sourceSteps: SourceStep[],
//   trackingData: SourceTrackingData
// ): SourceStep[] => {
//   if (!sourceSteps?.some(({ code }) => code === OutvioStatusCodes.DELIVERED_TO_SHOP))
//     return sourceSteps;
//   return sourceSteps?.map((step) => {
//     if (step.code === OutvioStatusCodes.DELIVERED_TO_SHOP) {
//       return {
//         ...step,
//         description: `${trackingData}`,
//       };
//     }
//     return step;
//   });
// };

const sourceToCategorizedSteps = (
  sourceSteps: SourceStep[],
  trackingData: SourceTrackingData,
): CategorizedStep[] => {
  /**
   * Populate the initial steps that are never in the Source steps.
   */
  const categorizedSteps: CategorizedStep[] = [...getInitialCategorizedSteps(trackingData)];
  let currentDisplayCode: DisplayStatusCodes = OutvioStatusCodes.RECEIVED;
  let i = 1;
  sourceSteps.map((sourceStep) => {
    const { code, date, description, coordinates } = sourceStep;
    const belongsToDisplayCodes = getCodeBelongsToDisplayStatusCodes(code);
    /**
     * If we must add a new category.
     */
    if (!belongsToDisplayCodes.includes(currentDisplayCode)) {
      currentDisplayCode = belongsToDisplayCodes.reduce((prev, curr) => {
        if (categorizedSteps.find(({ displayCode }) => displayCode === prev)) return curr;
        return prev;
      });
      i += 1;
      categorizedSteps.push({ steps: [], displayCode: currentDisplayCode });
    }

    // In case of DELIVERED_TO_SHOP, we need to populate the description with the shop location.
    categorizedSteps[i].steps.push(
      code === OutvioStatusCodes.DELIVERED_TO_SHOP
        ? {
            status: getStatusFromCode(code),
            code,
            displayCode: currentDisplayCode,
            dateTime: toDisplayDate(date),
            description: `${sourceStep.location || ''}`,
            coordinates,
          }
        : {
            status: getStatusFromCode(code),
            code,
            displayCode: currentDisplayCode,
            dateTime: toDisplayDate(date),
            description,
            coordinates,
          },
    );
    return null;
  });
  return categorizedSteps;
};

const getCurrentStatus = (sourceStatus: StepStatus, shouldBacktrack: boolean): StepStatus => {
  if (!shouldBacktrack) return sourceStatus;
  switch (sourceStatus) {
    case StepStatus.INCIDENT:
      return StepStatus.BACKTRACK_INCIDENT;
    case StepStatus.DONE:
      return StepStatus.BACKTRACK;
    default:
      return StepStatus.PENDING;
  }
};

/**
 * NB: Doesn't populate nextStatus, prevStatus, isFirst, isLastDoneStep
 * They default to false or undefined
 */
const getStepToDisplay = (step: CategorizedStep, shouldBacktrack: boolean): DisplayStep[] => {
  const displaySteps: DisplayStep[] = [];
  const lastStep = step.steps[step.steps.length - 1];
  const parentStep: DisplayStep = {
    status: getCurrentStatus(lastStep.status, shouldBacktrack),
    isLast: false,
    isFirst: false,
    isLastDoneStep: false,
    isSubStep: false,
    code: lastStep.code,
    displayCode: lastStep.displayCode,
    dateTime: lastStep.dateTime,
    description: lastStep.description,
    coordinates: lastStep.coordinates,
  };
  displaySteps.push(parentStep);
  // console.log(step);
  step.steps.map(({ displayCode, coordinates, code, description, status, dateTime }) => {
    displaySteps.push({
      status: getCurrentStatus(status, shouldBacktrack),
      description,
      displayCode,
      code,
      dateTime,
      coordinates,
      isSubStep: true,
      isLastDoneStep: false,
      isLast: false,
      isFirst: false,
    });
    return null;
  });
  return displaySteps;
};

const populateDisplayStepsMissingValues = (
  sourceSteps: DisplayStep[],
  to?: SourceDestinationAddress,
): DisplayStep[] => {
  let currentParentStep: DisplayStep = {
    ...sourceSteps[0],
    isFirst: true,
    nextStatus: sourceSteps[1].status === StepStatus.DONE ? StepStatus.DONE : StepStatus.PENDING,
  };
  return sourceSteps.map((step, i) => {
    if (i === 0) return currentParentStep;
    if (!step.isSubStep) {
      const nextParentStep = sourceSteps.find(({ isSubStep }, x) => !isSubStep && x > i);
      currentParentStep = {
        ...step,
        isFirst: false,
        prevStatus: currentParentStep.status,
        nextStatus: nextParentStep?.status,
        isLast: step.displayCode === OutvioStatusCodes.DELIVERED,
        ...(step.displayCode === OutvioStatusCodes.DELIVERED && { coordinates: to?.coordinates }),
        isLastDoneStep:
          [StepStatus.DONE, StepStatus.INCIDENT].includes(step.status) &&
          (nextParentStep?.status === StepStatus.PENDING ||
            nextParentStep?.status === StepStatus.BACKTRACK ||
            nextParentStep?.status === StepStatus.BACKTRACK_INCIDENT ||
            !nextParentStep?.status),
      };

      return currentParentStep;
    }

    return {
      ...step,
      ...(step.displayCode === OutvioStatusCodes.DELIVERED && { coordinates: to?.coordinates }),
      parent: {
        status: currentParentStep.status,
        prevStatus: currentParentStep.prevStatus,
        nextStatus: currentParentStep.nextStatus,
      },
    };
  });
};

/**
 * This doesn't update Backtrack Incidents.
 * This will actually take an incident and update the status
 * to Past incident based on backtrack.
 */
const updateIncidents = (steps: DisplayStep[]): DisplayStep[] => {
  let shouldPastIncident = false;
  return steps
    .reverse()
    .map((step) => {
      if (step.status !== StepStatus.INCIDENT) {
        if (
          ![StepStatus.PENDING, StepStatus.BACKTRACK, StepStatus.BACKTRACK_INCIDENT].includes(
            step.status,
          )
        ) {
          shouldPastIncident = true;
        }
        return step;
      }
      if (step.isSubStep) {
        const newStep = {
          ...step,
          status: shouldPastIncident ? StepStatus.PAST_INCIDENT : StepStatus.INCIDENT,
        };
        shouldPastIncident = true;
        return newStep;
      }
      return {
        ...step,
        status: step.nextStatus !== StepStatus.DONE ? StepStatus.INCIDENT : StepStatus.DONE,
      };
    })
    .reverse();
};

const populateDates = (steps: DisplayStep[]): DisplayStep[] => {
  let lastDate = '';
  return steps.map((step) => {
    if (step.dateTime) lastDate = step.dateTime;
    return {
      ...step,
      dateTime: step.status !== StepStatus.PENDING ? lastDate : '',
    };
  });
};

const addCREAStepIfPrintedLabel = (steps: SourceStep[], trackingData: SourceTrackingData) => {
  if (trackingData?.formattedTimes?.processDate && steps?.length === 0) {
    steps.push({
      status: StepStatus.DONE,
      code: OutvioStatusCodes.CREATED,
      date: trackingData?.formattedTimes?.processDate,
      courier: trackingData?.courier || '',
    });
  }
  return steps;
};

const getDisplayStepsFromCategorizedSteps = (
  categorizedSteps: CategorizedStep[],
  to?: SourceDestinationAddress,
): DisplayStep[] => {
  /**
   * Sort duplicated categorized steps
   * EX: IT, OFD, IT should be IT[], OFD
   */
  const sortedCategorizedSteps: CategorizedStep[] = [];
  displayStatus.map((code) => {
    const allStatusSteps: OrderStep[] = [];
    if (
      code === OutvioStatusCodes.HAND_OVER ||
      code === OutvioStatusCodes.DELIVERED_TO_PICKUP_POINT ||
      code === OutvioStatusCodes.DELIVERED_TO_SHOP
    ) {
      if (!categorizedSteps.find(({ displayCode }) => code === displayCode)) return null;
    }
    categorizedSteps.map(({ displayCode, steps }) => {
      if (displayCode === code) allStatusSteps.push(...steps);
      return null;
    });
    sortedCategorizedSteps.push({ displayCode: code, steps: allStatusSteps });
    return null;
  });
  /**
   * Finally convert to display steps
   */
  const currentDisplayCode = categorizedSteps[categorizedSteps.length - 1].displayCode;
  const displayStepsFirstIteration: DisplayStep[] = [];
  let shouldBacktrack = false;
  const lastDoneStep =
    [...sortedCategorizedSteps].reverse().find((step) => step.steps.length)?.displayCode ||
    OutvioStatusCodes.PROCESSING;
  let hasPassedLastDoneStep = false;
  /**
   * Populates the first iteration of DisplaySteps
   * nextStatus, prevStatus, isFirst, isLast get populated afterwards
   */
  sortedCategorizedSteps.map((step) => {
    if (!step.steps.length) {
      displayStepsFirstIteration.push({
        displayCode: step.displayCode,
        code: step.displayCode,
        status: hasPassedLastDoneStep ? StepStatus.PENDING : StepStatus.DONE,
        dateTime: '',
        isSubStep: false,
        isFirst: false,
        isLast: false,
        isLastDoneStep: false,
      });
    } else {
      displayStepsFirstIteration.push(...getStepToDisplay(step, shouldBacktrack));
    }
    if (currentDisplayCode === step.displayCode && !shouldBacktrack) {
      shouldBacktrack = true;
    }
    if (step.displayCode === lastDoneStep && !hasPassedLastDoneStep) hasPassedLastDoneStep = true;
    return null;
  });
  return populateDates(
    updateIncidents(populateDisplayStepsMissingValues(displayStepsFirstIteration, to)),
  );
};

/**
 * Flow: SourceStep[] -> CategorizedStep[] -> DisplayStep[]
 */
const getDisplaySteps = (
  sourceSteps: SourceSteps,
  trackingData: SourceTrackingData,
): DisplayStep[] => {
  const { steps = [] } = sourceSteps;
  const sortedSteps = addCREAStepIfPrintedLabel(sortStepsByDate([...steps]), trackingData);
  /**
   * Get categorized steps. These are unorganized, and do not include all the Display Steps.
   * However, they may be of some use in the future, so please keep this part of the logic modular.
   */
  const categorizedSteps = sourceToCategorizedSteps(sortedSteps, trackingData);
  return getDisplayStepsFromCategorizedSteps(categorizedSteps, trackingData.destinationAddress);
};

export default getDisplaySteps;
