export function LocalShipping({ ...props }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 -960 960 960"
      fill="currentColor"
      {...props}
    >
      <path d="M227.511-195.384q-43.665 0-74.049-30.513Q123.077-256.41 123.077-300H67.692v-395.385q0-27.615 18.5-46.115 18.5-18.5 46.116-18.5h529.231v144.615h92.307l138.462 186.154V-300h-64.616q0 43.59-30.565 74.103-30.566 30.513-74.231 30.513-43.665 0-74.05-30.513Q618.461-256.41 618.461-300H332.308q0 43.846-30.566 74.231-30.565 30.385-74.231 30.385Zm.181-40.001q27 0 45.808-18.807Q292.308-273 292.308-300T273.5-345.807q-18.808-18.808-45.808-18.808t-45.807 18.808Q163.077-327 163.077-300q0 27 18.808 45.808 18.807 18.807 45.807 18.807Zm495.385 0q27 0 45.808-18.807Q787.692-273 787.692-300t-18.807-45.807q-18.808-18.808-45.808-18.808t-45.808 18.808Q658.462-327 658.462-300q0 27 18.807 45.808 18.808 18.807 45.808 18.807ZM661.539-420H850L732.308-575.385h-70.769V-420Z" />
    </svg>
  );
}
