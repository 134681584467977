import React, { ChangeEvent, HTMLProps, MouseEvent, memo, useCallback, useMemo } from 'react';

import { Maybe, TicketChannels, TicketStatuses, TicketTypes } from 'app-types/desk';
import { useCourierIcons, useGetUser } from 'data-layer';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import {
  CallTicket,
  ChatMessage,
  CheckMark,
  FacebookIcon,
  InstagramColored,
  InstagramComment,
  MetaMessenger,
  OpenInNew,
  OutvioRound,
  OutvioSupport,
  Return,
  Shipping,
  WaitingResponse,
  WhatsAppColored,
} from 'icons';
import { useIntl } from 'localization';
import { twMerge } from 'tailwind-merge';
import { match } from 'ts-pattern';
import { Rating } from 'ui/molecules';

import { useIsTicketUnread } from './TicketPreview.hooks';

dayjs.extend(relativeTime);
dayjs.locale('en', {
  //TODO: FIX RELATIVE TRANSLATION FOR ES, DE, PL,PT
  relativeTime: {
    future: 'in %s',
    past: '%s ago',
    s: '%ds',
    m: '1m',
    mm: '%dm',
    h: '1h',
    hh: '%dh',
    d: '1d',
    dd: '%dd',
    M: '1mo',
    MM: '%dmo',
    y: '1y',
    yy: '%dy',
  },
});

export interface _TicketPreviewProps {
  id: string;
  channel?: TicketChannels;
  messagePreview?: string;
  recipientName?: string;
  subject?: string;
  isStatic?: boolean;
  isRelated?: boolean;
  className?: string;
  updatedAt?: string;
  description?: string;
  type?: TicketTypes;
  isOpen: boolean;
  onIsOpenChange?: (id: string) => void;
  isChecked: boolean;
  onIsCheckedChange?: (id: string, isChecked: boolean) => void;
  isMockup?: boolean;
  waitingForRecipientResponse?: boolean;
  courierName?: string | null;
  onStaticClick?: () => void;
  isDraft?: boolean | null;
  draftMessage?: string | null;
  assignee?: string | null;
  hasBeenSeenByAgent?: string | null;
  lastRecipientResponseDate?: string | null;
  status?: TicketStatuses | undefined;
  rating?: Maybe<number>;
}

export type TicketPreviewProps = Omit<HTMLProps<HTMLDivElement>, 'ref'> & _TicketPreviewProps;

export type StaticTicketPreviewProps = Omit<
  _TicketPreviewProps,
  'isStatic' | 'isOpen' | 'isChecked' | 'onIsCheckedChange'
>;

const EmailTypes: TicketChannels[] = [TicketChannels.Email, TicketChannels.Support];

function _TicketPreview({
  isDraft,
  draftMessage,
  courierName,
  id,
  channel,
  isStatic = false,
  isRelated = false,
  className,
  recipientName,
  type,
  subject,
  messagePreview,
  updatedAt,
  isChecked,
  isOpen,
  onIsOpenChange,
  onIsCheckedChange,
  isMockup,
  waitingForRecipientResponse,
  onStaticClick,
  assignee,
  hasBeenSeenByAgent,
  lastRecipientResponseDate,
  status,
  rating,
}: TicketPreviewProps) {
  const initials = useMemo(() => {
    const splitted = recipientName?.split(' ') || '';
    return `${splitted[0]?.charAt(0).toUpperCase() ?? ''}${
      splitted[1]?.charAt(0).toUpperCase() ?? ''
    }`;
  }, [recipientName]);

  const { formatMessage } = useIntl();
  const isOnBoardingTicket = type === TicketTypes.Onboarding;
  const isCourierTicket = type === TicketTypes.Courier;
  const isReturnTicket = type === TicketTypes.Return;

  const { data: dataUser } = useGetUser();
  const { data: dataIcons } = useCourierIcons(isCourierTicket);
  const courierIcon = useMemo(() => {
    return dataIcons?.find((item) => item?.integration === courierName)?.smallLogo;
  }, [courierName, dataIcons]);

  const isEmailTypeView = useMemo(() => {
    return channel ? EmailTypes.includes(channel) : true;
  }, [channel]);

  const isTicketUnread = useIsTicketUnread({
    id,
    isOpen,
    assignee,
    hasBeenSeenByAgent,
    lastRecipientResponseDate,
    channel,
  });

  const showHighlightEffect = match(status === TicketStatuses.Closed)
    .with(true, () => false)
    .with(false, () => isTicketUnread)
    .exhaustive();

  const messageText = (() => {
    if (isOnBoardingTicket) {
      return (
        formatMessage(
          { id: 'desk-app.onboarding.hi-username' },
          { username: dataUser?.firstname },
        ) +
        ', ' +
        formatMessage({ id: 'desk-app.onboarding.we-thrilled' })
      );
    }

    if (isDraft) {
      return (
        <>
          <span className="text-v2red mr-0.5">{formatMessage({ id: 'desk-app.draft' })},</span>
          {draftMessage}
        </>
      );
    }

    return messagePreview;
  })();

  const handleCheckboxChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      if (isMockup) return;
      onIsCheckedChange?.(id, event.target.checked);
    },
    [onIsCheckedChange, isMockup],
  );

  const handleButtonClick = useCallback(
    (event: MouseEvent<HTMLButtonElement>) => {
      if (isMockup) return;
      if (event.currentTarget !== event.target) return;
      onIsOpenChange?.(id);
    },
    [onIsOpenChange, isMockup],
  );

  const { locale } = useIntl();

  const lastTimeEdited = useMemo(() => {
    if (!updatedAt || !locale) return '';

    try {
      return dayjs(updatedAt).locale(locale).fromNow(true);
    } catch (e) {
      console.error(e);
      return '';
    }
  }, [updatedAt, locale]);

  const courierIcontoUse = courierIcon ? (
    <img src={courierIcon} />
  ) : (
    <Shipping className="w-8 h-8 text-v2blueGray-500" />
  );

  const checkboxIcon = match<boolean>(true)
    .with(isOnBoardingTicket, () => <img src={OutvioRound} />)
    .with(isCourierTicket, () => courierIcontoUse)
    .with(isReturnTicket, () => (
      <div
        className="min-w-[32px] min-h-[32px] text-white flex items-center 
               justify-center bg-v2violet rounded-[10px]"
      >
        <Return width={18} height={18} />
      </div>
    ))
    .otherwise(() => (
      <>
        {channel === TicketChannels.Email && initials}
        {channel === TicketChannels.Chat && <ChatMessage width="32" height="32" />}
        {channel === TicketChannels.Instagram && <InstagramColored width="32" height="32" />}
        {channel === TicketChannels.InstagramComment && <InstagramComment width="32" height="32" />}
        {channel === TicketChannels.Facebook && <MetaMessenger width="32" height="32" />}
        {channel === TicketChannels.FacebookComment && <FacebookIcon width="32" height="32" />}
        {channel === TicketChannels.Whatsapp && <WhatsAppColored width="32" height="32" />}
        {channel === TicketChannels.Support && <OutvioSupport width="32" height="32" />}
        {channel === TicketChannels.Voice && <CallTicket width="32" height="32" />}
      </>
    ));

  return (
    <button
      data-testid="outvioui--ticket-preview"
      className={twMerge(
        'relative flex w-full h-[94px] rounded-[10px] group gap-2 p-2.5',
        isStatic && 'cursor-default',
        isRelated && 'cursor-pointer',
        className,
      )}
      onClick={handleButtonClick}
    >
      <div
        className={twMerge(
          'absolute top-0 left-0 w-full h-full rounded-[10px] pointer-events-none overflow-hidden',
          !isOpen && !isMockup && !isStatic && 'hover:bg-v2blueGray-0',
          !isOpen && isChecked && 'bg-v2blueGray-0',
          isOpen && 'bg-primary bg-opacity-20',
          showHighlightEffect && 'bg-opacity-100 ticket-preview-unread-message-background',
        )}
      />
      <div
        className={twMerge(
          'relative flex items-center justify-center w-8 h-8 bg-v2blueGray-100 rounded-full flex-shrink-0',
          isChecked && 'bg-primary bg-opacity-20',
        )}
      >
        {!isStatic && (
          <label
            data-testid="outvioui--ticket-preview--checkbox-label"
            className={twMerge(
              'w-[18px] h-[18px] block relative select-none',
              !isMockup && 'cursor-pointer',
            )}
          >
            <input
              className="absolute opacity-0 h-0 w-0 pointer-events-none"
              type="checkbox"
              checked={isChecked}
              onChange={handleCheckboxChange}
              data-testid="outvioui--ticket-preview--checkbox"
            />
            <span
              className={twMerge(
                'absolute inset-0 h-full w-full rounded-md border-2 pointer-events-none',
                isChecked ? 'bg-primary border-primary' : 'bg-white border-[#C8D3D9] bg-opacity-80',
              )}
            />
            {isChecked && (
              <CheckMark
                width="11"
                height="11"
                className="absolute top-1/2 left-1/2 text-white translate-x-[-50%] translate-y-[-60%] pointer-events-none"
              />
            )}
          </label>
        )}

        <div
          className={twMerge(
            'absolute flex items-center justify-center w-full h-full pointer-events-none bg-white',
            !isStatic && 'group-hover:opacity-0',
            isOpen && 'bg-[#DFF1F0]',
            isEmailTypeView && !isCourierTicket && 'bg-primary',
            isEmailTypeView &&
              'rounded-full text-[12px] leading-[22px] tracking-[-0.012em] text-white font-semibold',
            isChecked && 'opacity-0',
          )}
        >
          {checkboxIcon}
        </div>
      </div>
      <div className="relative ml-0.5 grow min-w-0 h-full pointer-events-none">
        <div className="mt-[6px] mb-2 text-[14px] leading-[17px] tracking-[-0.014em] text-left text-[#233841] font-semibold text-ellipsis whitespace-nowrap overflow-hidden">
          {recipientName}
        </div>
        <div className="mb-1 text-[12px] leading-[15px] tracking-[-0.012em] text-left font-medium text-ellipsis text-v2blueGray-700 whitespace-nowrap overflow-hidden">
          {subject}
        </div>
        <div className="text-[12px] leading-[15px] tracking-[-0.012em] text-left font-normal text-ellipsis text-v2blueGray-500 whitespace-nowrap overflow-hidden">
          {messageText}
        </div>
      </div>
      <div className="relative flex-shrink-0 mt-[50px] mr-2 text-[12px] leading-[15px] tracking-[-0.012em] font-normal text-v2blueGray-500">
        {lastTimeEdited}
      </div>
      {isStatic && !isRelated && (
        <div
          onClick={onStaticClick}
          className="relative w-9 h-9 flex justify-center items-center rounded-10  hover:bg-v2blueGray-50 cursor-pointer flex-shrink-0"
        >
          <OpenInNew width="18" height="18" className="text-v2blueGray-550" />
        </div>
      )}

      {showHighlightEffect && (
        <div className="absolute top-[10px] right-[9px] w-5 h-5 rounded-full">
          <div className="absolute top-[6px] left-[6px] w-2 h-2 bg-v2blue-700 rounded-full ticket-preview-unread-message-pin-background" />
          <div className="absolute top-[6px] left-[6px] w-2 h-2 bg-v2blue-700 rounded-full" />
        </div>
      )}

      {waitingForRecipientResponse && !isStatic && !rating && (
        <div className="absolute top-[18px] right-[18px] w-4 h-4">
          <WaitingResponse className="text-v2blueGray-550" />
        </div>
      )}
      {rating ? (
        <div className="absolute top-4 right-4">
          <Rating value={rating} />
        </div>
      ) : null}
    </button>
  );
}

export const TicketPreview = memo(_TicketPreview);

// Setting TicketPreview as a named export allows us to use it in the following way:
TicketPreview.displayName = 'TicketPreview';

export default TicketPreview;
