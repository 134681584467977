import get from 'lodash-es/get';

import { OVLocalCache } from 'return-portal-ui';
import { messages } from 'return-portal-ui';
import { getBrandHost } from 'utils/getBrandHost';

import { LANG_TO_LOCALE } from '../../constants/index';
import { SupportedLanguage2LetterList } from '../../types/general';

interface OVRequestOptions {
  method: string;
  upload?: boolean;
  secure?: boolean;
  params?: Record<string, any>;
  data?: Record<string, any> | FormData;
}

interface OVAppOptions {
  skipApiCheck?: boolean;
  skipErrorRedirect?: boolean;
  skipJsonConvert?: boolean;
}

interface OVRequestMeta {
  token: string;
  refresh_token: string;
  userLang: string;
}

const checkStatus = (response: Response, appOptions: OVAppOptions): Promise<string | any> => {
  return new Promise(async (resolve, reject) => {
    let data = {};

    try {
      if (response.ok) {
        if (appOptions.skipJsonConvert) {
          data = await response.text();
        } else {
          data = await response.json();
        }
        return resolve(data);
      } else {
        try {
          data = await response.json();
          return resolve(data);
        } catch (e) {
          return reject(e);
        }
      }
    } catch (e) {
      return reject(e);
    }
  });
};

const checkApiResponse = (data: any): Promise<any> =>
  new Promise((resolve) => {
    if (data.success) {
      return resolve(data);
    }
    if (data.error || data.message) {
      // https://github.com/Outvio/Back-End/blob/develop/src/utils/errorResponse.js
      throw new Error(data.message || data.error);
    }
    return data;
  });

const handleError = (data: any, userLang: string) => {
  if (data.message && (data.message === 'INVALID_TOKEN' || data.message === 'Invalid Token.')) {
    throw new Error('INVALID_TOKEN');
  }

  if (data.message && data.error) {
    const errorCode = get(data, 'message', null);

    const code = data.error.status;
    const error = {
      code,
      message: errorCode,
      requestId: data.request_id,
    };

    const { apiErrors } = messages;

    const useUserLang = userLang === 'es-ES' ? 'es-ES' : 'en-EN';
    if (errorCode === null) {
      error.message = apiErrors[useUserLang]?.FALLBACK_ERROR_MESSAGE;
    } else if (errorCode !== null && apiErrors[useUserLang][errorCode]) {
      error.message = apiErrors[useUserLang][errorCode];
    }

    throw error;
  }

  throw data;
};

const getTokens = async (): Promise<string[]> => {
  return Promise.all([OVLocalCache.get('token'), OVLocalCache.get('refresh_token')]).catch(
    (error) => {
      console.log('getTokens', error);
      try {
        OVLocalCache.delete('token');
        OVLocalCache.delete('refresh_token');
      } catch (error) {
        console.log('getTokens catch', error);
        //
      }
      return [];
    },
  );
};

const gatherLocalData = (): Promise<OVRequestMeta> =>
  getTokens().then((results) => {
    const urlLang = location.pathname.split('/')[1] || 'en';
    const validUrlLang =
      typeof LANG_TO_LOCALE[urlLang as SupportedLanguage2LetterList] !== 'undefined'
        ? LANG_TO_LOCALE[urlLang as SupportedLanguage2LetterList]
        : 'en-EN';
    return {
      token: results[0],
      refresh_token: results[1],
      userLang: validUrlLang,
    };
  });

const makeHeaders = (options: OVRequestOptions, meta: OVRequestMeta) =>
  new Headers({
    Accept: 'application/json',
    ...(options.upload
      ? {}
      : {
          'Content-Type': 'application/json',
        }),
    ...(options.secure ? { 'x-access-token': meta.token } : {}),
    ...(import.meta.env.DEV && !import.meta.env.VITE_OUTVIO_API_URL.includes('api.outvio.com')
      ? {
          'brand-host': getBrandHost(),
        }
      : {}),
  });

const makeUrl = (url: string, options: OVRequestOptions): string => {
  const constructedParams = new URLSearchParams();
  let doAddParams = false;
  if (options?.params) {
    doAddParams = true;
    Object.keys(options.params).forEach((key) => {
      if (options?.params?.[key]) {
        constructedParams.append(key, options.params[key]);
      }
    });
  }

  return doAddParams ? `${url}?${constructedParams.toString()}` : url;
};

const request: (
  url: string,
  options: OVRequestOptions,
  appOptions?: OVAppOptions,
) => Promise<any> = async (
  url,
  options = { method: 'GET' },
  appOptions = { skipApiCheck: false, skipErrorRedirect: false, skipJsonConvert: false },
) => {
  const formedAppOptions = {
    skipApiCheck: appOptions?.skipApiCheck || false,
    skipErrorRedirect: appOptions?.skipErrorRedirect || false,
    skipJsonConvert: appOptions?.skipJsonConvert || false,
  };

  let meta: OVRequestMeta;
  try {
    meta = await gatherLocalData();
  } catch (err) {
    if (err instanceof Error) {
      throw new Error(err?.message);
    }
  }
  // @ts-ignore
  const ovHeaders = makeHeaders(options, meta);
  const finalUrl = makeUrl(url, options);
  const fetchOptObject = {
    method: options.method,
    headers: ovHeaders,
    body:
      options.upload && options.data instanceof FormData
        ? options.data
        : JSON.stringify(options.data),
  };

  let skipSecondaryChecks = true;

  return fetch(finalUrl, fetchOptObject)
    .then((res) => checkStatus(res, formedAppOptions))
    .then((res) => {
      if (!formedAppOptions.skipApiCheck) {
        return checkApiResponse(res);
      }
      return Promise.resolve(res);
    })
    .catch(async (err) => {
      if (err.message && err.message === 'EXPIRED_TOKEN') {
        // refresh token, update headers, retry
        const res = await fetch(`${import.meta.env.VITE_OUTVIO_API_URL}/v2/authorize`, {
          method: 'POST',
          headers: new Headers({
            Accept: 'application/json',
            'Content-Type': 'application/json',
          }),
          body: JSON.stringify({
            grant_type: 'refresh_token',
            refresh_token: meta.refresh_token,
          }),
        });
        const res_1 = await checkStatus(res, { skipJsonConvert: false });
        const res_2 = await checkApiResponse(res_1);
        // Refresh successfull, update local token, update headers, and try again
        skipSecondaryChecks = false;
        await Promise.all([
          OVLocalCache.set('token', res_2.access_token),
          OVLocalCache.set('refresh_token', res_2.refresh_token),
        ]);
        fetchOptObject.headers = makeHeaders(options, { ...meta, token: res_2.access_token });
        return await fetch(finalUrl, fetchOptObject);
      }
      return Promise.reject(err);
    })
    .then((res) => (skipSecondaryChecks ? res : checkStatus(res, formedAppOptions)))
    .then((res) => {
      if (skipSecondaryChecks) {
        return res;
      }
      if (!formedAppOptions.skipApiCheck) {
        return checkApiResponse(res);
      }
      return Promise.resolve(res);
    })
    .catch((err) => handleError(err, meta.userLang));
};

export default request;
