import { useMemo } from 'react';

import { createEditor } from 'slate';
import { withHistory } from 'slate-history';
import { withReact } from 'slate-react';

import { CustomEditor } from '../Interfaces/IRichTextInput';

const useInitEditor = () => {
  const editor = useMemo(() => withHistory(withReact(createEditor() as CustomEditor)), []);
  return { editor };
};

export default useInitEditor;
