const emojiMap: { [key: string]: string } = {
  ':-)': '😊',
  ':)': '😊',
  ':-(': '🙁',
  ':(': '🙁',
  ';-)': '😉',
  ';)': '😉',
  // Add more patterns and emojis here
};

const hotKeys = {
  'mod+b': 'bold',
  'mod+i': 'italic',
  'mod+u': 'underline',
};

export { emojiMap, hotKeys };
