export function CheckMark({ ...props }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 11.267 11.267"
      {...props}
    >
      <path
        d="M9680.937,438.38h8.622v4.311"
        transform="translate(7165.643 -6531.365) rotate(135)"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeWidth="1.5"
      />
    </svg>
  );
}
