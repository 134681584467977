export function Search({ ...props }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 -960 960 960"
      width="1em"
      height="1em"
      fill="currentColor"
      {...props}
    >
      <path d="M380.769-335.386q-102.461 0-173.537-71.076Q136.155-477.539 136.155-580q0-102.461 71.077-173.538 71.076-71.076 173.537-71.076 102.461 0 173.538 71.076Q625.384-682.461 625.384-580q0 42.846-14.385 81.846-14.385 39-38.385 67.846l230.155 230.154q8.307 8.308 8.499 20.885.193 12.576-8.499 21.268-8.693 8.692-21.077 8.692-12.384 0-21.076-8.692L530.461-388.155q-30 24.769-69 38.769t-80.692 14Zm0-59.998q77.308 0 130.962-53.654Q565.385-502.692 565.385-580q0-77.308-53.654-130.962-53.654-53.654-130.962-53.654-77.308 0-130.962 53.654Q196.154-657.308 196.154-580q0 77.308 53.653 130.962 53.654 53.654 130.962 53.654Z" />
    </svg>
  );
}
