import React, { ReactNode, memo } from 'react';

import { twMerge } from 'tailwind-merge';

export interface IOrderInfoBlock {
  title?: ReactNode;
  value?: ReactNode;
  imgSrc?: string;
  className?: string;
  icon?: ReactNode;
}
const OrderInfoBlock = memo(({ title, value, imgSrc, className, icon }: IOrderInfoBlock) => {
  return (
    <div className={twMerge('flex gap-4 items-center px-4 font-inter', className)}>
      {icon && icon}
      {imgSrc && (
        <div className="h-8 flex items-center justify-center flex-shrink-0">
          <img src={imgSrc} loading="lazy" className="h-full" />
        </div>
      )}
      <div className="flex flex-col">
        <div className="text-xs text-v2blueGray-500">{title}</div>
        <div className="text-sm text-text-v2blueGray-800">{value}</div>
      </div>
    </div>
  );
});

OrderInfoBlock.displayName = 'OrderInfoBlock';

export { OrderInfoBlock };
