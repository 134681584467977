import { useCallback, useContext } from 'react';

import { CreditCard, changePlanTo, deskActivate, requestActivatePayment } from 'data-layer';

import { SubscriptionContext } from '../../PricingPage/hooks/useSubscriptionContext';
import { PricingPlanProps } from '../../PricingPage/types/pricingTypes';

export type ProcessPaymentProps = {
  paymentMethod: string;
  selectedPlan: PricingPlanProps;
  allRegisteredCards: CreditCard[];
  isOnTrial: boolean;
};

const usePaymentProcessing = () => {
  const contextData = useContext(SubscriptionContext);

  return useCallback(
    async ({ paymentMethod, selectedPlan, allRegisteredCards, isOnTrial }: ProcessPaymentProps) => {
      const needToChangePlan =
        contextData?.subscription.plan !== selectedPlan.plan ||
        !contextData?.subscription.isActive ||
        isOnTrial;

      if (paymentMethod === 'new') {
        try {
          if (selectedPlan.plan && !selectedPlan.isDeskOnly) {
            await changePlanTo({
              targetPlan: selectedPlan.plan,
              period: 'monthly',
              deskIncluded: selectedPlan.isDeskIncluded,
            });
          }

          if (selectedPlan.isDeskOnly) {
            await deskActivate(true);
          }
        } catch (error) {
          return (error as any)?.message || error?.toString();
        }
      } else {
        const selectedCard = allRegisteredCards.find((card) => card._id === paymentMethod);

        //==========================================================
        // If the user has more than one card,
        // we need to activate the selected card if it's not active.
        // Only one card can be active as default.
        //==========================================================
        try {
          if (!selectedCard?.isActive) {
            await requestActivatePayment({
              _id: paymentMethod,
              method: 'cc',
            });
          }
          if (needToChangePlan) {
            await changePlanTo({
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              targetPlan: selectedPlan.plan!,
              period: 'monthly',
              deskIncluded: selectedPlan.isDeskIncluded,
            });
          }
          if (!needToChangePlan && selectedPlan.isDeskOnly) {
            await deskActivate(true);
          }
        } catch (e: any) {
          return e?.message || e?.toString();
        }
      }
    },
    [],
  );
};

export { usePaymentProcessing };
