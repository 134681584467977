import React, { useMemo, useState } from 'react';

import { Tab, Tabs, makeStyles } from '@material-ui/core';
import cn from 'classnames';

const useStyles = makeStyles((theme) => ({
  tabsFlexContainer: {
    alignItems: 'flex-end',
    height: '40px',
  },
  tabsIndicator: {
    display: 'none',
  },
  tabsRoot: {
    overflow: 'visible',
    height: '40px',
    minHeight: 'unset',
  },
  tabsFixed: {
    overflow: 'visible !important',
    height: '40px',
  },
  tabRoot: {
    width: 'fit-content',
    maxWidth: 'unset',
    minWidth: 'unset',
    height: '40px',
    minHeight: '40px',
    marginRight: '5px',

    fontSize: '13px',
    opacity: 1,
    color: '#00363D',
    fontWeight: 400,
    backgroundColor: '#fff',
    border: '1px solid #c6dbdd',
    borderBottom: 'none',
    borderTopLeftRadius: '5px',
    borderTopRightRadius: '5px',
    whiteSpace: 'nowrap',

    boxShadow: '0 0 10px rgba(198, 219, 221, 0.5)',

    transition: 'all 0.2s ease-in',

    [theme.breakpoints.down('xs')]: {
      minWidth: 'unset',
    },
  },
  tabSelected: {
    width: 'fit-content',
    maxWidth: 'unset',
    height: '40px',
    fontSize: '13px',
    fontWeight: 600,
    // Hack to make the tab pop out with white bg
    // transform: 'translateY(1px)',
    // backgroundColor: '#c6dbdd',
    backgroundColor: '#c6dbdd',
    boxShadow: 'none',

    [theme.breakpoints.down('xs')]: {
      width: 'auto',
    },
  },
  tabSelectedNoHighlight: {
    backgroundColor: '#fff',
  },
  tabContent: {
    width: '100%',
    border: '1px solid #c6dbdd',
    borderBottomLeftRadius: '5px',
    borderBottomRightRadius: '5px',
  },
}));

interface IOVTabs {
  children: React.ReactNode;
  noActiveHighlight?: boolean;
}

const OVTabs = ({ children, noActiveHighlight = false }: IOVTabs) => {
  const classes = useStyles();

  const childrenArray: any[] = useMemo(() => React.Children.toArray(children), [children]);
  const [currentTabIndex, changeTabTo] = useState(0);

  return (
    <>
      <Tabs
        value={currentTabIndex}
        onChange={(e, item) => changeTabTo(item)}
        classes={{
          flexContainer: classes.tabsFlexContainer,
          indicator: classes.tabsIndicator,
          root: classes.tabsRoot,
          fixed: classes.tabsFixed,
        }}
        variant="scrollable"
        scrollButtons="auto"
      >
        {childrenArray.map((child) => (
          <Tab
            key={child.key}
            label={child.props.title}
            classes={{
              root: classes.tabRoot,
              selected: cn(classes.tabSelected, {
                [classes.tabSelectedNoHighlight]: noActiveHighlight,
              }),
            }}
            disableRipple
            disableFocusRipple
          />
        ))}
      </Tabs>
      <div className={classes.tabContent}>{childrenArray[currentTabIndex]}</div>
    </>
  );
};

export default OVTabs;
