import { request } from 'ui';

export const webLog = (data: Record<string, any>) =>
  request(`${import.meta.env.VITE_OUTVIO_API_URL}/log`, {
    method: 'POST',
    data,
    secure: false,
  }).catch(() => {
    // Do nothing
  });
