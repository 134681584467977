export default {
  'account.save': 'Save',
  'account.address.name': 'Alias',
  'account.address.address': 'Address',
  'account.address.postcode': 'Postcode',
  'account.address.city': 'City',
  'account.address.country': 'Country',
  'account.address.province': 'Province',
  'account.address.contactName': 'Contact person',
  'account.address.contactPhone': 'Contact phone',
};
