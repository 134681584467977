import { IntlShape } from 'localization';
import * as yup from 'yup';

const paymentValidationSchema = (t: IntlShape) => {
  const requiredMessage = t.formatMessage({ id: '3PL.error.required-message' });
  const invalidCardNumber = t.formatMessage({ id: 'settings.payment.invalid-card-number' });

  const trimAndRemoveSpaces = (value: string) => (value ? value.trim().replace(/\s/g, '') : '');

  return yup.object().shape({
    plan: yup.string().required(requiredMessage),
    paymentMethods: yup.array(),
    holderName: yup.string().required(requiredMessage),
    number: yup.string().when('paymentMethod', {
      is: 'new',
      then: yup
        .string()
        .transform(trimAndRemoveSpaces)
        .min(16, invalidCardNumber)
        .required(requiredMessage),
      otherwise: yup.string().required(requiredMessage),
    }),
    expiry: yup.string().required(requiredMessage),
    cvc: yup.string().min(3).max(4).required(requiredMessage),
    acceptedTerms: yup.boolean().oneOf([true], requiredMessage),
  });
};

const shopifyPaymentValidationSchema = (t: IntlShape) => {
  const requiredMessage = t.formatMessage({ id: '3PL.error.required-message' });

  return yup.object().shape({
    plan: yup.string().required(requiredMessage),
    acceptedTerms: yup.boolean().oneOf([true], requiredMessage),
  });
};

export { paymentValidationSchema, shopifyPaymentValidationSchema };
