export default {
  'ovc.general.powered-by': 'Ausgeführt von',
  'ovc.auth.identity.email.placeholder': 'E-Mail',
  'ovc.auth.identity.cta': 'Ware zurücksenden',
  'ovc.auth.order.orderNumber.placeholder': 'Auftragsnummer oder Kurier-Sendungsverfolgungsnummer',
  'ovc.auth.order.clientInfo.placeholder':
    'Postleitzahl oder die letzten 5 Ziffern Ihrer Telefonnummer',
  'ovc.auth.switch-to-identity': 'mit E-Mail Adresse anmelden',
  'ovc.auth.switch-to-order': 'mit Auftragsinfos anmelden',
  'ovc.auth.tracking.cta': 'Meine Bestellung nachverfolgen',
  'ovc.auth.tracking.orderIdentifier.placeholder':
    'Auftrags-ID, OTN oder Sendungsverfolgung einfügen',
  'ovc.auth.tracking.postcode.placeholder': 'Postleitzahl des Bestimmungsortes einfügen',
  'ovc.listing.page.all': 'Alle Bestellungen',
  'ovc.listing.page.in-transit': 'In Zustellung befindliche Bestellungen',
  'ovc.listing.page.returns': 'Retouren',
  'ovc.listing.header.order': 'Bestellung',
  'ovc.listing.header.date': 'Datum',
  'ovc.listing.header.total': 'insgesamt',
  'ovc.listing.header.choose-product': 'Produkt wählen:',
  'ovc.listing.listing-track-cta': 'Sendung verfolgen',
  'ovc.listing.listing-return-cta': 'ware zurücksenden',
  'ovc.listing.listing-external-action-cta': 'Reparatur-Garantie anfordern',
  'ovc.listing.empty-list': 'Keine Bestellungsansicht verfügbar',
  'ovc.listing.return-status.store-non-returnable': 'Keine Rückgabe möglich',
  'ovc.listing.return-status.already-returned': 'Retourniert am {date}',
  'ovc.listing.return-status.no-longer-returnable': 'Keine Retoure mehr möglich',
  'ovc.listing.return-status.returnable': 'Retoure möglich bis {date}',
  'ovc.return-cart.refund-method-credit': `Wählen Sie diese Option, falls Sie Ihre Rückerstattung für Ihren nächsten Einkauf bei uns verwenden möchten! {lineBreak}
  {lineBreak}
  Sie erhalten von uns eine Geschenkkarte in Höhe des Rückerstattungsbetrages.`,
  'ovc.return-cart.inst.outvio.heading.confirm-pickup':
    'Bestätigen Sie Ihre Abholadresse und Ihre Rückgabesendung',
  'ovc.return-cart.inst.outvio.heading.confirm-return': 'Bestätigen Sie Ihre Rückgabesendung',
  'ovc.return-cart.address.heading':
    'Geben Sie eine neue Adresse ein oder wählen Sie eine bereits vorhandene Adresse:',
  'ovc.return-cart.socket-error':
    'Fehler im System des Paketdienstes: {error}. Bitte versuchen Sie es später noch einmal',
};
