import { twMerge } from 'tailwind-merge';

import { DropdownExpandMoreIcon } from '../Dropdown/DropdownExpandMoreIcon';
import { AgentTitleLogo, IAgentTitleLogo } from './AgentTitleLogo';

interface IAgentPanelButton extends IAgentTitleLogo {
  open?: boolean;
  onClick?: () => void;
  reversedIcon?: boolean;
  reversedTitle?: boolean;
}
export const AgentPanelButton = ({
  logo,
  title,
  subtitle,
  open = false,
  onClick,
  reversedIcon,
  reversedTitle,
}: IAgentPanelButton) => {
  return (
    <button
      type="button"
      onClick={onClick}
      className={twMerge(
        'rounded-[20px] py-0.5 px-1 hover:bg-v2blueGray-200/40 flex gap-3 items-center justify-between font-inter w-full',
        open && 'bg-v2blueGray-200/40',
      )}
    >
      <AgentTitleLogo reversed={reversedTitle} title={title} logo={logo} subtitle={subtitle} />
      <div className="pr-2">
        <DropdownExpandMoreIcon reversed={reversedIcon} open={open} />
      </div>
    </button>
  );
};
