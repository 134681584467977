import React, { forwardRef, useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { useInView } from 'react-intersection-observer';

import { makeStyles } from '@material-ui/core';
import { webLog } from 'data-layer';

import Loader from '../Loader';
import OVAlert from '../OVAlert';
import generateMapStyleParts from './generateMapStyleParts';
import { GeoLocation } from './types';

const useStyles = makeStyles({
  container: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',

    '& > img': {
      width: '100%',
      height: '100%',
    },
  },
});

const imageSize = (availableWidth = 0, availableHeight = 0) => {
  // https://developers.google.com/maps/documentation/maps-static/dev-guide#Imagesizes
  // Restricted to 640x640 images on non-premium tier

  const aspectRatio = availableHeight / availableWidth;
  const width = Math.round(availableWidth > 640 ? 640 : availableWidth);
  const height = Math.round(width * aspectRatio);

  return {
    scale: 2,
    size: `${width}x${height}`,
  };
};

const parseMarkers = (
  markers: GeoLocation[],
  customIcon: string,
  mapPinColor: string,
): string[] => {
  const arr: string[] = [];

  markers.forEach((marker, index) => {
    const parts = [];

    // Generic marker settings
    parts.push('size:mid');
    parts.push('scale:2');
    parts.push(`color:0x${mapPinColor.substring(1)}`);

    // Icon url must be url encoded
    // https://developers.google.com/maps/documentation/maps-static/start#CustomIcons
    if (markers.length > 2 && index === markers.length - 1) {
      // Last icon is either custom or regular
      if (customIcon) {
        parts.push(`icon:${customIcon}`);
      }
    } else if (markers.length <= 2 && index === 0) {
      // if we only have origin and destination, origin should have the custom marker (as thats the "current" step)
      if (customIcon) {
        parts.push(`icon:${customIcon}`);
      }
    }
    //}

    // location comes last
    parts.push(`${marker.lat},${marker.lng}`);

    arr.push(parts.join('|'));
  });

  return arr;
};

const generatePaths = (markers: GeoLocation[], mapPathColor: string | null): string => {
  const arr = [];

  arr.push('weight:3');
  arr.push(`color:0x${mapPathColor === null ? '3D727B' : mapPathColor.substring(1)}FF`);
  arr.push('geodesic:true');

  markers.forEach((marker) => {
    arr.push(`${marker.lat},${marker.lng}`);
  });

  return arr.join('|');
};

interface StaticMapProps {
  containerStyle?: React.CSSProperties;
  mapKey: string;
  mapHeight?: number;
  isDelivered: boolean;
  customMarkerIcon?: string;
  useCustomMapColors?: boolean;
  mapBaseColor?: string;
  mapTextColor?: string;
  mapPathColor?: string;
  mapPinColor?: string;
  markers: { lng: number; lat: number }[];
}

const StaticMap = forwardRef<HTMLDivElement, StaticMapProps>(
  (
    {
      containerStyle = {},
      mapKey,
      mapHeight = 300,
      isDelivered,
      customMarkerIcon = '',
      useCustomMapColors = false,
      mapBaseColor = '',
      mapTextColor = '',
      mapPathColor = '',
      mapPinColor = '',
      markers,
    },
    ref,
  ) => {
    const classes = useStyles();
    const containerRef = useRef<HTMLDivElement>();
    const [inViewRef, inView] = useInView({ triggerOnce: true });
    const [isLoaded, setIsLoaded] = useState(false);

    const [isLoading, setLoading] = useState(true);
    const [error, setError] = useState<null | string>(null);
    const [mapImg, setMapImg] = useState('');

    const mapRerenderKey = useMemo(
      () =>
        `${customMarkerIcon?.toString()}-${useCustomMapColors.toString()}-${mapBaseColor}-${mapTextColor}-${mapPathColor}-${mapPinColor}-`,
      [customMarkerIcon, useCustomMapColors, mapBaseColor, mapTextColor, mapPathColor, mapPinColor],
    );
    const prevMapRerenderKey = useRef<string>(mapRerenderKey);

    // https://www.npmjs.com/package/react-intersection-observer#how-can-i-assign-multiple-refs-to-a-component
    const setRefs = useCallback(
      (node: any) => {
        containerRef.current = node;
        inViewRef(node);
      },
      [inViewRef],
    );

    useEffect(() => {
      if ((inView && !isLoaded) || (inView && prevMapRerenderKey.current !== mapRerenderKey)) {
        prevMapRerenderKey.current = mapRerenderKey;
        // Init map when it gets in viewport
        const data: { [key: string]: any; markers: GeoLocation[] } = {
          key: mapKey,
          ...(containerRef.current
            ? imageSize(containerRef.current.offsetWidth, containerRef.current.offsetHeight)
            : {}),
          markers: [],
          path: '',
        };

        data.markers = markers;
        if (isDelivered || markers.length !== 0) {
          data.path = generatePaths(
            data.markers,
            useCustomMapColors && mapPathColor !== '' ? mapPathColor : null,
          );
        }

        try {
          const searchParams = new URLSearchParams('');
          Object.keys(data).forEach((key) => {
            if (key === 'markers') {
              const markerEntries = parseMarkers(data[key], customMarkerIcon, mapPinColor);
              markerEntries.forEach((markerEntry) => searchParams.append('markers', markerEntry));
            } else if (key === 'path') {
              if (data[key]) {
                searchParams.append('path', data[key]);
              }
            } else {
              searchParams.append(key, data[key]);
            }
          });

          // Add custom map colors
          if (useCustomMapColors && mapBaseColor !== '' && mapTextColor !== '') {
            const styleEntries = generateMapStyleParts(mapBaseColor, mapTextColor);
            styleEntries.forEach((entry) => {
              searchParams.append('style', entry);
            });
          }

          setMapImg(`https://maps.googleapis.com/maps/api/staticmap?${searchParams.toString()}`);
          setLoading(false);
          setIsLoaded(true);
          webLog({
            link: 'legacy',
            host: location.host,
          });
        } catch (err) {
          err instanceof Error && setError(err.message);
          setLoading(false);
          setIsLoaded(true);
        }
      }
    }, [inView, mapRerenderKey]);

    return (
      <div
        className={classes.container}
        style={{ height: `${mapHeight}px`, ...containerStyle }}
        ref={ref}
      >
        <div
          className={classes.container}
          style={{ height: `${mapHeight}px`, ...containerStyle }}
          ref={setRefs}
        >
          {isLoading && <Loader />}
          {!isLoading && error !== null && <OVAlert>{error}</OVAlert>}
          {!isLoading && error === null && <img src={mapImg} />}
        </div>
      </div>
    );
  },
);

export default StaticMap;
