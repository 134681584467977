export function FacebookIcon({ ...props }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 32 32" {...props}>
      <defs>
        <linearGradient
          id="linear-gradient-f"
          x1="-6.934"
          y1="10.216"
          x2="-6.934"
          y2="10.241"
          gradientUnits="objectBoundingBox"
        >
          <stop offset="0" stopColor="#0062e0" />
          <stop offset="1" stopColor="#19afff" />
        </linearGradient>
      </defs>
      <g transform="translate(-614 -984)">
        <rect
          width="32"
          height="32"
          rx="10"
          transform="translate(614 1016) rotate(-90)"
          fill="url(#linear-gradient-f)"
        />
        <path
          d="M23.893,21.067l.68-4.174H20.567V13.986c0-1.193.453-2.087,2.267-2.087H24.8V8.1a25.057,25.057,0,0,0-3.326-.3c-3.477,0-5.9,2.087-5.9,5.814v3.28H11.8v4.174h3.779V31.8h4.988V21.067Z"
          transform="translate(613.2 984.2)"
          fill="#fff"
        />
      </g>
    </svg>
  );
}
