import { memo } from 'react';

import { ArrowBack } from 'icons';

export const PortalBackButton = memo(
  ({
    text,
    onClick,
    href,
    className,
  }: {
    text: string;
    onClick?: () => void;
    href?: string;
    className?: string;
  }) => {
    const Tag = href ? 'a' : 'button';
    return (
      <Tag
        href={href}
        target={href ? '_blank' : undefined}
        onClick={onClick}
        type={href ? undefined : 'button'}
        className={`flex text-base items-center p-3 pr-4 gap-2 text-portal-black rounded-lg hover:bg-portal-black/5 font-medium [&>div>svg]:hover:translate-x-0.5 ${
          className ? className : ''
        }`}
      >
        <div className="w-[18px] h-6 overflow-hidden">
          <ArrowBack className="text-2xl transition-transform duration-300" />
        </div>
        <div className="hidden md:block">{text}</div>
      </Tag>
    );
  },
);
