export default {
  'orders.package': 'Paquete',
  'orders.returns.reasons.size': 'La talla no me va bien',
  'orders.returns.reasons.wrong-product': 'Artículo entregado erróneo',
  'orders.returns.reasons.wrong-size': 'La talla entregada es diferente a la pedida',
  'orders.returns.reasons.wrong-colour': 'El color del artículo es diferente al pedido',
  'orders.returns.reasons.package-broken': 'La caja del producto está rota',
  'orders.returns.reasons.product-broken': 'El producto está roto/no funciona',
  'orders.returns.reasons.product-used': 'El producto recibido está usado',
  'orders.returns.reasons.product-dirty': 'El producto recibido está sucio',
  'orders.returns.reasons.do-not-want': 'Ya no lo quiero',
  'orders.returns.reasons.exchange': 'Quiero hacer un cambio',
  'orders.returns.reasons.other': 'Otra',
  'orders.returns.pickup.form.comments': 'Comentarios',
  'orders.list.empty': 'No tienes pedidos actualmente',
  'orders.total': 'Total',
  'orders.vat': 'IVA',
  'or.confirm-address': 'CONFIRMA TU DIRECCION DE RECOGIDA',
  'or.from': 'De',
  'or.to': 'Hasta',
  'or.return.list.empty': 'No tienes devoluciones actualmente',
  'or.confirm-return': 'CONFIRMA TU DEVOLUCIÓN',
};
