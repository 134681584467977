export default {
  'ovc.general.powered-by': 'Powered by',
  'ovc.auth.identity.email.placeholder': 'E-Mail',
  'ovc.auth.identity.cta': 'Tagasta toode',
  'ovc.auth.order.orderNumber.placeholder': 'Tellimuse number või kulleri jälgmisnumber',
  'ovc.auth.order.clientInfo.placeholder': 'Posti sihtnumber või telefoni 5 viimast numbrit',
  'ovc.auth.switch-to-identity': 'Logi sisse e-mailiga',
  'ovc.auth.switch-to-order': 'Logi sisse tellimuse infoga',
  'ovc.auth.tracking.cta': 'Jälgi tellimust',
  'ovc.auth.tracking.orderIdentifier.placeholder':
    'Sisesta tellimuse number, OTN või kulleri pakinumber',
  'ovc.auth.tracking.postcode.placeholder': 'Sisesta saaja sihtnumber',
  'ovc.listing.page.all': 'Kõik tellimused',
  'ovc.listing.page.in-transit': 'Teel tellimused',
  'ovc.listing.page.returns': 'Tagastused',
  'ovc.listing.header.order': 'tellimus',
  'ovc.listing.header.date': 'kuupäev',
  'ovc.listing.header.total': 'kokku',
  'ovc.listing.header.choose-product': 'Vali toode:',
  'ovc.listing.listing-track-cta': 'jälgi',
  'ovc.listing.listing-return-cta': 'tagasta',
  'ovc.listing.listing-external-action-cta': 'Telli garantiiremont',
  'ovc.listing.empty-list': 'Tellimused puuduvad',
  'ovc.listing.return-status.store-non-returnable': 'Ei saa tagastada',
  'ovc.listing.return-status.already-returned': 'Tagastatud {date}',
  'ovc.listing.return-status.no-longer-returnable': 'Tagastuse periood möödunud',
  'ovc.listing.return-status.returnable': 'Tagasta kuni {date}',
  'ovc.return-cart.refund-method-credit': `Vali see, kui soovid kasutada tagastuse summat meie poes uueks ostuks!{lineBreak}
  {lineBreak}
  Sellisel juhul saadame sulle kinkekaardi tagastuse summale.`,
  'ovc.return-cart.inst.outvio.heading.confirm-pickup': 'kinnita tagastus ja korje aadress',
  'ovc.return-cart.inst.outvio.heading.confirm-return': 'kinnita tagastus',
  'ovc.return-cart.address.heading':
    'sisesta uus aadress või vali eelnevalt kasutatud aadresside hulgast:',
};
