import {
  ButtonHTMLAttributes,
  ReactElement,
  ReactNode,
  cloneElement,
  memo,
  useMemo,
  useRef,
} from 'react';
import React from 'react';

import { twMerge } from 'tailwind-merge';

import { useScrollText } from '../../hooks/useScrollText';

interface SectionPropsInterface {
  id?: string;
  leftIcon: ReactNode;
  label: ReactNode;
  count?: number;
  rightIcon?: ReactNode;
  isSelected: boolean;
  noColorInjection?: boolean;
  onClick?: (id?: string) => void;
  classNameSection?: string;
}

export type SectionProps = SectionPropsInterface & ButtonHTMLAttributes<HTMLButtonElement>;

export function Section({
  leftIcon,
  label,
  count,
  rightIcon,
  isSelected,
  onClick,
  noColorInjection = false,
  classNameSection,
  ...props
}: SectionProps) {
  const colorPalette = useMemo(
    () =>
      isSelected
        ? 'text-primary bg-primary bg-opacity-20'
        : 'text-v2blueGray-800 hover:bg-v2blueGray-200',
    [isSelected],
  );
  const leftIconComponent = useMemo(() => {
    if (!leftIcon) return null;
    if (noColorInjection) return leftIcon;

    if (typeof leftIcon === 'string') return leftIcon;
    const comp = cloneElement(leftIcon as ReactElement, {
      fill: isSelected ? '#4BB4B3' : '#849FAD',
    });
    return comp ?? null;
  }, [leftIcon, isSelected]);

  const rightIconComponent = useMemo(() => {
    if (!rightIcon) return null;
    if (noColorInjection) return rightIcon;

    if (typeof rightIcon === 'string') return rightIcon;
    const comp = cloneElement(leftIcon as ReactElement, {
      fill: isSelected ? '#4BB4B3' : '#849FAD',
    });
    return comp ?? null;
  }, [rightIcon, isSelected]);

  const textBoxRef = useRef<HTMLDivElement>(null);
  const triggerBoxRef = useRef<HTMLDivElement>(null);

  useScrollText(textBoxRef, triggerBoxRef);

  return (
    <div ref={triggerBoxRef}>
      <button
        data-testid="outvioui--section"
        className={twMerge(
          'group flex items-center w-full h-9 pl-3 text-sm rounded-[10px] tracking-[-0.014em]',
          count !== undefined ? 'pr-4' : 'pr-[10px]',
          isSelected ? 'font-medium' : 'font-normal',
          colorPalette,
          classNameSection,
        )}
        onClick={onClick}
      >
        {leftIconComponent}
        <div
          ref={textBoxRef}
          className={twMerge('mx-2 text-left truncate flex-1', props.className)}
          data-testid="outvioui--section-label"
        >
          <span className="group-hover:inline-block">{label}</span>
        </div>
        {count !== undefined && (
          <div
            className="w-4 flex items-center justify-center"
            data-testid="outvioui--section-counter"
          >
            {count}
          </div>
        )}
        <div className={isSelected ? 'text-primary' : 'text-v2blueGray-550'}>
          {rightIconComponent}
        </div>
      </button>
    </div>
  );
}

export default memo<SectionProps>(Section);
