export default {
  'ovc.general.powered-by': 'Pristato',
  'ovc.auth.identity.email.placeholder': 'El. paštas',
  'ovc.auth.identity.cta': 'Grąžinkite',
  'ovc.auth.order.orderNumber.placeholder': 'Užsakymo numeris ar siuntos sekimo numeris',
  'ovc.auth.order.clientInfo.placeholder': 'Pašto kodas arba paskutiniai 5 telefono skaičiai',
  'ovc.auth.switch-to-identity': 'Prisijungti naudojant el. paštą',
  'ovc.auth.switch-to-order': 'Prisijungti naudojant kito užsakymo informaciją',
  'ovc.auth.tracking.cta': 'Stebėti mano užsakymą',
  'ovc.auth.tracking.orderIdentifier.placeholder': 'Įrašyti užsakymo ID, OTN arba sekimo numerį',
  'ovc.auth.tracking.postcode.placeholder': 'Įterpti paskirties pašto kodą',
  'ovc.listing.page.all': 'Visi užsakymai',
  'ovc.listing.page.in-transit': 'Užsakymai pakeliui',
  'ovc.listing.page.returns': 'Grąžinimas',
  'ovc.listing.header.order': 'užsakymas',
  'ovc.listing.header.date': 'data',
  'ovc.listing.header.total': 'viso',
  'ovc.listing.header.choose-product': 'Pasirinkite prekę:',
  'ovc.listing.listing-track-cta': 'sekti',
  'ovc.listing.listing-return-cta': 'grįžti',
  'ovc.listing.listing-external-action-cta': 'prašyti garantinio taisymo',
  'ovc.listing.empty-list': 'Nėra rodytinų užsakymų',
  'ovc.listing.return-status.store-non-returnable': 'Negrąžinama',
  'ovc.listing.return-status.already-returned': 'Grąžinta {date}',
  'ovc.listing.return-status.no-longer-returnable': 'Nebegalima grąžinti',
  'ovc.listing.return-status.returnable': 'Grąžinti iki {date}',
  'ovc.return-cart.refund-method-credit': `Pasirinkite tik tuomet, jei norite savo grąžinamą pinigų sumą naudoti kitam pirkiniui mūsų parduotuvėje!{lineBreak}
  {lineBreak}
  Išsiųsime jums dovanų kortelę, kurios vertė bus grąžinama suma.`,
  'ovc.return-cart.inst.outvio.heading.confirm-pickup':
    'patvirtinkite savo paėmimo adresą ir grąžinkite',
  'ovc.return-cart.inst.outvio.heading.confirm-return': 'patvirtinkite grąžinimą',
  'ovc.return-cart.address.heading': 'įveskite naują adresą arba pasirinkite jau esamą:',
};
