export function Dashboard({ ...props }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="currentColor"
      {...props}
    >
      <g transform="translate(-152 -691)">
        <g transform="translate(151.892 690.025)">
          <path
            d="M14.26,9.975a.884.884,0,0,1-.9-.908V5.383a.888.888,0,0,1,.907-.909H19.7a.884.884,0,0,1,.9.909V9.066a.888.888,0,0,1-.907.908Zm-9.75,3.5a.884.884,0,0,1-.9-.9v-7.2a.867.867,0,0,1,.261-.641.881.881,0,0,1,.646-.259h5.44a.884.884,0,0,1,.9.9v7.2a.867.867,0,0,1-.261.641.881.881,0,0,1-.646.259Zm9.75,8a.884.884,0,0,1-.9-.9v-7.2a.867.867,0,0,1,.261-.641.881.881,0,0,1,.646-.259H19.7a.884.884,0,0,1,.9.9v7.2a.867.867,0,0,1-.261.641.881.881,0,0,1-.646.259Zm-9.75,0a.884.884,0,0,1-.9-.909V16.883a.888.888,0,0,1,.907-.908h5.44a.884.884,0,0,1,.9.908v3.683a.888.888,0,0,1-.907.909Z"
            fill="currentColor"
          />
        </g>
      </g>
    </svg>
  );
}
