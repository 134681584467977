import { ReactNode, memo, useMemo } from 'react';

import { TagColor } from 'app-types';
import { AgentStatuses } from 'data-layer';
import { AccountCircleFilled } from 'icons';
import { twMerge } from 'tailwind-merge';
import { AvatarLoader } from 'ui/atoms';

import { useRandomColor } from '../../hooks';
import { AgentStatus } from './AgentStatus';

export enum UserLogoSize {
  small = 'small',
  medium = 'medium',
  large = 'large',
  mediumSm = 'medium-sm',
}

export interface IAgentPanelLogo {
  name?: string;
  status?: AgentStatuses;
  className?: string;
  children?: ReactNode;
  size?: `${UserLogoSize}`;
  color?: TagColor | 'random';
  loading?: boolean;
}

export const AgentPanelLogo = memo(
  ({
    name,
    status,
    className,
    children,
    size = UserLogoSize.medium,
    color = 'random',
    loading,
  }: IAgentPanelLogo) => {
    const { bg } = useRandomColor(name || '');
    const { iconSize, loaderSize } = useMemo(() => {
      switch (size) {
        case UserLogoSize.mediumSm:
          return {
            iconSize: '32',
            loaderSize: 36,
          };
        default:
          return { iconSize: '24', loaderSize: 32 };
      }
    }, [size]);

    if (loading) {
      return <AvatarLoader width={loaderSize} height={loaderSize} />;
    }

    return (
      <div className="relative">
        <div
          className={twMerge(
            'rounded-full uppercase relative text-white flex justify-center items-center',
            size === UserLogoSize.large && 'w-14 h-14 font-bold text-xl',
            size === UserLogoSize.mediumSm && 'w-9 h-9 font-semibold text-sm',
            size === UserLogoSize.medium && 'w-8 h-8 font-semibold text-xs',
            size === UserLogoSize.small && 'w-6 h-6 font-medium text-[10px]',
            color === 'gray' && 'bg-v2blueGray-650',
            color === 'orange' && 'bg-v2orange',
            color === 'blue' && 'bg-v2blue',
            color === 'red' && 'bg-v2red-500',
            color === 'cyan' && 'bg-v2cyan-500',
            color === 'green' && 'bg-v2green-500',
            color === 'pink' && 'bg-v2red-100',
            color === 'violet' && 'bg-v2violet-500',
            color === 'random' && bg,
            !children && 'bg-v2blueGray-200 text-v2blueGray-400',
            className,
          )}
        >
          {children ? children : <AccountCircleFilled width={iconSize} height={iconSize} />}
        </div>
        {status && <AgentStatus status={status} size={size} className={'absolute'} withBorder />}
      </div>
    );
  },
);
