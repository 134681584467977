export function Pending({ ...props }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24" {...props}>
      <g transform="translate(-785 -395)">
        <g transform="translate(518.892 279.01)">
          <path
            d="M298.718,123.194a.8.8,0,0,1-.8.8h0a.8.8,0,0,1-.8-.8v-2.4a.8.8,0,0,1,.8-.8h0a.8.8,0,0,1,.8.8Z"
            transform="translate(-19.81)"
            fill="currentColor"
            opacity="0.5"
          />
          <path
            d="M298.718,168.194a.8.8,0,0,1-.8.8h0a.8.8,0,0,1-.8-.8v-2.4a.8.8,0,0,1,.8-.8h0a.8.8,0,0,1,.8.8Z"
            transform="translate(-19.81 -33.004)"
            fill="currentColor"
          />
          <path
            d="M273.313,147a.8.8,0,0,1,.8.8h0a.8.8,0,0,1-.8.8h-2.4a.8.8,0,0,1-.8-.8h0a.8.8,0,0,1,.8-.8Z"
            transform="translate(0 -19.81)"
            fill="currentColor"
            opacity="0.25"
          />
          <path
            d="M318.313,147a.8.8,0,0,1,.8.8h0a.8.8,0,0,1-.8.8h-2.4a.8.8,0,0,1-.8-.8h0a.8.8,0,0,1,.8-.8Z"
            transform="translate(-33.004 -19.81)"
            fill="currentColor"
            opacity="0.65"
          />
          <path
            d="M276.213,156.106a.8.8,0,0,1,1.093.292h0a.8.8,0,0,1-.292,1.093l-2.082,1.2a.8.8,0,0,1-1.092-.292h0a.8.8,0,0,1,.292-1.092Z"
            transform="translate(-2.656 -26.41)"
            fill="currentColor"
            opacity="0.2"
          />
          <path
            d="M315.178,133.6a.8.8,0,0,1,1.092.292h0a.8.8,0,0,1-.292,1.092l-2.082,1.2a.8.8,0,0,1-1.092-.292h0a.8.8,0,0,1,.292-1.093Z"
            transform="translate(-31.234 -9.9)"
            fill="currentColor"
            opacity="0.6"
          />
          <path
            d="M306.225,163.777a.8.8,0,0,1,.292-1.092h0a.8.8,0,0,1,1.093.293l1.2,2.082a.8.8,0,0,1-.292,1.092h0a.8.8,0,0,1-1.093-.292Z"
            transform="translate(-26.409 -31.235)"
            fill="currentColor"
            opacity="0.8"
          />
          <path
            d="M283.714,124.813a.8.8,0,0,1,.292-1.093h0a.8.8,0,0,1,1.093.292l1.2,2.082a.8.8,0,0,1-.292,1.093h0a.8.8,0,0,1-1.093-.292Z"
            transform="translate(-9.9 -2.657)"
            fill="currentColor"
            opacity="0.45"
          />
          <path
            d="M284.944,162.989a.8.8,0,0,1,1.092-.294h0a.8.8,0,0,1,.294,1.092l-1.2,2.084a.8.8,0,0,1-1.092.294h0a.8.8,0,0,1-.294-1.092Z"
            transform="translate(-9.921 -31.243)"
            fill="currentColor"
            opacity="0.15"
          />
          <path
            d="M307.406,124a.8.8,0,0,1,1.092-.294h0a.8.8,0,0,1,.293,1.092l-1.2,2.084a.8.8,0,0,1-1.092.294h0a.8.8,0,0,1-.294-1.092Z"
            transform="translate(-26.396 -2.645)"
            fill="currentColor"
            opacity="0.55"
          />
          <path
            d="M313.108,157.475a.8.8,0,0,1-.294-1.092h0a.8.8,0,0,1,1.092-.294l2.083,1.2a.8.8,0,0,1,.294,1.092h0a.8.8,0,0,1-1.092.294Z"
            transform="translate(-31.242 -26.398)"
            fill="currentColor"
            opacity="0.7"
          />
          <path
            d="M274.116,135.012a.8.8,0,0,1-.294-1.092h0a.8.8,0,0,1,1.092-.294l2.084,1.2a.8.8,0,0,1,.294,1.092h0a.8.8,0,0,1-1.092.294Z"
            transform="translate(-2.645 -9.923)"
            fill="currentColor"
            opacity="0.35"
          />
        </g>
      </g>
    </svg>
  );
}

<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"></svg>;
