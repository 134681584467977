import { ReactNode } from 'react';

import { twMerge } from 'tailwind-merge';

export type LayerWithSideBarProps = {
  className?: string;
  sideBarClassName?: string;
  sideBarContent: ReactNode;
  children?: ReactNode;
};

const LayerWithSideBar = ({
  className,
  sideBarClassName,
  sideBarContent,
  children,
}: LayerWithSideBarProps) => {
  return (
    <div className={twMerge('flex flex-col md:flex-row bg-white', className)}>
      <div className={twMerge('px-1 py-1', children && 'md:px-0 md:pl-1')}>
        <div
          className={twMerge(
            'bg-v2blueGray-0 w-full h-full max-w-full md:max-w-[472px] rounded-[18px] p-7 flex flex-col justify-between',
            sideBarClassName,
          )}
        >
          {sideBarContent}
        </div>
      </div>

      {children && <div className="p-8 flex-1 flex flex-col">{children}</div>}
    </div>
  );
};

export default LayerWithSideBar;
