export function Analytics({ ...props }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="1em" height="1em" {...props}>
      <g transform="translate(-152 -691)">
        <rect
          id="Rectangle_6411"
          data-name="Rectangle 6411"
          width="24"
          height="24"
          transform="translate(152 691)"
          fill="none"
        />
        <g id="Group_10099" data-name="Group 10099" transform="translate(152 691)">
          <path
            id="Path_38814"
            data-name="Path 38814"
            d="M8.116,16.75a.736.736,0,0,0,.75-.75V11a.75.75,0,1,0-1.5,0v5a.737.737,0,0,0,.75.75Zm3.885,0a.736.736,0,0,0,.75-.75V8a.75.75,0,1,0-1.5,0v8a.737.737,0,0,0,.75.75Zm3.885,0a.736.736,0,0,0,.75-.75V14a.75.75,0,1,0-1.5,0v2a.737.737,0,0,0,.75.75ZM5.308,20.5A1.793,1.793,0,0,1,3.5,18.692V5.308A1.793,1.793,0,0,1,5.308,3.5H18.692A1.793,1.793,0,0,1,20.5,5.308V18.692A1.793,1.793,0,0,1,18.692,20.5Z"
            fill="currentColor"
          />
        </g>
      </g>
    </svg>
  );
}
