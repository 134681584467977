export * from './CustomDomain';
export * from './Desk';
export * from './OrderStatuses';

export const minuteCache = 60 * 1000;
export const fiveMinutesCache = 5 * minuteCache;
export const thirtySecondsCache = 30 * 1000;
export const hourCache = 60 * minuteCache;
export const dayCache = 24 * hourCache;
export const halfDayCache = dayCache / 2;

export const GET_ALL_AGENT_QUERY = `GET_ALL_AGENT_QUERY`;
