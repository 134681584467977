import { gql } from '@apollo/client';

export const GorgiasStartImportMutation = gql`
  mutation Gorgias_startImport($input: ApiKeyImportInput!) {
    success: Gorgias_startImport(input: $input)
  }
`;

export const ZendeskStartImportMutation = gql`
  mutation Zendesk_startImport($input: ApiKeyImportInput!) {
    success: Zendesk_startImport(input: $input)
  }
`;
