import { RenderElementProps } from 'slate-react';

import ImageComponent from './elements/ImageComponent';
import LinkComponent from './elements/LinkComponent';

const RenderElement = (props: RenderElementProps) => {
  const { attributes, children, element } = props;

  switch (element.type) {
    case 'link':
      return <LinkComponent {...props} />;
    case 'image':
      return <ImageComponent {...props} />;
    default:
      return <p {...attributes}>{children}</p>;
  }
};

export default RenderElement;
