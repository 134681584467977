import { CSSProperties, ReactNode, forwardRef } from 'react';

import { Transition } from '@headlessui/react';
import { twMerge } from 'tailwind-merge';

export type TPopupPositions =
  | 'top'
  | 'right'
  | 'bottom'
  | 'left'
  | 'top-start'
  | 'top-end'
  | 'right-start'
  | 'right-end'
  | 'bottom-start'
  | 'bottom-end'
  | 'left-start'
  | 'bottom-center';

export enum DropdownPopupTheme {
  dark = 'dark',
}

export interface IDropdownPopup {
  open: boolean;
  className?: string;
  classNameWrapper?: string;
  children?: ReactNode;
  position?: TPopupPositions;
  style?: CSSProperties;
  theme?: DropdownPopupTheme;
}
const DropdownPopup = forwardRef<HTMLElement, IDropdownPopup>(
  (
    {
      open,
      className,
      children,
      style,
      position = 'bottom-end',
      classNameWrapper,
      theme,
    }: IDropdownPopup,
    ref,
  ) => {
    return (
      <Transition
        show={open}
        ref={ref}
        as="div"
        style={{ borderRadius: '14px', overflow: 'hidden', ...style }}
        leave="transition ease-in duration-100"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
        className={twMerge('shadow-popup', classNameWrapper)}
      >
        <div
          className={twMerge(
            'absolute w-full rounded-[14px] bg-white p-2 text-sm shadow-popup border border-v2blueGray-200 h-fit',
            position === 'bottom-end' && 'left-0 top-full origin-top mt-1',
            position === 'bottom-start' && 'right-0 top-full origin-bottom mt-1 w-full',
            position === 'top-start' && 'right-0 bottom-full origin-bottom mb-1 w-full',
            position === 'bottom-center' && 'left-1/2 top-full origin-top mt-1 -translate-x-1/2',
            open && 'z-[2]',
            theme === DropdownPopupTheme.dark &&
              'bg-v2blueGray-800 border-v2blueGray-700 dropdown-dark',
            className,
          )}
        >
          {children}
        </div>
      </Transition>
    );
  },
);

export { DropdownPopup };
