import { gql, useQuery } from '@apollo/client';
import { QueryHookOptions } from '@apollo/client/react/types/types';
import { TicketSupportStatuses } from 'app-types/desk';

export const COUNT_SUPPORT_TICKETS_QUERY = gql`
  query Ticket_CountByClientStore($status: TicketSupportStatuses!) {
    tickets: Ticket_CountByClientStore(status: $status)
  }
`;

type TData = { tickets: number };
type TVariables = { status: TicketSupportStatuses };

export function useCountSupportTickets(
  status: TicketSupportStatuses,
  options?: QueryHookOptions<TData, TVariables>,
) {
  return useQuery<TData, TVariables>(COUNT_SUPPORT_TICKETS_QUERY, {
    ...options,
    variables: {
      status,
    },
    context: { ...options?.context, clientName: 'desk' },
  });
}
