type AnyObject = Record<string, any>;

const get = <T extends AnyObject, K extends keyof T>(
  obj: T,
  path: string,
  defaultValue?: T[K],
): T[K] | undefined => {
  const travel = (regexp: RegExp) =>
    String.prototype.split
      .call(path, regexp)
      .filter(Boolean)
      .reduce((res: any, key: string) => (res !== null && res !== undefined ? res[key] : res), obj);
  const result = travel(/[,[\]]+?/) || travel(/[,[\].]+?/);
  return result === undefined || result === obj ? defaultValue : result;
};

export { get };
