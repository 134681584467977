import React, { FC } from 'react';

import { FieldProps } from 'formik';

interface RatingInput extends FieldProps {
  emoji: string | React.ReactNode;
  val: number;
  selectedColor: string;
}

const RatingInput: FC<RatingInput> = (props) => {
  const {
    field: { name },
    emoji,
    val,
    selectedColor,
    form: {
      values: { rating },
      setFieldValue,
    },
  } = props;

  return (
    <button
      style={{ backgroundColor: rating === val ? selectedColor : '' }}
      type="button"
      className={`flex justify-center items-center transition-all w-10 h-10 duration-100 cursor-pointer text-[32px] hover:text-[38px] rounded-md`}
      onClick={() => setFieldValue(name, val)}
    >
      <span>{emoji}</span>
    </button>
  );
};

export default RatingInput;
