import { useQuery } from '@tanstack/react-query';
import { DAY_CACHE } from 'ui';

import { getCourierIcons } from '../../../utils/api';

export function useCourierIcons(enabled = true) {
  return useQuery(['getCourierIcons'], getCourierIcons, {
    cacheTime: DAY_CACHE,
    staleTime: DAY_CACHE,
    enabled,
  });
}
