import { ExpandMore } from 'icons';
import { twMerge } from 'tailwind-merge';

interface IDropdownExpandMoreIcon {
  open?: boolean;
  reversed?: boolean;
}

export const DropdownExpandMoreIcon = ({ open, reversed }: IDropdownExpandMoreIcon) => {
  return (
    <div
      className={twMerge(
        'flex flex-shrink-0 justify-center items-center pointer-events-none w-6 h-6',
        'transform transition-transform duration-300',
        (reversed || open) && 'rotate-180',
        reversed && open && 'rotate-0',
      )}
    >
      <ExpandMore className="w-5 h-5 text-v2blueGray-550" />
    </div>
  );
};
