import { gql } from '@apollo/client';

export const CurrentSubscriptionQuery = gql`
  query Subscription_current {
    Subscription_current {
      plan
      isConquer
      isActive
      features {
        canSeePickingList
        skipAnalytics
      }
      isTrial
    }
  }
`;

export const WelcomeInfoQuery = gql`
  query Welcome_getInfo {
    User_getProfile {
      firstname
      lastname
      id
    }
    Store_current {
      company {
        name
        address
        city
        postcode
        countryCode
        contactPhone
        contactEmail
      }
      promoCode
      id
    }
    Brand_getDefault {
      brandUrl
      id
    }
  }
`;

export const WelcomeStatisticsQuery = gql`
  query Welcome_getStatistics {
    Store_current {
      statistics {
        shipmentsPerMonth
        whatCanWeDo
        clientSource
        partnerName
      }
      id
    }
  }
`;

export const BrandInfoGetDefaultQuery = gql`
  query Brand_getDefault {
    Brand_getDefault {
      id
      logo
      brandUrl
    }
  }
`;

export const BrandInfoGetByIdQuery = gql`
  query Brand_getById($brandId: String!) {
    brand: Brand_getById(brandId: $brandId) {
      id
      logo
      brandUrl
    }
  }
`;

export const StoreByIdQuery = gql`
  query Store_findOneById($storeId: String!) {
    store: Store_findOneById(id: $storeId) {
      id
      clientCode
    }
  }
`;

export const CompanyInfoQuery = gql`
  query Company {
    Store_current {
      company {
        name
        url
        address
        postcode
        city
        country
        countryCode
        vat
        registrationNumber
        contactEmail
        contactPhone
        supportEmail
      }
      id
    }
  }
`;

export const CurrentStoreQuery = gql`
  query Store_current {
    Store_current {
      id
      currency
      is3pl
      clientCode
      invoiceCheckingEnabled
      apiKey
      hasPendingOnboarding
      approved
      onboardingSteps {
        completed
        step
      }
      company {
        name
        city
        countryCode
      }
      statistics {
        shipmentsPerMonth
        whatCanWeDo
        clientSource
        partnerName
      }
    }
  }
`;

export const FulfillmentStatisticsQuery = gql`
  query Statistics_fulfillment($cache: Boolean) {
    Statistics_fulfillment(cache: $cache) {
      receivedOrders
      totalSales
      processedOrders
      averageCartValue
      returnOrders
      exchangeOrders
      returnRate
    }
  }
`;

export const IntegrationsV2Query = gql`
  query Integration_availableIntegrationsList {
    Integration_availableIntegrationsList {
      plugin
      name
      logo
      integration
      featured
      docs {
        de
        en
        es
        pl
        pt
      }
      isMock
      url
      isThirdParty
    }
  }
`;

export const CountProcessedOrdersPerCourierQuery = gql`
  query Statistics_countProcessedOrdersPerCourier($limit: Int, $cache: Boolean) {
    statistics: Statistics_countProcessedOrdersPerCourier(limit: $limit, cache: $cache) {
      courier
      percentage
    }
  }
`;

export const CourierTypeGetAllQuery = gql`
  query CourierType_getAll {
    couriers: CourierType_getAll {
      id
      name
      integration
      smallLogo
      alias
    }
  }
`;

export const CountCustomerMailNotificationsQuery = gql`
  query Statistics_countCustomerMailNotifications($input: CustomerMailNotificationsKpiInput) {
    statistics: Statistics_countCustomerMailNotifications(input: $input) {
      totalSent
      openRate
      failedRate
    }
  }
`;

export const CountOrdersInTransitAndIncidentsQuery = gql`
  query Statistics_countOrdersInTransitAndIncidents($cache: Boolean) {
    statistics: Statistics_countOrdersInTransitAndIncidents(cache: $cache) {
      ordersInTransit
      incidents
    }
  }
`;

export const CurrentUserProfileQuery = gql`
  query User_getProfile {
    user: User_getProfile {
      firstname
      lastname
      email
      roles
      language
      signatures {
        language
        signature
      }
      emailOnlySignature
      id
    }
  }
`;

export const PickupInfoQuerry = gql`
  query Pickup_info($otn: String) {
    Pickup_info(otn: $otn) {
      id
      isReturn
      courier_id
      courier
      status
      requestedDate
      shipmentReference
      warehouse
      code
      createdAt
      updatedAt
      failed
      rejectDate
      returnInfo {
        name
        postcode
        country
        countryCode
        state
        city
        address
        contactPhone
        contactName
      }
    }
  }
`;
