import { ReactNode } from 'react';

import { twMerge } from 'tailwind-merge';

import './DashboardCard.css';

export interface DashboardCardProps {
  head?: ReactNode;
  children?: ReactNode;
  className?: string;
  headClass?: string;
  theme?: DashboardCardTheme;
  isSubTheme?: boolean;
}

export enum DashboardCardTheme {
  gray = 'gray',
  black = 'black',
  violet = 'violet',
  green = 'green',
  orange = 'orange',
  blue = 'blue',
}

/* @apply flex-1; */
export function DashboardCard({
  head,
  children,
  className,
  headClass,
  theme = DashboardCardTheme.gray,
  isSubTheme,
}: DashboardCardProps) {
  return (
    <div className={twMerge('db-card', isSubTheme && 'sub', theme, className)}>
      <div className={twMerge('db-card-content', headClass)}>{head}</div>
      {children ? <div className="px-8 py-6">{children}</div> : null}
    </div>
  );
}
