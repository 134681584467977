import { memo } from 'react';

import ContentLoader, { IContentLoaderProps } from 'react-content-loader';

type ValueLoaderProps = {
  width?: number;
  height?: number;
} & IContentLoaderProps;

export const ValueLoader = memo(
  ({ width = 100, height = 40, rx = 7, ...props }: ValueLoaderProps) => {
    return (
      <ContentLoader
        speed={1}
        viewBox={`0 0 ${width} ${height}`}
        backgroundColor="#DAE2E6"
        foregroundColor="#EEF3F5"
        width={width}
        height={height}
        {...props}
      >
        <rect rx={rx} width={width} height={height} />
      </ContentLoader>
    );
  },
);
