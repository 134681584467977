export default {
  'orders.package': 'Pakuotė',
  'orders.returns.reasons.size': 'Netinka dydis',
  'orders.returns.reasons.wrong-product': 'Nusiųstas netinkamas produktas',
  'orders.returns.reasons.wrong-size': 'Nusiųstas netinkamas dydis',
  'orders.returns.reasons.wrong-colour': 'Netinkama spalva',
  'orders.returns.reasons.package-broken': 'Pažeista produkto pakuotė',
  'orders.returns.reasons.product-broken': 'Produktas sugedęs/pažeistas',
  'orders.returns.reasons.product-used': 'Produktas panaudotas',
  'orders.returns.reasons.product-dirty': 'Produktas nešvarus',
  'orders.returns.reasons.do-not-want': 'Nebenoriu šio produkto',
  'orders.returns.reasons.exchange': 'Norėčiau pakeisti produktą',
  'orders.returns.reasons.other': 'Kita',
  'orders.returns.pickup.form.comments': 'Komentarai',
  'orders.list.empty': 'Šiuo metu neturite užsakymų',
  'orders.total': 'Viso',
  'orders.vat': 'PVM',
  'or.confirm-address': 'PATVIRTINKITE ATSIĖMIMO ADRESĄ',
  'or.from': 'Nuo',
  'or.to': 'Iki',
  'or.return.list.empty': 'Šiuo metu neturite grąžinamų prekių',
  'or.confirm-return': 'PATVIRTINKITE GRĄŽINIMĄ',
};
