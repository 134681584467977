import { useContext } from 'react';

import { SubscriptionPlan } from 'app-types/core';
import {
  AccountCircleLandscape,
  Analytics,
  CmsFeaturesIntegrationIcon,
  FulfillmentOnboardingIcon,
  LocalShipping,
  LocationOn,
  ReturnsOnboardingIcon,
} from 'icons';
import { FormattedMessage } from 'localization';
import { Copy, Heading } from 'ui/atoms';

import { SubscriptionContext } from '../../hooks/useSubscriptionContext';
import lostFeatures, { LostFeatureObjKey } from './lostFeaturesOptions';

type LostFeaturesListProps = {
  currentPlan: SubscriptionPlan;
  targetPlan: SubscriptionPlan | undefined;
};

const LostFeaturesList = ({ targetPlan, currentPlan }: LostFeaturesListProps) => {
  const contextData = useContext(SubscriptionContext);

  if (!contextData?.plans?.length) {
    return <></>;
  }

  const lostFeaturesObject = lostFeatures(currentPlan, targetPlan, contextData.plans || []);

  const featureKeys = Object.keys(lostFeaturesObject) as Array<keyof typeof lostFeaturesObject>;

  return (
    <div className="flex flex-col gap-2">
      {featureKeys.map((featureKey) => {
        if (
          typeof lostFeaturesObject[featureKey] === 'undefined' ||
          lostFeaturesObject[featureKey].length === 0
        ) {
          return null;
        }

        return (
          <div
            className="border border-v2blueGray-200 rounded-[14px] px-6 pt-6 pb-8 flex gap-4"
            key={featureKey}
          >
            <div
              className="w-8 h-8 rounded-[10px] bg-v2blueGray-100 
            flex items-center justify-center text-v2blueGray-700 text-[24px]"
            >
              {icon[featureKey]}
            </div>
            <div className="flex-1">
              <Heading type="heading4" weight="medium" className="mb-4">
                <FormattedMessage id={`settings.subs.dowgrade.lf.heading.${featureKey}`} />
              </Heading>

              <div className="flex flex-col gap-[7px] text-v2blueGray-550">
                {lostFeaturesObject[featureKey].map((feature) => (
                  <Copy as="span" noColor className="flex gap-2 items-start" key={feature}>
                    <div className="font-bold text-xs mt-[3px]">·</div>
                    <FormattedMessage id={`settings.subs.dowgrade.lf.entry.${feature}`} />
                  </Copy>
                ))}
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

const icon: Record<LostFeatureObjKey, JSX.Element> = {
  stats: <Analytics />,
  branding: <LocationOn />,
  returns: <ReturnsOnboardingIcon />,
  cms: <CmsFeaturesIntegrationIcon />,
  couriers: <LocalShipping />,
  misc: <AccountCircleLandscape />,
  orders: <FulfillmentOnboardingIcon />,
};

export default LostFeaturesList;
