import { useCallback, useRef, useState } from 'react';

import { flip, offset, shift, useFloating } from '@floating-ui/react-dom';
import { Emoji } from 'icons';
import { Range, Transforms } from 'slate';
import { ReactEditor, useSlate } from 'slate-react';
import { twMerge } from 'tailwind-merge';
import { EmojiPicker, Tooltip } from 'ui';
import { IconButton } from 'ui/atoms';
import useClickAway from 'ui/src/hooks/useClickAway';

const MarkButtonEmojiPicker = ({
  title,
  withTitle,
  onClose,
}: {
  title: string;
  withTitle?: boolean;
  onClose?: () => void;
}) => {
  const [isEmojiOpen, setIsEmojiOpen] = useState<boolean>(false);

  const { refs, floatingStyles } = useFloating({
    placement: 'top-start',
    middleware: [offset(4), flip(), shift()],
  });
  const editor = useSlate();
  const emojiRef = useRef<HTMLDivElement | null>(null);

  const toggleEmojiPicker = useCallback(() => {
    setIsEmojiOpen((prevState) => !prevState);
  }, [setIsEmojiOpen, onClose]);

  const onEmojiClick = useCallback(
    (emoji: string) => {
      const { selection } = editor;

      if (selection && Range.isCollapsed(selection)) {
        Transforms.insertText(editor, emoji);

        // Move the cursor to the end of the newly inserted text
        Transforms.move(editor, { distance: 0, unit: 'character' });
        // Refocus the editor
        ReactEditor.focus(editor);
      }

      if (onClose) {
        onClose();
      }

      setIsEmojiOpen(false);
    },
    [editor],
  );

  useClickAway(emojiRef, () => {
    if (isEmojiOpen) {
      setIsEmojiOpen(false);
    }
  });

  return (
    <div
      ref={(currentRef) => {
        emojiRef.current = currentRef;
        refs.setReference(currentRef);
      }}
    >
      <Tooltip title={withTitle || isEmojiOpen ? '' : title} placement="top">
        <div className="relative">
          <IconButton
            data-testid="outvioui--popup-display-emoji-picker-button"
            className={twMerge(
              'flex items-center h-9 w-full hover:bg-transparent active:bg-v2blueGray-100',
              !withTitle && 'justify-center w-9',
              withTitle &&
                'justify-start px-2 gap-2 active:bg-v2blueGray-100 hover:bg-v2blueGray-100',
              isEmojiOpen && 'bg-v2blueGray-100 hover:bg-v2blueGray-100',
            )}
            onMouseDown={(e) => {
              e.preventDefault();
              toggleEmojiPicker();
            }}
          >
            <Emoji width="24" height="24" />
            {withTitle && <span className="capitalize">{title}</span>}
          </IconButton>
        </div>
      </Tooltip>
      {isEmojiOpen && (
        <EmojiPicker
          className="static h-[300px]"
          ref={refs.setFloating}
          style={{ ...floatingStyles, position: withTitle ? 'absolute' : 'fixed' }}
          onEmojiClick={onEmojiClick}
        />
      )}
    </div>
  );
};

export default MarkButtonEmojiPicker;
