export function ThreeDotsIconShortcut({ ...props }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="1em" height="1em" {...props}>
      <g id="Icon" transform="translate(-395 809) rotate(-90)">
        <rect
          id="Rectangle_6402"
          data-name="Rectangle 6402"
          width="24"
          height="24"
          transform="translate(785 395)"
          fill="none"
        />
        <g id="Group_9100" data-name="Group 9100" transform="translate(785 394.5)">
          <path
            id="Path_30482"
            data-name="Path 30482"
            d="M6.231,14A1.5,1.5,0,1,1,7.29,11.441,1.5,1.5,0,0,1,6.231,14ZM12,14a1.5,1.5,0,1,1,1.059-.441A1.444,1.444,0,0,1,12,14Zm5.769,0a1.5,1.5,0,1,1,1.059-.441A1.444,1.444,0,0,1,17.769,14Z"
            fill="#849ead"
          />
        </g>
      </g>
    </svg>
  );
}
