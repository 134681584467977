import { ReactNode } from 'react';

import { UserRole } from 'app-types/core';
import {
  Analytics,
  Bell,
  CourierSupport,
  Dashboard,
  DeskIcon,
  ExchangeV2,
  HelpFilled,
  History,
  Notifications,
  Orders,
  PickingList,
  Shipping,
  Tracking,
} from 'icons';

export enum NavbarMenuGroup {
  shipping = 'shipping',
  postShipping = 'postShipping',
  secondaryMenu = 'secondaryMenu',
  mobile = 'mobile',
}

export interface NavbarMenuItemData {
  id: MenuPages;
  to: string;
  icon: ReactNode;
  roles: UserRole[];
  langKey: string;
  groups: NavbarMenuGroup[];
}

export const v2Path = '/v2';

export enum MenuPages {
  orders = 'orders',
  picking = 'picking',
  shipping = 'shipping',
  tracking = 'tracking',
  returns = 'returns',
  history = 'history',
  courierSupport = 'courierSupport',
  emailsSent = 'emailsSent',
  desk = 'desk',
  alerts = 'alerts',
  dashboard = 'dashboard',
  analytics = 'analytics',
  userSupport = 'userSupport',
}

const menu: NavbarMenuItemData[] = [
  {
    id: MenuPages.orders,
    to: '/cms/orders',
    icon: <Orders />,
    roles: Object.values(UserRole),
    langKey: 'general.orders',
    groups: [NavbarMenuGroup.shipping],
  },
  {
    id: MenuPages.picking,
    to: '/cms/picking-list',
    icon: <PickingList />,
    roles: [UserRole.Owner, UserRole.Administrator, UserRole.Manager, UserRole.Warehouse],
    langKey: 'general.picking-list',
    groups: [NavbarMenuGroup.shipping],
  },
  {
    id: MenuPages.shipping,
    to: '/cms/shipping-queue',
    icon: <Shipping />,
    roles: [UserRole.Owner, UserRole.Administrator, UserRole.Manager, UserRole.Warehouse],
    langKey: 'general.shipping-queue',
    groups: [NavbarMenuGroup.shipping],
  },
  {
    id: MenuPages.tracking,
    to: '/cms/tracking',
    icon: <Tracking />,
    roles: Object.values(UserRole),
    langKey: 'general.tracking',
    groups: [NavbarMenuGroup.postShipping],
  },
  {
    id: MenuPages.returns,
    to: '/cms/returns',
    icon: <ExchangeV2 />,
    roles: Object.values(UserRole),
    langKey: 'general.returns',
    groups: [NavbarMenuGroup.postShipping],
  },
  {
    id: MenuPages.history,
    to: '/cms/history',
    icon: <History />,
    roles: Object.values(UserRole),
    langKey: 'general.history',
    groups: [NavbarMenuGroup.postShipping],
  },
  {
    id: MenuPages.courierSupport,
    to: '/cms/courier-support',
    icon: <CourierSupport />,
    roles: [UserRole.Owner, UserRole.Administrator, UserRole.Manager, UserRole.CustomerSupport],
    langKey: 'general.courierSupport',
    groups: [NavbarMenuGroup.postShipping],
  },
  {
    id: MenuPages.emailsSent,
    to: '/cms/emails/sent',
    icon: <Notifications />,
    roles: [UserRole.Owner, UserRole.Administrator, UserRole.Manager, UserRole.CustomerSupport],
    langKey: 'general.sentEmails',
    groups: [NavbarMenuGroup.postShipping],
  },
  {
    id: MenuPages.desk,
    to: `${v2Path}/desk`,
    icon: <DeskIcon />,
    roles: [UserRole.Owner, UserRole.Administrator, UserRole.Manager, UserRole.CustomerSupport],
    langKey: 'general.outvioDesk',
    groups: [NavbarMenuGroup.postShipping],
  },
  {
    id: MenuPages.alerts,
    to: '/cms/notifications',
    icon: <Bell />,
    roles: [UserRole.Owner, UserRole.Administrator, UserRole.Manager],
    langKey: 'general.alerts',
    groups: [NavbarMenuGroup.mobile],
  },
  {
    id: MenuPages.dashboard,
    to: `${v2Path}/dashboard`,
    icon: <Dashboard />,
    roles: [UserRole.Owner, UserRole.Administrator, UserRole.Manager, UserRole.Warehouse],
    langKey: 'general.dashboard',
    groups: [NavbarMenuGroup.mobile],
  },
  {
    id: MenuPages.analytics,
    to: '/cms/analytics',
    icon: <Analytics />,
    roles: [UserRole.Owner, UserRole.Administrator, UserRole.Manager],
    langKey: 'general.analytics',
    groups: [NavbarMenuGroup.secondaryMenu],
  },
  {
    id: MenuPages.userSupport,
    to: '/cms/user-support',
    icon: <HelpFilled />,
    roles: Object.values(UserRole),
    langKey: 'general.supportCenter',
    groups: [NavbarMenuGroup.mobile],
  },
];

export interface NavbarDataByUserResponse {
  getByGroup: (menuGroup: NavbarMenuGroup[]) => NavbarMenuItemData[];
  getById: (pageId: MenuPages) => NavbarMenuItemData | undefined;
}

export function getNavbarDataByUser({
  roles = [],
}: {
  roles?: UserRole[];
  isMobile?: boolean;
}): NavbarDataByUserResponse {
  if (!roles.length) {
    return {
      getByGroup: () => [],
      getById: () => undefined,
    };
  }

  const _menu = menu.filter((item) => {
    const res = item.roles.some((role) => roles.includes(role));

    return res;
  });

  return {
    getByGroup: (menuGroup: NavbarMenuGroup[]) => {
      return _menu.filter((item) => item.groups.some((g) => menuGroup.includes(g)));
    },
    getById: (id) => {
      return _menu.find((item) => item.id === id);
    },
  };
}
