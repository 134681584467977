export default {
  'custLanding.password.title': 'Esqueceste-te da palavra-passe?',
  'custLanding.signup.btn': 'Criar conta',
  'custLanding.login.btn': 'Entrar',
  'custLanding.password.btn': 'Redefinir palavra-passe',
  'custLanding.switch-to-login': 'Já tenho conta',
  'custLanding.switch-to-signup': 'Registar-me',
  'custLanding.auth.text.password':
    'Insere o teu endereço de email para receberes as instruções de redefinição da palavra-passe.',
  'custLanding.form.password': 'Palavra-passe',
  'custLanding.forgot-password': 'Esqueceste-te da palavra-passe?',
  'custLanding.forgot-password.success':
    'Vais receber as instruções para redefinires a palavra-passe brevemente!',
  'custLanding.let-get-started': 'Vamos começar!',
  'custLanding.already-have-an-account': 'Já tens uma conta?',
  'custLanding.just-remembered': 'Lembraste-te da tua palavra-passe?',
  'custlanding.login.error.no-order': 'Nenhuma encomenda encontrada para este endereço de email',
};
