export function Tracking({ ...props }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="1em" height="1em" {...props}>
      <g transform="translate(-152 -691)">
        <rect
          id="Rectangle_6411"
          data-name="Rectangle 6411"
          width="24"
          height="24"
          transform="translate(152 691)"
          fill="none"
        />
        <path
          id="Path_38809"
          data-name="Path 38809"
          d="M12,20.94a1.781,1.781,0,0,1-.585-.1,1.542,1.542,0,0,1-.529-.312q-1.346-1.24-2.519-2.555a22.9,22.9,0,0,1-2.039-2.628,14.03,14.03,0,0,1-1.371-2.6,6.826,6.826,0,0,1-.5-2.492A7.489,7.489,0,0,1,12,2.5a7.489,7.489,0,0,1,7.548,7.748,6.79,6.79,0,0,1-.5,2.488,14.4,14.4,0,0,1-1.366,2.6,21.949,21.949,0,0,1-2.035,2.628q-1.173,1.31-2.519,2.55a1.561,1.561,0,0,1-.53.317A1.757,1.757,0,0,1,12,20.94Zm0-9.075A1.806,1.806,0,0,0,13.276,8.78a1.806,1.806,0,1,0-2.552,2.556A1.745,1.745,0,0,0,12,11.865Z"
          transform="translate(152 691.281)"
          fill="currentColor"
        />
      </g>
    </svg>
  );
}
