export function CvcBackCardIcon({ ...props }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24" {...props}>
      <g id="Group_17452" data-name="Group 17452" transform="translate(-150 -689)">
        <rect
          id="Rectangle_6411"
          data-name="Rectangle 6411"
          width="24"
          height="24"
          transform="translate(150 689)"
          fill="none"
        />
        <g id="Group_15414" data-name="Group 15414" transform="translate(0.586)">
          <rect
            id="Rectangle_10961"
            data-name="Rectangle 10961"
            width="20"
            height="14"
            rx="2"
            transform="translate(151.414 694)"
            fill="#9db3bf"
            opacity="0.4"
          />
          <rect
            id="Rectangle_10962"
            data-name="Rectangle 10962"
            width="20"
            height="4"
            transform="translate(151.414 697)"
            fill="#849ead"
          />
          <g
            id="Rectangle_10963"
            data-name="Rectangle 10963"
            transform="translate(164.414 702)"
            fill="#f6f8f9"
            stroke="#ec6666"
            strokeWidth="1"
          >
            <rect width="5" height="3" rx="1.5" stroke="none" />
            <rect x="0.5" y="0.5" width="4" height="2" rx="1" fill="none" />
          </g>
        </g>
      </g>
    </svg>
  );
}
