import { Dispatch } from 'react';

import get from 'lodash-es/get';

import { AnyAction, PayloadAction, createSlice } from '@reduxjs/toolkit';
import { OVLocalCache } from 'return-portal-ui';
import { EMPTY_STORE_DATA } from 'return-portal-ui';
import { CustomerStoreData } from 'return-portal-ui/old-ui/types/CustomerStoreData';

import { requestStoreInfo } from '../utils/api/apiTracking';
import { requestUser } from '../utils/api/apiUser';
import { userLoaded } from './user';

const v2DefaultColors = {
  primaryText: '#00363D',
  primaryBg: '#FFFFFF',
  secondaryBg: '#FFFFFF',
  btnText: '#FFFFFF',
  btnBg: '#4BB4B3',
  alertColor: '#FF9800',

  // Map related
  mapBase: '#E0F2F1',
  mapPath: '#C6DCDD',
  mapText: '#00363D',
  mapPin: '#4BB4B3', // new, TODO: define this color
};

const appInitialState: {
  isInitialized: boolean;
  error: null | string;
  storeData: CustomerStoreData;
} = {
  isInitialized: false,
  error: null,
  storeData: { ...EMPTY_STORE_DATA },
};

const fillPotentialMissingData = (storeData: CustomerStoreData) => {
  const colors = storeData.portalSettings.colors;
  const newData = {
    ...storeData,
    portalSettings: {
      ...storeData.portalSettings,
      colors: {
        ...storeData.portalSettings.colors,
        primaryText: get(colors, 'primaryText', v2DefaultColors.primaryText),
        primaryBg: get(colors, 'primaryBg', get(colors, 'pageBg') || v2DefaultColors.primaryBg),
        secondaryBg: get(
          colors,
          'secondaryBg',
          get(colors, 'qnaBg') || v2DefaultColors.secondaryBg,
        ),
        btnText: get(colors, 'btnText', get(colors, 'loginBg') || v2DefaultColors.btnText),
        btnBg: get(colors, 'btnBg', get(colors, 'loginBtn') || v2DefaultColors.btnBg),
        alertColor: get(
          colors,
          'alertColor',
          get(colors, 'mapBarWarning') || v2DefaultColors.alertColor,
        ),
        mapBase: get(colors, 'mapBase', get(colors, 'mapBase') || v2DefaultColors.mapBase),
        mapPath: get(colors, 'mapPath', get(colors, 'mapPath') || v2DefaultColors.mapPath),
        mapText: get(colors, 'mapText', get(colors, 'mapText') || v2DefaultColors.mapText),
        mapPin: get(colors, 'mapPin', get(colors, 'mapPin') || v2DefaultColors.mapPin),
      },
      desktopBg:
        storeData.portalSettings.desktopBg ||
        'https://outvio-prod-public.s3.eu-central-1.amazonaws.com/img/bg_returns_desktop.jpg',
      mobileBg:
        storeData.portalSettings.mobileBg ||
        'https://outvio-prod-public.s3.eu-central-1.amazonaws.com/img/bg_returns_desktop.jpg',
      trackingDesktopBg:
        storeData.portalSettings.trackingDesktopBg ||
        'https://outvio-prod-public.s3.eu-central-1.amazonaws.com/img/bg_tracking_desktop.jpg',
      trackingMobileBg:
        storeData.portalSettings.trackingMobileBg ||
        'https://outvio-prod-public.s3.eu-central-1.amazonaws.com/img/bg_tracking_desktop.jpg',
    },
    customer: {
      ...(storeData.customer
        ? storeData.customer
        : { orderVerification: false, identityVerification: true }),
    },
  };

  return newData;
};

const appSlice = createSlice({
  name: 'app',
  initialState: appInitialState,
  reducers: {
    appInitSuccess(state) {
      state.isInitialized = true;
    },
    appInitError(state, { payload }: PayloadAction<string>) {
      state.error = payload;
    },
    storeDataLoaded(state, { payload }: PayloadAction<any>) {
      state.storeData = fillPotentialMissingData(payload);
    },
  },
});

export const { appInitSuccess, appInitError, storeDataLoaded } = appSlice.actions;

export const initApp = () => async (dispatch: Dispatch<AnyAction>) => {
  try {
    // Include otn in request for tracking pages
    const { pathname } = window.location;
    const split = pathname.split('/');

    const storeRes = await requestStoreInfo(
      pathname.includes('id') ? { otn: split[split.length - 1] } : {},
    );

    await OVLocalCache.purgeExpired();

    dispatch(storeDataLoaded(storeRes.data));

    const token = await OVLocalCache.get('token');

    let userRes = null;
    if (token) {
      try {
        userRes = await requestUser();
      } catch (err) {
        console.log(err);
      }
    }

    if (userRes) {
      dispatch(userLoaded(userRes.user));
    }
    dispatch(appInitSuccess());
  } catch (err: any) {
    console.log(err);
    dispatch(appInitError(err.message || err.toString()));
  }
};

export default appSlice.reducer;
