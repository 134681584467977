import { SubscriptionPlanDescriptor } from 'app-types';
import { SubscriptionPlan } from 'app-types/core';

export type LostFeatureObjKey =
  | 'orders'
  | 'cms'
  | 'couriers'
  | 'branding'
  | 'returns'
  | 'stats'
  | 'misc';

const lostFeaturesOptions = (
  currentPlan: SubscriptionPlan,
  targetPlan: SubscriptionPlan | undefined,
  plans: SubscriptionPlanDescriptor[],
) => {
  const finalObject: Record<LostFeatureObjKey, string[]> = {
    orders: [],
    cms: [],
    couriers: [],
    branding: [],
    returns: [],
    stats: [],
    misc: [],
  };
  const targetPlanName = targetPlan || SubscriptionPlan.Starter;

  const currentPlanFeatures = plans.find(
    (plan) => plan.name === currentPlan,
  ) as SubscriptionPlanDescriptor;
  const targetPlanFeatures = plans.find(
    (plan) => plan.name === targetPlanName,
  ) as SubscriptionPlanDescriptor;

  const compareAndPush = (
    current: any,
    target: any,
    feature: string,
    category: LostFeatureObjKey,
  ) => {
    if (current !== target) {
      finalObject[category].push(feature);
    }
  };

  // stats: analytics and dashboard
  compareAndPush(
    currentPlanFeatures.features.skipAnalytics,
    targetPlanFeatures.features.skipAnalytics,
    'analytics',
    'stats',
  );

  if (targetPlanName === SubscriptionPlan.Launch || targetPlanName === SubscriptionPlan.Starter) {
    finalObject.stats.push('dashboard-recent-notifications', 'dashboard-basic-kpis');
  }
  if (targetPlanName === SubscriptionPlan.Starter) {
    finalObject.stats.push('dashboard-map');
  }

  // returns
  compareAndPush(
    currentPlanFeatures.features.customizeReturns,
    targetPlanFeatures.features.customizeReturns,
    'custom-return-reasons',
    'returns',
  );
  compareAndPush(
    currentPlanFeatures.features.customerPortal.available,
    targetPlanFeatures.features.customerPortal.available,
    'handle-returns',
    'returns',
  );
  compareAndPush(
    currentPlanFeatures.features.customerPortal.available,
    targetPlanFeatures.features.customerPortal.available,
    'advanced-return-settings',
    'returns',
  );

  // Customer portal
  compareAndPush(
    currentPlanFeatures.features.customerPortal.available,
    targetPlanFeatures.features.customerPortal.available,
    'return-portal',
    'branding',
  );
  compareAndPush(
    currentPlanFeatures.features.customerPortal.banner,
    targetPlanFeatures.features.customerPortal.banner,
    'return-portal-banner',
    'branding',
  );
  compareAndPush(
    currentPlanFeatures.features.customerPortal.customisable,
    targetPlanFeatures.features.customerPortal.customisable,
    'return-portal-custom',
    'branding',
  );
  compareAndPush(
    currentPlanFeatures.features.customerPortal.watermark,
    targetPlanFeatures.features.customerPortal.watermark,
    'watermark',
    'branding',
  );
  compareAndPush(
    currentPlanFeatures.features.customerPortal.customTexts,
    targetPlanFeatures.features.customerPortal.customTexts,
    'return-portal-custom-texts',
    'branding',
  );
  compareAndPush(
    currentPlanFeatures.features.multibranding,
    targetPlanFeatures.features.multibranding,
    'multibranding',
    'branding',
  );
  compareAndPush(
    currentPlanFeatures.features.customerPortal.languages,
    targetPlanFeatures.features.customerPortal.languages,
    'languages',
    'branding',
  );
  compareAndPush(
    currentPlanFeatures.features.customDomains,
    targetPlanFeatures.features.customDomains,
    'custom-domains',
    'branding',
  );
  compareAndPush(
    currentPlanFeatures.features.mailSettings.editable,
    targetPlanFeatures.features.mailSettings.editable,
    'emails-editable',
    'branding',
  );

  // CMS integrations
  compareAndPush(
    currentPlanFeatures.features.numberAvailableIntegration,
    targetPlanFeatures.features.numberAvailableIntegration,
    'number-of-cms',
    'cms',
  );
  compareAndPush(
    currentPlanFeatures.features.canCreateManyIntegrationPerCMS,
    targetPlanFeatures.features.canCreateManyIntegrationPerCMS,
    'multiple-same-cms',
    'cms',
  );
  compareAndPush(
    currentPlanFeatures.features.useMapInIntegration,
    targetPlanFeatures.features.useMapInIntegration,
    'map-in-integration',
    'cms',
  );

  // Couriers
  compareAndPush(
    currentPlanFeatures.features.numberCustomCouriers,
    targetPlanFeatures.features.numberCustomCouriers,
    'num-of-couriers',
    'couriers',
  );
  compareAndPush(
    currentPlanFeatures.features.multiCouriersAllowed,
    targetPlanFeatures.features.multiCouriersAllowed,
    'multi-courier',
    'couriers',
  );
  compareAndPush(
    currentPlanFeatures.features.internalCourier,
    targetPlanFeatures.features.internalCourier,
    'internal-courier',
    'couriers',
  );

  // Misc
  compareAndPush(
    currentPlanFeatures.features.multiUsers,
    targetPlanFeatures.features.multiUsers,
    'multi-users',
    'misc',
  );
  compareAndPush(
    currentPlanFeatures.features.ordersExport,
    targetPlanFeatures.features.ordersExport,
    'orders-export',
    'misc',
  );
  compareAndPush(
    currentPlanFeatures.features.courierDirectMessaging,
    targetPlanFeatures.features.courierDirectMessaging,
    'courier-support-messaging',
    'misc',
  );

  // orders and shipping
  compareAndPush(
    currentPlanFeatures.features.warehouses,
    targetPlanFeatures.features.warehouses,
    'warehouses',
    'orders',
  );
  compareAndPush(
    currentPlanFeatures.features.trackingNotifications,
    targetPlanFeatures.features.trackingNotifications,
    'tracking-notifications',
    'orders',
  );
  compareAndPush(
    currentPlanFeatures.features.bulkPrinting,
    targetPlanFeatures.features.bulkPrinting,
    'bulk-print',
    'orders',
  );
  compareAndPush(
    currentPlanFeatures.features.canSeePickingList,
    targetPlanFeatures.features.canSeePickingList,
    'picking-list',
    'orders',
  );
  compareAndPush(
    currentPlanFeatures.features.canSeePickingList,
    targetPlanFeatures.features.canSeePickingList,
    'stock-settings',
    'orders',
  );
  compareAndPush(
    currentPlanFeatures.features.productionList,
    targetPlanFeatures.features.productionList,
    'production-list',
    'orders',
  );
  if (
    targetPlanName === SubscriptionPlan.Grow ||
    targetPlanName === SubscriptionPlan.Launch ||
    targetPlanName === SubscriptionPlan.Starter
  ) {
    finalObject.orders.push('scan-and-pack');
  }
  compareAndPush(
    currentPlanFeatures.features.useOrderWebhook,
    targetPlanFeatures.features.useOrderWebhook,
    'webhooks',
    'orders',
  );
  compareAndPush(
    currentPlanFeatures.features.shippingRules,
    targetPlanFeatures.features.shippingRules,
    'shipping-rules',
    'orders',
  );
  compareAndPush(
    currentPlanFeatures.features.directPrinting,
    targetPlanFeatures.features.directPrinting,
    'direct-printing-app',
    'orders',
  );
  compareAndPush(
    currentPlanFeatures.features.useTrackingMode,
    targetPlanFeatures.features.useTrackingMode,
    'tracking-mode',
    'orders',
  );

  return finalObject;
};

export default lostFeaturesOptions;
