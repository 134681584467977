export default {
  'custLanding.password.title': 'Mot de passe oublié ?',
  'custLanding.signup.btn': 'S’inscrire',
  'custLanding.login.btn': 'Se connecter',
  'custLanding.password.btn': 'Réinitialiser le mot de passe',
  'custLanding.switch-to-login': 'Se connecter avec un compte existant',
  'custLanding.switch-to-signup': "S'inscrire avec un nouveau compte",
  'custLanding.auth.text.password':
    "Entrez l'adresse e-mail associée à votre compte et nous vous enverrons des instructions pour obtenir un nouveau mot de passe.",
  'custLanding.form.password': 'Mot de passe',
  'custLanding.forgot-password': 'Mot de passe oublié ?',
  'custLanding.forgot-password.success': 'Vos instructions arriveront sous peu !',
  'custLanding.let-get-started': 'C’est parti !',
  'custLanding.already-have-an-account': 'Vous avez déjà un compte ?',
  'custLanding.just-remembered': 'Vous venez de vous souvenir de votre mot de passe ?',
  'custlanding.login.error.no-order': 'Aucune commande de trouvée pour cette adresse e-mail',
};
