export function ChatMessage({ ...props }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 32 32" {...props}>
      <g id="chat" transform="translate(-614 -984)">
        <rect
          width="32"
          height="32"
          rx="10"
          transform="translate(614 1016) rotate(-90)"
          fill="#007aff"
        />
        <g transform="translate(-342 832)" opacity="0.92">
          <rect width="24" height="24" transform="translate(960 156)" fill="none" />
          <path
            d="M0,10.176A10.162,10.162,0,0,1,10.069,0h.42A10.168,10.168,0,0,1,20.977,9.756v.42A10.162,10.162,0,0,1,10.908,20.351h-.42a10.138,10.138,0,0,1-3.042-.42H6.922l-2.1.944a.882.882,0,0,1-1.154-.42c0-.1-.1-.21-.1-.315l-.1-1.888a.907.907,0,0,0-.315-.629A10.3,10.3,0,0,1,0,10.176Z"
            transform="translate(961.698 157.699)"
            fill="none"
          />
          <path
            d="M8.5,0A8.212,8.212,0,0,1,17,8.256a8.212,8.212,0,0,1-8.5,8.256,7.849,7.849,0,0,1-2.465-.34H5.61l-1.7.766a.937.937,0,0,1-.68,0,.717.717,0,0,1-.34-.6L2.8,14.81A.736.736,0,0,0,2.55,14.3,8.365,8.365,0,0,1,0,8.256,8.212,8.212,0,0,1,8.5,0Z"
            transform="translate(963.5 159.5)"
            fill="#fff"
          />
        </g>
      </g>
    </svg>
  );
}
