export function CheckCircle({ ...props }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 -960 960 960"
      fill="currentColor"
      {...props}
    >
      <path d="m423.231-394.153-92.924-92.923q-8.307-8.308-20.884-8.5-12.577-.193-21.269 8.5-8.692 8.692-8.692 21.076t8.692 21.076l109.769 109.769q10.846 10.846 25.308 10.846 14.461 0 25.307-10.846l222.538-222.538q8.308-8.307 8.5-20.884.193-12.577-8.5-21.269-8.692-8.692-21.076-8.692t-21.076 8.692L423.231-394.153Zm56.836 294.152q-78.836 0-148.204-29.92-69.369-29.92-120.682-81.21-51.314-51.291-81.247-120.629-29.933-69.337-29.933-148.173t29.92-148.204q29.92-69.369 81.21-120.682 51.291-51.314 120.629-81.247 69.337-29.933 148.173-29.933t148.204 29.92q69.369 29.92 120.682 81.21 51.314 51.291 81.247 120.629 29.933 69.337 29.933 148.173t-29.92 148.204q-29.92 69.369-81.21 120.682-51.291 51.314-120.629 81.247-69.337 29.933-148.173 29.933ZM480-160q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z" />
    </svg>
  );
}
