import { HTMLProps, ReactElement, ReactNode, memo, useMemo } from 'react';

import { type FieldValues, type Path, useWatch } from 'react-hook-form';

export interface _AdminSettingsConditionalSectionProps<T> {
  className?: string;
  selector: Path<T>;
  value: string;
  comparatorType?: 'equals' | 'hasLength';
  customComparator?: (currentValue: Path<T>, value: string) => boolean;
  children?: ReactNode;
}

export type AdminSettingsConditionalSectionProps<T extends FieldValues> =
  HTMLProps<HTMLDivElement> & _AdminSettingsConditionalSectionProps<T>;

export function AdminSettingsConditionalSection<T extends FieldValues>({
  className,
  selector,
  value,
  children,
  comparatorType = 'equals',
  customComparator,
}: AdminSettingsConditionalSectionProps<T>) {
  const currentValue = useWatch({ name: selector });

  const comparator = useMemo(() => {
    if (customComparator) {
      return customComparator;
    }

    switch (comparatorType) {
      case 'hasLength':
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        return (currentValue: Path<T>, value: string) => currentValue?.length > 0;
      case 'equals':
      default:
        return (currentValue: Path<T>, value: string) => currentValue === value;
    }
  }, [comparatorType, customComparator]);

  return (
    <>
      {comparator(currentValue, value) ? (
        <div data-testid="outvioui--adminsettingsconditionalsection" className={className}>
          {children}
        </div>
      ) : (
        false
      )}
    </>
  );
}

export default memo(AdminSettingsConditionalSection) as <T extends FieldValues>(
  props: _AdminSettingsConditionalSectionProps<T>,
) => ReactElement | null;
