import React, { SelectHTMLAttributes, useContext, useMemo } from 'react';

import { Theme, makeStyles } from '@material-ui/core';
import cn from 'classnames';

import { SelectOption } from '../types/CustomerGeneral';
import opacityToSolidColor from '../utils/opacityToSolidColor';
import OVCStoreDataContext from './OVCStoreDataContext';

interface StyleProps {
  textColor: string;
  fadeText: string;
  errColor: string;
  errColorFaded: string;
}

const useStyles = makeStyles<Theme, StyleProps>({
  base: {
    width: '100%',
    height: '40px',
    padding: '0 10px',

    fontSize: '14px',
    outline: 'none',

    backgroundColor: 'transparent',
    borderRadius: '5px',
    border: (props) => `1px solid ${props.textColor}`,

    color: (props) => props.textColor,

    '&::placeholder': {
      color: (props: any) => props.fadeText,
    },
  },
  baseError: {
    border: (props) => `1px solid ${props.errColor}`,

    color: (props) => props.errColor,

    '&::placeholder': {
      color: (props: any) => props.errColorFaded,
    },
  },
});
interface IOVCSelect extends SelectHTMLAttributes<HTMLSelectElement> {
  options: SelectOption[];
  error?: boolean;
  placeholder?: string;
}

const OVCSelect = ({ options, className, placeholder, error = false, ...props }: IOVCSelect) => {
  const { storeData } = useContext(OVCStoreDataContext);

  const fadeText = useMemo(
    () =>
      opacityToSolidColor(
        storeData.portalSettings.colors.primaryText,
        0.7,
        storeData.portalSettings.colors.primaryBg,
      ),
    [storeData],
  );
  const errColorFaded = useMemo(
    () =>
      opacityToSolidColor(
        storeData.portalSettings.colors.alertColor,
        0.7,
        storeData.portalSettings.colors.primaryBg,
      ),
    [storeData],
  );

  const classes = useStyles({
    textColor: storeData.portalSettings.colors.primaryText,
    fadeText,
    errColor: storeData.portalSettings.colors.alertColor,
    errColorFaded,
  });

  return (
    <select className={cn(classes.base, { [classes.baseError]: error }, className)} {...props}>
      <option value="" disabled hidden>
        {placeholder}
      </option>
      {options.map((opt) => (
        <option key={opt.value} value={opt.value}>
          {opt.label}
        </option>
      ))}
    </select>
  );
};

export default OVCSelect;
