import get from 'lodash-es/get';

import { Language } from 'app-types';
import apiErrorsDE from 'localization/locales/de-DE/apiErrors.json';
import apiErrorsEN from 'localization/locales/en-EN/apiErrors.json';
import apiErrorsES from 'localization/locales/es-ES/apiErrors.json';
import apiErrorsPL from 'localization/locales/pl-PL/apiErrors.json';
import apiErrorsPT from 'localization/locales/pt-PT/apiErrors.json';

import DELETELATER_OVLocalCache from './DELETELATER_OVLocalCache';

//TODO DELETE MOMENT-UI after replacing request

const apiErrors: Record<Language, any> = {
  'en-EN': apiErrorsEN,
  'es-ES': Object.assign({}, apiErrorsEN, apiErrorsES),
  'de-DE': Object.assign({}, apiErrorsEN, apiErrorsDE),
  'pl-PL': Object.assign({}, apiErrorsEN, apiErrorsPL),
  'pt-PT': Object.assign({}, apiErrorsEN, apiErrorsPT),
};
interface OVRequestOptions {
  method: string;
  upload?: boolean;
  secure?: boolean;
  params?: Record<string, any>;
  data?: Record<string, any> | FormData;
  repeatingArrayInGet?: boolean;
}

interface OVAppOptions {
  skipApiCheck?: boolean;
  skipErrorRedirect?: boolean;
  skipJsonConvert?: boolean;
  skipLogout?: boolean;
  skipJsonLS?: boolean;
}

interface OVRequestMeta {
  token: string;
  adminToken?: string | null;
  isImpersonating?: boolean;
  refresh_token: string;
  userLang: Language;
}

const checkStatus = (response: Response, appOptions: OVAppOptions): Promise<string | any> => {
  return new Promise(async (resolve, reject) => {
    let data = {};
    try {
      if (response.ok) {
        if (appOptions.skipJsonConvert) {
          data = await response.text();
        } else {
          data = await response.json();
        }
        return resolve(data);
      } else {
        try {
          data = await response.json();
          return resolve(data);
        } catch (e) {
          if (e instanceof Error && /Unexpected token/g.test(e.message)) {
            return reject(new Error('Failed to fetch'));
          }
          return reject(e);
        }
      }
    } catch (e) {
      return reject(e);
    }
  });
};

const checkApiResponse = (data: any, userLang: Language): Promise<any> =>
  new Promise((resolve, reject) => {
    if (data.success) {
      return resolve(data);
    }
    if (data.error || data.message || data.courierError) {
      const useUserLang = userLang || 'en-EN';
      const errText = data?.error?.message || data.message || data.courierError;
      if (apiErrors[useUserLang][errText]) {
        throw new Error(apiErrors[useUserLang][errText]);
      } else if (data?.error && data?.error.name === 'ValidationError' && data?.error.errors) {
        return reject(data);
      } else if (data?.error && data?.error.name === 'Error' && data?.error.message) {
        return reject(data?.error);
      } else {
        // https://github.com/Outvio/Back-End/blob/develop/src/utils/errorResponse.js
        throw new Error(data?.error?.message || data.message || data.courierError);
      }
    }
    return resolve(data);
  });

function getSSOUrl() {
  try {
    return localStorage.getItem('ssoUrl');
  } catch (err) {
    //
  }
}

function setBeforeLogoutPage() {
  try {
    return localStorage.setItem('beforeLogoutPage', location.pathname);
  } catch (err) {
    //
  }
}

const handleSSOError = (error: Error) => {
  if (error?.message === 'INVALID_TOKEN' || error?.message === 'Invalid Token.') {
    const ssoUrl = getSSOUrl();
    if (ssoUrl) {
      setBeforeLogoutPage();
      window.location.assign(ssoUrl);
      return;
    }
  }
  return Promise.reject(error);
};

const handleError = (data: any, userLang: Language, skipLogout: boolean) => {
  if (
    data.message &&
    (data.message === 'INVALID_TOKEN' || data.message === 'Invalid Token.') &&
    skipLogout === false
  ) {
    return window.location.assign('/auth/logout');
  }

  if (data.message && data.error) {
    const errorCode = get(data, 'message', null);
    const errors = get(data, 'error.errors', null);

    const code = data.error.status;
    const error = {
      code,
      message: errorCode,
      requestId: data.request_id,
      errors,
    };

    const useUserLang = userLang || 'en-EN';
    if (errorCode === null) {
      error.message = apiErrors[useUserLang]?.FALLBACK_ERROR_MESSAGE;
    } else if (errorCode !== null && apiErrors[useUserLang][errorCode]) {
      error.message = apiErrors[useUserLang][errorCode];
    }

    throw error;
  }

  throw data;
};

export const gatherLocalData = (): Promise<OVRequestMeta> =>
  Promise.all([
    DELETELATER_OVLocalCache.get('token'),
    DELETELATER_OVLocalCache.get('impersonateToken'),
    DELETELATER_OVLocalCache.get('refresh_token'),
    DELETELATER_OVLocalCache.get('userLanguage'),
  ]).then((results) => ({
    token: results[1] || results[0],
    adminToken: results[1] ? results[0] : null,
    isImpersonating: Boolean(results[1]),
    refresh_token: results[2],
    userLang: results[3] || 'en-EN',
  }));

const gatherRawValues = () => {
  const values: OVRequestMeta = {
    token: window.localStorage.getItem('token') || '',
    refresh_token: window.localStorage.getItem('refresh_token') || '',
    userLang: (window.localStorage.getItem('userLanguage') as Language) || 'en-EN',
  };
  return values;
};

const makeHeaders = (options: OVRequestOptions, meta: OVRequestMeta) =>
  new Headers({
    Accept: 'application/json',
    ...(options.upload
      ? {}
      : {
          'Content-Type': 'application/json',
        }),
    ...(options.secure ? { 'x-access-token': meta.token } : {}),
    ...(meta.isImpersonating ? { 'x-outvio-imp': 'true' } : {}), // add header to exclude impersonating from activity tracking
    ...(meta.isImpersonating && meta.adminToken ? { 'x-outvio-admin': meta.adminToken } : {}), // used for deactivated account admin access
  });

const makeUrl = (url: string, options: OVRequestOptions): string => {
  const constructedParams = new URLSearchParams();
  let doAddParams = false;

  // NOTE: must not exclude falsy values from including in the final params object. some filters are "false", "0" or other falsy values that might get excluded
  if (options.params) {
    doAddParams = true;
    Object.keys(options.params).forEach((key) => {
      if (Array.isArray(options.params?.[key]) && options.repeatingArrayInGet) {
        options.params?.[key].forEach((item: string) => {
          constructedParams.append(key, item);
        });
      } else {
        constructedParams.append(key, options.params?.[key]);
      }
    });
  }

  return doAddParams ? `${url}?${constructedParams.toString()}` : url;
};

const request: (
  url: string,
  options: OVRequestOptions,
  appOptions?: OVAppOptions,
) => Promise<any> = async (
  url,
  options = { method: 'GET' },
  appOptions = {
    skipApiCheck: false,
    skipErrorRedirect: false,
    skipJsonConvert: false,
    skipLogout: false,
    skipJsonLS: false,
  },
) => {
  const formedAppOptions = {
    skipApiCheck: appOptions?.skipApiCheck || false,
    skipErrorRedirect: appOptions?.skipErrorRedirect || false,
    skipJsonConvert: appOptions?.skipJsonConvert || false,
    skipLogout: appOptions?.skipLogout || false,
    skipJsonLS: appOptions?.skipJsonLS || false,
  };

  let meta: OVRequestMeta;
  try {
    if (appOptions.skipJsonLS) {
      meta = gatherRawValues();
    } else {
      meta = await gatherLocalData();
    }
  } catch (err: any) {
    throw new Error(err);
  }
  const ovHeaders = makeHeaders(options, meta);
  const finalUrl = makeUrl(url, options);
  const fetchOptObject = {
    method: options.method,
    headers: ovHeaders,
    body:
      options.upload && options.data instanceof FormData
        ? options.data
        : JSON.stringify(options.data),
  };

  let skipSecondaryChecks = true;

  return fetch(finalUrl, fetchOptObject)
    .then((res) => checkStatus(res, formedAppOptions))
    .then((res) => {
      if (!formedAppOptions.skipApiCheck) {
        return checkApiResponse(res, meta.userLang);
      }
      return Promise.resolve(res);
    })
    .catch(handleSSOError)
    .catch(async (err) => {
      if (err.message && err.message === 'EXPIRED_TOKEN') {
        // refresh token, update headers, retry
        const res = await fetch(`${process.env.OUTVIO_API_URL}/v2/authorize`, {
          method: 'POST',
          headers: new Headers({
            Accept: 'application/json',
            'Content-Type': 'application/json',
          }),
          body: JSON.stringify({
            grant_type: 'refresh_token',
            refresh_token: meta.refresh_token,
          }),
        });
        const res_1 = await checkStatus(res, { skipJsonConvert: false });
        const res_2 = await checkApiResponse(res_1, meta.userLang);
        // Refresh successfull, update local token, update headers, and try again
        skipSecondaryChecks = false;
        await Promise.all([
          DELETELATER_OVLocalCache.set('token', res_2.access_token),
          DELETELATER_OVLocalCache.set('refresh_token', res_2.refresh_token),
        ]);
        fetchOptObject.headers = makeHeaders(options, { ...meta, token: res_2.access_token });
        return await fetch(finalUrl, fetchOptObject);
      }
      return Promise.reject(err);
    })
    .then((res) => (skipSecondaryChecks ? res : checkStatus(res, formedAppOptions)))
    .then((res) => {
      if (skipSecondaryChecks) {
        return res;
      }
      if (!formedAppOptions.skipApiCheck) {
        return checkApiResponse(res, meta.userLang);
      }
      return Promise.resolve(res);
    })
    .catch((err) => handleError(err, meta.userLang, formedAppOptions.skipLogout));
};

export default request;
