export default {
  'ovc.general.powered-by': 'Darbību nodrošina',
  'ovc.auth.identity.email.placeholder': 'E-pasts',
  'ovc.auth.identity.cta': 'Izveidot preces atgriešanu',
  'ovc.auth.order.orderNumber.placeholder': 'Pasūtījuma numurs vai kurjera izsekošanas numurs',
  'ovc.auth.order.clientInfo.placeholder': 'Pasta indekss vai pēdējie 5 tālruņa cipari',
  'ovc.auth.switch-to-identity': 'Tā vietā piesakieties ar e-pastu',
  'ovc.auth.switch-to-order': 'Tā vietā piesakieties ar pasūtījuma informāciju',
  'ovc.auth.tracking.cta': 'Izsekot manu pasūtījumu',
  'ovc.auth.tracking.orderIdentifier.placeholder':
    'Ievadiet pasūtījuma ID, OTN vai izsekošanas numuru',
  'ovc.auth.tracking.postcode.placeholder': 'Ievadiet galamērķa pasta indeksu',
  'ovc.listing.page.all': 'Visi pasūtījumi',
  'ovc.listing.page.in-transit': 'Pasūtījumi tranzītā',
  'ovc.listing.page.returns': 'Preču atgriešana',
  'ovc.listing.header.order': 'pasūtījums',
  'ovc.listing.header.date': 'datums',
  'ovc.listing.header.total': 'kopā',
  'ovc.listing.header.choose-product': 'Izvēlieties preci:',
  'ovc.listing.listing-track-cta': 'izsekot',
  'ovc.listing.listing-return-cta': 'atgriezt',
  'ovc.listing.listing-external-action-cta': 'pieprasīt garantijas remontu',
  'ovc.listing.empty-list': 'Parādīšanai nav pieejams neviens pasūtījums',
  'ovc.listing.return-status.store-non-returnable': 'Nevar atgriezt',
  'ovc.listing.return-status.already-returned': 'Atgriezts {date}',
  'ovc.listing.return-status.no-longer-returnable': 'Vairs nav atgriežams',
  'ovc.listing.return-status.returnable': 'Atgriezt līdz {date}',
  'ovc.return-cart.refund-method-credit': `Izvēlieties šo opciju, ja vēlaties izmantot naudas atmaksu savam nākamajam pirkumam pie mums!{lineBreak}
  {lineBreak}
  Mēs jums nosūtīsim dāvanu karti ar atmaksas summu.`,
  'ovc.return-cart.inst.outvio.heading.confirm-pickup':
    'apstipriniet savu paņemšanas adresi un preces atgriešanu',
  'ovc.return-cart.inst.outvio.heading.confirm-return': 'apstipriniet savu preces atgriešanu',
  'ovc.return-cart.address.heading': 'ievadiet jaunu adresi vai izvēlieties no esošajām:',
};
