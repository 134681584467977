export function Orders({ ...props }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24" {...props}>
      <g transform="translate(-865 -172)">
        <g
          transform="translate(865 172)"
          fill="#fff"
          stroke="currentColor"
          strokeWidth="1"
          opacity="0"
        >
          <rect width="24" height="24" stroke="none" />
          <rect x="0.5" y="0.5" width="23" height="23" fill="none" />
        </g>
        <path
          d="M894.281,4687.306a3.433,3.433,0,0,1-1.019,2.437,3.508,3.508,0,0,1-4.92,0,3.432,3.432,0,0,1-1.018-2.437m-3.8-.517-.609,7.233a6.5,6.5,0,0,0,.069,2.928,2.582,2.582,0,0,0,1.148,1.24,6.749,6.749,0,0,0,2.943.31h7.451a6.749,6.749,0,0,0,2.943-.31,2.578,2.578,0,0,0,1.148-1.24,6.5,6.5,0,0,0,.069-2.928l-.609-7.233a6.237,6.237,0,0,0-.468-2.514,2.638,2.638,0,0,0-1.125-1.025,6.471,6.471,0,0,0-2.567-.25h-6.234a6.471,6.471,0,0,0-2.567.25,2.638,2.638,0,0,0-1.125,1.025A6.236,6.236,0,0,0,883.525,4686.789Z"
          transform="translate(-13.8 -4506.75)"
          fill="none"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.64"
        />
      </g>
    </svg>
  );
}
