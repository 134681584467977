import { FunctionComponent, memo } from 'react';

import { useWindowSize } from 'react-use';

export const SMViewport = 640;
export const OnboardingViewport = 850;

export const Viewport = memo(
  ({
    minWidth,
    maxWidth,
    Component,
  }: {
    minWidth?: number;
    maxWidth?: number;
    Component: FunctionComponent;
  }) => {
    const { width } = useWindowSize();

    if (minWidth && width < minWidth) {
      return null;
    } else if (maxWidth && width > maxWidth - 1) {
      return null;
    }

    return <Component />;
  },
);
