import React, { Fragment, ReactNode, memo } from 'react';

import { Disclosure } from '@headlessui/react';
import { Lock } from 'icons';
import { useIntl } from 'localization';
import { twMerge } from 'tailwind-merge';

import Badge from '../../../atoms/Badge';
import { DropdownExpandMoreIcon } from '../../Dropdown/DropdownExpandMoreIcon';
import { DropdownItem } from '../../Dropdown/DropdownItem';

type TabData = {
  value: string;
  label: ReactNode;
  locked?: boolean;
  isNew?: boolean;
};

export type AdminSettingsDisclosureProps = {
  defaultOpen?: boolean;
  className?: string;
  sectionName: ReactNode;
  openTab: string;
  onChangeTab(newTab: string): void;
  data: TabData[];
  exact?: boolean;
};

export const AdminSettingsDisclosure = ({
  className,
  sectionName,
  data,
  openTab,
  onChangeTab,
  exact = true,
  defaultOpen,
}: AdminSettingsDisclosureProps) => {
  const { formatMessage } = useIntl();

  return (
    <Disclosure
      defaultOpen={defaultOpen}
      as="div"
      className={twMerge('text-sm', className)}
      data-testid="outvioui--adminsettingsdisclosure"
    >
      {({ open }) => (
        <>
          <Disclosure.Button as={Fragment}>
            <DropdownItem
              className={twMerge('gap-[6px] capitalize', open && 'font-semibold')}
              icon={<DropdownExpandMoreIcon open={open} />}
            >
              {sectionName}
            </DropdownItem>
          </Disclosure.Button>
          <Disclosure.Panel as={Fragment}>
            <div className="pl-6 flex flex-col mt-1">
              {data.map((entry: TabData, index) => {
                const selected = exact ? openTab === entry.value : openTab.startsWith(entry.value);
                return (
                  <DropdownItem
                    key={`${index}${entry.value}`}
                    wrapperClassName="relative"
                    disabled={entry.locked}
                    icon={
                      entry.locked ? <Lock className="w-5 h-5 text-v2blueGray-500" /> : undefined
                    }
                    className={
                      entry.locked
                        ? 'text-v2blueGray-550 justify-between'
                        : selected
                        ? ''
                        : 'text-v2blueGray-700'
                    }
                    iconPosition="right"
                    selected={selected}
                    onClick={() => {
                      onChangeTab(entry.value);
                    }}
                  >
                    {entry.label}
                    {entry.isNew && (
                      <div className="absolute top-1/2 -translate-y-1/2 right-2">
                        <Badge
                          text={formatMessage({ id: 'conversationsHub.new' })}
                          theme="purple"
                        />
                      </div>
                    )}
                  </DropdownItem>
                );
              })}
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
};

export default memo(AdminSettingsDisclosure);
