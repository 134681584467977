export default {
  'general.lang.ca': 'Catalan',
  'general.lang.es': 'Spanish',
  'general.lang.en': 'English',
  'general.lang.et': 'Estonian',
  'general.lang.fr': 'French',
  'general.lang.it': 'Italian',
  'general.lang.de': 'German',
  'general.lang.fi': 'Finnish',
  'general.lang.pt': 'Portuguese',
  'general.lang.lt': 'Lithuanian',
  'general.lang.lv': 'Latvian',
  'general.lang.pl': 'Polish',
  'general.back': 'INDIETRO',
  'general.required': 'Richiesto',
  'general.PRODUCT_PACKAGE_BROKEN': 'Confezione prodotto rotta',
  'general.PRODUCT_BROKEN': 'Prodotto rotto',
  'general.PRODUCT_USED': 'Prodotto usato',
  'general.PRODUCT_DIRTY': 'Prodotto sporco',
  'general.MISSING_PRODUCT_LABEL': 'Etichetta prodotto mancante',
  'general.PRODUCT_MISSING': 'Prodotto mancante',
  'general.OTHER': 'Altro',
  'general.credit-card': 'Carta di credito',
  'general.bank-transfer': 'Bonifico bancario',
  'general.cash-on-delivery': 'Contanti alla consegna',
  'general.continue': 'Continua',
  'general.CLOSED': 'CHIUSO',
  'general.PARTIALLY_RECEIVED': 'PARZIALMENTE RICEVUTO',
  'general.RECEIVED': 'RICEVUTO',
  'general.IN_TRANSIT': 'IN TRANSITO',
  'general.comments': 'Commenti',
  'general.return': 'Reso',
  'general.user-account': 'Account utente',
  'general.sign-out': 'Esci',
  'general.accept': 'Accetta',
  'general.was-returned-on': 'Reso il',
  'general.product-cannot-returned': 'Questo prodotto non può essere reso',
  'general.product-non-returnable':
    'Questo prodotto non può essere reso. Contatta l’assistenza clienti se desideri restituirlo.',
  'general.can-return-it': 'Puoi effettuare il reso entro il',
  'general.created': 'CREATO',
  'general.shipped': 'SPEDITO',
  'general.in-transit': 'IN TRANSITO',
  'general.out-of-delivery': 'FUORI PER CONSEGNA',
  'general.delivered': 'CONSEGNATO',
  'general.shipping-charges': 'SPESE DI SPEDIZIONE',
  'general.order-details': 'Dettagli ordine',
  'general.return-details': 'Dettagli della restituzione',
  'general.no-orders-in-transit': 'Nessun ordine attualmente in transito',
  'general.products-returned': 'Prodotti da rendere',
  'general.select-motive': 'Selezionare un motivo',
  'general.why-want-return': 'Perché vuoi rendere questo prodotto?',
  'general.do-have-comments': 'Hai commenti?',
  'general.enter-comment': 'Inserisci il tuo commento',
  'general.do-want-attach-image': 'Vuoi allegare qualche immagine?',
  'general.pickup-address': 'Indirizzo di ritiro',
  'general.date-time-pickup': 'Data e ora del ritiro',
  'general.contact-phone-number': 'Numero di telefono di contatto',
  'general.comments-courier': 'Commento per il corriere',
  'general.confirm-address': `Confermo l'indirizzo di ritiro:`,
  'general.quantity': 'Quantità',
  'general.length': 'Lunghezza',
  'general.width': 'Larghezza',
  'general.height': 'Altezza',
  'general.identifier': 'Identificativo',
  'general.tracking-number': 'Numero di tracciamento',
  'general.date-purchase': 'Data di acquisto',
  'general.date-time': 'Data ora',
  'general.status': 'Stato',
  'general.shipping-cost': 'Costo di spedizione per questo reso',
  'general.free': 'Gratuito',
  'general.done': 'Fatto',
  'general.spanish': 'Spagnolo',
  'general.english': 'Italiano',
  'returns.step.products.title': 'SELEZIONE PRODOTTO',
  'returns.step.products.text': 'Selezionare i prodotti da rendere',
  'returns.step.method.title': 'METODO DI RESO',
  'returns.step.method.text': 'Scegli il tuo metodo di reso',
  'returns.step.instructions.title': 'ISTRUZIONI',
  'returns.step.instructions.text': 'Prepara il tuo reso',
  'returns.step.confirm.title': 'CONFERMA',
  'returns.step.confirm.text': 'Reso completato',
  'general.img-upload': `Trascina o clicca per aggiungere un'immagine`,
  'retcart.packages.text': `Completa i pacchi per terminare il tuo reso
    {lineBreak}{lineBreak}I NOSTRI CONSIGLI:
    {lineBreak}- Cerca di riutilizzare lo stesso pacco utilizzato per consegnare il tuo ordine. Fa bene al pianeta ed è già disponibile!
    {lineBreak}- Se restituisci più di un articolo, cerca di utilizzare la quantità minima di pacchi.
    {lineBreak}- Ricordati che quando spedisci degli articoli, viene spedita anche l’aria all’interno del pacco! Se possibile e sicuro, cerca di utilizzare una scatola che minimizza lo spazio vuoto. Ciò abbassa i tuoi costi di reso.`,
  'retcart.step2.heading':
    'CONFERMA LE DIMENSIONI DEL TUO PACCO (FAI CLIC SU “AGGIUNGI PACCO” SE DEVE UTILIZZARNE PIÚ DI UNO)',
  'retcart.pack.default-title.display': 'UTILIZZARE L’IMBALLAGGIO DI CONSEGNA',
  'retcart.pack.custom-title.display': 'UTILIZZARE UN IMBALLAGGIO PERSONALIZZATO',
  'retcart.pack.custom-title.edit': 'UTILIZZARE UN IMBALLAGGIO PERSONALIZZATO (dimensione in cm)',
  'retcart.pack.change-size': '(Cambio dimensione)',
  'retcart.pack.remove': '(Rimuovi)',
  'retcart.pack.add': 'AGGIUNGI IMBALLAGGIO',
  'retcart.addrmodal.heading1': 'SELEZIONA UNO DEI TUOI INDIRIZZI SALVATI',
  'retcart.addrmodal.heading2': 'O INSERISCI UN NUOVO INDIRIZZO',
  'retcart.print.loading.heading': 'Stiamo elaborando il tuo reso...',
  'retcart.print.loading.note':
    'Non chiudere questa finestra finché non elaboriamo la tua richiesta!',
  'landing.email-sent':
    'Email inviata con successo, controlla la tua casella di posta per verificare la tua email entro i prossimi 15 minuti.',
  'general.status.IN_TRANSIT': 'IN TRANSITO',
  'general.status.DELIVERED': 'CONSEGNATO',
  'general.status.DELIVERED_POST_OFFICE': 'CONSEGNATO',
  'general.status.DELIVERED_TO_PICKUP_POINT': 'CONSEGNATO AL{lineBreak}PUNTO DI RACCOLTA',
  'general.status.DELIVERED_TO_SHOP': 'DELIVERED TO{lineBreak}SHOP',
  'general.status.PARTIALLY_DELIVERED': 'PARZIALMENTE CONSEGNATO',
  'general.status.PARTIALLY_DELIVERED.short': 'CONSEGNATO PARZ.',
  'general.status.DELAYED': 'IN RITARDO',
  'general.status.RETURNED': 'RESO',
  'general.status.DELETED': 'ELIMINATO',
  'general.status.RECEIVED': 'RICEVUTO',
  'general.status.PARTIALLY_RECEIVED': 'PARZIALMENTE RICEVUTO',
  'general.status.PARTIALLY_RECEIVED.short': 'RICEVUTO PARZ.',
  'general.status.CLOSED': 'CHIUSO',
  'general.status.CREATED': 'CREATO',
  'general.status.PICKING_QUEUE': 'CODA DI RITIRO',
  'general.status.SHIPPING_QUEUE': 'CODA DI SPEDIZIONE',
  'general.status.REFUNDED': 'RIMBORSATO',
  'general.status.SHIPMENT_PICKUP': 'RITIRO SPEDIZIONE',
  'general.status.SHIPPED': 'SPEDITO',
  'general.status.PICKUP': 'SPEDITO',
  'general.status.IN_TRANSIT_DELAYED': 'IN TRANSITO IN RITARDO',
  'general.status.EXCEPTION': 'ECCEZIONE',
  'general.status.OUT_FOR_DELIVERY': 'FUORI PER CONSEGNA',
  'general.status.RETURNED_TO_ORIGIN': 'RESTITUITO AL MITTENTE',
  'general.status.CANCELLED': 'ANNULLATO',
  'sidebar.orders-in-transit': 'ORDINI IN TRANSITO',
  'sidebar.my-orders': 'I MIEI ORDINI',
  'sidebar.returns': 'RESI',
  'general.courier.gls_ireland': 'GLS Ireland',
  'general.courier.tnt_italy': 'TNT Italy',
  'general.courier.brt': 'BRT',
  'general.courier.bring': 'Bring',
  'general.courier.budbee': 'Budbee',
  'general.courier.cbl': 'CBL',
  'general.courier.correos': 'Correos Express',
  'general.courier.ctt': 'CTT',
  'general.courier.ctt_portugal': 'CTT Portugal',
  'general.courier.chornopost': 'Chronopost',
  'general.courier.deprecated': 'DEPRECATED',
  'general.courier.deutschepost': 'Deutsche Post',
  'general.courier.deutschepost_germany': 'Deutsche Post',
  'general.courier.dhl': 'DHL',
  'general.courier.dhl_sweden': 'DHL',
  'general.courier.dhlparcel': 'DHL Parcel',
  'general.courier.dhlparcel_portugal': 'DHL Parcel',
  'general.courier.dhlparcel_spain': 'DHL Parcel',
  'general.courier.dhlparcel_poland': 'DHL Parcel',
  'general.courier.dhlparcel_unitedkingdom': 'DHL Parcel',
  'general.courier.dhl_paket_germany': 'DHL Paket',
  'general.courier.directa': 'Directa',
  'general.courier.dpd': 'DPD',
  'general.courier.gordon': 'Gordon',
  'general.courier.boostparcel': 'Boost Parcel',
  'general.courier.ronin': 'Ronin',
  'general.courier.trablisa': 'Trablisa Express',
  'general.courier.dpd_france': 'DPD',
  'general.courier.dpd_germany': 'DPD',
  'general.courier.dpd_portugal': 'DPD',
  'general.courier.dpd_portugal_new': 'DPD',
  'general.courier.dpd_romania': 'DPD',
  'general.courier.dpd_poland': 'DPD',
  'general.courier.envialia': 'Envialia',
  'general.courier.external': 'External Courier',
  'general.courier.external-EXCHANGE': 'Exchange',
  'general.courier.fedex': 'FedEx',
  'general.courier.glovo': 'Glovo',
  'general.courier.gls': 'GLS OLD',
  'general.courier.glsnew': 'GLS',
  'general.courier.gls_italy': 'GLS',
  'general.courier.glsshipit': 'GLS ShipIt',
  'general.courier.hermes': 'Hermes',
  'general.courier.inpost': 'InPost',
  'general.courier.inpost_spain': 'InPost',
  'general.courier.beeasy_spain': 'Beeasy',
  'general.courier.internal': 'Internal',
  'general.courier.matkahuolto': 'Matkahuolto',
  'general.courier.mondialrelay': 'Mondial Relay',
  'general.courier.colissimo': 'Colissimo',
  'general.courier.mrw': 'MRW',
  'general.courier.nacex': 'Nacex',
  'general.courier.omniva': 'Omniva',
  'general.courier.outvio': 'Outvio',
  'general.courier.paack': 'Paack',
  'general.courier.parcelforce': 'Parcelforce',
  'general.courier.posti': 'Posti',
  'general.courier.publiccorreos': 'Correos',
  'general.courier.postnord': 'PostNord',
  'general.courier.postnl': 'PostNL',
  'general.courier.relaiscolis': 'Relais Colis',
  'general.courier.royalmail': 'Royal Mail',
  'general.courier.schenker': 'DB Schenker',
  'general.courier.sending': 'SENDING',
  'general.courier.seur': 'SEUR',
  'general.courier.seur_atlas': 'SEUR Atlas',
  'general.courier.shop': 'To Shop',
  'general.courier.smartpost': 'Smartpost',
  'general.courier.spring': 'Spring GDS',
  'general.courier.tipsa': 'TIPSA',
  'general.courier.tnt': 'TNT',
  'general.courier.transaher': 'Transaher',
  'general.courier.ups': 'UPS',
  'general.courier.venipak': 'Venipak',
  'general.courier.zeleris': 'Zeleris',
  'general.courier.zeleriscustom': 'Zeleris',
  'general.courier.zitycity': 'Zitycity',
  'general.trackingbar.datetime': 'Data Ora',
  'general.trackingbar.location': 'Località',
  'general.trackingbar.status': 'Stato',
  'general.trackingbar.detail': 'Dettaglio',
  'general.trackingbar.no-info': 'Informazioni non ancora disponibili.',
  'general.return-error.all-returned': 'Tutti i prodotti sono stati resi.',
  'retcart.heading.methods': 'COME VUOI RENDERE I TUOI PRODOTTI?',
  'retcart.method.toClientShop': 'PORTA IL PRODOTTO IN UNO DEI NOSTRI NEGOZI VICINI A TE',
  'retcart.method.outvio': 'RESTITUISCI IL TUO RESO MEDIANTE IL NOSTRO CORRIERE',
  'retcart.method.ownCourier': 'INVIACI IL TUO RESO CON IL TUO CORRIERE',
  'retcart.method.exchange': 'CAMBIA O SOSTITUISCI I PRODOTTI INVECE DI RESTITUIRLI',
  'retcart.inst.toClientShop.heading':
    'CONFERMA IL TUO RESO E PORTA I PRODOTTI IN UN NEGOZIO VICINO A TE',
  'retcart.inst.toClientShop.text1':
    'PER FINALIZZARE IL TUO RESO Fai clic sul pulsante CONFERMA seguente:',
  'retcart.inst.toClientShop.text2':
    'Una scheda di reso verrà creata per te e dovrai portare i prodotti in uno dei nostri negozi prima di {dueDate}.',
  'retcart.inst.toClientShop.text3':
    'Puoi trovare un elenco completo dei nostri negozi qui: {link}.',
  'retcart.inst.toClientShop.text3.link': 'elenco di località dei negozi',
  'general.confirm': 'conferma',
  'retcart.inst.ownCourier.heading':
    'CONFERMA IL TUO RESO E SPEDISCI I PRODOTTI CON UN CORRIERE DI TUA SCELTA',
  'retcart.inst.ownCourier.text1':
    'PER FINALIZZARE IL TUO RESO Fai clic sul pulsante CONFERMA seguente:',
  'retcart.inst.ownCourier.text2':
    'Una scheda di reso verrà creata per te e dovrai accertarti di inviare i tuoi prodotti prima di {dueDate}.',
  'retcart.inst.ownCourier.text3': 'Dovrai inviare i prodotti a:',
  'retcart.inst.ownCourier.text4':
    'PS: Potrai utilizzare qualsiasi corriere desideri e dovrai pagare i costi del reso. Ti raccomandiamo di utilizzare un servizio con tracciamento fidato per garantire che il tuo reso ci pervenga in modo sicuro.',
  'retcart.inst.exchange.heading': 'COMUNICACI I DETTAGLI E CONFERMA LA TUA SOSTITUZIONE',
  'retcart.inst.exchange.text1':
    'Con quale prodotto, dimensione e quantità vorresti sostituire il(i) prodotto(i) attuale(i)?',
  'retcart.inst.exchange.text2':
    'Accertati di includere nel tuo messaggio tutti i dettagli necessari.',
  'retcart.inst.exchange.text.placeholder': 'Inserisci qui i dettagli della tua sostituzione.',
  'retcart.inst.exchange.text3': `Per finalizzare la tua sostituzione, fai clic su CONTINUA qui sotto.',
  
Riceveremo una e-mail con la tua richiesta di sostituzione e ti contatteremo per concordare i dettagli. Verrà anche inviata una copia della e-mail al tuo indirizzo di posta elettronica.`,
  'retcart.confirm-return-terms': 'Ho controllato il mio reso e accetto i {terms}',
  'retcart.confirm-return-terms.terms': 'Termini e Condizioni',
  'retcart.confirm.heading': 'RESO COMPLETATO',
  'retcart.confirm.text3': 'PER GARANTIRE CHE IL TUO RESO AVVENGA SENZA PROBLEMI:',
  'retcart.confirm.toClientShop.text4':
    '1. Stampa la scheda di reso o salva la copia elettronica che abbiamo inviato alla tua e-mail.',
  'retcart.confirm.toClientShop.text5': '2. Porta i prodotti a {fillerShop} prima di {dueDate}. ',
  'retcart.confirm.toClientShop.text5.fillerShop': 'un negozio vicino a te',
  'retcart.confirm.toClientShop.text6': '3. Accertati di portare la scheda di reso con te.',
  'retcart.download-return-sheet': 'SCARICA SCHEDA DI RESO',
  'retcart.download-label': 'SCARICA L´ETICHETTA E LA DISTINTA DI RESO',
  'retcart.confirm.ownCourier.text1':
    '1. Stampa la scheda di reso e mettila nel pacco insieme ai prodotti resi.',
  'retcart.confirm.ownCourier.text2':
    '2. Spedisci il tuo reso con un corriere tracciabile fidato di tua scelta a:',
  'retcart.confirm.ownCourier.text3': '3. Accertati di spedire il tuo reso prima di {dueDate}.',
  'retcart.confirm.outvio.text1':
    "IL TUO RESO È STATO ELABORATO CON SUCCESSO, SCARICA L'ETICHETTA DI SPEDIZIONE FACENDO CLIC SUL PULSANTE SEGUENTE",
  'retcart.confirm.outvio.text2': '(l’etichetta è anche stata inviata alla tua e-mail)',
  'retcart.confirm.outvio.text3':
    'PER GARANTIRE CHE NON CI SIANO PROBLEMI ALL’ARRIVO DEL CORRIERE:',
  'retcart.confirm.outvio.text4':
    '1. Stampa la scheda di reso e ritaglia l’etichetta di spedizione.',
  'retcart.confirm.outvio.text5':
    '2. Colloca la scheda di reso all’interno del pacco e applica l’etichetta sul pacco debitamente chiuso.',
  'retcart.confirm.outvio.text6':
    '3. Siediti, rilassati e aspetta che il corriere ritiri il tuo reso!',
  'retcart.inst.headline.important': 'IMPORTANTE:',
  'retcart.inst.hasPickup.noLabel': `1. Controlla il tuo indirizzo di ritiro e i prodotti del tuo reso. {lineBreak}
  2. Dopo aver fatto clic su "CONFERMA", verrà creato una distinta di reso. {lineBreak}
  3. Riponi gli articoli da restituire all'interno del pacco e chiudi la scatola. {fillerOptionally}, puoi anche aggiungere la distinta di reso all'interno della scatola. {lineBreak}
  4. Dopo aver fatto clic su Conferma, informeremo il corriere che verrà a ritirare il tuo reso nei prossimi 2 giorni lavorativi. IMPORTANTE! Non è necessario stampare alcuna etichetta di spedizione. Il corriere ne porterà una con se al momento del ritiro.`,
  'retcart.inst.hasPickup.noLabel.fillerOptionally': 'Facoltativo',
  'retcart.inst.hasPickup.byEmail': `1. Esamina l’indirizzo di ritiro e i prodotti del tuo reso.{lineBreak}
      2. Una volta fatto clic su "CONFERMA", una scheda di reso verrà creata e una richiesta di ritiro verrà inviata al nostro corriere. {fillerNotice}.{lineBreak}
      3. Una volta ricevuta l’etichetta di spedizione, colloca la scheda di reso all’interno del pacco, chiudilo e applica l’etichetta di spedizione sul pacco.{lineBreak}
      4. Il nostro corriere verrà a ritirare il tuo reso nei 2 giorni lavorativi successivi.`,
  'retcart.inst.hasPickup.byEmail.fillerNotice':
    "Riceverai l'etichetta di spedizione tramite email ({email}) entro i prossimi 2 giorni lavorativi.",
  'retcart.inst.hasPickup.provided': `1. Controlla il tuo indirizzo per il ritiro e i prodotti del tuo reso. {lineBreak}
  2. Dopo aver cliccato su "CONFERMA", verrà creata una distinta di reso. Stampala e ritaglia l'etichetta di spedizione per ogni pacco. {lineBreak}
  3. Metti gli articoli da restituire all'interno del pacco insieme al foglio di reso, attacca l'etichetta di spedizione e chiudi la scatola. {lineBreak}
  4. Il corriere verrà a ritirare il tuo reso nei prossimi 2 giorni lavorativi.`,
  'retcart.inst.noPickup.byEmail': `1. Controlla i prodotti presenti nel tuo reso. {lineBreak}
      2. Una volta fatto clic su "CONFERMA", verrà creata una scheda di reso. {fillerNotice}{lineBreak}
      3. Riceverai l’etichetta di spedizione di reso tramite e-mail. Una volta ricevuta l’etichetta di spedizione, colloca la scheda di reso all’interno del pacco, chiudilo e applica l’etichetta di spedizione sul pacco.{lineBreak}
      4. Dovrai portare il pacco o i pacchi a un punto di ritiro del correre vicino a te entro 5 giorni lavorativi. Per trovare il punto di ritiro più vicino a te visita la homepage del corriere {fillerCourierHomepage}.`,
  'retcart.inst.noPickup.byEmail.fillerNotice':
    ' Riceverai l’etichetta di spedizione via e-mail a ({email}) entro i 2 giorni successivi.',
  'retcart.inst.noPickup.byEmail.fillerCourierHomepage': 'qui',
  'retcart.inst.noPickup.provided': `1. Controlla i prodotti presenti nel tuo reso. {lineBreak}
      2. Una volta fatto clic su "CONFERMA", verrà creata una scheda di reso. Stampa e ritaglia l’etichetta di spedizione per ciascun pacco del tuo reso.{lineBreak}
      3. Colloca gli articoli di reso all’interno del pacco con la scheda di reso, applica l’etichetta di spedizione e chiudi la scatola.{lineBreak}
      4. Dovrai portare il pacco o i pacchi a un punto di ritiro del corriere vicino a te. Per trovare il punto di ritiro più vicino a te visita la homepage del corriere {fillerCourierHomepage}.`,
  'retcart.inst.noPickup.provided.fillerCourierHomepage': 'qui',
  'retcart.confirm.outvio.head-sheet':
    'IL TUO RESO É STATO ELABORATO CON SUCCESSO, SCARICA LA SCHEDA DI RESO FACENDO CLIC SUL PULSANTE SEGUENTE',
  'retcart.confirm.outvio.head-sheet.note':
    '(la scheda di reso è stata anche inviata alla tua e-mail):',
  'retcart.confirm.outvio.head-label':
    "IL TUO RESO È STATO ELABORATO CON SUCCESSO, SCARICA L'ETICHETTA DI SPEDIZIONE FACENDO CLIC SUL PULSANTE SEGUENTE",
  'retcart.confirm.outvio.head-label.note': '(l’etichetta è anche stata inviata alla tua e-mail)',
  'retcart.confirm.outvio.hasPickup.noLabel.head':
    'PER GARANTIRE CHE NON CI SIANO PROBLEMI ALL’ARRIVO DEL CORRIERE:',
  'retcart.confirm.outvio.hasPickup.noLabel': `1. Stampa la distinta di reso (facoltativo). {lineBreak}
  2. Includi la distinta di reso nel pacco chiuso in modo sicuro. NESSUNA ETICHETTA DI SPEDIZIONE È NECESSARIA! {lineBreak}
  3. Il corriere ritirerà il tuo reso entro i prossimi 2 giorni lavorativi e porterà con sè l'etichetta per la spedizione.`,
  'retcart.confirm.outvio.hasPickup.byEmail.head':
    'PS: L’ETICHETTA DI SPEDIZIONE VERRÀ INVIATA ALLA TUA E-MAIL ENTRO I PROSSIMI GIORNI.',
  'retcart.confirm.outvio.hasPickup.byEmail': `1. Una volta ricevuta l’etichetta di spedizione, stampala insieme alla scheda di reso.{lineBreak}
      2. Colloca la scheda di reso all’interno del pacco, chiudilo e applica l’etichetta di spedizione su di esso.{lineBreak}
      3. Il corriere verrà a ritirare il tuo reso entro i 2 giorni lavorativi successivi.`,
  'retcart.confirm.outvio.hasPickup.provided': `1. Stampa la scheda di reso e ritaglia l’etichetta di spedizione.{lineBreak}
      2. Colloca la scheda di reso all’interno del pacco, chiudilo e applica l’etichetta di spedizione su di esso.{lineBreak}
      3. Il corriere verrà a ritirare il tuo reso entro i 2 giorni lavorativi successivi.`,
  'retcart.confirm.outvio.noPickup.byEmail': `1. Una volta ricevuta l'etichetta di spedizione via e-mail, stampala insieme alla scheda di reso.{lineBreak}
      2. Colloca la scheda di reso all’interno del pacco, chiudilo e applica l’etichetta di spedizione su di esso.{lineBreak}
      3. Porta il tuo pacco a un punto di ritiro del corriere vicino a te. Per trovare il punto di ritiro più vicino visita la homepage del corriere {filler}.`,
  'retcart.confirm.outvio.noPickup.byEmail.filler': 'qui',
  'retcart.confirm.outvio.noPickup.provided': `1. Stampa la scheda di reso e ritaglia l’etichetta di spedizione.{lineBreak}
      2. Colloca la scheda di reso all’interno del pacco, chiudilo e applica l’etichetta di spedizione su di esso.{lineBreak}
      3. Porta il tuo pacco a un punto di ritiro del corriere vicino a te. Per trovare il punto di ritiro più vicino visita la homepage del corriere {filler}.`,
  'retcart.confirm.outvio.noPickup.provided.filler': 'qui',
  'general.retcart.restart.btn': 'Riavvia processo di reso',
  'general.heading.refund.methods': 'CON QUALE MODALITÀ DESIDERI RICEVERE IL RIMBORSO?',
  'retcart.method.SAME_AS_PAYMENT': 'RIMBORSO SECONDO IL MIO METODO DI PAGAMENTO ORIGINALE',
  'retcart.method.CREDIT_IN_STORE': 'CONSERVA IL CREDITO PER ACQUISTI FUTURI',
  'retcart.confirm.exchange.heading': 'RICHIESTA DI SOSTITUZIONE COMPLETATA',
  'retcart.confirm.exchange.text1':
    'LA TUA RICHIESTA DI SOSTITUZIONE È STATA RICEVUTA CON SUCCESSO',
  'retcart.confirm.exchange.text2':
    '(Una copia della tua richiesta di sostituzione è stata inviata anche al tuo indirizzo di posta elettronica).',
  'retcart.confirm.exchange.text3':
    'Ti contatteremo a breve per farti conoscere i dettagli della tua sostituzione.',
  'retcart.confirm.exchange.text4': 'GRAZIE!',
  'general.cancel': 'annulla',
  'general.retcart.init-restart':
    'se questo problema persiste, si prega di riavviare il procedimento cliccando QUI',
  'retcart.return-method.toClientShop': 'IN NEGOZIO',
  'retcart.return-method.outvio': 'IL NOSTRO CORRIERE',
  'retcart.return-method.ownCourier': 'IL PROPRIO CORRIERE',
  'retcart.return-method.exchange': 'CAMBIARE / SOSTITUIRE',
  'retcart.return-method.SAME_AS_PAYMENT': 'RIMBORSATO',
  'retcart.return-method.CREDIT_IN_STORE': 'CREDITO DI ACQUISTO',
  'retcart.return-method.pickup': 'RITIRO',
  'retcart.return-method.dropoff': 'CONSEGNA',
  'general.heading.pickup.methods': 'VUOI CONSEGNARE IL TUO PACCO O FARLO RITIRARE DAL CORRIERE?',
  'retcart.method.pickup': 'DESIDERO CHE IL CORRIERE VENGA A RITIRARE IL RESO A CASA',
  'retcart.method.dropoff': 'PORTERÒ IO STESSO IL PACCO PRESSO UN PUNTO DI RITIRO DEL CORRIERE',
  'retcart.img-attach-desc':
    "Carica le immagini dell'imballaggio, del prodotto e dell'etichetta di spedizione",
  'retcart.confirm.outvio.noPickup.noLabel.head':
    'IMPORTANTE! PRIMA DI CONSEGNARE ILRESO AD UN PUNTO DI RICONSEGNA, PER FAVORE:',
  'retcart.title.pickup': 'ritiro',
  'retcart.title.dropoff': 'consegna',
  'general.tracking.manage-exception': 'Gestire l´inesattezze sul sito del corriere',
  'general.error.id-ver-token-missing': `È necessario il codice per l'autenticazione. Si prega di controllare l'e-mail che è stata inviata per l'autenticazione.`,
  'general.error.id-ver.token-missing': `Errore nell'inizializzazione. Assicurati di usare un browser aggiornato ed efficiente.`,
  'general.error.fileupload.file-too-large': 'Dimensione massima del file {size} MB',
  'general.error.fileupload.too-many-files': 'Massimo{number} file(s)',
  'general.error.fileupload.file-invalid-type': 'Formato del file non valido',
  'general.error.fileupload.incorrect.size.max': `La risoluzione dell'immagine deve essere al massimo {width} per {height}`,
};
