export default {
  FALLBACK_ERROR_MESSAGE: 'Something went wrong. Please try again later.',
  COURIER_ERROR_FALLBACK: 'Courier error.',
  TOKEN_NOT_FOUND: 'Token not found.',
  IDENTITY_VERIFICATION_FAILED: 'Identity verification could not be completed.',
  ACCOUNT_BLOCKED: 'Your account is blocked. Please check your email.',
  WRONG_EMAIL_OR_PASS: 'E-mail and/or password are incorrect.',
  INVALID_CREDENTIALS: 'Invalid credentials.',
  INVALID_TOKEN: 'Session expired, please request new email validation link in the return portal.',
  MALFORMED_TOKEN: 'Malformed token.',
  CUSTOMER_ALREADY_CREATED: 'Customer account already created. Please log in instead.',
  SHIPPING_METHOD_NOT_SUPPORTED: 'Shipping method not supported.',
  SHIPMENT_ALREADY_PICKED_UP: 'Shipment already picked up.',
  NO_RATE_FOR_DESTINATION: 'No rate available for the destination.',
  BUILDING_LABEL_FAILED: 'Building label failed.',
  COURIER_NOT_SUPPORTED: 'Courier not supported.',
  PICKUP_REQUEST_FAILED: 'Pickup Request failed.',
  ERROR_TRY_AGAIN_LATER: 'Something went wrong. Please try again later.',
  EMAIL_ALREADY_REGISTERED: 'Email already registered.',
  INVALID_PASSWORD: 'Invalid password.',
  LABEL_ERROR: 'Label error.',
  UNDER_MAINTENANCE: "We're under maintenance. Outvio will be back online soon.",
  MERGING_PDFS_FAILED: 'Merging pdfs failed',
  EXPIRE_TOKEN: 'Token expired',
  GENERIC_MISSING_PARAM: 'Required paramaters missing.',
  ORDER_NOT_FOUND: 'Order not found.',
};
