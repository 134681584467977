import { useCallback, useEffect, useMemo, useState } from 'react';

import { Subscription } from 'app-types';
import { deskActivate, subDeactivate } from 'data-layer';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { ChevronDownIcon, ErrorFilled } from 'icons';
import { FormattedMessage } from 'localization';
import { twMerge } from 'tailwind-merge';
import { match } from 'ts-pattern';

import { ActionIcon, ActionIconTheme, Button, Copy, Heading } from '../../atoms';
import { CancellationConfirmWindow, CancellationType } from './CancellationConfirmWindow';

dayjs.extend(utc);

const SubCancelReminder = ({
  subscription,
  onSubscriptionUpdate,
}: {
  subscription: Subscription;
  onSubscriptionUpdate: () => Promise<void>;
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
  const [cancellationTypeConfirm, setCancellationTypeConfirm] =
    useState<CancellationType>('downgrade-plan');

  const onCancel = useCallback(
    async (selectedType: CancellationType) => {
      try {
        setIsLoading(true);

        setCancellationTypeConfirm(selectedType);

        if (selectedType === 'cancel-desk') {
          await deskActivate(true);
        } else {
          await subDeactivate(false);
        }

        await onSubscriptionUpdate();

        setIsConfirmationOpen(true);
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoading(false);
      }
    },
    [subscription],
  );

  useEffect(() => {
    setIsOpen(false);
  }, [subscription]);

  const isOnTrial = useMemo(
    () => subscription.endPromo && dayjs.utc(subscription.endPromo).isAfter(),
    [subscription],
  );

  const formattedDeadlineDate = useCallback(
    (value: string) =>
      new Intl.DateTimeFormat('en-GB', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
      }).format(new Date(value)),
    [subscription],
  );

  if (!subscription) {
    return <></>;
  }

  const isCancel = subscription.cancelSubscription;
  const isDowngrade =
    !!subscription.nextPlan &&
    subscription.nextPlan !== subscription.plan &&
    (subscription?.amount || 0) > (subscription?.nextAmount || 0);
  const isDeskDonwgrade = !!subscription?.addons?.some(
    (addon) => addon.type === 'desk' && !!addon.expiredOn,
  );

  const deskCancellingDate = isDeskDonwgrade
    ? subscription?.addons?.find((addon) => addon.type === 'desk')?.expiredOn
    : '';

  const { titleKey, descriptionKey, formattedDeadline, buttonLabelKey, cancellation } =
    match<boolean>(true)
      .with(isCancel, () => ({
        titleKey: 'settings.subs.cancel.reminder-widget.title',
        descriptionKey: 'settings.subs.cancel.reminder-widget.description',
        formattedDeadline: subscription.nextRebill
          ? formattedDeadlineDate(subscription.nextRebill)
          : '',
        buttonLabelKey: 'settings.subs.cancel.keep-current-plan',
        cancellation: 'cancel-subscription' as CancellationType,
      }))
      .with(isDowngrade, () => ({
        titleKey: 'settings.subs.downgrade.reminder-widget.title',
        descriptionKey: 'settings.subs.downgrade.reminder-widget.description',
        formattedDeadline: subscription.nextRebill
          ? formattedDeadlineDate(subscription.nextRebill)
          : '',
        buttonLabelKey: 'settings.subs.cancel.keep-current-plan',
        cancellation: 'downgrade-plan' as CancellationType,
      }))
      .with(isDeskDonwgrade, () => ({
        titleKey: 'settings.subs.desk-cancel.reminder-widget.title',
        descriptionKey: 'settings.subs.desk-cancel.reminder-widget.description',
        formattedDeadline: deskCancellingDate ? formattedDeadlineDate(deskCancellingDate) : '',
        buttonLabelKey: 'settings.subs.cancel.keep-desk',
        cancellation: 'cancel-desk' as CancellationType,
      }))
      .otherwise(() => ({
        titleKey: '',
        descriptionKey: '',
        formattedDeadline: '',
        buttonLabelKey: '',
        cancellation: undefined,
      }));

  const showReminder = (isCancel || isDowngrade || isDeskDonwgrade) && !isOnTrial;

  return (
    <>
      {showReminder && (
        <div
          className={twMerge(
            'z-10 p-4 fixed bottom-6 w-[332px] bg-v2red bg-opacity-[.88] rounded-[22px] align-middle inset-x-[calc(50%-166px)] transition-all duration-300 overflow-hidden ease-out',
            isOpen && 'max-h-[500px]',
            !isOpen && ' max-h-[68px]',
          )}
        >
          <div className="flex justify-between">
            <div className="flex items-center gap-3">
              <div className="w-9 h-9 rounded-[10px] bg-white/20 flex items-center justify-center text-white text-[24px]">
                <ErrorFilled />
              </div>
              <Heading type="heading6" className="text-white">
                <FormattedMessage id={titleKey} />
              </Heading>
            </div>
            <ActionIcon
              active={isOpen}
              onClick={() => setIsOpen((state) => !state)}
              theme={ActionIconTheme.light}
            >
              <ChevronDownIcon
                className={twMerge(
                  'rotate-180 transition-transform duration-300',
                  isOpen && 'rotate-0',
                )}
              />
            </ActionIcon>
          </div>
          <div
            className={twMerge(
              'ml-[48px] invisible translsition-all duration-300 opacity-0 ease-out overflow-hidden',
              !isOpen && 'max-h-0',
              isOpen && 'max-h-[300px] mt-2 visible opacity-100',
            )}
          >
            <Copy className="text-white/70">
              <FormattedMessage
                id={descriptionKey}
                values={{
                  date: formattedDeadline,
                  targetPlan: subscription?.nextPlan?.toUpperCase(),
                }}
              />
            </Copy>

            <Button loading={isLoading} onClick={() => onCancel(cancellation!)} className="mt-4">
              <FormattedMessage id={buttonLabelKey} />
            </Button>
          </div>
        </div>
      )}

      <CancellationConfirmWindow
        cancellationType={cancellationTypeConfirm}
        isOpen={isConfirmationOpen}
        onClose={() => setIsConfirmationOpen(false)}
      />
    </>
  );
};

export { SubCancelReminder };
