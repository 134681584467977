import { FormattedMessage } from 'react-intl';

import { Copy } from '../../../atoms';

type TotalPriceInfoSectionProps = {
  priceTitle: string;
  price: string;
  additionalInfoTitle: string;
  additionalInfo: string;
  nextBillingDate: string;
};

const TotalPriceInfoSection = ({
  priceTitle = '',
  price,
  additionalInfoTitle = '',
  additionalInfo,
  nextBillingDate,
}: TotalPriceInfoSectionProps) => {
  return (
    <div className="px-4 flex-1 flex flex-col justify-center">
      <div className="flex justify-between px-4 mb-1">
        <Copy as="span" type="copy3" className="text-v2blueGray-500">
          {priceTitle}
        </Copy>
        <Copy as="span" type="copy3" className="text-v2blueGray-500">
          {additionalInfoTitle}
        </Copy>
      </div>
      <div className="flex justify-between px-4">
        <Copy as="span" className="tracking-[-0.196px]" type="copy1" weight="medium">
          {price}
        </Copy>
        <Copy as="span" type="copy1" weight="medium" className="tracking-[-0.196px]">
          {additionalInfo}
        </Copy>
      </div>
      <div className="my-6 border-b border-dashed border-v2blueGray-200" />
      <div className="flex justify-between px-4">
        <Copy as="span" type="copy1" className="tracking-[-0.196px]">
          <FormattedMessage id="settings.subs.c-plan.next-date" />
        </Copy>
        <Copy as="span" type="copy1" weight="medium" className="tracking-[-0.196px]">
          {nextBillingDate}
        </Copy>
      </div>
    </div>
  );
};

export default TotalPriceInfoSection;
