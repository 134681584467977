import { gql } from '@apollo/client';

export const UserProfileUpdateMutation = gql`
  mutation User_updateProfile($input: UpdateUserProfileInput!) {
    User_updateProfile(input: $input) {
      id
      firstname
      lastname
      emailOnlySignature
      signatures {
        language
        signature
      }
    }
  }
`;

export const WelcomeInfoUpdateMutation = gql`
  mutation Store_onboarding(
    $storeOnboarding: StoreOnboardingInput!
    $user: UpdateUserProfileInput!
    $brand: UpdateBrandInput!
  ) {
    Store_onboarding(input: $storeOnboarding) {
      company {
        address
        city
        contactEmail
        contactPhone
        countryCode
        name
        postcode
      }
      promoCode
      id
    }
    User_updateProfile(input: $user) {
      firstname
      lastname
      id
    }
    Brand_updateDefault(input: $brand) {
      brandUrl
      id
    }
  }
`;

export const WelcomeStatisticsUpdateMutation = gql`
  mutation Store_update($input: StoreUpdateInput!) {
    Store_update(input: $input) {
      statistics {
        shipmentsPerMonth
        whatCanWeDo
        clientSource
        partnerName
      }
      hasPendingOnboarding
      id
      onboardingSteps {
        step
        completed
      }
    }
  }
`;

export const CompanyInfoUpdateMutation = gql`
  mutation Store_update($input: StoreUpdateInput!) {
    Store_update(input: $input) {
      company {
        name
        url
        address
        postcode
        city
        country
        countryCode
        vat
        registrationNumber
        contactEmail
        contactPhone
        supportEmail
      }
      id
    }
  }
`;

export const TrackingModeMutation = gql`
  mutation Store_update($input: StoreUpdateInput!) {
    Store_update(input: $input) {
      id
      onboardingSteps {
        step
        completed
      }
    }
  }
`;

export const SkipOnboardingStepMutation = gql`
  mutation Store_update($step: OnboardingSteps!) {
    Store_skipOnboardingStep(step: $step) {
      id
      onboardingSteps {
        step
        completed
      }
    }
  }
`;

export const FeatureRequestMutation = gql`
  mutation Store_featureRequest($feature: StoreFeature!) {
    Store_featureRequest(feature: $feature)
  }
`;

export const BrandUpdateSlugMutation = gql`
  mutation Brand_updateSlug($input: UpdateSlugInput!) {
    Brand_updateSlug(input: $input) {
      id
      slug
      trackingSlug
      logo
      brandUrl
      type
    }
  }
`;

export const BrandCreateCustomMutation = gql`
  mutation Brand_createCustom($input: CreateCustomBrandInput!) {
    Brand_createCustom(input: $input) {
      id
      slug
      trackingSlug
      logo
      brandUrl
      type
    }
  }
`;
