import { ReactNode, memo } from 'react';

import cx from 'classnames';
import { Delete } from 'icons';

interface PanelProps {
  className?: string;
  children?: ReactNode;
  haveDeleteButton?: boolean;
  onDeleteClick?: () => void;
}

export function Panel({
  children,
  className,
  haveDeleteButton,
  onDeleteClick,
  ...rest
}: PanelProps) {
  return (
    <div
      className={cx(
        'p-6 rounded-md border-ui-gray-lighter border-[1px] border-solid flex items-center justify-between',
        className,
      )}
      {...rest}
    >
      <div>{children}</div>
      {haveDeleteButton && (
        <button
          className="flex items-center justify-center w-10 h-10 rounded-md hover:bg-ui-blueGray hover:bg-opacity-[0.15]"
          type="button"
          onClick={onDeleteClick}
        >
          <Delete />
        </button>
      )}
    </div>
  );
}

export default memo(Panel);
