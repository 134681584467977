export default {
  'orders.package': 'Pacco',
  'orders.returns.reasons.size': 'Taglia non corrispondente',
  'orders.returns.reasons.wrong-product': 'Prodotto errato consegnato',
  'orders.returns.reasons.wrong-size': 'Taglia errata consegnata',
  'orders.returns.reasons.wrong-colour': 'Colore errato',
  'orders.returns.reasons.package-broken': 'Imballaggio prodotto rotto',
  'orders.returns.reasons.product-broken': 'Prodotto rotto/difettoso',
  'orders.returns.reasons.product-used': 'Prodotto usato',
  'orders.returns.reasons.product-dirty': 'Prodotto sporco',
  'orders.returns.reasons.do-not-want': 'Non lo voglio più',
  'orders.returns.reasons.exchange': 'Vorrei cambiare il prodotto',
  'orders.returns.reasons.other': 'Altro',
  'orders.returns.pickup.form.comments': 'Commenti',
  'orders.list.empty': 'Attualmente non hai ordini',
  'orders.total': 'Totale',
  'orders.vat': 'IVA',
  'or.confirm-address': 'CONFERMA IL TUO INDIRIZZO DI RITIRO',
  'or.from': 'Da',
  'or.to': 'Fino a',
  'or.return.list.empty': 'Attualmente non hai resi',
  'or.confirm-return': 'CONFERMA LA RESTITUZIONE',
};
