import { memo } from 'react';

import { CheckMark } from 'icons';
import { twMerge } from 'tailwind-merge';
import { match } from 'ts-pattern';
import { Copy } from 'ui/atoms';

export type CheckBoxProps = {
  className?: string;
  labelClassName?: string;
  label?: string;
  disabled?: boolean;
  checked: boolean;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  readOnly?: boolean;
  theme?: 'primary' | 'info' | 'dark';
};

export function CheckBox({
  className,
  labelClassName,
  disabled,
  label,
  checked,
  onChange,
  readOnly,
  theme = 'primary',
}: CheckBoxProps) {
  const checkedThemeStyling = match(theme)
    .with(
      'primary',
      () => 'bg-primary border-primary drop-shadow-[0px_2px_4px_rgba(75,180,179,0.2)]',
    )
    .with('info', () => 'bg-v2blue border-v2blue drop-shadow-[0px_2px_4px_rgba(0,122,255,0.2)]')
    .with(
      'dark',
      () => 'bg-v2blueGray-800 border-v2blueGray-800 drop-shadow-[0px_2px_4px_rgba(35,56,65,0.2)]',
    )
    .exhaustive();

  return (
    <div className={twMerge('flex cursor-pointer', disabled && 'cursor-not-allowed', className)}>
      <div className="w-[18px] h-[18px] mt-[0.15em] block relative select-none">
        <input
          className="appearance-none"
          type="checkbox"
          readOnly={readOnly}
          disabled={disabled}
          checked={checked}
          onChange={onChange}
        />
        <span
          className={twMerge(
            'absolute inset-0 h-full w-full rounded-md border-2 pointer-events-none',
            checked && checkedThemeStyling,
            !checked && 'bg-white border-v2blueGray-200',
          )}
        />
        {checked && (
          <CheckMark
            width="11"
            height="11"
            className="absolute top-1/2 left-1/2 translate-x-[-50%] translate-y-[-60%] pointer-events-none text-white"
          />
        )}
      </div>
      {label && (
        <label className={twMerge('cursor-pointer', disabled && 'opacity-40 cursor-not-allowed')}>
          <Copy className={twMerge('ml-2 text-v2blueGray-700', labelClassName)}>{label}</Copy>
        </label>
      )}
    </div>
  );
}

export default memo(CheckBox);
