import { gql } from '@apollo/client';

import {
  ATTACHMENT_FRAGMENT,
  BRAND_SETTINGS_FRAGMENT,
  CHAT_LANGUAGES_FRAGMENT,
  CHAT_TRANSLATIONS_FRAGMENT,
  COMMENT_FRAGMENT,
  EMAIL_CONFIGURATION_FRAGMENT,
  FOLDER_FRAGMENT,
  MESSAGE_COMMENT_FRAGMENT,
  PORTAL_DOMAINS_FORM_FLAGS_AND_SLUG_FRAGMENT,
  ROUTING_RULES_SCHEMA_FRAGMENT,
  ROUTING_RULE_FRAGMENT,
  SIGNATURE_FRAGMENT,
  STORE_ENTITY_FRAGMENT,
  TAG_FRAGMENT,
  TEAM_FRAGMENT,
  TEMPLATE_FRAGMENT,
  TICKET_FRAGMENT,
  TICKET_FRAGMENT_SHORT,
  TICKET_MESSAGES_FRAGMENT,
} from './fragments';

export const VOICE_LIST_PHONE_NUMBERS = gql`
  query Voice_ListPhoneNumbers {
    numbers: Voice_ListPhoneNumbers {
      brandId
      phone
    }
  }
`;

export const TEAM_GET_QUERY = gql`
  query GET_TEAMS_QUERY {
    Team_List {
      ...TeamFragment
    }
  }
  ${TEAM_FRAGMENT}
`;

export const GET_SIGNATURE_QUERY = gql`
  query GET_SIGNATURE_QUERY {
    Agent_MyProfile {
      ...SignatureFragment
    }
  }
  ${SIGNATURE_FRAGMENT}
`;

export const GET_TAGS_QUERY = gql`
  query GET_TAGS_QUERY {
    Store_MyStore {
      ...TagFragment
    }
  }
  ${TAG_FRAGMENT}
`;

export const GET_COMPANY_FOLDERS_QUERY = gql`
  query Folder_CompanyList {
    Folder_CompanyList {
      ...FolderFragment
    }
  }
  ${FOLDER_FRAGMENT}
`;

export const GET_PERSONAL_FOLDERS_QUERY = gql`
  query Folder_PersonalList {
    Folder_PersonalList {
      ...FolderFragment
    }
  }
  ${FOLDER_FRAGMENT}
`;

export const GET_STORE_DATA_QUERY = gql`
  query Store_MyStore {
    Store_MyStore {
      ...StoreEntityFragment
    }
  }
  ${STORE_ENTITY_FRAGMENT}
`;

export const GET_TEMPLATES_QUERY = gql`
  query Template_List($language: Languages) {
    Template_List(language: $language) {
      ...TemplateFragment
    }
  }
  ${TEMPLATE_FRAGMENT}
`;

export const GET_COURIER_CONTACT_LIST = gql`
  query CourierContact_List {
    CourierContact_List {
      id
      courierType
      emails {
        domain
        email
      }
      store
    }
  }
`;

export const GET_MY_FOLDERS_QUERY = gql`
  query Folder_List {
    Folder_List {
      ...FolderFragment
    }
  }
  ${FOLDER_FRAGMENT}
`;

export const GET_TICKETS_QUERY = gql`
  query Ticket_List($limit: Int!, $status: TicketStatuses!, $after: Int, $folder: String!) {
    Ticket_List(limit: $limit, status: $status, after: $after, folder: $folder) {
      ...TicketFragmentShort
    }
  }
  ${TICKET_FRAGMENT_SHORT}
`;

export const GET_SIGNED_URL_QUERY = gql`
  query GetSignedUrl($key: String!) {
    MediaFile_GetSignedUrl(key: $key)
  }
`;

export const GET_TEMPLATE_VARIABLES_QUERY = gql`
  query GetTemplateVariables {
    Template_Variables {
      agent
      customer
      order
      return
    }
  }
`;

export const GET_TEMPLATE_WITH_VARIABLES = gql`
  query Template_GetTemplateWithVariables($templateId: String!, $ticketId: String) {
    Template_GetTemplateWithVariables(templateId: $templateId, ticketId: $ticketId) {
      body
      bodyJson
    }
  }
`;

export const GET_TICKET_QUERY = gql`
  query Ticket_GetOne($ticketGetOneId: String, $ticketId: String) {
    Ticket_GetOne(id: $ticketGetOneId, ticketId: $ticketId) {
      ...TicketFragment
    }
  }
  ${TICKET_FRAGMENT}
`;

export const GET_TICKET_BY_CALL_QUERY = gql`
  query Ticket_GetOneByCall($callId: String!) {
    ticket: Ticket_GetOneByCall(callId: $callId) {
      status
      id
    }
  }
`;

export const GET_PORTAL_DOMAINS_FORM_FLAGS_AND_SLUG_QUERY = gql`
  query Brand($brandId: String!) {
    brand(id: $brandId) {
      ...PortalDomainsFormFlagsAndSlugFragment
    }
  }
  ${PORTAL_DOMAINS_FORM_FLAGS_AND_SLUG_FRAGMENT}
`;

export const BRAND_SETTINGS_QUERY = gql`
  query BrandSettingsQuery($id: String!) {
    brand(id: $id) {
      ...BrandSettings
    }
  }
  ${BRAND_SETTINGS_FRAGMENT}
`;

export const GET_TICKET_MESSAGES_QUERY = gql`
  query Message_FindAllByTicketId($ticketId: String!) {
    Message_FindAllByTicketId(ticketId: $ticketId) {
      ...TicketMessagesFragment
    }
  }
  ${TICKET_MESSAGES_FRAGMENT}
`;

export const GET_TICKET_LOGS_QUERY = gql`
  query TicketLog_FindByTicket($ticketId: String!) {
    TicketLog_FindByTicket(ticketId: $ticketId) {
      assignee
      status
      tags
      id
      triggeredByUser
      type
      createdAt
      triggeredByChat
    }
  }
`;

export const GET_ROUTING_RULES_SCHEMA_QUERY = gql`
  query Rule_GetSchema {
    Rule_GetSchema {
      ...RoutingRulesSchemaFragment
    }
  }
  ${ROUTING_RULES_SCHEMA_FRAGMENT}
`;

export const GET_ROUTING_RULES_QUERY = gql`
  query Rule_List {
    Rule_List {
      ...RoutingRuleFragment
    }
  }
  ${ROUTING_RULE_FRAGMENT}
`;

export const GET_EMAIL_CONFIGURATION_QUERY = gql`
  query EmailConfiguration_List {
    EmailConfiguration_List {
      ...EmailConfigurationFragment
    }
  }
  ${EMAIL_CONFIGURATION_FRAGMENT}
`;

export const GET_MESSAGE_COMMENTS_QUERY = gql`
  query MessageComment_FindAllByTicketId($ticketId: String!) {
    MessageComment_FindAllByTicketId(ticketId: $ticketId) {
      ...MessageCommentFragment
    }
  }
  ${MESSAGE_COMMENT_FRAGMENT}
`;

export const GET_FOLDER_COUNT_ALL_QUERY = gql`
  query Folder_CountAll {
    Folder_CountAll {
      data {
        count
        id
      }
    }
  }
`;

export const GET_FOLDER_COUNT_ONE_QUERY = gql`
  query Folder_CountOne($folderCountOneId: String!, $status: TicketStatuses) {
    Folder_CountOne(id: $folderCountOneId, status: $status) {
      count
    }
  }
`;

export const GET_TICKET_CUSTOMER_RELATED_TICKETS_QUERY = gql`
  query Ticket_GetOne_CustomerRelatedTickets($ticketGetOneId: String!) {
    Ticket_GetOne(id: $ticketGetOneId) {
      id
      customerRelatedTickets {
        ...TicketFragmentShort
      }
    }
  }
  ${TICKET_FRAGMENT_SHORT}
`;

export const GET_TICKET_RELATED_TICKETS_TAGS_WATCHERS = gql`
  query Ticket_GetOne_Related_Tickets_Tags_Watchers($ticketGetOneId: String!) {
    Ticket_GetOne(id: $ticketGetOneId) {
      id
      tags
      watchers
      relatedTickets {
        ...TicketFragmentShort
      }
    }
  }
  ${TICKET_FRAGMENT_SHORT}
`;

export const GET_TICKET_CUSTOMER_ORDER_HISTORY_QUERY = gql`
  query Ticket_CustomerOrderHistory($customerEmail: String!) {
    Ticket_CustomerOrderHistory(customerEmail: $customerEmail) {
      data {
        orderOtn
        orderId
        courierIntegrationName
        status
      }
      event
    }
  }
`;

export const GET_TICKET_CUSTOMER_INTERACTION_HISTORY_QUERY = gql`
  query Ticket_CustomerInteractionHistory($customerEmail: String!) {
    Ticket_CustomerInteractionHistory(customerEmail: $customerEmail) {
      data {
        orderOtn
        ticketChannel
        ticketId
        date
      }
      event
    }
  }
`;

export const GET_TICKET_CUSTOMER_RELATED_TICKETS_BY_EMAIL_QUERY = gql`
  query Ticket_CustomerRelatedTicketsByEmail($customerEmail: String, $customerPhone: String) {
    Ticket_CustomerRelatedTickets(customerEmail: $customerEmail, customerPhone: $customerPhone) {
      ...TicketFragmentShort
    }
  }
  ${TICKET_FRAGMENT_SHORT}
`;

export const GET_TICKET_CUSTOMER_SEARCH_QUERY = gql`
  query Ticket_CustomerSearch($customerEmail: String, $customerPhone: String) {
    Ticket_CustomerSearch(customerEmail: $customerEmail, customerPhone: $customerPhone) {
      addresses {
        address
        city
        contactPhone
        country
        postcode
        province
        state
      }
      firstname
      lastname
      email
    }
  }
`;

export const GET_CHAT_CONFIGURATION_BY_BRAND_QUERY = gql`
  query ChatConfiguration_GetByBrand($brandId: ID!) {
    ChatConfiguration_GetByBrand(brandId: $brandId) {
      id
      chatToken
      companyName
      offlineEmailId
      logo {
        ...AttachmentFragment
      }
      primaryColor
      secondaryColor
      supportTeam
      languages {
        ...ChatLanguagesFragment
      }
      automation {
        findOrder
        returnExchange
      }
    }
  }
  ${ATTACHMENT_FRAGMENT}
  ${CHAT_LANGUAGES_FRAGMENT}
`;

export const GET_CHAT_AVAILABILITY_BY_BRAND_QUERY = gql`
  query ChatAvailability_GetByBrand($brandId: ID!) {
    ChatAvailability_GetByBrand(brandId: $brandId) {
      brand
      id
      days {
        day
        from
        isEnabled
        to
      }
      timezone
    }
  }
`;

export const GET_CHAT_TRANSLATION_BY_BRAND_QUERY = gql`
  query ChatTranslation_GetByBrand($brandId: ID!, $languages: [Languages!]!) {
    ChatTranslation_GetByBrand(brandId: $brandId, languages: $languages) {
      id
      language
      values {
        ...ChatTranslationsFragment
      }
    }
  }
  ${CHAT_TRANSLATIONS_FRAGMENT}
`;

export const GET_COMMENTS_BY_TICKET_ID_QUERY = gql`
  query Comment_FindAllByTicketId($ticketId: ID!) {
    Comment_FindAllByTicketId(ticketId: $ticketId) {
      ...CommentFragment
    }
  }
  ${COMMENT_FRAGMENT}
`;

export const RatingPageSettingsQuery = gql`
  query RatingPage_FindByBrand($brandId: String!) {
    pages: RatingPage_FindByBrand(brandId: $brandId) {
      buttonText
      description
      id
      language
      title
    }
    settings: RatingRule_GetSettings(brandId: $brandId) {
      id
      ratingEnabled
      timeUnit
      timeValue
      whenTime
    }
  }
`;

export const GET_TICKETS_BY_SEARCH_QUERY = gql`
  query Search($limit: Float, $searchAfter: String, $query: String!) {
    Search(limit: $limit, searchAfter: $searchAfter, query: $query) {
      count
      results {
        id
        status
        updatedAt
        courierType
        type
        channel
        ticketId
        messagePreview
        subject
        paginationToken
        recipientName
      }
      success
    }
  }
`;
