import { ReactNode, useRef } from 'react';

import { Transition } from '@headlessui/react';

const PortalTransition = ({
  className,
  children,
  open,
  classNameContainer,
}: {
  children: ReactNode;
  open: boolean;
  className?: string;
  classNameContainer?: string;
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const refContent = useRef<HTMLDivElement>(null);

  const sizeObserver = new ResizeObserver((entries: ResizeObserverEntry[]) => {
    if (!ref?.current) return;
    const newHeight = entries[0].contentRect.height;
    const oldHeight = parseInt(ref.current.style.getPropertyValue(`max-height`));
    if (newHeight > oldHeight) {
      ref.current.style.setProperty(`max-height`, `${entries[0].contentRect.height}px`);
    }
  });

  return (
    <Transition
      ref={ref}
      show={open}
      appear={true}
      beforeEnter={() => {
        ref?.current &&
          ref.current.style.setProperty(`max-height`, `${ref.current.scrollHeight}px`);
      }}
      afterEnter={() => {
        refContent.current && sizeObserver.observe(refContent.current);
      }}
      beforeLeave={() => {
        ref.current && ref.current.style.setProperty(`max-height`, `0px`);
        refContent.current && sizeObserver.unobserve(refContent.current);
      }}
      className="transition-all duration-200"
      enterFrom="transform opacity-0 max-h-[0px]"
      enterTo={`transform opacity-100 ${className ? className : ''}`}
      leaveFrom={`transform opacity-50 ${className ? className : ''}`}
      leaveTo="transform opacity-0"
    >
      <div className={classNameContainer} ref={refContent}>
        {children}
      </div>
    </Transition>
  );
};
export { PortalTransition };
