export function ExpandMore({ ...props }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 96 960 960"
      width="1em"
      height="1em"
      fill="currentColor"
      {...props}
    >
      <path d="M480 682.461q-6.846 0-13.269-2.308t-12.038-7.923L274.308 491.846q-8.692-8.692-8.384-20.769.307-12.077 9-20.769 8.692-8.692 21.076-8.692t21.076 8.692L480 613.232l163.539-163.539q8.692-8.692 20.769-8.385 12.076.308 20.768 9 8.693 8.692 8.693 21.077 0 12.384-8.693 21.076L505.307 672.23q-5.615 5.615-11.846 7.923-6.23 2.308-13.461 2.308Z" />
    </svg>
  );
}
