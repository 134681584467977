import { ReactNode } from 'react';

import { twMerge } from 'tailwind-merge';

import './DashboardCardTitle.css';

export interface DashboardCardTitleProps {
  children?: ReactNode;
  className?: string;
}

export function DashboardCardTitle({ children, className }: DashboardCardTitleProps) {
  return <div className={twMerge('db-card-title', className)}>{children}</div>;
}
