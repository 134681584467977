import { SupportedLanguage, SupportedLanguage2LetterList } from '../types/general';

export const AVAILABLE_LANGUAGES: SupportedLanguage2LetterList[] = [
  'es',
  'ca',
  'en',
  'et',
  'fr',
  'it',
  'de',
  'fi',
  'lt',
  'lv',
  'pt',
  'pl',
  'sv',
  'da',
  'nl',
  'bg',
  'hr',
  'cs',
  'el',
  'hu',
  'ga',
  'ro',
  'sk',
  'sl',
  'no',
];
export const LANG_TO_LOCALE: Record<SupportedLanguage2LetterList, SupportedLanguage> = {
  es: 'es-ES',
  ca: 'ca-ES',
  en: 'en-EN',
  et: 'et-EE',
  fr: 'fr-FR',
  it: 'it-IT',
  de: 'de-DE',
  lt: 'lt-LT',
  lv: 'lv-LV',
  fi: 'fi-FI',
  pt: 'pt-PT',
  pl: 'pl-PL',
  sv: 'sv-SE',
  da: 'da-DK',
  nl: 'nl-NL',
  bg: 'bg-BG',
  hr: 'hr-HR',
  cs: 'cs-CZ',
  el: 'el-GR',
  hu: 'hu-HU',
  ga: 'ga-IE',
  ro: 'ro-RO',
  sk: 'sk-SK',
  sl: 'sl-SI',
  no: 'no-NO',
};
export const LOCALE_TO_LANG: Record<SupportedLanguage, SupportedLanguage2LetterList> = {
  'es-ES': 'es',
  'ca-ES': 'ca',
  'en-EN': 'en',
  'et-EE': 'et',
  'fr-FR': 'fr',
  'it-IT': 'it',
  'de-DE': 'de',
  'lt-LT': 'lt',
  'lv-LV': 'lv',
  'fi-FI': 'fi',
  'pt-PT': 'pt',
  'pl-PL': 'pl',
  'sv-SE': 'sv',
  'da-DK': 'da',
  'nl-NL': 'nl',
  'bg-BG': 'bg',
  'hr-HR': 'hr',
  'cs-CZ': 'cs',
  'el-GR': 'el',
  'hu-HU': 'hu',
  'ga-IE': 'ga',
  'ro-RO': 'ro',
  'sk-SK': 'sk',
  'sl-SI': 'sl',
  'no-NO': 'no',
};
