enum TokenType {
  user = 'token',
  impersonate = 'impersonateToken',
  refresh = 'refresh_token',
}

const setToken = (type: TokenType, token: string) => {
  try {
    // I need to wrap string like "string" because in legacy part of the app
    // we use OVLocalCache to store to local storage
    // OVLocalCache use JSON.stringify
    window.localStorage.setItem(type, `"${token}"`);
  } catch (e) {
    console.error(e);
    return '';
  }
};

const removeToken = (type: TokenType) => {
  try {
    window.localStorage.removeItem(type);
  } catch (e) {
    console.error(e);
    return '';
  }
};

const getToken = (type: TokenType) => {
  try {
    // I need to wrap string like "string" because in legacy part of the app
    // we use OVLocalCache to store to local storage
    // OVLocalCache use JSON.stringify
    return (window.localStorage.getItem(type) || '').replaceAll('"', '');
  } catch (e) {
    console.error(e);
    return '';
  }
};

export const setUserToken = (token: string) => setToken(TokenType.user, token);
export const setRefreshToken = (token: string) => setToken(TokenType.refresh, token);
export const setImpersonateToken = (token: string) => setToken(TokenType.impersonate, token);
export const getUserToken = () => getToken(TokenType.user);
export const getImpersonateToken = () => getToken(TokenType.impersonate);
export const removeImpersonateToken = () => removeToken(TokenType.impersonate);
export const getRefreshToken = () => getToken(TokenType.refresh);
export const getImpersonateOrUserToken = () => {
  const impersonate = getImpersonateToken();
  if (impersonate) return impersonate;
  return getUserToken();
};
