import { Fragment, memo, useCallback, useMemo } from 'react';

import { flip, offset, shift, useFloating } from '@floating-ui/react-dom';
import { Menu } from '@headlessui/react';
import { FormattedMessage } from 'localization';
import { twMerge } from 'tailwind-merge';

import { DropdownItem } from '../Dropdown/DropdownItem';
import { DropdownPopup } from '../Dropdown/DropdownPopup';
import { AgentPanelButton } from './AgentPanelButton';
import { AgentPanelLogo, UserLogoSize } from './AgentPanelLogo';

interface IEmailSupport {
  value?: string;
  label?: string;
  email?: string;
}
export interface IAssignSupportEmail {
  value?: string;
  options?: Array<IEmailSupport>;
  onChange?: (value?: string) => void;
  disabled?: boolean;
  className?: string;
}

export function AssignSupportEmail({
  value,
  onChange,
  options,
  disabled,
  className,
}: IAssignSupportEmail) {
  const valueObj = useMemo(() => {
    const valueObj = options?.find((option) => option?.value === value);

    const splitted = valueObj?.label?.split(' ') || '';
    const initials = `${splitted[0]?.charAt(0).toUpperCase() ?? ''}${
      splitted[1]?.charAt(0).toUpperCase() ?? ''
    }`;
    const firstName = splitted[0] || '';
    const lastName = splitted[1] || '';
    return { ...valueObj, firstName, lastName, initials };
  }, [value, options]);

  const handleChange = useCallback(
    (value: any) => {
      onChange && onChange(value);
    },
    [onChange],
  );

  const { refs, floatingStyles } = useFloating({
    placement: 'bottom-start',
    middleware: [offset(4), flip(), shift()],
  });

  return (
    <Menu ref={refs.setReference} as="div" className={twMerge('relative max-w-[188px]', className)}>
      {({ open }) => (
        <>
          <Menu.Button disabled={disabled} as={Fragment}>
            <div className={'relative'}>
              <AgentPanelButton
                reversedTitle
                logo={
                  <AgentPanelLogo name={valueObj?.label} color="random">
                    {!!valueObj?.initials && valueObj?.initials}
                  </AgentPanelLogo>
                }
                open={open}
                title={
                  value ? (
                    <div className="flex whitespace-nowrap">
                      <span className="overflow-hidden overflow-ellipsis">{`${valueObj?.firstName}`}</span>
                      <span>&nbsp;</span>
                      <span>{`${valueObj?.lastName?.charAt(0)}.`}</span>
                    </div>
                  ) : (
                    <FormattedMessage id="desk-app.store-unassigned" />
                  )
                }
                subtitle={valueObj?.email}
              />
            </div>
          </Menu.Button>
          <DropdownPopup
            ref={refs.setFloating}
            style={floatingStyles}
            className="static m-0 max-h-[300px] overflow-y-auto"
            classNameWrapper="w-full z-1"
            position="bottom-start"
            open={open}
          >
            <div className="flex flex-col w-full gap-[5px]">
              {options?.map((option, index) => {
                const splitted = option?.label?.split(' ') || '';
                const initials = `${splitted[0]?.charAt(0).toUpperCase() ?? ''}${
                  splitted[1]?.charAt(0).toUpperCase() ?? ''
                }`;
                return (
                  <DropdownItem
                    key={`${option.value}${index}`}
                    onClick={() => handleChange && handleChange(option.value)}
                    className="px-3"
                    selected={option.value === value}
                  >
                    <div className="flex gap-2 items-center w-full">
                      <AgentPanelLogo name={option.label} size={UserLogoSize.small} color="random">
                        {!!initials && initials}
                      </AgentPanelLogo>
                      <div className="truncate">
                        <div className="text-v2blueGray-800 text-sm font-semibold truncate">
                          {option.label}
                        </div>
                        <div className="text-v2blueGray-500 text-xs truncate">{option.email}</div>
                      </div>
                    </div>
                  </DropdownItem>
                );
              })}
            </div>
          </DropdownPopup>
        </>
      )}
    </Menu>
  );
}

export default memo<IAssignSupportEmail>(AssignSupportEmail);
