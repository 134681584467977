export default {
  'ovc.general.powered-by': 'Tarjoaja:',
  'ovc.auth.identity.email.placeholder': 'Sähköpostiosoite',
  'ovc.auth.identity.cta': 'Tee palautus',
  'ovc.auth.order.orderNumber.placeholder': 'Tilausnumero tai kuriirin seurantatunnus',
  'ovc.auth.order.clientInfo.placeholder': 'Postinumero tai puhelinnumeron 5 viimeistä numeroa',
  'ovc.auth.switch-to-identity': 'Kirjaudu vaihtoehtoisesti sähköpostilla',
  'ovc.auth.switch-to-order': 'Kirjaudu vaihtoehtoisesti tilaustiedoilla',
  'ovc.auth.tracking.cta': 'Seuraa tilaustani',
  'ovc.auth.tracking.orderIdentifier.placeholder': 'Syötä tilaustunnus, OTN tai seurantanumero',
  'ovc.auth.tracking.postcode.placeholder': 'Syötä toimitusosoitteen postinumero',
  'ovc.listing.page.all': 'Kaikki tilaukset',
  'ovc.listing.page.in-transit': 'Kuljetuksessa olevat tilaukset',
  'ovc.listing.page.returns': 'Palautukset',
  'ovc.listing.header.order': 'tilaus',
  'ovc.listing.header.date': 'päivämäärä',
  'ovc.listing.header.total': 'yhteensä',
  'ovc.listing.header.choose-product': 'Valitse tuote:',
  'ovc.listing.listing-track-cta': 'seuraa',
  'ovc.listing.listing-return-cta': 'palautus',
  'ovc.listing.listing-external-action-cta': 'pyydä takuukorjausta',
  'ovc.listing.empty-list': 'Ei näytettäviä tilauksia',
  'ovc.listing.return-status.store-non-returnable': 'Ei palautettavissa',
  'ovc.listing.return-status.already-returned': 'Palautettu {date}',
  'ovc.listing.return-status.no-longer-returnable': 'Ei enää palautettavissa',
  'ovc.listing.return-status.returnable': 'Palauta viimeistään {date}',
  'ovc.return-cart.refund-method-credit': `Valitse tämä, jos haluat käyttää hyvityksesi seuraavaa tilaustasi varten!{lineBreak}
  {lineBreak}
  Lähetämme sinulle hyvityssumman arvoisen lahjakortin.`,
  'ovc.return-cart.inst.outvio.heading.confirm-pickup': 'vahvista nouto-osoite ja palauta',
  'ovc.return-cart.inst.outvio.heading.confirm-return': 'vahvista palautus',
  'ovc.return-cart.address.heading': 'syötä uusi osoite tai valitse aiempi:',
};
