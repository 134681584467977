export default {
  FALLBACK_ERROR_MESSAGE:
    'Es ist ein Fehler aufgetreten. Bitte versuchen Sie es später noch einmal.',
  COURIER_ERROR_FALLBACK: 'Kurierfehler.',
  TOKEN_NOT_FOUND: ' Token nicht gefunden.',
  IDENTITY_VERIFICATION_FAILED: ' Identitätsüberprüfung konnte nicht abgeschlossen werden.',
  ACCOUNT_BLOCKED: ' Ihr Konto ist gesperrt. Bitte überprüfen Sie Ihre E - Mail-Adresse',
  WRONG_EMAIL_OR_PASS: 'E-Mail-Adresse und / oder Passwort sind nicht korrekt',
  INVALID_CREDENTIALS: 'Ungültige Zugangsdaten',
  INVALID_TOKEN:
    'Die Zeit ist abgelaufen, bitte fordern Sie einen neuen E-Mail-Validierungslink im Rückgabeportal an.',
  MALFORMED_TOKEN: 'Fehlerhaftes token',
  CUSTOMER_ALREADY_CREATED: ' Kundenkonto bereits registriert. Bitte loggen Sie sich ein.',
  SHIPPING_METHOD_NOT_SUPPORTED: ' Versandmethode nicht vorgesehen.',
  SHIPMENT_ALREADY_PICKED_UP: ' Sendung bereits abgeholt.',
  NO_RATE_FOR_DESTINATION: 'Kein Gebührensatz für den Bestimmungsort verfügbar.',
  BUILDING_LABEL_FAILED: 'Etikettenerstellung misslungen.',
  COURIER_NOT_SUPPORTED: 'Kurierdienst nicht vorgesehen.',
  PICKUP_REQUEST_FAILED: 'Abholanforderung gescheitert.',
  ERROR_TRY_AGAIN_LATER:
    'Es ist ein Fehler aufgetreten. Bitte versuchen Sie es später noch einmal.',
  EMAIL_ALREADY_REGISTERED: ' E-Mail-Adresse bereits registriert.',
  INVALID_PASSWORD: ' Ungültiges Passwort.',
  LABEL_ERROR: ' Kennzeichnungsfehler.',
  UNDER_MAINTENANCE:
    'Wir führen derzeit Instandhaltungsarbeiten durch. In Kürze wird Outvio wieder online sein.',
  MERGING_PDFS_FAILED: 'Zusammenführen der pdf-Dateien gescheitert',
  EXPIRE_TOKEN: 'Token abgelaufen',
  GENERIC_MISSING_PARAM: 'Erforderliche Parameter fehlen.',
  ORDER_NOT_FOUND: 'Auftrag nicht gefunden.',
};
