export function PickingList({ ...props }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="1em" height="1em" {...props}>
      <g transform="translate(-152 -691)">
        <rect
          id="Rectangle_6411"
          data-name="Rectangle 6411"
          width="24"
          height="24"
          transform="translate(152 691)"
          fill="none"
        />
        <g id="Group_10086" data-name="Group 10086" transform="translate(152 691)">
          <path
            id="Path_38807"
            data-name="Path 38807"
            d="M9.442,19.5a1.519,1.519,0,1,1,0-3.038H19.981a1.519,1.519,0,1,1,0,3.038Zm0-5.981a1.519,1.519,0,1,1,0-3.038H19.981a1.519,1.519,0,1,1,0,3.038Zm0-5.981A1.513,1.513,0,0,1,7.923,6.019,1.513,1.513,0,0,1,9.442,4.5H19.981A1.514,1.514,0,0,1,21.5,6.019a1.513,1.513,0,0,1-1.519,1.519Zm-5.423,0A1.513,1.513,0,0,1,2.5,6.019,1.513,1.513,0,0,1,4.019,4.5,1.513,1.513,0,0,1,5.538,6.019,1.513,1.513,0,0,1,4.019,7.538Zm0,5.981a1.519,1.519,0,1,1,0-3.038,1.519,1.519,0,1,1,0,3.038Zm0,5.981a1.519,1.519,0,1,1,0-3.038,1.519,1.519,0,1,1,0,3.038Z"
            fill="currentColor"
          />
        </g>
      </g>
    </svg>
  );
}
