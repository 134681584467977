import React, { Fragment, ReactNode, memo } from 'react';

import { Disclosure } from '@headlessui/react';

import { DropdownExpandMoreIcon } from '../../molecules';
import { AlertContainer } from '../AlertContainer/AlertContainer';
import { PortalTransition } from '../PortalTransition/PortalTransition';

interface IAlertDisclosure {
  children: ReactNode;
  defaultOpen?: boolean;
  color?: 'blue' | 'orange' | 'white' | 'gray';
  className?: string;
  title: ReactNode;
}

export function AlertDisclosure({
  children,
  defaultOpen,
  className,
  color,
  title,
}: IAlertDisclosure) {
  return (
    <Disclosure defaultOpen={defaultOpen} as={Fragment}>
      {({ open }) => (
        <AlertContainer className={className} color={color}>
          <div className="flex items-center gap-2">
            <Disclosure.Button as={Fragment}>
              <div className="flex w-9 h-9 items-center justify-center cursor-pointer hover:bg-v2blueGray-100 rounded-10">
                <DropdownExpandMoreIcon open={open} />
              </div>
            </Disclosure.Button>
            {title}
          </div>
          <PortalTransition open={open}>
            <Disclosure.Panel as="div">{children}</Disclosure.Panel>
          </PortalTransition>
        </AlertContainer>
      )}
    </Disclosure>
  );
}

export default memo(AlertDisclosure);
