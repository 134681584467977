import { Language } from 'app-types';
import apiErrorsEN from 'localization/locales/en-EN/apiErrors.json';

import {
  getImpersonateToken,
  getRefreshToken,
  getUserToken,
  setRefreshToken,
  setUserToken,
} from '../utils/token';

interface RequestOptions {
  method: string;
  upload?: boolean;
  secure?: boolean;
  data?: Record<string, any> | FormData;
  skipRefreshToken?: boolean;
  params?: Record<string, any>;
  headers?: Record<string, any>;
}

function makeHeaders(options: RequestOptions): Headers {
  const token = getUserToken();
  const adminToken = getImpersonateToken();

  return new Headers({
    Accept: 'application/json',
    ...(options.upload
      ? {}
      : {
          'Content-Type': 'application/json',
        }),
    ...(options.secure
      ? {
          'x-access-token': adminToken || token,
        }
      : {}),
    ...(adminToken
      ? {
          // add header to exclude impersonating from activity tracking
          'x-outvio-imp': 'true',
          // used for deactivated account admin access
          'x-outvio-admin': adminToken,
        }
      : {}),
    ...options.headers,
  });
}

const makeUrl = (url: string, options: RequestOptions): string => {
  const constructedParams = new URLSearchParams();
  let doAddParams = false;
  if (options?.params) {
    doAddParams = true;
    Object.keys(options.params).forEach((key) => {
      if (options?.params?.[key]) {
        constructedParams.append(key, options.params[key]);
      }
    });
  }

  return doAddParams ? `${url}?${constructedParams.toString()}` : url;
};

async function request<T>(
  url: string,
  options: RequestOptions = { method: 'GET' },
): Promise<T | undefined> {
  try {
    const response = await fetch(makeUrl(url, options), {
      method: options.method,
      headers: makeHeaders(options),
      body:
        options.upload && options.data instanceof FormData
          ? options.data
          : JSON.stringify(options.data),
    });
    const data = await response.json();

    if (data.success === false && data.message) {
      throw new Error(data.message);
    }

    return data;
  } catch (error: any) {
    if (error?.message === 'EXPIRED_TOKEN' && !options.skipRefreshToken) {
      return refreshToken<T>(url, options);
    }
    return handleError(error);
  }
}

async function refreshToken<T>(url: string, options: RequestOptions): Promise<T | undefined> {
  const refresh_token = getRefreshToken();
  const response = await fetch(`${process.env.OUTVIO_API_URL}/v2/authorize`, {
    method: 'POST',
    headers: new Headers({
      Accept: 'application/json',
      'Content-Type': 'application/json',
    }),
    body: JSON.stringify({
      grant_type: 'refresh_token',
      refresh_token: refresh_token,
    }),
  });
  const data = await response.json();
  setUserToken(data.access_token);
  setRefreshToken(data.refresh_token);
  options.skipRefreshToken = true;
  return request<T>(url, options);
}

const defaultLang = 'en-EN';

export function checkIfTokenError(error: Error): boolean {
  if (
    error?.message === 'INVALID_TOKEN' ||
    error?.message === 'Invalid Token.' ||
    error?.message === 'EXPIRED_TOKEN'
  ) {
    const ssoUrl = window.localStorage.getItem('ssoUrl');
    if (ssoUrl) {
      window.localStorage.setItem('beforeLogoutPage', location.pathname);
      window.location.assign(ssoUrl);
    } else {
      window.location.assign('/auth/logout');
    }
    return true;
  }
  return false;
}

async function handleError(error: Error): Promise<undefined> {
  if (checkIfTokenError(error)) {
    return;
  }

  if (error instanceof Error && /Unexpected token/g.test(error.message)) {
    throw new Error('Failed to fetch');
  }

  const errorCode = error.message;

  const userLang =
    (window.localStorage.getItem('userLanguage') as Language)?.replaceAll('"', '') || defaultLang;

  let apiErrors: Record<string, string> = {};

  if (userLang !== defaultLang) {
    const apiErrorsTranslation = await import(
      `../../localization/locales/${userLang}/apiErrors.json`
    );

    apiErrors = Object.assign({}, apiErrorsEN, apiErrorsTranslation);
  } else {
    apiErrors = Object.assign({}, apiErrorsEN);
  }

  if (errorCode === null) {
    error.message = apiErrors.FALLBACK_ERROR_MESSAGE;
  } else if (apiErrors[errorCode]) {
    error.message = apiErrors[errorCode];
  }

  throw error;
}

export default request;
