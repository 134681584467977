export function ArrowBack({ ...props }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 96 960 960"
      fill="currentColor"
      {...props}
    >
      <path d="M443.847 859.538 185.616 601.307q-5.615-5.615-7.923-11.846-2.307-6.23-2.307-13.461t2.307-13.461q2.308-6.231 7.923-11.846l258.615-258.615q8.308-8.308 20.385-8.616 12.076-.307 21.384 9 9.307 8.692 9.615 20.769.307 12.076-9 21.384L275.229 546.001h464.309q12.769 0 21.384 8.615T769.537 576q0 12.769-8.615 21.384t-21.384 8.615H275.229L487 817.77q8.307 8.307 8.615 20.691.307 12.385-9 21.077-8.692 9.307-21.076 9.307-12.385 0-21.692-9.307Z" />
    </svg>
  );
}
