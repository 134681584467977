import React, { forwardRef, useContext, useMemo } from 'react';

import get from 'lodash-es/get';

import { makeStyles } from '@material-ui/core';

import OVCOutvioLogoBig from './OVCOutvioLogoBig';
import OVCStoreDataContext from './OVCStoreDataContext';

const useStyles = makeStyles({
  storeLogo: {
    height: '50px',
    width: 'auto',
  },
  defaultLogo: {
    '& > svg': {
      height: '50px',
    },
  },
});

const OVCHeaderLogo = forwardRef<HTMLAnchorElement | HTMLDivElement>((props, ref) => {
  const context = useContext(OVCStoreDataContext);
  const classes = useStyles();

  const storeUrlHref = useMemo(() => {
    const rawUrl = get(context.storeData, 'url', '') || '';

    if (rawUrl === '') {
      return '/';
    }

    try {
      let urlObj;
      if (rawUrl.indexOf('http') === -1) {
        urlObj = new URL(`https://${rawUrl}`);
      } else {
        urlObj = new URL(rawUrl);
      }
      return urlObj.toString();
    } catch (err) {
      return '/';
    }
  }, [context.storeData]);

  if (context.storeData.portalSettings.logo || context.storeData.portalV2?.logo) {
    return (
      <a
        href={storeUrlHref}
        target="_blank"
        rel="noreferrer noopener"
        ref={ref as React.Ref<HTMLAnchorElement>}
      >
        <img
          className={classes.storeLogo}
          src={
            (context.storeData?.featureFlags?.newTrackingPage && context.storeData?.portalV2?.logo
              ? context.storeData.portalV2.logo
              : context.storeData.portalSettings.logo) || ''
          }
          alt={context.storeData.name || context.storeData.slug}
        />
      </a>
    );
  }

  return (
    <div
      className={classes.defaultLogo}
      data-cy="ovc-layout-header-logo-outvio"
      ref={ref as React.Ref<HTMLDivElement>}
    >
      <OVCOutvioLogoBig />
    </div>
  );
});

export default OVCHeaderLogo;
