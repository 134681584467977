import { useMemo } from 'react';

import { useMutation } from '@apollo/client';
import { type Mutation } from 'app-types/desk';

import { TICKET_HAS_BEEN_SEEN_MUTATION } from '../../../gql/mutations';

export function useTicketHasBeenSeen() {
  const [mutate, { loading, error }] = useMutation<Mutation>(TICKET_HAS_BEEN_SEEN_MUTATION);

  return useMemo(
    () => ({
      updateTicketHasBeenSeen: async (ticketId: string) => {
        await mutate({
          variables: {
            ticketId,
          },
          update: (cache, result) => {
            if (result.data?.Ticket_HasBeenSeen) {
              const { __typename, hasBeenSeenByAgent } = result.data.Ticket_HasBeenSeen;

              const identifier = cache.identify({
                __typename,
                id: ticketId,
              });

              if (identifier) {
                cache.modify({
                  id: identifier,
                  fields: {
                    hasBeenSeenByAgent() {
                      return hasBeenSeenByAgent;
                    },
                  },
                });
              }
            }
          },
        });
      },
      loading,
      error,
    }),
    [mutate, loading, error],
  );
}
