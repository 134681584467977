import enEN from './en-EN';
import esES from './es-ES';

export default {
  'en-EN': enEN,
  'es-ES': esES,
  'et-EE': enEN,
  'fr-FR': enEN,
  'it-IT': enEN,
  'de-DE': enEN,
  'fi-FI': enEN,
  'lt-LT': enEN,
  'pt-PT': enEN,
  'lv-LV': enEN,
  'pl-PL': enEN,
  'ca-ES': esES,
};
