export function TemplatesIcon({ ...props }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" width="1em" height="1em" {...props}>
      <g
        id="bar_chart_black_24dp_1_"
        data-name="bar_chart_black_24dp (1)"
        transform="translate(20) rotate(90)"
      >
        <path id="Path_16320" data-name="Path 16320" d="M0,0H20V20H0Z" fill="none" />
        <path
          id="Path_40430"
          data-name="Path 40430"
          d="M8.333.225V3.667a2.028,2.028,0,0,0,.092.875.781.781,0,0,0,.367.366A2.023,2.023,0,0,0,9.667,5h3.442M10,9.167H3.333M10,12.5H3.333M5,5.833H3.333M8.333,0H4A6.142,6.142,0,0,0,1.367.275,2.389,2.389,0,0,0,.275,1.367,6.146,6.146,0,0,0,0,4v8.667A6.146,6.146,0,0,0,.275,15.3a2.389,2.389,0,0,0,1.092,1.092A6.142,6.142,0,0,0,4,16.667H9.333a6.143,6.143,0,0,0,2.633-.275A2.389,2.389,0,0,0,13.058,15.3a6.146,6.146,0,0,0,.275-2.633V5Z"
          transform="translate(1.667 16.667) rotate(-90)"
          fill="none"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.64"
        />
        <path
          id="Path_40431"
          data-name="Path 40431"
          d="M2.086,9.521,6.94,4.387,6.77,8.844Z"
          transform="translate(-0.348 -0.731)"
          fill="currentColor"
        />
      </g>
    </svg>
  );
}
