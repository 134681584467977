import React from 'react';

import { twMerge } from 'tailwind-merge';
import { match } from 'ts-pattern';
import { Button, ButtonVariant, Heading } from 'ui/atoms';

export type ConfirmationWindowProps = {
  onCancel: () => void;
  onConfirm?: () => void;
  title?: React.ReactNode;
  content?: React.ReactNode;
  cancelButtonTitle: React.ReactNode;
  confirmButtonTitle?: React.ReactNode;
  isLoading?: boolean;
  headingIcon?: React.ReactNode;
  iconWrapperClass?: string;
  theme?: 'default' | 'info' | 'danger' | 'green' | 'dark';
};

export function ConfirmationWindow({
  title,
  content,
  onCancel,
  onConfirm,
  cancelButtonTitle,
  confirmButtonTitle,
  headingIcon,
  iconWrapperClass,
  isLoading = false,
  theme = 'default',
}: ConfirmationWindowProps) {
  const themeData = match(theme)
    .with('default', () => ({
      buttonTheme: ButtonVariant.Primary,
      iconStyling: 'bg-primary/30 text-primary',
      buttonIconWrapperClass: 'text-white',
    }))
    .with('info', () => ({
      buttonTheme: ButtonVariant.blue,
      iconStyling: 'bg-v2blue-600/30 text-v2blue-600',
      buttonIconWrapperClass: 'text-white',
    }))
    .with('danger', () => ({
      buttonTheme: ButtonVariant.Danger,
      iconStyling: 'bg-v2red/30 text-v2red',
      buttonIconWrapperClass: 'text-white',
    }))
    .with('green', () => ({
      buttonTheme: ButtonVariant.green,
      iconStyling: 'bg-v2green/30 text-v2green',
      buttonIconWrapperClass: 'text-white',
    }))
    .with('dark', () => ({
      buttonTheme: ButtonVariant.Primary,
      iconStyling: 'bg-v2blueGray-800/30 text-v2blueGray-800',
      buttonIconWrapperClass: 'text-white',
    }))
    .exhaustive();

  return (
    <div className="flex flex-col items-center bg-white text-center w-full">
      {headingIcon && (
        <div
          className={twMerge(
            'flex items-center justify-center w-12 h-12 mb-6 rounded-[16px]',
            themeData.iconStyling,
            iconWrapperClass,
          )}
        >
          {headingIcon}
        </div>
      )}
      <Heading className="mb-4 text-v2blueGray-800">{title}</Heading>
      <div className="mb-6 whitespace-pre-line text-[14px] leading-[22px] w-full">{content}</div>

      <div className={twMerge('grid w-full', onConfirm && 'grid-cols-2 gap-2')}>
        <Button
          disabled={isLoading}
          className="w-full"
          theme={ButtonVariant.Secondary}
          onClick={(event) => {
            event.preventDefault();
            event.stopPropagation();
            onCancel();
          }}
        >
          <span className="capitalize">{cancelButtonTitle}</span>
        </Button>
        {onConfirm && (
          <Button
            loading={isLoading}
            iconWrapperClass={themeData.buttonIconWrapperClass}
            className="w-full"
            theme={themeData.buttonTheme}
            onClick={(event) => {
              event.preventDefault();
              event.stopPropagation();
              onConfirm();
            }}
          >
            <span className="capitalize">{confirmButtonTitle}</span>
          </Button>
        )}
      </div>
    </div>
  );
}

export default ConfirmationWindow;
