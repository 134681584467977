export function CourierSupport({ ...props }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="1em" height="1em" {...props}>
      <g id="courier-support" transform="translate(-152 -691)">
        <rect
          id="Rectangle_6411"
          data-name="Rectangle 6411"
          width="24"
          height="24"
          transform="translate(152 691)"
          fill="none"
        />
        <path
          id="Path_38812"
          data-name="Path 38812"
          d="M12,14.712a.793.793,0,1,0-.575-.232A.782.782,0,0,0,12,14.712Zm0-3.481a.736.736,0,0,0,.75-.75V5.942a.75.75,0,1,0-1.5,0v4.539a.775.775,0,0,0,.056.291.71.71,0,0,0,.4.4A.776.776,0,0,0,12,11.231ZM6.038,17.5l-2,2a.826.826,0,0,1-.982.192.843.843,0,0,1-.555-.84V4.308A1.793,1.793,0,0,1,4.308,2.5H19.692A1.793,1.793,0,0,1,21.5,4.308V15.692A1.793,1.793,0,0,1,19.692,17.5Z"
          transform="translate(152 690.861)"
          fill="currentColor"
        />
      </g>
    </svg>
  );
}
