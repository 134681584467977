import {
  type Dispatch,
  type MutableRefObject,
  type SetStateAction,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';

type DropdownToggle = {
  uuidRef: MutableRefObject<string>;
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  toggleDropdown: () => void;
};

export const useDropdownToggle = (): DropdownToggle => {
  const [isOpen, setIsOpen] = useState(false);
  const uuidRef = useRef<string>(crypto.randomUUID());

  useEffect(() => {
    if (isOpen) {
      const outsideClickListener = (event: any) => {
        if (event.target?.closest(`[data-id="${uuidRef.current}"]`)) return;
        setIsOpen(false);
      };

      document.addEventListener('click', outsideClickListener);
      return () => {
        document.removeEventListener('click', outsideClickListener);
      };
    }
  }, [isOpen]);

  const toggleDropdown = useCallback(() => {
    setIsOpen((state) => !state);
  }, []);

  return { uuidRef, isOpen, setIsOpen, toggleDropdown };
};
