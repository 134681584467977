export function ItalicTextShortcut({ ...props }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="1em" height="1em" {...props}>
      <g id="Group_10944" data-name="Group 10944" transform="translate(-2.339 -2.375)">
        <g
          id="Rectangle_8501"
          data-name="Rectangle 8501"
          transform="translate(2.339 2.375)"
          fill="#849ead"
          stroke="#707070"
          strokeWidth="1"
          opacity="0"
        >
          <rect width="24" height="24" stroke="none" />
          <rect x="0.5" y="0.5" width="23" height="23" fill="none" />
        </g>
        <g id="Group_10945" data-name="Group 10945" transform="translate(2.754 2.375)">
          <path
            id="Path_39081"
            data-name="Path 39081"
            d="M6.3,18.625a.9.9,0,1,1,0-1.808H9.212l3.24-9.635H9.538a.9.9,0,1,1,0-1.808h7.346a.9.9,0,1,1,0,1.808H14.279l-3.24,9.635h2.606a.9.9,0,1,1,0,1.808Z"
            fill="#849ead"
          />
        </g>
      </g>
    </svg>
  );
}
