import { memo } from 'react';

import { twMerge } from 'tailwind-merge';

export interface IShipmentHistoryItem {
  date: string;
  title: string;
  isFirst?: boolean;
  isLast?: boolean;
  type?: 'passed' | 'active' | 'not-reached';
}
const ShipmentHistoryItem = memo(
  ({
    title,
    date,
    isFirst = false,
    isLast = false,
    type = 'not-reached',
  }: IShipmentHistoryItem) => {
    return (
      <div className="gap-4 flex items-stretch font-inter">
        <div className="flex flex-col items-center min-w-[16px]">
          <div
            className={twMerge(
              'bg-v2blueGray-200 h-full w-px -translate-x-1/2',
              (type === 'passed' || type === 'active') && 'bg-primary w-0.5 translate-x-0',
              type === 'not-reached' && 'bg-v2blueGray-200',
              isFirst && 'bg-transparent',
            )}
          />
          <div
            className={twMerge(
              'bg-v2blueGray-200 flex-shrink-0 rounded-full w-2 h-2 my-0.5',
              type === 'not-reached' && 'my-0',
              type === 'passed' && 'bg-primary',
              type === 'active' && 'bg-v2cyan-100 w-4 h-4 flex justify-center items-center',
            )}
          >
            {type === 'active' && <div className="rounded-full w-2 h-2 bg-primary" />}
          </div>
          <div
            className={twMerge(
              'bg-v2blueGray-200 h-full w-px -translate-x-1/2',
              type === 'passed' && 'bg-primary w-0.5 translate-x-0',
              type === 'not-reached' && 'bg-v2blueGray-200',
              type === 'active' && 'bg-v2blueGray-200',
              isLast && 'bg-transparent',
            )}
          />
        </div>
        <div className="flex flex-col gap-px text-sm py-2.5 ">
          <div
            className={twMerge(
              'text-v2blueGray-610',
              type === 'not-reached' && 'text-v2blueGray-200',
              type === 'active' && 'text-v2blueGray-800 font-semibold',
            )}
          >
            {title}
          </div>
          <div
            className={twMerge(
              'text-v2blueGray-400',
              type === 'not-reached' && 'text-v2blueGray-200',
            )}
          >
            {date}
          </div>
        </div>
      </div>
    );
  },
);

export { ShipmentHistoryItem };
