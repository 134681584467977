import { CSSProperties, Ref, forwardRef, useCallback, useRef, useState } from 'react';

import { flip, offset, shift, useFloating } from '@floating-ui/react-dom';
import { InsertImageShortcut } from 'icons';
import { FormattedMessage } from 'localization';
import { ReactEditor, useSlate } from 'slate-react';
import { twMerge } from 'tailwind-merge';
import { Tooltip } from 'ui';
import { Button, Copy, IconButton, TextInput } from 'ui/atoms';
import useClickAway from 'ui/src/hooks/useClickAway';

import { insertImage, isElementActive, isImageUrl } from '../utils';

type InsertImageButtonProps = {
  title: string;
  withTitle?: boolean;
  onClose?: () => void;
};

const InsertImageButton = ({ title, withTitle, onClose }: InsertImageButtonProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const { refs, floatingStyles } = useFloating({
    placement: 'top',
    middleware: [offset(4), flip(), shift()],
  });

  const editor = useSlate();

  const linkImageRef = useRef<HTMLDivElement | null>(null);

  useClickAway(linkImageRef, () => {
    if (isOpen) {
      setIsOpen(false);
    }
  });

  const toggleOpen = useCallback(() => {
    if (onClose && isOpen) {
      onClose();
    }

    ReactEditor.focus(editor);
    setIsOpen((prevState) => !prevState);
  }, [setIsOpen, onClose]);

  return (
    <div
      ref={(currentRef) => {
        linkImageRef.current = currentRef;
        refs.setReference(currentRef);
      }}
    >
      <Tooltip title={withTitle || isOpen ? '' : title} placement="top">
        <div className="relative">
          <IconButton
            open={isOpen || isElementActive(editor, 'image')}
            className={twMerge(
              'flex items-center h-9 w-full hover:bg-transparent active:bg-v2blueGray-100',
              !withTitle && 'justify-center w-9',
              withTitle &&
                'justify-start px-2 gap-2 active:bg-v2blueGray-100 hover:bg-v2blueGray-100',
              (isOpen || isElementActive(editor, 'image')) &&
                'bg-v2blueGray-100 hover:bg-v2blueGray-100',
            )}
            onMouseDown={(e) => {
              e.preventDefault();
              toggleOpen();
            }}
          >
            <InsertImageShortcut className="w-7 h-7" />
            {withTitle && <span className="capitalize">{title}</span>}
          </IconButton>
        </div>
      </Tooltip>
      {isOpen && (
        <InsertImageUrlForm
          onLinkClick={toggleOpen}
          ref={refs.setFloating}
          style={{ ...floatingStyles, position: withTitle ? 'absolute' : 'fixed' }}
        />
      )}
    </div>
  );
};

const InsertImageUrlForm = forwardRef(
  (
    {
      onLinkClick,
      className,
      style,
    }: {
      onLinkClick: () => void;
      className?: string;
      style?: CSSProperties;
    },
    ref?: Ref<HTMLDivElement>,
  ) => {
    const [linkImageUrl, setLinkImageUrl] = useState<string>('');

    const editor = useSlate();

    const handleInsertLink = useCallback(() => {
      if (linkImageUrl) {
        insertImage(editor, linkImageUrl);
      }

      onLinkClick();
    }, [linkImageUrl, editor, onLinkClick]);

    const isValid = linkImageUrl && isImageUrl(linkImageUrl);

    return (
      <div
        ref={ref}
        style={style}
        className={twMerge(
          'flex flex-col min-w-[332px] p-8 rounded-[22px] bg-white shadow-generic-popup overflow-hidden',
          className,
        )}
      >
        <form>
          <Copy className="ml-2 mb-4">
            <FormattedMessage id="desk-app.rich-editor.insert-image-modal.label" />
          </Copy>
          <TextInput
            autoFocus
            name="adsad"
            className="mb-10"
            placeholder="https://example.com/image.jpg"
            value={linkImageUrl}
            onChange={(e) => setLinkImageUrl(e.target.value)}
          />

          <div className="flex justify-end">
            <Button disabled={!isValid} onClick={handleInsertLink}>
              <FormattedMessage id="desk-app.rich-editor.insert-image-modal.button" />
            </Button>
          </div>
        </form>
      </div>
    );
  },
);

export { InsertImageButton };
