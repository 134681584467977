import moment from 'moment';
import { LS_KEYS } from 'ui';

class OVLocalCache {
  get(key: string): Promise<null | any> {
    return new Promise((resolve, reject) => {
      try {
        const raw = localStorage.getItem(key);

        if (!raw) {
          return resolve(null);
        }

        const entry = JSON.parse(raw);

        if (entry.expires) {
          if (moment(entry.expires).isBefore()) {
            localStorage.removeItem(key);
            return resolve(null);
          }
          return resolve(entry.data);
        }

        return resolve(entry);
      } catch (e) {
        return reject(e);
      }
    });
  }

  set(key: string, value: any, expires = false, expireTime = 48): Promise<any> {
    return new Promise((resolve, reject) => {
      try {
        localStorage.setItem(
          key,
          JSON.stringify(
            expires
              ? {
                  expires: moment().add(expireTime, 'hour').toDate(),
                  data: value,
                }
              : value,
          ),
        );
        return resolve(value);
      } catch (e) {
        return reject(e);
      }
    });
  }

  delete(key: string): Promise<boolean> {
    return new Promise((resolve, reject) => {
      try {
        localStorage.removeItem(key);
        return resolve(true);
      } catch (e) {
        return reject(e);
      }
    });
  }

  purgeExpired(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      try {
        const storage = { ...window.localStorage };

        const expireItemKeys = Object.keys(storage).reduce<string[]>((acc, key) => {
          if (storage[key].includes('expires')) {
            return acc.concat([key]);
          }
          return acc;
        }, []);

        expireItemKeys.forEach((key) => {
          const parsed = JSON.parse(storage[key]);
          if (parsed.expires && moment(parsed.expires).isBefore()) {
            localStorage.removeItem(key);
          }
        });
        return resolve(true);
      } catch (e) {
        return reject(e);
      }
    });
  }

  purgeNonCache(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      try {
        const storage = { ...window.localStorage };

        const nonexpireItemKeys = Object.keys(storage).reduce<string[]>((acc, key) => {
          if (!storage[key].includes('expires') && key !== LS_KEYS.REDIRECT_AFTER_LOGIN_URL) {
            return acc.concat([key]);
          }
          return acc;
        }, []);

        nonexpireItemKeys.forEach((key) => {
          localStorage.removeItem(key);
        });
        return resolve(true);
      } catch (e) {
        return reject(e);
      }
    });
  }
}

export default new OVLocalCache();
