import { SubscriptionPlan } from 'app-types/core';
import { FormattedMessage } from 'localization';
import { Button, ButtonVariant, Copy, Heading } from 'ui/atoms';
import LayerWithSideBar from 'ui/src/molecules/LayerWithSidebar';

import { DowngradeStep } from '../../DowngradePlanFlow';
import { PricingPlanProps } from '../../types/pricingTypes';
import LostFeaturesList from './LostFeaturesList';

type LostFeaturesProps = {
  onClose: () => void;
  setStep: (newStep: DowngradeStep) => void;
  from: PricingPlanProps;
  to: PricingPlanProps;
  tookDowngradeOffer: boolean;
};

const LostFeatures = ({ onClose, setStep, from, to, tookDowngradeOffer }: LostFeaturesProps) => {
  const handleNextStep = () => {
    if (!tookDowngradeOffer) {
      setStep(DowngradeStep.GetOffer);
    } else {
      setStep(DowngradeStep.HelpToImprove);
    }
  };

  const isCancelSubscription = to.plan === SubscriptionPlan.Starter || !to.plan;

  return (
    <LayerWithSideBar
      className="rounded-[22px]"
      sideBarContent={
        <>
          <div className="px-2 pt-2">
            <Heading className="mb-[29px] capitalize">
              <FormattedMessage
                id={
                  isCancelSubscription
                    ? 'settings.subs.downgrade.heading.cancel'
                    : 'settings.subs.downgrade.heading.general'
                }
                values={{ plan: to.plan }}
              />
            </Heading>
            <Copy className="mb-10 whitespace-pre-line" type="copy7">
              <FormattedMessage
                id="settings.subs.cancel.description"
                values={{
                  plan: <label className="first-letter:capitalize inline-block">{from.plan}</label>,
                }}
              />
            </Copy>
          </div>
          <Button onClick={onClose}>
            <FormattedMessage id="settings.subs.cancel.keep-my-advantages" />
          </Button>
        </>
      }
    >
      <>
        <Heading type="heading2" weight="regular" className="px-2 pt-2 mb-6">
          <FormattedMessage id="settings.subs.cancel.features-you-will-lose" />
        </Heading>

        <div className="md:h-[355px] overflow-y-hidden md:overflow-y-auto">
          <LostFeaturesList currentPlan={from.plan!} targetPlan={to.plan} />

          <Button onClick={handleNextStep} className="mt-4 w-full" theme={ButtonVariant.Secondary}>
            <FormattedMessage id="settings.subs.cancel.lose-all-advantages" />
          </Button>
        </div>
      </>
    </LayerWithSideBar>
  );
};

export default LostFeatures;
