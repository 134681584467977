export function Notifications({ ...props }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="1em" height="1em" {...props}>
      <g transform="translate(-152 -691)">
        <rect
          id="Rectangle_6411"
          data-name="Rectangle 6411"
          width="24"
          height="24"
          transform="translate(152 691)"
          fill="none"
        />
        <path
          id="Path_38813"
          data-name="Path 38813"
          d="M4.308,19.5A1.793,1.793,0,0,1,2.5,17.692V6.308A1.793,1.793,0,0,1,4.308,4.5H19.692A1.793,1.793,0,0,1,21.5,6.308v7.617a5.311,5.311,0,0,0-1.284-.514,5.681,5.681,0,0,0-1.38-.161,5.565,5.565,0,0,0-5.587,5.587V19.5ZM12,11,5.06,6.569a.6.6,0,0,0-.642-.037.615.615,0,0,0-.34.512.673.673,0,0,0,.042.367.592.592,0,0,0,.242.271l7.162,4.56a.881.881,0,0,0,.954,0l7.162-4.56a.593.593,0,0,0,.242-.271.672.672,0,0,0,.042-.367.628.628,0,0,0-.328-.5.618.618,0,0,0-.655.019Zm7.579,8.5H15.808a.75.75,0,1,1,0-1.5h3.781l-1.323-1.323a.723.723,0,0,1,.015-1.054.778.778,0,0,1,.527-.215.693.693,0,0,1,.527.215l2.485,2.494a.874.874,0,0,1,0,1.265l-2.485,2.494a.752.752,0,0,1-1.054-.01.76.76,0,0,1,0-1.044Z"
          transform="translate(152 690.707)"
          fill="currentColor"
        />
      </g>
    </svg>
  );
}
