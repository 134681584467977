import { memo } from 'react';

import ContentLoader, { IContentLoaderProps } from 'react-content-loader';

type ValueLoaderProps = {
  width?: number;
  height?: number;
} & IContentLoaderProps;

export const AvatarLoader = memo(({ width = 100, height = 40, ...props }: ValueLoaderProps) => {
  return (
    <ContentLoader
      speed={1}
      viewBox={`0 0 ${width} ${height}`}
      backgroundColor="#9DB3BF"
      foregroundColor="#C5D4DB"
      width={width}
      height={height}
      {...props}
    >
      <rect rx="18" width={width} height={height} />
    </ContentLoader>
  );
});
