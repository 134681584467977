import { memo } from 'react';

import { ArrowBack } from 'icons';
import { twMerge } from 'tailwind-merge';

interface IConnectCMSSection {
  title: React.ReactNode;
  subtitle?: React.ReactNode;
  logo?: string;
  logoElement?: React.ReactNode;
  className?: string;
  isActive?: boolean;
  onClick?: () => void;
}

const _ConnectCMSSection = ({
  title,
  subtitle,
  logo,
  className,
  isActive,
  logoElement,
  onClick,
}: IConnectCMSSection) => {
  return (
    <div
      onClick={onClick}
      className={twMerge(
        'group hover:cursor-pointer flex items-center gap-4 border w-full p-[23px] rounded-[14px]',
        className,
      )}
    >
      {logo && <img src={logo} alt="logo" className="w-[40px] h-[40px]" />}
      {logoElement && logoElement}
      <div className="flex flex-col flex-1">
        <span className="m-0 text-v2blueGray-800 text-[14px] tracking-[-0.252px] font-medium">
          {title}
        </span>
        {subtitle ? (
          <span className="m-0 mt-[2px] text-v2blueGray-500 font-normal text-[12px] tracking-[-0.144px]">
            {subtitle}
          </span>
        ) : (
          <div className="mt-1" />
        )}
      </div>
      <div
        className={twMerge(
          'w-8 h-8 flex justify-center items-center group-hover:bg-v2blueGray-100 rounded-md',
          isActive && 'group-hover:bg-primary/20',
        )}
      >
        <div className="transition transform rotate rotate-180 group-hover:rotate-[135deg]">
          <ArrowBack
            width="20"
            height="20"
            className={isActive ? 'text-primary' : 'text-[#849EAD]'}
          />
        </div>
      </div>
    </div>
  );
};

const ConnectCMSSection = memo<IConnectCMSSection>(_ConnectCMSSection);

ConnectCMSSection.displayName = 'ConnectCMSSection';

export { ConnectCMSSection };
