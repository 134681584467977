import React, { FC, memo } from 'react';

const TrackingPageLoader: FC = memo(() => {
  return (
    <div className="absolute top-0 w-full h-full flex justify-center items-center md:mb-0">
      <div className="relative w-full h-full">
        <div
          style={{
            boxShadow: '0 4px 12px rgba(110, 80, 255, 0.4)',
          }}
          className="bg-v2violet w-[80px] h-[20px] rounded-[20px] absolute top-1/2 left-[calc(50%-40px)] animate-wave"
        />
      </div>
    </div>
  );
});

export default TrackingPageLoader;
