export default {
  'orders.package': 'Paketti',
  'orders.returns.reasons.size': 'Koko ei mahdu',
  'orders.returns.reasons.wrong-product': 'Väärä tuote toimitettu',
  'orders.returns.reasons.wrong-size': 'Väärä koko toimitettu',
  'orders.returns.reasons.wrong-colour': 'Väärä väri',
  'orders.returns.reasons.package-broken': 'Tuotteen paketti hajonnut',
  'orders.returns.reasons.product-broken': 'Tuote rikki/viallinen',
  'orders.returns.reasons.product-used': 'Tuote käytetty',
  'orders.returns.reasons.product-dirty': 'Tuote likainen',
  'orders.returns.reasons.do-not-want': 'En halua sitä enää',
  'orders.returns.reasons.exchange': 'Haluaisin vaihtaa tuotteen',
  'orders.returns.reasons.other': 'Muu',
  'orders.returns.pickup.form.comments': 'Kommentit',
  'orders.list.empty': 'Sinulla ei ole tällä hetkellä tilauksia',
  'orders.total': 'Yhteensä',
  'orders.vat': 'ALV',
  'or.confirm-address': 'VAHVISTA NOUTO-OSOITTEESI',
  'or.from': 'Tästä',
  'or.to': 'Saakka',
  'or.return.list.empty': 'Tällä hetkellä sinulla ei ole palautuksia',
  'or.confirm-return': 'VAHVISTA PALAUTUKSESI',
};
