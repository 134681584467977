export default {
  'orders.package': 'Paket',
  'orders.returns.reasons.size': 'Größe passt nicht',
  'orders.returns.reasons.wrong-product': 'Falscher Artikel geliefert',
  'orders.returns.reasons.wrong-size': 'Falsche Größe geliefert',
  'orders.returns.reasons.wrong-colour': 'Falsche Farbe',
  'orders.returns.reasons.package-broken': 'Verpackung des Artikels beschädigt',
  'orders.returns.reasons.product-broken': 'Artikel defekt/fehlerhaft',
  'orders.returns.reasons.product-used': 'Artikel benutzt',
  'orders.returns.reasons.product-dirty': 'Artikel verschmutzt',
  'orders.returns.reasons.do-not-want': 'Der Artikel gefällt mir nicht',
  'orders.returns.reasons.exchange': 'Ich möchte den Artikel umtauschen',
  'orders.returns.reasons.other': 'Sonstiges',
  'orders.returns.pickup.form.comments': 'Kommentare',
  'orders.list.empty': 'Du hast gerade keine aktive Bestellung',
  'orders.total': 'Insgesamt',
  'orders.vat': 'MwSt.',
  'or.confirm-address': 'ABHOLADRESSE BESTÄTIGEN',
  'or.from': 'Von',
  'or.to': 'Bis',
  'or.return.list.empty': 'Momentan keine Rücksendungen',
  'or.confirm-return': 'BESTÄTIGEN SIE IHRE RÜCKSENDUNG',
};
