export function MastercardIcon({ ...props }) {
  return (
    <svg
      id="Mastercard-logo"
      xmlns="http://www.w3.org/2000/svg"
      height="1em"
      viewBox="0 0 20 12.5"
      {...props}
    >
      <g id="Group_14988" data-name="Group 14988" transform="translate(0 0)">
        <rect
          id="rect19"
          width="5.412"
          height="9.826"
          transform="translate(7.286 1.337)"
          fill="#ff5a00"
        />
        <path
          id="XMLID_330_"
          d="M7.646,6.25A6.292,6.292,0,0,1,10,1.337,6.112,6.112,0,0,0,6.185,0,6.214,6.214,0,0,0,0,6.25,6.214,6.214,0,0,0,6.185,12.5,6.112,6.112,0,0,0,10,11.163,6.261,6.261,0,0,1,7.646,6.25Z"
          transform="translate(0 0)"
          fill="#eb001b"
        />
        <path
          id="path22"
          d="M509.6,6.25a6.214,6.214,0,0,1-6.185,6.25,6.111,6.111,0,0,1-3.815-1.337,6.3,6.3,0,0,0,0-9.826A6.1,6.1,0,0,1,503.413,0,6.226,6.226,0,0,1,509.6,6.25Z"
          transform="translate(-489.6 0)"
          fill="#f79e1b"
        />
      </g>
    </svg>
  );
}
