import { useQuery } from '@tanstack/react-query';
import { SubscriptionPlan } from 'app-types/core';

import { hourCache } from '../../constants';
import { getEstimatedSubBill } from '../../queries/apiSubscription';

export const GET_ESTIMATED_SUB_BILL_KEY = 'subEstimatedBill';

export function useGetEstimatedSubBill<T = { rebillDate: string; amount: number; vat: number }>(
  targetPlan: SubscriptionPlan | undefined,
) {
  return useQuery<T>(
    [GET_ESTIMATED_SUB_BILL_KEY, { targetPlan, period: 'monthly' }],
    () => getEstimatedSubBill(targetPlan, 'monthly') as T,
    {
      enabled: !!targetPlan,
      staleTime: hourCache,
      cacheTime: hourCache,
    },
  );
}
