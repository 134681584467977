import { RETURN_PORTAL_TOKEN_LS_KEY } from '../../constants/localStorageKeys';

export const getAuthToken = () => {
  try {
    return window.localStorage.getItem(RETURN_PORTAL_TOKEN_LS_KEY) || '';
  } catch (e) {
    console.log(e);
    return '';
  }
};
