export function CallTicket({ ...props }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 32 32" {...props}>
      <rect
        id="Rectangle_10932"
        data-name="Rectangle 10932"
        width="32"
        height="32"
        rx="14"
        transform="translate(0 32) rotate(-90)"
        fill="#233841"
      />
      <g id="Group_9666" transform="translate(-954 -150)" opacity="0.92">
        <rect
          id="Rectangle_4521"
          width="20"
          height="20"
          transform="translate(960 156)"
          fill="none"
        />
        <path
          id="Path_30513"
          data-name="Path 30513"
          d="M0,8.306A8.3,8.3,0,0,1,8.22,0h.343a8.3,8.3,0,0,1,8.563,7.965v.343a8.3,8.3,0,0,1-8.22,8.307H8.563a8.277,8.277,0,0,1-2.483-.343H5.652l-1.713.771A.72.72,0,0,1,3,16.7c0-.086-.086-.171-.086-.257L2.826,14.9a.74.74,0,0,0-.257-.514A8.412,8.412,0,0,1,0,8.306Z"
          transform="translate(961.387 157.388)"
          fill="none"
        />
        <path
          id="call_FILL1_wght300_GRAD0_opsz24"
          d="M152.249-806.937a10.358,10.358,0,0,1-4.364-1.01,13.94,13.94,0,0,1-4.032-2.85,14.024,14.024,0,0,1-2.842-4.028,10.334,10.334,0,0,1-1.01-4.361.794.794,0,0,1,.231-.58.775.775,0,0,1,.576-.234h2.506a.767.767,0,0,1,.513.19.836.836,0,0,1,.283.469l.44,2.261a1.292,1.292,0,0,1-.019.541.8.8,0,0,1-.233.38l-1.775,1.727a10.659,10.659,0,0,0,.979,1.484,14.639,14.639,0,0,0,1.192,1.337,13.262,13.262,0,0,0,1.345,1.175,13.507,13.507,0,0,0,1.538,1.009l1.724-1.739a.976.976,0,0,1,.437-.263,1.255,1.255,0,0,1,.533-.037l2.134.434a.9.9,0,0,1,.475.3.758.758,0,0,1,.184.5v2.491a.775.775,0,0,1-.234.576A.794.794,0,0,1,152.249-806.937Z"
          transform="translate(823.265 979.265)"
          fill="#fff"
          stroke="#fff"
          strokeWidth="1"
        />
      </g>
    </svg>
  );
}
