import { useCallback } from 'react';

import { SubscriptionPlan } from 'app-types/core';
import { changePlanTo } from 'data-layer';
import { useIntl } from 'localization';

const useShopifyPaymentProcessing = () => {
  const { formatMessage } = useIntl();

  const failedPaymentMessage = formatMessage({ id: 'UPGRADE_PAYMENT_FAILED' });

  return useCallback(async (plan: SubscriptionPlan) => {
    try {
      await changePlanTo({
        targetPlan: plan,
        period: 'monthly',
      });
    } catch (error: any) {
      return error?.message || failedPaymentMessage;
    }
  }, []);
};

export { useShopifyPaymentProcessing };
