import { InputHTMLAttributes, memo } from 'react';

import { twMerge } from 'tailwind-merge';

export type RadioProps = {
  id?: string;
  name?: string;
  checked: boolean;
  disabled?: boolean;
  label?: string;
  theme?: 'primary' | 'info';
};

type Props = RadioProps & InputHTMLAttributes<HTMLInputElement>;

export function Radio({
  id,
  checked,
  name,
  disabled,
  label,
  theme = 'primary',
  ...otherProps
}: Props) {
  return (
    <div className="flex items-center">
      <input
        id={id}
        type="radio"
        checked={checked}
        name={name}
        disabled={disabled}
        className={twMerge(
          `h-5 w-5 cursor-pointer appearance-none rounded-full border-solid bg-v2blueGray-100 checked:bg-white checked:border-[6px]`,
          theme === 'primary' &&
            'hover:border-primary checked:border-primary checked:drop-shadow-[0px_2px_4px_rgba(75,180,179,0.2)]',
          theme === 'info' &&
            'hover:border-v2blue checked:border-v2blue checked:drop-shadow-[0px_2px_4px_rgba(0,122,255,0.2)]',
          disabled && 'cursor-not-allowed',
        )}
        onChange={otherProps.onChange}
      />
      {label && (
        <label htmlFor={id} className="ml-2 text-sm text-ui-gray-darker">
          {label}
        </label>
      )}
    </div>
  );
}

export default memo(Radio);
