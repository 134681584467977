import React, { useMemo } from 'react';

import { Dialog, DialogProps, makeStyles } from '@material-ui/core';
import cn from 'classnames';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: 'rgba(198,219,221,0.31)',
  },
  paper: {
    position: 'relative',
    width: '90%',
    boxShadow: '0 0 10px 0 rgba(198, 219, 221, 0.5)',
    borderRadius: '5px',

    maxWidth: '532px',

    [theme.breakpoints.down('xs')]: {
      width: '100%',
      borderRadius: '0',
    },
  },
}));

const PAPER_PROPS = {
  elevation: 0,
};

interface IOVDialog extends Omit<DialogProps, 'open'> {
  children: React.ReactNode;
  onClose(): void;
  paperClass?: string;
  backdropClass?: string;
  otherClasses?: Record<string, string>;
}

const OVDialog = ({
  children,
  onClose,
  paperClass = '',
  backdropClass = '',
  otherClasses = {},
  ...rest
}: IOVDialog) => {
  const classes = useStyles();

  const backdropProps = useMemo(
    () => ({
      classes: {
        root: cn(classes.root, backdropClass),
      },
    }),
    [classes, backdropClass],
  );

  const dialogClasses = useMemo(
    () => ({
      paper: cn(classes.paper, paperClass),
      ...otherClasses,
    }),
    [classes, paperClass, otherClasses],
  );

  return (
    <Dialog
      onClose={onClose}
      maxWidth={false}
      scroll="body"
      classes={dialogClasses}
      BackdropProps={backdropProps}
      PaperProps={PAPER_PROPS}
      {...rest}
      open
    >
      {children}
    </Dialog>
  );
};

export default OVDialog;
