export function Shipping({ ...props }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="1em" height="1em" {...props}>
      <g transform="translate(-152 -691)">
        <rect
          id="Rectangle_6411"
          data-name="Rectangle 6411"
          width="24"
          height="24"
          transform="translate(152 691)"
          fill="none"
        />
        <path
          id="Path_38808"
          data-name="Path 38808"
          d="M5.844,19.558A2.793,2.793,0,0,1,3.038,16.75a1.725,1.725,0,0,1-1.2-.563,1.75,1.75,0,0,1-.5-1.244V6.308A1.793,1.793,0,0,1,3.154,4.5H14.962a1.813,1.813,0,0,1,1.808,1.808v2h2.2a.869.869,0,0,1,.722.362l2.779,3.719a.8.8,0,0,1,.135.249.921.921,0,0,1,.045.294v2.915a.888.888,0,0,1-.9.9h-.9a2.814,2.814,0,0,1-4.8,1.989,2.71,2.71,0,0,1-.817-1.989H8.654a2.794,2.794,0,0,1-2.81,2.808Zm.007-1.5a1.249,1.249,0,0,0,.924-.384,1.273,1.273,0,0,0,.379-.929,1.294,1.294,0,0,0-1.3-1.3,1.273,1.273,0,0,0-.929.379,1.249,1.249,0,0,0-.384.924,1.31,1.31,0,0,0,1.313,1.313Zm12.192,0a1.249,1.249,0,0,0,.924-.384,1.273,1.273,0,0,0,.379-.929,1.294,1.294,0,0,0-1.3-1.3,1.273,1.273,0,0,0-.929.379,1.25,1.25,0,0,0-.384.924,1.31,1.31,0,0,0,1.313,1.313ZM16.769,13.25H21.25l-2.6-3.442H16.769Z"
          transform="translate(152 691)"
          fill="currentColor"
        />
      </g>
    </svg>
  );
}
