export default {
  'custLanding.password.title': 'Zapomniałeś hasło?',
  'custLanding.signup.btn': 'Zarejestruj się',
  'custLanding.login.btn': 'Zaloguj się',
  'custLanding.password.btn': 'Resetuj hasło',
  'custLanding.switch-to-login': 'Zaloguj się za pomocą istniejącego konta',
  'custLanding.switch-to-signup': 'Zarejestruj się z nowym kontem',
  'custLanding.auth.text.password':
    'Wprowadź adres e-mail powiązany z Twoim kontem, a my wyślemy Ci instrukcje, jak uzyskać nowe hasło.',
  'custLanding.form.password': 'Hasło',
  'custLanding.forgot-password': 'Zapomniałeś hasło?',
  'custLanding.forgot-password.success': 'Wkrótce otrzymasz instrukcje!',
  'custLanding.let-get-started': 'Zaczynajmy!',
  'custLanding.already-have-an-account': 'Masz już konto?',
  'custLanding.just-remembered': 'Przypomniałeś sobie hasło?',
  'custlanding.login.error.no-order': 'Nie znaleziono zamówień dla tego adresu e-mail',
};
