export default {
  'account.save': 'Salvesta',
  'account.address.name': 'Alias',
  'account.address.address': 'Aadress',
  'account.address.postcode': 'Sihtnumber',
  'account.address.city': 'Linn',
  'account.address.country': 'Riik',
  'account.address.province': 'Haldusüksus',
  'account.address.contactName': 'Kontakt isik',
  'account.address.contactPhone': 'Kontakt telefon',
};
