import { HTMLProps, ReactNode } from 'react';

import { ListItemType } from 'app-types';
import { twMerge } from 'tailwind-merge';
import { ErrorMessage } from 'ui/atoms';

interface _ListItemProps {
  wrapperClassName?: string;
  className?: string;
  type?: ListItemType;
  actionButton?: ReactNode;
  errorMessage?: ReactNode;
}

export type ListItemProps = HTMLProps<HTMLDivElement> & _ListItemProps;

export function ListItem({
  wrapperClassName,
  className,
  type = ListItemType.Primary,
  actionButton,
  children,
  errorMessage,
  ...otherProps
}: ListItemProps) {
  return (
    <div
      className={twMerge(
        'flex flex-col w-full [&:not(:last-of-type)]:mb-2 [&:last-of-type]:mb-6',
        wrapperClassName,
      )}
      {...otherProps}
    >
      <div
        data-testid="outvioui--listitem"
        className={twMerge(
          'flex items-center w-full rounded-[14px] border-[1px] border-v2blueGray-200',
          errorMessage && 'bg-v2blueGray-0 border-v2red',
          type === ListItemType.Primary && 'p-6',
          type === ListItemType.Secondary && 'px-8 py-6',
          type === ListItemType.Tertiary && 'pt-[22px] pr-8 pb-5 pl-10',
          className,
        )}
      >
        {children}
        {actionButton && <div className="relative">{actionButton}</div>}
      </div>
      {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
    </div>
  );
}

export default ListItem;
