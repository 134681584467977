export function InstagramComment({ ...props }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" {...props}>
      <defs>
        <radialGradient
          id="radial-gradient-ic"
          cx="0.065"
          cy="0.978"
          r="1.27"
          gradientTransform="translate(0)"
          gradientUnits="objectBoundingBox"
        >
          <stop offset="0.09" stopColor="#fa8f21" />
          <stop offset="0.78" stopColor="#d82d7e" />
        </radialGradient>
      </defs>
      <g id="instagram-comments" transform="translate(-7100 1059)">
        <rect
          id="Rectangle_12475"
          data-name="Rectangle 12475"
          width="32"
          height="32"
          rx="10"
          transform="translate(7100 -1027) rotate(-90)"
          fill="url(#radial-gradient-ic)"
        />
        <g id="Group_18862" data-name="Group 18862" transform="translate(6141 -1174)">
          <rect
            id="Rectangle_4522"
            data-name="Rectangle 4522"
            width="24"
            height="24"
            transform="translate(963 119)"
            fill="none"
          />
          <path
            id="instagram-comment-icon"
            d="M9.137,0A9.006,9.006,0,0,0,1.091,13.042L.147,16.814a.854.854,0,0,0,1.062,1.026l3.505-.988A9,9,0,1,0,9.137,0ZM3.98,3.847A7.295,7.295,0,1,1,5.3,15.206a.854.854,0,0,0-.7-.113l-2.433.7.659-2.638a.859.859,0,0,0-.075-.636,7.189,7.189,0,0,1-.677-1.7,7.327,7.327,0,0,1,1.9-6.978Z"
            transform="translate(965.878 122)"
            fill="#fff"
          />
        </g>
      </g>
    </svg>
  );
}
