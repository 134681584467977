import { memo, useMemo } from 'react';

import { AgentPanelLogo, IAgentPanelLogo } from '../AgentPanel/AgentPanelLogo';

export type UserLogoProps = Omit<IAgentPanelLogo, 'name'> & {
  firstName?: string;
  lastName?: string;
};

export const UserLogo = memo(({ firstName, lastName, ...props }: UserLogoProps) => {
  const { initials, fullName } = useMemo(() => {
    return {
      initials: `${firstName?.charAt(0).toUpperCase() ?? ''}${
        lastName?.charAt(0).toUpperCase() ?? ''
      }`,
      fullName: `${firstName} ${lastName}`.trim(),
    };
  }, [firstName, lastName]);

  return (
    <AgentPanelLogo name={fullName} {...props}>
      {initials}
    </AgentPanelLogo>
  );
});
