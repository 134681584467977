import { useSlate } from 'slate-react';
import { twMerge } from 'tailwind-merge';
import { Tooltip } from 'ui';
import { IconButton } from 'ui/atoms';

import { Format } from '../types';
import { isFormatActive, toggleFormat } from '../utils';

type MarkButtonProps = {
  format: Format;
  icon: JSX.Element;
  title: string;
  withTitle?: boolean;
};

const MarkButton = ({ format, icon, title, withTitle }: MarkButtonProps) => {
  const editor = useSlate();

  return (
    <Tooltip title={withTitle ? '' : title} placement="top">
      <IconButton
        className={twMerge(
          'flex items-center justify-center w-9 h-9 hover:bg-transparent active:bg-v2blueGray-100',
          isFormatActive(editor, format) && 'bg-v2blueGray-100 hover:bg-v2blueGray-100',
        )}
        onMouseDown={(event) => {
          event.preventDefault();
          toggleFormat(editor, format);
        }}
      >
        {icon}
      </IconButton>
    </Tooltip>
  );
};

export { MarkButton };
