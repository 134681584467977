import { ReactNode } from 'react';

import { FormattedMessage } from 'localization';
import { twJoin, twMerge } from 'tailwind-merge';

import { Heading } from '../../atoms';

export enum EPricingColors {
  gray = 'gray',
  violet = 'violet',
  lightGray = 'lightGray',
}

export const PricingItem = ({
  color = EPricingColors.gray,
  className,
  isActive,
  title,
  subtitle,
  featureList,
  children,
}: {
  className?: string;
  isActive?: boolean;
  title?: ReactNode;
  featureList?: ReactNode[];
  color?: EPricingColors;
  children?: ReactNode;
  subtitle: ReactNode;
}) => {
  return (
    <div
      className={twMerge(
        'flex flex-col pb-[48px] font-inter',
        color === EPricingColors.gray && 'bg-v2blueGray-0',
        color === EPricingColors.violet && 'bg-v2violet-650/20',
        className,
      )}
    >
      <div>
        <div className={twMerge('pt-9 pl-9 pr-9 relative')}>
          <Heading
            type="heading7"
            className={twJoin(
              isActive && 'flex justify-between items-center',
              color === EPricingColors.gray && 'text-v2blueGray-800',
              color === EPricingColors.lightGray && 'text-v2blueGray-500',
              color === EPricingColors.violet && 'text-v2violet-650',
            )}
          >
            {title}
            {isActive && (
              <div className="h-[28] flex items-center absolute right-0 bg-v2violet-650/15 rounded-l-full pl-3.5 pr-3 font-semibold text-[14px] tracking-normal text-v2violet-650">
                <FormattedMessage id="general.active" />
              </div>
            )}
          </Heading>
          <div
            className={twMerge(
              'text-sm mt-2.5',
              (color === EPricingColors.gray || color === EPricingColors.lightGray) &&
                'text-v2blueGray-400',
              color === EPricingColors.violet && 'text-v2violet-650/80',
            )}
          >
            {subtitle}
          </div>
        </div>
      </div>
      <div
        className={twMerge(!children && 'md:min-h-[36px] md:mt-9', children && 'min-h-[36px] mt-9')}
      >
        {children}
      </div>

      <div
        className={twJoin(
          'px-7 text-xs flex flex-col gap-4 pb-6 mt-10',
          color === EPricingColors.violet && 'text-v2violet-650',
          color === EPricingColors.lightGray && 'text-v2blueGray-600',
        )}
      >
        {featureList?.map((item, index) => (
          <div className="flex gap-2 items-center" key={`${item}${index}`}>
            <div className="font-bold text-xs">·</div>
            {item}
          </div>
        ))}
      </div>
    </div>
  );
};
