export default {
  'account.save': 'Guardar',
  'account.address.name': 'Alias',
  'account.address.address': 'Dirección',
  'account.address.postcode': 'Código Postal',
  'account.address.city': 'Ciudad',
  'account.address.country': 'País',
  'account.address.province': 'Provincia',
  'account.address.contactName': 'Persona de Contacto',
  'account.address.contactPhone': 'Teléfono de Contacto',
};
