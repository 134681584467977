export function TagMentionIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
      <g transform="translate(-150 -689)" opacity="0.6">
        <rect width="18" height="18" transform="translate(150 689)" fill="none" />
        <g transform="translate(151.851 690.851)">
          <path
            d="M45.533,103.566a6.892,6.892,0,0,1-2.741-.551,6.985,6.985,0,0,1-3.741-3.742,7.114,7.114,0,0,1,0-5.49,7.009,7.009,0,0,1,3.741-3.732,7.114,7.114,0,0,1,5.49,0,7.033,7.033,0,0,1,3.732,3.732,6.869,6.869,0,0,1,.551,2.75v.9a2.362,2.362,0,0,1-.7,1.721,2.306,2.306,0,0,1-1.71.708,2.356,2.356,0,0,1-1.244-.328,2.412,2.412,0,0,1-.855-.874,3.648,3.648,0,0,1-1.072.872,3.039,3.039,0,0,1-1.456.329,3.336,3.336,0,1,1,3.331-3.331v.9a1.259,1.259,0,0,0,.383.953,1.321,1.321,0,0,0,1.825,0,1.259,1.259,0,0,0,.383-.953v-.9a5.9,5.9,0,1,0-5.923,5.923h3.146a.555.555,0,1,1,0,1.11Zm0-4.812a2.212,2.212,0,1,0-1.573-.648A2.143,2.143,0,0,0,45.533,98.754Z"
            transform="translate(-38.5 -89.5)"
            fill="#ff9f31"
            stroke="#ff9f31"
            strokeWidth="0.8"
          />
        </g>
      </g>
    </svg>
  );
}

export default TagMentionIcon;
