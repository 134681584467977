export default {
  'ovc.general.powered-by': 'Powered by',
  'ovc.auth.identity.email.placeholder': 'Email', // custLanding.form.email
  'ovc.auth.identity.cta': 'Make a return', // landing.make-a-return
  'ovc.auth.order.orderNumber.placeholder': 'Order number or courier tracking', // custLanding.form.orderNumber
  'ovc.auth.order.clientInfo.placeholder': 'Post code or last 5 digits of phone', // custLanding.form.clientInfo
  'ovc.auth.switch-to-identity': 'Sign in with email instead', // custLanding.switch-to-identity
  'ovc.auth.switch-to-order': 'Sign in with order info instead', // custLanding.switch-to-order
  'ovc.auth.tracking.cta': 'Track my order', // custLanding.tracking.btn
  'ovc.auth.tracking.orderIdentifier.placeholder': 'Insert order ID, OTN or tracking number', // custLanding.tracking.orderIdentifier
  'ovc.auth.tracking.postcode.placeholder': 'Insert destination postcode', // custLanding.tracking.postcode
  'ovc.listing.page.all': 'All Orders',
  'ovc.listing.page.in-transit': 'Orders In Transit',
  'ovc.listing.page.returns': 'Returns',
  'ovc.listing.header.order': 'order',
  'ovc.listing.header.date': 'date',
  'ovc.listing.header.total': 'total',
  'ovc.listing.header.choose-product': 'Choose product:',
  'ovc.listing.listing-track-cta': 'track',
  'ovc.listing.listing-return-cta': 'return',
  'ovc.listing.listing-external-action-cta': 'request warranty repair',
  'ovc.listing.empty-list': 'No orders available for display',
  'ovc.listing.return-status.store-non-returnable': "Can't be returned",
  'ovc.listing.return-status.already-returned': 'Returned {date}',
  'ovc.listing.return-status.no-longer-returnable': 'No longer returnable',
  'ovc.listing.return-status.returnable': 'Return until {date}',
  'ovc.return-cart.refund-method-credit': `Choose this option in case you want to use your refund towards your next purchase with us!{lineBreak}
    {lineBreak}
    We will send you a gift card with the Refund amount.`,
  'ovc.return-cart.inst.outvio.heading.confirm-pickup': 'confirm your pickup address and return',
  'ovc.return-cart.inst.outvio.heading.confirm-return': 'confirm your return',
  'ovc.return-cart.address.heading': 'input a new address or select an existing one:',
  'ovc.return-cart.socket-error': 'Courier system error: {error}. Please try again later',
};
