import { StoreCurrency } from 'app-types/core';
import { FormattedMessage, useIntl } from 'localization';
import { twMerge } from 'tailwind-merge';
import { match } from 'ts-pattern';
import { Error as ErrorMessage } from 'ui/src/atoms';

import { Loader } from '../../../../components';
import currencyToSymbol from '../../../../utils/currencyToSymbol';
import { AlertContainer, Button, CheckBox, Copy, Heading } from '../../../atoms';

type ConfirmationSectionProps = {
  onSubmit: () => Promise<void>;
  currency: StoreCurrency;
  isShopifyBilling?: boolean;
  errorMessage?: string;
  type?: 'plan' | 'deskOnly' | 'whatsapp';
  isSubmitting: boolean;
  isLoading?: boolean;
  amount: number | undefined;
};

const ConfirmationSection = ({
  onSubmit,
  currency,
  isShopifyBilling,
  errorMessage,
  type = 'plan',
  isSubmitting,
  isLoading,
  amount,
}: ConfirmationSectionProps) => {
  const { locale } = useIntl();

  const accountLanguage = locale.slice(0, 2);
  const termsLink = accountLanguage === 'en' ? 'terms-of-service' : 'es/terminos-de-servicio/';

  const buttonTitleKey = match(type)
    .with('plan', () => 'settings.payment.confirm-and-pay')
    .with('deskOnly', () => 'settings.payment.desk-upgrade.confirm')
    .with('whatsapp', () => 'settings.payment.confirm-and-pay')
    .exhaustive();

  const formattedAmount = amount // if zero, show 0.00
    ? amount
    : amount?.toFixed(2);

  return (
    <>
      <AlertContainer
        className={twMerge(
          'bg-v2blueGray-0 p-4 mt-[29px] mb-10',
          !!isShopifyBilling && 'bg-v2blueGray-100',
        )}
      >
        <CheckBox
          disabled={isSubmitting}
          theme="dark"
          name="acceptedTerms"
          label={
            <Copy as="span" className="select-none tracking-[-0.196px]">
              <FormattedMessage
                id="settings.payment.i-agree-to"
                values={{
                  data: (
                    <a
                      href={`https://outvio.com/${termsLink}`}
                      target="_blank"
                      className="text-v2blueGray-800 font-medium underline tracking-[-0.196px]"
                    >
                      <FormattedMessage id="settings.payment.terms-of-service" />
                    </a>
                  ),
                }}
              />
            </Copy>
          }
        />
      </AlertContainer>

      {!isShopifyBilling && (
        <div className="flex-1 flex items-center justify-center">
          <div className="w-full flex justify-between mb-10 px-4">
            <Heading type="heading1" weight="light" className="text-v2blueGray-800">
              <FormattedMessage id="settings.payment.billed-today" />
            </Heading>

            {isLoading && <Loader className="flex-1" />}

            {!isLoading && (
              <Heading type="heading1" weight="semibold" className="text-v2blueGray-800">
                {currencyToSymbol(currency, formattedAmount)}
              </Heading>
            )}
          </div>
        </div>
      )}

      {errorMessage && (
        <ErrorMessage
          className="mb-8"
          title={<FormattedMessage id="general.action-failed" />}
          message={errorMessage}
        />
      )}

      <div className="flex items-end">
        <Button
          disabled={isSubmitting || !!errorMessage}
          loading={isSubmitting}
          onClick={onSubmit}
          className="w-full"
        >
          <FormattedMessage id={buttonTitleKey} />
        </Button>
      </div>
    </>
  );
};

export default ConfirmationSection;
