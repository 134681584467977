import { ReactNode, memo } from 'react';

import { twMerge } from 'tailwind-merge';

import './NavbarItem.css';

export interface NavbarItemProps {
  icon: ReactNode;
  isActive?: boolean;
}

export function NavbarItemBase({ icon, isActive }: NavbarItemProps) {
  return <div className={twMerge('navbar-item', isActive ? 'active' : '')}>{icon}</div>;
}

export const NavbarItem = memo(NavbarItemBase);
