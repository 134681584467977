import { Delete } from 'icons';
import { useIntl } from 'localization';
import { Transforms } from 'slate';
import {
  ReactEditor,
  RenderElementProps,
  useFocused,
  useSelected,
  useSlateStatic,
} from 'slate-react';
import { twMerge } from 'tailwind-merge';
import { Tooltip } from 'ui';
import { IconButton } from 'ui/atoms';

const ImageComponent = ({ attributes, children, element }: RenderElementProps) => {
  const editor = useSlateStatic();
  const path = ReactEditor.findPath(editor, element);

  const { formatMessage } = useIntl();

  const selected = useSelected();
  const focused = useFocused();

  return (
    <div {...attributes}>
      {children}
      <div contentEditable={false} className="relative">
        <img
          src={element.children[0]?.text || ''}
          className={twMerge(
            'my-2 block max-w-full max-h-[20em] rounded-[10px]',
            selected && focused && 'ring-4 ring-v2blueGray-800/20',
          )}
        />
        <IconButton
          onClick={() => Transforms.removeNodes(editor, { at: path })}
          className={twMerge(
            'break-normal absolute top-[0.5em] left-[0.5em] w-9 h-9 bg-v2blueGray-200 text-v2blueGray-800  items-center justify-center',
            selected ? 'flex' : 'hidden',
          )}
        >
          <Tooltip title={formatMessage({ id: 'general.remove' })} placement="top">
            <Delete className="w-7 h-7" />
          </Tooltip>
        </IconButton>
      </div>
    </div>
  );
};

export default ImageComponent;
