import React, { forwardRef, useContext, useMemo } from 'react';

import { makeStyles } from '@material-ui/core';
import { twMerge } from 'tailwind-merge';

import { useGetPaletteColors } from '../hooks';
import OVCStoreDataContext from './OVCStoreDataContext';

const useStyles = makeStyles({
  hero: {
    width: '100%',

    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    backgroundPositionX: 'center',
    backgroundPositionY: 'center',
    backgroundSize: 'cover',
    backgroundRepeatX: 'no-repeat',
    backgroundRepeatY: 'no-repeat',
    backgroundAttachment: 'fixed',
  },
});

interface IOVCHeroImage {
  children: React.ReactNode;
  useTrackingImg?: boolean;
}

interface ImgKey {
  desktop: 'desktopBg' | 'trackingDesktopBg';
}

const OVCHeroImage = forwardRef<HTMLDivElement, IOVCHeroImage>(
  ({ children, useTrackingImg = false }, ref) => {
    const context = useContext(OVCStoreDataContext);

    const { secondaryBg } = useGetPaletteColors();

    const imgKey: ImgKey = useMemo(
      () => (useTrackingImg ? { desktop: 'trackingDesktopBg' } : { desktop: 'desktopBg' }),
      [useTrackingImg],
    );

    const classes = useStyles();

    const containerStyle: React.CSSProperties = useMemo(
      () => ({
        backgroundImage: `url(${context.storeData.portalSettings[imgKey['desktop']]})`,
        backgroundColor: secondaryBg,
      }),
      [context.storeData, useTrackingImg],
    );

    return (
      <div className={twMerge(classes.hero, 'flex-1')} style={containerStyle} ref={ref}>
        {children}
      </div>
    );
  },
);

export default OVCHeroImage;
