import { HTMLProps } from 'react';

import { twMerge } from 'tailwind-merge';

export interface _CopyProps {
  className?: string;
  as?: 'p' | 'span' | 'label' | 'div';
  type?: 'copy1' | 'copy2' | 'copy3' | 'copy4' | 'copy5' | 'copy6' | 'copy7' | 'copy8';
  weight?: 'regular' | 'medium' | 'semibold';
  noColor?: boolean;
}

const headings = {
  copy1: 'text-[14px] leading-[22px]', // tracking Xd 14
  copy2: 'text-[12px] leading-[18px] tracking-[-0.144px]', // tracking Xd -12
  copy3: 'text-[12px] leading-[16px] tracking-[-0.144px]', // tracking Xd -12
  copy4: 'text-[14px] leading-[22px] tracking-[-0.252px]', // tracking Xd -18
  copy5: 'text-[14px] leading-[22px]', // tracking Xd 8
  copy6: 'text-[14px] leading-[22px] tracking-[-0.252px]', // tracking Xd -18
  copy7: 'text-[14px] leading-[20px]', // tracking Xd 8
  copy8: 'text-[14px] leading-[18px] tracking-[0px]', // tracking Xd 0
};

const fontWeights = {
  regular: 'font-normal',
  medium: 'font-medium',
  semibold: 'font-semibold',
};

export type CopyProps = HTMLProps<HTMLElement> & _CopyProps;

export function Copy({
  className,
  as: Tag = 'p',
  type = 'copy1',
  weight = 'regular',
  children,
  noColor = false,
  ...others
}: CopyProps) {
  const othersProps = { ...others } as any;
  return (
    <Tag
      data-testid="outvioui--copy"
      className={twMerge(
        'font-inter',
        !noColor && 'text-v2blueGray-700',
        headings[type],
        fontWeights[weight],
        className,
      )}
      {...othersProps}
    >
      {children}
    </Tag>
  );
}

export default Copy;
