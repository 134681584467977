import { IntlShape } from 'localization';
import * as yup from 'yup';

const helpToImproveSchema = (t: IntlShape) => {
  const requiredMessage = t.formatMessage({ id: '3PL.error.required-message' });

  return yup.object().shape({
    feedbackMessage: yup.string().required(requiredMessage),
    terms: yup.boolean().oneOf([true], requiredMessage),
  });
};

export { helpToImproveSchema };
