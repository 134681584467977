import React, { ReactNode, useState } from 'react';

import { Middleware, Placement, flip, offset, shift, useFloating } from '@floating-ui/react-dom';
import { twMerge } from 'tailwind-merge';

export interface CustomTooltipProps {
  children: React.ReactNode;
  title: string | ReactNode;
  placement?: Placement;
  offset?: number;
  middleware?: Middleware[];
  className?: string;
}

export const Tooltip: React.FC<CustomTooltipProps> = ({
  children,
  title,
  placement = 'top',
  middleware = [offset(4), flip(), shift()],
  className,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const { refs, floatingStyles } = useFloating({
    placement,
    middleware,
  });

  const handleMouseEnter = () => {
    setIsOpen(true); // Set isOpen to true on mouse enter
  };

  const handleMouseLeave = () => {
    setIsOpen(false);
  };

  return (
    <div ref={refs.setReference} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
      {children}
      {isOpen && title && (
        <div
          ref={refs.setFloating}
          style={floatingStyles}
          aria-hidden={!isOpen}
          className={twMerge(
            'z-10 bg-v2blueGray-800 text-white rounded-[10px] border border-v2blueGray-700 shadow-[0_4px_8px_#23384133] px-3 py-1 text-xs tracking-[-0.144px] leading-[18px]',
            className,
          )}
        >
          {title}
        </div>
      )}
    </div>
  );
};
