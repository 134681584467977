import { useCallback, useEffect } from 'react';

import { insertCreditCard } from 'data-layer';

import { GAWrapper } from '../../../utils/GAWrapper';

type CardDataProps = {
  holderName: string;
  number: string;
  cvc: string;
  expiryYear: string;
  expiryMonth: string;
  generationtime: string;
};

const useActivateNewCard = () => {
  useEffect(() => {
    // ======= Load adyen SDK ===============================
    if (!document.getElementById('adyen-sdk')) {
      const scriptSrc = import.meta.env.VITE_OUTVIO_ADYEN_SDK;
      if (scriptSrc) {
        const script = document.createElement('script');

        script.id = 'adyen-sdk';
        script.src = scriptSrc;
        script.async = true;

        document.body.appendChild(script);
      }
    }
  }, []);

  return useCallback(async (cardData: CardDataProps) => {
    if (!(window as any).adyen) {
      return 'Adyen failed to initialize';
    }

    const cseInstance = (window as any).adyen.createEncryption(
      process.env.OUTVIO_ADYEN_API_KEY,
      {},
    );

    let encryptedCartData = null;
    try {
      encryptedCartData = cseInstance.encrypt(cardData);

      if (encryptedCartData === false) {
        throw new Error('CSE returned false');
      }
    } catch (error: any) {
      return 'Error with encryption.';
    }

    try {
      await insertCreditCard({
        holderName: cardData.holderName,
        number: cardData.number,
        expiryYear: cardData.expiryYear,
        expiryMonth: cardData.expiryMonth,
        activate: true,
        'adyen-encrypted-data': encryptedCartData,
      });

      GAWrapper.setValue({
        event: 'credit_card_attached',
      });
    } catch (error) {
      return (error as any)?.message || error?.toString();
    }
  }, []);
};

export { useActivateNewCard };
