export default {
  'general.lang.ca': 'Kataloński',
  'general.lang.de': 'Niemiecki',
  'general.lang.ee': 'Estoński',
  'general.lang.en': 'Angielski',
  'general.lang.es': 'Hiszpański',
  'general.lang.et': 'Estoński',
  'general.lang.fi': 'Fiński',
  'general.lang.fr': 'Francuski',
  'general.lang.it': 'Włoski',
  'general.lang.lt': 'Litewski',
  'general.lang.lv': 'Łotewski',
  'general.lang.pl': 'Polski',
  'general.lang.pt': 'Portugalski',
  'general.back': 'POWRÓT',
  'general.required': 'Wymagane',
  'general.PRODUCT_PACKAGE_BROKEN': 'Zniszczone opakowanie',
  'general.PRODUCT_BROKEN': 'Zniszczony przedmiot',
  'general.PRODUCT_USED': 'Przedmiot był użyty',
  'general.PRODUCT_DIRTY': 'Brudny przedmiot',
  'general.MISSING_PRODUCT_LABEL': 'Brak etykiety przedmiotu',
  'general.PRODUCT_MISSING': 'Brakujący przedmiot',
  'general.OTHER': 'Inne',
  'general.credit-card': 'Karta Kredytowa',
  'general.bank-transfer': 'Transfer Bankowy',
  'general.cash-on-delivery': 'Za Pobraniem',
  'general.continue': 'Kontynuuj',
  'general.CLOSED': 'ZAMKNIĘTE',
  'general.PARTIALLY_RECEIVED': 'CZĘŚCIOWO DOSTARCZONO',
  'general.RECEIVED': 'OTRZYMANO',
  'general.IN_TRANSIT': 'W TRANZYCIE',
  'general.comments': 'Komentarz',
  'general.return': 'Zwrot',
  'general.user-account': 'Konto użytkownika',
  'general.sign-out': 'Wyloguj się',
  'general.accept': 'Akceptuj',
  'general.was-returned-on': 'Zostało zwrócone w dniu',
  'general.product-cannot-returned': 'Ten przedmiot nie może być zwrócony',
  'general.product-non-returnable':
    'Ten produkt nie może zostać zwrócony. Prosimy o kontakt z obsługą klienta, jeśli chcesz go zwrócić',
  'general.can-return-it': 'Możesz dokonać zwrotu do',
  'general.created': 'UTWORZONO',
  'general.shipped': 'WYSŁANO',
  'general.in-transit': 'W TRANZYCIE',
  'general.out-of-delivery': 'Z PRZEZNACZENIEM NA DOSTAWĘ',
  'general.delivered': 'DOSTARCZONO',
  'general.shipping-charges': 'OPŁATY ZA WYSYŁKĘ',
  'general.order-details': 'Szczegóły zamówienia',
  'general.return-details': 'Szczegóły zwrotu',
  'general.no-orders-in-transit': 'Brak zamówień w tranzycie',
  'general.products-returned': 'Przedmioty do zwrotu',
  'general.select-motive': 'Wybierz powód',
  'general.why-want-return': 'Dlaczego chcesz zwrócić ten produkt?',
  'general.do-have-comments': 'Czy masz jakieś uwagi?',
  'general.enter-comment': 'Wprowadź swój komentarz',
  'general.do-want-attach-image': 'Czy chcesz dołączyć jakieś zdjęcia?',
  'general.pickup-address': 'Adres odbioru',
  'general.date-time-pickup': 'Data i godzina odbioru',
  'general.contact-phone-number': 'Numer telefonu kontaktowego',
  'general.comments-courier': 'Komentarz dla kuriera',
  'general.confirm-address': 'Potwierdzam adres odbioru:',
  'general.quantity': 'Ilość',
  'general.length': 'Długość',
  'general.width': 'Szerokość',
  'general.height': 'Wysokość',
  'general.identifier': 'Identyfikator',
  'general.tracking-number': 'Numer śledzenia przesyłki',
  'general.date-purchase': 'Data zakupu',
  'general.date-time': 'Data i godzina',
  'general.status': 'Status',
  'general.shipping-cost': 'Koszt wysyłki dla tego zwrotu',
  'general.free': 'Darmowy',
  'general.done': 'Wykonano',
  'general.spanish': 'Hiszpański',
  'general.english': 'Angielski',
  'returns.step.products.title': 'WYBÓR PRZEDMIOTU',
  'returns.step.products.text': 'Wybierz przedmioty do zwrotu',
  'returns.step.method.title': 'METODA ZWROTU',
  'returns.step.method.text': 'Wybierz metodę zwrotu',
  'returns.step.instructions.title': 'INSTRUKCJE',
  'returns.step.instructions.text': 'Przygotuj swój zwrot',
  'returns.step.confirm.title': 'POTWIERDZENIE',
  'returns.step.confirm.text': 'ZWROT ZAKOŃCZONY',
  'general.img-upload': 'Przeciągnij i upuść lub kliknij, aby dodać obraz',
  'retcart.packages.text': `Wypełnij pakiety, aby zakończyć swój zwrot.
    {lineBreak}{lineBreak}NASZE WSKAZÓWKI:
    {lineBreak}- Spróbuj ponownie wykorzystać tę samą paczkę, która została użyta do dostarczenia Twojego zamówienia. Jest to dobre dla planety i jest łatwo dostępne!
    {lineBreak}- Jeśli zwracasz więcej niż jeden przedmiot, spróbuj użyć jak najmniejszej ilości opakowań.
    {lineBreak}- Pamiętaj, kiedy wysyłasz rzeczy, powietrze wewnątrz opakowania jest również wysyłane! Jeśli to możliwe i bezpieczne, spróbuj użyć pudełka, które minimalizuje pustą przestrzeń. To obniża twoje koszty zwrotu.`,
  'retcart.step2.heading':
    'POTWIERDŹ ROZMIAR SWOJEJ PACZKI (KLIKNIJ "DODAJ PACZKĘ", JEŚLI MUSISZ UŻYĆ WIĘCEJ NIŻ JEDNEJ)',
  'retcart.pack.default-title.display': 'UŻYJ PACZKI DOSTAWY:',
  'retcart.pack.custom-title.display': 'UŻYJ WŁASNEJ PACZKI',
  'retcart.pack.custom-title.edit': 'UŻYJ WŁASNE OPAKOWANIE (rozmiar w cm)',
  'retcart.pack.change-size': '(Zmień rozmiar)',
  'retcart.pack.remove': '(Usuń)',
  'retcart.pack.add': 'DODAJ PACZKĘ',
  'retcart.addrmodal.heading1': 'WYBIERZ KAŻDY Z ZAPISANYCH ADRESÓW',
  'retcart.addrmodal.heading2': 'LUB WPISZ NOWY ADRES',
  'retcart.print.loading.heading': 'Właśnie przetwarzamy Twój zwrot...',
  'retcart.print.loading.note': 'Nie zamykaj tego okna, dopóki nie przetworzymy Twojego żądania!',
  'landing.email-sent':
    'Email wysłany pomyślnie, proszę sprawdzić swoją skrzynkę odbiorczą, aby zweryfikować swój email w ciągu najbliższych 15 minut.',
  'general.status.IN_TRANSIT': 'W TRANZYCIE',
  'general.status.DELIVERED': 'DOSTARCZONO',
  'general.status.DELIVERED_POST_OFFICE': 'DOSTARCZONO',
  'general.status.DELIVERED_TO_PICKUP_OFFICE': 'DOSTARCZONO',
  'general.status.DELIVERED_TO_PICKUP_POINT': 'DOSTARCZONO DO{lineBreak}PUNKTU ODBIORU',
  'general.status.DELIVERED_TO_SHOP': 'DOSTARCZONE DO{lineBreak}SKLEPU',
  'general.status.PARTIALLY_DELIVERED': 'CZĘŚCIOWO DOSTARCZONO',
  'general.status.PARTIALLY_DELIVERED.short': 'CZĘŚCIOWO DOSTARCZONO',
  'general.status.DELAYED': 'OPÓŹNIONY',
  'general.status.RETURNED': 'ZWRÓCONO',
  'general.status.DELETED': 'USUNIĘTO',
  'general.status.RECEIVED': 'OTRZYMANO',
  'general.status.PARTIALLY_RECEIVED': 'PARTIALLY RECEIVED',
  'general.status.PARTIALLY_RECEIVED.short': 'ODEBRANE CZĘŚCIOWO',
  'general.status.CLOSED': 'ZAMKNIĘTO',
  'general.status.CREATED': 'STWORZONO',
  'general.status.PICKING_QUEUE': 'KOLEJKA PICKING',
  'general.status.SHIPPING_QUEUE': 'KOLEJKA WYSYŁKI',
  'general.status.REFUNDED': 'ZREFUNDOWANO',
  'general.status.SHIPMENT_PICKUP': 'PICKUP WYSYŁKI',
  'general.status.SHIPPED': 'WYSŁANO',
  'general.status.PICKUP': 'WYSŁANO',
  'general.status.IN_TRANSIT_DELAYED': 'OPÓŹNIONE W TRANZYCIE',
  'general.status.EXCEPTION': 'WYJĄTEK',
  'general.status.OUT_FOR_DELIVERY': 'ZA DOSTAWĄ',
  'general.status.RETURNED_TO_ORIGIN': 'POWRÓT DO NADAWCY',
  'general.status.CANCELLED': 'ANULOWANO',
  'sidebar.orders-in-transit': 'ZAMÓWIENIA W TRANZYCIE',
  'sidebar.my-orders': 'MOJE ZAMÓWIENIA',
  'sidebar.returns': 'ZWROTY',
  'general.courier.gls_ireland': 'GLS Ireland',
  'general.courier.tnt_italy': 'TNT Italy',
  'general.courier.brt': 'BRT',
  'general.courier.bring': 'Bring',
  'general.courier.budbee': 'Budbee',
  'general.courier.cbl': 'CBL',
  'general.courier.correos': 'Correos Express',
  'general.courier.ctt': 'CTT',
  'general.courier.chornopost': 'Chronopost',
  'general.courier.deprecated': 'DEPRECATED',
  'general.courier.deutschepost': 'Deutsche Post',
  'general.courier.deutschepost_germany': 'Deutsche Post',
  'general.courier.dhl': 'DHL',
  'general.courier.dhl_sweden': 'DHL',
  'general.courier.dhlparcel': 'DHL Parcel',
  'general.courier.dhlparcel_portugal': 'DHL Parcel',
  'general.courier.dhlparcel_spain': 'DHL Parcel',
  'general.courier.dhlparcel_poland': 'DHL Parcel',
  'general.courier.dhlparcel_unitedkingdom': 'DHL Parcel',
  'general.courier.dhl_paket_germany': 'DHL Paket',
  'general.courier.directa': 'Directa',
  'general.courier.dpd': 'DPD',
  'general.courier.gordon': 'Gordon',
  'general.courier.boostparcel': 'Boost Parcel',
  'general.courier.ronin': 'Ronin',
  'general.courier.trablisa': 'Trablisa Express',
  'general.courier.dpd_france': 'DPD',
  'general.courier.dpd_germany': 'DPD',
  'general.courier.dpd_portugal': 'DPD',
  'general.courier.dpd_portugal_new': 'DPD',
  'general.courier.dpd_romania': 'DPD',
  'general.courier.dpd_poland': 'DPD',
  'general.courier.envialia': 'Envialia',
  'general.courier.external': 'Kurier zewnętrzny',
  'general.courier.external-EXCHANGE': 'Wymień',
  'general.courier.fedex': 'FedEx',
  'general.courier.glovo': 'Glovo',
  'general.courier.gls': 'GLS OLD',
  'general.courier.glsnew': 'GLS',
  'general.courier.gls_italy': 'GLS',
  'general.courier.glsshipit': 'GLS ShipIt',
  'general.courier.hermes': 'Hermes',
  'general.courier.inpost': 'InPost',
  'general.courier.inpost_spain': 'InPost',
  'general.courier.beeasy_spain': 'Beeasy',
  'general.courier.internal': 'Wewnętrzne',
  'general.courier.matkahuolto': 'Matkahuolto',
  'general.courier.mondialrelay': 'Mondial Relay',
  'general.courier.colissimo': 'Colissimo',
  'general.courier.mrw': 'MRW',
  'general.courier.nacex': 'Nacex',
  'general.courier.omniva': 'Omniva',
  'general.courier.outvio': 'Outvio',
  'general.courier.paack': 'Paack',
  'general.courier.parcelforce': 'Parcelforce',
  'general.courier.posti': 'Posti',
  'general.courier.publiccorreos': 'Correos',
  'general.courier.postnord': 'PostNord',
  'general.courier.postnl': 'PostNL',
  'general.courier.relaiscolis': 'Relais Colis',
  'general.courier.royalmail': 'Royal Mail',
  'general.courier.schenker': 'DB Schenker',
  'general.courier.sending': 'SENDING',
  'general.courier.seur': 'SEUR',
  'general.courier.seur_atlas': 'SEUR Atlas',
  'general.courier.shop': 'To Shop',
  'general.courier.smartpost': 'Smartpost',
  'general.courier.spring': 'Spring GDS',
  'general.courier.tipsa': 'TIPSA',
  'general.courier.tnt': 'TNT',
  'general.courier.transaher': 'Transaher',
  'general.courier.ups': 'UPS',
  'general.courier.venipak': 'Venipak',
  'general.courier.zeleris': 'Zeleris',
  'general.courier.zeleriscustom': 'Zeleris',
  'general.courier.zitycity': 'Zitycity',
  'general.trackingbar.datetime': 'Data i godzina',
  'general.trackingbar.location': 'Lokalizacja',
  'general.trackingbar.status': 'Status',
  'general.trackingbar.detail': 'Szczegóły',
  'general.trackingbar.no-info':
    'Informacje o śledzeniu nie są jeszcze dostępne dla tego zamówienia. Zazwyczaj potrzeba kilku godzin, aby je zaktualizować. Proszę, sprawdź później.',
  'general.return-error.all-returned': 'Wszystkie przedmioty zostały zwrócone.',
  'retcart.heading.methods': 'JAK CHCESZ ZWRÓCIC SWOJE PRZEDMIOTY?',
  'retcart.method.toClientShop':
    'PRZYJDŹ Z PRZEDMIOTAMI DO JEDNEGO Z NASZYCH SKLEPÓW BLISKO CIEBIE',
  'retcart.method.outvio': 'SKORZYSTAJ Z NASZEGO KURIERA, ABY WYSŁAĆ SWÓJ ZWROT',
  'retcart.method.ownCourier': 'WYŚLIJ NAM SWÓJ ZWROT WŁASNYM KURIEREM',
  'retcart.method.exchange': 'WYMIANA LUB ZASTĄPIENIE PRZEDMIOTÓW PRZED ICH ZWROTEM',
  'retcart.inst.toClientShop.heading':
    'POTWIERDŹ SWÓJ ZWROT I PRZYNIEŚ PRZEDMIOTY DO NAJBLIŻSZEGO SKLEPU',
  'retcart.inst.toClientShop.text1':
    'Aby sfinalizować swój zwrot, proszę kliknąć przycisk POTWIERDŹ poniżej.:',
  'retcart.inst.toClientShop.text2':
    'Arkusz zwrotu zostanie stworzony dla Ciebie i będziesz musiał zanieść produkty do jednego z naszych sklepów przed {dueDate}.',
  'retcart.inst.toClientShop.text3': 'Pełną listę naszych sklepów można znaleźć tutaj: {link}.',
  'retcart.inst.toClientShop.text3.link': 'lista lokalizacji sklepów',
  'general.confirm': 'potwierdź',
  'retcart.inst.ownCourier.heading':
    'POTWIERDŹ ZWROT I WYŚLIJ PRZEDMIOTY WYBRANĄ PRZEZ SIEBIE FIRMĄ KURIERSKĄ',
  'retcart.inst.ownCourier.text1':
    'Aby sfinalizować swój zwrot, proszę kliknąć poniższy przycisk POTWIERDŹ:',
  'retcart.inst.ownCourier.text2':
    'Arkusz zwrotny zostanie utworzony dla Ciebie i będziesz musiał upewnić się, że wysłałeś swoje przedmioty przed {dueDate}.',
  'retcart.inst.ownCourier.text3': 'Przedmioty należy przesyłać na adres:',
  'retcart.inst.ownCourier.text4':
    'PS: Możesz użyć dowolnego kuriera, ale będziesz musiał pokryć koszty zwrotu. Zalecamy korzystanie z godnych zaufania usług kurierskich, aby mieć pewność, że Twój zwrot dotrze do nas bezpiecznie.',
  'retcart.inst.exchange.heading': 'PODAJ NAM SZCZEGÓŁY I POTWIERDŹ SWOJĄ WYMIANĘ',
  'retcart.inst.exchange.text1':
    'Na jaki przedmiot, rozmiar i ilość chciałbyś wymienić obecny przedmiot(y)?',
  'retcart.inst.exchange.text2':
    'Upewnij się, że dodałeś wszystkie niezbędne szczegóły w Twojej wiadomości.',
  'retcart.inst.exchange.text.placeholder': 'Zostaw nam swoje szczegóły wymiany tutaj.',
  'retcart.inst.exchange.text3': `Aby sfinalizować wymianę, proszę kliknąć POTWIERDŹ poniżej. Email z Twoją prośbą o wymianę zostanie wysłany do nas, a my skontaktujemy się z Tobą w celu uzgodnienia szczegółów wymiany. Kopia wiadomości e-mail zostanie również wysłana na Twój adres e-mail..`,
  'retcart.confirm-return-terms': 'Zapoznałem się z moim zwrotem i akceptuję {terms}',
  'retcart.confirm-return-terms.terms': 'Zasady i warunki',
  'retcart.confirm.heading': 'ZWROT ZAKOŃCZONY',
  'retcart.confirm.text3': 'ABY MIEĆ PEWNOŚĆ, ŻE WSZYSTKO PÓJDZIE GŁADKO Z TWOIM ZWROTEM:',
  'retcart.confirm.toClientShop.text4':
    '1. Wydrukuj formularz zwrotu lub zapisz kopię elektroniczną, którą wysłaliśmy na Twój adres e-mail.',
  'retcart.confirm.toClientShop.text5': '2. Przynieś swój zwrot do {fillerShop} przed {dueDate}. ',
  'retcart.confirm.toClientShop.text5.fillerShop': 'sklep blisko Ciebie',
  'retcart.confirm.toClientShop.text6': '3. Należy pamiętać o zabraniu ze sobą arkusza zwrotnego.',
  'retcart.download-return-sheet': 'POBIERZ ARKUSZ ZWROTNY',
  'retcart.download-label': 'POBIERZ ETYKIETĘ I ARKUSZ ZWROTNY',
  'retcart.confirm.ownCourier.text1':
    '1. Wydrukuj arkusz zwrotu i umieść go wewnątrz paczki razem z przedmiotami, które zwracasz.',
  'retcart.confirm.ownCourier.text2':
    '2. Wyślij swój zwrot za pośrednictwem zaufanej, sprawdzonej firmy kurierskiej na adres:',
  'retcart.confirm.ownCourier.text3': '3. Upewnij się, aby wysłać swój zwrot przed {dueDate}.',
  'retcart.confirm.outvio.text1':
    'TWÓJ ZWROT ZOSTAŁ PRZETWORZONY POMYŚLNIE, PROSZĘ POBRAĆ ETYKIETĘ WYSYŁKOWĄ KLIKAJĄC PONIŻSZY PRZYCISK',
  'retcart.confirm.outvio.text2': '(etykieta została również wysłana na Twój adres e-mail):',
  'retcart.confirm.outvio.text3':
    'ABY MIEĆ PEWNOŚĆ, ŻE WSZYSTKO PÓJDZIE GŁADKO, GDY PRZYJEDZIE KURIER, PROSZĘ:',
  'retcart.confirm.outvio.text4': '1. Wydrukuj arkusz zwrotu i odetnij etykietę wysyłkową.',
  'retcart.confirm.outvio.text5':
    '2. Umieść arkusz zwrotny wewnątrz paczki i przyklej etykietę na prawidłowo zamkniętej paczce..',
  'retcart.confirm.outvio.text6':
    '3. Usiądź wygodnie, zrelaksuj się i czekaj na kuriera, który odbierze Twój zwrot!',
  'retcart.inst.headline.important': 'WAŻNE:',
  'retcart.inst.hasPickup.noLabel': `1. Zapoznaj się z adresem odbioru i przedmiotami w przesyłce zwrotnej.{lineBreak}
      2. Po kliknięciu "POTWIERDŹ"zostanie utworzony arkusz zwrotny.{lineBreak}
      3. Umieść zwracane przedmioty wewnątrz paczki i zamknij pudełko. {fillerOptionally}, możesz również dodać arkusz zwrotny wewnątrz paczki.{lineBreak}
      4. Po kliknięciu przycisku Potwierdź, powiadomimy kuriera, który przyjedzie odebrać Twój zwrot w ciągu najbliższych 2 dni roboczych. WAŻNE! Nie musisz drukować żadnej etykiety wysyłkowej. Kurier przywiezie ją, gdy przyjedzie po odbiór zwrotu.`,
  'retcart.inst.hasPickup.noLabel.fillerOptionally': 'Opcjonalnie',
  'retcart.inst.hasPickup.byEmail': `1. Sprawdź adres odbioru i przedmioty w zwrocie.{lineBreak}
      2. Po kliknięciu przycisku "POTWIERDŹ"zostanie utworzona Karta Zwrotu oraz zostanie wysłane zlecenie odbioru do naszego kuriera. {fillerNotice}.{lineBreak}
      3. Po otrzymaniu etykiety wysyłkowej włóż arkusz zwrotny do środka paczki, zamknij ją i naklej etykietę wysyłkową na paczkę.{lineBreak}
      4. Nasz kurier przyjedzie po Twój zwrot w ciągu najbliższych 2 dni roboczych.`,
  'retcart.inst.hasPickup.byEmail.fillerNotice':
    'Etykietę wysyłkową otrzymasz pocztą elektroniczną ({email}) w ciągu najbliższych 2 dni roboczych.',
  'retcart.inst.hasPickup.provided': `1. Sprawdź swój adres odbioru i przedmioty w zwrocie.{lineBreak}
      2. Po kliknięciu przycisku "POTWIERDŹ", zostanie utworzony arkusz zwrotu. Proszę go wydrukować i odciąć etykietę wysyłkową dla każdej paczki w zwrocie.{lineBreak}
      3. Umieść zwracane przedmioty wewnątrz paczki wraz z kartą zwrotu, naklej etykietę wysyłkową i zamknij pudełko.{lineBreak}
      4. Kurier przyjedzie po Twój zwrot w ciągu najbliższych 2 dni roboczych.`,
  'retcart.inst.noPickup.byEmail': `1. Dokonaj przeglądu przedmiotów z Twojego zwrotu{lineBreak}
      2. Po kliknięciu "POTWIERDŹ"zostanie utworzony arkusz zwrotny. {fillerNotice}{lineBreak}
      3. Otrzymasz zwrotną etykietę wysyłkową pocztą elektroniczną. Po otrzymaniu etykiety wysyłkowej włóż arkusz zwrotny do paczki, zamknij ją i naklej etykietę wysyłkową na paczkę.{lineBreak}
      4. W ciągu 5 dni roboczych należy dostarczyć paczkę (paczki) do najbliższego punktu odbioru kurierskiego. Aby znaleźć najbliższy punkt odbioru, odwiedź stronę kurierów {fillerCourierHomepage}.`,
  'retcart.inst.noPickup.byEmail.fillerNotice':
    ' Etykietę wysyłkową otrzymasz pocztą elektroniczną na adres ({email}) w ciągu najbliższych 2 dni.',
  'retcart.inst.noPickup.byEmail.fillerCourierHomepage': 'tutaj',
  'retcart.inst.noPickup.provided': `1. Dokonaj przeglądu przedmiotów z Twojego zwrotu{lineBreak}
      2. Po kliknięciu przycisku "POTWIERDŹ", zostanie utworzony arkusz zwrotu. Proszę go wydrukować i odciąć etykietę wysyłkową dla każdej paczki w zwrocie.{lineBreak}
      3. Umieść zwracane przedmioty w paczce wraz z kartą zwrotu, naklej etykietę wysyłkową na paczkę i zamknij pudełko.{lineBreak}
      4. W ciągu 5 dni należy dostarczyć paczkę (paczki) do najbliższego punktu odbioru kurierskiego. Aby znaleźć najbliższy punkt odbioru, odwiedź stronę kurierów {fillerCourierHomepage}.`,
  'retcart.inst.noPickup.provided.fillerCourierHomepage': 'tutaj',
  'retcart.confirm.outvio.head-sheet':
    'TWÓJ ZWROT ZOSTAŁ PRZETWORZONY POMYŚLNIE, PROSZĘ POBRAĆ ARKUSZ ZWROTU KLIKAJĄC NA PONIŻSZY PRZYCISK',
  'retcart.confirm.outvio.head-sheet.note':
    '(arkusz zwrotny został również wysłany na Twój adres e-mail):',
  'retcart.confirm.outvio.head-label':
    'TWÓJ ZWROT ZOSTAŁ PRZETWORZONY POMYŚLNIE, PROSZĘ POBRAĆ ETYKIETĘ WYSYŁKOWĄ KLIKAJĄC PONIŻSZY PRZYCISK',
  'retcart.confirm.outvio.head-label.note':
    '(arkusz zwrotny został również wysłany na Twój adres e-mail):',
  'retcart.confirm.outvio.head-label-by-email': 'TWÓJ ZWROT ZOSTAŁ PRZETWORZONY POMYŚLNIE',
  'retcart.confirm.outvio.hasPickup.noLabel.head':
    'ABY MIEĆ PEWNOŚĆ, ŻE WSZYSTKO PÓJDZIE GŁADKO, GDY PRZYJEDZIE KURIER, PROSZĘ:',
  'retcart.confirm.outvio.hasPickup.noLabel': `1. Wydrukuj arkusz zwrotny (opcjonalnie).{lineBreak}
      2. Dołącz arkusz zwrotny do prawidłowo zamkniętej paczki. NIE JEST WYMAGANA ETYKIETA WYSYŁKOWA!{lineBreak}
      3. Kurier przyjedzie po Twój zwrot w ciągu najbliższych 2 dni roboczych i przywiezie etykietę wysyłkową dla Twojego zwrotu.`,
  'retcart.confirm.outvio.hasPickup.byEmail.head':
    'PS: ETYKIETA WYSYŁKOWA ZOSTANIE WYSŁANA NA TWÓJ ADRES E-MAIL W CIĄGU NAJBLIŻSZYCH KILKU DNI.',
  'retcart.confirm.outvio.hasPickup.byEmail': `1. Po otrzymaniu etykiety wysyłkowej na adres e-mail, wydrukuj ją wraz z formularzem zwrotu.{lineBreak}
      2. Umieść arkusz zwrotny wewnątrz paczki, zamknij ją i naklej na nią etykietę wysyłkową.{lineBreak}
      3. Kurier przyjedzie po Twój zwrot w ciągu najbliższych 2 dni roboczych.`,
  'retcart.confirm.outvio.hasPickup.provided': `1. Wydrukuj arkusz zwrotu i odetnij etykietę wysyłkową.{lineBreak}
      2. Umieść arkusz zwrotny wewnątrz paczki, zamknij ją i naklej na nią etykietę wysyłkową.{lineBreak}
      3. The courier will come to pick up your return within the next 2 business days.`,
  'retcart.confirm.outvio.noPickup.byEmail': `1. Po otrzymaniu etykiety wysyłkowej na adres e-mail, wydrukuj ją wraz z formularzem zwrotu.{lineBreak}
      2. Umieść arkusz zwrotny wewnątrz paczki, zamknij ją i naklej na nią etykietę wysyłkową.{lineBreak}
      3. Zanieś swoją paczkę do najbliższego punktu odbioru. Aby znaleźć najbliższy punkt odbioru, odwiedź stronę kurierów {filler}.`,
  'retcart.confirm.outvio.noPickup.byEmail.filler': 'tutaj',
  'retcart.confirm.outvio.noPickup.provided': `1. Wydrukuj arkusz zwrotu i odetnij etykietę wysyłkową.{lineBreak}
      2. Umieść arkusz zwrotny wewnątrz paczki, zamknij ją i naklej na nią etykietę wysyłkową.{lineBreak}
      3. Zanieś swoją paczkę do najbliższego punktu odbioru. Aby znaleźć najbliższy punkt odbioru, odwiedź stronę kurierów {filler}.`,
  'retcart.confirm.outvio.noPickup.provided.filler': 'tutaj',
  'general.retcart.restart.btn': 'Uruchom ponownie proces zwrotu',
  'general.heading.refund.methods': 'JAK CHCESZ OTRZYMAĆ SWÓJ ZWROT PIENIĘDZY?',
  'retcart.method.SAME_AS_PAYMENT': 'ZWROT PIENIĘDZY NA MÓJ PIERWOTNY SPOSÓB PŁATNOŚCI',
  'retcart.method.CREDIT_IN_STORE': 'KREDYT W SKLEPIE NA PRZYSZŁE ZAKUPY',
  'retcart.confirm.exchange.heading': 'WNIOSEK O WYMIANĘ ZREALIZOWANY',
  'retcart.confirm.exchange.text1': 'TWÓJ WNIOSEK O WYMIANĘ ZOSTAŁ PRZYJĘTY POMYŚLNIE.',
  'retcart.confirm.exchange.text2':
    '(Kopia Twojego wniosku o wymianę została również wysłana na Twój adres e-mail.).',
  'retcart.confirm.exchange.text3':
    'Wkrótce skontaktujemy się z Tobą, aby poinformować Cię o szczegółach wymiany.',
  'retcart.confirm.exchange.text4': 'DZIĘKUJEMY!',
  'general.cancel': 'anuluj',
  'general.retcart.init-restart':
    'Jeśli problem nadal występuje, prosimy o ponowne rozpoczęcie procesu klikając TUTAJ.',
  'retcart.return-method.toClientShop': 'W SKLEPIE',
  'retcart.return-method.outvio': 'NASZ KURIER',
  'retcart.return-method.ownCourier': 'WŁASNZ KURIER',
  'retcart.return-method.exchange': 'WYMIANA / ZAMIANA',
  'retcart.return-method.SAME_AS_PAYMENT': 'REFUNDACJA',
  'retcart.return-method.CREDIT_IN_STORE': 'KREDYT SKLEPOWY',
  'retcart.return-method.pickup': 'PICKUP',
  'retcart.return-method.dropoff': 'DROP-OFF',
  'general.heading.pickup.methods': 'CZY CHCESZ PODRZUCIĆ PACZKĘ, CZY KURIER MA JĄ ODEBRAĆ?',
  'retcart.method.pickup': 'CHCĘ, ABY KURIER ODEBRAŁ PACZKĘ ZWROTNĄ Z MOJEGO DOMU',
  'retcart.method.dropoff': 'CHCĘ SAM ZANIEŚĆ PACZKĘ DO PUNKTU ODBIORU KURIERA',
  'retcart.img-attach-desc': 'Załaduj zdjęcia opakowania, przedmiotu i etykiety wysyłkowej',
  'retcart.confirm.outvio.noPickup.noLabel.head':
    'WAŻNE! PRZED ODWIEZIENIEM ZWROTU DO PUNKTU ZDAWCZO-ODBIORCZEGO NALEŻY:',
  'retcart.title.pickup': 'pickup',
  'retcart.title.dropoff': 'dropoff',
  'general.tracking.manage-exception': 'Zarządzaj wyjątkami na stronie kuriera',
  'general.error.id-ver-token-missing':
    'Token jest wymagany do uwierzytelnienia. Proszę sprawdzić e-mail wysłany w celu uwierzytelnienia.',
  'general.error.id-ver.token-missing':
    'Błąd podczas inicjalizacji. Upewnij się, że używasz przeglądarki evergreen i że jest ona zaktualizowana.',
  'general.error.fileupload.file-too-large': 'Maksymalny rozmiar pliku {size} MB',
  'general.error.fileupload.too-many-files': 'Maksymalnie {number} plików',
  'general.error.fileupload.file-invalid-type': 'Nieprawidłowy typ pliku',
  'general.error.fileupload.incorrect.size.max':
    'Rozdzielczość obrazu musi wynosić co najwyżej {width} na {height}.',
};
