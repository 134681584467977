import { ICourier } from 'app-types';

import request from './request';

export const getCouriersIcons = (): Promise<ICourier[]> =>
  request(
    //@ts-ignore
    `${import.meta.env.VITE_OUTVIO_API_URL}/courier/schema`,
    {
      method: 'GET',
      secure: true,
    },
    { skipLogout: true, skipJsonLS: true },
  ).then((res) => res.data);
