// event tracking with dataLayer because using google tag manager
// https://www.bounteous.com/insights/2018/03/30/single-page-applications-google-analytics/
import { Maybe } from 'app-types/core';

declare global {
  interface Window {
    dataLayer?: any;
  }
}

class GAWrapperInstance {
  checkIfAvailable() {
    if (typeof window.dataLayer === 'undefined') {
      window.dataLayer = [];
    }
  }

  sendEvent(category: string, action: string, label: string, auxData?: any) {
    this.checkIfAvailable();

    window.dataLayer.push({
      event: 'genericEvent',
      eventCategory: category,
      eventAction: action,
      eventLabel: label,
      eventValue: undefined,
      ...auxData,
    });
  }

  setValue(value: any) {
    this.checkIfAvailable();

    window.dataLayer.push({
      event: 'genericEvent',
      ...value,
    });
  }

  trackPageView(path: string) {
    this.checkIfAvailable();

    window.dataLayer.push({
      event: 'pageview',
      path,
    });
  }
}

export const GAWrapper = new GAWrapperInstance();

export function sendGAOnboardingCompleted({
  clientCode,
  userId,
  companyName,
  shipmentsPerMonth,
  clientSource,
  shopUrl,
  city,
  countryCode,
}: {
  clientCode?: string;
  userId?: string;
  companyName?: Maybe<string>;
  shipmentsPerMonth?: Maybe<string>;
  clientSource?: Maybe<string>;
  shopUrl?: Maybe<string>;
  city?: Maybe<string>;
  countryCode?: Maybe<string>;
}) {
  const analyticsData: any = {
    cd_customer_code: clientCode,
    user_id: userId,
    cd_company_name: companyName,
    cd_shipments_per_month: shipmentsPerMonth,
  };
  if (clientSource) {
    analyticsData['cd_client_source'] = clientSource;
  }
  if (shopUrl) {
    analyticsData['cd_shop_url'] = shopUrl;
  }
  if (city) {
    analyticsData['cd_city'] = city;
  }
  if (countryCode) {
    analyticsData['cd_country'] = countryCode;
  }
  GAWrapper.sendEvent('Lead', 'Onboarding Completed', 'App', analyticsData);
}
