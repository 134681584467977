const OpenInNew = ({ ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 20 20"
      fill="currentColor"
      {...props}
    >
      <defs>
        <clipPath id="clip-path">
          <rect width="20" height="20" fill="currentColor" />
        </clipPath>
      </defs>
      <g clipPath="url(#clip-path)">
        <path
          d="M13.25,13.975v1.942a.836.836,0,0,1-.833.833H5.75a.836.836,0,0,1-.833-.833V9.25a.836.836,0,0,1,.833-.833H7.725a.833.833,0,1,0,0-1.667H5.75a2.5,2.5,0,0,0-2.5,2.5v6.667a2.5,2.5,0,0,0,2.5,2.5h6.667a2.5,2.5,0,0,0,2.5-2.5V13.975a.833.833,0,0,0-1.667,0"
          transform="translate(-0.542 -1.125)"
          fill="currentColor"
        />
        <path
          d="M18.833,3.25V9.917a.833.833,0,1,1-1.667,0V6.092l-1.5,1.5L14.25,9.017l-3.575,3.575a.854.854,0,0,1-.592.242.834.834,0,0,1-.583-.242.825.825,0,0,1,0-1.183l6.492-6.492H12.167a.833.833,0,1,1,0-1.667Z"
          transform="translate(-1.542 -0.542)"
          fill="currentColor"
        />
      </g>
    </svg>
  );
};

export { OpenInNew };
