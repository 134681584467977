export default {
  'general.lang.ca': 'Katalanisch',
  'general.lang.de': 'Deutsch',
  'general.lang.ee': 'Estnisch',
  'general.lang.en': 'Englisch',
  'general.lang.es': 'Spanisch',
  'general.lang.et': 'Estnisch',
  'general.lang.fi': 'Finnisch',
  'general.lang.fr': 'Französisch',
  'general.lang.it': 'Italienisch',
  'general.lang.lt': 'Litauisch',
  'general.lang.lv': 'Lettisch',
  'general.lang.pl': 'Polnisch',
  'general.lang.pt': 'Portugiesisch',
  'general.back': 'ZURÜCK',
  'general.required': 'Pflichtfeld',
  'general.PRODUCT_PACKAGE_BROKEN': 'Verpackung beschädigt',
  'general.PRODUCT_BROKEN': 'Artikel beschädigt',
  'general.PRODUCT_USED': 'Benutzter Artikel',
  'general.PRODUCT_DIRTY': 'Artikel verschmutzt',
  'general.WRONG_PRODUCT_RETURNED': 'Falscher Artikel geliefert',
  'general.MISSING_PRODUCT_LABEL': 'Artikel-Etikett fehlt',
  'general.PRODUCT_MISSING': 'Artikel fehlt',
  'general.OTHER': 'Sonstiges',
  'general.credit-card': 'Bankkarte',
  'general.bank-transfer': 'Überweisung',
  'general.cash-on-delivery': 'Barzahlung bei Lieferung',
  'general.continue': 'Weiter',
  'general.CLOSED': 'GESCHLOSSEN',
  'general.PARTIALLY_RECEIVED': 'TEILWEISE ERHALTEN',
  'general.RECEIVED': 'ERHALTEN',
  'general.IN_TRANSIT': 'UNTERWEGS ZUM ZIEL',
  'general.comments': 'Bemerkungen',
  'general.return': 'Retoure beantragen',
  'general.user-account': 'Benutzerkonto',
  'general.sign-out': 'Ausloggen',
  'general.accept': 'Zustimmen',
  'general.was-returned-on': 'Rücksendungsdatum',
  'general.product-cannot-returned': 'Diese Ware kann nicht zurückgesendet werden',
  'general.product-non-returnable':
    'Dieser Artikel kann nicht zurückgesendet werden. Bitte kontaktiere Sie den Paketdienst des Shops, wenn Sie einen Artikel zurücksenden möchten',
  'general.can-return-it': 'Zurückzusenden bis',
  'general.created': 'FERTIG',
  'general.shipped': 'VERSCHICKT',
  'general.in-transit': 'UNTERWEGS',
  'general.out-of-delivery': 'IN ZUSTELLUNG',
  'general.delivered': 'ZUGESTELLT',
  'general.shipping-charges': 'TRANSPORTKOSTEN',
  'general.order-details': 'Bestellungsdetails',
  'general.return-details': 'Details zur Rückgabe',
  'general.no-orders-in-transit': 'Derzeit keine Bestellungen unterwegs',
  'general.products-returned': 'Zurückzusendende waren',
  'general.select-motive': 'Ursache auswählen',
  'general.why-want-return': 'Warum möchten Sie die Ware zurücksenden?',
  'general.do-have-comments': 'Möchten Sie einen Kommentar hinzufügen?',
  'general.enter-comment': 'Kommentar eingeben',
  'general.do-want-attach-image': 'Möchten Sie ein Bild von der Ware hinzufügen?',
  'general.pickup-address': 'Abholadresse',
  'general.date-time-pickup': 'Abholdatum und -uhrzeit',
  'general.contact-phone-number': 'Kontakttelefonnummer',
  'general.comments-courier': 'Nachricht an den Zusteller',
  'general.confirm-address': 'Ich bestätige die Abholadresse:',
  'general.quantity': 'Menge',
  'general.length': 'Länge',
  'general.width': 'Breite',
  'general.height': 'Höhe',
  'general.identifier': 'Kennzeichen',
  'general.tracking-number': 'Paketnummer',
  'general.date-purchase': 'Kaufdatum',
  'general.date-time': 'Datum Zeit',
  'general.status': 'Status',
  'general.shipping-cost': 'Versandkosten für diese Sendung',
  'general.free': 'Kostenlos',
  'general.done': 'Fertig',
  'general.spanish': 'Español',
  'general.english': 'English',
  'returns.step.products.title': 'ZU RETOURNIERENDE WARE',
  'returns.step.products.text': 'Waren für die Retoure auswählen',
  'returns.step.method.title': 'RETOURENMETHODE',
  'returns.step.method.text': 'Retourenmethode auswählen',
  'returns.step.instructions.title': 'ANWEISUNGEN',
  'returns.step.instructions.text': 'Vorbereitung der Retoure',
  'returns.step.confirm.title': 'BESTÄTIGUNG',
  'returns.step.confirm.text': 'Retoure bestätigen',
  'general.img-upload': 'Drag and Drop oder klicken Sie, um ein Bild hinzuzufügen',
  'retcart.packages.text': `Verpacken Sie die Artikel, die Sie zurücksenden möchten
    {lineBreak}{lineBreak}UNSERE EMPFEHLUNGEN:
    {lineBreak}- Verwenden Sie möglichst dasselbe Paket, in dem die Ware bei Ihnen ankam. Wiederverwertung ist gut für den Planeten und in der Regel die bequemste Lösung!
    {lineBreak}- Wenn Sie mehr als einen Artikel zurücksenden, versuchen Sie möglichst wenige Pakete zu verwenden.
    {lineBreak}- Denken Sie daran, dass auch Luft im Paket die Versandkosten erhöht! Wenn möglich und sicher, versuchen Sie ein so kleines Paket wie möglich zu verwenden. So werden auch Ihre Versandkosten reduziert.`,
  'retcart.step2.heading':
    'PAKETGRÖSSE BESTÄTIGEN (AUF ‘PAKET HINZUFÜGEN’ KLICKEN, WENN DU ARTIKEL IN MEHREREN PAKETEN ZURÜCKSENDEST)',
  'retcart.pack.default-title.display': 'VERWENDE DASSELBE PAKET, IN DEM DER ARTIKEL ANKAM:',
  'retcart.pack.custom-title.display': 'EIGENE VERPACKUNG VERWENDEN',
  'retcart.pack.custom-title.edit': 'PAKETGRÖSSE (cm)',
  'retcart.pack.change-size': '(Größe ändern)',
  'retcart.pack.remove': '(Entfernen)',
  'retcart.pack.add': 'PAKET HINZUFÜGEN',
  'retcart.addrmodal.heading1': 'GESPEICHERTE ADRESSE AUSWÄHLEN',
  'retcart.addrmodal.heading2': 'ODER NEUE ADRESSE HINZUFÜGEN',
  'retcart.print.loading.heading': 'Ihre Rücksendung wird bearbeitet...',
  'retcart.print.loading.note':
    'Bitte nicht dieses Fenster schließen, bis Ihre Bestellung bestätigt ist!',
  'landing.email-sent':
    'E-Mail erfolgreich gesendet, bitte überprüfen Sie Ihren Posteingang, um Ihre E-Mail innerhalb der nächsten 15 Minuten zu bestätigen.',
  'general.status.IN_TRANSIT': 'UNTERWEGS',
  'general.status.DELIVERED': 'GELIEFERT',
  'general.status.DELIVERED_POST_OFFICE': 'GELIEFERT',
  'general.status.DELIVERED_TO_PICKUP_POINT': 'AN PICK-UP{lineBreak}POINT GELIEFERT',
  'general.status.DELIVERED_TO_SHOP': 'GELIEFERT AN{lineBreak}SHOP',
  'general.status.PARTIALLY_DELIVERED': 'TEILWEISE GELIEFERT',
  'general.status.PARTIALLY_DELIVERED.short': 'TEILW. GEL.',
  'general.status.DELAYED': 'VERSPÄTET',
  'general.status.RETURNED': 'ZURÜCKGESENDET',
  'general.status.DELETED': 'GELÖSCHT',
  'general.status.RECEIVED': 'ERHALTEN',
  'general.status.PARTIALLY_RECEIVED': 'TEILWEISE ERHALTEN',
  'general.status.PARTIALLY_RECEIVED.short': 'TEILW. ERH.',
  'general.status.CLOSED': 'GESCHLOSSEN',
  'general.status.CREATED': 'FERTIG',
  'general.status.PICKING_QUEUE': 'PICKLISTE',
  'general.status.SHIPPING_QUEUE': 'VERSANDLISTE',
  'general.status.REFUNDED': 'GELD ERSTATTET',
  'general.status.SHIPMENT_PICKUP': 'SENDUNGSABHOLUNG',
  'general.status.SHIPPED': 'VERSCHICKT',
  'general.status.PICKUP': 'VERSCHICKT',
  'general.status.IN_TRANSIT_DELAYED': 'UNTERWEGS MIT VERSPÄTUNG',
  'general.status.EXCEPTION': 'VORFALL',
  'general.status.OUT_FOR_DELIVERY': 'IN ZUSTELLUNG',
  'general.status.RETURNED_TO_ORIGIN': 'ZUM HERKUNFTSORT ZURÜCKGESENDET',
  'general.status.CANCELLED': 'STORNIERT',
  'sidebar.orders-in-transit': 'BESTELLUNGEN UNTERWEGS',
  'sidebar.my-orders': 'MEINE BESTELLUNGEN',
  'sidebar.returns': 'ZURÜCKGESENDET',
  'general.courier.gls_ireland': 'GLS Ireland',
  'general.courier.tnt_italy': 'TNT Italy',
  'general.courier.brt': 'BRT',
  'general.courier.bring': 'Bring',
  'general.courier.budbee': 'Budbee',
  'general.courier.cbl': 'CBL',
  'general.courier.correos': 'Correos Express',
  'general.courier.ctt': 'CTT',
  'general.courier.ctt_portugal': 'CTT Portugal',
  'general.courier.chornopost': 'Chronopost',
  'general.courier.deprecated': 'DEPRECATED',
  'general.courier.deutschepost': 'Deutsche Post',
  'general.courier.deutschepost_germany': 'Deutsche Post',
  'general.courier.dhl': 'DHL',
  'general.courier.dhl_sweden': 'DHL',
  'general.courier.dhlparcel': 'DHL Parcel',
  'general.courier.dhlparcel_portugal': 'DHL Parcel',
  'general.courier.dhlparcel_spain': 'DHL Parcel',
  'general.courier.dhlparcel_poland': 'DHL Parcel',
  'general.courier.dhlparcel_unitedkingdom': 'DHL Parcel',
  'general.courier.dhl_paket_germany': 'DHL Paket',
  'general.courier.directa': 'Directa',
  'general.courier.dpd': 'DPD',
  'general.courier.gordon': 'Gordon',
  'general.courier.boostparcel': 'Boost Parcel',
  'general.courier.ronin': 'Ronin',
  'general.courier.trablisa': 'Trablisa Express',
  'general.courier.dpd_france': 'DPD',
  'general.courier.dpd_germany': 'DPD',
  'general.courier.dpd_portugal': 'DPD',
  'general.courier.dpd_portugal_new': 'DPD',
  'general.courier.dpd_romania': 'DPD',
  'general.courier.dpd_poland': 'DPD',
  'general.courier.envialia': 'Envialia',
  'general.courier.external': 'External Courier',
  'general.courier.external-EXCHANGE': 'Exchange',
  'general.courier.fedex': 'FedEx',
  'general.courier.glovo': 'Glovo',
  'general.courier.gls': 'GLS ALT',
  'general.courier.glsnew': 'GLS',
  'general.courier.gls_italy': 'GLS',
  'general.courier.glsshipit': 'GLS ShipIt',
  'general.courier.hermes': 'Hermes',
  'general.courier.inpost': 'InPost',
  'general.courier.inpost_spain': 'InPost',
  'general.courier.internal': 'Intern',
  'general.courier.matkahuolto': 'Matkahuolto',
  'general.courier.mondialrelay': 'Mondial Relay',
  'general.courier.colissimo': 'Colissimo',
  'general.courier.mrw': 'MRW',
  'general.courier.nacex': 'Nacex',
  'general.courier.omniva': 'Omniva',
  'general.courier.outvio': 'Outvio',
  'general.courier.paack': 'Paack',
  'general.courier.parcelforce': 'Parcelforce',
  'general.courier.posti': 'Posti',
  'general.courier.publiccorreos': 'Correos',
  'general.courier.postnord': 'PostNord',
  'general.courier.postnl': 'PostNL',
  'general.courier.relaiscolis': 'Relais Colis',
  'general.courier.royalmail': 'Royal Mail',
  'general.courier.schenker': 'DB Schenker',
  'general.courier.sending': 'SENDING',
  'general.courier.seur': 'SEUR',
  'general.courier.seur_atlas': 'SEUR Atlas',
  'general.courier.shop': 'To Shop',
  'general.courier.smartpost': 'Smartpost',
  'general.courier.spring': 'Spring GDS',
  'general.courier.tipsa': 'TIPSA',
  'general.courier.tnt': 'TNT',
  'general.courier.transaher': 'Transaher',
  'general.courier.ups': 'UPS',
  'general.courier.venipak': 'Venipak',
  'general.courier.zeleris': 'Zeleris',
  'general.courier.zeleriscustom': 'Zeleris',
  'general.courier.zitycity': 'Zitycity',
  'general.trackingbar.datetime': 'Datum Zeit',
  'general.trackingbar.location': 'Standort',
  'general.trackingbar.status': 'Status',
  'general.trackingbar.detail': 'Info',
  'general.trackingbar.no-info': 'Information nicht verfügbar.',
  'general.return-error.all-returned': 'Alle Waren zurückgesendet.',
  'retcart.heading.methods': 'WIE MÖCHTEN SIE IHRE WAREN ZURÜCKSENDEN?',
  'retcart.method.toClientShop': 'BRINGEN SIE DIE ARTIKEL ZU EINEM UNSERER SHOPS',
  'retcart.method.outvio':
    'NUTZEN SIE UNSEREN PAKETDIENST, UM IHRE RETOURE AN UNS ZURÜCKZUSCHICKEN',
  'retcart.method.ownCourier': 'WARE/N ÜBER EIGENEN PAKETDIENST/ ZUSTELLER ZURÜCKSCHICKEN',
  'retcart.method.exchange': 'UMTAUSCH BZW. ERSATZ DER PRODUKTE, ANSTATT RÜCKGABE',
  'retcart.inst.toClientShop.heading':
    'BESTÄTIGEN SIE IHRE RETOURE UND BRINGEN SIE DIE ARTIKEL IN UNSEREN SHOP',
  'retcart.inst.toClientShop.text1':
    'ZUR BESTÄTIGUNG DER RÜCKSENDUNG unten auf BESTÄTIGEN klicken:',
  'retcart.inst.toClientShop.text2':
    'Bei Bestätigung der Rücksendung wird für Sie ein Retourenschein erstellt und Sie müssen Ihre Rücksendung in unseren Shop bringen spätestens bis {dueDate}.',
  'retcart.inst.toClientShop.text3':
    'Eine Standortliste aller unserer Shops finden Sie hier: {link}.',
  'retcart.inst.toClientShop.text3.link': 'Standortliste der Shops',
  'general.confirm': 'bestätigen',
  'retcart.inst.ownCourier.heading':
    'RETOUREN BESTÄTIGEN UND ARTIKEL ÜBER EIGENEN PAKETDIENST VERSCHICKEN',
  'retcart.inst.ownCourier.text1': 'ZUR BESTÄTIGUNG DER RETOURE unten auf BESTÄTIGEN klicken:',
  'retcart.inst.ownCourier.text2':
    'Bei Bestätigung der Rücksendung wird für Sie ein Retourenschein erstellt, und Sie müssen Ihre Retoure an unseren Shop schicken spätestens bis {dueDate}.',
  'retcart.inst.ownCourier.text3': 'Die Adresse, an die Sie Ihre Retoure schicken müssen, lautet:',
  'retcart.inst.ownCourier.text4':
    'PS: Sie können einen beliebigen Paketdienst Ihre Wahl nutzen, müssen aber die damit verbundenen Versandkosten tragen. Wir empfehlen einen vertrauenswürdigen Paketdienst mit Sendungsverfolgung, damit Ihre Sendung sicher ankommt.',
  'retcart.inst.exchange.heading': 'TEILEN SIE UNS IHRE DETAILS MIT UND BESTÄTIGEN SIE DEN TAUSCH',
  'retcart.inst.exchange.text1':
    'Mit welchem Produkt, in welcher Größe und Menge möchten Sie die momentane(n) Ware(n) tauschen?',
  'retcart.inst.exchange.text2':
    'Achten Sie in Ihrer Nachricht bitte auf alle notwendigen Details.',
  'retcart.inst.exchange.text.placeholder': 'Nennen Sie uns die Details des Tauschs hier.',
  'retcart.inst.exchange.text3': `Um den Tausch abzuschließen, klicken Sie bitte auf BESTÄTIGEN.

Eine E-Mail mit Ihrer Tauschanfrage wird dann an uns gesendet und wir kontaktieren Sie, um die Einzelheiten des Tauschs zu vereinbaren. Eine Kopie der E-Mail wird auch an Ihre E-Mail-Adresse gesendet.`,
  'retcart.confirm-return-terms': 'Ich habe meine Rückgabe überprüft und akzeptiere die {terms}',
  'retcart.confirm-return-terms.terms': 'Allgemeine Geschäftsbedingungen',
  'retcart.confirm.heading': 'RÜCKSENDUNG BESTÄTIGT',
  'retcart.confirm.text3': 'FÜR EINE PROBLEMLOSE RÜCKSENDUNG BITTE:',
  'retcart.confirm.toClientShop.text4':
    '1. Drucken Sie Ihren Retourenschein aus oder speichern Sie eine Kopie davon.',
  'retcart.confirm.toClientShop.text5':
    '2. Bringen Sie Ihre Artikel {fillerShop} in den Shop bis {dueDate}. ',
  'retcart.confirm.toClientShop.text5.fillerShop': 'einer unserer',
  'retcart.confirm.toClientShop.text6': '3. Bringen Sie unbedingt Ihren Retourenschein mit.',
  'retcart.download-return-sheet': 'RETOURENSCHEIN DRUCKEN',
  'retcart.download-label': 'AUFKLEBER UND RÜCKGABEBOGEN HERUNTERLADEN',
  'retcart.confirm.ownCourier.text1':
    '1. Drucken Sie Ihren Retourenschein aus und legen Sie ihn zusammen mit den Artikeln für die Rücksendung ins Paket.',
  'retcart.confirm.ownCourier.text2':
    '2. Schicken Sie die Rücksendung mit einem vertrauenswürdigen Zusteller an die Adresse:',
  'retcart.confirm.ownCourier.text3': '3. Schicken Sie das Paket bitte ab bis {dueDate}.',
  'retcart.confirm.outvio.text1':
    'IHRE RETOURE WURDE BESTÄTIGT, BITTE DRUCKEn SIE DEN PAKETSCHEIN FÜR DIE RETOURE AUS, INDEM SIE AUF DEN BUTTON UNTEN KLICKEN',
  'retcart.confirm.outvio.text2': '(der Paketschein wurde auch an Ihre E-Mail geschickt):',
  'retcart.confirm.outvio.text3':
    'DAMIT BEI ANKUNFT DES ZUSTELLERS ALLES REIBUNGSLOS ABLÄUFT, BITTE:',
  'retcart.confirm.outvio.text4':
    '1. Drucken Sie den Retourenschein und schneiden Sie Ihren Paketschein aus.',
  'retcart.confirm.outvio.text5':
    '2. Legen Sie den Retourenschein zusammen mit den Artikeln für die Retoure ins Paket, schließen Sie das Paket und kleben Sie den Paketschein auf.',
  'retcart.confirm.outvio.text6':
    '3. Warten Sie, bis unser Paketdienst an Ihre Tür klopft und Ihr Paket abholt!',
  'retcart.inst.headline.important': 'WICHTIG:',
  'retcart.inst.hasPickup.noLabel': `1. Überprüfen Sie Ihre Abholadresse und die Produkte in Ihrer Retoure. {lineBreak}
    2. Nachdem Sie "BESTÄTIGEN" angeklickt haben, wird ein Rücksendebogen erstellt. {lineBreak}
    3. Legen Sie die Artikel in die Verpackung und verschließen Sie das Paket. {fillerOptionally} Sie können den Rücksendebogen auch in das Paket einfügen. {lineBreak}
    4. Sobald Sie auf Bestätigen klicken, werden wir den Paketdienst benachrichtigen, der innerhalb der nächsten 2 Werktage Ihre Rücksendung abholen wird. WICHTIG! Sie müssen keinen Versandaufkleber ausdrucken. Dieser wird vom Paketdienst bei der Abholung mitgebracht.`,
  'retcart.inst.hasPickup.noLabel.fillerOptionally': 'Optional',
  'retcart.inst.hasPickup.byEmail': `1. Überprüfen Sie Ihre Artikel für die Rücksendung sowie die Abholadresse für den Zusteller.{lineBreak}
      2. Wenn Sie auf "BESTÄTIGEN" klicken, wird für Sie ein Retourenschein erstellt und wir informieren den Paketdienst, dass das Paket bei Ihnen abgeholt werden kann. {fillerNotice}.{lineBreak}
      3. Legen Sie danach die Artikel für die Retoure zusammen mit dem Retourenschein ins Paket und verschließen Sie dieses sicher. Kleben Sie den vom Paketdienst zugeschickten Paketschein auf das Paket.{lineBreak}
      4. Unser Paketdienst wird Ihr Paket dann innerhalb von 2 Werktagen abholen.`,
  'retcart.inst.hasPickup.byEmail.fillerNotice':
    'Den Versandschein erhalten Sie innerhalb der nächsten 2 Werktage per E-Mail ({email}).',
  'retcart.inst.hasPickup.provided': `1. Überprüfen Sie Ihre Abholadresse und die Produkte in Ihrer Retoure. {lineBreak}
  2. Nachdem Sie  "BESTÄTIGEN" angeklickt haben, wird ein Rücksendebogen erstellt. Bitte drucken Sie ihn aus und schneiden Sie für jedes Paket Ihrer Retoure einen Versandaufkleber ab. {lineBreak}
  3. Legen Sie die Artikel zusammen mit dem Rücksendebogen in die Verpackung, kleben Sie den Versandaufkleber auf und schließen Sie das Paket. {lineBreak}
  4. Der Paketdienst wird Ihre Rücksendung in den nächsten 2 Werktagen abholen.`,
  'retcart.inst.noPickup.byEmail': `1. Überprüfen Sie die Produkte in Ihrer Retoure. {lineBreak}
      2. Wenn Sie auf "BESTÄTIGEN" klicken, wird für Sie ein Retourenschein erstellt. {fillerNotice}{lineBreak}
      3. Legen Sie danach die Artikel für die Retoure zusammen mit dem Retourenschein ins Paket und verschließe dieses sicher. Klebe den vom Zusteller zugeschickten Paketschein auf das Paket.{lineBreak}
      4. Bring das Paket innerhalb von 5 Werktagen zum Abholpunkt des Paketdienstes. Den nächstgelegenen Abholpunkt finden Sie {fillerCourierHomepage}.`,
  'retcart.inst.noPickup.byEmail.fillerNotice':
    ' Der Paketdienst schickt Ihnen einen Paketschein an Ihre E-Mail ({email}) innerhalb der folgenden 2 Werktage.',
  'retcart.inst.noPickup.byEmail.fillerCourierHomepage': 'hier',
  'retcart.inst.noPickup.provided': `1. Überprüfen Sie die Produkte in Ihrer Rücksendung. {lineBreak}
      2. Wenn Sie auf "BESTÄTIGEN" klicken, wird für Sie ein Retourenschein erstellt. Bitte drucken Sie diesen aus und schneiden Sie die Paketscheine (wenn Ihre Retoure aus mehr als 1 Paket besteht, wird für jedes Paket auch ein separater Paketschein generiert).{lineBreak}
      3. Legen Sie danach die Artikel für die Rücksendung zusammen mit dem Retourenschein ins Paket und verschließen Sie dieses sicher. Kleben Sie den Paketschein auf das Paket.{lineBreak}
      4. Bringen Sie das Paket innerhalb von 5 Werktagen zum Abholpunkt des Paketdienstes. Den nächstgelegenen Abholpunkt finden Sie {fillerCourierHomepage}.`,
  'retcart.inst.noPickup.provided.fillerCourierHomepage': 'hier',
  'retcart.confirm.outvio.head-sheet':
    'IHRE RETOURE WURDE BESTÄTIGT, BITTE SPEICHERN SIE IHREN RETOURENSCHEIN, INDEM SIE AUF DEN BUTTON UNTEN KLICKEN',
  'retcart.confirm.outvio.head-sheet.note':
    '(Der Retourenschein wurde ebenfalls an Ihre E-Mail gesendet):',
  'retcart.confirm.outvio.head-label':
    'IHRE RETOURE WURDE BESTÄTIGT, BITTE SPEICHERN SIE IHREN PAKETSCHEIN, INDEM SIE AUF DEN BUTTON UNTEN KLICKEN',
  'retcart.confirm.outvio.head-label.note':
    '(Der Paketschein wurde ebenfalls an Ihre E-Mail gesendet):',
  'retcart.confirm.outvio.hasPickup.noLabel.head':
    'DAMIT BEI ANKUNFT DES PAKETDIENSTES ALLES REIBUNGSLOS ABLÄUFT, BITTE:',
  'retcart.confirm.outvio.hasPickup.noLabel': `1. Drucken Sie den Rückgabebogen aus (optional). {lineBreak}
  2. Fügen Sie den Rücksendebogen Ihrem sicher verschlossenen Paket bei. ES IST KEIN VERSANDAUFKLEBER NÖTIG! {lineBreak}
  3. Der Paketdienst wird Ihre Retoure innerhalb der nächsten 2 Werktage abholen und einen Versandaufkleber mitbringen.`,
  'retcart.confirm.outvio.hasPickup.byEmail.head':
    'PS: DER PAKETSCHEIN WIRD INNERHALB VON 2 WERKTAGEN AN IHRE E-MAIL GESENDET.',
  'retcart.confirm.outvio.hasPickup.byEmail': `1. Drucken Sie den Paketschein nach Erhalt vom Zusteller per E-Mail zusammen mit dem Retourenschein aus.{lineBreak}
      2. Legen Sie den Retourenschein zusammen mit den Artikeln für die Rücksendung ins Paket, schließen Sie das Paket und kleben Sie den Paketschein auf.{lineBreak}
      3. Unser Paketdienst holt das Paket innerhalb der nächsten 2 Werktage ab.`,
  'retcart.confirm.outvio.hasPickup.provided': `1. Drucken Sie den Retourenschein und schneiden den Paketschein aus.{lineBreak}
      2. Legen Sie den Retourenschein zusammen mit den Artikeln für die Retoure ins Paket, schließen Sie das Paket und kleben Sie den Paketschein auf.{lineBreak}
      3. Unser Paketdienst holt das Paket innerhalb der nächsten 2 Werktage ab.`,
  'retcart.confirm.outvio.noPickup.byEmail': `1. Drucken Sie den Paketschein nach Erhalt vom Paketdienst per E-Mail zusammen mit dem Retourenschein aus.{lineBreak}
      2. Legen Sie den Retourenschein zusammen mit den Artikeln für die Retoure ins Paket, schließen Sie das Paket und kleben Sie den Paketschein auf.{lineBreak}
      3. Bringen Sie das Paket innerhalb von 5 Werktagen zum Abholpunkt des Paketdienstes. Den nächstgelegenen Abholpunkt finden Sie {filler}.`,
  'retcart.confirm.outvio.noPickup.byEmail.filler': 'hier',
  'retcart.confirm.outvio.noPickup.provided': `1. Drucken Sie den Retourenschein aus und schneiden den Paketschein aus.{lineBreak}
      2. Legen Sie den Retourenschein zusammen mit den Artikeln für die Rücksendung ins Paket, schließen Sie das Paket und kleben Sie den Paketschein auf.{lineBreak}
      3. Bringen Sie das Paket innerhalb von 5 Werktagen zum Abholpunkt des Zustellers. Den nächstgelegenen Abholpunkt finden Sie {filler}.`,
  'retcart.confirm.outvio.noPickup.provided.filler': 'hier',
  'general.retcart.restart.btn': 'Erneut mit der Retoure beginnen',
  'general.heading.refund.methods': 'WIE MÖCHTEN SIE IHRE RÜCKERSTATTUNG ERHALTEN?',
  'retcart.method.SAME_AS_PAYMENT': 'RÜCKERSTATTUNG ÜBER MEINE URSPRÜNGLICHE ZAHLUNGSMETHODE',
  'retcart.method.CREDIT_IN_STORE': 'GUTHABEN FÜR SPÄTERE EINKÄUFE SPEICHERN',
  'retcart.confirm.exchange.heading': 'TAUSCHANFRAGE ABGESCHLOSSEN',
  'retcart.confirm.exchange.text1': 'IHRE TAUSCHANFRAGE WURDE ERFOLGREICH ÜBERMITTELT.',
  'retcart.confirm.exchange.text2':
    '(Eine Kopie Ihrer Tauschanfrage wurde auch an Ihre E-Mail-Adresse gesendet.)',
  'retcart.confirm.exchange.text3':
    'Wir werden Sie in Kürze kontaktieren, um Ihnen die Details Ihres Tauschs mitzuteilen.',
  'retcart.confirm.exchange.text4': 'VIELEN DANK!',
  'general.cancel': 'stornierung',
  'general.retcart.init-restart':
    'Sollte dieses problem weiterhin bestehen, starten Sie bitte den Prozess neu, indem sie HIER klicken',
  'retcart.return-method.toClientShop': 'IM SHOP ABGEBEN',
  'retcart.return-method.outvio': 'UNSER PAKETDIENST',
  'retcart.return-method.ownCourier': 'EIGENER PAKETDIENST',
  'retcart.return-method.exchange': 'UMTAUSCH / ERSATZ',
  'retcart.return-method.SAME_AS_PAYMENT': 'RÜCKERSTATTUNG',
  'retcart.return-method.CREDIT_IN_STORE': 'GUTSCHRIFT',
  'retcart.return-method.pickup': 'SELBSTABHOLUNG',
  'retcart.return-method.dropoff': 'ZUSTELLUNG',
  'general.heading.pickup.methods':
    'MÖCHTEN SIE IHR PAKET SELBST VERSCHICKEN ODER VOM PAKETDIENST ABHOLEN LASSEN?',
  'retcart.method.pickup': 'ICH MÖCHTE, DASS DER PAKETDIENST DIE RÜCKSENDUNG VON ZUHAUSE ABHOLT',
  'retcart.method.dropoff': 'ICH MÖCHTE DAS PAKET SELBST ZU EINEM SAMMELPUNKT BRINGEN',
  'retcart.img-attach-desc': 'Fotos von Verpackung, Produkt und Versandetikett hochladen',
  'retcart.confirm.outvio.noPickup.noLabel.head':
    'WICHTIG! BEVOR SIE DIE RETOURE AN EINEM RÜCKGABEORT BRINGEN, BITTE:',
  'retcart.title.pickup': 'Abholung',
  'retcart.title.dropoff': 'Zustellung',
  'general.tracking.manage-exception':
    'Aufgetretenen Fehler auf der Website des Paketdienstes behandeln',
  'general.error.id-ver-token-missing':
    'Zur Identifizierung wird ein Anmelde-Token benötigt. Bitte überprüfen Sie die zur Identifizierung gesendete E-Mail.',
  'general.error.id-ver.token-missing':
    'Initialisierungsfehler. Bitte stellen Sie sicher, dass Sie einen Evergreen-Browser verwenden und dass er auf dem neuesten Stand ist.',
  'general.error.fileupload.file-too-large': 'Maximale Dateigröße {size} MB',
  'general.error.fileupload.too-many-files': 'Maximal {number} Datei(en)',
  'general.error.fileupload.file-invalid-type': 'Ungültiger Datei-Typ',
  'general.error.fileupload.incorrect.size.max':
    'Die Bildauflösung darf maximal {width} mal {height} betragen}',
};
