export function OutvioSupport({ ...props }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 32 32" {...props}>
      <g transform="translate(-614 -984)">
        <rect
          width="32"
          height="32"
          rx="10"
          transform="translate(614 1016) rotate(-90)"
          fill="#233841"
        />
        <g transform="translate(-345 869)">
          <g transform="translate(869 103)">
            <path
              d="M17.845,18.923a1.391,1.391,0,0,0,1.625,1.625,1.333,1.333,0,0,0,1.077-1.077,1.391,1.391,0,0,0-1.625-1.625A1.334,1.334,0,0,0,17.845,18.923Z"
              transform="translate(86.813 8.812)"
              fill="#eef3f5"
              stroke="#f6f8f9"
              strokeWidth="0.2"
            />
            <path
              d="M16.569,6.208a5.2,5.2,0,0,1,.548-.039,4.146,4.146,0,0,1,.49.039.328.328,0,0,0,.353-.194L20.232.531A.247.247,0,0,0,20.1.207L20.075.2A6.314,6.314,0,0,0,19.252,0a.242.242,0,0,0-.274.156L17.136,4.547a.041.041,0,0,1-.078,0L15.178.2a.233.233,0,0,0-.294-.137c-.313.078-.607.156-.921.254A.247.247,0,0,0,13.8.625c0,.009.006.017.009.025l2.389,5.4A.393.393,0,0,0,16.569,6.208Z"
              transform="translate(88.893 18)"
              fill="#eef3f5"
              stroke="#f6f8f9"
              strokeWidth="0.2"
            />
            <path
              d="M28.458,16.65a5.2,5.2,0,0,1,.039.548,4.144,4.144,0,0,1-.039.49.328.328,0,0,0,.194.353l5.485,2.272a.247.247,0,0,0,.324-.131.157.157,0,0,0,.009-.025,6.234,6.234,0,0,0,.194-.824.242.242,0,0,0-.156-.274L30.12,17.217a.041.041,0,0,1-.029-.05.041.041,0,0,1,.029-.029l4.348-1.88a.233.233,0,0,0,.137-.294c-.078-.313-.156-.607-.254-.92a.246.246,0,0,0-.308-.165l-.025.009-5.405,2.389A.418.418,0,0,0,28.458,16.65Z"
              transform="translate(81.332 10.85)"
              fill="#eef3f5"
              stroke="#f6f8f9"
              strokeWidth="0.2"
            />
            <path
              d="M17.754,28.455a5.181,5.181,0,0,1-.548.039,4.146,4.146,0,0,1-.49-.039.329.329,0,0,0-.353.194l-2.272,5.487a.247.247,0,0,0,.131.324l.025.009a6.237,6.237,0,0,0,.824.194.242.242,0,0,0,.274-.156l1.841-4.387a.041.041,0,0,1,.05-.029.041.041,0,0,1,.029.029l1.88,4.347a.233.233,0,0,0,.294.137c.313-.078.607-.156.921-.254a.247.247,0,0,0,.166-.308.223.223,0,0,0-.009-.025l-2.39-5.4A.36.36,0,0,0,17.754,28.455Z"
              transform="translate(88.746 3.333)"
              fill="#eef3f5"
              stroke="#f6f8f9"
              strokeWidth="0.2"
            />
            <path
              d="M25.251,7.886c.137.118.294.235.411.353a2.837,2.837,0,0,1,.313.372.335.335,0,0,0,.391.1l5.483-2.252a.242.242,0,0,0,.118-.353c-.137-.254-.274-.49-.431-.727a.27.27,0,0,0-.313-.1l-4.409,1.8a.043.043,0,0,1-.059-.016.043.043,0,0,1,0-.042L28.5,2.613a.279.279,0,0,0-.1-.313,8.8,8.8,0,0,0-.824-.47.265.265,0,0,0-.351.133l0,0L25.152,7.49A.321.321,0,0,0,25.251,7.886Z"
              transform="translate(83.05 17.069)"
              fill="#eef3f5"
              stroke="#f6f8f9"
              strokeWidth="0.2"
            />
            <path
              d="M8.7,26.023c-.137-.118-.294-.235-.411-.353A2.876,2.876,0,0,1,7.98,25.3a.335.335,0,0,0-.391-.1L2.105,27.454a.242.242,0,0,0-.137.314.223.223,0,0,0,.019.039c.137.255.274.49.431.727a.27.27,0,0,0,.313.1l4.409-1.8a.043.043,0,0,1,.059.017.043.043,0,0,1,0,.042L5.455,31.3a.278.278,0,0,0,.1.313,8.7,8.7,0,0,0,.824.47.266.266,0,0,0,.351-.133v0l2.135-5.5A.493.493,0,0,0,8.7,26.023Z"
              transform="translate(94.994 5.021)"
              fill="#eef3f5"
              stroke="#f6f8f9"
              strokeWidth="0.2"
            />
            <path
              d="M26.023,25.221c-.118.137-.235.294-.353.411a2.839,2.839,0,0,1-.372.313.335.335,0,0,0-.1.391l2.252,5.483a.242.242,0,0,0,.314.137.223.223,0,0,0,.039-.019c.254-.137.49-.274.727-.431a.27.27,0,0,0,.1-.313l-1.8-4.408a.043.043,0,0,1,.017-.059.043.043,0,0,1,.042,0L31.3,28.471a.279.279,0,0,0,.313-.1,8.7,8.7,0,0,0,.47-.824.265.265,0,0,0-.133-.351l0,0-5.508-2.133A.5.5,0,0,0,26.023,25.221Z"
              transform="translate(83.022 5.079)"
              fill="#eef3f5"
              stroke="#f6f8f9"
              strokeWidth="0.2"
            />
            <path
              d="M7.885,8.7c.118-.137.235-.294.353-.411a2.876,2.876,0,0,1,.372-.313.334.334,0,0,0,.1-.391L6.455,2.105A.242.242,0,0,0,6.1,1.987c-.254.137-.49.274-.727.431a.27.27,0,0,0-.1.313L7.08,7.14a.043.043,0,0,1-.016.059.043.043,0,0,1-.042,0L2.613,5.455a.279.279,0,0,0-.313.1,8.912,8.912,0,0,0-.47.824.266.266,0,0,0,.133.351h0l5.5,2.134A.441.441,0,0,0,7.885,8.7Z"
              transform="translate(95.069 16.994)"
              fill="#eef3f5"
              stroke="#f6f8f9"
              strokeWidth="0.2"
            />
            <path
              d="M6.209,17.875a5.179,5.179,0,0,1-.039-.548,4.145,4.145,0,0,1,.039-.49.329.329,0,0,0-.194-.353L.531,14.212a.247.247,0,0,0-.324.131.263.263,0,0,0-.009.025A6.312,6.312,0,0,0,0,15.192a.242.242,0,0,0,.156.274l4.387,1.841a.041.041,0,0,1,0,.078L.2,19.266a.233.233,0,0,0-.137.294c.078.313.156.607.254.92a.247.247,0,0,0,.308.166l.025-.009,5.405-2.389A.439.439,0,0,0,6.209,17.875Z"
              transform="translate(96 10.683)"
              fill="#eef3f5"
              stroke="#f6f8f9"
              strokeWidth="0.2"
            />
          </g>
        </g>
      </g>
    </svg>
  );
}
