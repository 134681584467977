import { TAG_COLORS } from 'app-types';

function hashString(string: string): number {
  let hash = 0;
  for (let i = 0; i < string.length; i++) {
    const char = string.charCodeAt(i);
    hash = (hash << 5) - hash + char;
    hash |= 0; // Convert to 32-bit integer
  }
  return Math.abs(hash);
}

export function useRandomColor(srt: string) {
  const color = TAG_COLORS[hashString(srt ?? '') % TAG_COLORS.length];
  switch (color) {
    case 'gray':
      return {
        bg: 'bg-v2blueGray-650',
      };
    case 'orange':
      return {
        bg: 'bg-v2orange',
      };
    case 'blue':
      return {
        bg: 'bg-v2blue',
      };
    case 'red':
      return {
        bg: 'bg-v2red-500',
      };
    case 'cyan':
      return {
        bg: 'bg-v2cyan-500',
      };
    case 'green':
      return {
        bg: 'bg-v2green-500',
      };
    case 'pink':
      return {
        bg: 'bg-v2red-100',
      };
    case 'violet':
    default:
      return {
        bg: 'bg-v2violet-500',
      };
  }
}
