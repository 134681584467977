import { gql } from '@apollo/client';

export const ATTACHMENT_FRAGMENT = gql`
  fragment AttachmentFragment on MessageAttachmentObject {
    key
    originalFilename
    size
    type
    url
  }
`;

export const CHAT_LANGUAGES_FRAGMENT = gql`
  fragment ChatLanguagesFragment on ChatLanguageObject {
    isDefault
    language
  }
`;

export const TEAM_FRAGMENT = gql`
  fragment TeamFragment on TeamObject {
    agents
    icon
    id
    name
  }
`;

export const SIGNATURE_FRAGMENT = gql`
  fragment SignatureFragment on AgentObject {
    id
    emailOnlySignature
    signatures {
      language
      signature
    }
  }
`;

export const TAG_FRAGMENT = gql`
  fragment TagFragment on StoreObject {
    id
    tags
  }
`;

export const FOLDER_FRAGMENT = gql`
  fragment FolderFragment on FolderObject {
    brands
    channels
    icon
    id
    languages
    name
    sortingIndex
    tags
    ticketAssignees
    type
    visibleFor
  }
`;

export const PERSONAL_FOLDER_FRAGMENT = gql`
  fragment PersonalFolderFragment on FolderObject {
    channels
    icon
    id
    languages
    name
    tags
    brands
    sortingIndex
  }
`;

export const STORE_ENTITY_FRAGMENT = gql`
  fragment StoreEntityFragment on StoreObject {
    channels
    languages
    id
    domainType
    senderName
    supportEmail
    tags
    onboardingCompleted
  }
`;

export const TEMPLATE_FRAGMENT = gql`
  fragment TemplateFragment on TemplateObject {
    icon
    id
    body
    bodyJson
    language
    subject
  }
`;
export const TICKET_MESSAGE_FRAGMENT = gql`
  fragment TicketMessageFragment on MessageObject {
    attachments {
      ...AttachmentFragment
    }
    body
    fromEmail
    toEmail
    fromSenderName
    toSenderName
    cc
    bodyJson
    createdBy
    createdAt
    error
    id
    contextData {
      event
      durationSeconds
    }
    status
    ticket
    source
    type
  }
  ${ATTACHMENT_FRAGMENT}
`;

export const TICKET_MESSAGES_FRAGMENT = gql`
  fragment TicketMessagesFragment on MessageObject {
    ...TicketMessageFragment
  }
  ${TICKET_MESSAGE_FRAGMENT}
`;

export const TICKET_FRAGMENT_SHORT = gql`
  fragment TicketFragmentShort on TicketObject {
    id
    assignee
    hasBeenSeenByAgent
    lastRecipientResponseDate
    draft {
      text
      textJson
      attachments {
        ...AttachmentFragment
      }
    }
    channel
    messagePreview
    recipientName
    status
    subject
    supportEmail
    updatedAt
    type
    courierType
    waitingForRecipientResponse
    lastRecipientResponseDate
    rating
  }
  ${ATTACHMENT_FRAGMENT}
`;

export const TICKET_FRAGMENT_SHORT_NEW_TICKET = gql`
  fragment TicketFragmentShort on TicketObject {
    id
    lastRecipientResponseDate
    channel
    messagePreview
    recipientName
    status
    subject
    updatedAt
    type
    waitingForRecipientResponse
    lastRecipientResponseDate
  }
`;

export const PORTAL_DOMAINS_FORM_FLAGS_AND_SLUG_FRAGMENT = gql`
  fragment PortalDomainsFormFlagsAndSlugFragment on BrandObject {
    isReturnCustomDomainEnabled
    isTrackingCustomDomainEnabled
    slug
    id
  }
`;

export const BRAND_SETTINGS_FRAGMENT = gql`
  fragment BrandSettings on BrandObject {
    id
    isReturnCustomDomainEnabled
    isTrackingCustomDomainEnabled
    slug
    trackingSlug
  }
`;

export const BRAND_TRANSLATIONS_PAGE_TITLE_FRAGMENT = gql`
  fragment BrandTranslationsPageTitle on TranslationObject {
    id
    language
    values {
      pageTitle
    }
  }
`;

export const ROUTING_RULES_SCHEMA_FRAGMENT = gql`
  fragment RoutingRulesSchemaFragment on RuleObjectSchema {
    events
    schema
  }
`;

export const ROUTING_RULE_FRAGMENT = gql`
  fragment RoutingRuleFragment on RuleObject {
    assigneeTeams
    assignees
    condition {
      conditions {
        conditions {
          op
          param
          value
        }
        logOp
        op
        param
        value
      }
      logOp
    }
    events
    id
    isActive
    name
    snoozeTimeUnit
    snoozeTimeValue
    tags
    template
    whenDelay
    whenDelayUnit
    ticketStatus
  }
`;

export const TICKET_ORDER_FRAGMENT = gql`
  fragment TicketOrderFragment on TicketOrderObject {
    _id
    courierName
    id
    otn
  }
`;

export const COURIER_CONTACT_FRAGMENT = gql`
  fragment CourierContactFragment on CourierContactObject {
    id
    courierType
    emails {
      domain
      email
    }
    store
  }
`;

export const TICKET_FRAGMENT = gql`
  fragment TicketFragment on TicketObject {
    assignee
    brand
    cc
    channel
    closedAt
    closedBy
    createdBy
    clientStore
    type
    id
    language
    messagePreview
    draft {
      text
      textJson
      attachments {
        ...AttachmentFragment
      }
    }
    order {
      ...TicketOrderFragment
    }
    orders {
      ...TicketOrderFragment
    }
    recipientId
    recipientEmail
    recipientName
    returnId
    status
    supportEmail
    subject
    ticketId
    updatedAt
    tags
    post {
      attachments
      createdAt
      body
    }
  }
  ${TICKET_ORDER_FRAGMENT}
  ${ATTACHMENT_FRAGMENT}
`;

export const EMAIL_DOMAIN_AND_SENDER_FRAGMENT = gql`
  fragment EmailDomainAndSenderFragment on StoreObject {
    domainType
    senderName
  }
`;

export const EMAIL_CONFIGURATION_FRAGMENT = gql`
  fragment EmailConfigurationFragment on EmailConfigurationObject {
    brand
    activeEmail
    customEmail
    displayEmailHeader
    email
    emailVerified
    enableCustomEmail
    senderName
    id
    store
  }
`;

export const MESSAGE_COMMENT_FRAGMENT = gql`
  fragment MessageCommentFragment on MessageCommentObject {
    attachments {
      ...AttachmentFragment
    }
    createdBy
    mentions
    message
    text
    createdAt
    ticket
  }
  ${ATTACHMENT_FRAGMENT}
`;

export const CHAT_TRANSLATIONS_FRAGMENT = gql`
  fragment ChatTranslationsFragment on TranslationValuesObject {
    endAgentEndsChat
    endCancelButton
    endChatButton
    endText
    endTitle
    greeting
    initialMessages
    introduction
    offlineEmail
    offlineMessage
    offlineName
    offlineSendMessage
    offlineText
    offlineTitle
    onlineEmail
    onlineName
    onlineStartButton
    onlineText
    onlineTitle
    secondaryMessages
    thankYouText
    thankYouTitle
    onlineStatus
    offlineStatus
    onlineReplyHere
    findOrderQuestion
    findOrderPostcode
    findOrderOrderId
    findOrderTrack
    findOrderNotFound
    findOrderTryAgain
    findOrderFeedback
    findOrderThanks
    returnQuestion
    returnAnswer
    returnAnswerFeedback
    returnAnswerThanks
    agentQuestion
  }
`;

export const COMMENT_FRAGMENT = gql`
  fragment CommentFragment on CommentObject {
    body
    createdAt
    createdBy
    type
    externalId
    id
    parentId
    senderId
    senderName
    senderPhoto
    attachments {
      ...AttachmentFragment
    }
  }
  ${ATTACHMENT_FRAGMENT}
`;
