export function Catalan({ ...props }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="0.998em"
      viewBox="0 0 640 480"
      {...props}
    >
      <path d="m0 0h640v480h-640z" fill="#fcdd09" />
      <path
        d="m0 90h810m0 120h-810m0 120h810m0 120h-810"
        stroke="#da121a"
        strokeWidth="60"
        transform="scale(.79012 .88889)"
      />
    </svg>
  );
}
