import { useQuery } from '@tanstack/react-query';
import { DAY_CACHE } from 'ui/constants';

import { getUser } from '../../../utils/index';

/**
 * @deprecated useGetUser should not be used
 *
 * Use instead: `import { useUserProfile  } from 'data-layer'
 */
export function useGetUser() {
  return useQuery(['getUser'], getUser, {
    cacheTime: DAY_CACHE,
    staleTime: DAY_CACHE,
  });
}
