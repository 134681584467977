export default function ConnectCMSIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
      <g transform="translate(-150 -689)">
        <rect width="24" height="24" transform="translate(150 689)" fill="none" />
        <path
          d="M7,13.25a1.246,1.246,0,1,0-.885-.365A1.208,1.208,0,0,0,7,13.25Zm5,0a1.246,1.246,0,1,0-.885-.365A1.208,1.208,0,0,0,12,13.25Zm5,0a1.246,1.246,0,1,0-.885-.365A1.208,1.208,0,0,0,17,13.25ZM12,21.5A9.255,9.255,0,0,1,8.3,20.752a9.486,9.486,0,0,1-5.048-5.046,9.547,9.547,0,0,1,0-7.409A9.485,9.485,0,0,1,8.294,3.248a9.546,9.546,0,0,1,7.409,0,9.486,9.486,0,0,1,5.048,5.046,9.547,9.547,0,0,1,0,7.409,9.485,9.485,0,0,1-5.046,5.048A9.245,9.245,0,0,1,12,21.5Z"
          transform="translate(150 689)"
          fill="#3384e1"
        />
      </g>
    </svg>
  );
}
