import { ReactNode, memo } from 'react';

import { ValueLoader } from 'ui/atoms';

export interface DashboardKpiItemProps {
  name?: ReactNode;
  value?: ReactNode;
  rightSide?: ReactNode;
  loading?: boolean;
}

function DashboardKpiItemBase({ name, value, loading }: DashboardKpiItemProps) {
  return (
    <div className="space-y-1">
      <div className="text-v2blueGray-400 text-xs leading-[15px]">
        {name === undefined && loading ? (
          <div className="h-[15px] pt-0.5">
            <ValueLoader width={120} height={12} />
          </div>
        ) : (
          name
        )}
      </div>
      <div className="text-v2blueGray-900 font-medium text-22 leading-[26px] tracking-[-0.704px]">
        {loading ? (
          <div className="h-[26px] pt-1">
            <ValueLoader width={40} height={18} rx={9} />
          </div>
        ) : (
          value
        )}
      </div>
    </div>
  );
}

export const DashboardKpiItem = memo(DashboardKpiItemBase);
