import { useContext, useState } from 'react';

import { FormProvider, useWatch } from 'react-hook-form';

import { SubscriptionPlan } from 'app-types/core';
import { useGetEstimatedSubBill } from 'data-layer';
import { FormattedMessage, useIntl } from 'localization';
import { twMerge } from 'tailwind-merge';

import { Copy, Heading } from '../../atoms';
import LayerWithSideBar from '../../molecules/LayerWithSidebar';
import { useGetSpecificPlanPrice } from '../Pricing/hooks/useGetPlanOptions';
import { PriceStep } from '../PricingPage/hooks/pricingBlockerHooks';
import { SubscriptionContext } from '../PricingPage/hooks/useSubscriptionContext';
import { PricingPlanProps } from '../PricingPage/types/pricingTypes';
import ConfirmationSection from './fragments/ConfirmationSection';
import PaymentMethodForm from './fragments/PaymentMethodForm';
import Plans from './fragments/Plans';
import SupportedCardsIconsHeader from './fragments/SupportedCardsIconsHeader';
import TotalPriceInfoSection from './fragments/TotalPriceInfoSection';
import usePaymentForm from './hooks/usePaymentFormHooks';

type PaymentWrapperProps = {
  onPaymentSuccess: () => void;
  selectedPlan: PricingPlanProps;
  onAddVatInfo: () => void;
  isShopifyBilling: boolean;
  updateSubscriptionInfo: () => Promise<void>;
  step: PriceStep;
  onClose: () => void;
  onChangeSubmit: (isSubmitting: boolean) => void;
  isSubmitting: boolean;
  isOnTrial: boolean;
  deskOnly?: boolean;
};

type PaymentProps = {
  selectedPlan: PricingPlanProps;
  onAddVatInfo: () => void;
  isShopifyBilling: boolean;
  isSubmitting: boolean;
  deskOnly?: boolean;
  errorMessage?: string;
  onShopifySubmit: () => Promise<void>;
  onSubmit: () => Promise<void>;
};

const Payment = ({
  selectedPlan,
  onAddVatInfo,
  isShopifyBilling,
  isSubmitting,
  deskOnly,
  errorMessage,
  onShopifySubmit,
  onSubmit,
}: PaymentProps) => {
  const selectedPlanInForm = useWatch({ name: 'plan' }) as SubscriptionPlan;
  const isDeskIncludedInForm = useWatch({ name: 'desk' }) as boolean;

  const { data: estimatedAmounData, isLoading } = useGetEstimatedSubBill(
    !isShopifyBilling ? selectedPlanInForm : undefined,
  );

  const contextData = useContext(SubscriptionContext);
  const { getPricingData } = useGetSpecificPlanPrice();
  const { locale, formatMessage } = useIntl();

  const { titleKey, descriptionKey } = {
    titleKey: deskOnly ? 'settings.payment.desk-upgrade.title' : 'settings.payment.upgrade-plan',
    descriptionKey: deskOnly
      ? 'settings.payment.desk-upgrade.description'
      : 'settings.payment.upgrade-plan.vat-description',
  };

  const { getPrice, getOrderProcessingFee, deskPrice } = getPricingData();

  const totalPrice = deskOnly ? deskPrice : getPrice(selectedPlanInForm, 'monthly', true);
  const additionalInfo = getOrderProcessingFee(isDeskIncludedInForm ? 'planWithDesk' : 'plan');

  const formattedNextBillingDate = contextData?.subscription?.nextRebill
    ? new Intl.DateTimeFormat(locale, {
        month: 'short',
        day: '2-digit',
        year: 'numeric',
      }).format(new Date(contextData?.subscription.nextRebill))
    : '';

  return (
    <LayerWithSideBar
      className="rounded-[22px]"
      sideBarContent={
        <>
          <div className="px-2 pt-2 mb-8">
            <Heading className="mb-[29px] text-v2blueGray-800">
              <FormattedMessage id={titleKey} />
            </Heading>
            <Copy as="span" className="tracking-[-0.196px]" type="copy7">
              <FormattedMessage id={descriptionKey} />
            </Copy>

            {!deskOnly && (
              <Copy
                as="span"
                onClick={!isSubmitting ? onAddVatInfo : undefined}
                weight="semibold"
                className={twMerge(
                  'mb-[32px] text-v2blue-600 cursor-pointer',
                  isSubmitting && 'cursor-not-allowed',
                )}
                type="copy7"
              >
                {' '}
                <FormattedMessage id="settings.payment.upgrade-plan.vat-add-button" />
              </Copy>
            )}
          </div>

          <Plans
            initialSelectedPlan={selectedPlan.plan}
            isShopifyBilling={isShopifyBilling}
            deskOnly={deskOnly}
            isSubmitting={isSubmitting}
          />
          <TotalPriceInfoSection
            priceTitle={formatMessage({ id: 'settings.payment.plan-price' })}
            additionalInfoTitle={formatMessage({ id: 'settings.payment.order-processing-fee' })}
            price={totalPrice}
            additionalInfo={additionalInfo}
            nextBillingDate={formattedNextBillingDate}
          />

          {isShopifyBilling && (
            <ConfirmationSection
              isShopifyBilling={isShopifyBilling}
              currency={selectedPlan.currency}
              onSubmit={onShopifySubmit}
              errorMessage={errorMessage}
              isSubmitting={isSubmitting}
              type={deskOnly ? 'deskOnly' : 'plan'}
              amount={estimatedAmounData?.amount}
              isLoading={isLoading}
            />
          )}
        </>
      }
    >
      {!isShopifyBilling && (
        <>
          <SupportedCardsIconsHeader />
          <PaymentMethodForm isSubmitting={isSubmitting} />
          <ConfirmationSection
            currency={selectedPlan.currency}
            onSubmit={onSubmit}
            errorMessage={errorMessage}
            isSubmitting={isSubmitting}
            amount={estimatedAmounData?.amount}
            type={deskOnly ? 'deskOnly' : 'plan'}
            isLoading={isLoading}
          />
        </>
      )}
    </LayerWithSideBar>
  );
};

const PaymentFormWrapper = (props: PaymentWrapperProps) => {
  const [deskOnly] = useState(props.step === PriceStep.PaymentDesk);

  const { formMethods, errorMessage, onShopifySubmit, onSubmit } = usePaymentForm({
    ...props,
    deskOnly,
  });

  return (
    <FormProvider {...formMethods}>
      <Payment
        deskOnly={deskOnly}
        errorMessage={errorMessage}
        onShopifySubmit={onShopifySubmit}
        onSubmit={onSubmit}
        {...props}
      />
    </FormProvider>
  );
};

export { PaymentFormWrapper as Payment };
