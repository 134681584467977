import { useEffect } from 'react';

function preventDefault(e: Event): void {
  e.preventDefault();
}

function preventDefaultForScrollKeys(e: KeyboardEvent): boolean | void {
  preventDefault(e);
}

let supportsPassive = false;
try {
  window.addEventListener(
    'test',
    () => null,
    Object.defineProperty({}, 'passive', {
      get: function () {
        supportsPassive = true;
      },
    }),
  );
} catch (e) {
  supportsPassive = false;
}

const wheelOpt = supportsPassive ? { passive: false } : false;
const wheelEvent = 'onwheel' in document.createElement('div') ? 'wheel' : 'mousewheel';

function clickHandler(e: MouseEvent): void {
  e.stopPropagation();
  preventDefault(e);
}

function disableInterface() {
  const root = document.getElementById('root');
  root?.addEventListener('DOMMouseScroll', preventDefault, false);
  root?.addEventListener(wheelEvent, preventDefault, wheelOpt);
  root?.addEventListener('touchmove', preventDefault, wheelOpt);
  root?.addEventListener('keydown', preventDefaultForScrollKeys, false);
  root?.addEventListener('click', clickHandler, true);
}

function enableInterface() {
  const root = document.getElementById('root');
  root?.removeEventListener('DOMMouseScroll', preventDefault, false);
  root?.removeEventListener(wheelEvent, preventDefault);
  root?.removeEventListener('touchmove', preventDefault);
  root?.removeEventListener('keydown', preventDefaultForScrollKeys, false);
  root?.removeEventListener('click', clickHandler, true);
}

export function useDisableInterface() {
  useEffect(() => {
    try {
      if (window.localStorage.getItem('impersonateToken')) {
        const tId = setTimeout(() => {
          const root = document.getElementById('root');
          root?.removeAttribute('inert');
          root?.removeAttribute('aria-hidden');
        }, 1500);

        return () => {
          clearTimeout(tId);
        };
      }
    } catch (e) {
      //
    }
    disableInterface();
    return () => {
      enableInterface();
    };
  }, []);
}
