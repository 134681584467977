import React, { useMemo } from 'react';

import { Theme, makeStyles, useMediaQuery } from '@material-ui/core';
import TransferIcon from '@material-ui/icons/LocalShippingRounded';
import cn from 'classnames';
import { FormattedMessage } from 'localization';

import { TrackingStep } from '../types/Tracking';
import opacityToSolidColor from '../utils/opacityToSolidColor';
import DestinationIcon from './DestinationIcon';
import OriginIcon from './OriginIcon';
import {
  filterDupliacateSteps,
  filterSteps,
  makeSimpleSteps,
  normalizeStatusText,
  sortSteps,
} from './functions';

const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',
    padding: '0px 60px',
    marginBottom: '20px',

    display: 'grid',
    gridTemplateColumns: '80px 1fr 1fr 1fr 80px',
    gridTemplateRows: '50px',

    '& > div': {
      position: 'relative',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },

    [theme.breakpoints.down('xs')]: {
      padding: 0,
      gridTemplateColumns: '50px 1fr 1fr 1fr 50px',
    },
  },
  containerDelpu: {
    gridTemplateColumns: '80px 1fr 1fr 1fr 1fr 80px',
    [theme.breakpoints.down('xs')]: {
      gridTemplateColumns: '50px 1fr 1fr 1fr 1fr 50px',
    },
  },
  containerMobile: {
    marginBottom: 0,

    gridTemplateColumns: '80px 1fr 80px',
  },
  activeEndDot: {
    width: '50px',
    height: '50px',

    borderRadius: '50%',

    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    zIndex: 10,
  },
  dot: {
    width: '20px',
    height: '20px',

    borderRadius: '50%',

    zIndex: 10,
  },
  activeDot: {
    width: '40px',
    height: '40px',

    borderRadius: '50%',
    fontSize: '20px',

    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    zIndex: 10,
  },
  barLeft: {
    position: 'absolute',
    top: 'calc(50% - 5px)',
    left: 0,
    width: '50%',
    height: '10px',

    zIndex: 5,
  },
  barLeftRounded: {
    borderTopLeftRadius: '5px',
    borderBottomLeftRadius: '5px',
  },
  barRight: {
    position: 'absolute',
    top: 'calc(50% - 5px)',
    right: 0,
    width: '50%',
    height: '10px',
    zIndex: 5,
  },
  barRightRounded: {
    borderTopRightRadius: '5px',
    borderBottomRightRadius: '5px',
  },
  pointText: {
    flexDirection: 'column',
    justifyContent: 'flex-start !important',
    textAlign: 'center',
    fontSize: '11px',
    fontWeight: 400,

    '& > p:first-child': {
      margin: 0,
      whiteSpace: 'nowrap',

      fontSize: '13px',
      lineHeight: '15px',
      fontWeight: 700,
    },

    '& > p:nth-child(2)': {
      margin: 0,
      whiteSpace: 'break-spaces',

      fontSize: '11px',
      lineHeight: '13px',
      fontWeight: 400,

      '& > span:first-child': {
        maxWidth: '100px',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        WebkitLineClamp: 2,
        display: '-webkit-box',
        overflowWrap: 'break-word',
        WebkitBoxOrient: 'vertical',
      },
    },
  },
  pointTextFirst: {
    [theme.breakpoints.down('xs')]: {
      textAlign: 'left',
      alignItems: 'flex-start !important',
    },
  },
  pointTextLast: {
    [theme.breakpoints.down('xs')]: {
      textAlign: 'right',
      alignItems: 'flex-end !important',
    },
  },
  mobileStatusContainer: {
    width: '100%',
    marginTop: '4px',
    marginBottom: '20px',
    padding: '0 10px',

    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr',
    gridColumnGap: '4px',

    '& > div': {
      fontSize: '11px',
      lineHeight: '13px',
      fontWeight: 400,

      '& > p': {
        margin: 0,
        marginBottom: '4px',
      },
    },
  },
}));

interface TrackignBarProps {
  origin: string;
  destination: string;
  steps?: TrackingStep[];
  activeColor: string;
  inactiveColor?: string;
  exceptionColor: string;
  fillColor?: string;
  backgroundColor?: string;
  textColorMatchesActive?: boolean;
  creationStepDate?: string | null;
  estimatedDelivery?: string | null;
}

const TrackingBar = ({
  steps = [],
  origin,
  destination,
  creationStepDate = null,
  estimatedDelivery = null,
  activeColor,
  inactiveColor,
  exceptionColor,
  backgroundColor = '#ffffff',
  fillColor,
  textColorMatchesActive = false,
}: TrackignBarProps) => {
  const classes = useStyles();
  const isMobileBP = useMediaQuery((theme: Theme) => theme.breakpoints.down('xs'));

  // 1A = 10%; if not provided, 10% opacity of active color. otherwise use whats provided
  const finalInactiveColor = useMemo(
    () =>
      typeof inactiveColor === 'undefined'
        ? opacityToSolidColor(activeColor, 0.1, backgroundColor)
        : inactiveColor,
    [inactiveColor, activeColor, backgroundColor],
  );

  const filteredSteps = normalizeStatusText(filterDupliacateSteps(filterSteps(sortSteps(steps))));
  const { simpleSteps, hasPickupPointStep, hasDeliveredToShopStep } = makeSimpleSteps(
    filteredSteps,
    origin,
    destination,
    creationStepDate,
    estimatedDelivery,
  );

  const lastActiveStep = simpleSteps.reduce((acc, step, index) => {
    if (step.active) {
      return index;
    }
    return acc;
  }, 0);

  return (
    <>
      <div
        className={cn(classes.container, {
          [classes.containerDelpu]: hasPickupPointStep || hasDeliveredToShopStep,
          [classes.containerMobile]: isMobileBP,
        })}
      >
        <div>
          {simpleSteps[1].active ? (
            <OriginIcon color={activeColor} width={26} />
          ) : (
            <div className={classes.activeEndDot}>
              <OriginIcon color={activeColor} width={26} />
            </div>
          )}
        </div>
        {isMobileBP && (
          <>
            <div>
              {lastActiveStep > 0 && lastActiveStep < simpleSteps.length - 1 ? (
                <div
                  className={classes.activeDot}
                  style={{
                    backgroundColor: simpleSteps[lastActiveStep].hasWarning
                      ? exceptionColor
                      : activeColor,
                    color: fillColor,
                  }}
                >
                  <TransferIcon color="inherit" fontSize="inherit" />
                </div>
              ) : (
                <div
                  className={classes.dot}
                  style={{
                    backgroundColor:
                      lastActiveStep === simpleSteps.length - 1 ? activeColor : finalInactiveColor,
                  }}
                />
              )}
              <div
                className={cn(classes.barLeft, { [classes.barLeftRounded]: true })}
                style={{
                  backgroundColor:
                    (lastActiveStep > 0 &&
                      lastActiveStep < simpleSteps.length - 1 &&
                      simpleSteps[lastActiveStep].active) ||
                    lastActiveStep === simpleSteps.length - 1
                      ? activeColor
                      : finalInactiveColor,
                }}
              />
              <div
                className={cn(classes.barRight, {
                  [classes.barRightRounded]: true,
                })}
                style={{
                  backgroundColor: simpleSteps[simpleSteps.length - 1].active
                    ? activeColor
                    : finalInactiveColor,
                }}
              />
            </div>
          </>
        )}
        {!isMobileBP &&
          simpleSteps.map((simpleStep, index) => {
            // Do not render for first and last step
            if (index === 0 || index === simpleSteps.length - 1) {
              return null;
            }

            return (
              <div key={simpleStep.id}>
                {simpleSteps[index].active && !simpleSteps[index + 1].active ? (
                  <div
                    className={classes.activeDot}
                    style={{
                      backgroundColor: simpleSteps[index].hasWarning ? exceptionColor : activeColor,
                      color: fillColor,
                    }}
                  >
                    <TransferIcon color="inherit" fontSize="inherit" />
                  </div>
                ) : (
                  <div
                    className={classes.dot}
                    style={{
                      backgroundColor: simpleSteps[index].active ? activeColor : finalInactiveColor,
                    }}
                  />
                )}
                <div
                  className={cn(classes.barLeft, { [classes.barLeftRounded]: index === 1 })}
                  style={{
                    backgroundColor: simpleSteps[index].active ? activeColor : finalInactiveColor,
                  }}
                />
                <div
                  className={cn(classes.barRight, {
                    [classes.barRightRounded]:
                      index === simpleSteps.length - 2 && !simpleSteps[index + 1].active,
                  })}
                  style={{
                    backgroundColor: simpleSteps[index + 1].active
                      ? activeColor
                      : finalInactiveColor,
                  }}
                />
              </div>
            );
          })}
        <div>
          {simpleSteps[simpleSteps.length - 1].active ? (
            <div className={classes.activeEndDot} style={{ backgroundColor: activeColor }}>
              <DestinationIcon color={fillColor} size={25} />
            </div>
          ) : (
            <DestinationIcon color={finalInactiveColor} size={25} />
          )}
          {simpleSteps[simpleSteps.length - 1].active && (
            <div className={classes.barLeft} style={{ backgroundColor: activeColor }} />
          )}
        </div>

        {!isMobileBP && (
          <>
            <div
              className={cn(classes.pointText, classes.pointTextFirst)}
              style={{
                color: simpleSteps[0].active
                  ? textColorMatchesActive
                    ? activeColor
                    : fillColor
                  : finalInactiveColor,
              }}
            >
              <FormattedMessage
                id={`general.status.${simpleSteps[0].status}`}
                tagName="p"
                values={{ lineBreak: <br /> }}
              />
              <p>
                <span title={simpleSteps[0].location || ''}>{simpleSteps[0].location}</span>
                &#13;
                {simpleSteps[0].date}
              </p>
            </div>
            {simpleSteps.map((simpleStep, index) => {
              if (index === 0 || index === simpleSteps.length - 1) {
                return null;
              }

              return (
                <div
                  key={simpleStep.id}
                  className={classes.pointText}
                  style={{
                    color: simpleSteps[index].active
                      ? textColorMatchesActive
                        ? activeColor
                        : fillColor
                      : finalInactiveColor,
                  }}
                >
                  <FormattedMessage
                    id={`general.status.${simpleSteps[index].status}`}
                    tagName="p"
                    values={{ lineBreak: <br /> }}
                  />
                  {simpleSteps[index].active && !simpleSteps[index + 1].active && (
                    <p>
                      {simpleSteps[index].location && (
                        <>
                          <span title={simpleSteps[index].location || ''}>
                            {simpleSteps[index].location}
                          </span>
                          &#13;
                        </>
                      )}

                      {simpleSteps[index].date}
                    </p>
                  )}
                </div>
              );
            })}
            <div
              className={cn(classes.pointText, classes.pointTextLast)}
              style={{
                color: simpleSteps[simpleSteps.length - 1].active
                  ? textColorMatchesActive
                    ? activeColor
                    : fillColor
                  : finalInactiveColor,
              }}
            >
              <FormattedMessage
                id={`general.status.${simpleSteps[simpleSteps.length - 1].status}`}
                tagName="p"
                values={{ lineBreak: <br /> }}
              />
              <p>
                <span title={simpleSteps[simpleSteps.length - 1].location || ''}>
                  {simpleSteps[simpleSteps.length - 1].location}
                </span>
                &#13;
                {simpleSteps[simpleSteps.length - 1].date}
              </p>
            </div>
          </>
        )}
      </div>
      {isMobileBP && (
        <div className={classes.mobileStatusContainer}>
          <div
            style={{
              color: simpleSteps[0].active
                ? textColorMatchesActive
                  ? activeColor
                  : fillColor
                : finalInactiveColor,
            }}
          >
            <FormattedMessage
              id={`general.status.${simpleSteps[0].status}`}
              tagName="p"
              values={{ lineBreak: <br /> }}
            />
            <span title={simpleSteps[0].location || ''}>{simpleSteps[0].location}</span>
            &#13;
            <span>{simpleSteps[0].date}</span>
          </div>
          {lastActiveStep !== 0 && lastActiveStep !== simpleSteps.length - 1 ? (
            <div
              style={{
                textAlign: 'center',
                color: simpleSteps[lastActiveStep].active
                  ? textColorMatchesActive
                    ? activeColor
                    : fillColor
                  : finalInactiveColor,
              }}
            >
              <FormattedMessage
                id={`general.status.${simpleSteps[lastActiveStep].status}`}
                tagName="p"
                values={{ lineBreak: <br /> }}
              />

              {simpleSteps[lastActiveStep].location && (
                <>
                  <span title={simpleSteps[lastActiveStep].location || ''}>
                    {simpleSteps[lastActiveStep].location}
                  </span>
                  &#13;
                </>
              )}

              <span>{simpleSteps[lastActiveStep].date}</span>
            </div>
          ) : (
            <div />
          )}
          <div
            style={{
              textAlign: 'right',
              color: simpleSteps[simpleSteps.length - 1].active
                ? textColorMatchesActive
                  ? activeColor
                  : fillColor
                : finalInactiveColor,
            }}
          >
            <FormattedMessage
              id={`general.status.${simpleSteps[simpleSteps.length - 1].status}`}
              tagName="p"
              values={{ lineBreak: <br /> }}
            />
            <span title={simpleSteps[simpleSteps.length - 1].location || ''}>
              {simpleSteps[simpleSteps.length - 1].location}
            </span>
            &#13;
            <span>{simpleSteps[simpleSteps.length - 1].date}</span>
          </div>
        </div>
      )}
    </>
  );
};

export default TrackingBar;
