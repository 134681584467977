import React, { forwardRef } from 'react';

import { ButtonBase, ButtonBaseProps, CircularProgress, makeStyles } from '@material-ui/core';
import cn from 'classnames';

const useStyles = makeStyles((theme) => ({
  base: {
    position: 'relative',
    minWidth: '80px',
    width: 'fit-content',
    minHeight: '25px',

    padding: '5px 15px',
    borderRadius: '12px',
    border: '1px solid transparent',

    fontSize: '11px',
    fontWeight: 600,
    textTransform: 'uppercase',
    lineHeight: '13px',

    transition: 'all 0.15s ease-in-out',

    '&:disabled': {
      backgroundColor: '#b9ced0 !important',
      border: '1px solid #c6dbdd !important',
      color: '#fff !important',
      cursor: 'not-allowed',
    },

    [theme.breakpoints.down('xs')]: {
      minHeight: '35px',
      fontSize: '13px',
    },
  },
  primary: {
    border: '1px solid #4bb4b3',
    color: '#fff',
    backgroundColor: '#4bb4b3',

    '&:hover': {
      borderColor: '#3c908f',
      backgroundColor: '#3c908f',
    },
  },
  primaryInverted: {
    border: '1px solid #4bb4b3',
    color: '#4bb4b3',
    backgroundColor: 'transparent',

    '&:hover': {
      borderColor: '#3c908f',
      color: '#3c908f',
      backgroundColor: 'transparent',
    },

    '&:disabled': {
      backgroundColor: '#fff !important',
      border: '1px solid #c6dbdd !important',
      color: '#c6dbdd !important',
    },
  },
  secondary: {
    border: '1px solid #00363D',
    color: '#fff',
    backgroundColor: '#00363D',

    '&:hover': {
      borderColor: '#006370',
      backgroundColor: '#006370',
    },
  },
  secondaryInverted: {
    border: '1px solid #00363D',
    color: '#00363D',
    backgroundColor: 'transparent',

    '&:hover': {
      borderColor: '#006370',
      color: '#006370',
      backgroundColor: 'transparent',
    },

    '&:disabled': {
      backgroundColor: '#fff !important',
      border: '1px solid #c6dbdd !important',
      color: '#c6dbdd !important',
    },
  },
  danger: {
    border: '1px solid #e53934',
    color: '#fff',
    backgroundColor: '#e53934',

    '&:hover': {
      borderColor: '#b11b1b',
      backgroundColor: '#b11b1b',
    },
  },
  dangerInverted: {
    border: '1px solid #e53934',
    color: '#e53934',
    backgroundColor: 'transparent',

    '&:hover': {
      borderColor: '#b11b1b',
      color: '#b11b1b',
      backgroundColor: 'transparent',
    },

    '&:disabled': {
      backgroundColor: '#fff !important',
      border: '1px solid #c6dbdd !important',
      color: '#c6dbdd !important',
    },
  },
  hasIcon: {
    padding: '5px 15px 5px 37px',
  },
  hasIconOnRight: {
    padding: '5px 37px 5px 15px',
  },
  fullWidth: {
    width: '100%',
  },
  iconHolder: {
    position: 'absolute',
    top: 0,
    left: '15px',
    bottom: 0,
    width: '17px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '17px',
  },
  iconHolderRight: {
    left: 'unset',
    right: '15px',
  },
}));

interface IOVButton extends ButtonBaseProps {
  children: React.ReactNode;
  showLoader?: boolean;
  icon?: React.ReactNode | null;
  iconOnRight?: boolean;
  theme?: 'primary' | 'secondary' | 'danger';
  inverted?: boolean;
  fullWidth?: boolean;
  disabled?: boolean;
}

const OVButton = forwardRef<HTMLButtonElement, IOVButton>(
  (
    {
      children,
      showLoader = false,
      icon = null,
      theme = 'primary',
      fullWidth = false,
      inverted = false,
      iconOnRight = false,
      disabled = false,
      ...restProps
    },
    ref,
  ) => {
    const classes = useStyles();
    return (
      <ButtonBase
        classes={{
          root: cn(classes.base, {
            [classes.primary]: theme === 'primary',
            [classes.primaryInverted]: theme === 'primary' && inverted,
            [classes.secondary]: theme === 'secondary',
            [classes.secondaryInverted]: theme === 'secondary' && inverted,
            [classes.danger]: theme === 'danger',
            [classes.dangerInverted]: theme === 'danger' && inverted,
            [classes.hasIcon]: (showLoader || icon !== null) && !iconOnRight,
            [classes.hasIconOnRight]: (showLoader || icon !== null) && iconOnRight,
            [classes.fullWidth]: fullWidth,
          }),
        }}
        ref={ref}
        disabled={disabled}
        {...restProps}
      >
        {showLoader && (
          <div className={cn(classes.iconHolder, { [classes.iconHolderRight]: iconOnRight })}>
            <CircularProgress color="inherit" size={15} />
          </div>
        )}
        {!showLoader && icon !== null && (
          <div className={cn(classes.iconHolder, { [classes.iconHolderRight]: iconOnRight })}>
            {icon}
          </div>
        )}
        {children}
      </ButtonBase>
    );
  },
);

export default OVButton;
