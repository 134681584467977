import React from 'react';

const OVCOutvioLogoBig = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 185.154 41.287">
      <g>
        <path
          d="M72.93 30.486a10.692 10.692 0 004.285-4.083 11.572 11.572 0 001.536-5.9 11.319 11.319 0 00-1.536-5.861A10.6 10.6 0 0072.93 10.6a13.869 13.869 0 00-12.369 0 10.6 10.6 0 00-4.285 4.042 11.424 11.424 0 00-1.536 5.861 11.572 11.572 0 001.536 5.9 10.692 10.692 0 004.285 4.083 12.888 12.888 0 006.144 1.455 13.1 13.1 0 006.225-1.455zm-12.89-9.943a6.9 6.9 0 011.859-5.053 6.335 6.335 0 014.81-1.9 6.55 6.55 0 014.81 1.9 7.669 7.669 0 010 10.106 6.4 6.4 0 01-4.81 1.9 6.55 6.55 0 01-4.81-1.9 7.138 7.138 0 01-1.859-5.053z"
          fill="#00363d"
        />
        <path
          d="M152.117 9.467h-2.789a1.277 1.277 0 00-1.294 1.294v19.686a1.277 1.277 0 001.294 1.294h2.789a1.277 1.277 0 001.294-1.294V10.761a1.277 1.277 0 00-1.294-1.294z"
          fill="#00363d"
        />
        <path
          d="M174.026 30.486a10.482 10.482 0 004.2-4.083 11.572 11.572 0 001.536-5.9 11.319 11.319 0 00-1.536-5.861 10.644 10.644 0 00-4.2-4.042 12.726 12.726 0 00-6.107-1.457 12.454 12.454 0 00-6.063 1.455 10.386 10.386 0 00-4.2 4.042 11.424 11.424 0 00-1.536 5.861 11.187 11.187 0 001.536 5.9 10.744 10.744 0 004.2 4.083 13.444 13.444 0 0012.167 0zm-12.652-9.943a6.9 6.9 0 011.859-5.053 6.156 6.156 0 014.729-1.9 6.429 6.429 0 014.729 1.9 7.795 7.795 0 010 10.106 6.276 6.276 0 01-4.729 1.9 6.429 6.429 0 01-4.729-1.9 6.965 6.965 0 01-1.859-5.053z"
          fill="#00363d"
        />
        <path
          d="M144.154 9.467h-2.91a1.021 1.021 0 00-.93.606l-6.953 15.684a.115.115 0 01-.2 0l-6.751-15.643a1.021 1.021 0 00-.93-.606h-11.118a.107.107 0 01-.121-.121V6.194a1 1 0 00-1.011-1.011h-3.315a1 1 0 00-1.011 1.011v3.188a.107.107 0 01-.121.121h-9.3a1 1 0 00-1.011 1.011v10.227a6.738 6.738 0 01-1.7 4.972 6.062 6.062 0 01-4.568 1.738 5.122 5.122 0 01-3.961-1.5 6.284 6.284 0 01-1.415-4.487V10.482a1 1 0 00-1.011-1.011h-3.315a1 1 0 00-1.003 1.011v11.682c0 3.234.889 5.7 2.627 7.357a10.138 10.138 0 007.236 2.461 10.917 10.917 0 004.163-.808 8.509 8.509 0 003.032-2.1.113.113 0 01.2.081v1.536a1 1 0 001.011 1.011h3.031a1 1 0 001.011-1.011V15.082a1 1 0 011.011-1.011h3.961a.107.107 0 01.121.121v10.954a7.173 7.173 0 002.062 5.578 8.152 8.152 0 005.821 1.9 11.169 11.169 0 002.87-.364 7.216 7.216 0 001.5-.606 1.1 1.1 0 00.485-1.294l-.889-2.142a.737.737 0 00-.687-.4.761.761 0 00-.323.081 5.4 5.4 0 01-2.345.485 3.147 3.147 0 01-2.344-.849 3.382 3.382 0 01-.849-2.466V14.075h7.721a1.021 1.021 0 01.93.606l7.276 16.452a1.021 1.021 0 00.93.606h4.2a1.021 1.021 0 00.93-.606l8.933-20.211a1.081 1.081 0 00-.97-1.455z"
          fill="#00363d"
        />
        <path
          d="M17.874 20.098a2.871 2.871 0 003.355 3.355 2.753 2.753 0 002.223-2.223 2.871 2.871 0 00-3.355-3.355 2.753 2.753 0 00-2.223 2.223z"
          fill="#4bb4b3"
        />
        <path
          d="M19.532 12.819a10.649 10.649 0 011.132-.081 8.507 8.507 0 011.011.081.678.678 0 00.728-.4l4.689-11.32a.51.51 0 00-.323-.687 12.923 12.923 0 00-1.7-.4.5.5 0 00-.566.323l-3.8 9.055a.084.084 0 01-.162 0L16.66.416a.481.481 0 00-.606-.283 33.6 33.6 0 00-1.9.525.51.51 0 00-.323.687l4.932 11.157a.81.81 0 00.769.317z"
          fill="#4bb4b3"
        />
        <path
          d="M28.465 19.613a10.652 10.652 0 01.081 1.132 8.51 8.51 0 01-.081 1.011.678.678 0 00.4.728l11.322 4.689a.51.51 0 00.687-.323 12.924 12.924 0 00.4-1.7.5.5 0 00-.323-.566l-9.055-3.8a.084.084 0 010-.162l8.974-3.881a.481.481 0 00.283-.606 33.6 33.6 0 00-.525-1.9.51.51 0 00-.687-.323l-11.157 4.932a.861.861 0 00-.319.769z"
          fill="#4bb4b3"
        />
        <path
          d="M21.675 28.465a10.657 10.657 0 01-1.132.081 8.513 8.513 0 01-1.011-.081.678.678 0 00-.728.4l-4.689 11.327a.51.51 0 00.323.687 12.923 12.923 0 001.7.4.5.5 0 00.566-.323l3.8-9.055a.084.084 0 01.162 0l3.881 8.974a.481.481 0 00.606.283 33.6 33.6 0 001.9-.525.51.51 0 00.323-.687l-4.934-11.154a.743.743 0 00-.767-.327z"
          fill="#4bb4b3"
        />
        <path
          d="M25.393 14.358c.283.243.606.485.849.728a5.912 5.912 0 01.647.768.691.691 0 00.808.2l11.318-4.649a.5.5 0 00.243-.728 19.216 19.216 0 00-.889-1.5.557.557 0 00-.647-.2l-9.1 3.719a.089.089 0 01-.121-.121l3.6-9.1a.575.575 0 00-.2-.647 18.158 18.158 0 00-1.7-.97.548.548 0 00-.728.283l-4.285 11.4a.662.662 0 00.205.817z"
          fill="#4bb4b3"
        />
        <path
          d="M15.894 26.929c-.283-.243-.606-.485-.849-.728a5.91 5.91 0 01-.647-.768.691.691 0 00-.808-.2L2.272 29.882a.5.5 0 00-.243.728c.283.526.566 1.011.889 1.5a.557.557 0 00.647.2l9.1-3.719a.089.089 0 01.121.121l-3.6 9.1a.575.575 0 00.2.647 18.168 18.168 0 001.7.97.548.548 0 00.728-.283l4.406-11.359a1.019 1.019 0 00-.326-.858z"
          fill="#4bb4b3"
        />
        <path
          d="M26.929 25.393c-.243.283-.485.606-.728.849a5.911 5.911 0 01-.768.647.691.691 0 00-.2.808l4.649 11.318a.5.5 0 00.728.243 19.216 19.216 0 001.5-.889.557.557 0 00.2-.647l-3.719-9.1a.089.089 0 01.121-.121l9.1 3.6a.575.575 0 00.647-.2 18.163 18.163 0 00.97-1.7.548.548 0 00-.283-.728L27.777 25.07a1.019 1.019 0 00-.848.323z"
          fill="#4bb4b3"
        />
        <path
          d="M14.357 15.894c.243-.283.485-.606.728-.849a5.91 5.91 0 01.768-.647.691.691 0 00.2-.808L11.404 2.272a.5.5 0 00-.728-.243c-.525.283-1.011.566-1.5.889a.557.557 0 00-.2.647l3.719 9.1a.089.089 0 01-.121.121l-9.1-3.6a.575.575 0 00-.647.2 18.161 18.161 0 00-.97 1.7.548.548 0 00.283.728l11.359 4.406a.913.913 0 00.858-.326z"
          fill="#4bb4b3"
        />
        <path
          d="M12.819 21.796a10.65 10.65 0 01-.081-1.132 8.508 8.508 0 01.081-1.011.678.678 0 00-.4-.728l-11.32-4.689a.51.51 0 00-.687.323 12.921 12.921 0 00-.4 1.7.5.5 0 00.323.566l9.055 3.8a.084.084 0 010 .162L.416 24.668a.481.481 0 00-.283.606 33.6 33.6 0 00.525 1.9.51.51 0 00.687.323l11.157-4.932a.906.906 0 00.317-.769z"
          fill="#4bb4b3"
        />
        <path
          d="M180.049 28.87a2.576 2.576 0 102.021-2.021 2.494 2.494 0 00-2.021 2.021z"
          fill="#4bb4b3"
        />
      </g>
    </svg>
  );
};

export default OVCOutvioLogoBig;
