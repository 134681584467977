export function Add({ ...props }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="currentColor"
      {...props}
    >
      <g transform="translate(-785 -395)">
        <g transform="translate(787 397)">
          <path
            d="M10,15.625a.639.639,0,0,1-.466-.2.632.632,0,0,1-.2-.46v-4.3h-4.3a.632.632,0,0,1-.46-.2.642.642,0,0,1,0-.932.632.632,0,0,1,.46-.2h4.3v-4.3a.632.632,0,0,1,.2-.46.642.642,0,0,1,.932,0,.632.632,0,0,1,.2.46v4.3h4.3a.632.632,0,0,1,.46.2.642.642,0,0,1,0,.932.632.632,0,0,1-.46.2h-4.3v4.3a.632.632,0,0,1-.2.46A.64.64,0,0,1,10,15.625Z"
            fill="currentColor"
          />
        </g>
      </g>
    </svg>
  );
}
