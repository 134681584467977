import React, { InputHTMLAttributes } from 'react';

import { Theme, makeStyles } from '@material-ui/core';
import { twMerge } from 'tailwind-merge';
import { getColorContrast } from 'ui';
import { TextInput } from 'ui/atoms';

import { useGetPaletteColors } from '../hooks';
import { getCalculatedBrightnessFromHex } from '../utils';

export interface IOVCInput extends InputHTMLAttributes<HTMLInputElement> {
  error?: boolean;
  useSecondaryBg?: boolean;
  inputClassName?: string;
  centeredPlaceholderText?: boolean;
}

type StyleProps = {
  inputBgL1: string;
  inputBgL2: string;
  inputBgL3: string;
  inputTextColor: string;
  centeredPlaceholderText: boolean;
};

const useStyles = makeStyles<
  Theme,
  {
    inputBgL1: string;
    inputBgL2: string;
    inputBgL3: string;
    inputTextColor: string;
    centeredPlaceholderText: boolean;
  }
>({
  inputBackground: {
    backgroundColor: (props: StyleProps) => `${props.inputBgL1} !important`,
    color: (props: StyleProps) => `${props.inputTextColor} !important`,
    '&:hover': {
      backgroundColor: (props: StyleProps) => `${props.inputBgL2} !important`,
      borderWidth: '0px',
    },
    '&:focus': {
      backgroundColor: (props: StyleProps) => `${props.inputBgL1} !important`,
      outlineWidth: '1px',
      outlineOffset: '0px',
      borderWidth: '0px',
      outlineColor: (props: StyleProps) => `${props.inputBgL2} !important`,
    },
    '&::placeholder': {
      color: (props: StyleProps) => `${props.inputBgL3} !important`,
      textAlign: (props: StyleProps) => (props.centeredPlaceholderText ? 'center' : undefined),
    },
    '&:disabled': {
      color: (props: StyleProps) => `${props.inputBgL3} !important`,
    },
  },
});

const OVCInput = ({
  error = false,
  className = '',
  inputClassName = '',
  centeredPlaceholderText = false,
  ...rest
}: IOVCInput) => {
  const { secondaryBg, primaryText } = useGetPaletteColors();

  const textColor = getColorContrast(secondaryBg, true);
  const inputBgL1 = getCalculatedBrightnessFromHex(secondaryBg, 1);
  const inputBgL2 = getCalculatedBrightnessFromHex(secondaryBg, 2);
  const inputBgL3 = getCalculatedBrightnessFromHex(secondaryBg, 3);

  const classes = useStyles({
    inputBgL1,
    inputBgL2,
    inputBgL3,
    inputTextColor: primaryText,
    centeredPlaceholderText,
  });

  return (
    <TextInput
      isError={error}
      className={twMerge(className)}
      inputClassName={twMerge(
        'transition-all',

        classes.inputBackground,
        textColor,
        inputClassName,
      )}
      backgroundStyle={{ borderWidth: '0px' }}
      id={rest?.name}
      name={rest?.name || ''}
      {...rest}
    />
  );
};

export default OVCInput;
