export default {
  'orders.package': 'Package',
  'orders.returns.reasons.size': 'Size does not fit',
  'orders.returns.reasons.wrong-product': 'Wrong product delivered',
  'orders.returns.reasons.wrong-size': 'Wrong size delivered',
  'orders.returns.reasons.wrong-colour': 'Wrong colour',
  'orders.returns.reasons.package-broken': 'Product packaging broken',
  'orders.returns.reasons.product-broken': 'Product broken/faulty',
  'orders.returns.reasons.product-used': 'Product used',
  'orders.returns.reasons.product-dirty': 'Product dirty',
  'orders.returns.reasons.do-not-want': 'I do not want it anymore',
  'orders.returns.reasons.exchange': 'I would like to exchange the product',
  'orders.returns.reasons.other': 'Other',
  'orders.returns.pickup.form.comments': 'Comments',
  'orders.list.empty': 'Currently you have no orders',
  'orders.total': 'Total',
  'orders.vat': 'VAT',
  'or.confirm-address': 'CONFIRM YOUR PICKUP ADDRESS',
  'or.from': 'From',
  'or.to': 'Until',
  'or.return.list.empty': 'Currently you have no returns',
  'or.confirm-return': 'CONFIRM YOUR RETURN',
};
