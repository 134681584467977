import { ReactNode } from 'react';

import { SubscriptionPlan } from 'app-types/core';

import { Heading } from '../../atoms';
import { PricingPlanProps } from '../PricingPage/types/pricingTypes';
import { CancellationConfirmWindow } from '../SubCancelReminder/CancellationConfirmWindow';
import ConquerBlock from './fragments/ConquerBlock';
import DeskBlock from './fragments/DeskBlock';
import GrowBlock from './fragments/GrowBlock';
import { useSelectOrCancelPlan } from './hooks/pricingHooks';

type PricingProps = {
  title: ReactNode;
  onChangePlan: (selectedPlan: PricingPlanProps) => void;
  activePricingPlan: PricingPlanProps;
  updateSubscriptionInfo: () => Promise<void>;
  isCancelingSubscription: boolean;
  isTrial: boolean;
  isShopifyBilling: boolean;
  paymentInPendingProcess: boolean;
  onChangeDesk: (plan: PricingPlanProps) => void;
};

const Pricing = ({
  title,
  onChangePlan,
  activePricingPlan,
  updateSubscriptionInfo,
  isCancelingSubscription,
  isTrial,
  isShopifyBilling,
  paymentInPendingProcess,
  onChangeDesk,
}: PricingProps) => {
  const {
    cancellationType,
    handleSelectPlan,
    onCancelPlanChanging,
    onChangeConfirmationWindowState,
    showConfirmation,
  } = useSelectOrCancelPlan({
    activePricingPlan,
    onChangePlan,
    isCancelingSubscription,
    updateSubscriptionInfo,
  });

  const isConquerSelected = activePricingPlan.plan === SubscriptionPlan.Conquer;
  const isGrowSelected = activePricingPlan.plan === SubscriptionPlan.Grow;

  const inChangingPlanProcess =
    !!activePricingPlan.nextPlan && activePricingPlan.plan !== activePricingPlan.nextPlan;

  return (
    <div className="text-v2blueGray-700">
      <Heading className="ml-4 text-v2blueGray-800">{title}</Heading>

      <div className="grid md:grid-cols-[2fr_1fr] gap-6 mt-8 w-full">
        <div className="grid md:grid-cols-2 gap-6 md:gap-0 relative">
          <GrowBlock
            nextBillingDate={activePricingPlan.nextBillingDate}
            inChangingPlanProcess={inChangingPlanProcess}
            isActive={!!activePricingPlan.isActive && isGrowSelected}
            onSelectPlan={handleSelectPlan}
            onCancelPlanChanging={onCancelPlanChanging}
            isCancelingSubscription={isCancelingSubscription}
            isTrial={isTrial}
            shopifyBilling={isShopifyBilling}
            isConquerActive={!!activePricingPlan.isActive && isConquerSelected}
            paymentInPendingProcess={paymentInPendingProcess}
          />

          <div className="hidden md:block bg-v2blueGray-100 h-[calc(100%-48px)] start-1/2 top-[24px] left-0 absolute w-px -translate-x-1/2" />

          <ConquerBlock
            nextBillingDate={activePricingPlan.nextBillingDate}
            inChangingPlanProcess={inChangingPlanProcess}
            isActive={!!activePricingPlan.isActive && isConquerSelected}
            onSelectPlan={handleSelectPlan}
            onCancelPlanChanging={onCancelPlanChanging}
            isCancelingSubscription={isCancelingSubscription}
            isTrial={isTrial}
            shopifyBilling={isShopifyBilling}
            paymentInPendingProcess={paymentInPendingProcess}
          />
        </div>

        <DeskBlock
          activePricingPlan={activePricingPlan}
          onChangeDeskIncluded={onChangeDesk}
          paymentInPendingProcess={paymentInPendingProcess}
          isTrial={isTrial}
        />

        <CancellationConfirmWindow
          cancellationType={cancellationType}
          isOpen={showConfirmation}
          onClose={() => onChangeConfirmationWindowState(false)}
        />
      </div>
    </div>
  );
};

export { Pricing };
