import { useEffect, useState } from 'react';

import { TicketChannels } from 'app-types/desk';
import { useGetUser, useTicketHasBeenSeen } from 'data-layer';

const isNotSeenYet = (d1?: string | null, d2?: string | null) => {
  if (d1 === undefined || d1 === null) {
    return true;
  }

  if (d2 === undefined || d2 === null) {
    return false;
  }

  const date1 = new Date(d1).getTime();
  const date2 = new Date(d2).getTime();
  return date1 < date2;
};

type IsTicketUnreadProps = {
  id: string;
  channel?: TicketChannels;
  isOpen: boolean;
  assignee?: string | null;
  hasBeenSeenByAgent?: string | null;
  lastRecipientResponseDate?: string | null;
};

export const useIsTicketUnread = ({
  id,
  channel,
  isOpen,
  assignee,
  hasBeenSeenByAgent,
  lastRecipientResponseDate,
}: IsTicketUnreadProps) => {
  const [isTicketUnread, setIsTicketUnread] = useState<boolean>(false);
  const { updateTicketHasBeenSeen } = useTicketHasBeenSeen();
  const { data: userData } = useGetUser();

  useEffect(() => {
    if (
      channel !== TicketChannels.Chat ||
      userData === undefined ||
      (assignee !== null && assignee !== userData._id)
    ) {
      return;
    }

    setIsTicketUnread(isNotSeenYet(hasBeenSeenByAgent, lastRecipientResponseDate));
  }, [hasBeenSeenByAgent, lastRecipientResponseDate, userData, assignee]);

  useEffect(() => {
    if (
      !isOpen ||
      !isTicketUnread ||
      userData === undefined ||
      (assignee !== null && assignee !== userData._id)
    ) {
      return;
    }

    (async () => {
      try {
        updateTicketHasBeenSeen(id);
      } catch (error: any) {
        console.log(error?.message ?? error.toString());
      }
    })();
  }, [isOpen, isTicketUnread, userData, assignee]);

  return isTicketUnread;
};
