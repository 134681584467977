// @ts-nocheck
const baseStyleDoc = [
  {
    featureType: 'all',
    elementType: 'geometry',
    stylers: [
      {
        color: 'REPLACE_MAP_BASE',
      },
    ],
  },
  {
    featureType: 'all',
    elementType: 'labels.text.fill',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
  {
    featureType: 'all',
    elementType: 'labels.text.stroke',
    stylers: [
      {
        color: 'REPLACE_MAP_TEXT',
      },
    ],
  },
  {
    featureType: 'landscape',
    elementType: 'geometry',
    stylers: [
      {
        lightness: 30,
      },
      {
        saturation: 30,
      },
    ],
  },
  {
    featureType: 'poi',
    elementType: 'geometry',
    stylers: [
      {
        saturation: 20,
      },
    ],
  },
  {
    featureType: 'road',
    elementType: 'geometry',
    stylers: [
      {
        lightness: -10,
      },
    ],
  },
  {
    featureType: 'water',
    elementType: 'all',
    stylers: [
      {
        lightness: -20,
      },
    ],
  },
];

const generateMapStyleParts = (baseColor: string, textColor: string): string[] => {
  const arrayOfParts: string[] = baseStyleDoc.map((doc: any) => {
    const docParts: string[] = [];

    Object.keys(doc).forEach((key) => {
      if (key === 'featureType') {
        docParts.push(`feature:${doc[key]}`);
      } else if (key === 'elementType') {
        docParts.push(`element:${doc[key]}`);
      } else if (key === 'stylers') {
        doc[key].forEach((ruleEntry) => {
          const ruleKey = Object.keys(ruleEntry)[0];

          if (ruleKey === 'color' && ruleEntry.color === 'REPLACE_MAP_BASE') {
            docParts.push(`${ruleKey}:0x${baseColor.substring(1)}FF`);
          } else if (ruleKey === 'color' && ruleEntry.color === 'REPLACE_MAP_TEXT') {
            docParts.push(`${ruleKey}:0x${textColor.substring(1)}FF`);
          } else {
            docParts.push(`${ruleKey}:${ruleEntry[ruleKey]}`);
          }
        });
      }
    });

    return docParts.join('|');
  });

  return arrayOfParts;
};

export default generateMapStyleParts;
