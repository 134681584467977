import { HTMLAttributes, ReactNode, memo, useMemo } from 'react';

import { twMerge } from 'tailwind-merge';

export interface TicketProps extends HTMLAttributes<HTMLDivElement> {
  iconType?: 'image' | 'name';
  sender?: 'client' | 'agent';
  children?: ReactNode;
  type?: string;
  className?: string;
  name: string;
  date: string;
}
export function Ticket({
  children,
  name,
  date,
  iconType = 'name',
  type,
  className,
  sender = 'client',
  ...rest
}: TicketProps) {
  const initials = useMemo<string>(() => {
    if (!name) return '';
    const [firstName, lastName] = name.split?.(' ') || ['', ''];
    return `${firstName?.[0] ?? ''}${lastName?.[0] ?? ''}`;
  }, [name]);
  const textColor = useMemo<string>(() => {
    let textColor = 'text-v2blueGray-800';
    className?.split?.(' ')?.forEach?.((c) => {
      if (c.startsWith('text-')) textColor = c;
    });
    return textColor;
  }, [className]);

  return (
    <div
      {...rest}
      className={twMerge('font-inter pl-[72px] py-9 pr-6 relative', className)}
      data-testid="outvioui--ticket"
    >
      <div
        className={twMerge(
          'rounded-full text-xs leading-6 tracking-tight text-white font-semibold w-8 h-8 absolute flex items-center justify-center top-9 left-6',
          sender === 'client' ? 'bg-primary' : 'bg-v2blue-500',
        )}
      >
        {iconType && initials}
      </div>
      <div className="text-sm leading-[22px] tracking-tight text-left font-inter text-v2blueGray-800 font-semibold">
        <span data-testid="outvioui--ticket--name">{`${name || '-'}`}</span>
        {type && (
          <span className={twMerge('text-v2blueGray-500 font-semibold ml-2', textColor)}>
            {type}
          </span>
        )}
      </div>
      <div className="font-inter text-sm mb-6 text-v2blueGray-500 whitespace-nowrap tracking-tight">
        {date || '-'}
      </div>
      <div className="font-inter text-sm text-v2blueGray-700 max-w-full whitespace-pre-wrap">
        {children}
      </div>
    </div>
  );
}

export default memo<TicketProps>(Ticket);
