import { twMerge } from 'tailwind-merge';

export default function CloseIconTag(props: { className?: string }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 18 18"
      className={twMerge(`transition-all duration-200 `, props.className)}
    >
      <g>
        <path d="M0,0H18V18H0Z" fill="none" />
        <path
          d="M15.077,5.634a.747.747,0,0,0-1.057,0l-3.667,3.66L6.684,5.627A.748.748,0,0,0,5.627,6.684l3.667,3.668L5.627,14.019a.748.748,0,1,0,1.057,1.057l3.668-3.667,3.667,3.667a.748.748,0,1,0,1.057-1.057l-3.667-3.667,3.667-3.667A.752.752,0,0,0,15.077,5.634Z"
          transform="translate(-1.352 -1.352)"
          fill="currentColor"
        />
      </g>
    </svg>
  );
}
