export function DeskIcon({ ...props }) {
  return (
    <svg
      width="1em"
      height="1em"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      fill="currentColor"
      {...props}
    >
      <path
        d="M14410,8252h0l-4-4h-10a2,2,0,0,1-2-2v-1h12a1,1,0,0,0,1-1v-8h1a2,2,0,0,1,2,2v14Zm-20-5v-13a2,2,0,0,1,2-2h11a2,2,0,0,1,2,2v7a2,2,0,0,1-2,2h-9l-4,4Zm8-7.522a1.046,1.046,0,0,0-.756.282.907.907,0,0,0-.3.694.941.941,0,0,0,.3.705,1.114,1.114,0,0,0,1.5,0,.938.938,0,0,0,.3-.705.92.92,0,0,0-.3-.694A1.046,1.046,0,0,0,14398,8239.478Zm-1.02-5.779.34,5h1.359l.336-5Z"
        transform="translate(-14388 -8230)"
        fill="currentColor"
      />
    </svg>
  );
}
