export function ChevronRight({ ...props }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 20 20" {...props}>
      <g transform="translate(-24 -24)">
        <g transform="translate(43.835 24) rotate(90)">
          <rect width="20" height="20" transform="translate(0 -0.165)" fill="none" />
        </g>
        <path
          d="M5.615,11.9.164,6.467a.531.531,0,0,1-.122-.189.613.613,0,0,1,0-.442.532.532,0,0,1,.122-.188L5.615.2A.628.628,0,0,1,6.074,0a.657.657,0,0,1,.475.213.585.585,0,0,1,.2.45.638.638,0,0,1-.2.467L1.6,6.057,6.549,11a.6.6,0,0,1,.18.442.648.648,0,0,1-1.113.458Z"
          transform="translate(37.782 39.967) rotate(180)"
          fill="currentColor"
          stroke="rgba(0,0,0,0)"
          strokeMiterlimit="10"
          strokeWidth="1"
        />
      </g>
    </svg>
  );
}
