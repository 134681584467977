import { ReactNode, memo, useMemo } from 'react';

import { twMerge } from 'tailwind-merge';

import ConnectCMSCompleteIcon from './DisplayStep.ConnectCMSCompleteIcon';
import ConnectCMSIcon from './DisplayStep.ConnectCMSIcon';

export enum StepDisplayStep {
  Connect_CMS = 'Connect CMS',
  Connect_CMS_Success = 'Connect CMS Success',
}

export interface IDisplayStep {
  title?: ReactNode;
  description?: ReactNode;
  className?: string;
  step?: StepDisplayStep;
}

const DisplayStep = memo(({ className, title, description, step }: IDisplayStep) => {
  const [icon, cls] = useMemo(() => {
    switch (step) {
      case StepDisplayStep.Connect_CMS_Success:
        return [<ConnectCMSCompleteIcon key={`icon`} />, 'text-primary'];
      case StepDisplayStep.Connect_CMS:
        return [<ConnectCMSIcon key={`icon`} />, ''];
      default:
        return [<div key="icon" />, ''];
    }
  }, [step]);
  return (
    <div className={twMerge('flex gap-4 items-center px-4 font-inter', className, cls)}>
      {icon}
      <div className="flex flex-col gap-0.5">
        <div className={twMerge('font-semibold text-sm text-v2blueGray-900', cls)}>{title}</div>
        <div className={twMerge('text-xs text-v2blueGray-500', cls)}>{description}</div>
      </div>
    </div>
  );
});

DisplayStep.displayName = 'DisplayStep';

export { DisplayStep };
