import { useMutation } from '@apollo/client';
import { MutationHookOptions } from '@apollo/client/react/types/types';
import { StoreFeature } from 'app-types/core';

import { FeatureRequestMutation } from '../../../gql/core/mutations';

export function useRequestFeature(
  options?: MutationHookOptions<{ feature: StoreFeature }, { feature: StoreFeature }>,
) {
  return useMutation<{ feature: StoreFeature }, { feature: StoreFeature }>(FeatureRequestMutation, {
    ...options,
    context: { clientName: 'core' },
  });
}
