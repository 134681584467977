import React, { memo } from 'react';

import { twMerge } from 'tailwind-merge';
import { match } from 'ts-pattern';

import { Copy } from '../../../atoms';
import CheckBox from '../../../atoms/Checkbox/Checkbox';
import Radio from '../../../atoms/Radio/Radio';
import { INFO, PRIMARY } from './colorThemes';

type ItemCardProps = {
  title: React.ReactNode;
  description?: React.ReactNode;
  leftIcon?: JSX.Element;
  isSelected: boolean;
  onSelect: (value: boolean) => void;
  type: 'checkbox' | 'radio';
  theme?: 'primary' | 'info';
  disabled?: boolean;
};

const ItemCard = memo(
  ({
    title,
    description,
    leftIcon,
    isSelected,
    onSelect,
    type,
    theme = 'primary',
    disabled,
  }: ItemCardProps) => {
    const { containerStyling, iconStyling, titleColor, descriptionColor } = match(theme)
      .with('primary', () => PRIMARY)
      .with('info', () => INFO)
      .exhaustive();

    const selector = match(type)
      .with('checkbox', () => (
        <CheckBox disabled={disabled} theme={theme} readOnly checked={!!isSelected} />
      ))
      .with('radio', () => (
        <Radio disabled={disabled} theme={theme} checked={!!isSelected} onChange={() => null} />
      ))
      .exhaustive();

    return (
      <div
        onClick={() => {
          if (!disabled) {
            onSelect(!isSelected);
          }
        }}
        className={twMerge(
          'select-none group hover:cursor-pointer flex items-center gap-4 border w-full p-[26px] rounded-[14px] bg-white h-[84px]',
          isSelected && containerStyling,
          disabled && 'hover:cursor-not-allowed',
        )}
      >
        {leftIcon && (
          <div
            className={twMerge(
              'w-8 h-8 flex items-center justify-center rounded-[10px] text-v2blueGray-700 bg-v2blueGray-100',
              isSelected && iconStyling,
            )}
          >
            {leftIcon}
          </div>
        )}
        <div className="flex flex-col flex-1">
          <Copy as="span" type="copy4" weight="medium" className={isSelected ? titleColor : ''}>
            {title}
          </Copy>
          {description && (
            <Copy
              as="span"
              type="copy2"
              weight="regular"
              className={twMerge('text-v2blueGray-500', isSelected && descriptionColor)}
            >
              {description}
            </Copy>
          )}
        </div>

        <div className="w-8 h-8 flex justify-center items-center rounded-md">
          <div className="">{selector}</div>
        </div>
      </div>
    );
  },
);

ItemCard.displayName = 'ItemCard';

export { ItemCard };
