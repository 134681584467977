import { type ReactNode, memo } from 'react';

import { twMerge } from 'tailwind-merge';

import { AgentPanelLogo } from '../../AgentPanel/AgentPanelLogo';

export interface IAgentName {
  className?: string;
  icon?: ReactNode;
  firstname?: string;
  lastname?: string;
  description?: string;
  size?: 'small' | 'medium' | 'large';
}
const AgentName = memo(
  ({ className, icon, firstname, lastname, description, size = 'medium' }: IAgentName) => {
    const abbr = `${firstname?.charAt(0) || ''}${lastname?.charAt(0) || ''}`;
    return (
      <div className={twMerge('flex gap-4 items-center px-4 font-inter', className)}>
        {icon ? (
          <span className="text-[24px]">{icon}</span>
        ) : (
          <AgentPanelLogo size={size} name={`${firstname} ${lastname}`} color="random">
            {abbr}
          </AgentPanelLogo>
        )}
        <div className="flex flex-col gap-0.5 min-w-0">
          <div
            className={twMerge(
              'font-semibold text-sm text-v2blueGray-900 whitespace-nowrap overflow-hidden overflow-ellipsis',
              size === 'large' && 'font-bold text-[16px]',
            )}
          >
            {firstname} {lastname}
          </div>
          <div
            className={twMerge(
              'text-xs text-v2blueGray-500',
              size === 'large' && 'text-sm font-normal',
            )}
          >
            {description}
          </div>
        </div>
      </div>
    );
  },
);

export { AgentName };
