import { FunctionComponent, createContext, useContext } from 'react';

export interface CompatibleLinkProps {
  className?: string;
  children: any;
  to: string;
}

function DefaultLink({ to, children, className }: CompatibleLinkProps) {
  return (
    <a href={to} className={className}>
      {children}
    </a>
  );
}

export type CompatibleLinkFunction = FunctionComponent<CompatibleLinkProps>;

export const CompatibilityLinkContext = createContext<CompatibleLinkFunction>(DefaultLink);

export function useCompatibilityLink() {
  return useContext(CompatibilityLinkContext);
}
