import { MouseEventHandler, ReactNode, Ref, forwardRef } from 'react';

import { twMerge } from 'tailwind-merge';

import './DropdownItem.css';

export interface IDropdownItem {
  disabled?: boolean;
  selected?: boolean;
  children?: ReactNode;
  icon?: ReactNode;
  onClick?: MouseEventHandler<HTMLDivElement>;
  wrapperClassName?: string;
  className?: string;
  iconPosition?: 'left' | 'right';
}
const DropdownItem = forwardRef(
  (
    {
      disabled,
      selected,
      children,
      icon,
      onClick,
      wrapperClassName,
      className,
      iconPosition = 'left',
      ...rest
    }: IDropdownItem,
    ref: Ref<HTMLDivElement>,
  ) => {
    return (
      <div className={twMerge('[&:not(:last-of-type)]:mb-[5px]', wrapperClassName)}>
        <div
          ref={ref}
          onClick={onClick}
          className={twMerge(
            'dropdown-item',
            'text-v2blueGray-800 rounded-[10px] min-h-[36px] py-1 flex items-center gap-2 hover:bg-v2blueGray-200 hover:bg-opacity-40 cursor-pointer px-4 font-inter text-[14px] tracking-[-0.324px] w-full',
            iconPosition === 'right' && 'justify-between',
            Boolean(icon) && iconPosition === 'left' && 'pl-2.5',
            Boolean(icon) && iconPosition === 'right' && 'pr-2.5',
            selected && !disabled && 'font-semibold bg-primary/20 text-primary hover:bg-primary/20',
            disabled && 'pointer-events-none !text-v2blueGray-200',
            className,
          )}
          {...rest}
        >
          {iconPosition === 'right' && children}
          {icon && (
            <div
              className={twMerge(
                'w-6 h-6 flex items-center justify-center text-v2blueGray-350 text-[16px] pointer-events-none',
                selected && 'text-primary',
              )}
            >
              {icon}
            </div>
          )}
          {iconPosition === 'left' && children}
        </div>
      </div>
    );
  },
);

export { DropdownItem };
