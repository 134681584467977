export default {
  'custLanding.password.title': 'Aizmirsāt savu paroli?',
  'custLanding.signup.btn': 'Pierakstīties',
  'custLanding.login.btn': 'Pieteikties',
  'custLanding.password.btn': 'Nomainīt paroli',
  'custLanding.switch-to-login': 'Pieteikties ar esošo kontu',
  'custLanding.switch-to-signup': 'Pierakstīties ar jaunu kontu',
  'custLanding.auth.text.password':
    'Ievadiet ar savu kontu saistīto e-pasta adresi, un mēs jums nosūtīsim norādījumus, kā iegūt jaunu paroli.',
  'custLanding.form.password': 'Parole',
  'custLanding.forgot-password': 'Aizmirsāt savu paroli?',
  'custLanding.forgot-password.success': 'Drīz jūs saņemsiet instrukcijas!',
  'custLanding.let-get-started': 'Sāksim!',
  'custLanding.already-have-an-account': 'Jums jau ir konts?',
  'custLanding.just-remembered': 'Tikko atcerējāties savu paroli?',
  'custlanding.login.error.no-order':
    'Nav atrasts neviens pasūtījums saistībā ar šo e-pasta adresi',
};
