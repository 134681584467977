import { useState } from 'react';

import noop from 'lodash-es/noop';

import { twMerge } from 'tailwind-merge';

import { DialogContainer } from '../../atoms';
import { Payment } from '../Payment/Payment';
import VatForm from '../VatForm/VatForm';
import { PriceStep } from './hooks/pricingBlockerHooks';
import { PricingPlanProps } from './types/pricingTypes';

type UpgradePlanFlowProps = {
  step: PriceStep;
  onChangeStep: (step: PriceStep, updateSubscription?: boolean) => void;
  isShopifyBilling: boolean;
  selectedPlan: PricingPlanProps;
  typeIsModal: boolean;
  updateSubscriptionInfo: () => Promise<void>;
  handleResetChanges: () => void;
  isOnTrial: boolean;
};

const UpgradePlanFlow = ({
  step,
  onChangeStep,
  isShopifyBilling,
  selectedPlan,
  typeIsModal,
  updateSubscriptionInfo,
  handleResetChanges,
  isOnTrial,
}: UpgradePlanFlowProps) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [resetChangesNeeded, setResetChangesNeeded] = useState(true);

  return (
    <>
      <DialogContainer
        className={twMerge('p-0 rounded-[22px]', !isShopifyBilling && 'max-w-[912px]')}
        wrapperClassName="p-0 md:p-4 py-10"
        isOpen={step === PriceStep.Payment || step === PriceStep.PaymentDesk}
        handleClose={typeIsModal || isSubmitting ? noop : () => onChangeStep(PriceStep.None)}
        zIndex={1001}
        afterLeave={() => {
          if (isSubmitting) {
            setIsSubmitting(false);
          }

          if (resetChangesNeeded) {
            handleResetChanges();
          }
        }}
      >
        {selectedPlan && (
          <Payment
            onPaymentSuccess={() => onChangeStep(PriceStep.None)}
            selectedPlan={selectedPlan}
            onAddVatInfo={() => {
              setResetChangesNeeded(false);
              onChangeStep(PriceStep.Vat);
            }}
            isShopifyBilling={isShopifyBilling}
            updateSubscriptionInfo={updateSubscriptionInfo}
            step={step}
            onClose={() => {
              onChangeStep(PriceStep.None, true);
            }}
            onChangeSubmit={setIsSubmitting}
            isSubmitting={isSubmitting}
            isOnTrial={isOnTrial}
          />
        )}
      </DialogContainer>

      <DialogContainer
        className="p-0 max-w-[796px] rounded-t-[22px] rounded-b-none md:rounded-[22px]"
        wrapperClassName="p-0 md:p-4 pt-10 md:py-10 items-end md:items-center"
        isOpen={step === PriceStep.Vat}
        handleClose={noop}
        zIndex={1001}
      >
        <VatForm
          onClose={() => {
            setResetChangesNeeded(true);
            onChangeStep(PriceStep.Payment);
          }}
        />
      </DialogContainer>
    </>
  );
};

export default UpgradePlanFlow;
