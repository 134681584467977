import { Fragment, HTMLProps, ReactNode, memo } from 'react';

import { ArrowBack, ChevronRight } from 'icons';
import { twMerge } from 'tailwind-merge';
import { ButtonVariant, Heading, IconButton } from 'ui/atoms';
import { DropdownButton, type Option } from 'ui/molecules';

export interface _AdminSettingsHeaderProps {
  dropDownOptions?: Array<Option>;
  dropdownButtonLabel?: ReactNode;
  mainDirectoryTitle: ReactNode;
  subDirectoryTitles?: Array<ReactNode>;
  isBackButtonVisible?: boolean;
  onBackButtonClick?: () => void;
}

export type AdminSettingsHeaderProps = HTMLProps<HTMLDivElement> & _AdminSettingsHeaderProps;

export function AdminSettingsHeader({
  mainDirectoryTitle,
  subDirectoryTitles,
  isBackButtonVisible,
  onBackButtonClick,
  className,
  dropDownOptions,
  dropdownButtonLabel,
}: AdminSettingsHeaderProps) {
  return (
    <div
      data-testid="outvioui--adminsettingsheader"
      className={twMerge(
        'w-full flex items-center pl-4 py-7 border-b-[1px] border-v2blueGray-200 text-[#1F3C4E] font-inter text-[22px] leading-7 tracking-[-0.033em]',
        className,
      )}
    >
      {isBackButtonVisible && (
        <IconButton
          data-testid="outvioui--adminsettingsheader-nav-button"
          className="flex items-center justify-center w-9 h-9 mr-2"
          onClick={onBackButtonClick}
        >
          <ArrowBack width="24" height="24" />
        </IconButton>
      )}
      <Heading className="capitalize" as="h1" type="heading1">
        {mainDirectoryTitle}
      </Heading>
      {subDirectoryTitles &&
        subDirectoryTitles.map((title, index) => {
          const last = index === subDirectoryTitles.length - 1;

          return (
            <Fragment key={`${title}-${index}`}>
              <ChevronRight width="20" height="20" className="ml-2 mr-[5px] text-[#849EAD]" />
              <Heading
                className={twMerge(last && 'flex-1 truncate')}
                as="h2"
                type="heading1"
                weight="regular"
              >
                {title}
              </Heading>
            </Fragment>
          );
        })}
      {dropDownOptions && dropdownButtonLabel && (
        <>
          <div className="grow" />
          <DropdownButton
            position="bottom-start"
            iconWrapperClass="ml-3"
            iconclass="border-[#DAE2E6]"
            buttonClass="border-[transparent]"
            theme={ButtonVariant.Secondary}
            options={dropDownOptions}
            isDoubleActionDisabled
          >
            {dropdownButtonLabel}
          </DropdownButton>
        </>
      )}
    </div>
  );
}

export default memo(AdminSettingsHeader);
