import { Fragment, ReactNode } from 'react';

import { type Placement, flip, offset, shift, size, useFloating } from '@floating-ui/react-dom';
import { Menu } from '@headlessui/react';
import { twMerge } from 'tailwind-merge';
import { IconButton } from 'ui/atoms';
import { DropdownPopup, type TPopupPositions } from 'ui/molecules';

export interface IDropdownMenuButton {
  children: ReactNode;
  label: ReactNode;
  position?: TPopupPositions;
  className?: string;
  classNamePopup?: string;
  disabled?: boolean;
  isCustomFloating?: boolean;
}
const DropdownMenuButton = ({
  isCustomFloating,
  children,
  label,
  position,
  className,
  classNamePopup,
  disabled,
}: IDropdownMenuButton) => {
  const { refs, floatingStyles } = useFloating({
    placement: position as Placement,
    middleware: [
      offset(4),
      flip(),
      shift(),
      size({
        apply({ availableWidth, availableHeight, elements }) {
          Object.assign(elements.floating.style, {
            maxWidth: `${availableWidth}px`,
            maxHeight: `${availableHeight - 24}px`,
          });
        },
      }),
    ],
  });
  return (
    <Menu
      ref={isCustomFloating ? undefined : refs.setReference}
      as="div"
      className={twMerge('relative w-max z-2 dropdown-menu-button', className)}
    >
      {({ open }) => {
        return (
          <>
            <div
              className={twMerge(open && 'dropdown-open')}
              onClick={(event) => {
                event.preventDefault();
                event.stopPropagation();
              }}
            >
              <Menu.Button disabled={disabled} as={Fragment}>
                <IconButton open={open}>{label}</IconButton>
              </Menu.Button>
            </div>
            <DropdownPopup
              ref={refs.setFloating}
              style={isCustomFloating ? undefined : floatingStyles}
              className={twMerge('static m-0', classNamePopup)}
              position={position}
              open={open}
            >
              <Menu.Items>{children}</Menu.Items>
            </DropdownPopup>
          </>
        );
      }}
    </Menu>
  );
};

export { DropdownMenuButton };
