import jacket from '../Assets/jacket.png';
import jeans from '../Assets/jeans.png';
import pocketBag from '../Assets/pocket-bag.png';
import sweater from '../Assets/ribbed-sweater.png';
import socks from '../Assets/socks.png';
import tShirt from '../Assets/t-shirt.png';
import { Currency, OutvioStatusCodes, SourceTrackingData } from '../Interfaces';
import { trackingToV2 } from './trackingData';

const EDIT_MODE_SOURCE_TRACKING_DATA: SourceTrackingData = {
  courier: 'UPS',
  currency: Currency.USD,
  orderOtn: '',
  destination: 'Lisboa-PT',
  origin: 'Tallinn-EE',
  cancelingAllowed: true,
  destinationAddress: {
    address: '2478 Oakridge Drive',
    city: 'Denver',
    country: 'United States',
    postcode: 'CO 80202',
    comment: "Leave next door in the cause I'm not home",
    name: 'William Jackson',
    countryCode: 'US',
    email: 'william.jackson@mail.com',
    phone: '(312) 555-7842',
    coordinates: {
      lng: -9.147704899999999,
      lat: 38.7067455,
    },
  },
  otn: '',
  trackingNumber: 'Z5A101Y6891234567',
  method: '24h Fast Shipping',
  vat: 9.4,
  id: 'Z5A101Y6891234567',
  originAddress: {
    address: 'Siidisaba 7',
    city: 'Tallinn',
    postcode: '11311',
    country: 'Estonia',
    countryCode: 'EE',
    contactName: 'Your Shop',
    contactPhone: '+372 12345678',
    coordinates: {
      lng: 24.725664,
      lat: 59.41371289999999,
    },
  },
  processDate: '2022-02-09T14:32:01.480Z',
  createdAt: '2022-02-07T14:56:53.480Z',
  dateTime: '2022-02-07T14:56:53.480Z',
  estimatedDeliverDate: '2022-07-17T00:00:00.000Z',
  total: 44.8,
  shippingPrice: 12,
  products: [
    {
      name: 'Full-Zip Jacket',
      imgSrc: jacket,
      variant: 'Olive Green',
      amount: 1,
      price: 39.9,
      discountPrice: null,
    },
    {
      name: 'Soft Relaxed Socks',
      imgSrc: socks,
      variant: 'Dark Gray',
      amount: 1,
      price: 4.9,
      discountPrice: null,
    },
  ],
  canEditDeliveryAddress: true,
  recommendedProducts: [
    {
      title: 'Cotton T-Shirt',
      imageUrl: tShirt,
      originalPrice: 19.9,
      discountedPrice: 9.9,
      currency: 'USD',
      link: '#',
    },
    {
      title: 'Pocket Shoulder Bag',
      imageUrl: pocketBag,
      originalPrice: 29.9,
      currency: 'USD',
      link: '#',
    },
    {
      title: 'Ribbed Sweater',
      imageUrl: sweater,
      originalPrice: 49.9,
      currency: 'USD',
      link: '#',
    },
    {
      title: 'Straight Jeans',
      imageUrl: jeans,
      originalPrice: 49.9,
      discountedPrice: 39.9,
      currency: 'USD',
      link: '#',
    },
  ],
};

const EDIT_MODE_SOURCE_STEPS = {
  steps: [
    {
      date: '10/02/2022 22:22',
      location: null,
      courierCode: 'A090000V',
      eventCode: 'A090000V',
      status: 'CREATED',
      code: OutvioStatusCodes.CREATED,
      coordinates: { lat: 59.41371289999999, lng: 24.725664 },
      serviceAreaCode: null,
      courier: 'UPS',
    },
    {
      date: '11/02/2022 10:12',
      location: null,
      courierCode: 'A090000V',
      eventCode: 'A090000V',
      status: 'CREATED',
      code: OutvioStatusCodes.PROCESSING,
      coordinates: { lat: 59.41371289999999, lng: 24.725664 },
      serviceAreaCode: null,
      courier: 'UPS',
    },
    {
      date: '12/02/2022 15:32',
      location: null,
      courierCode: 'A090000V',
      eventCode: 'A090000V',
      status: 'CREATED',
      code: OutvioStatusCodes.PICKED_UP,
      coordinates: { lat: 52.2296756, lng: 21.0122287 },
      serviceAreaCode: null,
      courier: 'UPS',
    },
    {
      date: '13/02/2022 11:50',
      location: 'Warsaw',
      courierCode: 'A090000V',
      eventCode: 'A090000V',
      status: 'CREATED',
      code: OutvioStatusCodes.IN_TRANSIT,
      coordinates: { lat: 41.9027835, lng: 12.4963655 },
      serviceAreaCode: null,
      courier: 'UPS',
    },
    {
      date: '15/02/2022 11:50',
      location: 'Rome',
      courierCode: 'A090000V',
      eventCode: 'A090000V',
      status: 'CREATED',
      code: OutvioStatusCodes.IN_TRANSIT,
      coordinates: { lat: 38.7222524, lng: -9.1393366 },
      serviceAreaCode: null,
      courier: 'UPS',
    },
  ],
};

export const EDIT_MODE_TEST_DATA = trackingToV2(
  EDIT_MODE_SOURCE_TRACKING_DATA,
  EDIT_MODE_SOURCE_STEPS,
  true,
);
