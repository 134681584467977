export default {
  'custLanding.password.title': 'Unustasid salasõna?',
  'custLanding.signup.btn': 'Loo konto',
  'custLanding.login.btn': 'Logi sisse',
  'custLanding.password.btn': 'Muuda salasõna',
  'custLanding.switch-to-login': 'Logi sisse olemasoleva kontoga',
  'custLanding.switch-to-signup': ' Loo uus konto',
  'custLanding.auth.text.password':
    'Sisesta oma e-maili aaddress ja saadame sulle juhised uue salasõna loomiseks.',
  'custLanding.form.password': 'Salasõna',
  'custLanding.forgot-password': 'Unustasid salasõna?',
  'custLanding.forgot-password.success': 'Sinu juhised saabuvad mõne hetke pärast!',
  'custLanding.let-get-started': 'Tere tulemast!',
  'custLanding.already-have-an-account': 'Sul juba on konto?',
  'custLanding.just-remembered': 'Salasõna tuli meelde?',
  'custlanding.login.error.no-order': 'Selle e-mailiga seotud saadetisi ei leitud.',
};
