import { useCallback, useEffect, useState } from 'react';

import { FormProvider, useForm } from 'react-hook-form';

import { yupResolver } from '@hookform/resolvers/yup';
import { SubscriptionPlan } from 'app-types/core';
import {
  DowngradeFlowEmailType,
  changePlanTo,
  deskActivate,
  subDeactivate,
  triggerDowngradeSupportEmail,
} from 'data-layer';
import { FormattedMessage, useIntl } from 'localization';
import { match } from 'ts-pattern';
import {
  AlertContainer,
  Button,
  ButtonVariant,
  CheckBox,
  Copy,
  Heading,
  TextAreaRhf,
} from 'ui/atoms';
import { Error as ErrorMessage } from 'ui/src/atoms';
import LayerWithSideBar from 'ui/src/molecules/LayerWithSidebar';

import { PricingPlanProps } from '../../types/pricingTypes';
import { helpToImproveSchema } from './helpToImproveSchema';

type HelpToImproveForm = {
  feedbackMessage: string;
  terms: boolean;
};

type HelpToImproveProps = {
  onClose: () => void;
  from?: PricingPlanProps;
  to?: PricingPlanProps;
  onDowngradeDone: () => void;
  desk?: boolean;
};

const HelpToImprove = ({ onClose, from, to, onDowngradeDone, desk }: HelpToImproveProps) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const intl = useIntl();

  const formMethods = useForm<HelpToImproveForm>({
    resolver: yupResolver(helpToImproveSchema(intl) as any) as any,
  });
  const { handleSubmit: handleFormSubmit, reset } = formMethods;

  useEffect(() => {
    reset({
      feedbackMessage: '',
      terms: false,
    });
  }, []);

  const isCancelSubscription = (to?.plan === SubscriptionPlan.Starter || !to?.plan) && !desk;

  const handleDowngradeOrCancel = useCallback(
    async (data: HelpToImproveForm) => {
      setIsSubmitting(true);

      await triggerDowngradeSupportEmail({
        type: DowngradeFlowEmailType.Feedback,
        feedback: data.feedbackMessage,
        currentPlan: from?.plan || (desk ? 'Desk' : ''),
        downPlan: to?.plan || 'CANCEL',
      } as any)
        .then(() => {
          if (desk) {
            return deskActivate(false);
          } else {
            if (!to?.plan) {
              return subDeactivate(true);
            }
            return changePlanTo({ targetPlan: to?.plan, period: 'monthly' });
          }
        })
        .then(async () => {
          onDowngradeDone();
        })
        .catch((err) => {
          setErrorMessage(err.message || err.toString());
          setIsSubmitting(false);
        });
    },
    [from?.plan, onClose, to?.plan],
  );

  const accountLanguage = intl.locale.slice(0, 2);

  const baseUrl = 'https://outvio.com/';
  const termsLink = accountLanguage === 'es' ? 'es/terminos-de-servicio/' : 'terms-of-service';

  const featureComparisonLink =
    accountLanguage === 'en'
      ? `${baseUrl}pricing/#compare-features`
      : `${baseUrl}${accountLanguage}/pricing/#compare-features`;

  const { titleKey, descriptionKey } = match<boolean>(true)
    .with(isCancelSubscription, () => ({
      titleKey: 'settings.subs.downgrade.heading.cancel',
      descriptionKey: 'settings.subs.cancel.help-us-improve.description',
    }))
    .with(!!desk, () => ({
      titleKey: 'settings.subs.desk-cancel.title',
      descriptionKey: 'settings.subs.cancel.desk.help-us-improve.description',
    }))
    .otherwise(() => ({
      titleKey: 'settings.subs.downgrade.heading.general',
      descriptionKey: 'settings.subs.cancel.help-us-improve.description',
    }));

  return (
    <FormProvider {...formMethods}>
      <LayerWithSideBar
        className="rounded-[22px]"
        sideBarContent={
          <>
            <div className="px-2 pt-2 flex flex-col flex-1">
              <Heading className="mb-[29px] capitalize">
                <FormattedMessage id={titleKey} values={{ plan: to?.plan }} />
              </Heading>
              <Copy className="whitespace-pre-line" type="copy7">
                <FormattedMessage id={descriptionKey} />
              </Copy>

              <div className="my-4 flex-1 flex items-center justify-center">
                <Button
                  as="a"
                  target="_blank"
                  href={featureComparisonLink}
                  theme={ButtonVariant.blue}
                >
                  <FormattedMessage id="settings.subs.cancel.show-feature-comparison" />
                </Button>
              </div>

              {errorMessage && (
                <ErrorMessage
                  className="my-4"
                  title={<FormattedMessage id="general.action-failed" />}
                  message={errorMessage}
                />
              )}
            </div>

            <Button
              onClick={handleFormSubmit(handleDowngradeOrCancel)}
              theme={ButtonVariant.Secondary}
              className="!bg-transparent hover:!bg-white/50"
              loading={isSubmitting}
            >
              <FormattedMessage id="settings.subs.cancel.lose-all-advantages" />
            </Button>
          </>
        }
      >
        <>
          <Heading type="heading2" weight="regular" className="px-2 pt-2 mb-6">
            <FormattedMessage id="settings.subs.cancel.help-us-improve.title" />
          </Heading>

          <TextAreaRhf
            name="feedbackMessage"
            placeholder={intl.formatMessage({
              id: 'settings.subs.cancel.help-us-improve.input-placeholder',
            })}
            rows={5}
          />

          <AlertContainer className="bg-v2blueGray-0 p-4 mt-2">
            <CheckBox
              theme="dark"
              name="terms"
              label={
                <Copy as="span" className="select-none">
                  <FormattedMessage
                    id="settings.payment.i-agree-to"
                    values={{
                      data: (
                        <a
                          href={`${baseUrl}${termsLink}`}
                          target="_blank"
                          className="text-v2blueGray-800 font-medium underline"
                        >
                          <FormattedMessage id="settings.payment.terms-of-service" />
                        </a>
                      ),
                    }}
                  />
                </Copy>
              }
            />
          </AlertContainer>

          <div className="flex-1 mt-10 flex items-end">
            <Button disabled={isSubmitting} onClick={onClose} className="mt-4 w-full">
              <FormattedMessage id="settings.subs.cancel.keep-my-advantages" />
            </Button>
          </div>
        </>
      </LayerWithSideBar>
    </FormProvider>
  );
};

export default HelpToImprove;
