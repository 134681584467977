import React, { FC, memo } from 'react';

import { useGetPaletteColors } from 'return-portal-ui';
import { Copy } from 'ui/atoms';

interface TrackingNumberProps {
  trackingNumber?: string;
  secondaryTrackingNumber?: string | null;
  courier: string;
}

const SHOW_BOTH_COURIERS = ['nacex', 'envialia'];
const TrackingNumberComponent: FC<TrackingNumberProps> = (props: TrackingNumberProps) => {
  const { trackingNumber, secondaryTrackingNumber, courier } = props;
  const { primaryText } = useGetPaletteColors();

  if (secondaryTrackingNumber && SHOW_BOTH_COURIERS.includes(courier.toLowerCase())) {
    return (
      <Copy as="div" type="copy1" style={{ color: primaryText }} weight="medium">
        {trackingNumber} | {secondaryTrackingNumber}
      </Copy>
    );
  }
  return (
    <Copy as="div" type="copy1" style={{ color: primaryText }} weight="medium">
      {trackingNumber || '-'}
    </Copy>
  );
};

export default memo<TrackingNumberProps>(TrackingNumberComponent);
