export function AllInOneInbox({ ...props }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="1em" height="1em" {...props}>
      <g id="Group_18125" data-name="Group 18125" transform="translate(-232 -2138)">
        <rect
          id="Rectangle_8025"
          data-name="Rectangle 8025"
          width="24"
          height="24"
          transform="translate(232 2138)"
          fill="none"
        />
        <path
          id="Path_40423"
          data-name="Path 40423"
          d="M234.945,2150.357h3.644l1.74,1.133h5.177l1.559-1.544h3.225v4.36l-1.469,1.939-11.748-.288-1.635-1-.833-1.741Z"
          transform="translate(1.522 0.005)"
          fill="currentColor"
        />
        <path
          id="Path_39262"
          data-name="Path 39262"
          d="M2610.4,1394.25h2.7a1.457,1.457,0,0,1,1.3.781,1.457,1.457,0,0,0,1.3.781h4.608a1.456,1.456,0,0,0,1.3-.781,1.457,1.457,0,0,1,1.3-.781h2.7m-10.024-6.25h4.848a5.6,5.6,0,0,1,1.673.125,2.541,2.541,0,0,1,.9.547,5.338,5.338,0,0,1,.864,1.4l1.728,3.391a3.7,3.7,0,0,1,.28.594,2.031,2.031,0,0,1,.1.422,4.649,4.649,0,0,1,.024.656v1.617a5.632,5.632,0,0,1-.264,2.469,2.266,2.266,0,0,1-1.048,1.023,6.031,6.031,0,0,1-2.528.258h-8.32a6.029,6.029,0,0,1-2.528-.258,2.266,2.266,0,0,1-1.048-1.023,5.632,5.632,0,0,1-.264-2.469v-1.617a4.6,4.6,0,0,1,.024-.656,2.015,2.015,0,0,1,.1-.422,3.724,3.724,0,0,1,.28-.594l1.728-3.391a5.332,5.332,0,0,1,.864-1.4,2.542,2.542,0,0,1,.9-.547A5.594,5.594,0,0,1,2615.576,1388Z"
          transform="translate(-2374 755.75)"
          fill="none"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.64"
        />
      </g>
    </svg>
  );
}
