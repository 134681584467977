import { FormProvider } from 'react-hook-form';

import { StoreCompany } from 'app-types/core';
import useGetCountryOptions from 'data-layer/hooks/useGetCountryOptions';
import { FormattedMessage, useIntl } from 'localization';
import { Button, ButtonVariant, Copy, Heading, SelectRhf, TextInputRhf } from 'ui/src/atoms';

import { Loader } from '../../../components';
import { useSubmitVatFormHandler } from './hooks/vatFormHooks';

type VatSectionProps = {
  onClose: () => void;
};

const VatForm = ({ onClose }: VatSectionProps) => {
  const intl = useIntl();

  const { formMethods, isSubmitting, isLoading, submitHandler } = useSubmitVatFormHandler(onClose);
  const { countryOptions, isLoading: isCountryListLoading } = useGetCountryOptions();

  if (isLoading || isCountryListLoading) {
    return (
      <div className="h-[474px]">
        <Loader />
      </div>
    );
  }

  return (
    <>
      <div className="p-8">
        <FormProvider {...formMethods}>
          <>
            <div className="px-2 pt-2">
              <Heading className="mb-[29px] text-v2blueGray-800">
                <FormattedMessage id="settings.subs.vat.update.head" />
              </Heading>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 gap-4 ">
              <div>
                <Copy type="copy2" noColor className="ml-2 mb-4 text-v2blueGray-600">
                  <FormattedMessage id="settings.vat-number" />
                </Copy>
                <TextInputRhf<StoreCompany>
                  name="vat"
                  placeholder={intl.formatMessage({ id: 'settings.company.vat-ex' })}
                  className="mb-6"
                />
                <Copy type="copy2" noColor className="ml-2 mb-4 text-v2blueGray-600">
                  <FormattedMessage id="settings.registration-number" />
                </Copy>
                <TextInputRhf<StoreCompany>
                  name="registrationNumber"
                  placeholder={intl.formatMessage({ id: 'settings.company.regnum-ex' })}
                  className="mb-6"
                />
                <Copy type="copy2" noColor className="ml-2 mb-4 text-v2blueGray-600">
                  <FormattedMessage id="general.link.missing-data.companyName" />
                </Copy>
                <TextInputRhf<StoreCompany>
                  name="name"
                  placeholder={intl.formatMessage({ id: 'general.link.missing-data.companyName' })}
                />
              </div>
              <div>
                <Copy type="copy2" noColor className="ml-2 mb-4 text-v2blueGray-600">
                  <FormattedMessage id="general.address" />
                </Copy>
                <TextInputRhf<StoreCompany>
                  name="address"
                  placeholder={intl.formatMessage({ id: 'general.address' })}
                  className="mb-6"
                />
                <Copy type="copy2" noColor className="ml-2 mb-4 text-v2blueGray-600">
                  <FormattedMessage id="general.city" />
                </Copy>
                <TextInputRhf<StoreCompany>
                  name="city"
                  placeholder={intl.formatMessage({ id: 'general.city' })}
                  className="mb-6"
                />

                <div className="grid grid-cols-2 gap-2">
                  <div>
                    <Copy type="copy2" noColor className="ml-2 mb-4 text-v2blueGray-600">
                      <FormattedMessage id="general.postcode" />
                    </Copy>
                    <TextInputRhf<StoreCompany>
                      name="postcode"
                      placeholder={intl.formatMessage({ id: 'general.postcode' })}
                    />
                  </div>
                  <div>
                    <Copy type="copy2" noColor className="ml-2 mb-4 text-v2blueGray-600">
                      <FormattedMessage id="general.country" />
                    </Copy>
                    <SelectRhf<StoreCompany>
                      name="countryCode"
                      wrapperClassName="grow"
                      className="grow"
                      placeholder={intl.formatMessage({ id: 'general.country' })}
                      options={countryOptions}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="mt-10 flex gap-2 justify-end p-2">
              <Button theme={ButtonVariant.Secondary} onClick={onClose} className="capitalize">
                <FormattedMessage id="general.cancel" />
              </Button>
              <Button loading={isSubmitting} onClick={submitHandler} className="capitalize">
                <FormattedMessage id="general.update" />
              </Button>
            </div>
          </>
        </FormProvider>
      </div>
    </>
  );
};

export default VatForm;
