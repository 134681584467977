import { StoreCurrency } from 'app-types/core';

const currencyToSymbol = (currency: StoreCurrency, amount?: number | string) => {
  switch (currency) {
    case 'USD':
      return `$${amount}`;
    case 'EUR':
      return `€${amount}`;
    case 'GBP':
      return `£${amount}`;
    default:
      return `${currency}${amount}`;
  }
};

export default currencyToSymbol;
