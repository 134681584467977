import { useCallback, useRef, useState } from 'react';

import { flip, offset, shift, useFloating } from '@floating-ui/react-dom';
import { twMerge } from 'tailwind-merge';
import { Tooltip } from 'ui';
import { IconButton } from 'ui/atoms';
import useClickAway from 'ui/src/hooks/useClickAway';

const AdditionalButton = ({
  icon,
  shorcuts,
}: {
  icon: JSX.Element;
  shorcuts: (onClose: () => void) => JSX.Element;
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const { refs, floatingStyles } = useFloating({
    placement: 'top',
    middleware: [offset(4), flip(), shift()],
  });

  const threeDotsRef = useRef<HTMLDivElement | null>(null);

  useClickAway(threeDotsRef, () => {
    if (isOpen) {
      setIsOpen(false);
    }
  });

  const toggleOpen = useCallback(() => {
    setIsOpen((prevState) => !prevState);
  }, [setIsOpen]);

  return (
    <div
      className="relative"
      ref={(currentRef) => {
        threeDotsRef.current = currentRef;
        refs.setReference(currentRef);
      }}
    >
      <Tooltip title={isOpen ? '' : 'Additional'} placement="top">
        <IconButton
          open={isOpen}
          className={twMerge('flex items-center justify-center w-9 h-9')}
          onClick={toggleOpen}
        >
          {icon}
        </IconButton>
      </Tooltip>

      {isOpen && (
        <div
          ref={refs.setFloating}
          style={{ ...floatingStyles, position: 'fixed' }}
          className="flex flex-col min-w-[200px] p-4 rounded-[22px] bg-white shadow-generic-popup"
        >
          {shorcuts(() => {
            setIsOpen(false);
          })}
        </div>
      )}
    </div>
  );
};

export { AdditionalButton };
