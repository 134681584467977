import { ButtonHTMLAttributes, LegacyRef, forwardRef, useMemo } from 'react';

import cn from 'classnames';

import './Button.css';

export interface IButton extends ButtonHTMLAttributes<HTMLButtonElement | HTMLAnchorElement> {
  theme?:
    | 'primary'
    | 'secondary'
    | 'highlight'
    | 'text'
    | 'white'
    | 'gray'
    | 'dark'
    | 'fb'
    | 'text-black-onGray'
    | 'text-black'
    | 'text-black-darker'
    | 'hub-gray'
    | 'hub-error'
    | 'red'
    | 'green'
    | 'hub-teal';
  size?: 'normal' | 'small' | 'lg' | 'xs';
  icon?: React.ReactNode;
  iconSide?: 'right' | 'left';
  outlined?: boolean;
  active?: boolean;
  showLoader?: boolean;
  as?: 'button' | 'link';
  href?: string;
  target?: string;
}

export const Button = forwardRef(
  (
    {
      as = 'button',
      theme = 'primary',
      outlined = false,
      size = 'normal',
      icon = null,
      iconSide = 'right',
      className,
      children: childrenSrc,
      showLoader = false,
      onClick,
      active,
      ...rest
    }: IButton,
    ref: LegacyRef<HTMLButtonElement | HTMLAnchorElement>,
  ) => {
    const children = useMemo(() => {
      if (typeof childrenSrc !== 'string') {
        if (childrenSrc === undefined) {
          return '';
        }
        return childrenSrc;
      }

      return <span>{childrenSrc}</span>;
    }, [childrenSrc]);

    const Tag = as === 'link' ? 'a' : 'button';
    return (
      <Tag
        ref={ref as any}
        className={cn(
          'exp-button',
          {
            'exp-button-small': size === 'small',
            'exp-button-xs': size === 'xs',
            'exp-button-lg': size === 'lg',
            'exp-button-icon-right': (icon !== null || showLoader) && iconSide === 'right',
            'exp-button-icon-left': (icon !== null || showLoader) && iconSide === 'left',
            'exp-button-primary': theme === 'primary' && !outlined,
            'exp-button-primary-outlined': theme === 'primary' && outlined,
            'exp-button-secondary': theme === 'secondary' && !outlined,
            'exp-button-secondary-outlined': theme === 'secondary' && outlined,
            'exp-button-highlight': theme === 'highlight',
            'exp-button-text': theme === 'text',
            'exp-button-gray': theme === 'gray',
            'exp-button-fb': theme === 'fb',
            'exp-button-white': theme === 'white',
            'exp-button-dark': theme === 'dark',
            'exp-button-text-black': theme === 'text-black',
            'exp-button-text-black-darker': theme === 'text-black-darker',
            'text-black-onGray': theme === 'text-black-onGray',
            'bg-red bg-opacity-10 text-red': theme === 'hub-error',
            'icon-type-button': !!children === false,
            'exp-button-teal': theme === 'hub-teal',
            'exp-button-red': theme === 'red',
            'exp-button-green': theme === 'green',
            active,
          },
          className,
        )}
        onClick={(showLoader ? undefined : onClick) as any}
        {...rest}
      >
        {iconSide === 'left' && !showLoader && icon}
        {children}
        {iconSide === 'right' && !showLoader && icon}
      </Tag>
    );
  },
);
