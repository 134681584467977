import deDE from './de-DE';
import enEN from './en-EN';
import esES from './es-ES';
import etEE from './et-EE';
import fiFI from './fi-FI';
import frFR from './fr-FR';
import itIT from './it-IT';
import ltLT from './lt-LT';
import lvLV from './lv-LV';
import plPL from './pl-PL';
import ptPT from './pt-PT';

export default {
  'en-EN': enEN,
  'es-ES': esES,
  'et-EE': etEE,
  'fr-FR': frFR,
  'it-IT': itIT,
  'de-DE': deDE,
  'fi-FI': fiFI,
  'lt-LT': ltLT,
  'pt-PT': ptPT,
  'lv-LV': lvLV,
  'pl-PL': plPL,
  'ca-ES': esES,
};
