export default {
  'ovc.general.powered-by': 'Eseguito da',
  'ovc.auth.identity.email.placeholder': 'E-mail',
  'ovc.auth.identity.cta': 'Effettuare un reso',
  'ovc.auth.order.orderNumber.placeholder': `Numero dell'ordine o di tracciameto del corriere`,
  'ovc.auth.order.clientInfo.placeholder': 'Codice postale o ultime 5 cifre del numero di telefono',
  'ovc.auth.switch-to-identity': `Utilizza l'email per accedere`,
  'ovc.auth.switch-to-order': 'Accedi con altre info',
  'ovc.auth.tracking.cta': 'Traccia il mio ordine',
  'ovc.auth.tracking.orderIdentifier.placeholder':
    'Inserisci l´ID dell´ordine, ONT o il numero di tracciatura',
  'ovc.auth.tracking.postcode.placeholder': 'Inserire il codice postale di destinazione',
  'ovc.listing.page.all': 'tutti gli ordini',
  'ovc.listing.page.in-transit': 'Ordini in transito',
  'ovc.listing.page.returns': 'Resi',
  'ovc.listing.header.order': 'ordine',
  'ovc.listing.header.date': 'data',
  'ovc.listing.header.total': 'totale',
  'ovc.listing.header.choose-product': 'Scegli il prodotto:',
  'ovc.listing.listing-track-cta': 'traccia',
  'ovc.listing.listing-return-cta': 'reso',
  'ovc.listing.listing-external-action-cta': 'richiesta di riparazione in garanzia',
  'ovc.listing.empty-list': 'Non sono presenti ordini da visualizzare.',
  'ovc.listing.return-status.store-non-returnable': 'Non si può restituire',
  'ovc.listing.return-status.already-returned': 'Restituito il {date}',
  'ovc.listing.return-status.no-longer-returnable': 'Non più restituibile',
  'ovc.listing.return-status.returnable': 'Restituibile fino a  {date}',
  'ovc.return-cart.refund-method-credit': `Scegli questa opzione se vuoi utilizzare il rimborso per il tuo prossimo acquisto con noi!{lineBreak}
  {lineBreak}
  Ti invieremo una gift card con l'importo del rimborso.`,
  'ovc.return-cart.inst.outvio.heading.confirm-pickup':
    'Conferma il tuo indirizzo di ritiro e restituzione.',
  'ovc.return-cart.inst.outvio.heading.confirm-return': 'conferma la restituzione',
  'ovc.return-cart.address.heading': 'inserisci un nuovo indirizzo o scegline uno già esistente:',
};
