import { UseQueryOptions, useQuery } from '@tanstack/react-query';
import request from 'ui/utils/request';

import { hourCache } from '../../../constants';

export type CountryItem = {
  [key: string]: {
    countryCode: string;
    currency: string;
    language: string;
    timeZone: string;
  };
};

export function useGetCountries<T = CountryItem | undefined>(
  options?: UseQueryOptions<CountryItem | undefined, unknown, T, [string]>,
) {
  return useQuery(
    ['getCountries'],
    () =>
      request(`${process.env.OUTVIO_API_URL}/config/getOpenedCountriesList`, {
        method: 'GET',
        secure: true,
      }).then((res) => res.result as CountryItem),
    {
      staleTime: hourCache,
      cacheTime: hourCache,
      ...options,
    },
  );
}
