import { ButtonHTMLAttributes, Ref, forwardRef, memo } from 'react';

import { twMerge } from 'tailwind-merge';

export interface IconButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  open?: boolean;
}

const IconButton = (
  { open, className, children, ...otherProps }: IconButtonProps,
  ref: Ref<HTMLButtonElement>,
) => {
  return (
    <button
      ref={ref}
      className={twMerge(
        'hover:bg-v2blueGray-100 text-v2blueGray-500 rounded-lg',
        open && 'bg-v2blueGray-100',
        className,
      )}
      {...otherProps}
    >
      {children}
    </button>
  );
};
/**
 * @deprecated IconButton should not be used
 *
 * Use instead: `import { ActionIcon } from 'ui/atoms'
 */
export default memo(forwardRef(IconButton));
