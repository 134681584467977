import { ReactNode } from 'react';

import { twMerge } from 'tailwind-merge';

const PortalAlert = ({
  type = 'alert',
  className,
  children,
}: {
  className?: string;
  type?: 'accent' | 'alert';
  children: ReactNode;
}) => {
  return (
    <div
      className={twMerge(
        'rounded-lg font-medium px-6 py-5 w-full text-base',
        type === 'accent' && 'text-portal-accent bg-portal-accent/10',
        type === 'alert' && 'text-portal-alert bg-portal-alert/10',
        className,
      )}
    >
      {children}
    </div>
  );
};

export { PortalAlert };
