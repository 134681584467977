import { useMemo } from 'react';

import { RenderElementProps, useSelected } from 'slate-react';

const allowedSchemes = ['http:', 'https:', 'mailto:', 'tel:'];

const LinkComponent = ({ attributes, children, element }: RenderElementProps) => {
  const selected = useSelected();

  const safeUrl = useMemo(() => {
    let parsedUrl: URL | null = null;
    try {
      parsedUrl = new URL(element.url || '');
    } catch {
      /* empty */
    }
    if (parsedUrl && allowedSchemes.includes(parsedUrl.protocol)) {
      return parsedUrl.href;
    }
    return 'about:blank';
  }, [element.url]);

  return (
    <a
      {...attributes}
      href={safeUrl}
      target="_blank"
      className={`text-blue-600 underline ${selected ? 'ring-1 ring-v2blueGray-300' : ''}`}
    >
      {children}
    </a>
  );
};

export default LinkComponent;
