import { useMemo } from 'react';

import { twMerge } from 'tailwind-merge';

import { ValueComponentProps } from './Dropdown';
import { DropdownExpandMoreIcon } from './DropdownExpandMoreIcon';

export function DefaultDropdownValue({
  placeholder,
  value,
  multiple,
  open,
  isError,
  disabled,
}: ValueComponentProps) {
  const buttonLabel = useMemo(() => {
    if (multiple && Array.isArray(value)) {
      return value.map((option) => option.label).join(', ');
    }
    return value?.label || '';
  }, [multiple, value]);

  return (
    <div
      className={twMerge(
        'font-inter text-v2blueGray-800 cursor-pointer w-full flex gap-2 bg-v2blueGray-0 hover:bg-v2blueGray-100 justify-between rounded-[10px] pl-4 pr-2 py-1.5 text-sm items-center transition-colors',
        open && 'bg-v2blueGray-100',
        isError && 'border border-v2red',
        disabled &&
          'text-v2blueGray-400 cursor-not-allowed bg-transparent hover:bg-transparent border border-v2blueGray-200',
      )}
    >
      {!!value?.icon && value.icon}
      <div className="flex-1 min-w-0 text-left">
        <div
          className={twMerge(
            'h-full block truncate text-v2blueGray-800 ',
            (!buttonLabel || disabled) && 'text-v2blueGray-400',
          )}
        >
          {buttonLabel || placeholder}
        </div>
      </div>
      <DropdownExpandMoreIcon open={open} />
    </div>
  );
}
