import { ReactNode, memo } from 'react';

import { ValueLoader } from 'ui/atoms';

interface SecondaryCardItemProps {
  name?: ReactNode;
  value?: ReactNode;
  rightSide?: ReactNode;
  loading?: boolean;
}

function DashboardSecondaryKpiItemBase({
  name,
  value,
  rightSide,
  loading,
}: SecondaryCardItemProps) {
  return (
    <div className="flex justify-between items-center w-full">
      <div className="space-y-1">
        <div className="text-v2blueGray-400 text-xs leading-[15px]">
          {name === undefined && loading ? (
            <div className="h-[15px] pt-0.5">
              <ValueLoader width={120} height={12} />
            </div>
          ) : (
            name
          )}
        </div>
        <div className="text-v2blueGray-700 text-sm font-medium leading-[17px] tracking-[-0.196px]">
          {loading ? (
            <div className="h-[17px] pt-px">
              <ValueLoader width={72} height={14} />
            </div>
          ) : (
            value
          )}
        </div>
      </div>
      {rightSide}
    </div>
  );
}

export const DashboardSecondaryKpiItem = memo(DashboardSecondaryKpiItemBase);
