import { createContext } from 'react';

import noop from 'lodash-es/noop';

import { SupportedLanguage } from '../types/CustomerGeneral';

interface IOVCLangSwitcherContext {
  appLang: SupportedLanguage;
  changeLangTo(value: SupportedLanguage): void;
}

export default createContext<IOVCLangSwitcherContext>({ appLang: 'en-EN', changeLangTo: noop });
