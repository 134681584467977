export default {
  'orders.package': 'Paquet',
  'orders.returns.reasons.size': 'La talla no em va bé',
  'orders.returns.reasons.wrong-product': 'Article entregat erroni',
  'orders.returns.reasons.wrong-size': 'La talla entregada és diferent a la demanada',
  'orders.returns.reasons.wrong-colour': 'El color del article és diferent al demanat',
  'orders.returns.reasons.package-broken': 'La caixa del producte està trencada',
  'orders.returns.reasons.product-broken': 'El producte està malmès/no funciona',
  'orders.returns.reasons.product-used': 'El producte rebut està usat',
  'orders.returns.reasons.product-dirty': 'El producte rebut està brut',
  'orders.returns.reasons.do-not-want': 'Ja no el full',
  'orders.returns.reasons.exchange': 'Vull fer un canvi',
  'orders.returns.reasons.other': 'Altre',
  'orders.returns.pickup.form.comments': 'Comentaris',
  'orders.list.empty': 'No tens comandes actualment',
  'orders.total': 'Total',
  'orders.vat': 'IVA',
  'or.confirm-address': 'CONFIRMA LA TEVA ADREÇA DE RECOLLIDA',
  'or.from': 'De',
  'or.to': 'Fins',
  'or.return.list.empty': 'No tens devolucions actualment',
  'or.confirm-return': 'CONFIRMA LA TEVA DEVOLUCIÓ',
};
