import React, { forwardRef, useContext } from 'react';

import get from 'lodash-es/get';

import { useIntl } from 'localization';
import Question from 'tracking-fe/src/Components/TrackingPage/Modules/Questions/Question';

import { CustomerSupportedLanguage2Letter } from '../types/CustomerGeneral';
import OVCStoreDataContext from './OVCStoreDataContext';

interface IOVCLandingQnA {
  qnaType: 'returns' | 'tracking';
}

const OVCLandingQnA = forwardRef<HTMLDivElement, IOVCLandingQnA>(({ qnaType }, ref) => {
  const intl = useIntl();
  const { storeData } = useContext(OVCStoreDataContext);

  const urlLang = intl.locale.substring(0, 2) as CustomerSupportedLanguage2Letter;

  const lang =
    typeof get(storeData, `portalSettings.${qnaType}.title.${urlLang}`) !== 'undefined'
      ? urlLang
      : 'en';

  const questionsData = storeData.portalV2?.regionalSettings?.find(
    (x: any) => x?.language?.ISOCode === lang || x?.language?.ISOCode === intl.locale,
  )?.questions;

  return (
    <div
      className="w-full max-w-[980px] mx-auto pl-6 md:pr-6 pr-10 py-[50px]"
      ref={ref}
      data-cy="ovc-qna-container"
    >
      <div className="m-0 grid gap-6">
        {questionsData?.questions?.map((question: any) => (
          <Question question={question} key={question.order} />
        ))}
      </div>
    </div>
  );
});

export default OVCLandingQnA;
