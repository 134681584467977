const PRIMARY = {
  containerStyling: 'bg-primary/15 border-primary/80',
  iconStyling: 'text-white bg-primary',
  titleColor: 'text-primary',
  descriptionColor: 'text-primary/80',
};

const INFO = {
  containerStyling: 'bg-v2blue/20 border-v2blue/80',
  iconStyling: 'text-white bg-v2blue',
  titleColor: 'text-v2blue',
  descriptionColor: 'text-v2blue/70',
};

export { PRIMARY, INFO };
