export default {
  'account.save': 'Enregistrer',
  'account.address.name': 'Pseudonyme',
  'account.address.address': 'Adresse',
  'account.address.postcode': 'Code postal',
  'account.address.city': 'Ville',
  'account.address.country': 'Pays',
  'account.address.province': 'Province',
  'account.address.contactName': 'Personne à contacter',
  'account.address.contactPhone': 'Contact téléphonique',
};
