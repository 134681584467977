import { ReactNode, memo } from 'react';

import { OutvioLogo } from 'icons';

export interface NavbarLogoProps {
  children?: ReactNode;
}

function NavbarLogoBase({ children }: NavbarLogoProps) {
  return (
    <div className="w-full flex justify-center p-4">{children ? children : <OutvioLogo />}</div>
  );
}

export const NavbarLogo = memo(NavbarLogoBase);
