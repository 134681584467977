import { ReactNode } from 'react';

import { useController } from 'react-hook-form';
import type { FieldValues, UseControllerProps } from 'react-hook-form';

import { ExclamationRounded } from 'icons';
import { CheckMark } from 'icons';
import { twMerge } from 'tailwind-merge';
import { match } from 'ts-pattern';
import { Copy } from 'ui/atoms';

export interface _CheckBoxProps {
  className?: string;
  labelClassName?: string;
  label: ReactNode;
  disabled?: boolean;
  theme?: 'primary' | 'info' | 'dark';
}

export type CheckBoxProps<T extends FieldValues> = UseControllerProps<T> & _CheckBoxProps;

export function CheckBox<T extends FieldValues>({
  className,
  labelClassName,
  disabled,
  label,
  theme = 'primary',
  ...otherProps
}: CheckBoxProps<T>) {
  const {
    field,
    fieldState: { error },
  } = useController(otherProps);

  const checkedThemeStyling = match(theme)
    .with(
      'primary',
      () => 'bg-primary border-primary drop-shadow-[0px_2px_4px_rgba(75,180,179,0.2)]',
    )
    .with('info', () => 'bg-v2blue border-v2blue drop-shadow-[0px_2px_4px_rgba(0,122,255,0.2)]')
    .with(
      'dark',
      () => 'bg-v2blueGray-800 border-v2blueGray-800 drop-shadow-[0px_2px_4px_rgba(35,56,65,0.2)]',
    )
    .exhaustive();

  return (
    <div className={className}>
      <label data-testid="outvioui--checkbox" className={twMerge('flex', disabled && 'opacity-40')}>
        <div className="w-[18px] h-[18px] mt-[0.10em] block relative cursor-pointer select-none">
          <input
            data-testid="outvioui--checkbox-input"
            className="absolute opacity-0 cursor-pointer h-0 w-0 pointer-events-none"
            type="checkbox"
            disabled={disabled}
            {...field}
            checked={field.value ?? false}
          />
          <span
            className={twMerge(
              'absolute inset-0 h-full w-full rounded-md border-2 pointer-events-none',

              field.value && checkedThemeStyling,
              !field.value && 'bg-white border-v2blueGray-200',
              error && 'border-v2red',
            )}
          />
          {field.value && (
            <CheckMark
              width="11"
              height="11"
              className="absolute top-1/2 left-1/2 translate-x-[-50%] translate-y-[-60%] pointer-events-none text-white"
            />
          )}
        </div>
        <Copy
          as="span"
          className={twMerge(
            'inline-block w-[calc(100%-26px)] ml-2 text-v2blueGray-700',
            labelClassName,
          )}
        >
          {label}
        </Copy>
      </label>
      {error && (
        <div className="mt-2 flex items-center ml-[6px] text-v2red gap-1">
          <ExclamationRounded width="16" height="16" />
          <div className="first-letter:capitalize">
            <Copy as="span" noColor type="copy2" className="ml-1">
              {error.message}
            </Copy>
          </div>
        </div>
      )}
    </div>
  );
}

export default CheckBox;
