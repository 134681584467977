const LIGHT_LEVELS = [8, 24, 88];
const DARK_LEVELS = [40, 80, 120];

function getCalculatedBrightnessFromHex(hexcolor: string, level: 1 | 2 | 3): string {
  const componentToHex = (c: number): string => c.toString(16).padStart(2, '0');

  const rgbToHex = (r: number, g: number, b: number): string =>
    `#${componentToHex(r)}${componentToHex(g)}${componentToHex(b)}`;

  const adjustBrightness = (color: number, adjustment: number, isLight: boolean): number =>
    isLight ? Math.max(0, color - adjustment) : Math.min(255, color + adjustment);

  const color = hexcolor.replace('#', '');
  const r = parseInt(color.slice(0, 2), 16);
  const g = parseInt(color.slice(2, 4), 16);
  const b = parseInt(color.slice(4, 6), 16);
  const L = r * 0.2126 + g * 0.7152 + b * 0.0722;
  const isLight = L > 128;
  const adjustment = isLight ? LIGHT_LEVELS[level - 1] : DARK_LEVELS[level - 1];

  return rgbToHex(
    adjustBrightness(r, adjustment, isLight),
    adjustBrightness(g, adjustment, isLight),
    adjustBrightness(b, adjustment, isLight),
  );
}

export default getCalculatedBrightnessFromHex;
