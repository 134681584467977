export function UnderlineTextShortcut({ ...props }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="1em" height="1em" {...props}>
      <g id="Group_10946" data-name="Group 10946" transform="translate(-2.339 -2.375)">
        <g
          id="Rectangle_8501"
          data-name="Rectangle 8501"
          transform="translate(2.339 2.375)"
          fill="#849ead"
          stroke="#707070"
          strokeWidth="1"
          opacity="0"
        >
          <rect width="24" height="24" stroke="none" />
          <rect x="0.5" y="0.5" width="23" height="23" fill="none" />
        </g>
        <g id="Group_10947" data-name="Group 10947" transform="translate(2.339 2.606)">
          <path
            id="Path_39082"
            data-name="Path 39082"
            d="M6.1,20.125a.75.75,0,1,1,0-1.5H17.9a.75.75,0,1,1,0,1.5Zm5.9-3.6a4.712,4.712,0,0,1-3.641-1.416,5.372,5.372,0,0,1-1.309-3.8V4.333a.871.871,0,0,1,.279-.649.915.915,0,0,1,.658-.27.921.921,0,0,1,.917.919V11.41a3.538,3.538,0,0,0,.806,2.429,2.893,2.893,0,0,0,2.29.913,2.893,2.893,0,0,0,2.29-.913A3.538,3.538,0,0,0,15.1,11.41V4.333a.871.871,0,0,1,.279-.649.915.915,0,0,1,.658-.27.921.921,0,0,1,.917.919v6.985a5.371,5.371,0,0,1-1.309,3.8A4.711,4.711,0,0,1,12,16.529Z"
            fill="#849ead"
          />
        </g>
      </g>
    </svg>
  );
}
