export default {
  FALLBACK_ERROR_MESSAGE: 'Midagi läks valesti. Palun proovi hiljem uuesti.',
  COURIER_ERROR_FALLBACK: 'Kulleri veateade.',
  TOKEN_NOT_FOUND: 'Token puudub.',
  IDENTITY_VERIFICATION_FAILED: 'Isiku tuvastamine ebaõnnestus.',
  ACCOUNT_BLOCKED: 'Sinu konto on blokeeritud. Palun vaata oma e-maili.',
  WRONG_EMAIL_OR_PASS: 'E-mail ja/või salasõna on ebakorrektsed.',
  INVALID_CREDENTIALS: 'Ebakorrektsed kredentsiaalid.',
  INVALID_TOKEN: 'Sessioon aegunud, sisene uuesti läbi tagastuste portaali.',
  MALFORMED_TOKEN: 'Vigane token.',
  CUSTOMER_ALREADY_CREATED: 'Kliendi konto juba eksisteerib. Palun logi sisse.',
  SHIPPING_METHOD_NOT_SUPPORTED: 'Ebakorrektne transpordimeetod.',
  SHIPMENT_ALREADY_PICKED_UP: 'Saadetis juba kullerile üle antud.',
  NO_RATE_FOR_DESTINATION: 'Transpordi hind antud sihtkohta puudub.',
  BUILDING_LABEL_FAILED: 'Pakikaardi loomine ebaõnnestus.',
  COURIER_NOT_SUPPORTED: 'Ebakorrektne kuller.',
  PICKUP_REQUEST_FAILED: 'Korje kutse ebaõnnestus.',
  ERROR_TRY_AGAIN_LATER: 'Midagi läks valesti. Palun proovi hiljem uuesti.',
  EMAIL_ALREADY_REGISTERED: 'E-maili aadress juba registreeritud.',
  INVALID_PASSWORD: 'Ebakorrektne salasõna.',
  LABEL_ERROR: 'Pakikaardi viga.',
  UNDER_MAINTENANCE: 'Meie äppi hetkel uuendatakse. Outvio on kohe varsti jälle tagasi.',
  MERGING_PDFS_FAILED: 'Pdf faili loomine ebaõnnestus',
  EXPIRE_TOKEN: 'Token aegunud',
  GENERIC_MISSING_PARAM: 'Vajalik info puudub.',
  ORDER_NOT_FOUND: 'Tellimust ei leitud.',
};
