import { ReactNode } from 'react';

import './DashboardCardHead.css';
import { DashboardCardTitle } from './DashboardCardTitle';

export interface DashboardCardHeadProps {
  title?: ReactNode;
  description?: ReactNode;
}

export function DashboardCardHead({ title, description }: DashboardCardHeadProps) {
  return (
    <div className="space-y-6">
      <DashboardCardTitle>{title}</DashboardCardTitle>
      {description ? <div className="db-head-desc">{description}</div> : null}
    </div>
  );
}
