export function ReturnsOnboardingIcon({ ...props }) {
  return (
    <svg
      width="1em"
      height="1em"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      fill="currentColor"
      {...props}
    >
      <g id="Group_16834" data-name="Group 16834" transform="translate(-865 -172)">
        <g
          id="Rectangle_10299"
          data-name="Rectangle 10299"
          transform="translate(865 172)"
          fill="none"
          stroke="currentColor"
          strokeWidth="1"
          opacity="0"
        >
          <rect width="24" height="24" stroke="none" />
          <rect x="0.5" y="0.5" width="23" height="23" fill="none" />
        </g>
        <path
          id="Path_41467"
          data-name="Path 41467"
          d="M2154,687.555s.1.66,2.912,3.394a7.338,7.338,0,0,0,10.176,0,6.943,6.943,0,0,0,1.936-3.394m-15.024,0v4.666m0-4.666h4.8m11.2-3.111s-.1-.66-2.912-3.394a7.339,7.339,0,0,0-10.176,0,6.943,6.943,0,0,0-1.936,3.394m15.024,0v-4.667m0,4.667h-4.8"
          transform="translate(-1285 -502)"
          fill="rgba(0,0,0,0)"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.64"
        />
      </g>
    </svg>
  );
}
