import { gql } from '@apollo/client';

export const PORTAL_RETURN_FRAGMENT = gql`
  fragment PortalReturn on ReturnsObjectReturn {
    id
    hasKeepItem
    products {
      quantity
      totalValue
      order
      details
      attachments
      action
      ids
      exchangeData
      name
      variant
      price
      discountPrice
      discount
      currency
      pictureUrl
    }
    isFailed
    errorMessage
    totalValue
    exchangeOrder {
      id
      totalPayment
      invoiceUrl
    }
    instructionSet {
      id
      name
      store
      labels {
        language
        body
      }
      details {
        language
        body
      }
      steps {
        id
        labels {
          language
          body
        }
      }
    }
    paymentDetails {
      returnValue
      exchangeValue
      shippingValue
      bonusValue
      toBePaid
      toBeReturned
    }
    labelUrls
    returnSheetUrls
  }
`;
