import React from 'react';

export type ExclamationRoundedProps = React.HTMLProps<SVGSVGElement>;

export function ExclamationRounded({ ...props }: ExclamationRoundedProps) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 20 20" {...props}>
      <g transform="translate(18.5 20.5) rotate(180)">
        <path
          d="M10.417,14.375a.614.614,0,0,0,.625-.625V10.208a.625.625,0,1,0-1.25,0V13.75a.614.614,0,0,0,.625.625Zm0-6.218a.661.661,0,1,0-.48-.194A.651.651,0,0,0,10.417,8.157Zm0,10.176A7.713,7.713,0,0,1,7.33,17.71a7.9,7.9,0,0,1-4.207-4.2,7.955,7.955,0,0,1,0-6.175,7.9,7.9,0,0,1,4.2-4.207,7.955,7.955,0,0,1,6.175,0,7.9,7.9,0,0,1,4.207,4.2,7.955,7.955,0,0,1,0,6.175,7.9,7.9,0,0,1-4.2,4.207A7.7,7.7,0,0,1,10.418,18.333Zm0-1.25a6.434,6.434,0,0,0,4.729-1.937,6.434,6.434,0,0,0,1.937-4.729,6.434,6.434,0,0,0-1.937-4.729A6.434,6.434,0,0,0,10.417,3.75,6.434,6.434,0,0,0,5.687,5.687,6.434,6.434,0,0,0,3.75,10.417a6.434,6.434,0,0,0,1.937,4.729A6.434,6.434,0,0,0,10.417,17.083Z"
          transform="translate(-1.917 0.083)"
          fill="currentColor"
        />
      </g>
    </svg>
  );
}
