export default {
  'orders.package': 'Encomenda',
  'orders.returns.reasons.size': 'O tamanho não serve',
  'orders.returns.reasons.wrong-product': 'Produto errado',
  'orders.returns.reasons.wrong-size': 'Tamanho errado',
  'orders.returns.reasons.wrong-colour': 'Cor errada',
  'orders.returns.reasons.package-broken': 'Embalagem danificada',
  'orders.returns.reasons.product-broken': 'Produto danificado/defeituoso',
  'orders.returns.reasons.product-used': 'Produto usado',
  'orders.returns.reasons.product-dirty': 'Produto sujo',
  'orders.returns.reasons.do-not-want': 'Já não quero o produto',
  'orders.returns.reasons.exchange': 'Gostaria de trocar o produto',
  'orders.returns.reasons.other': 'Outra',
  'orders.returns.pickup.form.comments': 'Comentários',
  'orders.list.empty': 'Atualmente não existem encomendas',
  'orders.total': 'Total',
  'orders.vat': 'IVA',
  'or.confirm-address': 'CONFIRMA A MORADA PARA A RECOLHA',
  'or.from': 'De',
  'or.to': 'Até',
  'or.return.list.empty': 'Atualmente não existem devoluções',
  'or.confirm-return': 'CONFIRMA A TUA DEVOLUÇÃO',
};
