import { HTMLProps, memo } from 'react';

import { useFocused } from 'slate-react';
import { twMerge } from 'tailwind-merge';

function DeskInternalNoteEditorWrap({ children, className }: HTMLProps<HTMLDivElement>) {
  const focus = useFocused();
  return (
    <div
      className={twMerge(
        'min-h-[120px] w-full py-[11px] outline-none-[none] px-[16px] rounded-[10px] outline-0 bg-v2blueGray-0 font-inter font-light text-[14px] border border-white text-v2blueGray-800 leading-[22px] tracking-[0.03em] focus:ring-primary focus:border-primary',
        focus && 'border-v2blueGray-200',
        className,
      )}
    >
      {children}
    </div>
  );
}

export default memo(DeskInternalNoteEditorWrap);
