export default {
  'general.lang.ca': 'Catalan',
  'general.lang.es': 'Spanish',
  'general.lang.en': 'English',
  'general.lang.et': 'Estonian',
  'general.lang.fr': 'French',
  'general.lang.it': 'Italian',
  'general.lang.de': 'German',
  'general.lang.fi': 'Finnish',
  'general.lang.pt': 'Portuguese',
  'general.lang.lt': 'Lithuanian',
  'general.lang.lv': 'Latvian',
  'general.lang.pl': 'Polish',
  'general.back': 'RETOUR',
  'general.required': 'Requis',
  'general.PRODUCT_PACKAGE_BROKEN': 'Emballage du produit cassé',
  'general.PRODUCT_BROKEN': 'Produit cassé',
  'general.PRODUCT_USED': 'Produit utilisé',
  'general.PRODUCT_DIRTY': 'Produit sale',
  'general.MISSING_PRODUCT_LABEL': 'Étiquette du produit manquante',
  'general.PRODUCT_MISSING': 'Produit manquant',
  'general.OTHER': 'Autre',
  'general.credit-card': 'Carte de Crédit',
  'general.bank-transfer': 'Virement bancaire',
  'general.cash-on-delivery': 'Paiement à la livraison',
  'general.continue': 'Continuer',
  'general.CLOSED': 'FERMÉ',
  'general.PARTIALLY_RECEIVED': 'PARTIELLEMENT REÇU',
  'general.RECEIVED': 'REÇU',
  'general.IN_TRANSIT': 'EN TRANSIT',
  'general.comments': 'Commentaires',
  'general.return': 'Retour',
  'general.user-account': 'Compte utilisateur',
  'general.sign-out': 'Déconnexion',
  'general.accept': 'Accepter',
  'general.was-returned-on': 'Il a été retourné le',
  'general.product-cannot-returned': 'Ce produit ne peut être retourné',
  'general.product-non-returnable':
    "Ce produit ne peut pas être retourné. Veuillez contacter l'assistance clientèle si vous souhaitez le retourner",
  'general.can-return-it': "Vous pouvez le retourner jusqu'à",
  'general.created': 'CRÉÉ',
  'general.shipped': 'ENVOYÉ',
  'general.in-transit': 'EN TRANSIT',
  'general.out-of-delivery': 'EN COURS DE LIVRAISON',
  'general.delivered': 'LIVRÉ',
  'general.shipping-charges': 'FRAIS DE PORT',
  'general.order-details': 'Détails de la commande',
  'general.return-details': 'Détails du retour',
  'general.no-orders-in-transit': 'Aucune commande actuellement en transit',
  'general.products-returned': 'Produits à retourner',
  'general.select-motive': 'Choisissez une raison',
  'general.why-want-return': 'Pourquoi voulez-vous retourner le produit ?',
  'general.do-have-comments': 'Avez-vous des commentaires ?',
  'general.enter-comment': 'Entrez votre commentaire',
  'general.do-want-attach-image': 'Souhaitez-vous joindre des images ?',
  'general.pickup-address': 'Adresse du point de ramassage',
  'general.date-time-pickup': 'Date et heure de ramassage',
  'general.contact-phone-number': 'Numéro de téléphone',
  'general.comments-courier': 'Commentaire pour le courrier',
  'general.confirm-address': `Je confirme l'adresse de retrait:`,
  'general.quantity': 'Quantité',
  'general.length': 'Longueur',
  'general.width': 'Largeur',
  'general.height': 'Hauteur',
  'general.identifier': 'Identifiant',
  'general.tracking-number': 'Numéro de suivi',
  'general.date-purchase': "Date d'achat",
  'general.date-time': 'Date et heure',
  'general.status': 'Statut',
  'general.shipping-cost': 'Frais de port pour ce retour',
  'general.free': 'Gratuit',
  'general.done': 'Fait',
  'general.spanish': 'Espagnol',
  'general.english': 'Anglais',
  'returns.step.products.title': 'SÉLECTION DE PRODUITS',
  'returns.step.products.text': 'Sélectionnez les produits à retourner',
  'returns.step.method.title': 'MÉTHODE DE RETOUR',
  'returns.step.method.text': 'Choisissez la méthode de retour',
  'returns.step.instructions.title': 'INSTRUCTIONS',
  'returns.step.instructions.text': 'Préparez votre retour',
  'returns.step.confirm.title': 'CONFIRMATION',
  'returns.step.confirm.text': 'Retour terminé',
  'general.img-upload': 'Glissez-déposez ou cliquez pour ajouter une image',
  'retcart.packages.text': `Complétez les colis pour terminer votre retour
    {lineBreak}{lineBreak}NOS CONSEILS:
    {lineBreak}- Essayez de réutiliser le même colis que celui utilisé pour la livraison de votre commande. C'est bon pour la planète et il est facilement accessible!
    {lineBreak}- Si vous retournez plusieurs articles, essayez d'utiliser le moins de colis possible.
    {lineBreak}- N'oubliez pas que lorsque vous expédiez des articles, l'air à l'intérieur du colis est également expédié! Si c'est possible et sans danger, essayez d'utiliser une boîte permettant de réduire l'espace vide. Cela réduit vos frais de retour. `,
  'retcart.step2.heading':
    "CONFIRMEZ LA TAILLE DE VOTRE COLIS (CLIQUEZ SUR « AJOUTER UN COLIS » SI VOUS DEVEZ EN UTILISER PLUS D'UN)",
  'retcart.pack.default-title.display': 'UTILISER LE COLIS DE LIVRAISON',
  'retcart.pack.custom-title.display': 'UTILISER UN COLIS PERSONNALISÉ',
  'retcart.pack.custom-title.edit': 'UTILISER UN COLIS PERSONNALISÉ (taille en cm)',
  'retcart.pack.change-size': '(Modifier la taille)',
  'retcart.pack.remove': '(Enlever)',
  'retcart.pack.add': 'AJOUTER LE COLIS',
  'retcart.addrmodal.heading1': "CHOISISSEZ L'UNE DE VOS ADRESSES ENREGISTRÉES",
  'retcart.addrmodal.heading2': 'OU ENTREZ UNE NOUVELLE ADRESSE',
  'retcart.print.loading.heading': 'Nous traitons votre retour...',
  'retcart.print.loading.note':
    'Ne fermez pas cette fenêtre avant que nous ayons traité votre demande !',
  'landing.email-sent':
    'E-mail envoyé avec succès, veuillez vérifier votre boîte de réception pour vérifier votre e-mail dans les 15 prochaines minutes.',
  'general.status.IN_TRANSIT': 'EN TRANSIT',
  'general.status.DELIVERED': 'LIVRÉ',
  'general.status.DELIVERED_POST_OFFICE': 'LIVRÉ',
  'general.status.DELIVERED_TO_PICKUP_POINT': 'LIVRÉ AU POINT{lineBreak}DE RAMASSAGE',
  'general.status.DELIVERED_TO_SHOP': 'DELIVERED TO{lineBreak}SHOP',
  'general.status.PARTIALLY_DELIVERED': 'PARTIELLEMENT LIVRÉ',
  'general.status.PARTIALLY_DELIVERED.short': 'PARTIE LIVRÉE.',
  'general.status.DELAYED': 'EN RETARD',
  'general.status.RETURNED': 'RETOURNÉ',
  'general.status.DELETED': 'SUPPRIMÉ',
  'general.status.RECEIVED': 'REÇU',
  'general.status.PARTIALLY_RECEIVED': 'PARTIELLEMENT REÇU',
  'general.status.PARTIALLY_RECEIVED.short': 'PARTIE REÇUE.',
  'general.status.CLOSED': 'FERMÉ',
  'general.status.CREATED': 'CRÉÉ',
  'general.status.PICKING_QUEUE': 'QUEUE DE RAMASSAGE',
  'general.status.SHIPPING_QUEUE': 'QUEUE DE LIVRAISON',
  'general.status.REFUNDED': 'REMBOURSÉ',
  'general.status.SHIPMENT_PICKUP': "RAMASSAGE D'EXPÉDITION",
  'general.status.SHIPPED': 'ENVOYÉ',
  'general.status.PICKUP': 'ENVOYÉ',
  'general.status.IN_TRANSIT_DELAYED': 'EN TRANSIT RETARDÉ',
  'general.status.EXCEPTION': 'EXCEPTION',
  'general.status.OUT_FOR_DELIVERY': 'EN COURS DE LIVRAISON',
  'general.status.RETURNED_TO_ORIGIN': "RENVOYÉ AU POINT D'ORIGINE",
  'general.status.CANCELLED': 'ANNULÉ',
  'sidebar.orders-in-transit': 'COMMANDES EN TRANSIT',
  'sidebar.my-orders': 'MES COMMANDES',
  'sidebar.returns': 'RETOURS',
  'general.courier.gls_ireland': 'GLS Ireland',
  'general.courier.tnt_italy': 'TNT Italy',
  'general.courier.brt': 'BRT',
  'general.courier.bring': 'Bring',
  'general.courier.budbee': 'Budbee',
  'general.courier.cbl': 'CBL',
  'general.courier.correos': 'Correos Express',
  'general.courier.ctt': 'CTT',
  'general.courier.ctt_portugal': 'CTT Portugal',
  'general.courier.chornopost': 'Chronopost',
  'general.courier.deprecated': 'DEPRECATED',
  'general.courier.deutschepost': 'Deutsche Post',
  'general.courier.deutschepost_germany': 'Deutsche Post',
  'general.courier.dhl': 'DHL',
  'general.courier.dhl_sweden': 'DHL',
  'general.courier.dhlparcel': 'DHL Parcel',
  'general.courier.dhlparcel_portugal': 'DHL Parcel',
  'general.courier.dhlparcel_spain': 'DHL Parcel',
  'general.courier.dhlparcel_poland': 'DHL Parcel',
  'general.courier.dhlparcel_unitedkingdom': 'DHL Parcel',
  'general.courier.dhl_paket_germany': 'DHL Paket',
  'general.courier.directa': 'Directa',
  'general.courier.dpd': 'DPD',
  'general.courier.gordon': 'Gordon',
  'general.courier.boostparcel': 'Boost Parcel',
  'general.courier.ronin': 'Ronin',
  'general.courier.trablisa': 'Trablisa Express',
  'general.courier.dpd_france': 'DPD',
  'general.courier.dpd_germany': 'DPD',
  'general.courier.dpd_portugal': 'DPD',
  'general.courier.dpd_portugal_new': 'DPD',
  'general.courier.dpd_romania': 'DPD',
  'general.courier.dpd_poland': 'DPD',
  'general.courier.envialia': 'Envialia',
  'general.courier.external': 'External Courier',
  'general.courier.external-EXCHANGE': 'Exchange',
  'general.courier.fedex': 'FedEx',
  'general.courier.glovo': 'Glovo',
  'general.courier.gls': 'GLS OLD',
  'general.courier.glsnew': 'GLS',
  'general.courier.gls_italy': 'GLS',
  'general.courier.glsshipit': 'GLS ShipIt',
  'general.courier.hermes': 'Hermes',
  'general.courier.inpost': 'InPost',
  'general.courier.inpost_spain': 'InPost',
  'general.courier.beeasy_spain': 'Beeasy',
  'general.courier.internal': 'Internal',
  'general.courier.matkahuolto': 'Matkahuolto',
  'general.courier.mondialrelay': 'Mondial Relay',
  'general.courier.colissimo': 'Colissimo',
  'general.courier.mrw': 'MRW',
  'general.courier.nacex': 'Nacex',
  'general.courier.omniva': 'Omniva',
  'general.courier.outvio': 'Outvio',
  'general.courier.paack': 'Paack',
  'general.courier.parcelforce': 'Parcelforce',
  'general.courier.posti': 'Posti',
  'general.courier.publiccorreos': 'Correos',
  'general.courier.postnord': 'PostNord',
  'general.courier.postnl': 'PostNL',
  'general.courier.relaiscolis': 'Relais Colis',
  'general.courier.royalmail': 'Royal Mail',
  'general.courier.schenker': 'DB Schenker',
  'general.courier.sending': 'SENDING',
  'general.courier.seur': 'SEUR',
  'general.courier.seur_atlas': 'SEUR Atlas',
  'general.courier.shop': 'To Shop',
  'general.courier.smartpost': 'Smartpost',
  'general.courier.spring': 'Spring GDS',
  'general.courier.tipsa': 'TIPSA',
  'general.courier.tnt': 'TNT',
  'general.courier.transaher': 'Transaher',
  'general.courier.ups': 'UPS',
  'general.courier.venipak': 'Venipak',
  'general.courier.zeleris': 'Zeleris',
  'general.courier.zeleriscustom': 'Zeleris',
  'general.courier.zitycity': 'Zitycity',
  'general.trackingbar.datetime': 'Date et heure',
  'general.trackingbar.location': 'Lieu',
  'general.trackingbar.status': 'Statut',
  'general.trackingbar.detail': 'Détails',
  'general.trackingbar.no-info': 'Informations non encore disponibles.',
  'general.return-error.all-returned': 'Tous les produits ont été retournés.',
  'retcart.heading.methods': 'COMMENT VOULEZ-VOUS RETOURNER VOS PRODUITS ?',
  'retcart.method.toClientShop': "APPORTEZ LES PRODUITS À L'UN DE NOS MAGASINS PRÈS DE CHEZ VOUS",
  'retcart.method.outvio': 'UTILISER NOTRE SERVICE DE MESSAGERIE POUR VOTRE RETOUR',
  'retcart.method.ownCourier': 'ENVOYEZ-NOUS VOTRE RETOUR AVEC VOTRE PROPRE COURRIER',
  'retcart.method.exchange': 'ÉCHANGEZ OU REMPLACEZ LES PRODUITS AU LIEU DE LES RETOURNER',
  'retcart.inst.toClientShop.heading':
    'CONFIRMEZ VOTRE RETOUR ET APPORTEZ LES PRODUITS À UN MAGASIN PRÈS DE VOUS',
  'retcart.inst.toClientShop.text1':
    'POUR FINALISER VOTRE RETOUR, VEUILLEZ cliquer sur le bouton CONFIRMER ci-dessous :',
  'retcart.inst.toClientShop.text2':
    "Une feuille de retour sera créée spécialement pour vous et vous devrez apporter les produits à l'un de nos magasins avant {dueDate}.",
  'retcart.inst.toClientShop.text3':
    'Vous pouvez trouver une liste complète de nos magasins ici : {link}.',
  'retcart.inst.toClientShop.text3.link': 'liste des emplacements des magasins',
  'general.confirm': 'confirmer',
  'retcart.inst.ownCourier.heading':
    'CONFIRMEZ VOTRE RETOUR ET EXPÉDIEZ LES PRODUITS AVEC UN COURSIER DE VOTRE CHOIX',
  'retcart.inst.ownCourier.text1':
    'POUR FINALISER VOTRE RETOUR, VEUILLEZ cliquer sur le bouton CONFIRMER ci-dessous :',
  'retcart.inst.ownCourier.text2':
    "Une feuille de retour sera créée spécialement pour vous et vous devrez vous assurer d'envoyer vos produits avant {dueDate}.",
  'retcart.inst.ownCourier.text3': 'Vous devrez envoyer les produits à :',
  'retcart.inst.ownCourier.text4':
    "P.S. : Vous pouvez utiliser n'importe quel coursier que vous souhaitez et vous devrez payer les frais de retour. Nous vous recommandons d'utiliser un service de suivi fiable pour vous assurer que votre retour nous parvient en toute sécurité.",
  'retcart.inst.exchange.heading': 'FAITES-NOUS CONNAÎTRE LES DÉTAILS ET CONFIRMEZ VOTRE ÉCHANGE',
  'retcart.inst.exchange.text1':
    'Avec quel produit, taille et quantité souhaitez-vous échanger le(s) produit(s) actuel(s) ?',
  'retcart.inst.exchange.text2':
    "Assurez-vous d'inclure tous les détails nécessaires dans votre message.",
  'retcart.inst.exchange.text.placeholder': "Laissez-nous vos détails d'échange ici.",
  'retcart.inst.exchange.text3': `Pour finaliser votre échange, veuillez cliquer sur CONTINUER ci-dessous.',
  
Un e-mail avec votre demande d'échange nous sera alors envoyé et nous prendrons contact avec vous pour convenir des détails de l'échange. Une copie de l'e-mail sera également envoyée à votre adresse e-mail.`,
  'retcart.confirm-return-terms': `J'ai vérifié mon retour et j'accepte les {terms}`,
  'retcart.confirm-return-terms.terms': 'Conditions générales',
  'retcart.confirm.heading': 'RETOUR TERMINÉ',
  'retcart.confirm.text3': 'POUR VOUS ASSURER QUE TOUT SE PASSE BIEN AVEC VOTRE RETOUR :',
  'retcart.confirm.toClientShop.text4':
    '1. Imprimez la feuille de retour ou conservez la copie électronique que nous vous avons envoyée par e-mail.',
  'retcart.confirm.toClientShop.text5': '2. APPORTEZ LES PRODUITS À {fillerShop} avant {dueDate}. ',
  'retcart.confirm.toClientShop.text5.fillerShop': 'un magasin près de chez vous',
  'retcart.confirm.toClientShop.text6':
    "3. Assurez-vous d'apporter votre feuille de retour avec vous.",
  'retcart.download-return-sheet': 'TÉLÉCHARGER LA FICHE DE RETOUR',
  'retcart.download-label': "TÉLÉCHARGER L'ÉTIQUETTE ET LE BON DE RETOUR",
  'retcart.confirm.ownCourier.text1':
    '1. Imprimez la feuille de retour et placez-la dans le colis avec les produits que vous retournez.',
  'retcart.confirm.ownCourier.text2':
    '2. Envoyez votre retour avec un coursier fiable de votre choix à :',
  'retcart.confirm.ownCourier.text3': "3. Assurez-vous d'expédier votre retour avant {dueDate}.",
  'retcart.confirm.outvio.text1':
    "VOTRE RETOUR A ÉTÉ TRAITÉ AVEC SUCCÈS, VEUILLEZ TÉLÉCHARGER VOTRE ÉTIQUETTE D'EXPÉDITION EN CLIQUANT SUR LE BOUTON CI-DESSOUS",
  'retcart.confirm.outvio.text2': "(l'étiquette a également été envoyée à votre adresse e-mail) :",
  'retcart.confirm.outvio.text3':
    'POUR VOUS ASSURER QUE TOUT SE PASSE BIEN LORSQUE LE COURSIER VIENT, VEUILLEZ :',
  'retcart.confirm.outvio.text4':
    "1. Imprimez la feuille de retour et découpez l'étiquette d'expédition.",
  'retcart.confirm.outvio.text5':
    "2. Placez la feuille de retour à l'intérieur du colis et collez l'étiquette sur votre colis bien fermé.",
  'retcart.confirm.outvio.text6':
    '3. Asseyez-vous, détendez-vous et attendez que le coursier passe prendre votre retour !',
  'retcart.inst.headline.important': 'IMPORTANT :',
  'retcart.inst.hasPickup.noLabel': `1. Vérifiez votre adresse d'enlèvement et les produits de votre retour.{LineBreak}
    2. Après avoir cliqué sur « CONFIRMER », une Bon de Retour sera créé.{LineBreak}
    3. Placez les articles du retour à l'intérieur du colis et scellez la boîte. {fillerOptionally}, vous pouvez aussi déposer le Bon de Retour à l'intérieur de la boîte.{lineBreak}
    4. Une fois que vous aurez cliqué sur Confirmer, nous informerons le transporteur et il viendra enlever votre retour dans les 2 prochains jours ouvrables. IMPORTANT ! Vous n'avez pas besoin d'imprimer une étiquette d'expédition. Le transporteur en apportera une lorsqu'il viendra chercher le retour.`,
  'retcart.inst.hasPickup.noLabel.fillerOptionally': 'Optionnellement',
  'retcart.inst.hasPickup.byEmail': `1. Vérifiez votre adresse de ramassage et les produits que vous retournez.{lineBreak}
      2. Après avoir cliqué sur "CONFIRMER", une feuille de retour sera créée et une demande de ramassage sera envoyée à notre coursier. {fillerNotice}.{lineBreak}
      3. Une fois que vous avez reçu l'étiquette d'expédition, placez la feuille de retour à l'intérieur du colis, fermez-le et collez l'étiquette d'expédition sur celui-ci.{lineBreak}
      4. Notre coursier viendra récupérer votre retour dans les 2 prochains jours ouvrables. `,
  'retcart.inst.hasPickup.byEmail.fillerNotice':
    "Vous recevrez l'Étiquette d'Expédition par e-mail ({email}) dans les 2 prochains jours ouvrables.",
  'retcart.inst.hasPickup.provided': `1. Vérifiez votre adresse d'enlèvement et les produits de votre retour.{LineBreak}
    2. Après avoir cliqué sur « CONFIRMER », une Bon de Retour sera créé. Veuillez l'imprimer et découper l'étiquette d'expédition pour chaque colis de votre retour.{LineBreak}
    3. Placez les articles de retour dans le colis avec le Bon de retour, collez l'étiquette d'expédition et scellez la boîte.{LineBreak}
    4. Le transporteur viendra enlever votre retour dans les 2 prochains jours ouvrables.`,
  'retcart.inst.noPickup.byEmail': `1. Révisez les produits inclus dans le retour. {lineBreak}
      2. Après avoir cliqué sur "CONFIRMER", une feuille de retour sera créée. {fillerNotice}{lineBreak}
      3. Vous recevrez l'étiquette d'expédition de retour par e-mail. Une fois que vous avez reçu l'étiquette d'expédition, placez la feuille de retour à l'intérieur du colis, fermez-le et collez l'étiquette d'expédition sur celui-ci.{lineBreak}
      4. Vous devrez apporter le ou les colis à un point de ramassage du coursier près de chez vous dans les 5 jours ouvrables. Pour trouver le point de ramassage le plus proche de chez vous, visitez la page d'accueil des coursiers {fillerCourierHomepage}.`,
  'retcart.inst.noPickup.byEmail.fillerNotice':
    " Vous recevrez l'étiquette d'expédition par e-mail à ({email}) dans les 2 prochains jours",
  'retcart.inst.noPickup.byEmail.fillerCourierHomepage': 'ici',
  'retcart.inst.noPickup.provided': `1. Révisez les produits inclus dans le retour. {lineBreak}
      2. Après avoir cliqué sur "CONFIRMER", une feuille de retour sera créée. Veuillez l'imprimer et découper l'étiquette d'expédition pour chaque colis dans votre retour.{lineBreak}
      3. Placez les articles à retourner à l'intérieur du colis avec la feuille de retour, collez l'étiquette d'expédition et fermez la boîte.{lineBreak}
      4. Vous devrez apporter le ou les colis à un point de ramassage du coursier près de chez vous. Pour trouver le point de ramassage le plus proche de chez vous, visitez la page d'accueil des coursiers {fillerCourierHomepage}.`,
  'retcart.inst.noPickup.provided.fillerCourierHomepage': 'ici',
  'retcart.confirm.outvio.head-sheet':
    'VOTRE RETOUR A ÉTÉ TRAITÉ AVEC SUCCÈS, VEUILLEZ TÉLÉCHARGER VOTRE FEUILLE DE RETOUR EN CLIQUANT SUR LE BOUTON CI-DESSOUS',
  'retcart.confirm.outvio.head-sheet.note':
    '(la feuille de retour a également été envoyée à votre adresse e-mail):',
  'retcart.confirm.outvio.head-label':
    "VOTRE RETOUR A ÉTÉ TRAITÉ AVEC SUCCÈS, VEUILLEZ TÉLÉCHARGER VOTRE ÉTIQUETTE D'EXPÉDITION EN CLIQUANT SUR LE BOUTON CI-DESSOUS",
  'retcart.confirm.outvio.head-label.note':
    "(l'étiquette a également été envoyée à votre adresse e-mail) :",
  'retcart.confirm.outvio.hasPickup.noLabel.head':
    'POUR VOUS ASSURER QUE TOUT SE PASSE BIEN LORSQUE LE COURSIER VIENT, VEUILLEZ :',
  'retcart.confirm.outvio.hasPickup.noLabel': `1. Imprimez le Bon de Retour (facultatif).{LineBreak}
    2. Incluez le Bon de Retour dans votre colis bien fermé. AUCUNE ÉTIQUETTE D'EXPÉDITION REQUISE !{lineBreak}
    3. Le transporteur viendra enlever votre retour dans les 2 prochains jours ouvrables et apportera une étiquette d'expédition pour votre retour.`,
  'retcart.confirm.outvio.hasPickup.byEmail.head':
    "P.S. : L'ÉTIQUETTE D'EXPÉDITION SERA ENVOYÉE À VOTRE E-MAIL DANS LES PROCHAINS JOURS.",
  'retcart.confirm.outvio.hasPickup.byEmail': `1. Une fois que vous avez reçu l'étiquette d'expédition à votre e-mail, imprimez-la avec la feuille de retour.{lineBreak}
      2. Placez la feuille de retour à l'intérieur du colis, fermez-la et collez l'étiquette d'expédition dessus.{lineBreak}
      3. Le coursier viendra récupérer votre retour dans les 2 prochains jours ouvrables. `,
  'retcart.confirm.outvio.hasPickup.provided': `1. Imprimez la feuille de retour et découpez l'étiquette d'expédition.{lineBreak}
      2. Placez la feuille de retour à l'intérieur du colis, fermez-la et collez l'étiquette d'expédition dessus.{lineBreak}
      3. Le coursier viendra récupérer votre retour dans les 2 prochains jours ouvrables. `,
  'retcart.confirm.outvio.noPickup.byEmail': `1. Une fois que vous avez reçu l'étiquette d'expédition à votre e-mail, imprimez-la avec la feuille de retour.{lineBreak}
      2. Placez la feuille de retour à l'intérieur du colis, fermez-la et collez l'étiquette d'expédition dessus.{lineBreak}
      3. Apportez votre colis à un point de ramassage du coursier près de chez vous. Pour trouver le point de ramassage le plus proche de chez vous, visitez la page d'accueil des coursiers {filler}.`,
  'retcart.confirm.outvio.noPickup.byEmail.filler': 'ici',
  'retcart.confirm.outvio.noPickup.provided': `1. Imprimez la feuille de retour et découpez l'étiquette d'expédition.{lineBreak}
      2. Placez la feuille de retour à l'intérieur du colis, fermez-la et collez l'étiquette d'expédition dessus.{lineBreak}
      3. Apportez votre colis à un point de ramassage du coursier près de chez vous. Pour trouver le point de ramassage le plus proche de chez vous, visitez la page d'accueil des coursiers {filler}.`,
  'retcart.confirm.outvio.noPickup.provided.filler': 'ici',
  'general.retcart.restart.btn': 'Redémarrez le processus de retour',
  'general.heading.refund.methods': 'COMMENT VOULEZ-VOUS RECEVOIR VOTRE REMBOURSEMENT ?',
  'retcart.method.SAME_AS_PAYMENT': 'REMBOURSEMENT DE MON MODE DE PAIEMENT ORIGINAL',
  'retcart.method.CREDIT_IN_STORE': 'CRÉDIT MAGASIN POUR DES ACHATS FUTURS',
  'retcart.confirm.exchange.heading': "DEMANDE D'ÉCHANGE TERMINÉE",
  'retcart.confirm.exchange.text1': 'VOTRE DEMANDE A BIEN ÉTÉ ENREGISTRÉE.',
  'retcart.confirm.exchange.text2':
    "(Une copie de votre demande d'échange a également été envoyée à votre adresse e-mail).",
  'retcart.confirm.exchange.text3':
    'Nous vous contacterons sous peu pour vous vous connaître les détails de votre échange.',
  'retcart.confirm.exchange.text4': 'MERCI!',
  'general.cancel': 'annuler',
  'general.retcart.init-restart':
    'Si ce problème persiste, veuillez redémarrer le processus en cliquant ICI',
  'retcart.return-method.toClientShop': 'EN MAGASIN',
  'retcart.return-method.outvio': 'NOTRE SERVICE DE MESSAGERIE',
  'retcart.return-method.ownCourier': 'VOTRE PROPRE SERVICE DE MESSAGERIE',
  'retcart.return-method.exchange': 'ÉCHANGE / REMPLACEMENT',
  'retcart.return-method.SAME_AS_PAYMENT': 'REMBOURSEMENT',
  'retcart.return-method.CREDIT_IN_STORE': 'AVOIR EN MAGASIN',
  'retcart.return-method.pickup': 'ENLÈVEMENT',
  'retcart.return-method.dropoff': 'DÉPÔT',
  'general.heading.pickup.methods':
    'SOUHAITEZ-VOUS DÉPOSER VOTRE COLIS OU SOLLICITER UN ENLÈVEMENT DU SERVICE DE MESSAGERIE ?',
  'retcart.method.pickup':
    'JE SOLLICITE UN ENLÈVEMENT À DOMICILE  DU RETOUR PAR LE SERVICE DE MESSAGERIE',
  'retcart.method.dropoff':
    'JE SOUHAITE DÉPOSER MOI-MÊME LE COLIS À UN POINT DE RAMASSAGE DU SERVICE DE MESSAGERIE',
  'retcart.img-attach-desc':
    "Charger des photos de l'emballage, du produit et de l'étiquette d'expédition",
  'retcart.confirm.outvio.noPickup.noLabel.head':
    "IMPORTANT ! AVANT D'EMMENER LE RETOUR À UN POINT DE DÉPÔT, VEUILLEZ :",
  'retcart.title.pickup': 'enlèvement',
  'retcart.title.dropoff': 'dépôt',
  'general.tracking.manage-exception': "Gérer l'exception sur le site Web du service de messagerie",
  'general.error.id-ver-token-missing': `Un jeton est requis pour l'authentification. Veuillez vérifier l'e-mail envoyé pour l'authentification.`,
  'general.error.id-ver.token-missing': `Erreur lors de l'initialisation. Veuillez vous assurer que vous utilisez un navigateur évolutif et qu'il est mis à jour.`,
  'general.error.fileupload.file-too-large': 'Taille maximale du fichier de {size} Mo',
  'general.error.fileupload.too-many-files': '{number} fichiers maximum',
  'general.error.fileupload.file-invalid-type': 'Type de fichier invalide',
  'general.error.fileupload.incorrect.size.max': `La résolution de l'image doit être au maximum de {width} par {height}`,
};
