import { Action, ThunkAction, configureStore } from '@reduxjs/toolkit';

import rootReducer, { RootState } from './reducers';

const store = configureStore({
  reducer: rootReducer,
});

export type AppThunk = ThunkAction<void, RootState, unknown, Action<string>>;

export default store;
