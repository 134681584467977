import { ReactNode, memo } from 'react';

import { MoreVert } from 'icons';
import { twMerge } from 'tailwind-merge';

import { useRandomColor } from '../../hooks';
import { DropdownMenuButton } from '../../molecules';
import { ListItem } from '../../templates';

interface ListItemWithStatusProps {
  name: string;
  icon?: ReactNode;
  rightElement?: ReactNode;
  status?: ReactNode;
  dropdownMenu?: ReactNode;
  className?: string;
  onClick?: () => void;
}

function ListItemWithIconBase({
  name,
  icon,
  rightElement,
  status,
  dropdownMenu,
  onClick,
  className,
}: ListItemWithStatusProps) {
  const { bg } = useRandomColor(name);
  return (
    <ListItem
      className={twMerge(`flex justify-between items-center w-full`, className)}
      onClick={onClick}
    >
      <div className="flex items-center space-x-4">
        <div
          className={twMerge(
            'w-8 h-8 rounded-10 text-white font-semibold text-lg flex justify-center items-center overflow-hidden',
            bg,
          )}
        >
          {icon ? icon : name.charAt(0)}
        </div>
        <div className="space-y-1">
          <div className="text-sm font-medium text-v2blueGray-800 leading-[17px]">{name}</div>
          {status ? status : null}
        </div>
      </div>
      {dropdownMenu ? (
        <DropdownMenuButton
          classNamePopup="min-w-[204px] max-h-[80vh] overflow-y-auto"
          position="bottom-end"
          label={
            <div className="w-9 h-9 flex justify-center items-center text-v2blueGray-800">
              <MoreVert className="w-6 h-6" />
            </div>
          }
        >
          {dropdownMenu}
        </DropdownMenuButton>
      ) : null}

      {rightElement && <div className="flex items-center">{rightElement}</div>}
    </ListItem>
  );
}

export const ListItemWithIcon = memo(ListItemWithIconBase);
