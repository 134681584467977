export default {
  FALLBACK_ERROR_MESSAGE: 'Qualcosa č andato storto. Riprova piů tardi.',
  COURIER_ERROR_FALLBACK: 'Errore del corriere.',
  TOKEN_NOT_FOUND: 'Token non trovato.',
  IDENTITY_VERIFICATION_FAILED: "Impossibile completare la verifica dell'identitŕ.",
  ACCOUNT_BLOCKED: 'Il tuo account č bloccato. Controlla la tua e-mail.',
  WRONG_EMAIL_OR_PASS: 'E-mail e / o password non corrette.',
  INVALID_CREDENTIALS: 'Credenziali non valide.',
  INVALID_TOKEN:
    'Sessione scaduta, si prega di richiedere una nuova email con il link di convalida nel portale di restituzione.',
  MALFORMED_TOKEN: 'Token non corretto.',
  CUSTOMER_ALREADY_CREADY: "Account giŕ creato. Esegui l'accesso.",
  SHIPPING_METHOD_NOT_SUPPORTED: 'Metodo di spedizione non accettato.',
  SHIPMENT_ALREADY_PICKED_UP: 'Spedizione giŕ ritirata.',
  NO_RATE_FOR_DESTINATION: 'Nessuna tariffa prevista per la destinazione.',
  BUILDING_LABEL_FAILED: 'Creazione etichetta non riuscita.',
  COURIER_NOT_SUPPORTED: 'Corriere non previsto.',
  PICKUP_REQUEST_FAILED: 'Richiesta di ritiro non riuscita.',
  ERROR_TRY_AGAIN_LATER: 'Qualcosa č andato storto. Riprova piů tardi. ',
  EMAIL_ALREADY_REGIS: 'Email giŕ registrata.',
  INVALID_PASSWORD: 'Password non valida.',
  LABEL_ERROR: 'Errore etichetta.',
  UNDER_MAINTENANCE: 'Siamo in manutenzione. Outvio tornerŕ presto online.',
  MERGING_PDFS_FAILED: 'Unione dei files PDF non riuscita',
  EXPIRE_TOKEN: 'Token scaduto',
  GENERIC_MISSING_PARAM: 'Parametri necessari mancanti.',
  ORDER_NOT_FOUND: 'Ordine non trovato.',
};
