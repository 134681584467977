export function AutomationsIcon({ ...props }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" width="1em" height="1em" {...props}>
      <g id="Group_18427" data-name="Group 18427" transform="translate(13089 3635)">
        <rect
          id="Rectangle_3785"
          data-name="Rectangle 3785"
          width="20"
          height="20"
          transform="translate(-13089 -3635)"
          fill="none"
        />
        <ellipse
          id="Ellipse_653"
          data-name="Ellipse 653"
          cx="2.417"
          cy="2.42"
          rx="2.417"
          ry="2.42"
          transform="translate(-13075.834 -3621.849)"
          fill="currentColor"
        />
        <path
          id="Path_40428"
          data-name="Path 40428"
          d="M613.667,3660.333a2.667,2.667,0,1,0-2.667-2.667A2.665,2.665,0,0,0,613.667,3660.333Zm0,0v6.222a1.784,1.784,0,0,0,1.778,1.778h2.667m6.222-2.667a2.667,2.667,0,1,0,2.667,2.667A2.665,2.665,0,0,0,624.333,3665.667Zm0,0V3655"
          transform="translate(-13698 -7287.51)"
          fill="none"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.64"
        />
      </g>
    </svg>
  );
}
