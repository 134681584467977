import noop from 'lodash-es/noop';

import { Subscription, SubscriptionPlanDescriptor } from 'app-types';
import { FormattedMessage } from 'localization';
import { Loader } from 'ui';
import { DialogContainer } from 'ui/src/atoms';
import { Pricing } from 'ui/src/organisms/Pricing/Pricing';

import DowngradeDeskFlow from './DowngradeDeskFlow';
import MeetingConfirmation from './DowngradeParts/MeetingConfirmation/MeetingConfirmation';
import DowngradePlanFlow from './DowngradePlanFlow';
import UpgradePlanFlow from './UpgradePlanFlow';
import { PriceStep, usePricingBlocker } from './hooks/pricingBlockerHooks';

export type PlanProgressProps = {
  cardActivated?: boolean;
  upgradeFinished?: boolean;
  error?: string;
};

export type ShopifyProgressProps = {
  url?: string;
  error?: string;
};

export type SubscriptionFlowProps = {
  type?: 'content' | 'modal';
  hideCancel?: boolean;
  subscription: Subscription;
  updateSubscriptionInfo: () => Promise<any>;
  plans: SubscriptionPlanDescriptor[];
  planProgress?: PlanProgressProps;
  onProgressFinish?: () => void;
  shopifyProgress?: ShopifyProgressProps;
};

const SubscriptionFlow = ({
  type = 'modal',
  hideCancel,
  subscription,
  updateSubscriptionInfo,
}: Omit<SubscriptionFlowProps, 'plans'>) => {
  const {
    step,
    selectedPlan,
    activePricingPlan,
    handleSetStep,
    handleUpdateSubInfo,
    handleChangePlan,
    isOnTrial,
    handleChangeDesk,
    handleResetChanges,
  } = usePricingBlocker(updateSubscriptionInfo);

  if (!subscription) return <Loader />;

  const typeIsModal = type === 'modal';
  const showCancelSubscription =
    !typeIsModal && !hideCancel && !subscription.cancelSubscription && subscription.isActive;

  const pricingWindowProps = {
    title: (
      <FormattedMessage
        id={typeIsModal ? 'settings.subs.inactive-subscription' : 'settings.subs.heading.main'}
      />
    ),
    onChangePlan: handleChangePlan,
    updateSubscriptionInfo: handleUpdateSubInfo,
    activePricingPlan: activePricingPlan,
    isCancelingSubscription: !!subscription.cancelSubscription,
    isTrial: isOnTrial,
    isShopifyBilling: subscription.shopifyBilling,
    paymentInPendingProcess: subscription.isPicked,
    onChangeDesk: handleChangeDesk,
  };

  return (
    <>
      {typeIsModal ? (
        <DialogContainer
          className="px-6 pb-6 pt-10 max-w-[912px] rounded-[22px]"
          wrapperClassName="p-0 md:p-4 py-10"
          isOpen={step === PriceStep.Price && !subscription.isPicked}
          handleClose={noop}
          zIndex={1001}
        >
          <Pricing {...pricingWindowProps} />
        </DialogContainer>
      ) : (
        <Pricing {...pricingWindowProps} />
      )}

      <UpgradePlanFlow
        isShopifyBilling={subscription.shopifyBilling}
        onChangeStep={handleSetStep}
        selectedPlan={selectedPlan}
        step={step}
        typeIsModal={typeIsModal}
        updateSubscriptionInfo={handleUpdateSubInfo}
        handleResetChanges={handleResetChanges}
        isOnTrial={isOnTrial}
      />

      <DialogContainer
        className="p-0 max-w-[912px] rounded-[22px]"
        wrapperClassName="p-0 md:p-4 py-10"
        isOpen={step === PriceStep.DowngradePlan}
        handleClose={noop}
        zIndex={1001}
        afterLeave={handleResetChanges}
      >
        <DowngradePlanFlow
          from={activePricingPlan}
          to={selectedPlan}
          onClose={() => handleSetStep(PriceStep.None)}
          onDowngradeDone={() => handleSetStep(PriceStep.None, true)}
          tookDowngradeOffer={!!subscription.tookDowngradeOffer}
          onBookMeeting={handleSetStep}
        />
      </DialogContainer>

      <DialogContainer
        className="p-0 max-w-[912px] rounded-[22px]"
        wrapperClassName="p-0 md:p-4 py-10"
        isOpen={step === PriceStep.DowngradeDesk}
        handleClose={noop}
        zIndex={1001}
        afterLeave={handleResetChanges}
      >
        <DowngradeDeskFlow
          onClose={() => handleSetStep(PriceStep.None)}
          onDowngradeDone={() => handleSetStep(PriceStep.None, true)}
          onBookMeeting={handleSetStep}
        />
      </DialogContainer>

      <DialogContainer
        className="max-w-[448px] rounded-[22px]"
        wrapperClassName="p-0 md:p-4 py-10"
        isOpen={step === PriceStep.RequestCall || step === PriceStep.RequestDemo}
        handleClose={noop}
        zIndex={50}
      >
        <MeetingConfirmation
          meetingType={step === PriceStep.RequestCall ? 'RequestCall' : 'RequestDemo'}
          onClose={() => handleSetStep(PriceStep.None)}
        />
      </DialogContainer>

      {showCancelSubscription && !subscription.isPicked && (
        <p className="mt-10 text-13 text-font-base text-center mx-auto">
          <FormattedMessage
            id="settings.subs.cancel.text"
            values={{
              span: (chunks: any) => (
                <span
                  onClick={() => handleSetStep(PriceStep.Cancel)}
                  className="font-semibold text-primary-base hover:text-primary-hover cursor-pointer"
                >
                  {chunks}
                </span>
              ),
            }}
          />
        </p>
      )}
    </>
  );
};

export default SubscriptionFlow;
