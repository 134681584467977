export default {
  'account.save': 'Guardar',
  'account.address.name': 'Alcunha',
  'account.address.address': 'Morada',
  'account.address.postcode': 'Código Postal',
  'account.address.city': 'Cidade',
  'account.address.country': 'País',
  'account.address.province': 'Distrito',
  'account.address.contactName': 'Pessoa de Contacto',
  'account.address.contactPhone': 'Contacto telefónico',
};
