export function CmsFeaturesIntegrationIcon({ ...props }) {
  return (
    <svg
      width="1em"
      height="1em"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      fill="currentColor"
      {...props}
    >
      <g id="Icon" transform="translate(-785 -395)">
        <rect
          id="Rectangle_6402"
          data-name="Rectangle 6402"
          width="24"
          height="24"
          transform="translate(785 395)"
          fill="none"
        />
        <g id="Group_17906" data-name="Group 17906" transform="translate(784.88 394.025)">
          <path
            id="Path_41592"
            data-name="Path 41592"
            d="M17.254,12.533a1.793,1.793,0,0,1-1.807-1.808V8.282a1.793,1.793,0,0,1,1.807-1.808h1.537A1.793,1.793,0,0,1,20.6,8.282v2.442a1.793,1.793,0,0,1-1.807,1.808Zm-5.863,0a1.793,1.793,0,0,1-1.808-1.808V8.282a1.793,1.793,0,0,1,1.808-1.808h1.46a1.793,1.793,0,0,1,1.808,1.808v2.442a1.793,1.793,0,0,1-1.808,1.808Zm-5.941,0a1.793,1.793,0,0,1-1.808-1.808V8.282A1.793,1.793,0,0,1,5.45,6.475H6.987A1.793,1.793,0,0,1,8.794,8.282v2.442a1.793,1.793,0,0,1-1.808,1.808Zm0,6.942a1.793,1.793,0,0,1-1.808-1.808V15.225A1.793,1.793,0,0,1,5.45,13.417H6.987a1.793,1.793,0,0,1,1.808,1.808v2.442a1.793,1.793,0,0,1-1.808,1.808Zm5.941,0a1.793,1.793,0,0,1-1.808-1.808V15.225a1.793,1.793,0,0,1,1.808-1.808h1.46a1.793,1.793,0,0,1,1.808,1.808v2.442a1.793,1.793,0,0,1-1.808,1.808Zm5.863,0a1.793,1.793,0,0,1-1.807-1.808V15.225a1.793,1.793,0,0,1,1.807-1.808h1.537A1.793,1.793,0,0,1,20.6,15.225v2.442a1.793,1.793,0,0,1-1.807,1.808Z"
            fill="currentColor"
          />
        </g>
      </g>
    </svg>
  );
}
