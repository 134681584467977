import { initReactI18next } from 'react-i18next';

import i18n from 'i18next';

// @ts-ignore
import de from '../Locales/de-DE/translation.json';
// @ts-ignore
import en from '../Locales/en-EN/translation.json';
// @ts-ignore
import es from '../Locales/es-ES/translation.json';
// @ts-ignore
import pl from '../Locales/pl-PL/translation.json';
// @ts-ignore
import pt from '../Locales/pt-PT/translation.json';

/**
 * TODO: Lazy and dynamic loading these
 */
const resources = {
  en,
  de,
  es,
  pl,
  pt,
};

i18n.use(initReactI18next).init({
  ns: ['trackingPage'],
  resources,
  lng: 'en',
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
