import { ReactNode } from 'react';

import { Pending } from 'icons';
import { twMerge } from 'tailwind-merge';

const ButtonPricing = ({
  children,
  theme = 'violet',
  onClick,
  loading = false,
  disabled,
}: {
  children: ReactNode;
  theme?: 'outline' | 'violet';
  onClick?: () => void;
  loading?: boolean;
  disabled?: boolean;
}) => {
  return (
    <button
      disabled={disabled || loading}
      onClick={onClick}
      className={twMerge(
        'font-inter flex items-center gap-2 rounded-[10px] font-medium text-sm w-full justify-center border p-2',
        theme === 'outline' && 'text-v2blueGray-800 border-v2blueGray-200',
        theme === 'violet' &&
          'text-white border-transparent bg-v2violet-650 drop-shadow-[0_4px_8px_rgba(110,80,255,0.2)]',
        disabled && 'cursor-not-allowed opacity-50',
      )}
    >
      {loading && <Pending className="animate-spin" width="20" height="20" />}
      <div className="first-letter:capitalize ">{children}</div>
    </button>
  );
};

export default ButtonPricing;
