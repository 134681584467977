import { type HTMLProps, type ReactNode } from 'react';

import { ExclamationRoundedFilled } from 'icons';
import { twMerge } from 'tailwind-merge';
import { Copy, Heading } from 'ui/atoms';

export interface _ErrorProps {
  className?: string;
  titleClassName?: string;
  copyClassName?: string;
  title?: ReactNode;
  message?: ReactNode;
}

export type ErrorProps = Omit<HTMLProps<HTMLDivElement>, 'title'> & _ErrorProps;

export function Error({ className, titleClassName, copyClassName, title, message }: ErrorProps) {
  return (
    <div
      data-testid="outvioui--error"
      className={twMerge(
        'flex items-start justify-center pt-3 pb-[15px] pl-4 bg-[#fbe0e0] rounded-[14px] pointer-events-none',
        className,
      )}
    >
      <div className="flex items-center justify-center min-w-[36px] min-h-[36px] mt-1 bg-v2red rounded-[10px]">
        <ExclamationRoundedFilled className="text-white" width="20" height="20" />
      </div>
      <div className="grow flex flex-col mt-[2px] ml-3 mr-3">
        {title && (
          <Heading className={twMerge('mb-[3px] text-v2red', titleClassName)} type="heading4">
            {title}
          </Heading>
        )}
        {message && (
          <Copy className={twMerge('my-0 text-v2red opacity-70', copyClassName)} type="copy2">
            {message}
          </Copy>
        )}
      </div>
    </div>
  );
}

export default Error;
