export type LocalePage =
  | 'customerOrder'
  | 'customerLanding'
  | 'account'
  | 'general'
  | 'countries'
  | 'apiErrors'
  | 'ovc';
// nl-NL, bg-BG, hr-HR, cs-CZ, el-GR, hu-HU, ga-IE, ro-RO, sk-SK, sl-SI, no-NO
export type CustomerSupportedLanguage2Letter =
  | 'es'
  | 'en'
  | 'et'
  | 'fr'
  | 'it'
  | 'de'
  | 'fi'
  | 'lt'
  | 'lv'
  | 'da'
  | 'pt'
  | 'pl'
  | 'sv'
  | 'ca'
  | 'nl'
  | 'bg'
  | 'hr'
  | 'cs'
  | 'el'
  | 'hu'
  | 'ga'
  | 'ro'
  | 'sk'
  | 'sl'
  | 'no';

export type SupportedLanguage =
  | 'es-ES'
  | 'en-EN'
  | 'et-EE'
  | 'fr-FR'
  | 'da-DK'
  | 'it-IT'
  | 'de-DE'
  | 'fi-FI'
  | 'lv-LV'
  | 'lt-LT'
  | 'pt-PT'
  | 'pl-PL'
  | 'sv-SE'
  | 'ca-ES'
  | 'nl-NL'
  | 'bg-BG'
  | 'hr-HR'
  | 'cs-CZ'
  | 'el-GR'
  | 'hu-HU'
  | 'ga-IE'
  | 'ro-RO'
  | 'sk-SK'
  | 'sl-SI'
  | 'no-NO';

export enum LanguageNames {
  'es-ES' = 'Español',
  'en-EN' = 'English',
  'et-EE' = 'Eesti keel',
  'fr-FR' = 'Français',
  'it-IT' = 'Italiano',
  'de-DE' = 'Deutsch',
  'fi-FI' = 'Suomi',
  'lv-LV' = 'Latviešu',
  'lt-LT' = 'Lietuvių',
  'pt-PT' = 'Português',
  'pl-PL' = 'Polski',
  'ca-ES' = 'Català',
  'sv-SE' = 'Svenska',
  'da-DK' = 'Dansk',
  'nl-NL' = 'Nederlands',
  'bg-BG' = 'Български',
  'hr-HR' = 'Hrvatski',
  'cs-CZ' = 'Čeština',
  'el-GR' = 'Ελληνικά',
  'hu-HU' = 'Magyar',
  'ga-IE' = 'Gaeilge',
  'ro-RO' = 'Română',
  'sk-SK' = 'Slovenčina',
  'sl-SI' = 'Slovenščina',
  'no-NO' = 'Norsk',
}

export type CustomerListingPage = 'all' | 'in-transit' | 'returns';

export interface SelectOption {
  value: string;
  label: string;
}
