import { useContext } from 'react';

import { OVCStoreDataContext } from '../CustomerArea';

const useGetPaletteColors = () => {
  const {
    storeData: {
      portalV2,
      portalSettings: { colors },
    },
  } = useContext(OVCStoreDataContext);

  return {
    header: portalV2?.colors?.header || colors?.header || '#FFFFFF',
    secondaryBg: portalV2?.colors?.secondaryBg || colors?.secondaryBg || '#FFFFFF',
    primaryText: portalV2?.colors?.primaryText || colors?.primaryText || '#000000',
    btnBg: portalV2?.colors?.btnBg || colors?.btnBg || '#000000',
    alertColor: '#FF6A00', // hardcoded for now v2orange-200
  };
};

export default useGetPaletteColors;
