import { useUserProfile } from 'data-layer';
import { Spinner } from 'icons';
import { FormattedMessage } from 'localization';
import { Button, Copy, Heading } from 'ui/atoms';

import LayerWithSideBar from '../../molecules/LayerWithSidebar';

type PaymentDoneProps = {
  onClose: () => void;
};

const PaymentDone = ({ onClose }: PaymentDoneProps) => {
  const { data, loading } = useUserProfile();

  return (
    <LayerWithSideBar
      className="rounded-[22px]"
      sideBarClassName="md:w-[328px]"
      sideBarContent={
        <div className="px-2 py-2 flex items-center md:items-start h-full">
          <Heading className="capitalize text-v2blueGray-800">
            <FormattedMessage id="settings.payment-done.upgrade-successful" />
          </Heading>
        </div>
      }
    >
      <div className="p-2 h-full flex flex-col">
        <Heading className="text-v2blueGray-800" weight="semibold">
          <FormattedMessage
            id="settings.payment-done.welcome"
            values={{
              username: loading ? (
                <Spinner className="inline-block ml-2 text-[18px]" />
              ) : (
                data?.firstname
              ),
            }}
          />
        </Heading>
        <Copy className="mt-6 whitespace-pre-line">
          <FormattedMessage id="settings.payment-done.description" />
        </Copy>

        <div className="flex-1 mt-20 flex items-end justify-end">
          <Button onClick={onClose}>
            <FormattedMessage id="settings.payment-done.lets-get-started" />
          </Button>
        </div>
      </div>
    </LayerWithSideBar>
  );
};

export default PaymentDone;
