import React, {
  type Dispatch,
  type ReactNode,
  type SetStateAction,
  type TextareaHTMLAttributes,
  forwardRef,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { type FieldValues, type Path, get, useFormContext } from 'react-hook-form';

import { twMerge } from 'tailwind-merge';
import { ErrorMessage } from 'ui/atoms';

interface _TextAreaProps {
  name?: string;
  className?: string;
  textareaClassName?: string;
  isFocused: boolean;
  setIsFocused: Dispatch<SetStateAction<boolean>>;
  isError: boolean;
  errorMessage?: string;
  register?: (name: string) => any;
  showCharacterCount?: boolean;
}

export type TextAreaProps = TextareaHTMLAttributes<HTMLTextAreaElement> & _TextAreaProps;

export const TextAreaBase = ({
  isFocused,
  isError,
  className,
  ...restProps
}: {
  isFocused?: boolean;
  isError?: boolean;
  className?: string;
} & TextareaHTMLAttributes<HTMLTextAreaElement>) => {
  return (
    <textarea
      data-testid="outvioui--textarea"
      className={twMerge(
        'w-full py-[11px] outline-none-[none] px-[16px] rounded-[10px] outline-0 bg-v2blueGray-0 placeholder:font-inter font-inter text-[14px] border text-v2blueGray-800 leading-[22px] resize-none',
        isFocused && '!border-v2blueGray-200',
        isError ? `border-v2red placeholder-v2red` : `border-v2blueGray-0`,
        className,
      )}
      {...restProps}
    />
  );
};

export const TextArea = forwardRef<HTMLTextAreaElement, TextAreaProps>(
  (
    {
      name = '',
      className,
      onBlur,
      textareaClassName = 'min-h-[88px]',
      isFocused,
      setIsFocused,
      isError,
      errorMessage,
      register,
      showCharacterCount,
      maxLength,
      ...otherProps
    },
    ref: any,
  ) => {
    let refRHF: (ref: any) => void;
    let onBlurRHF: (event: React.FocusEvent<HTMLTextAreaElement, Element>) => void;
    let restRHF = {};
    if (register) {
      const { ref, onBlur, ...rest } = register(name);
      refRHF = ref;
      onBlurRHF = onBlur;
      restRHF = rest;
    }

    const { watch } = useFormContext<FieldValues>();

    const value = watch(name);

    return (
      <div className={twMerge('flex flex-col flex-1', className)}>
        <div className="relative flex-1">
          <textarea
            data-testid="outvioui--textarea"
            className={twMerge(
              'w-full py-[11px] outline-none-[none] px-[16px] rounded-[10px] outline-0 bg-v2blueGray-0 placeholder:font-inter font-inter text-[14px] border text-v2blueGray-800 leading-[22px] resize-none',
              isFocused && '!border-v2blueGray-200',
              isError ? `border-v2red placeholder-v2red` : `border-v2blueGray-0`,
              textareaClassName,
            )}
            {...otherProps}
            maxLength={maxLength}
            {...restRHF}
            onFocus={() => {
              setIsFocused(true);
            }}
            onBlur={(event) => {
              onBlur && onBlur(event);
              onBlurRHF && onBlurRHF(event);
              setIsFocused(false);
            }}
            ref={(currentRef) => {
              if (ref) {
                ref.current = currentRef;
              }

              refRHF && refRHF(currentRef);
            }}
          />
          {!!maxLength && showCharacterCount && (
            <div
              className={`absolute bottom-3 right-4 flex gap-2 items-center text-sm text-v2blueGray-400 z-[9999] ${
                value?.length === maxLength ? '!text-red-500' : ''
              }`}
            >
              {value ? value?.length + `/${maxLength}` : '0' + `/${maxLength}`}
            </div>
          )}
        </div>
        {isError && <ErrorMessage>{errorMessage ?? ('' as ReactNode)}</ErrorMessage>}
      </div>
    );
  },
);

export type _TextAreaRhfProps<T> = {
  className?: string;
  textareaClassName?: string;
  name: Path<T>;
  showCharacterCount?: boolean;
  maxLength?: number;
};

export type TextAreaRhfProps<T extends FieldValues> = TextareaHTMLAttributes<HTMLTextAreaElement> &
  _TextAreaRhfProps<T>;

export const TextAreaRhf = forwardRef<HTMLTextAreaElement, TextAreaRhfProps<FieldValues>>(
  ({ name, showCharacterCount, maxLength, ...otherProps }, ref) => {
    const [isFocused, setIsFocused] = useState<boolean>(false);
    const {
      register,
      unregister,
      getFieldState,
      formState: { errors },
    } = useFormContext<FieldValues>();

    useEffect(() => {
      return () => {
        const { isDirty } = getFieldState(name as Path<FieldValues>);
        unregister(name as Path<FieldValues>, {
          keepDirty: true,
          keepDefaultValue: true,
          keepValue: isDirty,
        });
      };
    }, [name, unregister, getFieldState]);

    const fieldError = useMemo(() => get(errors, name), [errors[name]?.message, name]);

    return (
      <TextArea
        name={name}
        ref={ref}
        register={register as (name: string) => void}
        isFocused={isFocused}
        setIsFocused={setIsFocused}
        isError={!!fieldError}
        errorMessage={fieldError?.message as string}
        maxLength={maxLength}
        showCharacterCount={showCharacterCount}
        {...otherProps}
      />
    );
  },
);
export default TextArea;
