import { ReactNode, memo, useMemo } from 'react';

import { CheckCircle, ExclamationRounded } from 'icons';
import { twMerge } from 'tailwind-merge';

export enum ListItemStatusVariant {
  info = 'info',
  success = 'success',
  pending = 'pending',
  disabled = 'disabled',
}

interface ListItemStatusProps {
  children: ReactNode;
  variant?: ListItemStatusVariant;
}

function ListItemStatusBase({
  children,
  variant = ListItemStatusVariant.success,
}: ListItemStatusProps) {
  const { Icon, variantClass } = useMemo(() => {
    switch (variant) {
      case ListItemStatusVariant.info:
        return {
          Icon: ExclamationRounded,
          variantClass: 'text-v2orange',
        };
      case ListItemStatusVariant.pending:
      case ListItemStatusVariant.disabled:
        return {
          Icon: ExclamationRounded,
          variantClass: 'text-v2blueGray-400',
        };

      default:
        return {
          Icon: CheckCircle,
          variantClass: 'text-primary',
        };
    }
  }, [variant]);

  return (
    <div className={twMerge('flex items-center space-x-1', variantClass)}>
      <Icon
        className={twMerge('text-base', variant === ListItemStatusVariant.disabled && 'rotate-180')}
      />
      <div className="text-xs font-normal leading-[15px]">{children}</div>
    </div>
  );
}

export const ListItemStatus = memo(ListItemStatusBase);
