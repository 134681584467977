import React, { useContext, useMemo } from 'react';

import { Checkbox, CheckboxProps, Theme, makeStyles } from '@material-ui/core';
import CheckBox from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlank from '@material-ui/icons/CheckBoxOutlineBlank';
import cn from 'classnames';

import opacityToSolidColor from '../utils/opacityToSolidColor';
import OVCStoreDataContext from './OVCStoreDataContext';

interface StyleProps {
  textColor: string;
  tenPctText: string;
  alertColor: string;
  error: boolean;
  offsetPosition: boolean;
}

const useStyles = makeStyles<Theme, StyleProps>({
  root: {
    color: (props) => `${props.textColor} !important`,
    fontSize: '40px',
    transform: (props) => (props.offsetPosition ? 'translateY(-15px) translateX(15px)' : 'none'),
  },
  rootError: {
    color: (props) => `${props.alertColor} !important`,
  },
  colorSecondary: {
    '&:hover': {
      backgroundColor: (props: any) => `${props.tenPctText} !important`,
    },
  },
});

interface IOVCCheckbox extends CheckboxProps {
  offsetPosition?: boolean;
  customColor?: string;
  customBgColor?: string;
  error?: boolean;
}

const OVCCheckbox = ({
  offsetPosition = false,
  checked,
  customColor,
  customBgColor,
  error = false,
  ...rest
}: IOVCCheckbox) => {
  const { storeData } = useContext(OVCStoreDataContext);

  const tenPctText = useMemo(
    () =>
      opacityToSolidColor(
        customColor || storeData.portalSettings.colors.primaryText,
        0.1,
        customBgColor ||
          (checked
            ? storeData.portalSettings.colors.primaryBg
            : storeData.portalSettings.colors.secondaryBg),
      ),
    [storeData, checked, customColor, customBgColor],
  );

  const classes = useStyles({
    textColor: customColor || storeData.portalSettings.colors.primaryText,
    tenPctText,
    alertColor: storeData.portalSettings.colors.alertColor,
    error,
    offsetPosition,
  });

  console.log();

  return (
    <Checkbox
      checkedIcon={<CheckBox color="inherit" fontSize="inherit" />}
      icon={<CheckBoxOutlineBlank color="inherit" fontSize="inherit" />}
      classes={{
        root: cn(classes.root, { [classes.rootError]: error }),
        colorSecondary: classes.colorSecondary,
      }}
      checked={checked}
      {...rest}
    />
  );
};

export default OVCCheckbox;
