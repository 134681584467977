export function HelpFilled({ ...props }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 -960 960 960"
      width="1em"
      height="1em"
      fill="currentColor"
      {...props}
    >
      <path
        fill="currentColor"
        d="M479.56-255.386q17.132 0 28.94-11.829 11.807-11.829 11.807-28.961 0-17.132-11.829-28.939-11.829-11.808-28.961-11.808-17.132 0-28.939 11.829-11.808 11.829-11.808 28.961 0 17.132 11.829 28.94 11.829 11.807 28.961 11.807Zm-28.329-143.23h56.307q.769-29.538 8.654-47.192 7.884-17.653 38.269-46.807 26.384-26.385 40.423-48.731 14.038-22.346 14.038-52.779 0-51.643-37.114-80.682-37.115-29.038-87.808-29.038-50.076 0-82.884 26.731-32.807 26.73-46.807 62.96l51.383 20.615q7.308-19.923 25-38.807 17.693-18.885 52.539-18.885 35.462 0 52.423 19.424 16.962 19.423 16.962 42.731 0 20.384-11.616 37.307-11.615 16.923-29.615 32.693-39.384 35.538-49.769 56.692-10.385 21.154-10.385 63.768Zm28.836 298.615q-78.836 0-148.204-29.92-69.369-29.92-120.682-81.21-51.314-51.291-81.247-120.629-29.933-69.337-29.933-148.173t29.92-148.204q29.92-69.369 81.21-120.682 51.291-51.314 120.629-81.247 69.337-29.933 148.173-29.933t148.204 29.92q69.369 29.92 120.682 81.21 51.314 51.291 81.247 120.629 29.933 69.337 29.933 148.173t-29.92 148.204q-29.92 69.369-81.21 120.682-51.291 51.314-120.629 81.247-69.337 29.933-148.173 29.933Z"
      />
    </svg>
  );
}
