import React, { useState } from 'react';

import { Collapse, makeStyles } from '@material-ui/core';
import ArrowDown from '@material-ui/icons/KeyboardArrowDownRounded';
import ArrowUp from '@material-ui/icons/KeyboardArrowUpRounded';
import cn from 'classnames';
import { FormattedMessage } from 'localization';

import { TrackingStep } from '../types/Tracking';

const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',

    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    flexDirection: 'column',
  },
  textContainer: {
    paddingLeft: '12px',
    display: 'grid',
    gridTemplateColumns: '1fr 40px',

    backgroundColor: '#E0F2F1',
    borderTopLeftRadius: '5px',
    borderTopRightRadius: '5px',

    cursor: 'pointer',
    transition: 'all 0.15s ease-in',

    '&:hover': {
      backgroundColor: '#ceeae9',
    },
    '&:hover $textCell, &:hover $toggleCell': {
      color: '#3c908f',
    },

    [theme.breakpoints.down('xs')]: {
      padding: '0 6px',
      paddingTop: '6px',
      gridTemplateColumns: '1fr',
    },
  },
  textContainerMissingInfo: {
    gridTemplateColumns: '1fr',
    paddingRight: '12px',
  },
  textCell: ({ showExtendedCourierInfo }: { showExtendedCourierInfo: boolean }) => ({
    color: '#4BB4B3',

    ...(showExtendedCourierInfo
      ? {}
      : {
          display: 'flex',
          alignItems: 'center',

          [theme.breakpoints.down('xs')]: {
            display: 'block',
          },
        }),

    '& > p': {
      margin: 0,
      textAlign: 'left',
      fontSize: '15px',
      lineHeight: '30px',

      '& > span:first-of-type': {
        marginLeft: '8px',
      },
      [theme.breakpoints.down('xs')]: {
        textAlign: 'center',
        fontSize: '11px',
        lineHeight: '20px',
      },

      ...(showExtendedCourierInfo
        ? {}
        : {
            marginLeft: '8px',

            [theme.breakpoints.down('xs')]: {
              marginLeft: 0,
            },
          }),
    },
  }),
  toggleCell: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: '#4BB4B3',
    fontSize: '20px',
  },
  tableContainer: {
    width: '100%',
    marginBottom: '10px',

    [theme.breakpoints.down('xs')]: {
      overflowX: 'auto',
      width: 'calc(100vw - 10px)',
    },
  },
  tableContainerInner: {
    padding: '10px',
    width: '100%',
    borderTop: '1px solid #E0F2F1',
    borderRadius: '5px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',

    [theme.breakpoints.down('xs')]: {
      width: 'max-content',
    },
  },
  tableHeader: {
    width: '100%',
    height: '40px',
    padding: '8px',
    marginBottom: '10px',
    backgroundColor: '#fff',
    boxShadow: '0 0 10px 0 rgba(198, 219, 221, 0.5)',
    borderRadius: '5px',

    display: 'grid',
    gridTemplateColumns: '110px 140px 160px 1fr',

    '& > *': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      textAlign: 'center',
      minHeight: '24px',
      fontSize: '13px',
      color: '#4bb4b3',
      fontWeight: 700,
      textTransform: 'uppercase',
      borderRight: '1px solid #c6dbdd',

      '&:last-of-type': {
        borderRight: 'none',
      },
    },
  },
  tableRow: {
    width: '100%',
    padding: '8px',
    minHeight: '40px',
    marginBottom: '10px',
    backgroundColor: '#fff',
    boxShadow: '0 0 10px 0 rgba(198, 219, 221, 0.5)',
    borderRadius: '5px',

    display: 'grid',
    gridTemplateColumns: '110px 140px 160px 1fr',

    '&:last-of-type': {
      marginBottom: '0',
    },

    '& > *': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      textAlign: 'center',
      minHeight: '24px',
      fontSize: '13px',
      lineHeight: '16px',
      color: '#00363d',

      '&:last-child': {
        textAlign: 'left',
        justifyContent: 'flex-start',
        paddingLeft: '5px',
      },
    },
    [theme.breakpoints.down('xs')]: {
      gridTemplateColumns: '110px 140px 160px 200px',
    },
  },
  collapseContainer: {
    width: '100%',
  },
}));

export const DISPLAY_SECONDARY_TRACKNUM_COURIER = [
  'NACEX',
  'SCHENKER',
  'GLSNEW',
  'ENVIALIA',
  'TRANSAHER',
  'GLS_ITALY',
  'DHLPARCEL_PORTUGAL',
];

interface TrackNumDisplayProps {
  courier: string;
  courierMethod: string;
  trackingNumber: string;
  secondaryCourier: string;
  secondaryCourierMethod: string;
  secondaryTrackingNumber: string;
  showExtendedCourierInfo: boolean;
  isCustomerArea: boolean;
}

const TrackNumDisplay = ({
  courier,
  courierMethod,
  trackingNumber,
  secondaryCourier,
  secondaryCourierMethod,
  secondaryTrackingNumber,
  showExtendedCourierInfo,
  isCustomerArea = false,
}: TrackNumDisplayProps) => {
  if (courier) {
    return (
      <>
        <p>
          <FormattedMessage id={`general.courier.${courier.toLowerCase()}`} />
          {showExtendedCourierInfo && (
            <>
              {' - '}
              <span style={{ textTransform: 'capitalize', margin: 0 }}>
                {courierMethod.toLowerCase() || '-'}
              </span>
            </>
          )}
          {':  '}
          {isCustomerArea ? secondaryTrackingNumber || trackingNumber || '-' : trackingNumber}
        </p>
        {!!secondaryCourier && !!secondaryTrackingNumber && (
          <p>
            <FormattedMessage id={`general.courier.${secondaryCourier.toLowerCase()}`} />
            {showExtendedCourierInfo && secondaryCourierMethod && (
              <>
                {' - '}
                <span style={{ textTransform: 'capitalize', margin: 0 }}>
                  {secondaryCourierMethod.toLowerCase()}
                </span>
              </>
            )}
            {':  '}
            {secondaryTrackingNumber}
          </p>
        )}
        {!isCustomerArea && !!secondaryTrackingNumber && (
          <p>
            <FormattedMessage
              id={`general.courier.${(secondaryCourier || courier).toLowerCase()}`}
            />
            {showExtendedCourierInfo && (secondaryCourierMethod || courierMethod) && (
              <>
                {' - '}
                <span style={{ textTransform: 'capitalize', margin: 0 }}>
                  {(secondaryCourierMethod || courierMethod).toLowerCase()}
                </span>
              </>
            )}
            {':  '}
            {secondaryTrackingNumber}
          </p>
        )}
      </>
    );
  }

  return <FormattedMessage id="general.trackingbar.no-info-yet" />;
};

interface TrackingNumAndDetailsProps {
  steps?: TrackingStep[];
  courier?: string;
  courierMethod?: string;
  trackingNum?: string;
  secondaryCourier?: string;
  secondaryCourierMethod?: string;
  secondaryTrackingNum?: string;
  tableHeaderTextColor?: string;
  tableHeaderBackgroundColor?: string;
  keepDetailTableBorders?: boolean;
  toggleBackgroundColor?: string;
  toggleTextColor?: string;
  showExtendedCourierInfo?: boolean;
  isCustomerArea?: boolean;
}

const TrackingNumAndDetails = ({
  steps = [],
  courier = '',
  courierMethod = '',
  trackingNum = '',
  secondaryCourier = '',
  secondaryCourierMethod = '',
  secondaryTrackingNum = '',
  tableHeaderTextColor = '#4bb4b3',
  tableHeaderBackgroundColor = '#fff',
  keepDetailTableBorders = true,
  toggleBackgroundColor = '#E0F2F1',
  toggleTextColor = '#4BB4B3',
  showExtendedCourierInfo = false,
  isCustomerArea = false,
}: TrackingNumAndDetailsProps) => {
  const classes = useStyles({ showExtendedCourierInfo });
  const [areDetailsOpen, setDetails] = useState(false);

  return (
    <div className={classes.container}>
      <div
        className={cn(classes.textContainer, { [classes.textContainerMissingInfo]: !courier })}
        onClick={() => {
          if (courier) {
            setDetails((prev) => !prev);
          }
        }}
        style={{ backgroundColor: toggleBackgroundColor }}
      >
        <div className={classes.textCell} style={{ color: toggleTextColor }}>
          <TrackNumDisplay
            courier={courier}
            courierMethod={courierMethod}
            trackingNumber={trackingNum}
            secondaryCourier={secondaryCourier}
            secondaryCourierMethod={secondaryCourierMethod}
            secondaryTrackingNumber={secondaryTrackingNum}
            showExtendedCourierInfo={showExtendedCourierInfo}
            isCustomerArea={isCustomerArea}
          />
        </div>
        {courier && (
          <div className={classes.toggleCell} style={{ color: toggleTextColor }}>
            {areDetailsOpen ? (
              <ArrowUp color="inherit" fontSize="inherit" />
            ) : (
              <ArrowDown color="inherit" fontSize="inherit" />
            )}
          </div>
        )}
      </div>
      <Collapse in={areDetailsOpen} classes={{ root: classes.collapseContainer }}>
        <div className={classes.tableContainer}>
          <div
            className={classes.tableContainerInner}
            style={keepDetailTableBorders ? { border: '1px solid #E0F2F1' } : {}}
          >
            <div
              className={classes.tableHeader}
              style={{ backgroundColor: tableHeaderBackgroundColor }}
            >
              <div style={{ color: tableHeaderTextColor, borderRightColor: tableHeaderTextColor }}>
                <FormattedMessage id="general.trackingbar.datetime" />
              </div>
              <div style={{ color: tableHeaderTextColor, borderRightColor: tableHeaderTextColor }}>
                <FormattedMessage id="general.trackingbar.location" />
              </div>
              <div style={{ color: tableHeaderTextColor, borderRightColor: tableHeaderTextColor }}>
                <FormattedMessage id="general.trackingbar.status" />
              </div>
              <div style={{ color: tableHeaderTextColor, borderRightColor: tableHeaderTextColor }}>
                <FormattedMessage id="general.trackingbar.detail" />
              </div>
            </div>
            {steps.length === 0 && (
              <div
                style={{
                  padding: '20px',
                  fontSize: '13px',
                  lineHeight: '15px',
                  textAlign: 'center',
                }}
              >
                <FormattedMessage id="general.trackingbar.no-info" />
              </div>
            )}

            {steps.map((step, i) => (
              <div key={i} className={classes.tableRow}>
                <div>
                  <span style={{ maxWidth: '90px' }}>{step.date}</span>
                </div>
                <div>{step.location || 'N/A'}</div>
                <div
                  title={step.substatus}
                  style={{
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    WebkitLineClamp: 2,
                    overflowWrap: 'break-word',
                    WebkitBoxOrient: 'vertical',
                  }}
                >
                  {step.substatus}
                </div>
                <div>{step.courierDescription || step.description}</div>
              </div>
            ))}
          </div>
        </div>
      </Collapse>
    </div>
  );
};

export default TrackingNumAndDetails;
