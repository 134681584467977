export default {
  'orders.package': 'Colis',
  'orders.returns.reasons.size': 'La taille ne convient pas',
  'orders.returns.reasons.wrong-product': 'Mauvais produit livré',
  'orders.returns.reasons.wrong-size': 'Mauvaise taille livrée',
  'orders.returns.reasons.wrong-colour': 'Mauvaise couleur',
  'orders.returns.reasons.package-broken': 'Emballage du produit cassé',
  'orders.returns.reasons.product-broken': 'Produit cassé/défectueux',
  'orders.returns.reasons.product-used': 'Produit utilisé',
  'orders.returns.reasons.product-dirty': 'Produit sale',
  'orders.returns.reasons.do-not-want': "Je n'en veux plus",
  'orders.returns.reasons.exchange': 'Je souhaite échanger le produit',
  'orders.returns.reasons.other': 'Autre',
  'orders.returns.pickup.form.comments': 'Commentaires',
  'orders.list.empty': "Vous n'avez actuellement aucune commande",
  'orders.total': 'Total',
  'orders.vat': 'TVA',
  'or.confirm-address': 'CONFIRMEZ VOTRE ADRESSE DE RAMASSAGE',
  'or.from': 'À partir de',
  'or.to': "Jusqu'à",
  'or.return.list.empty': "Vous n'avez actuellement aucun retour",
  'or.confirm-return': 'CONFIRMER VOTRE RETOUR',
};
