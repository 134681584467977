//TODO: Replace to '@tanstack/react-query' only after update in 'frontend' and 'portal' apps
//DEPRECATED (USeD FOR OLD REACT QUERY).
import { useQuery } from 'react-query';

import { getCouriersIcons } from '../../utils/apiCustomCouriers';
import { DAY_CACHE } from '../constants';

export function useCourierIcon(enabled = true) {
  return useQuery(['getCouriersIcons'], getCouriersIcons, {
    cacheTime: DAY_CACHE,
    staleTime: DAY_CACHE,
    enabled,
  });
}
